angular.module("app").directive("unicornsIndustryLogoColor", () => {
    let link = (scope) => {
  
    };
  
    return {
      restrict: "E",
      templateUrl: "app/directives/dbUnicornsIndustryLogoColor/dbUnicornsIndustryLogoColorView.html",
      link: link,
      scope: {
        data: "=",
      }
    };
});