(function () {
  angular.module('app').directive('hueChartByLogoPerIndustry', [ '$sce', 
      function ($sce) {
        function link(scope) {
          scope.$watch("data", bindData);

          function bindData() {
            scope.dataForBrands = scope.data;
          }

          scope.loadSVG = (item) => {
            const output = [item.logo_svg.slice(0, 4), ` width='30' height='35'`, item.logo_svg.slice(4)].join('');
            return $sce.trustAsHtml(output)
          }
            
        }

        const directive = {
          link: link,
          restrict: 'E',
          replace: true,
          scope: {
            data: '='
          },
          templateUrl: 'app/directives/custom-infographics-brand-directives/chart-by-logo-per-industry.html'
        };

        return directive;

      }
    ]);
}());
