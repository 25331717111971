angular.module("app").directive("brandAttribute", () => {
  let link = (scope) => {
    scope.loadMoreAttrs = () => {
      //scope.isLoadedAllData = true;
      scope.loadMoreData();
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbBrandAttribute/dbBrandAttributeView.html",
    link: link,
    scope: {
      data: "=",
      selectedColor: "=",
      loadMoreData: "=",
      isLoadedAllData: "=",
    }
  };
});
