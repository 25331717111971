angular.module('app').directive('colorTrademarksByCase', () => {
  let link = (scope) => {
    scope.headerData = [ "color", "application number", "color name", "product name", "brand", "date granted" ];
  
    scope.loadMore = () => {
      scope.loadMoreData();
    };

    scope.sorting = (colName) => {
      scope.sortColumn(colName);
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorTrademarksByCase/dbColorTrademarksByCaseView.html",
    link: link,
    scope: {
      data: "=",
      selectedItem: "=",
      isLoadMoreData: "=",
      loadMoreData: "=",
      sortColumn: "=",
    }
  };
});
