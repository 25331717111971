angular.module("app").component("homeColorsComponent", {
  templateUrl: "app/components/home-colors-page/home-colors-page.tmpl.html",
  controller: ($scope, homeColorsService, $location, modalService, localStorageService) => {
    $scope.mode = "search"; // search | view
    $scope.activeTab = 1;
    $scope.currentPage = 0;

    $scope.selectedMenuCategory = "";
    $scope.selectedMenuValue = "";
    $scope.selectedViewMode = "";
    $scope.isLoadingControls = true;

    if(localStorageService.get("currentUser")){
      $scope.user = localStorageService.get("currentUser");
    }

    $scope.controlsData = {
      categories: [{ id: -1, title: "CATEGORIES(ALL)" }],
      sub_categories: [{ id: -1, title: "SUB CATEGORIES(ALL)" }],
      styles: [{ id: -1, title: "STYLE(ALL)" }],
      colors: [{ id: -1, title: "COLOR(ALL)" }],
    };

    $scope.resetFilters = () => {
      if(!$scope.user.admin) {
        modalService.showModal(10, null, "We're working to finalize this dashboard and except it launched in Q4 of 2022.");
      }
      return ($scope.selectedFilters = {
        category: $scope.controlsData.categories[0],
        sub_category: $scope.controlsData.sub_categories[0],
        style: $scope.controlsData.styles[0],
        color: $scope.controlsData.colors[0],
      });
    };

    $scope.isModeSearch = () => {
      return $scope.mode === "search";
    };

    $scope.isModeView = () => {
      return $scope.mode === "view";
    };

    $scope.setModeSearch = () => {
      $scope.isLoadingControls = true;
      $scope.topColors = [];
      $scope.frequency = [];
      $scope.resetFilters();
      $location.url($location.path());
      this.fetchCategoryForHomeColors();
      return ($scope.mode = "search");
    };

    $scope.setModeView = () => {
      $scope.mode = "view";
    };

    $scope.resetFilters();

    $scope.setTab = (tab) => {
      if (tab < 1 || tab > 4) return;
      $scope.activeTab = tab;
    };

    $scope.isActiveTab = (tab) => {
      return $scope.activeTab === tab;
    };

    this.fetchSelectsByQuery = () => {
      const normalizeData = (accum, filterKey) => {
        const filterData = filters[filterKey];
        accum[filterKey] = filterData.id === -1 ? "" : filterData.title;
        return accum;
      };
      const filters = $scope.selectedFilters;
      return Object.keys(filters).reduce(normalizeData, {});
    };

    this.fetchCategoryForHomeColors = () => {
      const controlsData = {};
      this.fetchSelectsByQuery();
      $location.search();

      return homeColorsService
        .fetchCategoryForHomeColors(this.fetchSelectsByQuery())
        .then((data) => {
          controlsData.styles = [
            $scope.controlsData.styles[0],
            ...data.styles.map((title, id) => ({
              id,
              title,
            })),
          ];
          controlsData.colors = [
            $scope.controlsData.colors[0],
            ...data.colors.map((title, id) => ({
              id,
              title,
            })),
          ];
          controlsData.categories = [
            $scope.controlsData.categories[0],
            ...data.categories.map((title, id) => ({
              id,
              title,
            })),
          ];
          controlsData.sub_categories = [
            $scope.controlsData.sub_categories[0],
            ...data.sub_categories.map((title, id) => ({
              id,
              title,
            })),
          ];
          $scope.controlsData = controlsData;
          $scope.isLoadingControls = false;
        });
    };

    this.fetchCategoryForHomeColors();

    $scope.onUISelect = (item, selector) => {
      $scope.isLoadingControls = true;
      $scope.selectedMenuValue = item.id === -1 ? "" : item.title;
      $scope.selectedMenuCategory = selector;
      this.fetchCategoryForHomeColors();
      $scope.queryParams = this.fetchSelectsByQuery();
      $location.search($scope.queryParams);

      if (item.title) $scope.selectedViewMode = selector.toLowerCase();
    };

    this.fetchProductsByCategory = () => {
      this.fetchSelectsByQuery();

      return homeColorsService
        .fetchProductsByCategory($location.search(), $scope.currentPage + 1)
        .then((data) => {
          $scope.homeList = data.products;
          const countData = data.color_data;
          $scope.topColors = countData.top_four_colors;
          $scope.colorsCount = countData.colors_count;
          $scope.stylesCount = countData.styles_count;
          $scope.productsCount = countData.products_count;
          $scope.frequency = countData.accent;
          $scope.pageCount = data.paginate.total_pages;
        });
    };

    $scope.setPage = (num) => {
      if (num >= 0 && num < $scope.pageCount) {
        $scope.currentPage = num;
        this.fetchProductsByCategory();
      }
    };

    $scope.disableSelectorsButOne = (active) => {
      const newValues = {
        category: false,
        sub_category: false,
        style: false,
        color: false,
      };

      Object.keys($scope.needDisableSelector).forEach((key) => {
        newValues[key] = !(
          key === active ||
          (key === "color" && active === "color")
        );
      });
      $scope.needDisableSelector = Object.assign({}, newValues);
    };

    $scope.unDisableAllSelectors = () => {
      $scope.needDisableSelector = {
        category: false,
        sub_category: false,
        style: false,
        color: false,
      };
    };

    // Mocked data
    $scope.choices = [1, 2, 3, 4, 5, 6, 7];

    $scope.frequencyByCategory = [
      { hex: "#000000", percentage: 55 },
      { hex: "#ffffff", percentage: 10 },
      { hex: "#892194", percentage: 10 },
      { hex: "#404f47", percentage: 10 },
      { hex: "#faebff", percentage: 10 },
      { hex: "#d7915e", percentage: 5 },
    ];

    $scope.infographics = [
      "https://s3.amazonaws.com/huestorage/hue-data-website/infographic_infographic_files/infographic_files/000/000/035/original/CO2a.RTW.FW.17.AllRe.MI.07_27_2018.jpg?1532744790",
      "https://s3.amazonaws.com/huestorage/hue-data-website/infographic_infographic_files/infographic_files/000/000/041/original/CO3a.RTW.FW.AllYe.AP.TK.07_27_2018.jpg?1532746128",
      "https://s3.amazonaws.com/huestorage/hue-data-website/infographic_infographic_files/infographic_files/000/000/039/original/CA1a.RTW.FW.17.AP.SE.07_27_2018.jpg?1532745995",
    ];

    $scope.mainColor = "#0A9F45";
    $scope.circularPercentages = [
      { title: "Kitchen", percentage: 60 },
      { title: "Bathroom", percentage: 60 },
      { title: "Bedroom", percentage: 15 },
      { title: "Living", percentage: 60 },
      { title: "Nursery/Kids", percentage: 60 },
      { title: "Homeoffice", percentage: 60 },
      { title: "Outdoor", percentage: 15 },
    ];

    $scope.loadGraphics = () => {
      this.fetchProductsByCategory();
      $scope.setModeView();
    };

    $scope.checkUrl = () => {
      if ($location.url() !== $location.path()) {
        $scope.loadGraphics();
        if ($location.search().color !== "") {
          $scope.selectedViewMode = "color";
          $scope.selectedMenuCategory = "color";
          $scope.selectedMenuValue = $location.search().color;
        } else if ($location.search().category !== "") {
          $scope.selectedViewMode = "category";
          $scope.selectedMenuCategory = "category";
          $scope.selectedMenuValue = $location.search().category;
        } else if ($location.search().sub_category !== "") {
          $scope.selectedViewMode = "sub_category";
          $scope.selectedMenuCategory = "sub_category";
          $scope.selectedMenuValue = $location.search().sub_category;
        } else if ($location.search().style !== "") {
          $scope.selectedViewMode = "style";
          $scope.selectedMenuCategory = "style";
          $scope.selectedMenuValue = $location.search().style;
        }
      }
    };

    $scope.checkUrl();
  },
});
