angular.module("app").directive("hueDbFiveYearsColors", [
  "reduceValue",
  "$timeout",
  function (reduceValue, $timeout) {
    function link(scope, element) {
      scope.tooltipsterConfig = {
        animation: 'fade',
        theme: 'tooltipster-default',
        trigger: 'custom',
        position: 'bottom'
      };
      scope.selectedBar = null;
      scope.$watch("pastColorYear", bindData);
      scope.$watch("pastSelectedYear", bindData);

      scope.tooltipTitle = function (color) {
        return `FAMILY: ${color.family}
        HEX: ${color.hex}
        RGB: ${color.rgb}
        BRAND: ${color.brand[0]}
        MODEL: ${color.model[0]}`.toUpperCase();
      }

      scope.selectBar = function (event) {
        if (scope.selectedBar == event.target) {
          angular.element(scope.selectedBar).tooltipster('hide');
          scope.selectedBar = null;
        } else {
          if (scope.selectedBar)
            angular.element(scope.selectedBar).tooltipster('hide');
            angular.element(event.target).tooltipster('show');
            scope.selectedBar = event.target;
        }
      };

      function bindData() {
        scope.year = scope.pastSelectedYear[0] ? scope.pastSelectedYear[0].year : '';
        scope.selectedYearColorPalette = scope.pastSelectedYear[0] ? scope.pastSelectedYear[0].colors : [];
        scope.brandPalettesYears = Object.keys(
          scope.pastColorYear
        ).reverse();
        if(scope.mode !== 'color') {
          scope.colorsData =  scope.basicColorSelected ? scope.basicColorSelected : [];
          const container = element.find('[chart-type="groups"]').html("");
          const colors = _.map(scope.colorsData, function (d) {
            const value = Math.round(d.percentage);
            return {
              value: reduceValue.reduce(d.count, "1"),
              valueTitle: Math.round(d.percentage) + "%",
              value2: reduceValue.reduce(d.count, "1"),
              valueTitle2: Math.round(d.percentage),
              title: d.family,
              color: d.hex,
            };
          });
  
          const renderChart = () => {
            $timeout(() => {
              const ch = new chartDotsHorizontal({
                data: colors,
                container: container[0],
                options: {},
              });
            }, 0);
          };
  
          if(scope.pastSelectedYear[0]){
            renderChart();
          }
        }
        

        // const windowResizeHandler = _.debounce(renderChart, 200);
        // scope.$on("$destroy", function () {
        //   $(window).off("resize", windowResizeHandler);
        // });
        // $(window).resize(windowResizeHandler);
      }

      // bindData();
    }

    return {
      restrict: "E",
      templateUrl:
        "app/directives/dbFiveYearsColors/dbFiveYearsColorsView.html",
      link: link,
      scope: {
        colorPaletteData: "=",
        pastColorYear: "=",
        pastSelectedYear: "=",
        basicColorSelected: "=",
        selectedColor: "=",
        mode: "=",
      },
    };
  },
]);
