angular.module('app').directive('colorUseCompareInPeriodArt', () => {
  let link = (scope) => {
    scope.filteredItem = {};
    scope.maxValuePercent = 1; 
    scope.$watch('colorData', function (newVal, prevVal) {
      if(newVal) {
        scope.colorData = newVal;
        bindData();
      }
    });

    scope.$watch('selectedColor', function (newVal, prevVal) {
      if(newVal) {
        scope.selectedColor = newVal;
      }

    });

    scope.widthDynamic = percent => {
      if (percent == 0){
        return 0
      } else if(percent > 0 && percent <= 1){
        return `calc(((100% - 87px) / 25) * 1 + 34px)`;
      } else if(percent > 1 && percent <= 5){
        return `calc((100% - 87px) / 25 * 5 + 49px)`;
      } else if(percent > 5 && percent <= 10){
        return `calc(((100% - 87px) / 25) * 10 + 68px)`;
      } else if(percent > 10 && percent <= 15){
        return `calc(((100% - 87px) / 25) * 15 + 87px)`;
      } else if(percent > 15 && percent <= 20){
        return `calc(((100% - 87px) / 25) * 20 + 106px)`;
      } else if(percent > 20 && percent <= 25){
        return `calc(((100% - 87px) / 25) * 25 + 74px)`;
      } else if(percent > 25){
        return `calc(((100% - 87px) / 25) * 25 + 74px)`;
      }
    }

    function bindData() {

      scope.data = scope.colorData.filter((item) => {
        return item.hex !== scope.selectedColor.hex;
      });

      scope.filteredItem = scope.colorData.filter((item) => {
        return item.hex !== scope.selectedColor.hex;
      });

      scope.selectedItem = scope.filteredItem.length ? scope.filteredItem[0] : scope.filteredItem;
      
    }

    scope.numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorUseCompareInPeriodArt/dbColorUseCompareInPeriodArtView.html",
    link: link,
    scope: {
      colorData: "=",
      selectedColor: "=",
      colorHeaderData: "=",
      brandColorHeaderData: "="
    }
  };
});
