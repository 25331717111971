(function () {
  angular.module("app").directive("hueChartByStudiesAttributes", [
    function () {
      function link(scope, element) {
        const containers = element.find("[chart-block]");
        const containerWidth = $("#brandCapture").width();

        _.each(containers, (c, i) => {
          const container = $(c);
          const svg = d3
            .select(container[0])
            .append("svg")
            .attr("width", "100%")
            .attr("height", "415");

          const g = svg
            .append("g")
            .attr(
              "transform",
              "translate(" +
                ((containerWidth / 10 - 60) / 2 + 30) +
                "," +
                380 +
                ")"
            );

          const circle = g
            .append("line")
            .attr("x1", -400)
            .attr("x2", 400)
            .attr("y1", 0)
            .attr("y2", 0)
            .style({ stroke: "#cccccc", "stroke-width": 1 });
          g.append("circle")
            .attr("r", 30)
            .style({ stroke: "#cccccc", "stroke-width": 1 })
            .attr("fill", "#fff");
          g.append("text")
            .text("ATTRIBUTE")
            .attr("text-anchor", "middle")
            .attr("y", 5)
            .style({ "font-weight": "300" });
          g.append("line")
            .style({ stroke: "#000", "stroke-width": 1 })
            .attr("x1", 0)
            .attr("x2", 0)
            .attr("y1", -30)
            .attr("y2", -340);
          g.append("line")
            .style({ stroke: "#000", "stroke-width": 1 })
            .attr("x1", -6)
            .attr("x2", 6)
            .attr("y1", -340)
            .attr("y2", -340);
          g.append("text")
            .text("211")
            .attr("text-anchor", "middle")
            .attr("y", -340 - 10);
          g.append("rect")
            .attr("width", 42)
            .attr("height", 16)
            .attr("fill", "#ff979d")
            .attr("transform", "translate(" + -21 + "," + -130 + ")");
          g.append("rect")
            .attr("width", 42)
            .attr("height", 16)
            .attr("fill", "#aa00dd")
            .attr("transform", "translate(" + -21 + "," + -100 + ")");
          g.append("rect")
            .attr("width", 42)
            .attr("height", 16)
            .attr("fill", "#9282ff")
            .attr("transform", "translate(" + -21 + "," + -70 + ")");
        });
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {},
        templateUrl:
          "app/directives/custom-infographics-brand-directives/chart-by-studies-attributes.html",
      };

      return directive;
    },
  ]);
})();
