angular.module("app").directive("hueColorByCarType", function () {
  function link(scope) {
    // scope.$watch("selectedColor", bindData);
    scope.$watch("data", (newValue, oldValue) => {
      if(newValue) {
        if(newValue.data && newValue.data.total_percentage !== 0){
          scope.totalPercentage = newValue.data.total_percentage;
          bindData();
        } 
        else {
          scope.totalPercentage = 0;
        }
      }
    });

    function bindData() {
      document.getElementById("my_auto_dataviz").innerHTML = "";
      const containerWidth = document.getElementById("autoCapture").offsetWidth;
      const width = containerWidth - 60;
      const height = 500;
      const margin = 40;
      const radius = Math.min(width, height) / 2 - margin;
      const svg = d3
        .select("#my_auto_dataviz")
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      const apiData = scope.data.data.result ? scope.data.data.result : [];
      scope.tempData = [];
      apiData.forEach(element => {
        if(element.percentage !== 0) {
          scope.tempData.push(element);
        }
      });
      // scope.tempData.sort(() => Math.random() - 0.5);
      let count = scope.tempData.length;
      let fixedHeight = 100 / count;
      scope.tempData.forEach(element => {
        element.height = fixedHeight;
      });

      const dataImageUrls = {
        Hatchback: "assets/img/icons/auto/car_logo_3.svg",
        Pickup: "assets/img/icons/auto/car_logo_8.svg",
        Suv: "assets/img/icons/auto/car_logo_6.svg",
        Minivan: "assets/img/icons/auto/car_logo_6.svg",
        "Convertible And Coupe": "assets/img/icons/auto/car_logo_2.svg",
        "Sedan And Vagon": "assets/img/icons/auto/car_logo_1.svg",
      };

      // Compute the position of each group on the pie:
      const pie = d3.layout
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          const tempData = {key: d.value.title, value: d.value.percentage, height: d.value.height}
          return tempData.height;
        });
      const data_ready = pie(d3.entries(scope.tempData));
      data_ready.map((item) => {
        item.data.logo = dataImageUrls[item.data.value.title];
      });

      // The arc generator
      const arc = d3.svg
        .arc()
        .innerRadius(radius * 0.7) // This is the size of the donut hole
        .outerRadius(radius * 0.8);

      const arc2 = d3.svg
        .arc()
        .innerRadius(radius * 0.7)
        .outerRadius(radius * 0.9);

      // Another arc that won't be drawn. Just for labels positioning
      const outerArc = d3.svg
        .arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", scope.selectedColor && scope.selectedColor.hex)
        .attr(
          "stroke",
          scope.selectedColor && scope.selectedColor.title === "White"
            ? "#E0E0DD"
            : "white"
        )
        .style(
          "stroke-width",
          scope.selectedColor && scope.selectedColor.title === "White"
            ? "1px"
            : "5px"
        );

      // Add the polylines between chart and labels:
      svg
        .selectAll("allPolylines")
        .data(data_ready)
        .enter()
        .append("polyline")
        .attr("stroke", "black")
        .style("fill", "none")
        .attr("stroke-width", 1)
        .attr("points", function (d) {
          const posA = arc2.centroid(d); // line insertion in the slice
          const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
          const posC = outerArc.centroid(d); // Label position = almost the same as posB
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
          posC[0] = radius * 1 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left

          return [posA, posB, posC];
        });

      // Add the polylines between chart and labels:
      svg
        .selectAll("allLabels")
        .data(data_ready)
        .enter()
        .append("text")
        .text(function (d) {
          return d.data.value.title;
        })
        .attr("transform", function (d) {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 1.84 * (midangle < Math.PI ? 1 : -1);
          pos[1] += 6;

          return "translate(" + pos + ")";
        })
        .attr("font-size", "14px")
        .style("text-anchor", function (d) {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midangle < Math.PI ? "start" : "end";
        });

      svg
        .selectAll("allLabels")
        .data(data_ready)
        .enter()
        .append("text")
        .text((d) => `${d.data.value.percentage}%`)
        .attr("transform", function (d) {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 1.1 * (midangle < Math.PI ? 1 : -1);
          pos[1] += 10;
          return "translate(" + pos + ")";
        })
        .attr("font-size", "26px")
        .attr("font-weight", "300")
        .style("text-anchor", function (d) {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midangle < Math.PI ? "start" : "end";
        });

      // Add the label Images:
      svg
        .selectAll("allLabelImagess")
        .data(data_ready)
        .enter()
        .append("image")
        .attr("href", function (d) {
          return d.data.logo;
        })
        .attr("width", 64)
        .attr("height", 64)
        .attr("transform", function (d) {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          let imagePositionY;

          if (d.data.value.title === "PICKUP") {
            imagePositionY = 45;
          } else {
            imagePositionY = 45;
          }

          pos[0] = radius * 1.64 * (midangle < Math.PI ? 1 : -1);
          pos[0] -= midangle < Math.PI ? 35 : 35;
          pos[1] -= 25;

          return "translate(" + pos + ")";
        });

      svg
        .append("text")
        .text(`${scope.data.data.total_percentage}%`)
        .attr("transform", "translate(0, -10)")
        .attr("font-size", "40px")
        .attr("style", "text-anchor: middle");

      svg
        .append("text")
        .text(`OF CARS IN ${scope.selectedYear && scope.selectedYear.title} USED`)
        .attr("transform", "translate(0, 35)")
        .attr("font-size", "16px")
        .attr("style", "text-anchor: middle");

      svg
        .append("text")
        .text(scope.selectedColor && scope.selectedColor.title)
        .attr("transform", "translate(0, 56)")
        .attr({
          "text-anchor": "middle",
          "font-size": "16px",
          "text-transform": "uppercase",
        })
        .attr("style", "text-transform: uppercase");
    }
  }
  return {
    restrict: "E",
    templateUrl:
      "app/directives/customInfographicsAutoDirectives/colorByCarType/colorByCarType.html",
    link: link,
    scope: {
      data: "=",
      selectedYear: "=",
      selectedColor: "=",
    },
  };
});
