angular
  .module('app')
  .service('moodboardService', ['httpService', '$http', 'appConfig', 'authService', function (httpService, $http, appConfig, authService) {

    this.getDetailsMoodboard = function (type_eq, type_end, id) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/moodboards?q[moodboard_type_eq]=${type_eq}&q[moodable_type_end]=${type_end}&q[moodable_id_eq]=${id}`,
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };

    this.getMoodboard = function (type_eq) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/moodboards?q[moodboard_type_eq]=${type_eq}`,
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };

    this.getListOfMoodboard = function (type_eq) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/moodboards/list?q[moodboard_type_eq]=${type_eq}`,
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };

    this.existingMoodboard = function (id, data) {
      return $http({
        url:
          appConfig.dashboardServiceUrl +
          "api/moodboards/" + id + "/items",
        method: "POST",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        data: data,
      });
    };

    this.existingMoodboardChart = function (id, data) {
      return $http({
        url:
          appConfig.dashboardServiceUrl +
          "api/moodboards/" + id + "/charts",
        method: "POST",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        data: { moodboard_chart: data },
      });
    };

    this.createMoodboard = function (data) {
      return $http({
        url:
          appConfig.dashboardServiceUrl +
          "api/moodboards",
        method: "POST",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        data: { moodboard: data },
      });
    };

    this.getMoodboardItem = function (id) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/moodboards/${id}/items`,
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };

    this.getMoodboardMailPDF = function (id) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/moodboards/${id}/download.json`,
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };

    this.deleteItemMoodboard = function (mainId, imageId) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/moodboards/${mainId}/items/${imageId}`,
        method: "DELETE",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };

    this.deleteChartMoodboard = function (mainId, imageId) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/moodboards/${mainId}/charts/${imageId}`,
        method: "DELETE",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };

    this.deleteMoodboard = function (mainId) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/moodboards/${mainId}`,
        method: "DELETE",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };
  }]);