angular.module('app').directive('wordsUsedAsColorDescriptive', () => {
  let link = (scope) => {
    scope.characters = [
      "A", "Á", "B", "C", "D", "E", "É", "F", "G", "H", "I", "K",
      "L", "M", "N", "O", "Ö", "P", "Q", "R", "S", "T", "V", "X"
    ];
    // dummy data-> words used as color descriptive


    scope.characterSelect = (value) => {
      scope.colorDescriptiveWords = scope.colorNameCloudData;
      scope.selectedCharacter = value;
      if (scope.selectedCharacter === "ALL") {
        // scope.selectedColorDescriptiveWords = scope.colorDescriptiveWords.sort((a, b) => {
        //   const nameA = a.name.toUpperCase();
        //   const nameB = b.name.toUpperCase();
        //   if (nameA < nameB) {
        //     return -1;
        //   }
        //   if (nameA > nameB) {
        //     return 1;
        //   }
        //   return 0;
        // });
        scope.selectedColorDescriptiveWords = scope.colorDescriptiveWords;
      } else {
        scope.selectedColorDescriptiveWords = scope.colorDescriptiveWords.filter(word => {
          return (scope.selectedCharacter.toLowerCase()).indexOf(word.name.toLowerCase().substring(0, 1)) === 0
        });
      }
    };

    scope.isSelected = (value) => {
      return scope.selectedCharacter === value;
    };

    scope.wordCloudOptions = {
      width: 800,
      height: 500,
    };
    scope.$watch('colorNameCloudData', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.colorNameCloudData = newValue;
        scope.characterSelect("ALL");
      }
    });
  };


  return {
    restrict: "E",
    templateUrl: "app/directives/dbWordsUsedAsColorDescriptive/dbWordsUsedAsColorDescriptiveView.html",
    link: link,
    scope: {
      isCloudView: "=",
      colorNameCloudData: "="
    }
  };
});
