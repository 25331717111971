angular.module("app").component("landingPageComponent", {
  templateUrl: "app/components/landing-page/landing-page.tmpl.html",
  controller: function (
    authService,
    $scope,
    $state,
    localStorageService,
    $http,
    searchColor,
    dataValidate,
    appConfig,
    $window,
    $location,
    modalService,
    colorRequest,
    reportService
  ) {
    const vm = this;

    if ($state.router.globals.current.url === "/subscription/cancel") {
      modalService.showModal(8);
    } else if ($state.router.globals.current.url === "/subscription/success") {
      modalService.showModal(7);
    }


    $http
      .get(
        appConfig.dashboardServiceUrl +
          `api/infographics.json?q[published_month_eq]=&q[published_year_eq]=&q[topic_eq]=`
      )
      .then(function (res) {
        vm.reports_on_landing = res.data.infographics.slice(0, 6);
      });

    const rgbToHex = (r, g, b) => {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    };

    $scope.setRgb = function (r, g, b) {
      $scope.colorRGB_R = r;
      $scope.colorRGB_G = g;
      $scope.colorRGB_B = b;
    };

    this.searchByRGB = function () {
      const RGB = {
        red: $scope.colorRGB_R,
        green: $scope.colorRGB_G,
        blue: $scope.colorRGB_B,
      };

      const hexColor = rgbToHex(
        $scope.colorRGB_R,
        $scope.colorRGB_G,
        $scope.colorRGB_B
      );
      const colorNTC = ntc.name(hexColor);

      if (colorNTC[1].slice(0, 13) !== "Invalid Color") {
        colorRequest.getRgb(colorNTC[1], 1).then(function (data) {
          vm.paintColorNamesData = [
            {
              colorName: colorNTC[1],
              RGB: RGB.red + "," + RGB.green + "," + RGB.blue,
            },
          ];
          vm.colorAssociationNames = data.short_namecontains;
          searchColor.set(vm.paintColorNamesData, vm.colorAssociationNames);
          $state.go("colorIndex", {
            red: $scope.colorRGB_R ? $scope.colorRGB_R : 0,
            green: $scope.colorRGB_G ? $scope.colorRGB_G : 0,
            blue: $scope.colorRGB_B ? $scope.colorRGB_B : 0,
          });
        });
      } else {
        modalService.showModal(5);
      }
    };
  },
});
