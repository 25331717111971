angular.module("app").directive("sportColorComparison", () => {
  let link = (scope) => {
    scope.$watch("selectedItem", function (selectedItem) {
      scope.selectedData = scope.data.find(item => item.name.toLowerCase() === selectedItem.title.toLowerCase());
      scope.otherData = scope.data.filter(item => item.name.toLowerCase() !== selectedItem.title.toLowerCase());
    });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbSportColorComparison/dbSportColorComparisonView.html",
    link: link,
    scope: {
      selectedItem: "=",
      data: "="
    }
  };
});
