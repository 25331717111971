angular.module("app").directive("infographicsForArt", [
  "$location",
  "$timeout",
  "customInfographicsConstants",
  "artDashboardRepository",
  function (
    $location,
    timeout,
    customInfographicsConstants,
    artDashboardRepository
  ) {
    function link(scope) {
      scope.meta = {};
      scope.isFilterVisible = customInfographicsConstants.isFilterVisible;
      scope.currentYear = moment().year();
      scope.filtersForArt = {
        schoolList: [
          {
            id: 1,
            title: 'All Schools'
          },
          {
            id: 2,
            title: 'american'
          },
          {
            id: 3,
            title: 'austrian'
          },
          {
            id: 4,
            title: 'belgian'
          },
          {
            id: 5,
            title: 'bohemian'
          },
          {
            id: 6,
            title: 'catalan'
          },
          {
            id: 7,
            title: 'danish'
          },
          {
            id: 8,
            title: 'dutch'
          },
          {
            id: 9,
            title: 'english'
          },
          {
            id: 10,
            title: 'finnish'
          },
          {
            id: 11,
            title: 'flemish'
          },
          {
            id: 12,
            title: 'french'
          },
          {
            id: 13,
            title: 'german'
          },
          {
            id: 14,
            title: 'greek'
          },
          {
            id: 15,
            title: 'hungarian'
          },
          {
            id: 16,
            title: 'irish'
          },
          {
            id: 17,
            title: 'italian'
          },
          {
            id: 18,
            title: 'netherlandish'
          },
          {
            id: 19,
            title: 'norwegian'
          },
          {
            id: 20,
            title: 'polish'
          },
          {
            id: 21,
            title: 'portugees'
          },
          {
            id: 22,
            title: 'russian'
          },
          {
            id: 23,
            title: 'scotish'
          },
          {
            id: 24,
            title: 'spanish'
          },
          {
            id: 25,
            title: 'swedish'
          },
          {
            id: 26,
            title: 'swiss'
          },
          {
            id: 27,
            title: 'other'
          }
        ],
        color: [],
        artist: []
      };

      artDashboardRepository.getArtColor().then(function (data) {
        scope.filtersForArt.color = data.colors;
        scope.filtersForArt.color.unshift({
          id: "all",
          title: "COLOR",
          all: true,
        });
        scope.currentColorForArt = scope.filtersForArt.color[0];
      });

      artDashboardRepository.getArtArtist('')
      .then(data => {   
        scope.filtersForArt.artist = data.artists;
        scope.filtersForArt.artist.unshift({
          id: "all",
          name: "ARTIST",
          last_name: 'all',
          all: true,
        });
        scope.currentArtistForArt = scope.filtersForArt.artist[0];
        scope.currentArtistForArt1 = scope.filtersForArt.artist[0];
      });


      scope.chartsForArt = [
        {
          qNumber: "ART1",
          title: "1. COLOR COMBINATION FOR COLOR USED BY SCHOOL ARTISTS",
          chartTitle: "COLOR COMBINATION FOR COLOR USED BY SCHOOL ARTISTS",
          id: "colorCombinationForColor",
          filters: {
            schoolForArt: true,
            colorForArt: true
          },
        },
        {
          qNumber: "ART2",
          title: "2. SELECTED COLOR COMBINATIONS BY ARTIST",
          chartTitle: "SELECTED COLOR COMBINATIONS BY ARTIST",
          id: "selectedColorCombinationsByArtist",
          filters: {
            artistForArt: true,
          },
        },
        {
          qNumber: "ART3",
          title: "3. UNIQUE COLORS FOR ARTIST",
          chartTitle: "UNIQUE COLORS FOR ARTIST",
          id: "uniqueColorsForArtist",
          filters: {
            artistForArt1: true,
          },
        },
      ];
      scope.currentChartForArt = {
        selected: scope.chartsForArt[0],
      };

      scope.updateColor = function (color) {
        scope.currentColorForArt = color;
        const color2 = scope.currentColorForArt ? scope.currentColorForArt.name : 'COLOR';
        const school2 = scope.currentSchoolForArt ? scope.currentSchoolForArt.title : 'SCHOOL';
        scope.titleForArt = "COLOR COMBINATION FOR " + color2 + " USED BY " + school2 + " ARTISTS";
        if(scope.currentColorForArt && scope.currentColorForArt.title !== "COLOR") {
          const school = scope.currentSchoolForArt.title === 'All Schools' ? '' : scope.currentSchoolForArt.title;
          const color = scope.currentColorForArt.title === "COLOR" ? '' : scope.currentColorForArt.id;
          scope.getChart1(school, color) 
        }
      };
      scope.updateSchool = function (school) {
        scope.currentSchoolForArt = school;
        const color1 = scope.currentColorForArt ? scope.currentColorForArt.name : 'COLOR';
        const school1 = scope.currentSchoolForArt ? scope.currentSchoolForArt.title : 'SCHOOL';
        scope.titleForArt = "COLOR COMBINATION FOR " + color1 + " USED BY " + school1 + " ARTISTS";
        if(scope.currentColorForArt && scope.currentColorForArt.title !== "COLOR") {
          const school = scope.currentSchoolForArt.title === 'All Schools' ? '' : scope.currentSchoolForArt.title;
          const color = scope.currentColorForArt.title === "COLOR" ? '' : scope.currentColorForArt.id;
          scope.getChart1(school, color) 
        }
      };
      scope.updateArtist = function (artist) {
        scope.currentArtistForArt = artist;
        const artist1 = scope.currentArtistForArt ? scope.currentArtistForArt.name : 'ARTIST';
        scope.titleForArt = "SELECTED COLOR COMBINATIONS BY " + artist1;
        if(scope.currentArtistForArt && scope.currentArtistForArt.name !== "ARTIST") {
          const artist = scope.currentArtistForArt.name === "ARTIST" ? '' : scope.currentArtistForArt.id;
          scope.getChart2(artist) 
        }
      };

      scope.updateArtist1 = function (artist) {
        scope.currentArtistForArt1 = artist;
        const artist2 = scope.currentArtistForArt1 ? scope.currentArtistForArt1.name : 'ARTIST';
        scope.titleForArt = "UNIQUE COLORS FOR " + artist2;
        if(scope.currentArtistForArt1 && scope.currentArtistForArt1.name !== "ARTIST") {
          const artist = scope.currentArtistForArt1.name === "ARTIST" ? '' : scope.currentArtistForArt1.id;
          scope.getChart3(artist) 
        }
      };

      scope.getChart1 = (school, color) => {
        artDashboardRepository.getArtInfographicsChart1(school, color).then(function (data) {
          scope.colorCombinationDataForArt = data.response.chartData2.combined_colors
        });
      }

      scope.getChart2 = (artist) => {
        artDashboardRepository.getArtInfographicsChart2(artist).then(function (data) {
          scope.selectedColorCombinationsByArtist = data.response.chartData2;
        });
      }

      scope.getChart3 = (artist) => {
        artDashboardRepository.getArtInfographicsChart3(artist).then(function (data) {
          scope.artistUnqiueColorsData = data.response.chartData1;
        });
      }

      scope.initStatesByQueryParamsForArt = function () {
        timeout(() => {
          scope.currentChartForArt.selected =
            scope.chartsForArt.find((i) => i.id === scope.currentChartForArt.selected.id) ||
            scope.chartsForArt[0];

          scope.currentSchoolForArt = scope.filtersForArt.schoolList[0];
          scope.currentColorForArt = scope.filtersForArt.color[0];
          scope.currentArtistForArt = scope.filtersForArt.artist[0];
          scope.currentArtistForArt1 = scope.filtersForArt.artist[0];
          scope.colorCombinationDataForArt = [];
          scope.selectedColorCombinationsByArtist = [];
          scope.artistUnqiueColorsData = [];

          if(scope.currentChartForArt.selected.qNumber === 'ART1'){
            const color = scope.currentColorForArt ? scope.currentColorForArt.name : 'COLOR';
            const school = scope.currentSchoolForArt ? scope.currentSchoolForArt.title : 'SCHOOL';
            scope.titleForArt = "COLOR COMBINATION FOR " + color + " USED BY " + school + " ARTISTS";
          } else if(scope.currentChartForArt.selected.qNumber === 'ART2'){ 
            const artist = scope.currentArtistForArt ? scope.currentArtistForArt.name : 'ARTIST';
            scope.titleForArt = "SELECTED COLOR COMBINATIONS BY " + artist;
          } else if(scope.currentChartForArt.selected.qNumber === 'ART3'){ 
            const artist1 = scope.currentArtistForArt1 ? scope.currentArtistForArt1.name : 'ARTIST';
            scope.titleForArt = "UNIQUE COLORS FOR "+ artist1 ;
          } else {
            scope.titleForAuto = scope.currentChartForArt.selected.chartTitle;
          }
        }, 0);
      };

      scope.initStatesByQueryParamsForArt();

      scope.updateQueryBySelectorsForArt = function () {
        scope.initStatesByQueryParamsForArt();
      };

      scope.refresh = () => {
        scope.updateQueryBySelectorsForArt();
      };

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
          result += name.toUpperCase() + ".";

        return result + date + type;
      };

      scope.exportJpgThroughDomToImage = (name) => {
        let node = angular.element("#artCapture")[0];

        const titles = angular.element(".graphic-titles");
        const header = angular.element(".graphic-titles-export");
        const footer = angular.element(".customized-infographics-footer");

        footer.css({ display: "block" });
        header.css({ display: "block" });
        titles.css({ display: "none" });
        $("html").addClass("hide-scrollbar");

        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            const img = new Image();
            img.src = dataUrl;

            const link = document.createElement("a");
            link.href = img.src;
            link.download = scope.generateImgName(".png", "brand", name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            $("html").removeClass("hide-scrollbar");
            footer.css({ display: "none" });
            header.css({ display: "none" });
            titles.css({ display: "block" });
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      };
    }

    return {
      restrict: "E",
      templateUrl:
        "app/components/customized-infographics/infographicsForArt/infographicsForArt.html",
      link: link,
      scope: {
      },
    };
  },
]);
