angular.module("app").directive("gameGenreColorComparison", () => {
  let link = (scope) => {
    scope.isLoadedAllData = false;
    scope.loadMoreGenre = () => {
      scope.loadMoreData();
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbGameGenreColorComparison/dbGameGenreColorComparisonView.html",
    link: link,
    scope: {
      selectedGenre: "=",
      allGenreData: "=",
      loadMoreData: "=",
      isLoadedAllData: "=",
    }
  };
});
