angular.module("app").directive("colorComparisonTherapeuticArea", () => {
  let link = (scope) => {

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorComparisonTherapeuticArea/dbColorComparisonTherapeuticAreaView.html",
    link: link,
    scope: {
      selectedArea: "=",
      therapeuticAreas: "="
    }
  };
});
