angular.module("app").controller("gameController", [
  "$scope",
  "gameDashboardRepository",
  "dashboardOverlayService",
  "searchMenuRepository",
  "$timeout",
  "charts",
  "$state",
  "$location",
  "modalService",
  "localStorageService",
  function (
    scope,
    gameDashboardRepository,
    dashboardOverlayService,
    searchMenuRepository,
    timeout,
    charts,
    $state,
    $location,
    modalService,
    localStorageService
  ) {
    scope.menus = {
      genre: "",
      creator: "",
      year: "",
      color: "",
    };

    scope.disabledControls = {
      genre: false,
      creator: false,
      year: false,
      color: false,
    };

    scope.type = {
      genre: "genre",
      creator: "creator",
      year: "year",
      color: "color",
    };

    if(localStorageService.get("currentUser")){
      scope.user = localStorageService.get("currentUser");
    }

    scope.params = $location.search();

    scope.$watch('params', function (params) {
     if (params.control === 'searchMenu') {
       scope.init();
     } else {
       scope.handleChangeControl(params.control, params);
     }
    });

    scope.disabledInput = (type, value) => {
      switch (type) {
        case "genre":
          scope.disableCreator = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "creator":
          scope.disableGenre = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "year":
          scope.disableGenre = Boolean(value);
          scope.disableCreator = Boolean(value);
          break;
      }
    };

    scope.mainParam = null;
    scope.mainParamId = null;
    scope.secondaryParams = {};
    scope.tab = 1;
    scope.currentPage = 0;

    scope.genre_place_holder = "SEARCH BY GENRE";
    scope.creator_place_holder = "SEARCH BY CREATOR";
    scope.year_place_holder = "SEARCH BY YEAR";
    scope.header_title = "color: blue";

    scope.showDashboard = false;
    scope.title = scope.year;
    scope.subtitle = null;

    scope.isLoadingControls = true;
    scope.ajaxInProgress = false;

    scope.compareData = [];
    scope.colorsCountData = [];
    scope.colorsCountData = [];
    scope.shadesData = [];
    scope.topFamiliesData = [];
    scope.topBrandsData = [];
    scope.topColorsData = [];
    scope.colorFrequencyData = [];
    scope.topFinishesData = [];
    scope.carColorsData = [];
    scope.colorPaletteData = [];
    scope.mainSeason = [];
    scope.mosaicData = {};
    scope.companyList = [];
    scope.creatorList = [];
    scope.yearList = [];
    scope.genreList = [];
    scope.yearSelections = [];
    scope.showDropdown = true;
    scope.selectedYear = { name: moment().year(), id: moment().year() };
    scope.updateSelectedYear = function (year) {
      if(scope.searchMode === 'year') {
        scope.selectedYear = year;
        scope.selectedFilterValue = { title: year.name, id: year.id };
        scope.setTab(1);
      } else {
        scope.selectedYear = year;
        scope.setTab(1);
      }
      scope.loadGraphics();
    };

    scope.videoDashboardData = [];

    scope.colorPaletteBucket = 38;

    scope.loadSettings = () => {
      // scope.ajaxInProgress = true;
      gameDashboardRepository.getColors().then((data) => {
        scope.colors = data.colors;
      }).finally(() => {
        // scope.ajaxInProgress = false;
      });

      gameDashboardRepository.getGenres().then((data) => {
        scope.genreList = data.genre;
      }).finally(() => {
        // scope.ajaxInProgress = false;
      });

      gameDashboardRepository.getYears().then(data => { 
        scope.yearList = data.year;
      }).finally(() => {
        // scope.ajaxInProgress = false;
      });
    };

    scope.loadSettings();

    scope.setback = () => {
      $state.go("vr-games", {control: "searchMenu", id: "", title: "", hex: "", name: ""});
    };

    scope.init = () => {
      scope.menus = {
        genre: "",
        creator: "",
        year: "",
        color: "",
      };
      scope.disableGenre = false;
      scope.disableCreator = false;
      scope.disableYear = false;
      scope.isLoadingControls = true;
      scope.currentPage = 0;
      scope.mainSeason = [];
      scope.tab = 1;
      // if(!scope.user.admin) {
      //   modalService.showModal(10, null, "We're working to finalize this dashboard and except it launched in Q2 of 2023.");
      // }
    };

    scope.setTab = (tabId) => {
      scope.tab = tabId;
      scope.select_images = false;
      scope.selected_moodboard = false;
      scope.state = 1;
    };

    scope.isSet = (tabId) => {
      return scope.tab === tabId;
    };

    scope.changeColorPaletteBucket = function (value) {
      scope.colorPaletteBucket = value;
    };

    scope.loadGraphics = function () {
      if (scope.mainParam) {
        scope.showDashboard = true;
        dashboardOverlayService.loadingStart(4000);

        // ----------------------------------------

        if (scope.mainParam === "color") {
          scope.ajaxInProgress = true;
          gameDashboardRepository
            .getVideoDashboard(
              scope.mainParam,
              scope.mainParamId,
              scope.currentPage + 1,
              30,
              scope.selectedYear.id
            )
            .then(function (data) {
              scope.videoDashboardData = data.video_games;
              scope.pageCount = data.paginate.total_pages;
              scope.filteredChoices = data.years.map(item => {
                return { name: item, id: item };
              });
              scope.yearSelections = scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1); 
              scope.selectedYear = { name: data.year, id: data.year };
              scope.isLoadingControls = false;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          } else if (scope.mainParam === "genre") {
            scope.ajaxInProgress = true;
            gameDashboardRepository
            .getVideoDashboard(
              scope.mainParam,
              scope.mainParamId,
              scope.currentPage + 1,
              30,
              scope.selectedYear.id
            )
            .then(function (data) {
              scope.videoDashboardData = data.video_games;
              scope.pageCount = data.paginate.total_pages;
              scope.filteredChoices = data.years.map(item => {
                return { name: item, id: item };
              });
              scope.yearSelections = scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1); 
              scope.selectedYear = { name: data.year, id: data.year };
              scope.isLoadingControls = false;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          } else if (scope.mainParam === "creator") {
            scope.ajaxInProgress = true;
            gameDashboardRepository
            .getVideoDashboard(
              scope.mainParam,
              scope.selectedFilterValue.title,
              scope.currentPage + 1,
              30,
              scope.selectedYear.id
            )
            .then(function (data) {
              scope.videoDashboardData = data.video_games;
              scope.pageCount = data.paginate.total_pages;
              scope.filteredChoices = data.years.map(item => {
                return { name: item, id: item };
              });
              scope.yearSelections = scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1); 
              scope.selectedYear = { name: data.year, id: data.year };
              scope.isLoadingControls = false;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          } else if (scope.mainParam === "year") {
            scope.ajaxInProgress = true;
            gameDashboardRepository
            .getVideoDashboard(
              scope.mainParam,
              scope.mainParamId,
              scope.currentPage + 1,
              30,
              scope.selectedYear.id
            )
            .then(function (data) {
              scope.videoDashboardData = data.video_games;
              scope.pageCount = data.paginate.total_pages;
              scope.filteredChoices = data.years.map(item => {
                return { name: item, id: item };
              });
              scope.yearSelections = scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1); 
              scope.selectedYear = { name: data.year, id: data.year };
              scope.isLoadingControls = false;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          }
      }
    };

    scope.setPage = (num) => {
      if (num >= 0 && num < scope.pageCount) {
        scope.currentPage = num;
        scope.loadGraphics();
      }
    };

    scope.handleChangeControl = function (control, item) {
      scope.header_title = () => {
        if (control === "color") {
          return `${control}: ${item.name}`;
        } else if (control === 'year') {
          return `${control}:`;
        } else if (control === 'genre') {
          return `${control}: ${item.name}`;
        } else {
          return `${control}: ${item.title}`;
        }
      };
      scope.mainParam = control;
      scope.selectedFilterValue = item;
      scope.mainParamId = item.id;
      scope.searchMode = control;

      if(scope.searchMode === 'year') {
        scope.searchId = parseInt(item.title);
        scope.selectedYear = {name: parseInt(item.title), id: parseInt(item.title)};
      }
      scope.loadGraphics();
    };

    scope.handleSearchChange = function (control, item) {
      if(item.name === 'medium yellow/green') {
        item.name = 'medium yellow - green'
      } else if(item.name === 'light yellow/green') {
        item.name = 'light yellow - green'
      } else if(item.name === 'dark yellow/green') {
        item.name = 'dark yellow - green'
      }

      $state.go("vr-games", Object.assign({control: control}, item));
    };

    scope.getResultsOnFilterSearch = (type, str, page) => {
      scope.ajaxInProgress = true;  
      switch (type) {
        case 'creator':
          if (!str.length) {
            scope.creatorList = [];
            scope.ajaxInProgress = false;
            return;
          }

          gameDashboardRepository.getCreatorList(str)
            .then(data => {
              scope.creatorList = [];
              scope.creatorList = data.creator;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break; 

        default:
          scope.ajaxInProgress = false;
      }
    }; 

    scope.$watch(
      () => {
        return dashboardOverlayService.showOverlay;
      },
      (newValue) => {
        scope.showDashboardOverlay = newValue;
      }
    );
  },
]);
