(function () {
  angular.module("app").directive("globalIndividualColorChart", [
    function () {
      function link(scope) {
        scope.$watch("data", (newValue, oldValue) => {
          if (newValue && newValue !== oldValue) {
            bindData();
          }
        });

        scope.$watch("data", bindData);
        scope.$watch("index", bindData);

        function bindData() {
          const parent = document.getElementById(`my_canvas_container_${scope.index}`);
          while (parent && parent.firstChild) {
            parent.removeChild(parent.firstChild);
          }
          const canvas = document.createElement("canvas");

          if (parent) {
            parent.appendChild(canvas);
            canvas.setAttribute("id", `individual_canvas_${scope.index}`);
            canvas.setAttribute("width", "100%");
          }
          const ctx = canvas.getContext("2d");

          let labels = scope.data.timeValues;

          scope.chartData = [scope.data];

          const datasets = scope.chartData.map((el) => {
            const isColorLight = () => {
              const color =
                el.color.hex === "#FFFFFF"
                  ? "#010101"
                  : el.color.hex === "#F5F5DC"
                  ? "#030303"
                  : el.color.hex;

              const offset =
                color === "#010101"
                  ? [20, 3]
                  : color === "#030303"
                  ? [10, 5]
                  : [];

              return { color, offset };
            };
            let points = el.points;
            el.points = points.map(point => point.value ? point.value : point);

            return {
              label: "",
              fill: false,
              lineTension: 0.3,
              backgroundColor: el.color.hex,
              borderColor: isColorLight().color,
              borderCapStyle: "butt",
              borderDash: isColorLight().offset,
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: isColorLight().color,
              pointBackgroundColor: "#dbdbdb",
              pointBorderWidth: 1,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: isColorLight().color,
              pointHoverBorderColor: isColorLight().color,
              pointHoverBorderWidth: 2,
              pointRadius: 8,
              pointHitRadius: 10,
              data: el.points.slice(-scope.data.timeValues.length),
              spanGaps: false,
            };
          });

          datasets.push({
            data           : scope.averageData || [],
            borderWidth    : 5, // set diameter of dots here
            backgroundColor: "#fff",
            borderColor: "#010101",
            fill           : false,
            pointRadius    : 0,
            borderDash     : [0,10], // set 'length' of dash/dots to zero and
            // space between dots (center to center)
            // recommendation: 2x the borderWidth
            borderCapStyle : 'round' // this is where the magic happens
          });

          const data = {
            labels: labels,
            datasets: datasets,
          };

          window.myLineChart = new Chart(ctx, {
            type: "line",
            data: data,
            options: {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      fontSize: 16,
                      padding: 5,
                    },
                  },
                ],
                xAxes: [
                  {
                    ticks: {
                      fontSize: 16,
                      padding: 5,
                    },
                  },
                ],
              },
            },
          });
        }

        bindData();
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          data: "=",
          averageData: "=",
          index: "=",
        },
        templateUrl:
          "app/directives/dbGlobalColorData/globalColorTracker/globalIndividualColorChart/globalIndividualColorChartView.html",
      };

      return directive;
    },
  ]);
})();
