(function () {
  angular.module("app").directive("hueChartTrends", [
    "common",
    "config",
    "chartsHelper",
    function (common, config, chartsHelper) {
      function link(scope, element) {
        scope.$watch("data", bindData);

        function bindData() {
          if (!scope.data) {
            return;
          }

          const container = element /*.find('[chart-type]')*/
            .html("");

          const bars = [];
          const data = {
            periods: [],
            data: bars,
          };
          _.each(scope.data, function (d) {
            if(d.data.length > 0) {
              data.periods.push({title: d.title});
            }
          })

          _.each(scope.data, function (period) {
            _.each(_.sortBy(period.data, "value").reverse(), function (d, i) {
              let bar = _.find(bars, { name: d.name });
              if (!bar) {
                bar = {
                  name: d.name,
                  title: i + 1,
                  points: [],
                  color: d.color,
                };
                if (d.color === "#ffffff") {
                  bar.color = "#efefef";
                }
                bars.push(bar);
              }

              bar.points.push({ value: i });
            });
          });

          const ch = new chartGraphLinearHorizontal({
            data: data,
            container: container[0],
            options: {},
          });
        }
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          data: "=",
        },
        templateUrl:
          "app/directives/custom-infographics-directives/chart-trends.html",
      };

      return directive;
    },
  ]);
})();
