angular.module("app").directive("topicModalDetail", () => {
  let link = (scope) => {
    scope.closeClickHandler = () => {
      scope.closefunction();
    };

    scope.getSelectedTopic = (topic) => {
      scope.getTopic(topic);
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/components/reports/topicModalView.html",
    link: link,
    scope: {
      onClose: "&",
      closefunction: "=",
      topicData: "=",
      getTopic: "=",
    },
  };
});
