angular
  .module("app")
  .service("homeColorsService",
    function (httpService, appConfig, authService) {
      const parseQuery = (filters, page) => {
        const items = Object.keys(filters);
        const query = new URLSearchParams();
        items.forEach((key) => {
          query.append(`q[${key}_eq]`, filters[key]);
        });
        if (page) {
          query.append("page", page);
        }
        return query.toString();
      };

      this.getColorsList = () => {
        return httpService
            .get(`${appConfig.dashboardServiceUrl}api/home/search/colors`, {
                params: { token: authService.token },
            }) .then((res) => {
              if (res) {
                return res.data;
              }
            });
      };
  
      this.getCategoriesList = () => {
        return httpService
            .get(`${appConfig.dashboardServiceUrl}api/home/search/categories`, {
                params: { token: authService.token },
            }) .then((res) => {
              if (res) {
                return res.data;
              }
            });
      };

      this.getStyleList = () => {
        return httpService
            .get(`${appConfig.dashboardServiceUrl}api/home/search/styles`, {
                params: { token: authService.token },
            }) .then((res) => {
              if (res) {
                return res.data;
              }
            });
      };
  
      this.getSubCategoriesList = (str) => {
        str = str.split(' ').join('+');
        return httpService
            .get(`${appConfig.dashboardServiceUrl}api/home/search/sub_categories?letter=`+ str, {
                params: { token: authService.token },
            }) .then((res) => {
              if (res) {
                return res.data;
              }
            });
      };

      this.getProductImage = (type, params, page, per_page, letter) => {
        let url;
        if (type && type === "color") {
          if (letter === 'ALL') {
            url = `api/home/dashboard?color_id=${params.id}&page=${page}&per_page=${per_page}&category=${letter.toLowerCase()}`;
          } else {
            url = `api/home/dashboard?color_id=${params.id}&page=${page}&per_page=${per_page}&category=${letter}`;
          }
        } else if (type && type === 'category') {
          url = `api/home/dashboard?category=${params.id}&page=${page}&per_page=${per_page}`;
        } else if (type && type === 'subCategory') {
          if (letter === 'ALL') {
            url = `api/home/dashboard?sub_category=${params.title}&page=${page}&per_page=${per_page}&category=${letter.toLowerCase()}`;
          } else {
            url = `api/home/dashboard?sub_category=${params.title}&page=${page}&per_page=${per_page}&category=${letter}`;
          }
        } else if (type && type === 'style') {
          if (letter === 'ALL') {
            url = `api/home/dashboard?style=${params.title}&page=${page}&per_page=${per_page}&category=${letter.toLowerCase()}`;
          } else {
            url = `api/home/dashboard?style=${params.title}&page=${page}&per_page=${per_page}&category=${letter}`;
          }
        }
        return httpService.get(appConfig.dashboardServiceUrl + url,
          authService.getHeaderConfig()
        )
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      };

      // category color data API 
      this.getCategoriesChartsDataByCategory = (id,node) => { 
        return httpService.get(appConfig.dashboardServiceUrl + "api/home/dashboard/categories?category=" + id,{
                params: { token: authService.token, node: node }})
            .then(function (data) {
              return (data.data);
            });
      };

      this.getCategoriesChartsData3ByCategory = (id,node,letter) => { 
        return httpService.get(appConfig.dashboardServiceUrl + "api/home/dashboard/categories?category=" + id,{
                params: { token: authService.token, node: node, letter: letter.toLowerCase() }})
            .then(function (data) {
              return (data.data);
            });
      };

      // color color data API 
      this.getColorChartsDataByColorId = (id,node) => { 
        return httpService.get(appConfig.dashboardServiceUrl + "api/home/dashboard/colors?color_id=" + id,{
                params: { token: authService.token, node: node }})
            .then(function (data) {
              return (data.data);
            });
      };

      this.getColorChartsData3ByColorId = (id,node,letter) => { 
        return httpService.get(appConfig.dashboardServiceUrl + "api/home/dashboard/colors?color_id=" + id,{
                params: { token: authService.token, node: node, category: letter === 'ALL' ? letter.toLowerCase() : letter }})
            .then(function (data) {
              return (data.data);
            });
      };

      this.getColorChartsData4ByColorId = (id,node,letter) => { 
        return httpService.get(appConfig.dashboardServiceUrl + "api/home/dashboard/colors?color_id=" + id,{
                params: { token: authService.token, node: node, category: letter === 'ALL' ? letter.toLowerCase() : letter }})
            .then(function (data) {
              return (data.data);
            });
      };

      // sub category color data API 
      this.getSubCategoriesChartsDataBySubCategory = (id,node) => { 
        return httpService.get(appConfig.dashboardServiceUrl + "api/home/dashboard/sub_categories?node=" + node, {
                params: { token: authService.token, sub_category: id }})
            .then(function (data) {
              return (data.data);
            });
      };

      this.getSubCategoriesChartsDataLetterBySubCategory = (id,node,letter) => { 
        return httpService.get(appConfig.dashboardServiceUrl + "api/home/dashboard/sub_categories?node=" + node,{
                params: { token: authService.token, sub_category: id, letter: letter.toLowerCase() }})
            .then(function (data) {
              return (data.data);
            });
      };

      // style color data API 
      this.getStyleChartsDataByStyle = (id,node) => { 
        return httpService.get(appConfig.dashboardServiceUrl + "api/home/dashboard/styles?node=" + node, {
                params: { token: authService.token, style: id }})
            .then(function (data) {
              return (data.data);
            });
      };

      this.getStyleChartsDataLetterByStyle = (id,node,letter) => { 
        return httpService.get(appConfig.dashboardServiceUrl + "api/home/dashboard/styles?node=" + node,{
                params: { token: authService.token, style: id, category: letter }})
            .then(function (data) {
              return (data.data);
            });
      };

      this.fetchCategoryForHomeColors = function (filters) {
        const params = parseQuery(filters);
        return httpService
          .get(
            appConfig.dashboardServiceUrl + "/api/home/settings.json?" + params,
            authService.getHeaderConfig()
          )
          .then((res) => res.data);
      };

      this.fetchProductsByCategory = function (filters, page) {
        const params = parseQuery(filters, page);
        return httpService
          .get(
            appConfig.dashboardServiceUrl + "/api/home/products.json?" + params,
            authService.getHeaderConfig()
          )
          .then((res) => res.data);
      };

      this.getSettings = () => {
        return httpService
          .get(appConfig.dashboardServiceUrl + "api/home/settings.json", authService.getHeaderConfig())
          .then((res) => {
            if (res) {
              return res.data;
            }
          });
      };

      this.getProduct = (type, params, page, per_page) => {
        let url;
          if (type && type === "color"){
            url = `api/home/products.json?q[color_eq]=${params.title}&page=${page}&per_page=${per_page}`;
          } else if (type && type === 'category') {
            url = `api/home/products.json?q[category_eq]=${params.title}&page=${page}&per_page=${per_page}`;
          } else if (type && type === 'subCategory') {
            url = `api/home/products.json?q[sub_category_eq]=${params.title}&page=${page}&per_page=${per_page}`;
          } else if (type && type === 'style') {
            url = `api/home/products.json?q[style_eq]=${params.title}&page=${page}&per_page=${per_page}`;
          }
          return httpService.get(appConfig.dashboardServiceUrl + url,
            authService.getHeaderConfig()
          )
          .then(function (data) {
            return data.data;
          });
      };
    }
  );
