angular.module("app").service("pulseDashboardRepository", [
  "appConfig",
  "$q",
  "authService",
  "httpService",
  function (appConfig, $q, authService, httpService) {
    this.getDailyViewTrackerData = (date, sources, sentiments, period = '1m') => { 
      let query = '';
      if (sources) {
        query += `?sources=${sources}&`;
      }
      else {
        query += '?';
      }
      query += `daily_view_date=${date}`;
      query += '&modules=colorSentimentMonthData,sentimentMonthColorData';
      query += `&period=${period}`;
      if (sentiments) {
        query += `&sentiments=${sentiments}`;
      }

      return httpService
        .get(
          `${appConfig.dashboardServiceUrl}api/tracker/posts.json${query}`,
          authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };
  },
]);
