(function () {
  "use strict";
  
  angular.module("app").directive("hueChartColorsUniqueWithLevelsGame", [
    "common",
    "config",
    "chartsHelper",
    "reduceValue",
    function (common, config, chartsHelper, reduceValue) {
      function link(scope, element, attributes) {
        scope.$watch("data", (newValue, oldValue) => {
          if (newValue) {
            bindData();
          }
        });

        function bindData() {
          if (!scope.data) {
            return;
          }

          const containerGroups = element
            .find('[chart-type="groups"]')
            .html("");

          const options = {};
          options.layout = {
            sections: {
              horizontal: 7,
              vertical: 2,
            },
          };
          options.data = options.data || {};
          if (!scope.data.grouped) {
            options.layout = {
              sections: {
                horizontal: 1,
                vertical: 1,
              },
            };
          }
          scope.RBGData = scope.data.unique;

          const colors = _.map(scope.data.groups, function (d) {
            const value = Math.round(d.prcentage);
            return {
              value: reduceValue.reduce(d.count),
              valueTitle: Math.round(value) + "%",
              value2: reduceValue.reduce(d.count),
              valueTitle2: Math.round(d.prcentage * 100),
              title: d.name,
              color: d.hex,
            };
          });

          let ch = new chartDotsHorizontal({
            data: colors,
            container: containerGroups[0],
            options: {},
          });
        }
        const windowResizeHandler = _.debounce(bindData, 200);
        scope.$on("$destroy", function () {
          $(window).off("resize", windowResizeHandler);
        });
        $(window).resize(windowResizeHandler);
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          data: "=",
          board: "=",
        },
        templateUrl:
          "app/directives/custom-infographics-directives/chart-colors-unique-with-levels-game.html",
      };

      return directive;
    },
  ]);
})();
