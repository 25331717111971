(function () {
  'use strict';

  angular.module('app').directive('hueChartCommonWithGroups',
    [
      'common', 'config', 'chartsHelper', 'colorSortService',
      function (common, config, chartsHelper, colorSortService) {
        function link(scope, element, attributes) {
          scope.$watch("data", (newValue) => {
            if (newValue) {
              bindData();
            }
          });

          function bindData() {

            let ch;
            if (!scope.data) {
              return;
            }

            if (scope.data['chart']) {
              scope.palettes = _.chunk(scope.data.chart, 5);
            }

            const containerBagel = element.find('[chart-type="bagel"]').html('');
            const containerBrief = element.find('[chart-type="brief"]').html('');
            const containerGroups = element.find('[chart-type="groups"]').html('');

            const groups = _.map(scope.data.colors, function (c) {
              return {
                value: c.count,
                valueTitle: c.percentage,
                value2: c.count,
                valueTitle2: c.percentage.replace('%',''),
                title: c.name,
                color: c.color
              }
            });

            const colors = _.map(scope.data.top_4, function (c) {
              return {
                value: c.count,
                valueTitle: c.percentage,
                value2: c.count,
                valueTitle2: c.percentage.replace('%',''),
                title: c.family,
                color: c.rgb
              }
            });

            const bagelOptions = {
              bars: {
                radius: 130,
                radiusOuter: 1,
                radiusInner: 105,
                legend: {
                  radius: 50,
                  radiusInner: 40,
                  position: 'none'
                }
              }
            };

            if (!scope.mode || !scope.mode.extraView) {
              ch = new chartBoxBagelGroup({
                data: colors,
                filterData: scope.filterData,
                container: containerBagel[0],
                options: bagelOptions
              });

              // ch = new chartDotsHorizontalInfo({
              //   data: colors,
              //   container: containerBrief[0],
              //   options: {
              //     layout: {
              //       bars: {
              //         separator: {
              //           radius: 7
              //         }
              //       }
              //     }
              //   }
              // });

            }
            ch = new chartBoxLinearHorizontal({
              data: groups,
              container: containerGroups[0],
              options: {
                layout: {
                  bars: {
                    separator: {
                      radius: 6
                    }
                  }
                }
              }
            });
          }
        }

        const directive = {
          link: link,
          restrict: 'E',
          replace: true,
          scope: {
            data: '=',
            mode: '=',
            filterData: "="
          },
          templateUrl: 'app/directives/custom-infographics-directives/chart-common-with-groups.html'
        };

        return directive;

      }
    ]);
}());
