angular.module('app').directive('brandIndustryComplete', [
  '$sce',
  ($sce) => {
    let link = (scope) => {
      // dummy data
      scope.filteredChoices = scope.industries;
      scope.isTagContainsHighlight = false; 
      scope.isVisible = {
        suggestions: false,
      };

      scope.searchTitle = () => {
        if (scope.enteredtext.length < 0) {
          return;
        }
        scope.searchhandler('industry', scope.enteredtext);
      };

      scope.filterItems = () => {
        if (scope.enteredtext.length < 0) {
          return;
        }
        scope.isVisible.suggestions = Boolean(scope.enteredtext.length);
        scope.filteredChoices = scope.industries;//.filter(industry => industry.title.toLowerCase().includes(scope.enteredtext.toLowerCase()));
      };

      var stringToArray = function(input) {
        if(input)
        {
          return input.trim().match(/\S+/g); 
        }
        else
        {
          return [];
        }
      };
 
     // returns regex that basically says 'match any word that starts with one of my keywords'
     var getRegexPattern = function(keywordArray) { 
       var pattern = "(^|\\b)(" + keywordArray.join("|") + ")"; 
       return new RegExp(pattern, "gi"); 
     };

      scope.highlight = function(text, search) {
        if (!search) {
            return $sce.trustAsHtml(text);
        }
        var keywordList = stringToArray(search);
        var pattern = getRegexPattern(keywordList);
        return angular.uppercase($sce.trustAsHtml(text.replace(pattern, '<span class="highlightedText">$2</span>')));
        //return angular.uppercase($sce.trustAsHtml(text.replace(new RegExp(search, 'gi'), '<span class="highlightedText">$&</span>')));
      };

      scope.highlightLogo = function(text, search) {
        let tagValue = "";
        if (!search) {
          tagValue = $sce.trustAsHtml(text);
        }
        var keywordList = stringToArray(search);
        var pattern = getRegexPattern(keywordList);
        //tagValue = angular.uppercase($sce.trustAsHtml(text.replace(new RegExp(search, 'gi'), '<span class="highlightedText">$&</span>'))); 
        tagValue = angular.uppercase($sce.trustAsHtml(text.replace(pattern, '<span class="highlightedText">$2</span>'))); 
        if(tagValue.toString().includes('highlightedText'))
        {
          scope.isTagContainsHighlight = true; 
        } else {
          scope.isTagContainsHighlight = false; 
        } 
      };

      scope.selectItem = (choice) => {
        scope.selected = choice;
        scope.enteredtext = choice.title;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };

      scope.$watch('industries', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          scope.industries = newValue;
          scope.filterItems();
        }
      }, true);

    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbBrandIndustryComplete/dbBrandIndustryCompleteView.html",
      link: link,
      scope: {
        enteredtext: "=",
        selected: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        disabled: "=",
        disable: "=",
        board: "=",
        industries: "=",
        searchhandler: "=",
      }
    };
  }
]);
