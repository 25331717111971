(function () {
  angular.module('app').directive('hueChartNails',
    ['common', 'config', 'chartsHelper',
      function () {
        function link(scope, element) {
          scope.$watch("data", (newValue) => {
            if (newValue) {
              bindData();
            }
          });

          function bindData() {
            if (!scope.data) {
              return;
            }
            totalAchr = scope.data.achromatic_colors.percentage.replace('%','');
            totalChr = scope.data.chromatic_colors.percentage.replace('%','');
            const data = {
              groups: [{title: totalChr + '% ACHROMATIC COLORS', name: 'chr'},
                {title: totalAchr + '% CHROMATIC COLORS', name: 'achr'}]
            };
            
            _.each(scope.data.chromatic_colors.colors, function (obj) {
              obj.group = "achr";
              obj.title = obj.name;
              obj.value = obj.count;
              obj.valueTitle2 = obj.count;
              obj.valueTitle = obj.percentage;
            })
            _.each(scope.data.achromatic_colors.colors, function (obj) {
              obj.group = "chr";
              obj.title = obj.name;
              obj.value = obj.count;
              obj.valueTitle2 = obj.count;
              obj.valueTitle = obj.percentage;
            })

            data.data = scope.data.chromatic_colors.colors.concat(scope.data.achromatic_colors.colors);

            const container = element.find('[chart-type]').html('');

            const ch = new chartNailsLinearVerticalSimple({
              data: data,
              container: container[0],
              options: {}
            });
          }
        }

        const directive = {
          link: link,
          restrict: 'E',
          replace: true,
          scope: {
            data: '='
          },
          templateUrl: 'app/directives/custom-infographics-directives/chart-nails.html'
        };

        return directive;
      }
    ]);
}());
