angular.module("app").factory("colorRequest",
  function (httpService, appConfig) {
    const url = appConfig.dashboardServiceUrl + "api/color_names.json";

    return {
      getShortNames: function (shortName, page) {
        return httpService
          .get(url + `?q[name_cont]=${shortName}&page=${page}&per_page=${60}`)
          .then(function (res) {
            return res.data;
          });
      },
      getRgb: function (shortName, page) {
        return httpService
          .get(url + `?q[name_cont]=${shortName}&page=${page}&per_page=${60}`)
          .then(function (res) {
            return res.data;
          });
      },
    };
  }
);
