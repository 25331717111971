angular.module("app").directive("actualPharmaColorsForYear", [
  "$timeout",
  "$window",
  (timeout, window) => {
    let link = (scope, element) => {
      scope.data = [];
      scope.showCollection = false;
      scope.showCompanyDetails = false;
      scope.imageDetailsData = null;
      window.scr = scope;
      scope.collectionDataList = [];

      scope.itemHeight = () => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        return (scope.elemHeight =
          elements[0].clientWidth && elements[0].clientWidth + "px");
      };

      scope.itemHeight();

      $(window).resize(() => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        timeout(() => {
          return (scope.elemHeight = elements[0].clientWidth + "px");
        }, 10);
      });

      scope.toggleCompanyDetails = () => {
        scope.showCompanyDetails = !scope.showCompanyDetails;
      };

      scope.companyClickHandler = (company, index) => {
        scope.imagedata = scope.yearColorData[index];
        scope.index = index;
        scope.toggleCompanyDetails();
      };
    };

    return {
      restrict: "E",
      templateUrl:  "app/directives/dbActualPharmaColorsForYear/dbActualPharmaColorsForYearView.html",
      link: link,
      scope: {
        yearColorData: "=",
        therapeuticAreas: "="
      }
    };
}]);
