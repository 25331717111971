(function () {
  'use strict';
    const serviceId = 'repo.common';
    angular
    .module('app')
    .service(serviceId,
      [
        'data', 'common',
        function (dataContext, common) {

        const context = dataContext.context({
            controller: 'common'
        });

        const $q = common.$q;

        function getTestData(dataRequest) {
          const def = $q.defer();
          def.resolve({data: dataRequest || 'ok'});
          return def.promise;
          return context.get('titles', {s: search, t: type})
            .then(function (result) {
              return result.data;
            });
      }
          this.testData = getTestData;
        }
      ]);
}());
