angular.module("app").directive("globalColorSelection", () => {
  let link = (scope) => {
    scope.selectedColors = [];
    scope.defaultColors = ['pink', 'green', 'magenta'];
    scope.isSelectColor = (id) => {
      if (scope.selectedColors.indexOf(id) !== -1) {
        return true;
      }

      return false;
    };

    scope.colorSelect = (id, onlyPush = false) => {
      if (onlyPush) {
        //Don't remove colors from selected colors
        scope.selectedColors.push(id);
      }
      else {
        if (!scope.isSelectColor(id)) {
          scope.selectedColors.push(id);
        }
        else {
          scope.selectedColors = scope.selectedColors.filter(item => item !== id);
        }
      }

      const colors = scope.colors.filter(item => {
        if (scope.selectedColors.indexOf(item.id) !== -1) {
          return true;
        }

        return false;
      });

      scope.setColors(colors);
    };

    scope.colors.forEach((item) => {
      if (scope.defaultColors.includes(item.id)) {
        scope.colorSelect(item.id, true);
      }
      // if (scope.selectedColors.length < 5) {
      //   scope.colorSelect(item.id);
      // }
    });

    scope.$watch('colors', (colors) => {
      if (colors) {
        colors.forEach((item) => {
          if (scope.defaultColors.includes(item.id)) {
            scope.colorSelect(item.id, true);
          }
          // if (scope.selectedColors.length < 5) {
          //   scope.colorSelect(item.id);
          // }
        });
      }
    });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbGlobalColorData/globalColorTracker/globalColorSelection/globalColorSelectionView.html",
    link: link,
    scope: {
      colors: "=",
      setColors: "=",
    }
  };
});
