function chartBoxBagelGroup(settings) {
  const self = this;
  self.name = 'bagel';

  chartBase(self);
  initialize();

  self.settings = settings;
  self.container = settings.container;
  self.data = settings.data;
  self.filterData = settings.filterData;

  const _opts = self.mergeDefaults(settings.options);
  let _charts = null;

  function initialize() {
    self.getDefaults = function () {
      const d = {
        layout: {
          bars: {
            title: {
              width: 50
            },
            separator: {
              width: 20,
              radius: 4
            },
            bar: {
              width: 200,
              height: 20,
              margin: {
                top: 3,
                bottom: 3
              },
              background: '#ccc'
            },
            value: {
              width: 40
            },
            value2: {
              width: 40
            }
          }
        },
        axis: {},
        bars: {
          radius: 50,
          radiusInner: 40,
          legend: {
            position: 'right'
          }
        }
      };

      d.layout.padding = {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      };
      return d;
    };
  }

  function prepareContainer() {
    self.initializeLayout(_opts);

    self.x = self.w / 2;
    self.y = self.h / 2;

    self._c.attr('transform', String.format('translate({0},{1})', self.x, self.y));

    self._gr = self._svg.append('g');
    self._t0 = self._svg.append('g').attr('transform', String.format('translate({0},{1})', self.x, self.y));
    self._t = self._svg.append('g').attr('transform', String.format('translate({0},{1})', self.x, self.y));

    self._empty = self._svg.append('g').attr('transform', String.format('translate({0},{1})', self.x, self.y))
      .style('display', 'none');

    self.xScale = d3.scale.ordinal()
      .domain(d3.range(0, 1))
      .rangeBands([0, 360]);

    // grid
    self._gr
      .attr('stroke-width', '1')
      .attr({fill: 'none'})
      .attr({stroke: '#e5e5e5'})
      .attr('transform', String.format('translate({0},{1})', self.x, self.y));
  }

  function getPathRaw(percentageFrom, percentage, radius, radiusIn, isAlternate) {
    const size = radius * 2;
    const sizeIn = radiusIn * 2;
    const k = radius;

    const unit = (Math.PI * 2);
    const startangle = percentageFrom * unit + 0.0001;
    const endangle = percentage * unit - 0.0001;
    const x1 = (size / 2) * Math.sin(startangle);
    const y1 = -(size / 2) * Math.cos(startangle);
    const x2 = (size / 2) * Math.sin(endangle);
    const y2 = -(size / 2) * Math.cos(endangle);
    let big = 0;
    if (Math.abs(endangle - startangle) > Math.PI) {
      big = 1;
    }

    const x2In = (sizeIn / 2) * Math.sin(endangle);
    const y2In = -(sizeIn / 2) * Math.cos(endangle);

    const d = // "M 0,0" +  // Start at circle center
      (isAlternate ? '' : ' M ' + (x1) + ',' + (y1)) +     // Draw line to (x1,y1)
      ' A ' + (size / 2) + ',' + (size / 2) +       // Draw an arc of radius r
      (isAlternate ? (big === 1 ? ' 1 1 0 ' : ' 0 0 0 ') : ' 0 ' + big + ' 1 ') +       // Arc details...
      (x2) + ',' + (y2) +             // Arc goes to to (x2,y2)

      String.format(' A {0},{1} ' +
        (isAlternate ? '0 0 0 ' : '0 0 1 ') +
        '{2},{3} ', (radius - radiusIn) / 2, (radius - radiusIn) / 2, x2In, y2In) +     // Draw line to (x1,y1)
      (isAlternate ? ' Z' : '');                       // Close path back to (cx,cy)

    return d;
  }

  function getCenterPoint(percentageFrom, percentageTo, radius, radiusInner) {
    const size = (radius - (radius - radiusInner) / 2);

    const unit = (Math.PI * 2);
    const endangle = (percentageFrom + ((percentageTo - percentageFrom) / 2)) * unit - 0.001;

    const x2 = (size) * Math.sin(endangle);
    const y2 = -(size) * Math.cos(endangle);

    return {x: x2, y: y2};
  }

  function getPathByPercentage(percentageFrom, percentage, radius, radiusInner) {
    const pathOuter = getPathRaw(percentageFrom, percentage, radius, radiusInner);
    const pathInner = getPathRaw(percentage, percentageFrom, radiusInner, radius, true);

    return pathOuter + ' ' + pathInner;
  }

  function getColorByPercentage(percentage, itemIndex) {
    if (percentage === 0) {
      return '#ECECEC';
    }
    const colors = self.isArray(_opts.bars.color) ? _opts.bars.color : [_opts.bars.color || null];
    return colors[itemIndex];
  }

  function prepareData() {
    const data = _.map(self.data, function (el) {
      return el.value;
    });
    const sum = _.reduce(data, function (memo, num) {
      return memo + num;
    }, 0);

    const sumLeft = sum;
    let prev = 0;
    let prevElement = null;
    _.each(self.data, function (el) {
      const test_data = {from: prev, to: 0, percent: 0};
      const _data = {from: 0, to: prev, percent: 0};
      _data._prev = prevElement;
      prevElement = _data;

      if (sum === 0) {
        return;
      }
      const percent = el.value / sum;
      prev += 0.25;
      _data.to = prev;
      test_data.to = prev;
      _data.percent += 0.25;

      el.test_data = test_data;
      el._data = _data;
    });
  }

  function bindData() {
    const charts = self._c.selectAll('g').data(self.data)
      .enter().append('g')
      .each(function (d, i) {
        const g0 = d3.select(this);
        g0.attr('data-eltype', 'block');

        const g = g0.append('g');
        g.attr('data-eltype', 'bar');

        const col1 = d.color;

        const path1 = g.append('path');
        path1.attr('d', getPathByPercentage(d._data.from, d._data.from, _opts.bars.radius, _opts.bars.radiusInner))
          .attr('fill-rule', 'evenodd')
          .attr('isvalue', '1')
          .style({
            fill: 'rgb(' + col1 + ')',
            cursor: 'pointer',
            stroke: self.isWhiteRGB(col1) ? '#ccc' : null
          });

        let designer = self.filterData.designer.id === 'all' ? '' : ' / ' + self.filterData.designer.title;
        let category = self.filterData.category.id === 'all' ? '' : ' / ' + self.filterData.category.title;
        let season = self.filterData.season.id === 'all' ? '' : ' / ' + self.filterData.season.title;
        let year = self.filterData.year.id === 'all' ? '' : ' / ' + self.filterData.year.title;
        let disText = designer + category;
        let disText1 = season + year;

        if(disText) {
          g.append('text')
            .text('')
            .attr('text-anchor', 'middle')
            .attr('transform', String.format('translate({0},{1})', 0, 0)) // -10,0  //-20,70 // 0, 0
            .attr('style', 'font-family: Gotham SSm A, Gotham SSm B; font-size: 14px; text-transform: uppercase;');

          g.append('text')
            .text('TOP 4 COLORS FOR')
            .attr('text-anchor', 'middle')
            .attr('transform', String.format('translate({0},{1})', 0, -30)) // -10,0  //-20,70 // 0, 0
            .attr('style', 'font-family: Gotham SSm A, Gotham SSm B; font-size: 14px; text-transform: uppercase;');

          g.append('text')
            .text(designer)
            .attr('text-anchor', 'middle')
            .attr('transform', String.format('translate({0},{1})', 0, -10)) // -10,0  //-20,70 // 0, 0
            .attr('style', 'font-family: Gotham SSm A, Gotham SSm B; font-size: 14px; text-transform: uppercase;');
          
          g.append('text')
            .text(category)
            .attr('text-anchor', 'middle')
            .attr('transform', String.format('translate({0},{1})', 0, 10)) // -10,0  //-20,70 // 0, 0
            .attr('style', 'font-family: Gotham SSm A, Gotham SSm B; font-size: 14px; text-transform: uppercase;');

          g.append('text')
            .text(disText1)
            .attr('text-anchor', 'middle')
            .attr('transform', String.format('translate({0},{1})', 0, 30)) // -10,0  //-20,70 // 0, 0
            .attr('style', 'font-family: Gotham SSm A, Gotham SSm B; font-size: 14px; text-transform: uppercase;');
        } else {
          g.append('text')
            .text('TOP 4 COLORS FOR')
            .attr('text-anchor', 'middle')
            .attr('transform', String.format('translate({0},{1})', 0, 0)) // -10,0  //-20,70 // 0, 0
            .attr('style', 'font-family: Gotham SSm A, Gotham SSm B; font-size: 14px; text-transform: uppercase;');

          g.append('text')
            .text('')
            .attr('text-anchor', 'middle')
            .attr('transform', String.format('translate({0},{1})', 0, -30)) // -10,0  //-20,70 // 0, 0
            .attr('style', 'font-family: Gotham SSm A, Gotham SSm B; font-size: 14px; text-transform: uppercase;');

          g.append('text')
            .text('')
            .attr('text-anchor', 'middle')
            .attr('transform', String.format('translate({0},{1})', 0, -10)) // -10,0  //-20,70 // 0, 0
            .attr('style', 'font-family: Gotham SSm A, Gotham SSm B; font-size: 14px; text-transform: uppercase;');
          g.append('text')
            .text('')
            .attr('text-anchor', 'middle')
            .attr('transform', String.format('translate({0},{1})', 0, 10)) // -10,0  //-20,70 // 0, 0
            .attr('style', 'font-family: Gotham SSm A, Gotham SSm B; font-size: 14px; text-transform: uppercase;');

          g.append('text')
            .text('')
            .attr('text-anchor', 'middle')
            .attr('transform', String.format('translate({0},{1})', 0, 30)) // -10,0  //-20,70 // 0, 0
            .attr('style', 'font-family: Gotham SSm A, Gotham SSm B; font-size: 14px; text-transform: uppercase;');
        }

        const textPoint = {
          center: getCenterPoint(d.test_data.from, d.test_data.to, _opts.bars.radius, _opts.bars.radiusInner),
          width: 0,
          height: 0
        };

        let lbl = null;
        if (d._data.percent >= 0.1) {
          const colorPercent = d.percentage ? Math.round(d.percentage * 100) + '%' : d.valueTitle2 + '%';
          lbl = g.append('text')
            .attr('text-anchor', 'center')
            .attr('fill', '#000')
            .attr('stroke', null)
            .text('R ' + col1.split(',')[0].trim() + '\n' + 'G ' + col1.split(',')[1].trim() + '\n' + 'B ' + col1.split(',')[2].trim());

          const titleLine = g.append('line')
            .style({stroke: '#000', 'stroke-width': 1});

          const box = lbl.node().getBBox();
          textPoint.width = box.width;
          textPoint.height = box.height;
          const titlePadding = _opts.bars.radius - _opts.bars.radiusInner + 70;
          let x = textPoint.center.x - box.width / 2;
          let y = textPoint.center.y + box.height / 2;
          let x1TitleLine = x;
          let x2TitleLine = x;

          if (d.test_data.from < 0.25) {
            x += titlePadding;
            x1TitleLine = 102;
            x2TitleLine = 125;
          } else if (d.test_data.from >= 0.25 && d.test_data.from < 0.5) {
            x += titlePadding;
            y -= 10;
            x1TitleLine = 125;
            x2TitleLine = 102;
          } else if (d.test_data.from >= 0.5 && d.test_data.from < 0.75) {
            x -= titlePadding;
            y -= 15;
            x1TitleLine = -125;
            x2TitleLine = -106;
          } else {
            x -= titlePadding;
            x1TitleLine = -125;
            x2TitleLine = -104;
          }
          const y1TitleLine = y - 5;

          titleLine.attr('x1', x1TitleLine);
          titleLine.attr('x2', x2TitleLine);
          titleLine.attr('y1', y1TitleLine);
          titleLine.attr('y2', y1TitleLine);

          lbl.attr('x', x);
          lbl.attr('y', y);
        }
      });

    if (_opts.bars.legend.position !== 'none') {
      const leftSection = [],
        rightSection = [];
      _.each(self.data, function (d) {
        if (_opts.bars.legend.position === 'left') {
          leftSection.splice(0, 0, {data: d});
        } else {
          rightSection.push({data: d});
        }
      });

      _.each([{isLeft: true, s: leftSection}, {isLeft: false, s: rightSection}], function (s) {
        const top = 30 + 10,
          bottom = self.h - 15 - 20,
          marginLeft = 10,
          marginRight = 10;

        const dy = (bottom - top) / (s.s.length <= 1 ? 1 : s.s.length - 1);
        let firstCirclePoint = null;
        let lastCirclePoint = null;
        _.each(s.s, function (el, elIndex) {
          const indexCorrected = (s.s.length == 1 && (s.isLeft && el.percentCenter >= 0.75 || !s.isLeft && el.percentCenter > 0.25) ? 1 : elIndex);
          el.y = s.isLeft ? bottom - indexCorrected * dy : top + indexCorrected * dy;

          const g0 = self._t0.append('g');

          const correction = {dy: -self.y};
          const firstPoint = {x: s.isLeft ? -self.w / 2 + 10 : self.w / 2 - 83, y: correction.dy + el.y + 7};

          lastCirclePoint = {
            x: firstPoint.x,
            y: firstPoint.y - 10
          };

          if (firstCirclePoint === null) {
            firstCirclePoint = lastCirclePoint;
          }

          const circle = g0.append('circle')
            .attr('cx', lastCirclePoint.x)
            .attr('cy', lastCirclePoint.y)
            .attr('r', _opts.bars.separator.radius)
            .style({fill: el.data.color, stroke: self.isWhite(el.data.color) ? '#ccc' : null});

          const lbl = g0.append('text')
            .text(el.data.valueTitle)
            .attr('x', firstPoint.x + 12)
            .attr('y', firstPoint.y - 7)
            .attr('text-anchor', 'start') // s.isLeft ? 'start' : 'end')
            .attr('alignment-baseline', 'middle')
            .attr('class', 'title');
        });
        if (firstCirclePoint && lastCirclePoint) {
          self._t0.insert('line', ':first-child')
            .attr({
              x1: firstCirclePoint.x,
              y1: firstCirclePoint.y,
              x2: lastCirclePoint.x,
              y2: lastCirclePoint.y
            })
            .style({
              'stroke-dasharray': '3,2',
              stroke: '#ccc',
              'stroke-width': 2
            });
        }
      });
    }
    if (_opts.bars.legend.title) {
      const lblGroup = self._t0.append('g');
      const lbl = lblGroup
        .append('text')
        .text(_opts.bars.legend.title)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('class', 'legend-title');

      const box = lbl.node().getBBox();
      const boxMaxWidth = (_opts.bars.radiusInner - 5) * 2;
      if (box.width > boxMaxWidth) {
        const sentence = _opts.bars.legend.title;
        let firstWord = sentence,
          secondWord;
        let ind = sentence.lastIndexOf(' ');
        if (ind === -1) {
          ind = sentence.lastIndexOf('-');
        }
        if (ind > -1) {
          firstWord = sentence.substr(0, ind);
          secondWord = sentence.substr(ind);
        }
        lbl.text(firstWord);

        if (secondWord) {
          const lbl2 = lblGroup
            .append('text')
            .text(secondWord)
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .attr('class', 'legend-title')
            .attr('transform', self.formatTranslate(0, Number(box.height)));

          lblGroup
            .attr('transform', self.formatTranslate(0, -box.height * (1 + 0.00) / 2));
        }
      }
    }

    _charts = charts;
  }

  function animateChanges() {
    const delay = 0;
    self._c.selectAll('g[data-eltype="block"]').data(self.data);
    d3.selectAll(self._c[0][0].childNodes).filter('[data-eltype="block"]')
      .each(function (d, i) {
        if (!d) {
          return;
        }

        const g0 = d3.select(this);
        const g = g0.selectAll('[data-eltype="bar"]');
        const b = g.selectAll('[isvalue="1"]');

        const duration = 1000;
        b.transition()
          .ease('cubic-out')
          .duration(duration)
          .delay(delay)
          .attrTween('d', translateDonut(_opts.bars.radius, _opts.bars.radiusInner));
      });

    function translateDonut(r, rIn) {
      return function (obj) {
        return function (t) {
          const dP = obj._data.percent * t;
          const fromPrev = obj._data._prev ? obj._data._prev.to * t : 0;
          return getPathByPercentage(obj._data.from + fromPrev, obj._data.from + fromPrev + dP, r, rIn);
        };
      };
    }
  }

  function checkAndIndicateIfDataEmpty() {
    let total = 0;
    _.each(self.data, function (d) {
      if (d._data) {
        total += d._data.percent;
      }
    });

    if (false && total < 0.01) {
      self._empty.selectAll('*').remove();
      self._empty.append('text')
        .attr('class', 'data-empty')
        .text('No relevant analytics')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle');
    }
    self._empty.style('display', total < 1 ? '' : 'none');
  }

  self.applyValues = function (data) {
    if (data.length === self.data.length) { // animating changes
      self.dataPrev = self.data;
      self.data = data;
      prepareData();
      animateChanges(function () {
        self.dataPrev = null;
      });
    } else {
      self.data = data;
      prepareContainer();
      bindData();
    }
  };

  prepareData();
  prepareContainer();

  try {
    bindData();
  } catch (e) {
  }
  try {
    animateChanges();
  } catch (e) {

  }

  checkAndIndicateIfDataEmpty();
}
