angular.module("app").component("loginComponent", {
  templateUrl: "app/components/login/login.tmpl.html",
  controller: function (authService, $state, localStorageService) {
    const self = this;
    self.email = "";
    self.password = "";
    self.isRemembered = false;
    self.isTandC = false;
    self.error = "";
    self.errorMsg = ""; 
    self.serverError = "";
    self.response = false;
    self.widgetId = "";
    const products = { courses: {}, reports: {}, teaching_materials: {} };
    localStorageService.set("products", products);

    self.model = {
      key: "6LfDJqwUAAAAACHIqavpN1wTmv8xwZImTU79S2I6",
    };

    self.login = () => {
      self.error = false;
      if(self.email && self.password) {
        if(!self.isTandC) {
          self.error = true;
          self.errorMsg = "YOU MUST READ AND ACCEPT THE TERMS AND CONDITIONS"
        } else {
          authService.login(self.email, self.password, self.isTandC, self.isRemembered)
          .then(function (data) {
            if (data && data.success === true) {
              $state.go("landing-page");
            } else {
              self.error = true;
              self.errorMsg = data.message;
              // self.errorMsg = 'THE EMAIL OR PASSWORD YOU ENTERED IS INCORRECT'
            }
          });
        }
      } else {
        self.error = true;
        self.errorMsg = "PLEASE ENTER EMAIL AND PASSWORD"
      }
    };

    self.setResponse = (res) => {
      self.response = res;
    };

    self.setWidgetId = (widgetId) => {
      self.widgetId = widgetId;
    };
  },
});
