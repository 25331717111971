angular.module("app").directive("fashionComparativeView", ["$sce", ($sce) => {
  let link = (scope) => {
    scope.widthDynamic = percent => {
      if (percent == 0){
        return 0
      } else if(percent > 0 && percent <= 10){
        return 20;
      } else if(percent > 10 && percent <= 20){
        return 40;
      } else if(percent > 20&& percent <= 30){
        return 60;
      } else if(percent > 30&& percent <= 40){
        return 80;
      } else if(percent > 40){
        return 80;
      }
    }
    scope.img = (svg) => {
      if(svg){
        const output = [svg.slice(0, 4), ` width='60' height='60'`, svg.slice(4)].join('');
        return $sce.trustAsHtml(output);
      }
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbFashionComparative/dbFashionComparativeView.html",
    link: link,
    scope: {
      selectedColor: "=",
      data: "="
    }
  };
}]);
