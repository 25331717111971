angular.module("app").directive("hueDbColorsByFamily", function ($timeout) {
  function link(scope, element) {
    const drawChart = function () {
      let i;
      draw.clear();

      if (scope.chartData == null) return;

      const graphContainer = $(".graph", element);
      const width = graphContainer.width() - chartXOffset - chartXOffset;
      const height = graphContainer.height() - 6 - chartYOffset;
      const chartXMax = graphContainer.width() - chartXOffset;
      const chartYMax = graphContainer.height() - chartYOffset;

      const graphYearStart = new Date().getFullYear() - scope.yearRange;
      for (i = 0; i <= scope.yearRange; i++) {
        const x = Math.round(width * (i / scope.yearRange) + chartXOffset);
        if (i % 2) {
          draw
            .line(x, 3, x, chartYMax + 10)
            .stroke({ width: 1, color: "#ccc" });
          draw
            .text((graphYearStart + i).toString())
            .fill("#000")
            .font(fontX)
            .cx(x)
            .cy(chartYMax + 18);
        } else {
          draw.line(x, 3, x, chartYMax).stroke({ width: 1, color: "#ccc" });
          draw
            .text((graphYearStart + i).toString())
            .fill("#000")
            .font(fontX)
            .cx(x)
            .cy(chartYMax + 10);
        }
      }

      const itemCountRange = scope.chartMaxValue - scope.chartMinValue;
      for (i = 0; i <= itemCountRange; i += chartYAxisStep) {
        const y = Math.round(height * (i / itemCountRange) + 3);
        draw
          .line(chartXOffset, y, chartXMax, y)
          .stroke({ width: 1, color: "#ccc" });

        if (i === 0)
          draw
            .text((scope.chartMaxValue - i).toString())
            .fill("#000")
            .font(fontX)
            .cx(chartXOffset - 15)
            .cy(y + 5);
        else if (i === itemCountRange)
          draw
            .text((scope.chartMaxValue - i).toString())
            .fill("#000")
            .font(fontX)
            .cx(chartXOffset - 15)
            .cy(y - 5);
        else
          draw
            .text((scope.chartMaxValue - i).toString())
            .fill("#000")
            .font(fontX)
            .cx(chartXOffset - 15)
            .cy(y);
      }

      _.each(scope.chartData, function (line, index) {
        if (!scope.visibleColors[index]) return;

        const x = line.x;
        const y = line.y;
        const count = x.length;
        const items = [];

        for (let i = 0; i < count; i++)
          items.push([
            Math.round(width * x[i] + chartXOffset),
            Math.round(height * y[i] + 3),
          ]);

        draw
          .polyline(items)
          .fill("none")
          .style({
            "stroke-dasharray":
              line.color === "#F5F5DC"
                ? "20 5"
                : line.color === "#FFFFFF"
                ? "10 10"
                : "",
          })
          .stroke({
            color:
              line.color === "#F5F5DC"
                ? "black"
                : line.color === "#FFFFFF"
                ? "black"
                : line.color,
            width: 2,
          });
      });
    };
    const chartYOffset = 30;
    const chartXOffset = 30;
    let chartYAxisStep = 5;
    const fontX = {
      family: 'Gotham Cond SSm A","Gotham Cond SSm B',
      size: 15,
      weight: 600,
    };

    scope.colorBarItemHeight = "0%";
    scope.chartData = null;
    scope.chartMinValue = 0;
    scope.chartMaxValue = 10;
    scope.visibleColors = [];

    scope.showAllLines = function () {
      const count = scope.data.length;
      for (let i = 0; i < count; i++) scope.visibleColors[i] = true;
      drawChart();
    };

    scope.toggleLine = function (index) {
      scope.visibleColors[index] = scope.visibleColors[index] ? false : true;
      drawChart();
    };

    const draw = SVG($(".graph", element)[0]);

    const parseData = function (data) {
      let minItemsCount = null;
      let maxItemsCount = 0;
      _.each(data, function (line) {
        const items = line.items;
        const ic = items.length;
        for (let i = 0; i < ic; i++) {
          if (minItemsCount == null || minItemsCount > items[i][1])
            minItemsCount = items[i][1];
          if (maxItemsCount < items[i][1]) maxItemsCount = items[i][1];
        }
      });
      chartYAxisStep =
        maxItemsCount - minItemsCount + 1 <= 5
          ? 1
          : Math.round((maxItemsCount - minItemsCount + 1) / 5);

      minItemsCount =
        Math.floor(minItemsCount / chartYAxisStep) * chartYAxisStep;
      maxItemsCount =
        Math.ceil(maxItemsCount / chartYAxisStep) * chartYAxisStep;
      scope.chartMinValue = minItemsCount;
      scope.chartMaxValue = maxItemsCount;

      const currentDate = new Date();
      const graphXStart = currentDate.getFullYear() - scope.yearRange;
      const graphXEnd = currentDate.getFullYear();
      const graphXLength = scope.yearRange;
      const graphYLength = maxItemsCount - minItemsCount;

      scope.chartData = _.map(data, function (line) {
        const items = line.items;
        const result = {
          color: line.color.color.hex,
          title: line.color.title,
          x: [],
          y: [],
        };
        const x = result.x;
        const y = result.y;
        const count = items.length;

        if (
          !_.some(items, function (i) {
            return i[0] == graphXStart;
          }) &&
          _.some(items, function (i) {
            return i[0] > graphXStart;
          })
        ) {
          // if there is no zero point and there are visible points
          const itemsBeforeXMin = _.filter(items, function (i) {
            return i[0] < graphXStart;
          });
          if (
            itemsBeforeXMin.length &&
            _.some(items, function (i) {
              return i[0] > graphXStart;
            })
          ) {
            // find Y at zero point
            const p1 = _.max(itemsBeforeXMin, function (i) {
              return i[0];
            }); // find closest points to zero X
            const p2 = _.find(items, function (i) {
              return i[0] > graphXStart;
            });
            const slope = (p2[1] - p1[1]) / (p2[0] - p1[0]);
            const b = -(slope * p1[0] - p1[1]);

            x.push(0);
            y.push(
              1 - (slope * graphXStart + b - minItemsCount) / graphYLength
            );
          }
        }

        for (let i = 0; i < count; i++) {
          const year = items[i][0];
          if (year < graphXStart || year > graphXEnd) continue;
          x.push((year - graphXStart) / graphXLength);
          y.push(1 - (items[i][1] - minItemsCount) / graphYLength);
        }

        return result;
      });

      scope.colorBarItemHeight = 100 / data.length + "%";
      drawChart();
    };

    scope.$watch("data", function (newValue, oldValue) {
      let i;
      if (newValue && newValue.length) {
        scope.visibleColors = [];

        const count = newValue.length;
        if (scope.defaultColorId != null && scope.defaultColorId != undefined) {
          const id = _.isNumber(scope.defaultColorId)
            ? scope.defaultColorId
            : parseInt(scope.defaultColorId);
          for (i = 0; i < count; i++)
            scope.visibleColors[i] = newValue[i].color.id === id;
        } else for (i = 0; i < count; i++) scope.visibleColors[i] = true;

        $timeout(function () {
          parseData(newValue);
        }, 300);
      }
    });

    scope.$watch("yearRange", function (newValue, oldValue) {
      if (scope.data && scope.data.length)
        $timeout(function () {
          parseData(scope.data);
        }, 0);
    });

    const windowResizeHandler = _.debounce(drawChart, 200);
    scope.$on("$destroy", function () {
      $(window).off("resize", windowResizeHandler);
    });
    $(window).resize(windowResizeHandler);
  }

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorsByFamily/dbColorsByFamilyView.html",
    link: link,
    scope: {
      data: "=",
      yearRange: "=",
      defaultColorId: "=",
    },
  };
});
