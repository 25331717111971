angular.module('app').directive('fashionCategoryComplete', [
  "brandingDashboardRepository",
  function (
    brandingDashboardRepository
  ) { 
    function link (scope) {
      // dummy data
      // scope.filteredChoices = [
      //   {
      //     "id": 0,
      //     "title": "couture",
      //     "img_url": "/assets/img/fashion/category/couture.png"
      //   },
      //   {
      //     "id": 1,
      //     "title": "rtw",
      //     "img_url": "/assets/img/fashion/category/rtw.png"
      //   },
      //   {
      //     "id": 2,
      //     "title": "menswear",
      //     "img_url": "/assets/img/fashion/category/menswear.png"
      //   },
      // ];

      scope.filteredChoices = [];

      scope.loadFashionYears = () => {
        scope.ajaxInProgress = true;
        brandingDashboardRepository.dashboard.getFashionCategories().then(data => {  
          scope.filteredChoices= data.categories;
        }).finally(() => {
          scope.ajaxInProgress = false;
        });
      };
  
      scope.loadFashionYears();

      scope.isVisible = {
        suggestions: false,
      };

      scope.filterItems = () => {
        if (scope.enteredtext.length < 0) return;
        scope.isVisible.suggestions =
          scope.enteredtext.length === 0
            ? false
            : scope.filteredChoices.length > 0;
      };

      scope.selectItem = (choice) => {
        scope.selected = choice;
        scope.enteredtext = choice.title;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbFashionComplete/fashionCategoryComplete/fashionCategoryCompleteView.html",
      link: link,
      scope: {
        enteredtext: "=",
        selected: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        disabled: "=",
        disable: "=",
        board: "=",
      }
    };
  }
]);
