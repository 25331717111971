angular.module("app").controller("sportController", [
  "$scope",
  "dashboardOverlayService",
  "searchMenuRepository",
  "$timeout",
  "charts",
  "$state",
  "$location",
  "modalService",
  "localStorageService",
  function (
    scope,
    dashboardOverlayService,
    searchMenuRepository,
    timeout,
    charts,
    $state,
    $location,
    modalService,
    localStorageService
  ) {
    scope.menus = {
      sport: "",
      team: "",
      color: "",
    };

    scope.disabledControls = {
      sport: false,
      team: false,
      color: false,
    };

    scope.type = {
      sport: "sport",
      team: "team",
      color: "color",
    };

    if(localStorageService.get("currentUser")){
      scope.user = localStorageService.get("currentUser");
    }

    scope.params = $location.search();

    scope.$watch('params', function (params) {
      if (params.control === 'searchMenu') {
        scope.init();
      } else {
        scope.handleChangeControl(params.control, params);
      }
    });

    scope.disabledInput = (type, value) => {
      switch (type) {
        case "sport":
          scope.disableTeam = Boolean(value);
          break;
        case "team":
          scope.disableSport = Boolean(value);
          break;
      }
    };

    scope.mainParam = null;
    scope.mainParamId = null;
    scope.secondaryParams = {};
    scope.tab = 1;
    scope.currentPage = 0;

    scope.sport_place_holder = "SEARCH BY SPORT";
    scope.team_place_holder = "SEARCH BY TEAM";

    scope.showDashboard = false;
    scope.title = scope.year;
    scope.subtitle = null;

    scope.isLoadingControls = true;

    // dummy data -> colors
    scope.colors = [
      {
        id: 0,
        hex: '#e51d1c',
        title: 'red',
      },
      {
        id: 1,
        hex: '#ef7c17',
        title: 'orange',
      },
      {
        id: 2,
        hex: '#ede437',
        title: 'yellow',
      },
      {
        id: 3,
        hex: '#87af27',
        title: 'OliveDrab ',
      },
      {
        id: 4,
        hex: '#0c7c34',
        title: 'green',
      },
      {
        id: 5,
        hex: '#6dc6d5',
        title: 'SkyBlue',
      },
      {
        id: 6,
        hex: '#1a4899',
        title: 'blue',
      },
      {
        id: 7,
        hex: '#5d4394',
        title: 'indigo',
      },
      {
        id: 8,
        hex: '#a3509a',
        title: 'purple',
      },
      {
        id: 9,
        hex: '#924b14',
        title: 'brown',
      },
      {
        id: 10,
        hex: '#f2f3da',
        title: 'silver',
      },
      {
        id: 11,
        hex: '#c0c0bf',
        title: 'grey',
      },
      {
        id: 12,
        hex: '#ffffff',
        title: 'white',
      },
      {
        id: 13,
        hex: '#020303',
        title: 'black',
      },
    ];

    // dummy data -> sport colors data
    scope.sportContentData = [];
    for (let i = 0; i < 16; i++) {
      scope.sportContentData.push({
        id: i,
        name: "Juventus away jersay 2017/18",
        team: "Juventus",
        sport: "soccer",
        type: "team road uniform",
        img_url: `/assets/img/sports/uniforms/${i % 2}.png`,
        logo_url: '/assets/img/sports/logo-new.svg',
        color: {
          hex: "#fdd910",
          R: 253, G: 217, B: 16
        },
      });
    }

    scope.sportContentData1 = [];
    for (let i = 0; i < 16; i++) {
      scope.sportContentData1.push({
        id: i,
        name: "Juventus away jersay 2017/18",
        team: "Juventus",
        sport: "soccer",
        type: "team road uniform",
        img_url: `/assets/img/sports/uniforms/${i % 2 + 1}.png`,
        logo_url: '/assets/img/sports/logo-new.svg',
        color: {
          hex: "#fdd910",
          R: 253, G: 217, B: 16
        },
      });
    }

    scope.setback = () => {
      $state.go("sport", {control: "searchMenu", id: "", title: "", hex: ""});
    };

    scope.init = () => {
      scope.menus = {
        sport: "",
        team: "",
        color: "",
      };
      scope.disableSport = false;
      scope.disableTeam = false;
      scope.isLoadingControls = true;
      scope.currentPage = 0;
      scope.mainSeason = [];
      scope.tab = 1;
      if(!scope.user.admin) {
        modalService.showModal(10, null, "We're working to finalize this dashboard and except it launched in Q4 of 2022.");
      }
    };

    scope.setTab = (tabId) => {
      scope.tab = tabId;
      scope.select_images = false;
      scope.selected_moodboard = false;
      scope.state = 1;
    };

    scope.isSet = (tabId) => {
      return scope.tab === tabId;
    };

    // searchMenuRepository.getControlsDataAuto().then(function (data) {
    //   scope.controlsData = data;
    // });

    scope.setColorAsMain = function (color) {
      scope.iconUrl = null;
      scope.secondaryParams = {};
      scope.mainParam = null;
      scope.tempColor = color;
      scope.menus = {
        sport: "",
        team: "",
        color: "",
      };
      scope.menus.color = color.id;
      if (
        !scope.controlsData.colors.find(function (item) {
          return item.id === scope.tempColor.id;
        })
      ) {
        scope.controlsData.colors.unshift(scope.tempColor);
      }
      scope.tempColor = null;
      scope.handleChangeControl("color");
      scope.loadGraphics();
    };

    scope.loadGraphics = function () {
      if (scope.mainParam) {
        scope.showDashboard = true;
        dashboardOverlayService.loadingStart(4000);

        const parseData = (length) => {
          let size = length;
          for (
            let i = 0;
            i < Math.ceil(scope.colorPaletteData.length / size);
            i++
          ) {
            scope.mainSeason[i] = scope.colorPaletteData.slice(
              i * size,
              i * size + size
            );
          }
        };

        scope.isLoadingControls = false;
      }
    };

    scope.setPage = (num) => {
      if (num >= 0 && num < scope.pageCount) {
        scope.currentPage = num;
        scope.loadGraphics();
      }
    };

    scope.handleChangeControl = function (control, item) {
      scope.header_title = () => {
        return `${control}: ${item.title}`;
      };
      scope.mainParam = control;
      scope.selectedFilterValue = item;
      scope.selectedFilterValue.img_url = scope.getImageUrl(control, item);
      scope.mainParamId = item.id;
      scope.searchMode = control;

      scope.loadGraphics();
    };

    scope.getImageUrl = (control, item) => {
      if (control === 'sport') {
        if (item.title === "Baseball") {
          return '/assets/img/sports/types/baseball.png';
        }
        if (item.title === "Basketball") {
          return '/assets/img/sports/types/basketball.png';
        }
        if (item.title === "Football") {
          return '/assets/img/sports/types/football.png';
        }
        if (item.title === "Hockey") {
          return '/assets/img/sports/types/hockey.png';
        }
        if (item.title === "Soccer") {
          return '/assets/img/sports/types/soccer.png';
        }
        if (item.title === "Lacrosse") {
          return '/assets/img/sports/types/lacrosse.png';
        }
        if (item.title === "Rugby") {
          return '/assets/img/sports/types/rugby.png';
        }
      }

      if (control === 'team') {
        return '/assets/img/sports/logo1.png';
      }

      return '';
    };

    scope.handleSearchChange = function (control, item) {
      $state.go("sport", Object.assign({control: control}, item));
    };

    scope.$watch(
      () => {
        return dashboardOverlayService.showOverlay;
      },
      (newValue) => {
        scope.showDashboardOverlay = newValue;
      }
    );
  },
]);
