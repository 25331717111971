(function () {
  angular.module("app").directive("volumeLogoOverYear", [
    function () {
      function link(scope) {

      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          data: "=",
          selectedColor: "=",
          colorFrequencyData: "=",
        },
        templateUrl:
          "app/directives/customInfographicsUnicornDirectives/volumeLogoOverYear/volumeLogoOverYear.html",
      };

      return directive;
    },
  ]);
})();
