angular.module("app").service("natureFlowerDashboardRepository", [
  "appConfig",
  "authService",
  "httpService",
  function (appConfig, authService, httpService) {

    this.getFlowerColorsList = () => {
      return httpService
          .get(`${appConfig.dashboardServiceUrl}api/nature/flowers/search/colors`, {
              params: { token: authService.token },
          }) .then((res) => {
            if (res) {
              return res.data;
            }
          });
    };

    this.getFlowerFamilyList = (str) => {
      str = str.split(' ').join('+');
      return httpService
          .get(`${appConfig.dashboardServiceUrl}api/nature/flowers/search/families?letter=`+ str, {
              params: { token: authService.token },
          }) .then((res) => {
            if (res) {
              return res.data;
            }
          });
    };

    this.getFlowerLifespanList = () => {
      return httpService
          .get(`${appConfig.dashboardServiceUrl}api/nature/flowers/search/lifespans`, {
              params: { token: authService.token },
          }) .then((res) => {
            if (res) {
              return res.data;
            }
          });
    };

    this.getFlowersImage = (type, params, page, per_page) => {
      let url;
      if (type && type === "color") {
        url = `api/nature/flowers/dashboard?color_id=${params.id}&page=${page}&per_page=${per_page}`;
      } else if (type && type === "flowerFamily") {
        url = `api/nature/flowers/dashboard?family=${params.title}&page=${page}&per_page=${per_page}`;
      } else if (type && type === "flowerLifespan") {
        url = `api/nature/flowers/dashboard?lifespan=${params.title}&page=${page}&per_page=${per_page}`;
      }
      return httpService.get(appConfig.dashboardServiceUrl + url,
        authService.getHeaderConfig()
      )
        .then(function (data) {
          return data && data.data ? data.data : data;
        });
    };

    // color color data API 
    this.getColorChartsDataByColorId = (id,node) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/nature/flowers/dashboard/colors?color_id=" + id,{
              params: { token: authService.token, node: node }})
          .then(function (data) {
            return (data.data);
          });
    };
    
    // family color data API 
    this.getFamilyChartsDataByFamily = (id,node) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/nature/flowers/dashboard/families?family=" + id,{
              params: { token: authService.token, node: node }})
          .then(function (data) {
            return (data.data);
          });
    };

    this.getFamilyChartsData2ByFamily = (id, node, page, per_page) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/nature/flowers/dashboard/families?family=" + id,{
              params: { token: authService.token, node: node, page: page, per_page: per_page }})
          .then(function (data) {
            return (data.data);
          });
    };

    // family lifespan color data API 
    this.getLifespanChartsDataByLifespan = (id,node) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/nature/flowers/dashboard/lifespans?lifespan=" + id,{
              params: { token: authService.token, node: node }})
          .then(function (data) {
            return (data.data);
          });
    };

  },
]);
