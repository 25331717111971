angular.module("app").directive("flowerFamiliesColorsAcrossArea", () => {
  let link = (scope) => {
    scope.colors = [
      { title: "white", hex: "#ffffff" },
      { title: "brown", hex: "#63310e" },
      { title: "pink", hex: "#f7c6de" },
      { title: "purple", hex: "#6d4595" },
      { title: "blue", hex: "#37b1e6" },
      { title: "green", hex: "#46ac33" },
      { title: "yellow", hex: "#fae51b" },
      { title: "orange", hex: "#f2891d" },
      { title: "red", hex: "#e6341c" }
    ];

    scope.selectedColor = scope.colors[0];
    scope.selectColor = (index) => {
      scope.selectedColor = scope.colors[index];
    };

    scope.familyData = [
      {
        state: "NY",
        flora: "34 type flowers"
      }
    ];

    scope.showImageDetails = false;
    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (index) => {
      scope.index = index;
      scope.toggleImageDetails();
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbFlowerFamiliesColorsAcrossArea/dbFlowerFamiliesColorsAcrossAreaView.html",
    link: link,
    scope: {
    }
  };
});
