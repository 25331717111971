angular.module('app').directive('natureColorUsMap', ($interval, $timeout) => {
  function link(scope) {
// dummy data -> map data
    scope.mapData = [
      {
        id: 0,
        title: 'CA'
      },
      {
        id: 1,
        title: 'WA'
      }
    ];

    // Change svg size after element is loaded
    scope.checkExist = $interval(function () {
      if ($('#my_nature_color_us_map_dataviz svg').length) {
        scope.svg = $('#my_nature_color_us_map_dataviz svg')[0];
        scope.svg.setAttribute('width', 500);
        scope.svg.setAttribute('height', 400);
        clearInterval(scope.checkExist);
      }
    }, 100);

    // To reload the US map with new color
    scope.$watch('selectedItem', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.selectedItem = newValue;
        let count = scope.mapData.length;
        let temp = scope.mapData;
        temp.forEach(item => {
          scope.mapData.push(item);
        });
        $timeout(function () {
          scope.mapData = scope.mapData.splice(0, count);
        }, 100);
      }
    }, true);
  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/dbNatureColorUsMap/dbNatureColorUsMapView.html',
    link: link,
    scope: {
      colorData: '=',
      selectedItem: '='
    }
  };
});
