angular.module('app').component('cartThankComponent', {
	templateUrl: 'app/components/cart-thank/cart-thank.tmpl.html',
	controller: function ($state, $http, appConfig, localStorageService, modalService, $stateParams) {
		const vm = this;

		vm.orderId = localStorageService.get('orderId');
		vm.receiptURL = localStorageService.get('rcptURL');
		vm.reports = localStorageService.get('reportPdf');
		vm.courseVideo = localStorageService.get('courseVideo');
		vm.courseImageUrl = localStorageService.get('courseImageUrl');
		vm.courseHeader = localStorageService.get('courseHeader');
		vm.courseFacultyName = localStorageService.get('courseFacultyName');
		vm.coursePublishedDate = localStorageService.get('coursePublishedDate');
		localStorageService.remove('orderId');
		localStorageService.remove('purchase');
		localStorageService.set('products', { courses: {}, reports: {}, teaching_materials: {} });

		vm.openVideo = function () {
			window.parent.postMessage({
				action: 'OpenVideoModal',
				data: {
					videoData: {
						header: vm.courseHeader,
						faculty_name: vm.courseFacultyName,
						image_url: vm.courseImageUrl,
						published_date: vm.coursePublishedDate,
						video_url: vm.courseVideo,
					},
				},
			});
		};
	},
});
