angular.module("app").directive("pricingCard",[ "paymentService", "localStorageService", "modalService", (paymentService, localStorageService, modalService) => {
  let link = (scope) => {
    scope.getActive = (item) => {
      if(scope.isAnnuallyMode) {
        if(item.type === 'pro' && scope.currentUserPlan && scope.currentUserPlan.name === 'pro-yearly') {
          return true;
        } else if(item.type === 'teams' && scope.currentUserPlan && scope.currentUserPlan.name === 'team-yearly') {
          return true;
        }
      } else {
        if(item.type === 'pro' && scope.currentUserPlan && scope.currentUserPlan.name === 'pro-monthly') {
          return true;
        } else if(item.type === 'teams' && scope.currentUserPlan && scope.currentUserPlan.name === 'team-monthly') {
          return true;
        }
      }
    }
    scope.showCardModal = false;
    scope.upgradePlanDetails = undefined;

    scope.$watch("isAnnuallyMode", function (mode) {
      scope.isAnnuallyMode = mode;
    });

    scope.$watch("currentUserPlan", function (currentUserPlan) {
      scope.currentUserPlan = currentUserPlan;
    });
    
    scope.$watch("isSource", function (isSource) {
      scope.isSource = isSource;
    });

    scope.getInteger = (value) => {
      return Math.floor(value);
    };

    scope.getDecimal = (value) => {
      const res = Math.round((value - Math.floor(value)) * 100);

      return res ? res : '';
    };
  
    scope.joinNow =(data) => {
      if(!(data.type === 'pro' || data.type === 'teams')) {return;}
      let planPrice;
      // if(data.type === 'pro' && scope.isAnnuallyMode){
      //   planPrice = scope.planPrices['pro-yearly'];
      // } else if(data.type === 'pro' && !scope.isAnnuallyMode){
      //   planPrice = scope.planPrices['pro-monthly'];
      // } else if(data.type === 'teams' && scope.isAnnuallyMode){
      //   planPrice = scope.planPrices['team-yearly'];
      // } else if(data.type === 'teams' && !scope.isAnnuallyMode){
      //   planPrice = scope.planPrices['team-monthly'];
      // }

      if(data.type === 'pro' && scope.isAnnuallyMode){
        planPrice = data.yearlyId;
      } else if(data.type === 'pro' && !scope.isAnnuallyMode){
        planPrice = data.monthlyId;
      } else if(data.type === 'teams' && scope.isAnnuallyMode){
        planPrice =  data.yearlyId;
      } else if(data.type === 'teams' && !scope.isAnnuallyMode){
        planPrice = data.monthlyId;
      }
      var stripe = Stripe(scope.stripePubKey);
      const params = {
        plan_id: planPrice
      }
      paymentService.createSubscription(params).then((data) => {
        stripe.redirectToCheckout({
          sessionId: data.stripe.id
        }).then(function (result) {
        });
      });
    }

    scope.joinNowTeam =(data) => {
      if(!(data.type === 'pro' || data.type === 'teams')) {return;}
      let planPrice;

      if(data.type === 'pro' && scope.isAnnuallyMode){
        planPrice = data.yearlyId;
      } else if(data.type === 'pro' && !scope.isAnnuallyMode){
        planPrice = data.monthlyId;
      } else if(data.type === 'teams' && scope.isAnnuallyMode){
        planPrice =  data.yearlyId;
      } else if(data.type === 'teams' && !scope.isAnnuallyMode){
        planPrice = data.monthlyId;
      }
      var stripe = Stripe(scope.stripePubKey);
      const params = {
        plan_id: planPrice
      }
      paymentService.joinSubscription(params).then((data) => {
        stripe.redirectToCheckout({
          sessionId: data.stripe.id
        }).then(function (result) {
        });
      });
    }

    scope.upgradePlanFree =(data) => {
      if(!(data.type === 'free')) {return;}
      let planPrice;
      if(data.type === 'free' && scope.isAnnuallyMode){
        planPrice =  data.yearlyId;
      } else if(data.type === 'free' && !scope.isAnnuallyMode){
        planPrice = data.monthlyId;
      }
      const params = {
        plan_id: planPrice
      }
      paymentService.joinSubscription(params).then((data) => {
        setTimeout(() => {
          scope.getCurrentPlan();
        }, 500);
        if(data.success === 'ok') {
          modalService.showModal(9, null, data.message);
        }
      });
    }

    scope.upgradePlan =(data) => {
      if(!(data.type === 'pro' || data.type === 'teams' || data.type === 'free')) {return;}
      if(!scope.isSource) {
        scope.showCardModal = true;
        scope.upgradePlanDetails = data;
      } else {
        let planPrice;
        if(data.type === 'pro' && scope.isAnnuallyMode){
          planPrice = data.yearlyId;
        } else if(data.type === 'pro' && !scope.isAnnuallyMode){
          planPrice = data.monthlyId;
        } else if(data.type === 'teams' && scope.isAnnuallyMode){
          planPrice =  data.yearlyId;
        } else if(data.type === 'teams' && !scope.isAnnuallyMode){
          planPrice = data.monthlyId;
        } else if(data.type === 'free' && scope.isAnnuallyMode){
          planPrice =  data.yearlyId;
        } else if(data.type === 'free' && !scope.isAnnuallyMode){
          planPrice = data.monthlyId;
        }
        const params = {
          plan_id: planPrice
        }
        paymentService.upgradeSubscription(params).then((data) => {
          setTimeout(() => {
            scope.getCurrentPlan();
          }, 500);
          if(data.success === 'ok') {
            modalService.showModal(9, null, data.message);
          }
        });
      }
    }

    scope.getUpgratePlanData = (token) => {
      let planPrice;
      if(scope.upgradePlanDetails.type === 'pro' && scope.isAnnuallyMode){
        planPrice = scope.upgradePlanDetails.yearlyId;
      } else if(scope.upgradePlanDetails.type === 'pro' && !scope.isAnnuallyMode){
        planPrice = scope.upgradePlanDetails.monthlyId;
      } else if(scope.upgradePlanDetails.type === 'teams' && scope.isAnnuallyMode){
        planPrice =  scope.upgradePlanDetails.yearlyId;
      } else if(scope.upgradePlanDetails.type === 'teams' && !scope.isAnnuallyMode){
        planPrice = scope.upgradePlanDetails.monthlyId;
      } else if(scope.upgradePlanDetails.type === 'free' && scope.isAnnuallyMode){
        planPrice =  scope.upgradePlanDetails.yearlyId;
      } else if(scope.upgradePlanDetails.type === 'free' && !scope.isAnnuallyMode){
        planPrice = scope.upgradePlanDetails.monthlyId;
      }
      const params = {
        plan_id: planPrice,
        source: token
      }

      paymentService.upgradeSubscription(params).then((data) => {
        scope.showCardModal = false;
        setTimeout(() => {
          scope.getCurrentPlan();
        }, 500);
        if(data.success === 'ok') {
          modalService.showModal(9, null, data.message);
        }
      });
    }

    scope.toggleSignUpDetails = (item) => {
      scope.toggleSignUp(item);
    }

    scope.toggleCardDetails = function(item) {
      scope.showCardModal = false;
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/pricingCard/pricingCardView.html",
    link: link,
    scope: {
      data: "=",
      isAnnuallyMode: "=",
      toggleSignUp: "=",
      stripePubKey: "=",
      planPrices: "=",
      currentUserPlan: "=",
      getCurrentPlan: "=",
      isSource: "="
    }
  };
},
]);
