angular.module('app').directive('colorLogoPieChart', ['$window', function ($window) {
    function link (scope) {
      scope.option = {
        cutoutPercentage: 80,
      };
  
      scope.labels = [];
      scope.percentage = [];
      scope.hex = [];
  
      scope.formColorPercentBarBGStyle = function (item) {
        return 'rgb(' + item.rgb + ')';
      };
  
      scope.$watch('colors', function (newValue) {
        if (!newValue) {
          return;
        } else {
          scope.labels = [];
          scope.percentage = [];
          scope.hex = [];
        
          scope.colors.forEach(function (colorData) {
            scope.labels.push(colorData.title);
            scope.percentage.push(colorData.percentage);
            scope.hex.push('rgb(' + colorData.rgb + ')');
          });
       
          scope.chartConfig = {
            type: 'doughnut',
            data: {
              labels: scope.labels,
              datasets: [{
                data: scope.percentage,
                backgroundColor: scope.hex,
                borderWidth: 1,
                borderColor: '#ebebeb',
              }],
            },
            options: {
              legend: {display: false},
              cutoutPercentage: 90,
              responsive: false,
              layout: {},
              plugins: {outlabels: false},
            }
          };
  
          if (scope.showOutsideLabels) {
            scope.chartConfig.options.layout.padding = 60;
            scope.chartConfig.options.plugins.outlabels = {
              text: '%p',
              color: 'black',
              lineColor: 'black',
              stretch: 20,
              font: {
                resizable: true,
                minSize: 17,
                maxSize: 18,
                weight: 'bold',
                family: 'Gotham-Book'
              },
              padding: 2,
              lineWidth: 2,
              backgroundColor: 'white',
              borderColor: null
            };
          }
  
          var ctx = document.getElementById('doughnut' + scope.$id);
          if (ctx)
            var chart = new Chart(ctx, scope.chartConfig);
        }
      });
    }
  
    return {
      restrict: 'E',
      templateUrl: 'app/directives/colorLogoPieChart/colorLogoPieChart.html',
      link: link,
      scope: {
        colors:'=',
        showBottomBar:'=',
        showColorPercent:'=',
        size:'=',
        showOutsideLabels:'=',
      }
    }
}]);