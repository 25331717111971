angular.module("app").component("pressComponent", {
  templateUrl: "app/components/press/press.tmpl.html",
  controller: function ($state, $http, appConfig, dataValidate) {
    const vm = this;
    vm.pageData = {};

    vm.data = {
      first_name: {
        value: "",
        required: true,
        name: "first name",
        type: "provide",
      },
      last_name: {
        value: "",
        required: true,
        name: "last name",
        type: "provide",
      },
      email: { value: "", required: true, name: "email", type: "provide" },
      company: {
        value: "",
        required: true,
        name: "company name",
        type: "provide",
      },
      body: { value: "", required: true, name: "message", type: "enter" },
    };

    //mentions dummy data
    vm.mentions = [
      {
        title: "Fast Company",
        url: "https://www.fastcompany.com/90778314/how-gray-became-the-king-of-color",
        published_date: new Date(2022, 7, 14),
        header: "How gray became the king of color",
        image: "assets/images/fast.jpeg"
      },
      {
        title: "EndpointsNews",
        url: "https://endpts.com/pharma-color-and-marketing-typically-awash-in-blue-newer-brand-updates-adopt-bold-hues",
        published_date: new Date(2022, 2, 8),
        header: "Pharma colors and marketing: Typically ‘awash in blue,’ newer brand updates adopt bold hues",
        image: "assets/images/color-and-marketing-scaled.jpg"
      },
      {
        title: "Forbes",
        url: "https://www.forbes.com/sites/michellegreenwald/2016/02/17/a-new-comprehensive-4-in-1-big-data-resource-to-aid-color-innovation/?sh=690b8dab4256",
        published_date: new Date(2016, 1, 17),
        header: "A New, Comprehensive, 4-In-1, Big Data Resource To Aid Color Innovation",
        image: 'assets/images/forbes.png'
      },
    ];

    // vm.init = () => {
    //   $http
    //     .get(appConfig.dashboardServiceUrl + "api/presses.json")
    //     .then(function (res) {
    //       if (res && res.data && res.data.presses) {
    //         vm.pageData = angular.copy(res.data);
    //       }
    //     });
    //   $http
    //     .get(appConfig.dashboardServiceUrl + "api/reports.json")
    //     .then(function (res) {
    //       if (res && res.data) {
    //         vm.pageData.reports = angular.copy(res.data.data);
    //       }
    //     });
    // };

    vm.press = () => {
      if (dataValidate.validate(vm.data)) {
        const data = {};
        for (const item in this.data) {
          data[item] = this.data[item].value;
        }
        $http
          .post(appConfig.dashboardServiceUrl + "api/forms/press.json", {
            contact: data,
          })
          .then((res) => {
            if (res.status === 201) {
              $state.go("thank-you");
            }
          });
      }
    };
    vm.makeDate = (item) => {
      return moment(
        item.data.published_year +
          "-" +
          item.data.published_month +
          "-" +
          item.data.published_day
      ).format("MMMM D, YYYY");
    };
  },
});
