(function () {
  'use strict';

  angular.module('app').directive('hueChartGridNails',
    [
      'common', 'config', 'chartsHelper',
      function (common, config, chartsHelper) {
        function link(scope, element) {
          scope.$watch("data", (newValue) => {
            if (newValue) {
              bindData();
            }
          });

          function bindData() {
            if (!scope.data) {
              return;
            }

            let totalAchr = 0;
            let totalChr = 0;
            totalAchr = scope.data.achromatic_colors.percentage.replace('%','');
            totalChr = scope.data.chromatic_colors.percentage.replace('%','');
            const data = {
              groups: [{title: totalAchr + '% ACHROMATIC COLORS', name: 'chr'},
                {title: totalChr + '% CHROMATIC COLORS', name: 'achr'}]
            };
            data.periods = [
              { title: "rtw" },
              { title: "menswear" },
              { title: "couture" }
            ];
            _.each(scope.data.chromatic_colors.colors, function (obj) {
              obj.group = "achr";
              obj.color = obj.hex;
              obj.title = obj.name;
              obj.data = _.map(obj.categories, function (d) {
                let per = d.percentage.replace('%','')
                return {
                  title: d.percentage,
                  value: (per / 1000)
                }
              })
              let finalValue = 0;
              if(obj.categories.length > 0) {
                obj.categories.forEach(element => {
                  finalValue = finalValue + element.total;
                });
                obj.value = finalValue;
              } else {
                obj.value = finalValue;
              }
            })
            _.each(scope.data.achromatic_colors.colors, function (obj) {
              obj.group = "chr";
              obj.color = obj.hex;
              obj.title = obj.name;
              obj.data = _.map(obj.categories, function (d) {
                const per = d.percentage.replace('%','')
                return {
                  title: d.percentage,
                  value: (per / 1000)
                }
              })
              let finalValue = 0;
              if(obj.categories.length > 0) {
                obj.categories.forEach(element => {
                  finalValue = finalValue + element.total;
                });
                obj.value = finalValue;
              } else {
                obj.value = finalValue;
              }
            })

            data.data = scope.data.chromatic_colors.colors.concat(scope.data.achromatic_colors.colors);

            const container = element.find('[chart-type]').html('');
            const ch = new chartNailsLinearVertical({
              data: data,
              container: container[0],
              options: {
                layout: {
                  mode: scope.mode && scope.mode.extraView ? 'colors' : 'default'
                }
              }
            });
          }
        }

        const directive = {
          link: link,
          restrict: 'E',
          replace: true,
          scope: {
            data: '=',
            mode: '='
          },
          templateUrl: 'app/directives/custom-infographics-directives/chart-grid-nails.html'
        };

        return directive;

      }
    ]);
}());
