(function () {
  angular.module('app').directive('hueChartFlagColorsInfluence', [
    function () {
      function link(scope) {
        scope.countries = scope.data;
      }

      const directive = {
        link: link,
        restrict: 'E',
        replace: true,
        scope: {
          data: '='
        },
        templateUrl: 'app/directives/custom-infographics-brand-directives/chart-flag-colors-influence.html'
      };

      return directive;
    }
  ]);
}());
