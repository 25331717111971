angular.module('app').directive('infographicsForUnicorn', [
  '$location',
  '$timeout',
  'customInfographicsConstants',
  "httpService",
  "authService",
  "appConfig",
  function ($location, timeout, customInfographicsConstants, httpService, authService, appConfig) {
    function link(scope) {
      scope.currentYear = moment().year();
      scope.filtersForUnicorn = {
        color: [],
        industry:[],
        country:[],
        year: [],
      };

      scope.$watch('colorGroup', function (colorGroup) {
        if (colorGroup) {
          scope.filtersForUnicorn.color = scope.colorGroup;
          scope.filtersForUnicorn.color.unshift({
            id: "all",
            title: "COLOR",
            all: true,
          });
          scope.selectedColor = scope.filtersForUnicorn.color[0];
          if(scope.currentChartForUnicorn.selected.qNumber === 'UNICORN01'){
            scope.titleForUnicorn = 'Volume of unicorns using ' + scope.selectedColor.title + ' in Their logos over the years';
          }
        }
      });

      scope.chartsForUnicorn = [
        {
          qNumber: 'UNICORN01',
          title:
            '1. Volume of unicorns using color in Their logos over the years',
          chartTitle:
            'Volume of unicorns using color in Their logos over the years',
          id: 'colorUsingLogoYear',
          filters: {
            colorForUnicorns: true
          }
        },
        {
          qNumber: 'UNICORN02',
          title: '2. Unicorn logo colors comparison for selected industries',
          chartTitle: 'Unicorn logo colors comparison for selected industries',
          id: 'logoComparisonPerIndustry',
          filters: {
            industryForUnicorns1: true,
            industryForUnicorns2: true,
            industryForUnicorns3: true
          }
        },
        {
          qNumber: 'UNICORN03',
          title: '3. Unicorn logo colors comparison for selected countries',
          chartTitle: 'Unicorn logo colors comparison for selected countries',
          id: 'logoComparisonPerCountry',
          filters: {
            countryForUnicorns1: true,
            countryForUnicorns2: true,
            countryForUnicorns3: true
          }
        },
        {
          qNumber: 'UNICORN04',
          title: '4. Unicorn using color in their logo across all industries',
          chartTitle: 'Unicorn using color in their logo across all industries',
          id: 'logoAcrossAll',
          filters: {}
        },
        {
          qNumber: 'UNICORN05',
          title: '5. Top 5 logo colors used by unicorns in year across industries',
          chartTitle: 'Top 5 logo colors used by unicorns in year across industries',
          id: 'top5logo',
          filters: {
            year: true
          }
        },
        {
          qNumber: 'UNICORN06',
          title: '6. NUMBER OF COLORS UNICORNS ARE USING IN THEIR LOGOS ACROSS ALL INDUSTRIES',
          chartTitle: 'Number of colors unicorns are using in their logos across all industries',
          id: 'numberLogoAcrossAll',
          filters: {}
        },
      ];

      scope.currentChartForUnicorn = {
        selected: scope.chartsForUnicorn[0]
      };
      
      scope.selectedFilterValue = {id: 'beige', title: 'beige', hex: '#f5b8c7'}
      scope.unicornColorVolumeDataOverYears = customInfographicsConstants.unicornColorVolumeDataOverYears;
      scope.colorFrequencyData = customInfographicsConstants.colorFrequencyData;
      scope.isFilterVisible = customInfographicsConstants.isFilterVisible;
      scope.selectedIndustryData = customInfographicsConstants.selectedIndustryData;
      scope.filtersForUnicorn.industry = customInfographicsConstants.listAllIndustryData;
      scope.selectedIndustry1 = scope.filtersForUnicorn.industry[0];
      scope.selectedIndustry2 = scope.filtersForUnicorn.industry[1];
      scope.selectedIndustry3 = scope.filtersForUnicorn.industry[2];
      scope.selectedCountryData = customInfographicsConstants.selectedCountryData;
      scope.filtersForUnicorn.country = customInfographicsConstants.countries;
      scope.selectedCountry1 = scope.filtersForUnicorn.country[84];
      scope.selectedCountry2 = scope.filtersForUnicorn.country[15];
      scope.selectedCountry3 = scope.filtersForUnicorn.country[10];
      scope.unicornUsingLogoAcrossIndustry = customInfographicsConstants.dataForLogos;
      scope.colorLogoAcrossIndustryData = customInfographicsConstants.colorLogoAcrossIndustryData;
      scope.topFiveLogos = customInfographicsConstants.topFiveLogos;
      scope.filtersForUnicorn.year = customInfographicsConstants.yearList.sort((a, b) => (a.id < b.id) ? 1 : -1);
      scope.selectedYear =  scope.filtersForUnicorn.year[0];


      scope.updateColor = function (color) {
        scope.selectedColor = color;
        if(scope.currentChartForUnicorn.selected.qNumber === 'UNICORN01'){
          scope.titleForUnicorn = 'Volume of unicorns using ' + scope.selectedColor.title + ' in Their logos over the years';
        }
      };

      scope.updateIndustry1 = function (industry) {
        scope.selectedIndustry1 = industry;
      };

      scope.updateIndustry2 = function (industry) {
        scope.selectedIndustry2 = industry;
      };

      scope.updateIndustry3 = function (industry) {
        scope.selectedIndustry3 = industry;
      };

      scope.updateCountry1= function (country) {
        scope.selectedCountry1 = country;
      };

      scope.updateCountry2 = function (country) {
        scope.selectedCountry2 = country;
      };

      scope.updateCountry3 = function (country) {
        scope.selectedCountry3 = country;
      };

      scope.updateYear = function (year) {
        scope.selectedYear = year;
      }

      scope.initStatesByQueryParamsForUnicorn = function () {
        // const {chart} = $location.search();
        timeout(() => {
          scope.selectedMenuId = 11;
          scope.currentChartForUnicorn.selected =
            scope.chartsForUnicorn.find(i => i.id === scope.currentChartForUnicorn.selected.id) ||
            scope.chartsForUnicorn[0];
           
            if(scope.currentChartForUnicorn.selected.qNumber === 'UNICORN01'){
              scope.titleForUnicorn = 'Volume of unicorns using ' + scope.selectedFilterValue.title + ' in Their logos over the years';
            } else {
              scope.titleForUnicorn = scope.currentChartForUnicorn.selected.chartTitle;
            }
        }, 0);
      };

      scope.initStatesByQueryParamsForUnicorn();

      scope.updateQueryBySelectorsForUnicorn = function () {
        // $location.search({
        //   tab: 11,
        //   board: scope.selectedMenuId,
        //   chart: scope.currentChartForUnicorn.selected.id
        // });
        scope.initStatesByQueryParamsForUnicorn();
      };

      scope.refresh = () => {
        scope.updateQueryBySelectorsForUnicorn();
      };

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
          result += name.toUpperCase() + ".";

        return result + date + type;
      };

      scope.exportJpgThroughDomToImage = (name) => {
        console.log('namama', name);
        let node = angular.element("#unicornCapture")[0];
        
        const titles = angular.element(".graphic-titles");
        const header = angular.element(".graphic-titles-export");
        const footer = angular.element(".customized-infographics-footer");

        footer.css({ display: "block" });
        header.css({ display: "block" });
        titles.css({ display: "none" });
        $("html").addClass("hide-scrollbar");

        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            const img = new Image();
            img.src = dataUrl;

            const link = document.createElement("a");
            link.href = img.src;
            link.download = scope.generateImgName(".png", "brand", name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            $("html").removeClass("hide-scrollbar");
            footer.css({ display: "none" });
            header.css({ display: "none" });
            titles.css({ display: "block" });
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      };
    }

    return {
      restrict: 'E',
      templateUrl:
        'app/components/customized-infographics/infographicsForUnicorn/infographicsForUnicorn.html',
      link: link,
      scope: {
        colorGroup: "=",
        industryData: "="
      }
    };
  }
]);
