angular.module("app").directive("topicsStudiedOverYears", () => {
  let link = (scope) => {
    scope.$watch('data', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.data = newValue;
        scope.data.chart_details = scope.data.chart_details.filter(function( obj ) {
          return obj.keyword !== 'all';
        });
      }
    }, true);
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbTopicsStudiedOverYears/dbTopicsStudiedOverYearsView.html",
    link: link,
    scope: {
      data: "=",
      selectedColor: "="
    }
  };
});
