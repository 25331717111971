angular.module("app").directive("colorLogoByIndustry", function () {
  function link(scope) {
  }
  return {
    restrict: "E",
    templateUrl:
      "app/directives/customInfographicsUnicornDirectives/colorLogoByIndustry/colorLogoByIndustry.html",
    link: link,
    scope: {
      colorFamilies: "=",
    },
  };
});
