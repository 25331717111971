angular
  .module('app')
  .component('colorBlogComponent', {
    templateUrl: 'app/components/color-blog/color-blog.tmpl.html',
    controller: function (localStorageService, colorBlog, $scope, $http, appConfig) {

      this.posts = [];
      this.categoryList = [];
      this.archiveList = [];
      this.socialLinks = appConfig.socialLinks;

      colorBlog.getPosts().then(posts => {
        this.categoryList = Object.keys(posts.categories);
        this.archiveList = ['All', ...Object.keys(posts.dates)];

        posts.posts.forEach(item => {
          item.description = item.description.replace(/<p>&nbsp;<\/p>/gi, '');
          item.header = item.header[0] + item.header.slice(1).toLowerCase();
        });
        this.posts = posts.posts;
      });

      this.searchOfPost = (searchParam) => {

        if (searchParam === '&date=All') {
          searchParam = "";
        }

        $scope.searchParam = searchParam;

        $http.get(`${appConfig.dashboardServiceUrl}api/posts.json?${searchParam}`)
          .then((res) => {
            if (res.data.posts) {
              this.posts = [...res.data.posts];
            }
          })
      };

      this.monthNumToText = function (num) {
        if (num < 1 || num > 12)
          return;

        return ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ][num - 1];
      };
    }
  });
