angular.module("app").directive("autocomplete", [
  "searchMenuRepository",
  "legalTrendsDashboardRepository",
  "pharmaDashboardRepository",
  "autoDashboardRepository",
  (
    searchMenuRepository,
    legalTrendsDashboardRepository,
    pharmaDashboardRepository,
    autoDashboardRepository
  ) => {
    const link = (scope) => {
      scope.filteredChoices = [];
      scope.isVisible = {
        suggestions: false,
      };

      scope.filterItems = () => {
        const getSelectorType = (v) => {
          if (v === "company") return "companies";
          if (v === "industry") return "industries";
          if (v === "attribute") return "attributors";
          return v;
        };
        if (scope.enteredtext.length < 0) return;
        const type = getSelectorType(scope.selecttype);
        scope.disabled(type, scope.enteredtext.length);
        if (scope.board === "auto") {
          if (type === "year") {
            autoDashboardRepository.getColorsForFilter().then((data) => {
              if (data.data.years) {
                scope.filteredChoices = data.data.years.map(item => {
                  return { title: item, id: item };
                });
                scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1);
              }
              scope.isVisible.suggestions =
              scope.enteredtext.length === 0
                ? false
                : scope.filteredChoices.length > 0;
              });
          }
        } else if (scope.board === "pharma") {
          pharmaDashboardRepository.getSettings().then((data) => {
            if (type === "therapeuticArea") {
              scope.filteredChoices = data.areas;
              scope.isVisible.suggestions =
                scope.enteredtext.length === 0
                  ? false
                  : scope.filteredChoices.length > 0;
            } else if (type === "pillShape") {
              scope.filteredChoices = data.shapes;
              scope.isVisible.suggestions =
                scope.enteredtext.length === 0
                  ? false
                  : scope.filteredChoices.length > 0;
            } else if (type === "pharmaceuticalCompany") {
              scope.filteredChoices = data.companies.map((item) => {
                return { id: item.id, title: item.name };
              });
              scope.isVisible.suggestions =
                scope.enteredtext.length === 0
                  ? false
                  : scope.filteredChoices.length > 0;
            } else if (type === "year") {
              scope.filteredChoices = data.years;
              scope.isVisible.suggestions =
                scope.enteredtext.length === 0
                  ? false
                  : scope.filteredChoices.length > 0;
            }
          });
        } else if (scope.board === "legal") {
          if (type === "year") {
            legalTrendsDashboardRepository.getSettings().then((data) => {
              if (data.years) {
                scope.filteredChoices = data.years.map(item => {
                  return { title: item };
                });

                scope.isVisible.suggestions =
                  scope.enteredtext.length === 0
                    ? false
                    : scope.filteredChoices.length > 0;
              }
            });
          }
        } else if (scope.board === "art") {
          if (type === "period") {
            scope.filteredChoices = [{ id: "2006", title: "REALISM" }];
            scope.isVisible.suggestions =
              scope.enteredtext.length === 0
                ? false
                : scope.filteredChoices.length > 0;
          }
        } else if (scope.board === "nature") {
          if (type === "flowerFamily") {
            scope.filteredChoices = [{ id: "2007", title: "OLEACEAE" }];
            scope.isVisible.suggestions =
              scope.enteredtext.length === 0
                ? false
                : scope.filteredChoices.length > 0;
          } else if (type === "flowerLifespan") {
            scope.filteredChoices = [{ id: "2008", title: "LIFESPAN" }];
            scope.isVisible.suggestions =
              scope.enteredtext.length === 0
                ? false
                : scope.filteredChoices.length > 0;
          } else if (type === "flowerLocation") {
            scope.filteredChoices = [{ id: "2009", title: "GEO" }];
            scope.isVisible.suggestions =
              scope.enteredtext.length === 0
                ? false
                : scope.filteredChoices.length > 0;
          } else if (type === "birdFamily") {
            scope.filteredChoices = [{ id: "2010", title: "HUMMNIGBIRDS" }];
            scope.isVisible.suggestions =
              scope.enteredtext.length === 0
                ? false
                : scope.filteredChoices.length > 0;
          } else if (type === "birdSize") {
            scope.filteredChoices = [{ id: "2011", title: "SIZE" }];
            scope.isVisible.suggestions =
              scope.enteredtext.length === 0
                ? false
                : scope.filteredChoices.length > 0;
          } else if (type === "birdLocation") {
            scope.filteredChoices = [{ id: "2012", title: "GEO" }];
            scope.isVisible.suggestions =
              scope.enteredtext.length === 0
                ? false
                : scope.filteredChoices.length > 0;
          }
        } else if (scope.board === "brand") {
          searchMenuRepository
            .getBrandingItems(type, scope.enteredtext)
            .then((availableItems) => {
              scope.filteredChoices = availableItems;
              scope.isVisible.suggestions =
                scope.enteredtext.length === 0
                  ? false
                  : availableItems.length > 0;
            });
        }
      };
      /**
       * Takes one based index to save selected choice object
       */
      scope.selectItem = (choice) => {
        scope.selected = choice;
        scope.enteredtext = choice.title;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };
    };

    return {
      restrict: "E",
      link: link,
      templateUrl: "app/directives/dbAutoComplete/dbAutoCompleteView.html",
      scope: {
        enteredtext: "=",
        selected: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        disabled: "=",
        disable: "=",
        board: "=",
      },
    };
  },
]);
