angular.module("app").directive("sportColorPieChart", function () {
  function link(scope) {
    const width = 1200;
    const height = 500;
    const margin = 40;

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    const radius = Math.min(width, height) / 2 - margin;

    // append the svg object to the div called 'my_pharma_dataviz'
    const svg = d3
      .select("#my_sport_color_dataviz")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    // Create chart data
    const colorData = {};
    const dataImageUrls = {};
    for (const item of scope.data) {
      colorData[item.title] = item.percentage;
      dataImageUrls[item.title] = item.img_url;
    }

    // Compute the position of each group on the pie:
    const pie = d3.layout
      .pie()
      .sort(null) // Do not sort group by size
      .value(function (d) {
        return d.value;
      });
    const data_ready = pie(d3.entries(colorData));

    // The arc generator
    const arc = d3.svg
      .arc()
      .innerRadius(radius * 0.7) // This is the size of the donut hole
      .outerRadius(radius * 0.8);

    // Another arc that won't be drawn. Just for labels positioning
    const outerArc = d3.svg
      .arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9);

    // Add the polylines between chart and labels:
    svg
      .selectAll("allPolylines")
      .data(data_ready)
      .enter()
      .append("polyline")
      .attr("stroke", "black")
      .style("fill", "none")
      .attr("stroke-width", 1)
      .attr("points", function (d) {
        const posA = arc.centroid(d); // line insertion in the slice
        const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
        const posC = outerArc.centroid(d); // Label position = almost the same as posB
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
        posC[0] = radius * 1.5 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left

        return [posA, posB, posC];
      });

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll("allSlices")
      .data(data_ready)
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", scope.selectedColor.hex)
      .attr(
        "stroke",
        scope.selectedColor.hex === "#ffffff" ? "#E0E0DD" : "white"
      )
      .style(
        "stroke-width",
        scope.selectedColor.hex === "#ffffff" ? "1px" : "5px"
      );

    svg
      .selectAll("allLabels")
      .data(data_ready)
      .enter()
      .append("text")
      .text((d) => `${d.data.value}%`)
      .attr("transform", function (d) {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = radius * 1.55 * (midangle < Math.PI ? 1 : -1);
        pos[1] += 8;

        return "translate(" + pos + ")";
      })
      .attr("font-size", "24px")
      .attr("font-weight", "600")
      .attr("style", "font-family: Gotham SSm A, Gotham SSm B")
      .style("text-anchor", function (d) {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? "start" : "end";
      });

    // Add the label Images:
    svg
      .selectAll("allLabelImagess")
      .data(data_ready)
      .enter()
      .append("image")
      .attr("href", function (d) {
        return dataImageUrls[d.data.key];
      })
      .attr("width", 40)
      .attr("height", 40)
      .attr("transform", function (d) {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = radius * 1.85 * (midangle < Math.PI ? 1 : -1);
        pos[0] += midangle < Math.PI ? 0 : -35;
        pos[1] = pos[1] - 20;

        return "translate(" + pos + ")";
      });

    svg
      .selectAll("allLabels")
      .data(data_ready)
      .enter()
      .append("text")
      .text(function (d) {
        return d.data.key;
      })
      .attr("transform", function (d) {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = (radius * 2.1) * (midangle < Math.PI ? 1 : -1);
        pos[1] += 4;

        return "translate(" + pos + ")";
      })
      .attr("font-size", "16px")
      .style("text-anchor", function (d) {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? "start" : "end";
      })
      .style("text-transform", "uppercase")
      .style("font-family", "Gotham SSm A, Gotham SSm B");

    svg
      .append("text")
      .text(scope.selectedColor.title)
      .attr("transform", "translate(0, -70)")
      .attr("font-size", "22px")
      .attr("style", "text-anchor: middle")
      .style("font-family", "Gotham SSm A, Gotham SSm B")
      .style("text-transform", "uppercase");

    svg
      .append("text")
      .text("IS MOST")
      .attr("transform", "translate(0, -40)")
      .attr("font-size", "22px")
      .attr("style", "text-anchor: middle")
      .style("font-family", "Gotham SSm A, Gotham SSm B");

    svg
      .append("text")
      .text("POPULAR")
      .attr("transform", "translate(0, -10)")
      .attr("font-size", "22px")
      .attr("style", "text-anchor: middle")
      .style("font-family", "Gotham SSm A, Gotham SSm B");

    svg
      .append("text")
      .text("AMONG")
      .attr("transform", "translate(0, 20)")
      .attr("font-size", "22px")
      .attr("style", "text-anchor: middle")
      .style("font-family", "Gotham SSm A, Gotham SSm B");

    svg
      .append("text")
      .text("HOCKEY")
      .attr("transform", "translate(0, 50)")
      .attr("font-size", "22px")
      .attr("style", "text-anchor: middle")
      .style("font-family", "Gotham SSm A, Gotham SSm B");

    svg
      .append("text")
      .text("TEAMS")
      .attr("transform", "translate(0, 80)")
      .attr("font-size", "22px")
      .attr("style", "text-anchor: middle")
      .style("font-family", "Gotham SSm A, Gotham SSm B");

    scope.$watch('selectedColor', () => {
      svg
        .selectAll("path")
        .remove();

      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", scope.selectedColor.hex)
        .attr(
          "stroke",
          scope.selectedColor.hex === "#ffffff" ? "#E0E0DD" : "white"
        )
        .style(
          "stroke-width",
          scope.selectedColor.hex === "#ffffff" ? "1px" : "5px"
        );

    })
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/dbSportColorPieChart/dbSportColorPieChartView.html",
    link: link,
    scope: {
      selectedColor: "=",
      data: "="
    },
  };
});
