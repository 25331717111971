// angular.module('app').directive('hueDashboardScroll', function () {
// 	function link(scope, element, attrs) {
// 		$(element).mCustomScrollbar({
// 			theme: 'minimal-dark',
// 			scrollInertia: 0,
// 			mouseWheel: {
// 				scrollAmount: 70
// 			}
// 		});
// 	}
//
// 	return {
// 		restrict: 'A',
// 		link: link
// 	};
// });
