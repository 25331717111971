angular.module("app").directive("brandIndustryColorComparison", () => {
  let link = (scope) => {
    scope.isLoadedAllData = false;
    scope.loadMoreIndustry = () => {
      //scope.isLoadedAllData = true;
      scope.loadMoreData();
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbBrandIndustryColorComparison/dbBrandIndustryColorComparisonView.html",
    link: link,
    scope: {
      selectedIndustry: "=",
      industryData: "=",
      loadMoreData: "=",
      isLoadedAllData: "=",
    }
  };
});
