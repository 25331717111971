angular.module("app").component("marketcapCompaniesComponent", {
  templateUrl: "app/components/marketcap-companies/marketcap-companies.tmpl.html",
  controller: function (
    $state,
    $http,
    appConfig
  ) {
    const vm = this;

    vm.init = function () {
    $http.get(appConfig.dashboardServiceUrl + "api/brands/marketcap_companies/charts")
      .then(function (res) {
        vm.result = res.data.result;
        vm.colorComparedInPeriodData = vm.result[0].chartData1;
        vm.selectedIndustry = vm.result[0].chartData2;
       
        vm.colorComparedInPeriodData1 = vm.result[1].chartData1;
        vm.selectedIndustry1 = vm.result[1].chartData2;
      });
    };
  },
});
