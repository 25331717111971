angular.module("app").directive("numberOfColorLogoAcrossAllIndustry", function () {
  function link(scope) {
  }

  const directive = {
    link: link,
    restrict: "E",
    replace: true,
    scope: {
      data: "=",
    },
    templateUrl:
      "app/directives/customInfographicsUnicornDirectives/numberOfColorLogoAcrossAllIndustry/numberOfColorLogoAcrossAllIndustry.html",
  };

  return directive;
});
