angular
  .module('app')
  .component('infographicsComponent', {
    templateUrl: 'app/components/infographics/infographics.tmpl.html',
    controller: function ($http, appConfig, modalService, categoryValues, $location, anchorSmoothScroll,
                          localStorageService) {
      var vm = this;
      vm.hueModel = 'VERTICAL';
      vm.yearModel = 'YEAR';
      vm.year = [];
      vm.hue = categoryValues('hue');
      vm.showTopicModal = false;
      vm.showDateModal = false;
      vm.selectedTopic = '';
      vm.selectedDate = '';
      vm.selectedYear = '';
      vm.pageData = {};
      vm.items = [];
      vm.flag = true;
      let numberOfElements = 12;
      let count = 1;
      vm.topic = [
        { index: 1, id: 'Fashion', title: 'FASHION', icon: '/assets/img/topic/fashion.png'},
        { index: 2, id: 'Brand', title: 'BRAND', icon: '/assets/img/topic/brand.png'},
        { index: 3, id: 'Auto', title: 'AUTO', icon: '/assets/img/topic/auto.png'},
        { index: 4, id: 'Art', title: 'ART', icon: '/assets/img/topic/art.png'},
        { index: 5, id: 'Pharma', title: 'PHARMA', icon: '/assets/img/topic/pharmaceutical.png'},
        { index: 6, id: 'Sport', title: 'SPORT', icon: '/assets/img/topic/sport.png'},
        { index: 7, id: 'Home', title: 'HOME', icon: '/assets/img/topic/home.png'},
        { index: 8, id: 'Legal', title: 'LEGAL', icon: '/assets/img/topic/legal.png'},
        { index: 9, id: 'Nature', title: 'NATURE', icon: '/assets/img/topic/nature.png'},
        { index: 10, id: 'Naming', title: 'NAMING', icon: '/assets/img/topic/color-naming.png'},
        { index: 11, id: 'Research', title: 'RESEARCH', icon: '/assets/img/topic/color-research.png'},
        { index: 12, id: 'Social', title: 'SOCIAL', icon: '/assets/img/topic/tracker.png'},
      ]
      vm.date = [
        { index: 1, id: 'January', title: 'JANUARY', isdisabled: false},
        { index: 2, id: 'February', title: 'FEBRUARY', isdisabled: false},
        { index: 3, id: 'March', title: 'MARCH', isdisabled: false},
        { index: 4, id: 'April', title: 'APRIL', isdisabled: false},
        { index: 5, id: 'May', title: 'MAY', isdisabled: false},
        { index: 6, id: 'June', title: 'JUNE', isdisabled: false},
        { index: 7, id: 'July', title: 'JULY', isdisabled: false},
        { index: 8, id: 'August', title: 'AUGUST', isdisabled: false},
        { index: 9, id: 'September', title: 'SEPTEMBER', isdisabled: false},
        { index: 10, id: 'October', title: 'OCTOBER', isdisabled: false},
        { index: 11, id: 'November', title: 'NOVEMBER', isdisabled: false},
        { index: 12, id: 'December', title: 'DECEMBER', isdisabled: false},
      ]

      vm.init = function () {
        const topic = vm.selectedTopic ? vm.selectedTopic.id : '';
        const date = vm.selectedDate ? vm.selectedDate.index : '';
        const year = vm.selectedYear ? vm.selectedYear : '';
        $http.get(appConfig.dashboardServiceUrl + `api/infographics.json?q[published_month_eq]=` + date + `&q[published_year_eq]=` + year + `&q[topic_eq]=` + topic)
          .then(function (res) {
            vm.pageData = res.data.infographics;
            vm.select();
          });
      };

      vm.sortItems = function () {
        vm.filterData.forEach(function (elem, index) {
          if (index > numberOfElements * count - 1) {
            elem.style = 'display: none';
            vm.flag = false;
          } else {
            elem.style = '';
            vm.flag = true;
          }
          elem.description = `<p>${elem.description}</p>`;
          elem.header = elem.header[0] + elem.header.slice(1).toLowerCase();
          vm.items.push(elem);
        });
      };

      vm.more = function () {
        vm.items = [];
        count++;
        vm.sortItems();
      };

      vm.onGraphicClick = function (event) {
        if (event) {
          modalService.showModal(2, event);
        }
      };

      vm.select = function () {
        if (vm.hue.includes(vm.hueModel) || vm.year.includes(Number(vm.yearModel))) {
          vm.filterData = angular.copy(vm.pageData.filter(function (t) {
            if ((!vm.hue.includes(vm.hueModel) || vm.hueModel === t.hue) &&
              (!vm.year.includes(Number(vm.yearModel)) || vm.yearModel === t.published_year)) {
              return t;
            }
          }));
        } else {
          vm.filterData = angular.copy(vm.pageData);
        }
        count = 1;
        vm.items = [];
        vm.sortItems();
      };

      vm.gotoElement = function (eID) {
        $location.hash('prefooter');
        anchorSmoothScroll.scrollTo(eID);
        $location.hash('');
      };

      vm.getUser = function () {
        return localStorageService.get('currentUser').id === undefined;
      };

      vm.makeDate = function (item) {
          return moment(item.published_year + '-' + item.published_month + '-' + item.published_day).format('MMMM D, YYYY');
      };
      vm.toggleTopicDetails = function() {
        vm.showTopicModal = !vm.showTopicModal;
      };
      vm.toggleDateDetails = function() {
        vm.showDateModal = !vm.showDateModal;
      };
      vm.filterDate = function(data, year) {
        vm.showDateModal = !vm.showDateModal;
        vm.selectedYear = year;
        vm.selectedDate = data;
        vm.init();
      };
      vm.filterTopic = function(data) {
        vm.showTopicModal = !vm.showTopicModal;
        vm.selectedTopic = data;
        vm.init();
      };
      vm.allPosts = function() {
        vm.selectedYear = '';
        vm.selectedDate = '';
        vm.selectedTopic = '';
        vm.init();
      };
    }
  });
