angular.module('app').directive('fashionSeasonComplete', ["brandingDashboardRepository",
function (
  brandingDashboardRepository
) { 
  function link (scope) {
      // dummy data
      // scope.filteredChoices = [
      //   {
      //     "id": 0,
      //     "title": "spring",
      //   },
      //   {
      //     "id": 1,
      //     "title": "resort",
      //   },
      //   {
      //     "id": 2,
      //     "title": "pre-fall",
      //   },
      //   {
      //     "id": 2,
      //     "title": "fall",
      //   },
      // ];

      scope.filteredChoices = [];

      scope.loadFashionSeasons = () => {
        scope.ajaxInProgress = true;
        brandingDashboardRepository.dashboard.getFashionSeasons().then(data => {  
          scope.filteredChoices= data.seasons;
        }).finally(() => {
          scope.ajaxInProgress = false;
        });
      };

      scope.loadFashionSeasons();

      scope.isVisible = {
        suggestions: false,
      };

      scope.filterItems = () => {
        if (scope.enteredtext.length < 0) return;
        scope.isVisible.suggestions =
          scope.enteredtext.length === 0
            ? false
            : scope.filteredChoices.length > 0;
      };

      scope.selectItem = (choice) => {
        scope.selected = choice;
        scope.enteredtext = choice.name;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbFashionComplete/fashionSeasonComplete/fashionSeasonCompleteView.html",
      link: link,
      scope: {
        enteredtext: "=",
        selected: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        disabled: "=",
        disable: "=",
        board: "=",
      }
    };
  }
]);
