angular.module("app").controller("fashionController", [
  "$scope",
  "dashboardRepository",
  "dashboardOverlayService",
  "dashboardService",
  "exportService",
  "searchMenuRepository",
  "$state",
  "anchorSmoothScroll",
  "$location",
  "$timeout",
  "$rootScope",
  (
    scope,
    dashboardRepository,
    dashboardOverlayService,
    dashboardService,
    exportService,
    searchMenuRepository,
    $state,
    anchorSmoothScroll,
    $location,
    $timeout,
    $rootScope
  ) => {
    scope.tempColor = null;

    scope.gotoElement = (eID) => {
      $location.hash("prefooter");
      anchorSmoothScroll.scrollTo(eID);
      $location.hash("");
    };

    scope.menus = {
      season: "",
      year: "",
      category: "",
      city: "",
      region: "",
      color: "",
      designer: "",
    };

    scope.disabledControls = {
      season: false,
      year: false,
      category: false,
      region: false,
      city: false,
      color: false,
      designer: false,
    };

    scope.controlsParams = () => {
      return {
        season_eq: scope.menus.season,
        year_eq: scope.menus.year,
        category_eq: scope.menus.category,
        region_eq: scope.menus.region,
        city_id_eq: scope.menus.city,
        color_standards: scope.menus.color,
        designer_id_eq: scope.menus.designer,
      };
    };

    scope.mainParam = null;
    scope.mainParamId = null;
    scope.secondaryParams = {};

    $rootScope.showDashboard = false;
    scope.title = scope.year;
    scope.subtitle = null;

    scope.showSeason = true;
    scope.showYear = true;
    scope.showCategory = true;
    scope.isLoadingControls = true;

    scope.$watch("showDashboard", (newValue, oldValue) => {
      if (newValue === false && newValue !== oldValue) {
        scope.mainParam = null;
        scope.menus = {
          season: "",
          year: "",
          category: "",
          city: "",
          region: "",
          color: "",
          designer: "",
        };

        searchMenuRepository.getControlsData("", true).then((data) => {
          scope.controlsData = data;
          scope.initStatesByQueryParams();
          scope.isLoadingControls = false;
        });
      }
    });

    // Pages info
    scope.seasonPageInfo = [
      {
        width: 1,
        type: "countTo",
        tooltip: "#cities",
        data: { subtitle: "Cities", count: 0, menuTab: "city" },
      },
      {
        width: 1,
        type: "countTo",
        tooltip: "#regions",
        data: { subtitle: "Regions", count: 0, menuTab: "region" },
      },
      {
        width: 1,
        type: "countTo",
        tooltip: "#designers",
        data: { subtitle: "Designers", count: 0, menuTab: "designer" },
      },
      {
        width: 1,
        type: "countTo",
        tooltip: "#colors_shades",
        data: { subtitle: "Colors", count: 0, menuTab: "color" },
      },
    ];
    scope.colorPageInfo = [
      { width: 2, type: "desc", tooltip: "#description", data: { text: null } },
      {
        width: 1,
        type: "countTo",
        tooltip: "#designers",
        data: { subtitle: "Designers", count: 0, menuTab: "designer" },
      },
      {
        width: 1,
        type: "countTo",
        tooltip: "#colors_shades",
        data: { subtitle: "Colors", count: 0, menuTab: "color" },
      },
    ];
    scope.yearPageInfo = [
      {
        width: 1,
        type: "countTo",
        tooltip: "#seasons",
        data: { subtitle: "Seasons", count: 0, menuTab: "season" },
      },
      {
        width: 1,
        type: "countTo",
        tooltip: "#cities",
        data: { subtitle: "Cities", count: 0, menuTab: "city" },
      },
      {
        width: 1,
        type: "countTo",
        tooltip: "#designers",
        data: { subtitle: "Designers", count: 0, menuTab: "designer" },
      },
      {
        width: 1,
        type: "countTo",
        tooltip: "#colors_shades",
        data: { subtitle: "Colors", count: 0, menuTab: "color" },
      },
    ];
    scope.designerPageInfo = [
      { width: 2, type: "desc", tooltip: "#description", data: { text: null } },
      {
        width: 1,
        type: "countTo",
        data: { subtitle: "Images", count: 0, menuTab: null },
      },
      {
        width: 1,
        type: "countTo",
        tooltip: "#colors_shades",
        data: { subtitle: "Colors", count: 0, menuTab: "color" },
      },
    ];
    scope.regionPageInfo = [
      {
        width: 1,
        type: "countTo",
        tooltip: "#cities",
        data: { subtitle: "Cities", count: 0, menuTab: "city" },
      },
      {
        width: 1,
        type: "countTo",
        tooltip: "#seasons",
        data: { subtitle: "Seasons", count: 0, menuTab: "season" },
      },
      {
        width: 1,
        type: "countTo",
        tooltip: "#designers",
        data: { subtitle: "Designers", count: 0, menuTab: "designer" },
      },
      {
        width: 1,
        type: "countTo",
        tooltip: "#colors_shades",
        data: { subtitle: "Colors", count: 0, menuTab: "color" },
      },
    ];
    scope.cityPageInfo = [
      { width: 2, type: "desc", tooltip: "#description", data: { text: null } },
      {
        width: 1,
        type: "countTo",
        tooltip: "#designers",
        data: { subtitle: "Designers", count: 0, menuTab: "designer" },
      },
      {
        width: 1,
        type: "countTo",
        tooltip: "#colors_shades",
        data: { subtitle: "Colors", count: 0, menuTab: "color" },
      },
    ];
    scope.categoryPageInfo = [
      { width: 2, type: "desc", tooltip: "#description", data: { text: null } },
      {
        width: 1,
        type: "countTo",
        tooltip: "#designers",
        data: { subtitle: "Designers", count: 0, menuTab: "designer" },
      },
      {
        width: 1,
        type: "countTo",
        tooltip: "#colors_shades",
        data: { subtitle: "Colors", count: 0, menuTab: "color" },
      },
    ];

    scope.topColorsData = [];
    scope.colorFrequencyData = [];
    scope.distributionByCategoryData = [];
    scope.colorFrequencyBySeasonData = [];
    scope.colorFrequencyByRegionData = [];
    scope.colorFrequencyByCityData = [];
    scope.topColorsByYearData = [];
    scope.colorPaletteData = [];
    scope.designerImagesData = [];
    scope.colorPaletteBucket = 38;

    scope.seasonParam = () => {
      scope.pageInfo = scope.seasonPageInfo;
      scope.mainParamId = scope.menus.season;
      scope.iconUrl = "assets/img/icons/seasons/" + scope.mainParamId + ".svg";
      scope.showSeason = false;
      scope.secondaryParams = {
        year: scope.menus.year,
        category_id: scope.menus.category,
        city_id: scope.menus.city,
      };
    };

    scope.yearParam = () => {
      scope.pageInfo = scope.yearPageInfo;
      scope.mainParamId = scope.menus.year;
      scope.showYear = false;
      scope.secondaryParams = {
        season_id: scope.menus.season,
        category_id: scope.menus.category,
        city_id: scope.menus.city,
      };
    };

    scope.categoryParam = () => {
      scope.pageInfo = scope.categoryPageInfo;
      scope.mainParamId = scope.menus.category;
      scope.iconUrl =
        "assets/img/icons/categories/" + scope.mainParamId + ".svg";
      scope.showCategory = false;
      scope.secondaryParams = {
        season_id: scope.menus.season,
        year: scope.menus.year,
        city_id: scope.menus.city,
      };
    };

    scope.regionParam = () => {
      scope.pageInfo = scope.regionPageInfo;
      scope.mainParamId = scope.menus.region;
      scope.iconUrl =
        "assets/img/icons/regions/wbg/" + scope.mainParamId + ".svg";
      scope.secondaryParams = {
        season_id: scope.menus.season,
        year: scope.menus.year,
        category_id: scope.menus.category,
      };
    };

    scope.cityParam = () => {
      scope.pageInfo = scope.colorPageInfo;
      scope.mainParamId = scope.menus.city;
      scope.iconUrl = "assets/img/icons/cities/" + scope.mainParamId + ".svg";
      scope.secondaryParams = {
        season_id: scope.menus.season,
        year: scope.menus.year,
        category_id: scope.menus.category,
      };
    };

    scope.colorParam = () => {
      scope.pageInfo = scope.colorPageInfo;
      scope.mainParamId = scope.menus.color;
      scope.secondaryParams = {
        season_id: scope.menus.season,
        year: scope.menus.year,
        category_id: scope.menus.category,
        city_id: scope.menus.city,
      };
    };

    scope.designerParam = () => {
      scope.pageInfo = scope.designerPageInfo;
      scope.mainParamId = scope.menus.designer;
      scope.secondaryParams = {
        season_id: scope.menus.season,
        year: scope.menus.year,
        category_id: scope.menus.category,
        city_id: scope.menus.city,
      };
    };

    scope.initStatesByQueryParams = () => {
      const {
        designer,
        color,
        category,
        season,
        year,
        region,
        city,
      } = $location.search();
      const availableParams = Object.keys($location.search()).length;
      scope.menus.season = Number(season) || "";
      scope.menus.designer = Number(designer) || "";
      scope.menus.color = Number(color) || "";
      scope.menus.category = Number(category) || "";
      scope.menus.year = Number(year) || "";
      scope.menus.region = Number(region) || "";
      scope.menus.city = Number(city) || "";
      if (availableParams) {
        if ($location.search().season !== "") {
          scope.mainParam = "season";
          scope.seasonParam();
        } else if ($location.search().year !== "") {
          scope.mainParam = "year";
          scope.yearParam();
        } else if ($location.search().category !== "") {
          scope.mainParam = "category";
          scope.categoryParam();
        } else if ($location.search().region !== "") {
          scope.mainParam = "region";
          scope.regionParam();
        } else if ($location.search().city !== "") {
          scope.mainParam = "city";
          scope.cityParam();
        } else if ($location.search().color !== "") {
          scope.mainParam = "color";
          scope.colorParam();
        } else if ($location.search().designer !== "") {
          scope.mainParam = "designer";
          scope.designerParam();
        } else {
          scope.mainParam = null;
          scope.menus = {
            season: "",
            year: "",
            category: "",
            city: "",
            region: "",
            color: "",
            designer: "",
          };
        }
        $timeout(() => scope.loadGraphics());
      }
    };

    searchMenuRepository.getControlsData("", true).then((data) => {
      scope.controlsData = data;
      scope.initStatesByQueryParams();
      scope.isLoadingControls = false;
    });

    scope.changeColorPaletteBucket = (value) => {
      if (value !== scope.colorPaletteBucket) {
        dashboardRepository[scope.mainParam]
          .getColorPalette(scope.mainParamId, scope.secondaryParams, value)
          .then((data) => {
            scope.colorPaletteData = data;
          });
      }
      scope.colorPaletteBucket = value;
    };

    scope.setColorAsMain = (color) => {
      scope.iconUrl = null;
      scope.secondaryParams = {};
      scope.mainParam = null;
      scope.tempColor = color;
      scope.menus = {
        season: "",
        year: "",
        category: "",
        city: "",
        region: "",
        color: "",
        designer: "",
      };
      scope.menus.color = color.id;
      scope.handleChangeControl("color");
      scope.loadGraphics();
    };

    window.sc = scope;

    const getParamText = (id, prop) => {
      try {
        let text = "";
        if (id == "") {
          return "all " + prop;
        }
        for (let i = 0; i < scope.controlsData[prop].length; i++) {
          const data = scope.controlsData[prop][i];
          if (data.id == id) {
            text = data.name;
            break;
          }
        }
        return text;
      } catch (error) {
        return "";
      }
    };

    scope.initStatesByQueryParams();

    scope.header_title = "";
    scope.loadGraphics = () => {
      // update header title
      $state.go("contentFashion");
      $timeout(() => scope.updateQueryBySelectors());
      $rootScope.showDashboard = true;

      const data = scope.controlsParams();

      scope.header_title =
        getParamText(data.season_eq, "seasons") +
        " " +
        getParamText(data.year_eq, "years") +
        " / " +
        getParamText(data.category_eq, "categories");

      if (data.region_id) {
        scope.header_title += " / " + getParamText(data.region_id, "regions");
        if (data.city_id_eq) {
          scope.header_title +=
            " / " + getParamText(data.city_id_eq, "cities");
        }
      } else {
        scope.header_title +=
          " / " + getParamText(data.city_id_eq, "cities");
      }

      scope.title = getParamText(data.season_eq, "seasons");
      scope.subtitle =
        getParamText(data.year_eq, "years") +
        " " +
        getParamText(data.category_eq, "categories");
      if (scope.mainParam) {
        $rootScope.showDashboard = true;
        dashboardOverlayService.loadingStart();

        dashboardService.getHeaderSubtitle(
          scope.menus.season,
          scope.menus.year,
          scope.menus.category,
          scope.showSeason,
          scope.showYear,
          scope.showCategory,
          function (data) {}
        );

        dashboardRepository[scope.mainParam]
          .getPageData(scope.mainParamId, scope.secondaryParams)
          .then((data) => {
            exportService.title = data.title;
            exportService.season = scope.season;
            exportService.year = scope.year;
            exportService.category = scope.category;

            if (scope.mainParam === "color") {
              scope.colorHex = data.hex;
              scope.pageInfo[0].data.text = data.description;
              scope.pageInfo[1].data.count = data.designerCount;
              scope.pageInfo[2].data.count = data.shadeCount;
            } else if (scope.mainParam === "season") {
              scope.pageInfo[0].data.count = data.cityCount;
              scope.pageInfo[1].data.count = data.regionCount;
              scope.pageInfo[2].data.count = data.designerCount;
              scope.pageInfo[3].data.count = data.colorCount;
            } else if (scope.mainParam === "year") {
              scope.pageInfo[0].data.count = data.seasonCount;
              scope.pageInfo[1].data.count = data.cityCount;
              scope.pageInfo[2].data.count = data.designerCount;
              scope.pageInfo[3].data.count = data.colorCount;
            } else if (scope.mainParam === "designer") {
              scope.pageInfo[0].data.text = data.description;
              scope.pageInfo[1].data.count = data.imageCount;
              scope.pageInfo[2].data.count = data.colorCount;
            } else if (scope.mainParam === "region") {
              scope.pageInfo[0].data.count = data.cityCount;
              scope.pageInfo[1].data.count = data.seasonCount;
              scope.pageInfo[2].data.count = data.designerCount;
              scope.pageInfo[3].data.count = data.colorCount;
            } else if (scope.mainParam === "city") {
              scope.pageInfo[0].data.text = data.description;
              scope.pageInfo[1].data.count = data.designerCount;
              scope.pageInfo[2].data.count = data.colorCount;
            } else if (scope.mainParam === "category") {
              scope.pageInfo[0].data.text = data.description;
              scope.pageInfo[1].data.count = data.designerCount;
              scope.pageInfo[2].data.count = data.colorCount;
            }
          });

        dashboardRepository[scope.mainParam]
          .getTopColors(scope.mainParamId, scope.secondaryParams)
          .then((data) => {
            scope.topColorsData = data;
          });

        if (scope.mainParam !== "color") {
          dashboardRepository[scope.mainParam]
            .getColorFrequency(scope.mainParamId, scope.secondaryParams)
            .then((data) => {
              scope.colorFrequencyData = data;
            });
        }

        dashboardRepository[scope.mainParam]
          .getTopColorsByYear(scope.mainParamId, scope.secondaryParams)
          .then((data) => {
            scope.topColorsByYearData = data;
          });

        dashboardRepository[scope.mainParam]
          .getColorPalette(
            scope.mainParamId,
            scope.secondaryParams,
            scope.colorPaletteBucket
          )
          .then((data) => {
            scope.colorPaletteData = data;
          });
        // --------

        if (scope.mainParam !== "city") {
          dashboardRepository[scope.mainParam]
            .getColorFrequencyByCity(scope.mainParamId, scope.secondaryParams)
            .then((data) => {
              scope.colorFrequencyByCityData = _.filter(data, (item) => {
                return item.colors.length > 0;
              });
            });
        }

        if (scope.mainParam !== "region") {
          dashboardRepository[scope.mainParam]
            .getColorFrequencyByRegion(scope.mainParamId, scope.secondaryParams)
            .then((data) => {
              scope.colorFrequencyByRegionData = data;
            });
        }

        if (scope.mainParam !== "color" && scope.mainParam !== "category") {
          dashboardRepository[scope.mainParam]
            .getDistributionByCategory(scope.mainParamId, scope.secondaryParams)
            .then((data) => {
              scope.distributionByCategoryData = data;
            });
        }

        if (scope.mainParam !== "season") {
          dashboardRepository[scope.mainParam]
            .getColorFrequencyBySeason(scope.mainParamId, scope.secondaryParams)
            .then((data) => {
              scope.colorFrequencyBySeasonData = _.filter(data, (item) => {
                return item.colors.length > 0;
              });
            });
        }

        // show color graph
        dashboardRepository["color"]
          .getColorFrequencyByCategory(scope.mainParamId, scope.secondaryParams)
          .then((data) => {
            scope.colorFrequencyByCategoryData = data;
          });
      }
    };

    scope.updateQueryBySelectors = () => {
      $location.search(scope.menus);
    };

    scope.handleChangeControl = (control) => {
      scope.isLoadingControls = true;
      scope.updateQueryBySelectors();

      if (!scope.mainParam) {
        scope.mainParam = control;
        scope.mainParamId = scope.menus[control];
        $timeout(() => scope.updateQueryBySelectors());
      }

      if (scope.mainParam && scope.menus[scope.mainParam] === "") {
        scope.mainParam = null;
        scope.menus = {
          season: "",
          year: "",
          category: "",
          city: "",
          region: "",
          color: "",
          designer: "",
        };
      }

      switch (scope.mainParam) {
        case "season":
          scope.seasonParam();
          break;

        case "color":
          scope.colorParam();
          break;

        case "year":
          scope.yearParam();
          break;

        case "designer":
          scope.designerParam();
          break;

        case "region":
          scope.regionParam();
          break;

        case "city":
          scope.cityParam();
          break;

        case "category":
          scope.categoryParam();
          break;

        default:
          $state.go("fashion");
          scope.menus = {
            season: "",
            year: "",
            category: "",
            city: "",
            region: "",
            color: "",
            designer: "",
          };
          scope.disabledControls = {
            season: false,
            year: false,
            category: false,
            region: false,
            city: false,
            color: false,
            designer: false,
          };
          scope.tempColor = null;
          scope.mainParam = null;
          scope.iconUrl = null;
          scope.secondaryParams = {};
          scope.showSeason = true;
          scope.showYear = true;
          scope.showCategory = true;
          $rootScope.showDashboard = false;
          scope.topColorsData = [];
          scope.topColorsByYearData = [];
          scope.designerImagesData = [];
          scope.colorPaletteData = [];
          scope.colorFrequencyByCityData = [];
          scope.colorFrequencyByRegionData = [];
          scope.colorFrequencyData = [];
          scope.distributionByCategoryData = [];
          scope.colorFrequencyBySeasonData = [];
          scope.colorFrequencyByCategoryData = [];
          break;
      }

      searchMenuRepository
        .getControlsData(scope.controlsParams(), true)
        .then((data) => {
          scope.controlsData = data;
          scope.isLoadingControls = false;

          if (scope.tempColor) {
            if (
              !scope.controlsData.colors.find((item) => {
                return item.id === scope.tempColor.id;
              })
            ) {
              scope.controlsData.colors.unshift(scope.tempColor);
              scope.menus.color = scope.tempColor.id;
              scope.tempColor = null;
            }
          }
        });
    };

    scope.$watch(
      () => {
        return dashboardOverlayService.showOverlay;
      },
      (newValue) => {
        $rootScope.showDashboardOverlay = newValue;
      }
    );
  },
]);
