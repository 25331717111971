angular.module("app").directive("fashionFrequentColorsAcrossSeasons", () => {
  let link = (scope) => {

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbFashionFrequentColorsAcrossRegions/dbFashionFrequentColorsAcrossRegionsView.html",
    link: link,
    scope: {
      data: "="
    }
  };
});
