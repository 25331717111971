(function () {
  "use strict";

  angular.module("app").directive("hueChartBySeasonFiveYears", [
    "common",
    "config",
    "chartsHelper",
    function (common, config, chartsHelper) {
      function link(scope, element) {
        scope.$watch("data", (newValue) => {
          if (newValue) {
            bindData();
          }
        });

        function bindData() {
          const containers = element.find(".f-season");
          if (scope.data) {
            scope.data.reverse();
          }
          _.each(containers, function (c, i) {
            const data = (scope.data || [])[i];
            if (!data) {
              return;
            }
            c = $(c);

            c.find("h3").text(getAbbr(data.season) + " "+  data.year);

            const containerUnique = c.find('[chart-type="unique"]').html("");
            let ch = new chartSpheric({
              data: data.chart,
              container: containerUnique[0],
              options: {},
            });

            const container = c.find('[chart-type="top4"]').html("");
            const colors = _.map(data.top_4, function (c) {
              const par = c.percentage.replace('%','')
              return {
                value: c.count,
                valueTitle: Math.round(par) + "%",
                value2: c.count, //Math.round(c.value * 1000),
                valueTitle2: Math.round(par),
                title: c.color,
                color: c.color,
              };
            });

            ch = new chartDotsHorizontal({
              data: colors,
              container: container[0],
              options: {},
            });
          });
          return;
          const n = Math.round(Math.random() * 4 - 0.5);
          const maxR = n !== 1 ? 255 : Math.round(Math.random() * 255 - 0.5);
          const maxG = n !== 2 ? 255 : Math.round(Math.random() * 255 - 0.5);
          const maxB = n !== 3 ? 255 : Math.round(Math.random() * 255 - 0.5);
          const count = Math.random() * 1000 + 100;
          for (let l = 0; l < count; l++) {
            const color = String.format(
              "rgb({0},{1},{2})",
              Math.round(Math.random() * maxR),
              Math.round(Math.random() * maxG),
              Math.round(Math.random() * maxB)
            );
            result.push({
              color: color,
              title: color,
            });
          }

          let ch = new chartSpheric({
            data: dataPrepared,
            container: container,
            options: {},
          });

          result.push({
            value: value,
            valueTitle: Math.round(value) + "%",
            value2: Math.round(Math.random() * 1000),
            valueTitle2: Math.round(Math.random() * 1000),
            title: _colors[i].title,
            color: _colors[i].color,
          });

          ch = new chartDotsHorizontal({
            data: dataPrepared,
            container: container,
            options: {},
          });
        }

        function getAbbr(value) {
          if (value === "Fall") {
            return "FW";
          } else if (value === "Pre-Fall") {
            return "PF";
          } else if (value === "Spring") {
            return "SS";
          } else if (value === "Resort") {
            return "RS";
          } else if (value === "SEASONS") {
            return "ALL";
          } else {
            return value;
          }
        }
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          data: "=",
        },
        templateUrl:
          "app/directives/custom-infographics-directives/chart-by-season-five-years.html",
      };

      return directive;
    },
  ]);

  angular.module("app").directive("hueChartBySeasonFiveYearsColors", [
    "common",
    "config",
    "chartsHelper",
    "reduceValue",
    "colorSortService",
    function (common, config, chartsHelper, reduceValue, colorSortService) {
      function link(scope, element, attributes) {
        scope.$watch("data", bindData);

        function bindData() {
          if (!scope.data) {
            return;
          }
          scope.palettes = scope.data.splice(14, 1)[0];
          const palettes = {};
          const keys = [];
          for (const k in scope.palettes) {
            keys.push(k);
          }
          keys.reverse().forEach(function (key) {
            palettes[key] = scope.palettes[key];
          });
          scope.palettes = palettes;

          scope.palettes[Object.keys(scope.palettes)[0]] = colorSortService(
            scope.palettes[Object.keys(scope.palettes)[0]],
            24
          );
          scope.mainSeason = scope.palettes[Object.keys(scope.palettes)[0]];
          scope.mainTitle = Object.keys(scope.palettes)[0];
          delete scope.palettes[Object.keys(scope.palettes)[0]];

          _.forEach(scope.palettes, function (value, key) {
            scope.palettes[key] = colorSortService(value, 21);
          });
          scope.mainSeason = _.chunk(scope.mainSeason, 4);

          const container = element.find('[chart-type="groups"]');
          const colors = _.map(scope.data, function (d) {
            const value = Math.round(d.percentage * 100);
            return {
              value: reduceValue.reduce(d.value, "1"),
              valueTitle: Math.round(d.percentage * 100) + "%",
              value2: reduceValue.reduce(d.value, "1"),
              valueTitle2: Math.round(d.percentage * 100),
              title: d.title,
              color: d.color,
            };
          });

          const ch = new chartDotsHorizontal({
            data: colors,
            container: container[0],
            options: {},
          });
        }
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          data: "=",
          mode: "=",
        },
        templateUrl:
          "app/directives/custom-infographics-directives/chart-by-season-five-years-colors.html",
      };

      return directive;
    },
  ]);
})();
