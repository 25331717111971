angular
  .module('app')
  .service('educationService', ['httpService', '$http', 'appConfig', 'authService', function (httpService, $http, appConfig, authService) {

    this.getPodcast = function (page) {
      return $http({
        url: appConfig.dashboardServiceUrl + 'api/products/podcasts?per_page=10&page=' + page,
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    }

    this.getAllCourses = function () {
      return $http({
        url: appConfig.dashboardServiceUrl + 'api/products/courses',
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    }

    this.getCourseDetails = function (id) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/products/course/${id}`,
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };

    this.getAllTeachingMaterial = function () {
      return $http({
        url: appConfig.dashboardServiceUrl + 'api/products/teaching_materials',
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    }

    this.getTeachingMaterialDetails = function (id) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/products/teaching_material/${id}`,
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };

  }]);
