(function () {
  angular.module('app').directive('globalAllColorLineChart', [
    function () {
      function link(scope, element) {
        chartBase(self);

        const data = {
          yAxis: [
            { title: 299, position: 10 },
            { title: 300, position: 20 },
            { title: 301, position: 30 },
            { title: 302, position: 40 },
            { title: 303, position: 50 }
          ],
        };

        function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); } 

        function bindData() {
          let tempChartData = scope.chartData.map(item => {
            let points = item.points.map(point => {
              let val = point.value;
              if (isNaN(val)) {
                val = isNaN(point) ? 0 : point;
              }
              // return {value: (300 + Math.random())}; // Uncomment this to check graph with dummy data
              return {value: val};
            });
            let timeValues = item.timeValues.map(val => {
              if(isNumber(val)) {
                return parseInt(val);
              } else {
                return val;
              }
            });
            return {
              color: item.color.hex,
              points,
              timeValues
            };
          });
          let tempAverageData = scope.averageData.map(item => isNaN(item) ? 0 : item);
          // Uncomment this section to use dummy data
          // tempAverageData = [];
          // for (let j = 0; j < 100; j++) {
          //   tempAverageData.push(300.1 + Math.sin(j / 100 * 2.5 * Math.PI) * 1.3);
          // }

          const container = element.find(`#my_global_All_line_chart`).html('');
          const width = $(container[0]).width();
          const svg = d3
            .select(container[0])
            .append('svg')
            .attr('width', '100%')
            .attr('height', '600');

          // get MIN and MAX value and gradient color
          let points = [];

          for (let k = 0; k < tempChartData.length; k++) {
            const gradientDefs = svg
              .append('defs')
              .append('linearGradient')
              .attr('id', `my_global_all_line_chart_${k}_gradient`)
              .attr('x1', '0%')
              .attr('y1', '0%')
              .attr('x2', '0%')
              .attr('y2', '100%');

            gradientDefs
              .append('stop')
              .attr('offset', '5%')
              .attr('stop-color', tempChartData[k].color)
              .attr('stop-opacity', '0.8');

            gradientDefs
              .append('stop')
              .attr('offset', '95%')
              .attr('stop-color', tempChartData[k].color)
              .attr('stop-opacity', '0');

            const chartPoints = tempChartData[k].points.map(item => item.value);
            points = [...points, ...chartPoints];
          }

          const MIN_POINT = Math.min(...points);
          const MAX_POINT = Math.max(...points);
          const INTERVAL = Math.round((MAX_POINT - MIN_POINT) / 4);
          for (let i = 0; i < 5; i++) {
            data.yAxis[i].title = (MIN_POINT + INTERVAL * i);
          }

          const g = svg
            .append('g')
            .attr('transform', 'translate(' + -10 + ',' + 580 + ')');

          // draw horizontal line
          g.append('line')
            .attr('x1', 60)
            .attr('x2', width)
            .attr('y1', -30)
            .attr('y2', -30)
            .style({stroke: '#ccc', 'stroke-width': 1});

          for (let j = 0; j < data.yAxis.length; j++) {
            g.append('line')
              .attr('x1', 60)
              .attr('x2', width - 30)
              .attr('y1', -30 - data.yAxis[j].position * 10)
              .attr('y2', -30 - data.yAxis[j].position * 10)
              .style({stroke: '#ccc', 'stroke-width': 1});

            g.append('text')
              .attr('text-anchor', 'middle')
              .attr('dominant-baseline', 'central')
              .attr('y', -30 - data.yAxis[j].position * 10)
              .attr('x', width - 10)
              .text(data.yAxis[j].title + "%");
          }

          if(tempChartData[0]){
            scope.periods = tempChartData[0].timeValues || [];

                      // draw vertical line
            for (let j = 0; j < tempChartData[0].timeValues.length; j++) {
              g.append('line')
                .attr('x1', 60 + j * (width - 60) / tempChartData[0].timeValues.length)
                .attr('x2', 60 + j * (width - 60) / tempChartData[0].timeValues.length)
                .attr('y1', -30)
                .attr('y2', -530)
                .style({stroke: '#ccc', 'stroke-width': 1});

              g.append('text')
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'central')
                .attr('y', -10)
                .attr('x', 60 + j * (width - 60) / tempChartData[0].timeValues.length)
                .text(tempChartData[0].timeValues[j])
                .attr('style', 'font-family: Gotham SSm A, Gotham SSm B; font-size: 14px;');
            }

            for (let k = 0; k < tempChartData.length; k++) {
              let chartData = tempChartData[k].points.slice(-tempChartData[0].timeValues.length);

              // draw charts
              const dx = (width - 90) / (chartData.length - 1);
              let points = '60, -30 ';
              for (let i = 0; i < chartData.length; i++) {
                const lastItem = i === chartData.length - 1;

                if (!lastItem) {
                  g.append("line")
                    .attr("x1", i * dx + 60)
                    .attr("x2", (i + 1) * dx + 60)
                    .attr("y1", -130 - (chartData[i].value - MIN_POINT) / INTERVAL * 100)
                    .attr("y2", -130 - (chartData[i + 1].value - MIN_POINT) / INTERVAL * 100)
                    .style({ stroke: tempChartData[k].color.hex, "stroke-width": 1 });
                }

                points += `${i * dx + 60},${-130 - (chartData[i].value - MIN_POINT) / INTERVAL * 100} `;
              }

              points += `${width - 30},-30`;

              g.append('polygon')
                .attr('points', points)
                .style('fill', `url(#my_global_all_line_chart_${k}_gradient)`);
            }

            // dummy average data
            const cnt = tempAverageData.length;
            const dx1 = (width - 90) / (cnt - 1);
            for (let i = 0; i < cnt; i++) {
              const lastItem = i === cnt - 1;

              if (!lastItem) {
                g.append("line")
                  .attr("x1", i * dx1 + 60)
                  .attr("x2", (i + 1) * dx1 + 60)
                  .attr("y1", -130 - (tempAverageData[i] - MIN_POINT) / INTERVAL * 100)
                  .attr("y2", -130 - (tempAverageData[i + 1]  - MIN_POINT) / INTERVAL * 100)
                  .style({ stroke: '#000', "stroke-width": 6, "stroke-dasharray": "1, 12", "stroke-linecap": "round" });
              }
            }
          }

        }

        scope.$watch("chartData", (newValue, oldValue) => {
          if (newValue) {
            bindData();
          }
        });

        // control modal
        scope.showImageDetails = false;

        scope.toggleImageDetails = () => {
          scope.showImageDetails = !scope.showImageDetails;
        };

        scope.imageClickHandler = index => {
          scope.index = index;
          scope.toggleImageDetails();
        };

        // scope.modalData = [
        //   {
        //     id: 0,
        //     title: '@FOXYYOUNGK_JAE',
        //     twitter: 'NAME@NAME',
        //     date: 'NOV 28, 2020 / 9:44 AM',
        //     description: 'WIZ KHALIFA PLAYING BLACK AND YELLOW',
        //     img_url: `/assets/img/global/mentions/1.png`
        //   }
        // ];
      }

      const directive = {
        link: link,
        restrict: 'E',
        replace: true,
        scope: {
          chartData: '=',
          averageData: '='
        },
        templateUrl:
          'app/directives/dbGlobalColorData/globalColorTracker/globalAllColorLineChart/globalAllColorLineChartView.html'
      };

      return directive;
    }
  ]);
})();
