angular.module("app").directive("circlepercentage", function () {
  function link(scope) {
    scope.radius = 60;
    const circumference = scope.radius * 2 * Math.PI;

    scope.value = Math.round(scope.value);
    scope.strokeDasharrayValue = `${circumference} ${circumference}`;
    scope.strokeDashoffsetValue =
      circumference - (scope.value / 100) * circumference;
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/circlePercentage/circlePercentage.html",
    link: link,
    scope: {
      value: "=",
      color: "=",
    },
  };
});
