angular.module('app').directive('globalColorTracker', [
  "trackDashboardRepository",
  (trackDashboardRepository) => {
    let link = scope => {
      scope.ajaxInProgress = false;
      scope.colors = [];
      scope.sentiments = 'positive,negative'
      scope.sources = 'instagram,twitter'
      scope.period = '1d';
      scope.dateFrom = null;
      scope.dateTo = null;
      scope.chartData = [];
      scope.filteredChartData = [];
      scope.averageChartData = [];

      scope.isAreaView = true;

      scope.monthData = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
      ];

      scope.abbreMonthData = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      scope.updateDate = () => {
        const now = new Date();
        scope.year = now.getFullYear();
        scope.month = now.getMonth();
        scope.day = now.getDate();
        scope.hour = now.getHours();
      };

      scope.updateDate();

      scope.selectedColors = [];
      scope.selectedColorIds = [];
      scope.setColors = colors => {
        scope.selectedColors = colors.map(color => color.hex);
        scope.selectedColorIds = colors.map(color => color.id);

        if (scope.mode === 'tracker') {
          scope.setMentionColors(colors);
        }

        scope.filteredChartData = scope.chartData.filter(item => {
          if (scope.selectedColors.indexOf(item.color.hex) !== -1) {
            return true;
          }
          return false;
        });
      };

      scope.setSentiments = (sentiments) => {
        scope.sentiments = sentiments;

        scope.loadChartsData();
      };

      scope.setSources = (sources) => {
        scope.sources = sources;

        scope.loadChartsData();
      };

      scope.setCustomDate = (from , to) => {
        scope.dateFrom = from;
        scope.dateTo = to;
        scope.period = null;

        scope.loadChartsData();
      };

      scope.setPeriod = period => {
        scope.period = period;
        scope.dateFrom = null;
        scope.dateTo = null;

        scope.loadChartsData();
      };

      scope.loadChartsData = () => {
        if (scope.ajaxInProgress) {
          return;
        }
        scope.ajaxInProgress = true;
        trackDashboardRepository.getChartsData(
          scope.selectedColorIds.length ? scope.selectedColorIds.toString() : 'pink,green,magenta',
          scope.period,
          scope.dateFrom,
          scope.dateTo,
          scope.sources,
          scope.sentiments
        ).then((data) => {
          scope.ajaxInProgress = false;
          scope.colors = data.colors;
          scope.chartData = data.chartData;
          scope.averageChartData = data.averageChartData;
          if(scope.chartData) {
            scope.filteredChartData = scope.chartData.filter(item => {
              if (scope.selectedColors.indexOf(item.color.hex) !== -1) {
                return true;
              }
              return false;
            });
          }
          
          scope.getAverageData();
        });

        // new header API bind
        trackDashboardRepository
          .getChartsHeaderData(
            scope.dateFrom,
            scope.dateTo,
            scope.sources,
            scope.sentiments
          )
          .then(data => {
            scope.headerData = data;
          });
      };

      scope.getAverageData = () => {
        scope.chartAverageData = [];
        // if (scope.averageChartData && scope.averageChartData.points.length) {
          // for (let i = 0; i < 30; i++) {
          //   scope.chartAverageData.push(0);
          // }
        // }
      };


      scope.getFormattedDate = () => {
        const month = scope.month + 1;
        const monthToString = month < 10 ? '0' + month : month;
        const dayToString = scope.day < 10 ? '0' + scope.day : scope.day;

        return `${scope.year}-${monthToString}-${dayToString}`;
      };

    };

    return {
      restrict: 'E',
      templateUrl: 'app/directives/dbGlobalColorData/globalColorTracker/globalColorTrackerView.html',
      link: link,
      scope: {
        mode: "=",
        setMentionColors: "=",
      }
    };
  }
]);
