angular.module("app").controller("fragranceController", [
  "$scope",
  "fragranceDashboardRepository",
  "dashboardOverlayService",
  "searchMenuRepository",
  "$timeout",
  "charts",
  "$state",
  "$location",
  "modalService",
  "localStorageService",
  function (
    scope,
    fragranceDashboardRepository,
    dashboardOverlayService,
    searchMenuRepository,
    timeout,
    charts,
    $state,
    $location,
    modalService,
    localStorageService
  ) {
    scope.menus = {
      designer: "",
      accord: "",
      country: "",
      year: "",
      color: "",
    };

    scope.disabledControls = {
      designer: false,
      accord: false,
      country: false,
      year: false,
      color: false,
    };

    scope.type = {
      designer: "designer",
      accord: "accord",
      country: "country",
      year: "year",
      color: "color",
    };

    if(localStorageService.get("currentUser")){
      scope.user = localStorageService.get("currentUser");
    }

    scope.params = $location.search();

    scope.$watch('params', function (params) {
     if (params.control === 'searchMenu') {
       scope.init();
     } else {
       scope.handleChangeControl(params.control, params);
     }
    });

    scope.disabledInput = (type, value) => {
      switch (type) {
        case "designer":
          scope.disableAccord = Boolean(value);
          scope.disableCountry = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "accord":
          scope.disableDesigner = Boolean(value);
          scope.disableCountry = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "country":
          scope.disableDesigner = Boolean(value);
          scope.disableAccord = Boolean(value);
          scope.disableYear = Boolean(value);
        case "year":
          scope.disableDesigner = Boolean(value);
          scope.disableAccord = Boolean(value);
          scope.disableCountry = Boolean(value);
          break;
      }
    };

    scope.mainParam = null;
    scope.mainParamId = null;
    scope.secondaryParams = {};
    scope.tab = 1;
    scope.currentPage = 0;

    scope.designer_place_holder = "SEARCH BY DESIGNER";
    scope.accord_place_holder = "SEARCH BY MAIN ACCORD";
    scope.country_place_holder = "SEARCH BY COUNTRY";
    scope.year_place_holder = "SEARCH BY YEAR";
    scope.header_title = "color: blue";

    scope.showDashboard = false;
    scope.title = scope.year;
    scope.subtitle = null;

    scope.isLoadingControls = true;
    scope.ajaxInProgress = false;

    scope.designerList = [];
    scope.accordList = [];
    scope.countryList = [];
    scope.yearList = [];
    scope.yearSelections = [];
    scope.showDropdown = true;
    scope.selectedYear = { name: moment().year(), id: moment().year() };
    scope.updateSelectedYear = function (year) {
      if(scope.searchMode === 'year') {
        scope.selectedYear = year;
        scope.selectedFilterValue = { title: year.name, id: year.id };
        scope.setTab(1);
      } else {
        scope.selectedYear = year;
        scope.setTab(1);
      }
      scope.loadGraphics();
    };

    scope.fragranceDashboardData = [];

    scope.loadSettings = () => {
      // scope.ajaxInProgress = true;
      fragranceDashboardRepository.getColors().then((data) => {
        scope.colors = data.colors;
      }).finally(() => {
        // scope.ajaxInProgress = false;
      });

      fragranceDashboardRepository.getMainAccord().then(data => { 
        scope.accordList = data.accords;
      }).finally(() => {
        // scope.ajaxInProgress = false;
      });

      fragranceDashboardRepository.getCountry().then(data => { 
        scope.countryList = data.countries;
      }).finally(() => {
        // scope.ajaxInProgress = false;
      });

      fragranceDashboardRepository.getYears().then(data => { 
        scope.yearList = data.years;
      }).finally(() => {
        // scope.ajaxInProgress = false;
      });
    };

    scope.loadSettings();

    scope.setback = () => {
      $state.go("fragrance", {control: "searchMenu", id: "", title: "", hex: "", name: ""});
    };

    scope.init = () => {
      scope.menus = {
        designer: "",
        accord: "",
        country: "",
        year: "",
        color: "",
      };
      scope.disableDesigner = false;
      scope.disableAccord = false;
      scope.disableCountry = false;
      scope.disableYear = false;
      scope.isLoadingControls = true;
      scope.currentPage = 0;
      scope.tab = 1;
      if(!scope.user.admin) {
        modalService.showModal(10, null, "We're working to finalize this dashboard and except it launched in Q2 of 2023.");
      }
    };

    scope.setTab = (tabId) => {
      scope.tab = tabId;
      scope.select_images = false;
      scope.selected_moodboard = false;
      scope.state = 1;
    };

    scope.isSet = (tabId) => {
      return scope.tab === tabId;
    };

    scope.loadGraphics = function () {
      if (scope.mainParam) {
        scope.showDashboard = true;
        dashboardOverlayService.loadingStart(4000);

        // ----------------------------------------

        if (scope.mainParam === "color") {
          scope.ajaxInProgress = true;
          fragranceDashboardRepository
            .getFragranceDashboard(
              scope.mainParam,
              scope.mainParamId,
              scope.currentPage + 1,
              30,
              scope.selectedYear.id
            )
            .then(function (data) {
              scope.fragranceDashboardData = data.perfumes;
              scope.pageCount = data.paginate.total_pages;
              scope.filteredChoices = data.years.map(item => {
                return { name: item, id: item };
              });
              scope.yearSelections = scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1); 
              scope.selectedYear = { name: data.year, id: data.year };
              if(scope.yearSelections.length === 0){
                scope.showDropdown = false;
              }
              scope.isLoadingControls = false;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          } else if (scope.mainParam === "designer") {
            scope.ajaxInProgress = true;
            fragranceDashboardRepository
            .getFragranceDashboard(
              scope.mainParam,
              scope.selectedFilterValue.title,
              scope.currentPage + 1,
              30,
              scope.selectedYear.id
            )
            .then(function (data) {
              scope.fragranceDashboardData = data.perfumes;
              scope.pageCount = data.paginate.total_pages;
              scope.filteredChoices = data.years.map(item => {
                return { name: item, id: item };
              });
              scope.yearSelections = scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1); 
              scope.selectedYear = { name: data.year, id: data.year };
              if(scope.yearSelections.length === 0){
                scope.showDropdown = false;
              }
              scope.isLoadingControls = false;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          } else if (scope.mainParam === "accord") {
            scope.ajaxInProgress = true;
            fragranceDashboardRepository
            .getFragranceDashboard(
              scope.mainParam,
              scope.mainParamId,
              scope.currentPage + 1,
              30,
              scope.selectedYear.id
            )
            .then(function (data) {
              scope.fragranceDashboardData = data.perfumes;
              scope.pageCount = data.paginate.total_pages;
              scope.filteredChoices = data.years.map(item => {
                return { name: item, id: item };
              });
              scope.yearSelections = scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1); 
              scope.selectedYear = { name: data.year, id: data.year };
              if(scope.yearSelections.length === 0){
                scope.showDropdown = false;
              }
              scope.isLoadingControls = false;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          } else if (scope.mainParam === "country") {
            scope.ajaxInProgress = true;
            fragranceDashboardRepository
            .getFragranceDashboard(
              scope.mainParam,
              scope.selectedFilterValue.title,
              scope.currentPage + 1,
              30,
              scope.selectedYear.id
            )
            .then(function (data) {
              scope.fragranceDashboardData = data.perfumes;
              scope.pageCount = data.paginate.total_pages;
              scope.filteredChoices = data.years.map(item => {
                return { name: item, id: item };
              });
              scope.yearSelections = scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1); 
              scope.selectedYear = { name: data.year, id: data.year };
              if(scope.yearSelections.length === 0){
                scope.showDropdown = false;
              }
              scope.isLoadingControls = false;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          } else if (scope.mainParam === "year") {
            scope.showDropdown = false;
            scope.ajaxInProgress = true;
            fragranceDashboardRepository
            .getFragranceDashboard(
              scope.mainParam,
              scope.mainParamId,
              scope.currentPage + 1,
              30,
              scope.selectedYear.id
            )
            .then(function (data) {
              scope.fragranceDashboardData = data.perfumes;
              scope.pageCount = data.paginate.total_pages;
              scope.filteredChoices = data.years.map(item => {
                return { name: item, id: item };
              });
              scope.yearSelections = scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1); 
              scope.selectedYear = { name: data.year, id: data.year };
              scope.isLoadingControls = false;
              if(scope.yearSelections.length === 0){
                scope.showDropdown = false;
              } 
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          }
      }
    };

    scope.setPage = (num) => {
      if (num >= 0 && num < scope.pageCount) {
        scope.currentPage = num;
        scope.loadGraphics();
      }
    };

    scope.handleChangeControl = function (control, item) {
      scope.header_title = () => {
        if (control === "color") {
          return `${control}: ${item.name}`;
        } else if (control === 'accord') {
          return `${control}: ${item.name}`;
        } else {
          return `${control}: ${item.title}`;
        }
      };
      scope.mainParam = control;
      scope.selectedFilterValue = item;
      scope.mainParamId = item.id;
      scope.searchMode = control;

      scope.loadGraphics();
    };

    scope.handleSearchChange = function (control, item) {
      if(item.name === 'medium yellow/green') {
        item.name = 'medium yellow - green'
      } else if(item.name === 'light yellow/green') {
        item.name = 'light yellow - green'
      } else if(item.name === 'dark yellow/green') {
        item.name = 'dark yellow - green'
      }

      $state.go("fragrance", Object.assign({control: control}, item));
    };

    scope.getResultsOnFilterSearch = (type, str, page) => {
      scope.ajaxInProgress = true;  
      switch (type) {
        case 'designer':
          if (!str.length) {
            scope.designerList = [];
            scope.ajaxInProgress = false;
            return;
          }

          fragranceDashboardRepository.getDesignerList(str)
            .then(data => {
              scope.designerList = [];
              scope.designerList = data.designers;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
        break; 
        default:
          scope.ajaxInProgress = false;
      }
    }; 

    scope.$watch(
      () => {
        return dashboardOverlayService.showOverlay;
      },
      (newValue) => {
        scope.showDashboardOverlay = newValue;
      }
    );
  },
]);
