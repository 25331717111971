(function () {
  'use strict';

  const serviceId = 'data';
  const apiRootUrl = 'https://huedata-fashion.herokuapp.com/api/';
  angular.module('app').factory(serviceId,
    [
      '$resource', 'common',
      function ($resource, common) {
        const resource = $resource(apiRootUrl + ':controller/:action/:id/:id1/:id2/:id3/:id4/:id5/:id6/:id7/:id8',
            {action: '@action', controller: '@controller'},
            {'put': {method: 'PUT', isArray: false, headers: {'Content-Type': 'application/json'}}},
            {'get': {method: 'GET', isArray: false, headers: {'Content-Type': 'application/json'}}},
            {'post': {method: 'POST', isArray: false, headers: {'Content-Type': 'application/json'}}},
            {'delete': {method: 'DELETE', isArray: false, headers: {'Content-Type': 'application/json'}}}
        );

        const $q = common.$q;

        function processContextAction() {
          const resourceMethod = arguments[0];
          const settings = arguments[1];
          const requestParams = arguments[2];
          const data = arguments[3];

          const params = [];

          const action = typeof (requestParams) === 'string'
              ? {action: requestParams}
              : requestParams;

          action.controller = action.controller || settings.controller;

          const def = $q.defer();

          params.push(action);
          if (data)
            params.push(data);

          resourceMethod.apply(resource, params)
            .$promise
            .then(function (result) {
                def.resolve(result);
              },
              function (e) {
                processRequestError(e);
                def.reject(e);
              });

          return def.promise;
        }

        function processRequestError(error) {
          console.log(error);
        }

        const context = function (settings) {
          const result = {
            save: function () {
              const params = [resource.save, settings || {}];
              params.push.apply(params, arguments);
              return processContextAction.apply(this, params);
            },
            get: function () {
              const params = [resource.get, settings];
              const requestDataParams = arguments[1] || {};
              requestDataParams.action = arguments[0];
              params.push(requestDataParams);
              return processContextAction.apply(this, params);
            }
          };

          return result;
        };

        const service = {
          resource: resource,
          context: context
        };

        function init() {

        }

        init();

        return service;
      }]);
}());
