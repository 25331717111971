angular
  .module('app')
  .component('subscriptionCancelComponent', {
    templateUrl: 'app/components/subscription-cancel/subscription-cancel.tmpl.html',
    controller: function ($state) {
        const vm = this;
        vm.goToPricing = function () {
            $state.go("pricing");
          };
    }
  });