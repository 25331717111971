angular.module("app").directive("hueDbDesignerImages", [
  "$timeout",
  "dashboardRepository",
  (timeout, dashboardRepository) => {
    let link = (scope) => {
      scope.data = [];
      scope.collectionData = [];
      scope.showCollection = false;
      scope.showImageDetails = false;
      scope.collectionLoading = false;
      scope.imageDetailsData = null;
      window.scr = scope;
      scope.currentPageForColection = 0;
      scope.page_limit = 18;
      scope.pageCountForCollection = 1;
      scope.currentPageForFashion = 0;
      scope.page_limit1 = 12;
      scope.pageCountForFashoin = 1;
      scope.collectionDataList = [];

      scope.loadData = () => {
        scope.paramsForNewApi = {
          season_eq: scope.menus.season,
          category_eq: scope.menus.category,
          year_eq: scope.menus.year,
          region_eq: scope.menus.region,
          city_id_eq: scope.menus.city,
          page: scope.currentPageForFashion + 1,
        };

        dashboardRepository
          .getFashionCollections(scope.paramsForNewApi)
          .then((data) => {
            scope.data = data;
            scope.pageCountForFashoin = scope.data.paginate.total_pages;
          });
      };

      scope.loadData();

      scope.getCollectionData = () => {
        dashboardRepository
          .getCollectionData(
            scope.collectionId,
            scope.currentPageForColection + 1
          )
          .then((data) => {
            scope.collectionLoading = false;
            scope.collectionData = data;
            scope.pageCountForCollection = data.paginate.total_pages;
            timeout(() => {
              scope.showCollection = true;
            }, 300);
          });
        scope.collectionLoading = true;
      };

      scope.setPageForCollection = (num) => {
        if (num >= 0 && num < scope.pageCountForCollection) {
          scope.currentPageForColection = num;
          scope.getCollectionData();
        }
      };

      scope.setPageForFashion = (num) => {
        if (num >= 0 && num < scope.pageCountForFashoin) {
          scope.currentPageForFashion = num;
          scope.loadData();
        }
      };

      scope.openFullCollection = (index) => {
        scope.collectionId = scope.data.collections[index].id;
        scope.getCollectionData();
      };

      scope.closeFullCollection = () => {
        scope.showCollection = false;
      };

      scope.toggleImageDetails = () => {
        scope.showImageDetails = scope.showImageDetails ? false : true;
      };

      scope.$on("mood_state_changed", () => {
        scope.toggleImageDetails();
      });

      scope.imageClickHandler = (index) => {
        let data = {
          index: index,
          data: [],
          collection: scope.collectionData.collection,
        };
        data.data = scope.collectionData.photos;
        scope.imageDetailsData = data;
        scope.toggleImageDetails();
      };
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbDesignerImages/dbDesignerImagesView.html",
      link: link,
      scope: {
        isExpanded: "=",
        singleDesigner: "=",
        yearId: "=",
        seasonId: "=",
        categoryId: "=",
        cityId: "=",
        regionId: "=",
        colorId: "=",
        menus: "=",
      },
    };
  },
]);
