angular.module("app").directive("colorShadesFrequency", () => {
  let link = (scope) => {
    scope.setWidth= percent => {
      let per = percent.replace('%', '');
      let percentage = parseFloat(per) + 0.4;
      return percentage +'%'
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorShadesFrequency/dbColorShadesFrequencyView.html",
    link: link,
    scope: {
      selectedColor: "=",
      data: "="
    }
  };
});
