// angular.module('app').directive('hueMcustomscrollbar', function () {
// 	function link(scope, element, attrs) {
// 		$(element).mCustomScrollbar();
// 	}
//
// 	return {
// 		restrict: 'A',
// 		link: link
// 	};
// });
