angular.module("app").service("customInfographicsConstants", [
  function () {
    this.isFilterVisible = (filterId, filters) => {
      const filterOptions = filters || {};
      let filter = filterOptions.all;
      if (!filter) {
        filter = filterOptions[filterId];
      }

      if (filter === true) {
        return true;
      } else if (angular.isFunction(filter)) {
        return filter();
      }

      return false;
    };

    this.menu = [
      { id: 1, title: "FASHION" },
      { id: 2, title: "BRANDS" },
      { id: 4, title: "CARS" },
      { id: 7, title: "ART" },
      { id: 9, title: "PULSE" }
    ];

    this.menuAdmin = [
      { id: 1, title: "FASHION" },
      { id: 2, title: "BRANDS" },
      { id: 3, title: "HOME" },
      { id: 4, title: "CARS" },
      { id: 5, title: "PHARMA" },
      { id: 6, title: "LEGAL" },
      { id: 7, title: "ART" },
      { id: 8, title: "NATURE" },
      { id: 9, title: "PULSE" },
      { id: 10, title: "SPORT" },
      { id: 11, title: "UNICORN" },
    ];

    // Unicorn dummy data
    // dummy data -> VOLUME OF UNICORNS USING BEIGE IN THEIR LOGOS OVER THE YEARS 
    this.unicornColorVolumeDataOverYears = {
      data: {
        color: { hex: '#f00' },
        points: [300, 300.3, 300.7, 302.5, 299.5, 300.4, 300.7, 299.6, 298, 301.2, 298.7, 300.6, 302.5, 303, 297, 300.5],
        timeValues: ['2009' ,'2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020']
      },
      averageData: [298, 298.37476262917147, 298.7362491053694, 299.071653589958, 299.3690942118574, 299.6180339887499, 299.80965410493206, 299.93716632225727, 299.99605345685654, 299.98422940262896, 299.9021130325903, 299.7526133600877, 299.5410264855516, 299.2748479794974, 298.96350734820345, 298.6180339887499, 298.2506664671286, 297.8744189609414, 297.5026202256703, 297.14844141686984, 296.8244294954151, 296.5420627451572, 296.31134414899594, 296.1404470282235, 296.0354254985426, 296, 298.0354254985426, 296.1404470282235, 296.31134414899594, 298.5420627451572, 298.8244294954151, 299.14844141686984, 299.5026202256703, 299.8744189609414, 300.2506664671286, 300.6180339887499, 300.96350734820345, 301.2748479794974, 301.5410264855516, 301.7526133600877, 301.9021130325903, 301.98422940262896, 301.99605345685654, 301.93716632225727, 301.80965410493206, 301.6180339887499, 301.3690942118574, 301.071653589958, 300.7362491053694, 300.37476262917147],
    };
    this.colorFrequencyData = {
      percentages: [
        ">18.44%",
        "9.83%-7.09%",
        "6.89%-4.76%",
        "9.83%",
      ],
      mostPopular: [
        {
          percentage: 20,
          title: "color1",
          color: { hex: "#fff6f9" }
        },
        {
          percentage: 20,
          title: "color2",
          color: { hex: "#f6bac6", hex1: "#ff0000" }
        },
        {
          percentage: 15,
          title: "color3",
          color: { hex: "#ff7f00" }
        },
        {
          percentage: 15,
          title: "color4",
          color: { hex: "#fff640", hex1: "#f3d725" }
        },
        {
          percentage: 15,
          title: "color5",
          color: { hex: "#2d9641" }
        },
        {
          percentage: 15,
          title: "color6",
          color: { hex: "#2065dc" }
        }
      ],
      moderate: [
        {
          percentage: 55,
          title: "color7",
          color: { hex: "#7d1f7f" }
        },
        {
          percentage: 45,
          title: "color8",
          color: { hex: "#644321" }
        },
      ],
      infrequent: [
        {
          percentage: 100,
          title: "color9",
          color: { hex: "#b9b9b8", hex1: "#2f52a0" }
        },
      ],
      not_used: [
        {
          percentage: 30,
          title: "color10",
          color: { hex: "#ffffff" }
        },
        {
          percentage: 70,
          title: "color11",
          color: { hex: "#020303" }
        },
      ]
    };

    this.colorLogoFrequencyData = {
      percentages: [
        8.44,
        8.44,
        8.44,
        8.44
      ],
      mostPopular: [
        {
          percentage: 20,
          title: "color1",
          hex: "#fff6f9"
        },
        {
          percentage: 20,
          title: "color2",
          hex: "#f6bac6"
        },
        {
          percentage: 15,
          title: "color3",
          hex: "#ff7f00"
        },
        {
          percentage: 15,
          title: "color4",
          hex: "#fff640"
        },
        {
          percentage: 15,
          title: "color5",
          hex: "#2d9641"
        },
        {
          percentage: 15,
          title: "color6",
          hex: "#2065dc"
        }
      ],
      moderate: [
        {
          percentage: 55,
          title: "color7",
          hex: "#7d1f7f"
        },
        {
          percentage: 45,
          title: "color8",
          hex: "#644321"
        },
      ],
      infrequent: [
        {
          percentage: 100,
          title: "color9",
          hex: "#b9b9b8"
        },
      ],
      not_used: [
        {
          percentage: 30,
          title: "color10",
          hex: "#ffffff"
        },
        {
          percentage: 70,
          title: "color11",
          hex: "#020303"
        },
      ]
    };

    this.selectedIndustryData = [
      {
        id: 1,
        name: "ARTIFICIAL INTELLIGENCE",
        logo_url: "/assets/img/unicorn/artificial.png",
        colorFamilyComparisonData: this.colorLogoFrequencyData
      },
      {
        id: 2,
        name: "AUTO & TRANSPORTATION",
        logo_url: "/assets/img/unicorn/auto.png",
        colorFamilyComparisonData: this.colorLogoFrequencyData
      },
      {
        id: 3,
        name: "CONSUMER & RETAIL",
        logo_url: "/assets/img/unicorn/consumer_retail.png",
        colorFamilyComparisonData: this.colorLogoFrequencyData
      }
    ];
    // dummy data -> industry logo list
    this.listAllIndustryData = [
      {
        title: "ARTIFICIAL INTELLIGENCE",
        img_url: "/assets/img/unicorn/search/artificial_intelligence.svg",
      },
      {
        title: "AUTO & TRANSPORTATION",
        img_url: "/assets/img/unicorn/search/auto_transporatation.svg",
      },
      {
        title: "CONSUMER & RETAIL",
        img_url: "/assets/img/unicorn/search/consumer.svg",
      },
      {
        title: "CYBERSECURITY",
        img_url: "/assets/img/unicorn/search/cybersecurity.svg",
      },
      {
        title: "DATA MANAGEMENT & ANALYTICS",
        img_url: "/assets/img/unicorn/search/data_managment.svg",
      },
      {
        title: "E-COMMERCE & DIRECT-TO-CONSUMER",
        img_url: "/assets/img/unicorn/search/ecommerce.svg",
      },
      {
        title: "EDTECH",
        img_url: "/assets/img/unicorn/search/edtech.svg",
      },
      {
        title: "FINTECH",
        img_url: "/assets/img/unicorn/search/fintech.svg",
      },
      {
        title: "FOOD",
        img_url: "/assets/img/unicorn/search/food.svg",
      },
      {
        title: "HARDWARE",
        img_url: "/assets/img/unicorn/search/hardware.svg",
      },
      {
        title: "HEALTH",
        img_url: "/assets/img/unicorn/search/health.svg",
      },
      {
        title: "INTERNET SOFTWARE & SERVICE",
        img_url: "/assets/img/unicorn/search/interner_software.svg",
      },
      {
        title: "MOBILE & TELECOMMUNICATIONS",
        img_url: "/assets/img/unicorn/search/mobile_telecommunication.svg",
      },
      {
        title: "OTHER",
        img_url: "/assets/img/unicorn/search/other.svg",
      },
      {
        title: "SUPPLY CHAIN, LOGISTICS & DELIVERY",
        img_url: "/assets/img/unicorn/search/supply_chain.svg",
      },
    ];
    this.selectedCountryData = [
      {
        id: 1,
        name: "Spain",
        logo_url: "/assets/img/unicorn/flag/spain.png",
        colorFamilyComparisonData: this.colorLogoFrequencyData
      },
      {
        id: 2,
        name: "Chile",
        logo_url: "/assets/img/unicorn/flag/chile.png",
        colorFamilyComparisonData: this.colorLogoFrequencyData
      },
      {
        id: 3,
        name: "Brazil",
        logo_url: "/assets/img/unicorn/flag/brazil.png",
        colorFamilyComparisonData: this.colorLogoFrequencyData
      }
    ];

    this.dataForLogos = [
      {
        percentage: "45",
        logo: "/assets/img/unicorn/artificial.png",
        title: "ARTIFICIAL INTELLIGENCE",
      },
      {
        percentage: "70",
        logo: "/assets/img/unicorn/auto.png",
        title: "AUTO & TRANSPORTATION",
      },
      {
        percentage: "20",
        logo: "/assets/img/unicorn/consumer_retail.png",
        title: "CONSUMER & RETAIL",
      },
      {
        percentage: "60",
        logo: "/assets/img/unicorn/cybersecurity.png",
        title: "CYBERSECURITY",
      },
      {
        percentage: "88",
        logo: "/assets/img/unicorn/data_mngt.png",
        title: "DATA MANAGEMENT & ANALYTICS",
      },
      {
        percentage: "10",
        logo: "/assets/img/unicorn/ecommerce.png",
        title: "E-COMMERCE & DIRECT-TO-CONSUMER",
      },
      {
        percentage: "50",
        logo: "/assets/img/unicorn/edtech.png",
        title: "EDTECH",
      },
      {
        percentage: "30",
        logo: "/assets/img/unicorn/finetch.png",
        title: "FINTECH",
      },
      {
        percentage: "60",
        logo: "/assets/img/unicorn/food.png",
        title: "FOOD",
      },
      {
        percentage: "20",
        logo: "/assets/img/unicorn/hardware.png",
        title: "HARDWARE",
      },
      {
        percentage: "60",
        logo: "/assets/img/unicorn/health.png",
        title: "HEALTH",
      },
      {
        percentage: "75",
        logo: "/assets/img/unicorn/internet_software.png",
        title: "INTERNET SOFTWARE & SERVICE",
      },
      {
        percentage: "40",
        logo: "/assets/img/unicorn/mobile_telecom.png",
        title: "MOBILE & TELECOMMUNICATIONS",
      },
      {
        percentage: "80",
        logo: "/assets/img/unicorn/other.png",
        title: "OTHER",
      },
      {
        percentage: "30",
        logo: "/assets/img/unicorn/supply_chain_logistics.png",
        title: "SUPPLY CHAIN, LOGISTICS & DELIVERY",
      }
    ];

    this.colorLogoAcrossIndustryData = [
      {
        name: "ARTIFICIAL INTELLIGENCE",
        img_url: "/assets/img/unicorn/artificial.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "AUTO & TRANSPORTATION",
        img_url: "/assets/img/unicorn/auto.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "CONSUMER & RETAIL",
        img_url: "/assets/img/unicorn/consumer_retail.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "CYBERSECURITY",
        img_url: "/assets/img/unicorn/cybersecurity.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "DATA MANAGEMENT & ANALYTICS",
        img_url: "/assets/img/unicorn/data_mngt.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "E-COMMERCE & DIRECT-TO-CONSUMER",
        img_url: "/assets/img/unicorn/ecommerce.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "EDTECH",
        img_url: "/assets/img/unicorn/edtech.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "FINTECH",
        img_url: "/assets/img/unicorn/finetch.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "FOOD",
        img_url: "/assets/img/unicorn/food.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "HARDWARE",
        img_url: "/assets/img/unicorn/hardware.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "HEALTH",
        img_url: "/assets/img/unicorn/health.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "INTERNET SOFTWARE & SERVICE",
        img_url: "/assets/img/unicorn/internet_software.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "MOBILE & TELECOMMUNICATIONS",
        img_url: "/assets/img/unicorn/mobile_telecom.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "OTHER",
        img_url: "/assets/img/unicorn/other.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "SUPPLY CHAIN, LOGISTICS & DELIVERY",
        img_url: "/assets/img/unicorn/supply_chain_logistics.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
    ];

    this.topFiveLogos = [
      {
        id: 0,  
        title: "ARTIFICIAL INTELLIGENCE",
        details: {
          percentage: "45",
          logo: "/assets/img/unicorn/artificial.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 1,
        title: "AUTO & TRANSPORTATION",
        details: {
          percentage: "70",
          logo: "/assets/img/unicorn/auto.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 2,
        title: "CONSUMER & RETAIL",
        details: {
          percentage: "20",
          logo: "/assets/img/unicorn/consumer_retail.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 3,
        title: "CYBERSECURITY",
        details: {
          percentage: "60",
          logo: "/assets/img/unicorn/cybersecurity.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 4,
        title: "DATA MANAGEMENT & ANALYTICS",
        details: {
          percentage: "88",
          logo: "/assets/img/unicorn/data_mngt.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 5,
        title: "E-COMMERCE & DIRECT-TO-CONSUMER",
        details: {
          percentage: "10",
          logo: "/assets/img/unicorn/ecommerce.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 6,
        title: "EDTECH",
        details: {
          percentage: "50",
          logo: "/assets/img/unicorn/edtech.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 7,
        title: "FINTECH",
        details: {
          percentage: "30",
          logo: "/assets/img/unicorn/finetch.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 8,
        title: "FOOD",
        details: {
          percentage: "60",
          logo: "/assets/img/unicorn/food.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 9,
        title: "HARDWARE",
        details: {
          percentage: "20",
          logo: "/assets/img/unicorn/hardware.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 10,
        title: "HEALTH",
        details: {
          percentage: "60",
          logo: "/assets/img/unicorn/health.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 11,
        title: "INTERNET SOFTWARE & SERVICE",
        details: {
          percentage: "75",
          logo: "/assets/img/unicorn/internet_software.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 12,   
        title: "MOBILE & TELECOMMUNICATIONS",
        details: {
          percentage: "40",
          logo: "/assets/img/unicorn/mobile_telecom.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 13,
        title: "OTHER",
        details: {
          percentage: "80",
          logo: "/assets/img/unicorn/other.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      },
      {
        id: 14,
        title: "SUPPLY CHAIN, LOGISTICS & DELIVERY",
        details: {
          percentage: "30",
          logo: "/assets/img/unicorn/supply_chain_logistics.png",
          colors: [
            {
              color: {
                hex: "#494897"
              },
              name: "dark black",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#33A068"
              },
              name: "medium gray",
              percentage: "11.0%"
            },
            {
              color: {
                hex: "#ED7628"
              },
              name: "light gray",
              percentage: "14.0%"
            },
            {
              color: {
                hex: "#A28134"
              },
              name: "medium blue",
              percentage: "25.0%"
            },
            {
              color: {
                hex: "#418BCB"
              },
              name: "blue",
              percentage: "25.0%"
            }
          ]
        }
      }
    ];

    this.yearList = [
      {
          "id": 1988,
          "title": "1988"
      },
      {
          "id": 1989,
          "title": "1989"
      },
      {
          "id": 1990,
          "title": "1990"
      },
      {
          "id": 1991,
          "title": "1991"
      },
      {
          "id": 1992,
          "title": "1992"
      },
      {
          "id": 1993,
          "title": "1993"
      },
      {
          "id": 1994,
          "title": "1994"
      },
      {
          "id": 1995,
          "title": "1995"
      },
      {
          "id": 1996,
          "title": "1996"
      },
      {
          "id": 1997,
          "title": "1997"
      },
      {
          "id": 1998,
          "title": "1998"
      },
      {
          "id": 1999,
          "title": "1999"
      },
      {
          "id": 2000,
          "title": "2000"
      },
      {
          "id": 2001,
          "title": "2001"
      },
      {
          "id": 2002,
          "title": "2002"
      },
      {
          "id": 2003,
          "title": "2003"
      },
      {
          "id": 2004,
          "title": "2004"
      },
      {
          "id": 2005,
          "title": "2005"
      },
      {
          "id": 2006,
          "title": "2006"
      },
      {
          "id": 2007,
          "title": "2007"
      },
      {
          "id": 2008,
          "title": "2008"
      },
      {
          "id": 2009,
          "title": "2009"
      },
      {
          "id": 2010,
          "title": "2010"
      },
      {
          "id": 2011,
          "title": "2011"
      },
      {
          "id": 2012,
          "title": "2012"
      },
      {
          "id": 2013,
          "title": "2013"
      },
      {
          "id": 2014,
          "title": "2014"
      },
      {
          "id": 2015,
          "title": "2015"
      },
      {
          "id": 2016,
          "title": "2016"
      },
      {
          "id": 2017,
          "title": "2017"
      },
      {
          "id": 2018,
          "title": "2018"
      },
      {
          "id": 2019,
          "title": "2019"
      },
      {
          "id": 2020,
          "title": "2020"
      },
      {
          "id": 2021,
          "title": "2021"
      }
    ];

    // end unicorn data

    this.dataForBrands = [
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-11.png",
        title: "ADVERTISING",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-12.png",
        title: "AEROSPACE & DEFENSE",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-13.png",
        title: "AGRICULTURE",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-11.png",
        title: "AIR FREIGHT & LOGISTICS",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-14.png",
        title: "AIRLINES",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-15.png",
        title: "APPAREL",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-16.png",
        title: "AUTO",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-17.png",
        title: "BANKS",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-18.png",
        title: "BIOTECHNOLOGY",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-19.png",
        title: "BUILDING MATERIALS",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-20.png",
        title: "CHEMICALS",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-28.png",
        title: "COMMERCIAL BANKS",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-21.png",
        title: "COMMERCIAL SERVICES & SUPPLIES",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-11.png",
        title: "COMMUNICATION EQUIPMENT",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-11.png",
        title: "COSMETICS / PERSONAL CARE",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-23.png",
        title: "DISTRIBUTION / WHOLESALE",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-11.png",
        title: "DIVERSIFIED CONSUMER SERVICES",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-11.png",
        title: "ELECTRICAL EQUIPMENT",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-24.png",
        title: "ELECTRONICS",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-25.png",
        title: "ENERGY",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-26.png",
        title: "ENGINEERING & CONSTRUCTION",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-27.png",
        title: "ENVIRONMENTAL CONTROL",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-11.png",
        title: "FINANCIAL SERVICES",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-29.png",
        title: "FOOD & BEVERAGES",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-30.png",
        title: "FOREST PRODUCTS & PAPER",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-11.png",
        title: "HAND / MACHINE TOOLS",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-32.png",
        title: "HARDWARE",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-33.png",
        title: "HEALTHCARE",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-34.png",
        title: "HOLDING COMPANIES",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-35.png",
        title: "HOME BUILDERS",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-36.png",
        title: "HOME FURNISHINGS",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-37.png",
        title: "HOUSEWARES",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-38.png",
        title: "INSURANCE",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-39.png",
        title: "IRON / STEEL",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-11.png",
        title: "IT SERVICES",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-40.png",
        title: "LEISURE TIME",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-11.png",
        title: "LIFE SCIENCES TOOLS & SERVICES",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-41.png",
        title: "LODGING",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-42.png",
        title: "MACHINERY",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-11.png",
        title: "MARINE",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-43.png",
        title: "MEDIA & ENTERTAINMENT",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-44.png",
        title: "MINING",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-45.png",
        title: "MISC MANUFACTURES",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-46.png",
        title: "OFFICE / BUSINESS EQUIP",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-47.png",
        title: "OIL&GAS",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-48.png",
        title: "PACKAGING & CONTAINERS",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-22.png",
        title: "PERSONAL PRODUCTS",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-49.png",
        title: "PHARMACEUTICALS",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-50.png",
        title: "PIPELINES",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-31.png",
        title: "PERSONAL SERVICES",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-51.png",
        title: "REAL ESTATE",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-52.png",
        title: "RETAIL",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-53.png",
        title: "SEMICONDUCTORS",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-54.png",
        title: "SHIPBUILDING",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-55.png",
        title: "SOFTWARE",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-56.png",
        title: "STORAGE/ WAREHOUSING",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-57.png",
        title: "TELECOMMUNICATIONS",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-58.png",
        title: "TEXTILES",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-11.png",
        title: "TOBACCO",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-59.png",
        title: "TOYS / GAMES / HOBBIES",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-60.png",
        title: "TRANSPORTATION",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-61.png",
        title: "TRUCKING & LEASING",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-62.png",
        title: "WATER",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
      },
    ];

    this.dataForBrandsIndustry = [
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-11.png",
        title: "Advertising",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-12.png",
        title: "Aerospace & Defense",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-13.png",
        title: "Agriculture",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-14.png",
        title: "Airlines",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-15.png",
        title: "Apparel",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/animal.png",
        title: "Animal Health",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/arts.png",
        title: "Arts, Entertainment & Recreation",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-16.png",
        title: "Automotive",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-17.png",
        title: "Banking",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-19.png",
        title: "Building Materials",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-18.png",
        title: "Business Services",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-20.png",
        title: "Chemical Manufacturing",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-21.png",
        title: "Commercial Services",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/consulting.png",
        title: "Consulting",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/consumer.png",
        title: "Consumer Services",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
        color5: "#ffddbc",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-41.png",
        title: "Cosmetics & Personal Care",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
        color5: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/education.png",
        title: "Education",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-24.png",
        title: "Electronics",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-25.png",
        title: "Energy",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-26.png",
        title: "Engineering & Construction",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",

      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-27.png",
        title: "Environmental Control",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/financial.png",
        title: "Financial Services",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-29.png",
        title: "Food & Beverages",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-30.png",
        title: "Forest Products & Paper",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/funeral.png",
        title: "Funeral Services",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/government.png",
        title: "Government",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-32.png",
        title: "Hand & Machine Tools",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-33.png",
        title: "Healthcare",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-34.png",
        title: "High Tech",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-35.png",
        title: "Home Furnishings",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-36.png",
        title: "Hospitality & Lodging",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-38.png",
        title: "Insurance",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-39.png",
        title: "Iron/Steel",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/legal.png",
        title: "Legal",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-42.png",
        title: "Machinery Manufacturing",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-43.png",
        title: "Media & Entertainment",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/membership.png",
        title: "Membership Organizations",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-44.png",
        title: "Mining",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-45.png",
        title: "Miscellaneous Manufacturing",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-46.png",
        title: "Office & Business Equip",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-47.png",
        title: "Oil & Gas",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-48.png",
        title: "Packaging & Containers",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-49.png",
        title: "Pharmaceuticals",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-51.png",
        title: "Real Estate",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-52.png",
        title: "Retail",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-53.png",
        title: "Scientific Research & Development",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
        color4: "#ff4ea1",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-54.png",
        title: "Shipbuilding",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
        color4: "#2dddff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-57.png",
        title: "Telecommunications",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#8000ef",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-58.png",
        title: "Textiles",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#2160ff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-59.png",
        title: "Toys, Games & Hobbies",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-60.png",
        title: "Transportation",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-61.png",
        title: "Travel & Leisure Time",
        color1: "#fbef34",
        color2: "#00fb7a",
        color3: "#2160ff",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-56.png",
        title: "Warehousing & Storage",
        color1: "#fbef34",
        color2: "#bc3c00",
        color3: "#8000ef",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-62.png",
        title: "Water",
        color1: "#fbef34",
        color2: "#bc3c00",
      },
      {
        percentage1: "30%",
        image1: "/assets/img/icons/infographics/pasted-image-23.png",
        title: "Wholesale & Distribution",
        color1: "#fbef34",
        color2: "#8000ef",
      },
    ];

    this.countries = [
      {
        country: "ARGENTINA",
        percentage: "15%",
      },
      {
        country: "AUSTRALIA",
        percentage: "15%",
      },
      {
        country: "AUSTRIA",
        percentage: "15%",
      },
      {
        country: "BAHRAIN",
        percentage: "15%",
      },
      {
        country: "BANGLADESH",
        percentage: "15%",
      },
      {
        country: "BARBADOS",
        percentage: "15%",
      },
      {
        country: "BELGIUM",
        percentage: "15%",
      },
      {
        country: "BERMUDA",
        percentage: "15%",
      },
      {
        country: "BOSNIA AND HERZEGOVINA",
        percentage: "15%",
      },
      {
        country: "BOTSWANA",
        percentage: "15%",
      },
      {
        country: "BRAZIL",
        percentage: "15%",
      },
      {
        country: "BRITISH VIRGIN ISLANDS",
        percentage: "15%",
      },
      {
        country: "BULGARIA",
        percentage: "15%",
      },
      {
        country: "CANADA",
        percentage: "15%",
      },
      {
        country: "CAYMAN ISLANDS",
        percentage: "15%",
      },
      {
        country: "CHILE",
        percentage: "15%",
      },
      {
        country: "COLOMBIA",
        percentage: "15%",
      },
      {
        country: "COTE D'IVOIRE",
        percentage: "15%",
      },
      {
        country: "CROATIA",
        percentage: "15%",
      },
      {
        country: "CURACAO",
        percentage: "15%",
      },
      {
        country: "CYPRUS",
        percentage: "15%",
      },
      {
        country: "DENMARK",
        percentage: "15%",
      },
      {
        country: "ECUADOR",
        percentage: "15%",
      },
      {
        country: "EGYPT",
        percentage: "15%",
      },
      {
        country: "ESTONIA",
        percentage: "15%",
      },
      {
        country: "FINLAND",
        percentage: "15%",
      },
      {
        country: "FRANCE",
        percentage: "15%",
      },
      {
        country: "GABON",
        percentage: "15%",
      },
      {
        country: "GEORGIA",
        percentage: "15%",
      },
      {
        country: "GERMANY",
        percentage: "15%",
      },
      {
        country: "GHANA",
        percentage: "15%",
      },
      {
        country: "GREECE",
        percentage: "15%",
      },
      {
        country: "GUERNSEY",
        percentage: "15%",
      },
      {
        country: "HONG KONG",
        percentage: "15%",
      },
      {
        country: "HUNGARY",
        percentage: "15%",
      },
      {
        country: "ICELAND",
        percentage: "15%",
      },
      {
        country: "INDIA",
        percentage: "15%",
      },
      {
        country: "INDONESIA",
        percentage: "15%",
      },
      {
        country: "IRELAND",
        percentage: "15%",
      },
      {
        country: "ISLE OF MAN",
        percentage: "15%",
      },
      {
        country: "ISRAEL",
        percentage: "15%",
      },
      {
        country: "ITALY",
        percentage: "15%",
      },
      {
        country: "JAMAICA",
        percentage: "15%",
      },
      {
        country: "JAPAN",
        percentage: "15%",
      },
      {
        country: "JERSEY",
        percentage: "15%",
      },
      {
        country: "JORDAN",
        percentage: "15%",
      },
      {
        country: "KAZAKHSTAN",
        percentage: "15%",
      },
      {
        country: "KENYA",
        percentage: "15%",
      },
      {
        country: "KUWAIT",
        percentage: "15%",
      },
      {
        country: "LATVIA",
        percentage: "15%",
      },
      {
        country: "LEBANON",
        percentage: "15%",
      },
      {
        country: "LITHUANIA",
        percentage: "15%",
      },
      {
        country: "LUXEMBOURG",
        percentage: "15%",
      },
      {
        country: "MACAU",
        percentage: "15%",
      },
      {
        country: "MALAYSIA",
        percentage: "15%",
      },
      {
        country: "MALTA",
        percentage: "15%",
      },
      {
        country: "MAURITIUS",
        percentage: "15%",
      },
      {
        country: "MEXICO",
        percentage: "15%",
      },
      {
        country: "MONACO",
        percentage: "15%",
      },
      {
        country: "MOROCCO",
        percentage: "15%",
      },
      {
        country: "NAMIBIA",
        percentage: "15%",
      },
      {
        country: "NEW ZEALAND",
        percentage: "15%",
      },
      {
        country: "NIGERIA",
        percentage: "15%",
      },
      {
        country: "NORTHERN MARIANA",
        percentage: "15%",
      },
      {
        country: "ISLANDS",
        percentage: "15%",
      },
      {
        country: "NORWAY",
        percentage: "15%",
      },
      {
        country: "OMAN",
        percentage: "15%",
      },
      {
        country: "PAKISTAN",
        percentage: "15%",
      },
      {
        country: "PANAMA",
        percentage: "15%",
      },
      {
        country: "PERU",
        percentage: "15%",
      },
      {
        country: "POLAND",
        percentage: "15%",
      },
      {
        country: "PORTUGAL",
        percentage: "15%",
      },
      {
        country: "PUERTO RICO",
        percentage: "15%",
      },
      {
        country: "QATAR",
        percentage: "15%",
      },
      {
        country: "ROMANIA",
        percentage: "15%",
      },
      {
        country: "RUSSIA",
        percentage: "15%",
      },
      {
        country: "SAUDI ARABIA",
        percentage: "15%",
      },
      {
        country: "SENEGAL",
        percentage: "15%",
      },
      {
        country: "SERBIA",
        percentage: "15%",
      },
      {
        country: "SINGAPORE",
        percentage: "15%",
      },
      {
        country: "SLOVAKIA",
        percentage: "15%",
      },
      {
        country: "SLOVENIA",
        percentage: "15%",
      },
      {
        country: "SOUTH AFRICA",
        percentage: "15%",
      },
      {
        country: "SOUTH KOREA",
        percentage: "15%",
      },
      {
        country: "SPAIN",
        percentage: "15%",
      },
      {
        country: "SRI LANKA",
        percentage: "15%",
      },
      {
        country: "SWEDEN",
        percentage: "15%",
      },
      {
        country: "SWITZERLAND",
        percentage: "15%",
      },
      {
        country: "TAIWAN",
        percentage: "15%",
      },
      {
        country: "TANZANIA THAILAND",
        percentage: "15%",
      },
      {
        country: "THE BAHAMAS",
        percentage: "15%",
      },
      {
        country: "THE CZECH REPUBLIC",
        percentage: "15%",
      },
      {
        country: "THE NETHERLANDS",
        percentage: "15%",
      },
      {
        country: "THE PEOPLE'S",
        percentage: "15%",
      },
      {
        country: "REPUBLIC OF CHINA",
        percentage: "15%",
      },
      {
        country: "THE PHILIPPINES",
        percentage: "15%",
      },
      {
        country: "THE UNITED ARAB",
        percentage: "15%",
      },
      {
        country: "THE UNITED KINGDOM",
        percentage: "15%",
      },
      {
        country: "THE UNITED STATES",
        percentage: "15%",
      },
      {
        country: "TOGO",
        percentage: "15%",
      },
      {
        country: "TRINIDAD AND TOBAGO",
        percentage: "15%",
      },
      {
        country: "TUNISIA",
        percentage: "15%",
      },
      {
        country: "TURKEY",
        percentage: "15%",
      },
      {
        country: "UGANDA",
        percentage: "15%",
      },
      {
        country: "UKRAINE",
        percentage: "15%",
      },
      {
        country: "VENEZUELA",
        percentage: "15%",
      },
      {
        country: "VIETNAM",
        percentage: "15%",
      },
      {
        country: "ZAMBIA",
        percentage: "15%",
      },
      {
        country: "ZIMBABWE",
        percentage: "15%",
      },
    ];

    this.alphabet = [
      "a",
      "à",
      "b",
      "c",
      "d",
      "e",
      "é",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "ö",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];

    this.atoz = [
      {id: 'a-z', title: 'A - Z'},
      {id: 'a', title: 'a'},
      {id: 'b', title: 'b'},
      {id: 'c', title: 'c'},
      {id: 'd', title: 'd'},
      {id: 'e', title: 'e'},
      {id: 'f', title: 'f'},
      {id: 'g', title: 'g'},
      {id: 'h', title: 'h'},
      {id: 'i', title: 'i'},
      {id: 'j', title: 'j'},
      {id: 'k', title: 'k'},
      {id: 'l', title: 'l'},
      {id: 'm', title: 'm'},
      {id: 'n', title: 'n'},
      {id: 'o', title: 'o'},
      {id: 'p', title: 'p'},
      {id: 'q', title: 'q'},
      {id: 'r', title: 'r'},
      {id: 's', title: 's'},
      {id: 't', title: 't'},
      {id: 'u', title: 'u'},
      {id: 'v', title: 'v'},
      {id: 'w', title: 'w'},
      {id: 'x', title: 'x'},
      {id: 'y', title: 'y'},
      {id: 'z', title: 'z'}
    ]
    this.citiesAbbrevs = {
      London: "LN",
      Milan: "MI",
      Paris: "PR",
      Berlin: "BR",
      NewYork: "NY",
      Mexico: "MX",
      RioDeJaneiro: "RJ",
      Seoul: "SE",
      Tokyo: "TK",
      SaoPaulo: "SP",
      Istanbul: "IS",
      Monaco: "MN",
      Florence: "FL",
      Rome: "RO",
      Kiev: "KI",
      LosAngeles: "LA",
      LakmeIndia: "LI",
      Copenhagen: "CP",
      Salzburg: "SA",
      Stockholm: "ST",
      Madrid: "MA",
      Sydney: "SY",
      Dubai: "DU",
      Kaliningrad: "KA",
      Moscow: "MO",
      PalmSprings: "PS",
      Cannes: "CN",
      Cambridge: "CB",
      Tbilisi: "TB",
      Havana: "HA",
      Kyoto: "KO",
      SaintPetersburg: "SG",
      Shanghai: "SH",
    };

    this.regionsAbbrevs = {
      AsiaAndPacific: "AP",
      Europe: "EU",
      SouthAmerica: "LA",
      NorthAmerica: "NA",
    };

    this.groupTitlesTemplates = {
      beige: {
        name: "#f5f5dc",
        template: "#f5f5{0}",
      },
      black: {
        name: "#000000",
        template: "#{0}{0}{0}",
      },
      blue: {
        name: "#0000ff",
        template: "#{0}{0}ff",
      },
      brown: {
        name: "#964b00",
        template: "#{0}{1}00",
      },
      cyan: {
        name: "#00ffff",
        template: "#{0}ffff",
      },
      gray: {
        name: "#c0c0c0",
        template: "#{0}{0}{0}",
      },
      green: {
        name: "#008000",
        template: "#{0}80{0}",
      },
      magenta: {
        name: "#ff00ff",
        template: "#ff{0}ff",
      },
      orange: {
        name: "#ff7f00",
        template: "#ff{0}00",
      },
      red: {
        name: "#ff0000",
        template: "#ff{0}{1}",
      },
      violet: {
        name: "#8f00ff",
        template: "#{0}00ff",
      },
      white: {
        name: "#ffffff",
        template: "#{0}{0}{0}",
      },
      yellow: {
        name: "#ffff00",
        template: "#ffff{0}",
      },
      yellowgreen: {
        name: "#8db600",
        template: "#8d{0}00",
      },
    };

    // dummy data -> top 30 pharmaceutical companies that use (color)
    this.pharmaCompanyColorData = [];
    for (let i = 0; i < 30; i++) {
      if (i % 4 === 0) {
        this.pharmaCompanyColorData.push({
          name: "PFIZER",
          img_url: "/assets/img/company/pfizer-logo.png",
          percentage: 30,
        });
      } else {
        this.pharmaCompanyColorData.push({
          name: "TEVA",
          img_url: "/assets/img/company/teva-logo.png",
          percentage: 99,
        });
      }
    }

    // dummy data -> color use for diamond shape pills
    this.pillShapeColorData = [
      {
        name: "red",
        hexValue: "#ff111a",
        percentage: 5,
        count: 35261,
      },
      {
        name: "orange",
        hexValue: "#ff7f1a",
        percentage: 20,
        count: 35261,
      },
      {
        name: "yellow",
        hexValue: "#f4d629",
        percentage: 34,
        count: 35261,
      },
      {
        name: "green",
        hexValue: "#037b03",
        percentage: 5,
        count: 35261,
      },
      {
        name: "blue",
        hexValue: "#114394",
        percentage: 10,
        count: 35261,
      },
      {
        name: "violet",
        hexValue: "#78177a",
        percentage: 99,
        count: 35261,
      },
      {
        name: "magenta",
        hexValue: "#e896bc",
        percentage: 5,
        count: 35261,
      },
      {
        name: "brown",
        hexValue: "#5f3e19",
        percentage: 64,
        count: 35261,
      },
      {
        name: "gray",
        hexValue: "#b3b3b2",
        percentage: 5,
        count: 35261,
      },
      {
        name: "white",
        hexValue: "#f5f6f6",
        percentage: 5,
        count: 35261,
      },
      {
        name: "black",
        hexValue: "#000000",
        percentage: 99,
        count: 35261,
      },
    ];

    // dummy data -> use of (color) by pill shape
    this.colurUseDataByPillShape = [
      {
        name: "ROUND",
        img_url: "/assets/img/shapes/round.png",
        count: 12456,
        percentage: 99,
      },
      {
        name: "OVAL",
        img_url: "/assets/img/shapes/oval.png",
        count: 12456,
        percentage: 5,
      },
      {
        name: "CAPSULE",
        img_url: "/assets/img/shapes/capsule.png",
        count: 12456,
        percentage: 15,
      },
      {
        name: "RECTANGLE",
        img_url: "/assets/img/shapes/rectangle.png",
        count: 12456,
        percentage: 99,
      },
      {
        name: "TRIANGLE",
        img_url: "/assets/img/shapes/triangle.png",
        count: 12456,
        percentage: 5,
      },
      {
        name: "HEXAGON",
        img_url: "/assets/img/shapes/hexagon.png",
        count: 12456,
        percentage: 15,
      },
      {
        name: "DIAMOND",
        img_url: "/assets/img/shapes/diamond.png",
        count: 12456,
        percentage: 5,
      },
      {
        name: "PENTAGON",
        img_url: "/assets/img/shapes/pentagon.png",
        count: 12456,
        percentage: 15,
      },
      {
        name: "SQUARE",
        img_url: "/assets/img/shapes/square.png",
        count: 12456,
        percentage: 99,
      },
      {
        name: "BULLET",
        img_url: "/assets/img/shapes/bullet.png",
        count: 12456,
        percentage: 5,
      },
      {
        name: "FREEFORM",
        img_url: "/assets/img/shapes/freeform.png",
        count: 12456,
        percentage: 15,
      },
      {
        name: "OCTAGON",
        img_url: "/assets/img/shapes/octagon.png",
        count: 12456,
        percentage: 5,
      },
      {
        name: "TEAR",
        img_url: "/assets/img/shapes/tear.png",
        count: 12456,
        percentage: 99,
      },
      {
        name: "DOUBLE-CIRCLE",
        img_url: "/assets/img/shapes/double-circle.png",
        count: 12456,
        percentage: 5,
      },
      {
        name: "SEMI-CIRCLE",
        img_url: "/assets/img/shapes/semi-circle.png",
        count: 12456,
        percentage: 15,
      },
      {
        name: "CLOVER",
        img_url: "/assets/img/shapes/clover.png",
        count: 12456,
        percentage: 15,
      },
    ];

    // dummy data -> (color) trademarks across all product and service categories
    this.colorTrademarkDataAcrossProduct = [
      {
        name: "chemicals",
        img_url: "/assets/img/legals/products/chemicals.png",
        percentage: 30
      },
      {
        name: "paints",
        img_url: "/assets/img/legals/products/paints.png",
        percentage: 30
      },
      {
        name: "cosmetics and cleaning products",
        img_url: "/assets/img/legals/products/cosmetics-and-cleaning-products.png",
        percentage: 30
      },
      {
        name: "lubricants and fuels",
        img_url: "/assets/img/legals/products/lubricants-and-fuels.png",
        percentage: 60
      },
      {
        name: "pharmaceuticals",
        img_url: "/assets/img/legals/products/pharmaceuticals.png",
        percentage: 40
      },
      {
        name: "metal goods",
        img_url: "/assets/img/legals/products/metal-goods.png",
        percentage: 50
      },
      {
        name: "machinery",
        img_url: "/assets/img/legals/products/machinery.png",
        percentage: 85
      },
      {
        name: "hand tools",
        img_url: "/assets/img/legals/products/hand-tools.png",
        percentage: 35
      },
      {
        name: "electrical and scientific apparatus",
        img_url: "/assets/img/legals/products/electrical-and-scientific-appratus.png",
        percentage: 15
      },
      {
        name: "medical apparatus",
        img_url: "/assets/img/legals/products/medical-apparatus.png",
        percentage: 67
      },
      {
        name: "environmental control apparatus",
        img_url: "/assets/img/legals/products/environmental-control-apparatus.png",
        percentage: 50
      },
      {
        name: "vehicles",
        img_url: "/assets/img/legals/products/vehicles.png",
        percentage: 40
      },
      {
        name: "firearms",
        img_url: "/assets/img/legals/products/firearms.png",
        percentage: 90
      },
      {
        name: "jewelry",
        img_url: "/assets/img/legals/products/jewelry.png",
        percentage: 20
      },
      {
        name: "musical instruments",
        img_url: "/assets/img/legals/products/musical-instruments.png",
        percentage: 40
      },
      {
        name: "paper goods and printed matter",
        img_url: "/assets/img/legals/products/paper-goods-and-printed-matter.png",
        percentage: 10
      },
      {
        name: "rubber goods",
        img_url: "/assets/img/legals/products/rubber-goods.png",
        percentage: 100
      },
      {
        name: "leather goods",
        img_url: "/assets/img/legals/products/leather-goods.png",
        percentage: 70
      },
      {
        name: "toys and sporting goods",
        img_url: "/assets/img/legals/products/toys-and-sporting-goods.png",
        percentage: 70
      },
      {
        name: "meats and processed foods",
        img_url: "/assets/img/legals/products/meats-and-processed-foods.png",
        percentage: 30
      },
      {
        name: "staple foods",
        img_url: "/assets/img/legals/products/staple-foods.png",
        percentage: 75
      },
      {
        name: "natural agricultural products",
        img_url: "/assets/img/legals/products/natural-agricultural-products.png",
        percentage: 40
      },
      {
        name: "light beverages",
        img_url: "/assets/img/legals/products/light-beverages.png",
        percentage: 50
      },
      {
        name: "wines and sprits",
        img_url: "/assets/img/legals/products/wines-and-spirits.png",
        percentage: 100
      },
      {
        name: "smokers articles",
        img_url: "/assets/img/legals/products/smokers-articles.png",
        percentage: 30
      },
      {
        name: "advertising and business",
        img_url: "/assets/img/legals/products/advertising-and-business.png",
        percentage: 70
      },
      {
        name: "insurance and financial",
        img_url: "/assets/img/legals/products/insurance-and-financial.png",
        percentage: 70
      },
      {
        name: "non-metalic building materials",
        img_url: "/assets/img/legals/products/non-metalic-building-materials.png",
        percentage: 60
      },
      {
        name: "toys and sporting goods",
        img_url: "/assets/img/legals/products/toys-and-sporting-goods.png",
        percentage: 70
      },
      {
        name: "meats and processed foods",
        img_url: "/assets/img/legals/products/meats-and-processed-foods.png",
        percentage: 70
      },
      {
        name: "staple foods",
        img_url: "/assets/img/legals/products/staple-foods.png",
        percentage: 70
      },
      {
        name: "natural agricultural products",
        img_url: "/assets/img/legals/products/natural-agricultural-products.png",
        percentage: 100
      },
      {
        name: "light beverages",
        img_url: "/assets/img/legals/products/light-beverages.png",
        percentage: 20
      },
      {
        name: "wines and spirits",
        img_url: "/assets/img/legals/products/wines-and-spirits.png",
        percentage: 90
      },
      {
        name: "smokers articles",
        img_url: "/assets/img/legals/products/smokers-articles.png",
        percentage: 70
      },
      {
        name: "advertising and business",
        img_url: "/assets/img/legals/products/advertising-and-business.png",
        percentage: 40
      },
      {
        name: "insurance and financial",
        img_url: "/assets/img/legals/products/insurance-and-financial.png",
        percentage: 20
      },
      {
        name: "non-metallic building materials",
        img_url: "/assets/img/legals/products/non-metalic-building-materials.png",
        percentage: 40
      },
      {
        name: "furniture and articles not otherwise",
        img_url: "/assets/img/legals/products/furniture-and-articles.png",
        percentage: 70
      },
      {
        name: "housewares and glass",
        img_url: "/assets/img/legals/products/housewares-and-glass.png",
        percentage: 60
      },
      {
        name: "cordage and fibers",
        img_url: "/assets/img/legals/products/cordage-and-fibers.png",
        percentage: 10
      },
      {
        name: "yarns and threads",
        img_url: "/assets/img/legals/products/yarns-and-threads.png",
        percentage: 70
      },
      {
        name: "fabrics",
        img_url: "/assets/img/legals/products/fabrics.png",
        percentage: 30
      },
      {
        name: "clothing",
        img_url: "/assets/img/legals/products/clothing.png",
        percentage: 70
      },
      {
        name: "fancy goods",
        img_url: "/assets/img/legals/products/fancy-goods.png",
        percentage: 70
      },
      {
        name: "floor coverings",
        img_url: "/assets/img/legals/products/floor-coverings.png",
        percentage: 70
      },
      {
        name: "construction and repair",
        img_url: "/assets/img/legals/products/construction-and-repair.png",
        percentage: 10
      },
      {
        name: "communication",
        img_url: "/assets/img/legals/products/communication.png",
        percentage: 70
      },
      {
        name: "transporation and storage",
        img_url: "/assets/img/legals/products/transporation-and-storage.png",
        percentage: 20
      },
      {
        name: "material treatment",
        img_url: "/assets/img/legals/products/material-treatment.png",
        percentage: 10
      },
      {
        name: "education and entertainment",
        img_url: "/assets/img/legals/products/education-and-entertainment.png",
        percentage: 100
      },
      {
        name: "computer, scientific and legal",
        img_url: "/assets/img/legals/products/computer-scientific-and-legal.png",
        percentage: 70
      },
      {
        name: "hotels and restaurants",
        img_url: "/assets/img/legals/products/hotels-and-restaurants.png",
        percentage: 70
      },
      {
        name: "medical, beauty and agricultural",
        img_url: "/assets/img/legals/products/medical-beauty-and-agricultural.png",
        percentage: 30
      },
      {
        name: "staple foods",
        img_url: "/assets/img/legals/products/personal.png",
        percentage: 50
      },
    ];

    // dummy data -> (color) trademarks granted over five years
    this.colorTrademarkDataOverFiveYears = [
      {
        items: [
          [ "2015", "50" ],
          [ "2016", "60" ],
          [ "2017", "30" ],
          [ "2018", "80" ],
          [ "2019", "10" ],
          [ "2020", "70" ],
        ],
        color: {
          color: {hex: "#fff6f9"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "25" ],
          [ "2017", "40" ],
          [ "2018", "50" ],
          [ "2019", "80" ],
          [ "2020", "100" ],
        ],
        color: {
          color: {hex: "#f6bac6", hex1: "#ff0000"}
        }
      },
      {
        items: [
          [ "2015", "90" ],
          [ "2016", "90" ],
          [ "2017", "40" ],
          [ "2018", "80" ],
          [ "2019", "10" ],
          [ "2020", "50" ],
        ],
        color: {
          color: {hex: "#ff7f00"}
        }
      },
      {
        items: [
          [ "2015", "15" ],
          [ "2016", "25" ],
          [ "2017", "35" ],
          [ "2018", "50" ],
          [ "2019", "60" ],
          [ "2020", "90" ],
        ],
        color: {
          color: {hex: "#fff640", hex1: "#f3d725"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "15" ],
          [ "2017", "75" ],
          [ "2018", "45" ],
          [ "2019", "60" ],
          [ "2020", "20" ],
        ],
        color: {
          color: {hex: "#2d9641"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "80" ],
          [ "2017", "20" ],
          [ "2018", "75" ],
          [ "2019", "40" ],
          [ "2020", "45" ],
        ],
        color: {
          color: {hex: "#2065dc"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "40" ],
          [ "2017", "50" ],
          [ "2018", "15" ],
          [ "2019", "88" ],
          [ "2020", "43" ],
        ],
        color: {
          color: {hex: "#7d1f7f"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "45" ],
          [ "2017", "15" ],
          [ "2018", "95" ],
          [ "2019", "28" ],
          [ "2020", "36" ],
        ],
        color: {
          color: {hex: "#644321"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "16" ],
          [ "2017", "45" ],
          [ "2018", "55" ],
          [ "2019", "68" ],
          [ "2020", "76" ],
        ],
        color: {
          color: {hex: "#b9b9b8"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "23" ],
          [ "2017", "42" ],
          [ "2018", "59" ],
          [ "2019", "48" ],
          [ "2020", "66" ],
        ],
        color: {
          color: {hex: "#FFFFFF"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "47" ],
          [ "2017", "54" ],
          [ "2018", "19" ],
          [ "2019", "69" ],
          [ "2020", "99" ],
        ],
        color: {
          color: {hex: "#020303"}
        }
      },
    ];

    // dummy data -> (color) combination for (color) used by (period) artists
    this.colorCombinationDataForArt = [
        {
          id: 0,
          hex: '#f0b7c2',
          title: 'color1',
          count: 31,
          percent: 70,
        },
        {
          id: 1,
          hex: '#d62421',
          title: 'color2',
          count: 31,
          percent: 40,
        },
        {
          id: 2,
          hex: '#771812',
          title: 'color3',
          count: 31,
          percent: 30,
        },
        {
          id: 3,
          hex: '#ecaa4d',
          title: 'color4',
          count: 31,
          percent: 90,
        },
        {
          id: 4,
          hex: '#e4791e',
          title: 'color5',
          count: 31,
          percent: 70,
        },
        {
          id: 5,
          hex: '#e68722',
          title: 'color6',
          count: 31,
          percent: 50,
        },
        {
          id: 6,
          hex: '#e9e698',
          title: 'color7',
          count: 31,
          percent: 70,
        },
        {
          id: 7,
          hex: '#e3db42',
          title: 'color8',
          count: 31,
          percent: 90,
        },
        {
          id: 8,
          hex: '#e7cf30',
          title: 'color9',
          count: 31,
          percent: 70,
        },
        {
          id: 9,
          hex: '#c7ce35',
          title: 'color10',
          count: 31,
          percent: 20,
        },
        {
          id: 10,
          hex: '#83a72c',
          title: 'color11',
          count: 31,
          percent: 70,
        },
        {
          id: 11,
          hex: '#495123',
          title: 'color12',
          count: 31,
          percent: 40,
        },
        {
          id: 12,
          hex: '#79b772',
          title: 'color13',
          count: 31,
          percent: 80,
        },
        {
          id: 13,
          hex: '#0f7835',
          title: 'color14',
          count: 31,
          percent: 70,
        },
        {
          id: 14,
          hex: '#0f311f',
          title: 'color15',
          count: 31,
          percent: 20,
        },
        {
          id: 15,
          hex: '#e5f1f5',
          title: 'color16',
          count: 31,
          percent: 70,
        },
        {
          id: 16,
          hex: '#6cbdcb',
          title: 'color17',
          count: 31,
          percent: 55,
        },
        {
          id: 17,
          hex: '#1d8180',
          title: 'color18',
          count: 31,
          percent: 40,
        },
        {
          id: 18,
          hex: '#a9c0c8',
          title: 'color19',
          count: 31,
          percent: 70,
        },
        {
          id: 19,
          hex: '#10468e',
          title: 'color20',
          count: 31,
          percent: 70,
        },
        {
          id: 20,
          hex: '#262f63',
          title: 'color21',
          count: 31,
          percent: 60,
        },
        {
          id: 21,
          hex: '#bc92ba',
          title: 'color22',
          count: 31,
          percent: 70,
        },
        {
          id: 22,
          hex: '#58418c',
          title: 'color23',
          count: 31,
          percent: 30,
        },
        {
          id: 23,
          hex: '#663887',
          title: 'color24',
          count: 31,
          percent: 70,
        },
        {
          id: 24,
          hex: '#de92b4',
          title: 'color25',
          count: 31,
          percent: 90,
        },
        {
          id: 25,
          hex: '#994e91',
          title: 'color26',
          count: 31,
          percent: 70,
        },
        {
          id: 26,
          hex: '#762377',
          title: 'color27',
          count: 31,
          percent: 30,
        },
        {
          id: 27,
          hex: '#ffffff',
          title: 'color28',
          count: 31,
          percent: 70,
        },
        {
          id: 28,
          hex: '#eff1d9',
          title: 'color29',
          count: 31,
          percent: 70,
        },
        {
          id: 29,
          hex: '#ba9669',
          title: 'color30',
          count: 31,
          percent: 90,
        },
        {
          id: 30,
          hex: '#806543',
          title: 'color31',
          count: 31,
          percent: 70,
        },
        {
          id: 31,
          hex: '#aebcb3',
          title: 'color32',
          count: 31,
          percent: 40,
        },
        {
          id: 32,
          hex: '#c0c0be',
          title: 'color33',
          count: 31,
          percent: 70,
        },
        {
          id: 33,
          hex: '#34454e',
          title: 'color34',
          count: 31,
          percent: 50,
        },
        {
          id: 34,
          hex: '#806751',
          title: 'color35',
          count: 31,
          percent: 70,
        },
        {
          id: 35,
          hex: '#8f4b17',
          title: 'color36',
          count: 31,
          percent: 70,
        },
        {
          id: 36,
          hex: '#634221',
          title: 'color37',
          count: 31,
          percent: 100,
        },
        {
          id: 37,
          hex: '#050607',
          title: 'color38',
          count: 31,
          percent: 70,
        },
        {
          id: 38,
          hex: '#060606',
          title: 'color39',
          count: 31,
          percent: 80,
        },
      ];

    // dummy data -> selected color combinations by (artist)
    this.selectedColorCombinationsByArtist = [
      [
        {hex: "#d000a4", R: 208, G: 0, B: 164, percent: 24},
        {hex: "#ff9c00", R: 255, G: 156, B: 0, percent: 24},
        {hex: "#e78e00", R: 231, G: 142, B: 0, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#1600e7", R: 22, G: 0, B: 231, percent: 24},
        {hex: "#ec5cff", R: 236, G: 92, B: 255, percent: 24},
        {hex: "#e78e00", R: 231, G: 142, B: 0, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#9fff00", R: 159, G: 255, B: 0, percent: 24},
        {hex: "#4584ff", R: 69, G: 132, B: 255, percent: 24},
        {hex: "#e78e00", R: 231, G: 142, B: 0, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#5c3800", R: 96, G: 52, B: 0, percent: 24},
        {hex: "#3c005c", R: 60, G: 0, B: 92, percent: 24},
        {hex: "#7dff45", R: 125, G: 255, B: 69, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#9fff00", R: 159, G: 255, B: 0, percent: 24},
        {hex: "#4584ff", R: 69, G: 132, B: 255, percent: 24},
        {hex: "#eaff45", R: 234, G: 255, B: 69, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#8c17ff", R: 140, G: 23, B: 255, percent: 24},
        {hex: "#ffd58b", R: 255, G: 213, B: 139, percent: 24},
        {hex: "#3200d0", R: 50, G: 0, B: 208, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#9fff00", R: 159, G: 255, B: 0, percent: 24},
        {hex: "#4584ff", R: 69, G: 132, B: 255, percent: 24},
        {hex: "#375c00", R: 55, G: 92, B: 0, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#730028", R: 115, G: 0, B: 40, percent: 24},
        {hex: "#18ff00", R: 24, G: 255, B: 0, percent: 24},
        {hex: "#e78e00", R: 231, G: 142, B: 0, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#9fff00", R: 159, G: 255, B: 0, percent: 24},
        {hex: "#4584ff", R: 69, G: 132, B: 255, percent: 24},
        {hex: "#300073", R: 48, G: 0, B: 115, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#ff3f17", R: 255, G: 63, B: 23, percent: 24},
        {hex: "#00a29f", R: 0, G: 162, B: 159, percent: 24},
        {hex: "#e78e00", R: 231, G: 142, B: 0, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ]
    ];

    // dummy data -> unique colors used by (artist)
    this.artistUnqiueColorsData = [
      {hex: "#deb4a1", R: 222, G: 180, B: 161},
      {hex: "#dfc3b8", R: 223, G: 195, B: 184},
      {hex: "#a8a1de", R: 168, G: 161, B: 222},
      {hex: "#dec3a1", R: 222, G: 195, B: 161},
      {hex: "#dea1a1", R: 222, G: 161, B: 161},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#de5e2c", R: 222, G: 94, B: 44},
      {hex: "#a1debf", R: 161, G: 222, B: 191},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#dec3a1", R: 222, G: 195, B: 161},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#a1debf", R: 161, G: 222, B: 191},

      {hex: "#a8a1de", R: 168, G: 161, B: 222},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#a8a1de", R: 168, G: 161, B: 222},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#875c4b", R: 135, G: 92, B: 75},
      {hex: "#a1debf", R: 161, G: 222, B: 191},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#de5e2c", R: 222, G: 94, B: 44},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#c3dea1", R: 195, G: 222, B: 161},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#c3dea1", R: 195, G: 222, B: 161},

      {hex: "#c3dea1", R: 195, G: 222, B: 161},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#a1debf", R: 161, G: 222, B: 191},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#ded8a1", R: 222, G: 216, B: 161},
      {hex: "#bea1de", R: 190, G: 161, B: 222},
      {hex: "#a8a1de", R: 168, G: 161, B: 222},
      {hex: "#875c4b", R: 135, G: 92, B: 75},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#dea1a1", R: 222, G: 161, B: 161},

      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#a1dec9", R: 161, G: 222, B: 201},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#de5e2c", R: 222, G: 94, B: 44},
      {hex: "#dea1a1", R: 222, G: 161, B: 161},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#a1debf", R: 161, G: 222, B: 191},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#ded8a1", R: 222, G: 216, B: 161},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#bea1de", R: 190, G: 161, B: 222},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
    ];

    // dummy data -> birds color families in 6 regions
    this.birdColorDataAcrossRegions = [
      {
        id: 0,
        title: "North America & Canada"
      },
      {
        id: 1,
        title: "South America"
      },
      {
        id: 2,
        title: "Europe"
      },
      {
        id: 3,
        title: "Africa"
      },
      {
        id: 4,
        title: "Asia"
      },
      {
        id: 5,
        title: "Australia"
      },
    ];

    // dummy data -> flower lifespan across color families
    this.flowerLifespanData = [
      {
        id: 0,
        hex: '#e6341c',
        title: 'red',
        img_url: "/assets/img/nature/flower-families/red.png",
      },
      {
        id: 1,
        hex: '#f2891d',
        title: 'orange',
        img_url: "/assets/img/nature/flower-families/orange.png",
      },
      {
        id: 2,
        hex: '#fae51b',
        title: 'yellow',
        img_url: "/assets/img/nature/flower-families/yellow.png",
      },
      {
        id: 3,
        hex: '#46ac33',
        title: 'green',
        img_url: "/assets/img/nature/flower-families/green.png",
      },
      {
        id: 4,
        hex: '#37b1e6',
        title: 'blue',
        img_url: "/assets/img/nature/flower-families/blue.png",
      },
      {
        id: 5,
        hex: '#6d4595',
        title: 'purple',
        img_url: "/assets/img/nature/flower-families/purple.png",
      },
      {
        id: 6,
        hex: '#f7c6de',
        title: 'pink',
        img_url: "/assets/img/nature/flower-families/pink.png",
      },
      {
        id: 7,
        hex: '#63310e',
        title: 'brown',
        img_url: "/assets/img/nature/flower-families/brown.png",
      },
      {
        id: 8,
        hex: '#ffffff',
        title: 'white',
        img_url: "/assets/img/nature/flower-families/white.png",
      },
    ];

    // dummy data -> most liked/disliked colors // april 2022
    this.colorSentimentMonthData = {
      date: "",
      positiveData: {
        colors: [
          { hex: "#fff6f9", percentage: 34 },
          { hex: "#f6bac6", hex1: "#ff0000", percentage: 33 },
          { hex: "#ff7f00", percentage: 33 }
        ],
        percentData: "8.44%"
      },
      negativeData: {
        colors: [
          { hex: "#752478", percentage: 34 },
          { hex: "#634321", percentage: 33 },
          { hex: "#2a9042", percentage: 33 }
        ],
        percentData: "8.44%"
      }
    };

    // dummy data -> use of (color) across all sports
    this.colorDataAcrossSports = [
      {
        "id": 0,
        "title": "Baseball",
        "img_url": "/assets/img/sports/types/baseball.png",
        "percentage": 11,
      },
      {
        "id": 1,
        "title": "Basketball",
        "img_url": "/assets/img/sports/types/basketball.png",
        "percentage": 11,
      },
      {
        "id": 2,
        "title": "Football",
        "img_url": "/assets/img/sports/types/football.png",
        "percentage": 11,
      },
      {
        "id": 3,
        "title": "Hockey",
        "img_url": "/assets/img/sports/types/hockey.png",
        "percentage": 11,
      },
      {
        "id": 4,
        "title": "Soccer",
        "img_url": "/assets/img/sports/types/soccer.png",
        "percentage": 11,
      },
      {
        "id": 5,
        "title": "Lacrosse",
        "img_url": "/assets/img/sports/types/lacrosse.png",
        "percentage": 11,
      },
      {
        "id": 6,
        "title": "Rugby",
        "img_url": "/assets/img/sports/types/rugby.png",
        "percentage": 11,
      },
    ];

    // dummy data -> popular color combinations for (color) across all sports
    const popularCombinationData = [
      {
        color: { hex: "#d8d635", title: "color1" },
        percentage: 12,
      },
      {
        color: { hex: "#89884b", title: "color2" },
        percentage: 12,
      },
      {
        color: { hex: "#684b89", title: "color3" },
        percentage: 24,
      },
      {
        color: { hex: "#bea1de", title: "color4" },
        percentage: 8,
      },
      {
        color: { hex: "#89884b", title: "color5" },
        percentage: 12,
      },
      {
        color: { hex: "#684b89", title: "color6" },
        percentage: 24,
      },
      {
        color: { hex: "#bea1de", title: "color7" },
        percentage: 8,
      }
    ];
    this.sportColorCombinationData = [
      {
        "id": 0,
        "title": "Baseball",
        "img_url": "/assets/img/sports/types/baseball.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 1,
        "title": "Basketball",
        "img_url": "/assets/img/sports/types/basketball.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 2,
        "title": "Football",
        "img_url": "/assets/img/sports/types/football.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 3,
        "title": "Hockey",
        "img_url": "/assets/img/sports/types/hockey.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 4,
        "title": "Soccer",
        "img_url": "/assets/img/sports/types/soccer.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 5,
        "title": "Lacrosse",
        "img_url": "/assets/img/sports/types/lacrosse.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 6,
        "title": "Rugby",
        "img_url": "/assets/img/sports/types/rugby.png",
        "colorData": popularCombinationData,
      },
    ];

    // dummy data -> use of (color) across all sport teams
    this.colorDataAcrossTeams = [
      {
        id: 0,
        title: "Anaheim Ducks",
        img_url: "/assets/img/sports/teams/1.png",
        percentage: 99,
      },
      {
        id: 1,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/2.png",
        percentage: 70,
      },
      {
        id: 2,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/3.png",
        percentage: 40,
      },
      {
        id: 3,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/4.png",
        percentage: 60,
      },
      {
        id: 4,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/5.png",
        percentage: 80,
      },
      {
        id: 5,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/6.png",
        percentage: 75,
      },
      {
        id: 6,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/7.png",
        percentage: 65,
      },
      {
        id: 7,
        title: "Vegas Golden Knights",
        img_url: "/assets/img/sports/teams/8.png",
        percentage: 70,
      },
      {
        id: 8,
        title: "Anaheim ducks",
        img_url: "/assets/img/sports/teams/9.png",
        percentage: 99,
      },
      {
        id: 9,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/10.png",
        percentage: 85,
      },
      {
        id: 10,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/1.png",
        percentage: 74,
      },
      {
        id: 11,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/2.png",
        percentage: 70,
      },
      {
        id: 12,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/3.png",
        percentage: 40,
      },
      {
        id: 13,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/4.png",
        percentage: 50,
      },
      {
        id: 14,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/5.png",
        percentage: 30,
      },
      {
        id: 15,
        title: "Anaheim Ducks",
        img_url: "/assets/img/sports/teams/1.png",
        percentage: 80,
      },
      {
        id: 16,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/2.png",
        percentage: 70,
      },
      {
        id: 17,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/3.png",
        percentage: 60,
      },
      {
        id: 18,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/4.png",
        percentage: 67,
      },
      {
        id: 19,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/5.png",
        percentage: 73,
      },
      {
        id: 20,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/6.png",
        percentage: 78,
      },
      {
        id: 21,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/7.png",
        percentage: 80,
      },
      {
        id: 22,
        title: "Vegas Golden Knights",
        img_url: "/assets/img/sports/teams/8.png",
        percentage: 85,
      },
      {
        id: 23,
        title: "Anaheim ducks",
        img_url: "/assets/img/sports/teams/9.png",
        percentage: 86,
      },
      {
        id: 24,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/10.png",
        percentage: 70,
      },
      {
        id: 25,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/1.png",
        percentage: 90,
      },
      {
        id: 26,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/2.png",
        percentage: 60,
      },
      {
        id: 27,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/3.png",
        percentage: 50,
      },
      {
        id: 28,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/4.png",
        percentage: 90,
      },
      {
        id: 29,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/5.png",
        percentage: 80,
      },
    ];

    // dummy data -> color combinations for each of the (selected sport) teams
    const colorCombinationData = [
      {hex: "#40ff0e", R: 64, G: 255, B: 14, percent: 28},
      {hex: "#0084da", R: 0, G: 132, B: 218, percent: 28},
      {hex: "#a7007f", R: 167, G: 0, B: 127, percent: 12},
      {hex: "#ffe32b", R: 255, G: 227, B: 43, percent: 32}
    ];
    this.colorCombinationTeamData = [
      {
        id: 0,
        title: "Anaheim Ducks",
        img_url: "/assets/img/sports/teams/1.png",
        colors: colorCombinationData
      },
      {
        id: 1,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/2.png",
        colors: colorCombinationData
      },
      {
        id: 2,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/3.png",
        colors: colorCombinationData
      },
      {
        id: 3,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/4.png",
        colors: colorCombinationData
      },
      {
        id: 4,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/5.png",
        colors: colorCombinationData
      },
      {
        id: 5,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/6.png",
        colors: colorCombinationData
      },
      {
        id: 6,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/7.png",
        colors: colorCombinationData
      },
    ];

    // dummy data -> color use across all (selected sport) teams
    this.colorDataAcrossAllSportTeams = [
      {
        id: 0,
        title: "Anaheim Ducks",
        img_url: "/assets/img/sports/teams/1.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', null, '#8db723', null, null, '#3563ad',
          '#5d4596', null, '#974d15', '#f4f5dc', null, '#ffffff', '#020303'
        ]
      },
      {
        id: 1,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/2.png",
        sport: "NFL",
        colors: [
          '#ef103a', null, null, '#8db723', '#0a8236', null, '#3563ad',
          null, '#a6529b', '#974d15', null, '#c0c0bf', '#ffffff', null
        ]
      },
      {
        id: 2,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/3.png",
        sport: "NFL",
        colors: [
          null, '#f07e14', '#f1e60f', null, '#0a8236', null, null,
          '#5d4596', '#a6529b', null, '#f4f5dc', '#c0c0bf', '#ffffff', '#020303'
        ]
      },
      {
        id: 3,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/4.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', null, null, null, '#3563ad',
          null, '#a6529b', null, '#f4f5dc', '#c0c0bf', null, '#020303'
        ]
      },
      {
        id: 4,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/5.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', null, '#8db723', null, '#6ac6d9', null,
          '#5d4596', '#a6529b', null, '#f4f5dc', '#c0c0bf', null, null
        ]
      },
      {
        id: 5,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/6.png",
        sport: "NFL",
        colors: [
          null, '#f07e14', null, '#8db723', null, null, '#3563ad',
          '#5d4596', null, '#974d15', null, '#c0c0bf', '#ffffff', '#020303'
        ]
      },
      {
        id: 6,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/7.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', '#8db723', '#0a8236', '#6ac6d9', '#3563ad',
          '#5d4596', '#a6529b', '#974d15', '#f4f5dc', '#c0c0bf', '#ffffff', '#020303'
        ]
      },
      {
        id: 7,
        title: "Vegas Golden Knights",
        img_url: "/assets/img/sports/teams/8.png",
        sport: "NFL",
        colors: [
          '#ef103a', null, '#f1e60f', null, null, '#6ac6d9', '#3563ad',
          '#5d4596', null, '#974d15', null, '#c0c0bf', null, '#020303'
        ]
      },
      {
        id: 8,
        title: "Anaheim ducks",
        img_url: "/assets/img/sports/teams/9.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', '#8db723', null, '#6ac6d9', null,
          '#5d4596', null, null, '#f4f5dc', '#c0c0bf', null, null
        ]
      },
      {
        id: 9,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/10.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', null, '#8db723', '#0a8236', null, '#3563ad',
          '#5d4596', null, null, '#f4f5dc', null, null, '#020303'
        ]
      },
      {
        id: 10,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/1.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', null, null, '#0a8236', '#6ac6d9', '#3563ad',
          '#5d4596', '#a6529b', null, null, null, null, '#020303'
        ]
      },
      {
        id: 11,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/2.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', '#8db723', '#0a8236', null, '#3563ad',
          '#5d4596', '#a6529b', null, '#f4f5dc', null, null, null
        ]
      },
      {
        id: 12,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/3.png",
        sport: "NFL",
        colors: [
          null, '#f07e14', '#f1e60f', null, '#0a8236', '#6ac6d9', '#3563ad',
          '#5d4596', '#a6529b', '#974d15', null, '#c0c0bf', null, null
        ]
      },
      {
        id: 13,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/4.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', '#8db723', null, '#6ac6d9', null,
          '#5d4596', null, '#974d15', null, '#c0c0bf', null, null
        ]
      },
      {
        id: 14,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/5.png",
        sport: "NFL",
        colors: [
          null, null, '#f1e60f', '#8db723', null, '#6ac6d9', '#3563ad',
          null, '#a6529b', '#974d15', '#f4f5dc', null, '#ffffff', null
        ]
      },
      {
        id: 15,
        title: "Anaheim Ducks",
        img_url: "/assets/img/sports/teams/1.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', null, '#8db723', null, null, '#3563ad',
          '#5d4596', null, '#974d15', '#f4f5dc', null, '#ffffff', '#020303'
        ]
      },
      {
        id: 16,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/2.png",
        sport: "NFL",
        colors: [
          '#ef103a', null, null, '#8db723', '#0a8236', null, '#3563ad',
          null, '#a6529b', '#974d15', null, '#c0c0bf', '#ffffff', null
        ]
      },
      {
        id: 17,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/3.png",
        sport: "NFL",
        colors: [
          null, '#f07e14', '#f1e60f', null, '#0a8236', null, null,
          '#5d4596', '#a6529b', null, '#f4f5dc', '#c0c0bf', '#ffffff', '#020303'
        ]
      },
      {
        id: 18,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/4.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', null, null, null, '#3563ad',
          null, '#a6529b', null, '#f4f5dc', '#c0c0bf', null, '#020303'
        ]
      },
      {
        id: 19,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/5.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', null, '#8db723', null, '#6ac6d9', null,
          '#5d4596', '#a6529b', null, '#f4f5dc', '#c0c0bf', null, null
        ]
      },
      {
        id: 20,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/6.png",
        sport: "NFL",
        colors: [
          null, '#f07e14', null, '#8db723', null, null, '#3563ad',
          '#5d4596', null, '#974d15', null, '#c0c0bf', '#ffffff', '#020303'
        ]
      },
      {
        id: 21,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/7.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', '#8db723', '#0a8236', '#6ac6d9', '#3563ad',
          '#5d4596', '#a6529b', '#974d15', '#f4f5dc', '#c0c0bf', '#ffffff', '#020303'
        ]
      },
      {
        id: 22,
        title: "Vegas Golden Knights",
        img_url: "/assets/img/sports/teams/8.png",
        sport: "NFL",
        colors: [
          '#ef103a', null, '#f1e60f', null, null, '#6ac6d9', '#3563ad',
          '#5d4596', null, '#974d15', null, '#c0c0bf', null, '#020303'
        ]
      },
    ];

    // dummy data -> color use comparison for (selected sport) and all other sports
    const colorComparisonData = {
      percentages: [
        ">18.44%",
        "9.83%-7.09%",
        "6.89%-4.76%",
        "9.83%",
      ],
      mostPopular: [
        {
          percentage: 20,
          title: "color1",
          color: { hex: "#fff6f9" }
        },
        {
          percentage: 20,
          title: "color2",
          color: { hex: "#f6bac6", hex1: "#ff0000" }
        },
        {
          percentage: 15,
          title: "color3",
          color: { hex: "#ff7f00" }
        },
        {
          percentage: 15,
          title: "color4",
          color: { hex: "#fff640", hex1: "#f3d725" }
        },
        {
          percentage: 15,
          title: "color5",
          color: { hex: "#2d9641" }
        },
        {
          percentage: 15,
          title: "color6",
          color: { hex: "#2065dc" }
        }
      ],
      moderate: [
        {
          percentage: 55,
          title: "color7",
          color: { hex: "#7d1f7f" }
        },
        {
          percentage: 45,
          title: "color8",
          color: { hex: "#644321" }
        },
      ],
      infrequent: [
        {
          percentage: 100,
          title: "color9",
          color: { hex: "#b9b9b8", hex1: "#2f52a0" }
        },
      ],
      not_used: [
        {
          percentage: 30,
          title: "color10",
          color: { hex: "#ffffff" }
        },
        {
          percentage: 70,
          title: "color11",
          color: { hex: "#020303" }
        },
      ]
    };
    this.sportComparisonData = [
      {
        name: "Baseball",
        img_url: "/assets/img/sports/types/baseball.png",
        colorComparisonData
      },
      {
        name: "Basketball",
        img_url: "/assets/img/sports/types/basketball.png",
        colorComparisonData
      },
      {
        name: "Football",
        img_url: "/assets/img/sports/types/football.png",
        colorComparisonData
      },
      {
        name: "Hockey",
        img_url: "/assets/img/sports/types/hockey.png",
        colorComparisonData
      },
      {
        name: "Lacrosse",
        img_url: "/assets/img/sports/types/lacrosse.png",
        colorComparisonData
      },
      {
        name: "Rugby",
        img_url: "/assets/img/sports/types/rugby.png",
        colorComparisonData
      },
      {
        name: "Soccer",
        img_url: "/assets/img/sports/types/soccer.png",
        colorComparisonData
      },
    ];

    // dummy data -> use of colors by all (sports) teams
    this.sportTeamUsedColorData = [
      {
        id: 0,
        title: "color1",
        color: { hex: "#e51c20", hex1: "#f6bac6" },
        percentage: 11
      },
      {
        id: 1,
        title: "color2",
        color: { hex: "#f07e14" },
        percentage: 11
      },
      {
        id: 2,
        title: "color3",
        color: { hex: "#f5eb49", hex1: "#f1d724" },
        percentage: 11
      },
      {
        id: 3,
        title: "color4",
        color: { hex: "#299741" },
        percentage: 11
      },
      {
        id: 4,
        title: "color5",
        color: { hex: "#3462ac" },
        percentage: 11
      },
      {
        id: 5,
        title: "color6",
        color: { hex: "#7c217f" },
        percentage: 11
      },
      {
        id: 6,
        title: "color7",
        color: { hex: "#654322" },
        percentage: 11
      },
      {
        id: 7,
        title: "color8",
        color: { hex: "#b9b9b8" },
        percentage: 11
      },
      {
        id: 8,
        title: "color9",
        color: { hex: "#ffffff" },
        percentage: 11
      },
      {
        id: 9,
        title: "color10",
        color: { hex: "#040506" },
        percentage: 11
      },
    ];

    // dummy data -> color combinations for (color) in (industry) logos
    this.colorCombinationDataForBrand1 = [
      {
        id: 0,
        hex: '#f0b7c2',
        title: 'color1',
        count: 6564,
        percent: 10,
      },
      {
        id: 1,
        hex: '#d62421',
        title: 'color2',
        count: 6564,
        percent: 5,
      },
      {
        id: 2,
        hex: '#771812',
        title: 'color3',
        count: 6564,
        percent: 10,
      },
      {
        id: 3,
        hex: '#ecaa4d',
        title: 'color4',
        count: 6564,
        percent: 15,
      },
      {
        id: 4,
        hex: '#e4791e',
        title: 'color5',
        count: 6564,
        percent: 20,
      },
      {
        id: 5,
        hex: '#e68722',
        title: 'color6',
        count: 6564,
        percent: 25,
      },
      {
        id: 6,
        hex: '#e9e698',
        title: 'color7',
        count: 6564,
        percent: 30,
      },
      {
        id: 7,
        hex: '#e3db42',
        title: 'color8',
        count: 6564,
        percent: 40,
      },
      {
        id: 8,
        hex: '#e7cf30',
        title: 'color9',
        count: 6564,
        percent: 70,
      },
      {
        id: 9,
        hex: '#c7ce35',
        title: 'color10',
        count: 6564,
        percent: 15,
      },
      {
        id: 10,
        hex: '#83a72c',
        title: 'color11',
        count: 6564,
        percent: 35,
      },
      {
        id: 11,
        hex: '#495123',
        title: 'color12',
        count: 6564,
        percent: 35,
      },
      {
        id: 12,
        hex: '#79b772',
        title: 'color13',
        count: 6564,
        percent: 80,
      },
      {
        id: 13,
        hex: '#0f7835',
        title: 'color14',
        count: 6564,
        percent: 35,
      },
      {
        id: 14,
        hex: '#0f311f',
        title: 'color15',
        count: 31,
        percent: 70,
      },
      {
        id: 15,
        hex: '#e5f1f5',
        title: 'color16',
        count: 6564,
        percent: 35,
      },
      {
        id: 16,
        hex: '#6cbdcb',
        title: 'color17',
        count: 6564,
        percent: 40,
      },
      {
        id: 17,
        hex: '#1d8180',
        title: 'color18',
        count: 6564,
        percent: 25,
      },
      {
        id: 18,
        hex: '#a9c0c8',
        title: 'color19',
        count: 6564,
        percent: 80,
      },
      {
        id: 19,
        hex: '#10468e',
        title: 'color20',
        count: 6564,
        percent: 35,
      },
      {
        id: 20,
        hex: '#262f63',
        title: 'color21',
        count: 6564,
        percent: 45,
      },
      {
        id: 21,
        hex: '#bc92ba',
        title: 'color22',
        count: 6564,
        percent: 25,
      },
      {
        id: 22,
        hex: '#58418c',
        title: 'color23',
        count: 6564,
        percent: 100,
      },
      {
        id: 23,
        hex: '#663887',
        title: 'color24',
        count: 6564,
        percent: 30,
      },
      {
        id: 24,
        hex: '#de92b4',
        title: 'color25',
        count: 6564,
        percent: 40,
      },
      {
        id: 25,
        hex: '#994e91',
        title: 'color26',
        count: 6564,
        percent: 25,
      },
      {
        id: 26,
        hex: '#762377',
        title: 'color27',
        count: 6564,
        percent: 80,
      },
      {
        id: 27,
        hex: '#ffffff',
        title: 'color28',
        count: 6564,
        percent: 60,
      },
      {
        id: 28,
        hex: '#eff1d9',
        title: 'color29',
        count: 6564,
        percent: 50,
      },
      {
        id: 29,
        hex: '#ba9669',
        title: 'color30',
        count: 6564,
        percent: 15,
      },
      {
        id: 30,
        hex: '#806543',
        title: 'color31',
        count: 6564,
        percent: 50,
      },
      {
        id: 31,
        hex: '#aebcb3',
        title: 'color32',
        count: 6564,
        percent: 40,
      },
      {
        id: 32,
        hex: '#c0c0be',
        title: 'color33',
        count: 6564,
        percent: 80,
      },
      {
        id: 33,
        hex: '#34454e',
        title: 'color34',
        count: 6564,
        percent: 60,
      },
      {
        id: 34,
        hex: '#806751',
        title: 'color35',
        count: 6564,
        percent: 35,
      },
      {
        id: 35,
        hex: '#8f4b17',
        title: 'color36',
        count: 6564,
        percent: 25,
      },
      {
        id: 36,
        hex: '#634221',
        title: 'color37',
        count: 6564,
        percent: 50,
      },
      {
        id: 37,
        hex: '#050607',
        title: 'color38',
        count: 6564,
        percent: 35,
      },
    ];

    this.colorCombinationDataForBrand = [
      {
        "id": 27,
        "hex": "#FFC9D2",
        "name": "light red",
        "count": 1,
        "percent": "0.1"
      },
      {
        "id": 28,
        "hex": "#C20E14",
        "name": "dark red",
        "count": 5,
        "percent": "0.6"
      },
      {
        "id": 24,
        "hex": "#FEC167",
        "name": "light orange",
        "count": 23,
        "percent": "2.9"
      },
      {
        "id": 23,
        "hex": "#FF7F00",
        "name": "medium orange",
        "count": 40,
        "percent": "5.0"
      },
      {
        "id": 25,
        "hex": "#D44417",
        "name": "dark orange",
        "count": 6,
        "percent": "0.8"
      },
      {
        "id": 34,
        "hex": "#FDFDBA",
        "name": "light yellow",
        "count": 3,
        "percent": "0.4"
      },
      {
        "id": 33,
        "hex": "#FFFF00",
        "name": "medium yellow",
        "count": 12,
        "percent": "1.5"
      },
      {
        "id": 35,
        "hex": "#FFDF00",
        "name": "dark yellow",
        "count": 1,
        "percent": "0.1"
      },
      {
        "id": 37,
        "hex": "#DFFF00",
        "name": "light yellow/green",
        "count": 2,
        "percent": "0.3"
      },
      {
        "id": 36,
        "hex": "#8DB600",
        "name": "medium yellow/green",
        "count": 4,
        "percent": "0.5"
      },
      {
        "id": 38,
        "hex": "#5C790C",
        "name": "dark yellow/green",
        "count": 1,
        "percent": "0.1"
      },
      {
        "id": 18,
        "hex": "#A0FF99",
        "name": "light green",
        "count": 8,
        "percent": "1.0"
      },
      {
        "id": 17,
        "hex": "#02A300",
        "name": "medium green",
        "count": 14,
        "percent": "1.8"
      },
      {
        "id": 19,
        "hex": "#03580F",
        "name": "dark green",
        "count": 2,
        "percent": "0.3"
      },
      {
        "id": 12,
        "hex": "#C9FFFF",
        "name": "light cyan",
        "count": 4,
        "percent": "0.5"
      },
      {
        "id": 11,
        "hex": "#00FFFF",
        "name": "medium cyan",
        "count": 10,
        "percent": "1.3"
      },
      {
        "id": 13,
        "hex": "#007E7F",
        "name": "dark cyan",
        "count": 0,
        "percent": "0"
      },
      {
        "id": 6,
        "hex": "#A0D3FF",
        "name": "light blue",
        "count": 27,
        "percent": "3.4"
      },
      {
        "id": 5,
        "hex": "#0000FF",
        "name": "medium blue",
        "count": 53,
        "percent": "6.6"
      },
      {
        "id": 7,
        "hex": "#000AA3",
        "name": "dark blue",
        "count": 9,
        "percent": "1.1"
      },
      {
        "id": 30,
        "hex": "#DCD0FF",
        "name": "light purple",
        "count": 16,
        "percent": "2.0"
      },
      {
        "id": 29,
        "hex": "#8F00FF",
        "name": "medium purple",
        "count": 13,
        "percent": "1.6"
      },
      {
        "id": 31,
        "hex": "#5700B3",
        "name": "dark purple",
        "count": 4,
        "percent": "0.5"
      },
      {
        "id": 21,
        "hex": "#F98DFF",
        "name": "light magenta",
        "count": 10,
        "percent": "1.3"
      },
      {
        "id": 20,
        "hex": "#FF00FF",
        "name": "medium magenta",
        "count": 11,
        "percent": "1.4"
      },
      {
        "id": 22,
        "hex": "#C50597",
        "name": "dark magenta",
        "count": 2,
        "percent": "0.3"
      },
      {
        "id": 9,
        "hex": "#A89C79",
        "name": "light brown",
        "count": 11,
        "percent": "17"
      },
      {
        "id": 8,
        "hex": "#7F572E",
        "name": "medium brown",
        "count": 17,
        "percent": "2.1"
      },
      {
        "id": 10,
        "hex": "#402D1D",
        "name": "dark brown",
        "count": 0,
        "percent": "0"
      },
      {
        "id": 2,
        "hex": "#EAE8D9",
        "name": "light beige",
        "count": 21,
        "percent": "2.6"
      },
      {
        "id": 1,
        "hex": "#D5CDB5",
        "name": "medium beige",
        "count": 30,
        "percent": "3.8"
      },
      {
        "id": 3,
        "hex": "#AFA18C",
        "name": "dark beige",
        "count": 7,
        "percent": "0.9"
      },
      {
        "id": 15,
        "hex": "#DDDDDD",
        "name": "light gray",
        "count": 96,
        "percent": "12.0"
      },
      {
        "id": 14,
        "hex": "#A7A7A7",
        "name": "medium gray",
        "count": 97,
        "percent": "12.1"
      },
      {
        "id": 16,
        "hex": "#535353",
        "name": "dark gray",
        "count": 14,
        "percent": "1.8"
      },
      {
        "id": 32,
        "hex": "#FFFFFF",
        "name": "light white",
        "count": 1,
        "percent": "0.1"
      },
      {
        "id": 4,
        "hex": "#000000",
        "name": "dark black",
        "count": 194,
        "percent": "24.3"
      }
    ]


    // dummy data -> cross industry logo color families comparison
    const colorFamilyComparisonData = {
      percentages: [
        "8.44%",
        "8.44%",
        "8.44%",
        "8.44%",
      ],
      mostPopular: [
        {
          percentage: 40,
          title: "color1",
          color: { hex: "#db4034" }
        },
        {
          percentage: 30,
          title: "color2",
          color: { hex: "#2f52a0" }
        },
        {
          percentage: 30,
          title: "color3",
          color: { hex: "#ffffff" }
        }
      ],
      moderate: [
        {
          percentage: 55,
          title: "color4",
          color: { hex: "#020303" }
        },
        {
          percentage: 45,
          title: "color5",
          color: { hex: "#87ae27" }
        },
      ],
      infrequent: [
        {
          percentage: 55,
          title: "color7",
          color: { hex: "#efe648" }
        },
        {
          percentage: 45,
          title: "color8",
          color: { hex: "#e37a36" }
        },
      ],
      not_used: [
        {
          percentage: 55,
          title: "color10",
          color: { hex: "#8dcfda" }
        },
        {
          percentage: 45,
          title: "color11",
          color: { hex: "#f2f3db" }
        },
      ]
    };

    this.crossIndustryLogoData = [
      {
        name: "ARTS, ENTERTAINMENT & RECREATION",
        img_url: "assets/img/brands/industry/arts.png",
        colorFamilyComparisonData
      },
      {
        name: "ANIMAL HEALTH",
        img_url: "assets/img/brands/industry/animal.png",
        colorFamilyComparisonData
      },
      {
        name: "APPAREL",
        img_url: "assets/img/brands/industry/apparel.png",
        colorFamilyComparisonData
      }
    ];

    // dummy data -> top 10 industries using color in logos
    this.top10IndustriesUsingColorInLogos = [
      {
        category: "advertising",
        percentage: 60,
        img_url: "/assets/img/brands/industry/advertising.png",
      },
      {
        category: "aerospace & defense",
        percentage: 40,
        img_url: "/assets/img/brands/industry/aerospace.png",
      },
      {
        category: "agriculture",
        percentage: 80,
        img_url: "/assets/img/brands/industry/agriculture.png",
      },
      {
        category: "airlines",
        percentage: 60,
        img_url: "/assets/img/brands/industry/airlines.png",
      },
      {
        category: "animal",
        percentage: 60,
        img_url: "/assets/img/brands/industry/animal.png",
      },
      {
        category: "arts",
        percentage: 40,
        img_url: "/assets/img/brands/industry/arts.png",
      },
      {
        category: "banking",
        percentage: 80,
        img_url: "/assets/img/brands/industry/banking.png",
      },
      {
        category: "business",
        percentage: 60,
        img_url: "/assets/img/brands/industry/business.png",
      },
      {
        category: "consulting",
        percentage: 70,
        img_url: "/assets/img/brands/industry/consulting.png",
      },
      {
        category: "education",
        percentage: 90,
        img_url: "/assets/img/brands/industry/education.png",
      }
    ];

    // dummy data -> top attributed associated with (color)
    this.topAttributedAssociatedData = [
      {
        "title": "new",
        "title1": "new",
        "description": "not exist before; made, introduced, or discovered recently or now for the first time",
      },
      {
        "title": "playful",
        "title1": "play·ful",
        "description": "fond of games and amusement; lighthearted.",
      },
      {
        "title": "premium",
        "title1": "pre·mi·um",
        "description": "an amount to be paid for an insurance policy.",
      },
      {
        "title": "new",
        "title1": "new",
        "description": "not exist before; made, introduced, or discovered recently or now for the first time",
      },
      {
        "title": "playful",
        "title1": "play·ful",
        "description": "fond of games and amusement; lighthearted.",
      },
      {
        "title": "premium",
        "title1": "pre·mi·um",
        "description": "an amount to be paid for an insurance policy.",
      },
      {
        "title": "new",
        "title1": "new",
        "description": "not exist before; made, introduced, or discovered recently or now for the first time",
      },
      {
        "title": "playful",
        "title1": "play·ful",
        "description": "fond of games and amusement; lighthearted.",
      },
      {
        "title": "premium",
        "title1": "pre·mi·um",
        "description": "an amount to be paid for an insurance policy.",
      },
      {
        "title": "new",
        "title1": "new",
        "description": "not exist before; made, introduced, or discovered recently or now for the first time",
      },
    ];
  },
]);
