angular.module("app").component("colorEmotionStudyComponent", {
  templateUrl:
    "app/components/color-emotion-study/color-emotion-study.tmpl.html",
  controller: function ($scope, $uibModal, $location, appConfig, $http) {
    $scope.progressMax = 100;
    $scope.progressValue = 0;
    $scope.colorEmotionData = {};
    $scope.products = [];
    $scope.serverError = null;
    $scope.showModal = false;
    $scope.setEntries = true;
    $scope.combinationsShown = [];
    $scope.socialLinks = appConfig.socialLinks;

    $http
      .get(appConfig.dashboardServiceUrl + "api/emotions/entries.json")
      .then((res) => {
        $scope.colorEmotionData = res.data;
        $scope.colors = res.data.colors;
        $scope.products = res.data.products;

        $scope.getRandomColor = () => {
          const index = $scope.randomInteger(1, $scope.colors.length) - 1;
          const colors = $scope.colors;
          $scope.colors = colors.filter((_, i) => i !== index);
          return colors[index];
        };
        $scope.getRandomProduct = () => {
          const index = $scope.randomInteger(1, $scope.products.length) - 1;
          const products = $scope.products;
          $scope.products = products.filter((_, i) => i !== index);
          return products[index];
        };

        $scope.activeColor = $scope.getRandomColor();
        $scope.activeProduct = $scope.getRandomProduct();
      });

    $scope.emotions = [
        { title: "Excited", src: "../../../assets/img/icons/smile/excited1.svg" },
        { title: "Trusting", src: "../../../assets/img/icons/smile/trust1.svg" },
        { title: "Neutral", src: "../../../assets/img/icons/smile/neutral1.svg" },
        { title: "Bored", src: "../../../assets/img/icons/smile/bored1.svg" },
        {title: "Disgusted",src: "../../../assets/img/icons/smile/disgust1.svg"},
    ];

    $scope.countOfSelected = 0;

    $scope.randomInteger = (min, max) => {
      let rand = min - 0.5 + Math.random() * (max - min + 1);
      return Math.round(rand);
    };
    $scope.handleClickEmoji = (emotion) => {
      if ($scope.setEntries) {
        const params = {
          entry: {
            color: $scope.activeColor.rgb,
            product: $scope.activeProduct.key,
            score: emotion.title,
            session_id: $scope.colorEmotionData.session_id,
          },
        };

        $scope.init = () => {
          $http
            .post(
              appConfig.dashboardServiceUrl + "api/emotions/entries.json",
              params
            )
            .then(() => {
              if ($scope.progressValue < $scope.progressMax) {
                $scope.progressValue += 10;
              }
              $scope.countOfSelected++;

              const isCombinationRepeat = (combination, combinations) => {
                return !!combinations.find(
                  (item) =>
                    item.product === combination.product &&
                    item.color === combination.color
                );
              };

              const generateCombination = () => ({
                product: $scope.getRandomProduct(),
                color: $scope.getRandomColor(),
              });

              const generateUniqCombination = (combinations) => {
                let generated = generateCombination();
                while (isCombinationRepeat(generated, combinations)) {
                  generated = generateCombination();
                }
                return generated;
              };

              const combination = generateUniqCombination(
                $scope.combinationsShown
              );

              $scope.activeProduct = combination.product;
              $scope.activeColor = combination.color;
              $scope.combinationsShown.push(combination);

              if ($scope.progressValue === 20) {
                if (this.mode === "landing") {
                  $location.path("/color-emotion-study");
                }
              }

              if (!this.mode && $scope.countOfSelected === 2) {
                $scope.openSurveyModal();
              }

              if ($scope.progressValue === 100) {
                $scope.openThankYouModal();
                $scope.setEntries = false;
              }
            })
            .catch((error) => {
              $scope.serverError = Object.keys(error.data.errors).map((key) => {
                return `${key} ${error.data.errors[key].join(" ")}`;
              });
            });
        };

        $scope.init();
      }
    };

    $scope.openThankYouModal = () => {
      $uibModal.open({
        templateUrl: "app/components/modal/thankYouModal.tmpl.html",
        windowTopClass: "emotion-popup",
        backdropClass: "emotion-popup-backdrop",
        controller: function ($scope, $uibModalInstance) {
          $scope.cancel = function () {
            $uibModalInstance.dismiss("cancel");
          };
        },
      });
    };

    $scope.openSurveyModal = () => {
      const { colorEmotionData } = $scope;

      $uibModal.open({
        templateUrl: "app/components/modal/emotion-modal.tmpl.html",
        windowTopClass: "emotion-popup",
        backdropClass: "emotion-popup-backdrop",
        controller: function ($scope, $uibModalInstance) {
          $scope.colorEmotionDataModal = colorEmotionData;
          $scope.formErrors = [];

          $scope.gender = "Please Select";
          $scope.born = "Please Select";
          $scope.country = "Please Select";
          $scope.activeColor = null;

          $scope.sendForm = function () {
            $scope.formErrors = [];

            const validateForms = (field) => {
              if (!$scope[field] || $scope[field] === "Please Select") {
                $scope.formErrors.push(field);
              }
            };
            validateForms("gender");
            validateForms("born");
            validateForms("country");
            validateForms("activeColor");

            if (!$scope.formErrors.length) {
              const params = {
                entry: {
                  gender: $scope.gender,
                  born_in: $scope.born,
                  country: $scope.country,
                  favorite_color: $scope.activeColor.rgb,
                },
              };

              $http
                .patch(
                  appConfig.dashboardServiceUrl +
                    `api/emotions/entries/${$scope.colorEmotionDataModal.session_id}.json`,
                  params
                )
                .then(() => {
                  $uibModalInstance.close();
                })
                .catch((error) => {
                  $scope.serverError = error;
                });
            }
          };

          $scope.setActive = function (color) {
            $scope.activeColor = color;
          };

          $scope.ok = function () {
            $uibModalInstance.close();
          };

          $scope.cancel = function () {
            $uibModalInstance.dismiss("cancel");
          };
        },
      });
    };

    $scope.isModeLanding = () => this.mode === "landing";
  },
  bindings: {
    mode: "=", // landing
  },
});
