angular.module("app").directive("fashionYearColorFrequency", () => {
  let link = (scope) => {
    scope.isNotActive = (index, percentage) => {
      const percent = parseFloat(percentage);
      let availableIndex = 0;
      if(percent > 0 && percent < 1){
        availableIndex = 1;
      } else if(percent >= 1 && percent <= 5){
        availableIndex = 2;
      } else if(percent > 5.01 && percent <= 10){
        availableIndex = 4;
      } else if(percent > 10.01 && percent <= 20){
        availableIndex = 6;
      } else if(percent > 20.01){
        availableIndex = 9;
      }
      return 8 - index >= availableIndex || availableIndex === 0;
    };

    scope.colorBoxes = Array(9).fill(null);
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbFashionYearColorFrequency/dbFashionYearColorFrequencyView.html",
    link: link,
    scope: {
      data: "="
    }
  };
});
