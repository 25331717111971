angular.module("app").directive("sportColorUsedColorAcrossTeams", () => {
  let link = (scope) => {
    scope.showImageDetails = false;
    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (index) => {
      scope.imagedata = scope.data[index];
      scope.index = index;
      scope.toggleImageDetails();
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbSportUsedColorAcrossTeams/dbSportUsedColorAcrossTeamsView.html",
    link: link,
    scope: {
      data: "=",
      mode: "=",
    }
  };
});
