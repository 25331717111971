angular.module('app').directive('colorformscol', function () {
  function link(scope) {
    function splitRGB () {
      if (!scope.rgb)
        return;
      return {
        r: scope.rgb.split(',')[0].trim(),
        g: scope.rgb.split(',')[1].trim(),
        b: scope.rgb.split(',')[2].trim()
      }
    }

    function splitCMYK () {
      if (!scope.cmyk)
        return;
      try {
        var cmyk = {
          c: scope.cmyk.split(',')[0].trim(),
          m: scope.cmyk.split(',')[1].trim(),
          y: scope.cmyk.split(',')[2].trim(),
          k: scope.cmyk.split(',')[3].trim()
        }
        return cmyk;
      } catch (e) {
        return
      }
    }

    function splitHSL () {
      if (!scope.hsl)
        return;
      return {
        h: Math.trunc(+scope.hsl.split(',')[0].trim()),
        s: Math.trunc(+scope.hsl.split(',')[1].trim()),
        l: Math.trunc(+scope.hsl.split(',')[2].trim())
      }
    }

    scope.splittedRGB = splitRGB();
    scope.splittedCMYK = splitCMYK();
    scope.splittedHSL = splitHSL();
  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/colorFormsCol/colorFormsCol.html',
    link: link,
    scope: {
      name:'=',
      rgb:'=',
      cmyk:'=',
      hsl:'=',
      hex:'=',
      optionWidth:'=',
      optionStyles:'='
    }
  };
});
