angular.module("app").directive("actualPharmaColorModal", () => {
  let link = (scope) => {
    scope.allData = {
      data: scope.companyColorData,
      index: scope.index,
    };

    scope.data = scope.companyColorData[scope.index];

    // dummy data
    scope.colorStatistics = [
      {
        percentage: '8%',
        hexValue: '#448ccb'
      },
      {
        percentage: '8%',
        hexValue: '#d638c5'
      },
      {
        percentage: '8%',
        hexValue: '#448ccb'
      },
      {
        percentage: '8%',
        hexValue: '#448ccb'
      },
      {
        percentage: '8%',
        hexValue: '#448ccb'
      },
      {
        percentage: '8%',
        hexValue: '#448ccb'
      },
      {
        percentage: '8%',
        hexValue: '#52d638'
      },
      {
        percentage: '8%',
        hexValue: '#448ccb'
      },
      {
        percentage: '8%',
        hexValue: '#52d638'
      },
      {
        percentage: '8%',
        hexValue: '#448ccb'
      },
      {
        percentage: '10%',
        hexValue: '#ffffff'
      },
      {
        percentage: '8%',
        hexValue: '#448ccb'
      }
    ];

    scope.isWhite = color => {
      return color === '#ffffff';
    };

    scope.LeftButtonHandler = () => {
      let index = scope.allData.index;
      if (index > 0) {
        index--;
      }
      scope.allData.index = index;
      scope.data = scope.allData.data[index];
    };

    scope.RightButtonHandler = () => {
      try {
        let index = scope.allData.index;
        if (index < scope.allData.data.length - 1) {
          index++;
        }
        scope.allData.index = index;
        scope.data = scope.allData.data[index];
      } catch (e) {}
    };

    scope.closeClickHandler = () => {
      scope.closefunction();
    };

    scope.shareFacebook = (url) => {
      window.open("https://www.facebook.com/sharer/sharer.php?u=" + url);
    };

    scope.shareTwitter = (url) => {
      window.open("https://twitter.com/home?status=" + url);
    };

    scope.sharePinterest = (url) => {
      window.open("https://pinterest.com/pin/create/button/?url=" + url);
    };

    scope.shareGooglePlus = (url) => {
      window.open("https://plus.google.com/share?url=" + url);
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbActualPharmaColorsForYear/dbActualPharmaColorModalView.html",
    link: link,
    scope: {
      companyColorData: "=",
      index: "=",
      onClose: "&",
      closefunction: "=",
    },
  };
});
