angular.module("app").service("pharmaDashboardRepository", [
  "appConfig",
  "authService",
  "httpService",
  function (appConfig, authService, httpService) {
    this.getSettings = () => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/pharma/settings.json", authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getCompanyList = (str) => {
      return httpService.get(
        appConfig.dashboardServiceUrl + `api/pharma/settings/companies?q[company]=${str}`,
        authService.getHeaderConfig()
      ).then((res) => {
        if (res) {
          return res.data;
        }
      });
    };

    this.getAreaList = (str) => {
      return httpService.get(
        appConfig.dashboardServiceUrl + `api/pharma/settings/areas?q[area]=${str}`,
        authService.getHeaderConfig()
      ).then((res) => {
        if (res) {
          return res.data;
        }
      });
    };

    this.getPills = (field, id, page, per_page) => {
      let queryField = '';
      if (field === 'color') {
        queryField = 'color_id';
      } else if (field === 'pharmaceuticalCompany') {
        queryField = 'company_id';
      } else if (field === 'therapeuticArea') {
        queryField = 'area_id';
      } else if (field === 'pillShape') {
        queryField = 'shape';
      } else if (field === 'year') {
        queryField = 'year';
      } 

      return httpService
        .get(
          appConfig.dashboardServiceUrl +
            `api/pharma/dashboard?${queryField}=${id}&page=${page}&per_page=${per_page}`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    // color color data API 
    this.getColorChartsDataByColorId = (id,node) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/pharma/dashboard/colors?color_id=" + id,{
              params: { token: authService.token, node: node }})
          .then(function (data) {
            return (data.data);
          });
    };

    // company color data API 
    this.getCompanyChartsDataByCompanyId = (id,node) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/pharma/dashboard/company?company_id=" + id,{
              params: { token: authService.token, node: node }})
          .then(function (data) {
            return (data.data);
          });
    };

    // year color data API 
    this.getYearChartsDataByYearId = (id,node) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/pharma/dashboard/years?year=" + id,{
              params: { token: authService.token, node: node }})
          .then(function (data) {
            return (data.data);
          });
    };

    // shape color data API 
    this.getShapeChartsDataByShapeId = (id,node) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/pharma/dashboard/shape?shape=" + id,{
              params: { token: authService.token, node: node }})
          .then(function (data) {
            return (data.data);
          });
    };
  },
]);
