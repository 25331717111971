(function () {
  'use strict';
    const serviceId = 'repo.meta';
    angular
    .module('app')
    .service(serviceId,
      ['data', 'common',
        function (dataContext, common) {
          const context = dataContext.context({
              controller: 'charts'
          });

          const $q = common.$q;
          function objects(req) {
              const def = $q.defer();
              context.get('objects')
                  .then(function (response) {
                      const categories = (response.result || response.data).categories;
                      def.resolve(categories);
                  }).catch(function () {
                      const res = {};
                      def.resolve(res);
            });

            return def.promise;
          }

          this.objects = objects;
        }
      ]);
}());
