angular.module("app").directive("hueChartUseOfColorByCarBrand", function () {
  function link(scope) {
    scope.$watch("data", (newValue, oldValue) => {
      if (newValue) {
        bindData();
      }
    });

    function bindData() {
      scope.carBrands = [];
      scope.carBrands = scope.data.data && scope.data.data.result;
    }

    scope.widthDynamic = percent => {
      if (percent === 0){
        return 0
      } else if(percent > 0 && percent <= 1){
        return 20;
      } else if(percent > 1 && percent <= 5){
        return 40;
      } else if(percent > 5 && percent <= 10){
        return 60;
      } else if(percent >= 11){
        return 80;
      }
    }
  }

  const directive = {
    link: link,
    restrict: "E",
    replace: true,
    scope: {
      data: "=",
      mode: "=",
      selectedColor: "=",
    },
    templateUrl:
      "app/directives/customInfographicsAutoDirectives/colorByCarBrand/chartUseOfColorByCarBrand.html",
  };

  return directive;
});
