angular.module("app").directive("categoryColorComparison", () => {
  let link = (scope) => {
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbCategoryColorComparison/dbCategoryColorComparisonView.html",
    link: link,
    scope: {
      categoryData: "=",
      mode: "=",
    }
  };
});
