angular.module("app").directive("designerUseColorAcrossCities", () => {
  let link = (scope) => {
    scope.$watch("data", (newValue) => {
      if (newValue.length !== 0) {
        scope.data = scope.data.filter(item => item.colors.length > 0);
      }
    });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbDesignerUseColorAcrossCities/dbDesignerUseColorAcrossCitiesView.html",
    link: link,
    scope: {
      data: "="
    }
  };
});
