angular.module("app").directive("colorResearchComplete", () => {
  let link = (scope) => {
    scope.colorSelect = (color) => {
      if (scope.selectedColor && scope.selectedColor.hex === color.hex) {
        scope.selectedColor = null;
      }
      else {
        scope.selectedColor = color;
      }

      scope.selectHandle('color', scope.selectedColor);
    };

    scope.isSelectedColor = (color) => {
      return scope.selectedColor && scope.selectedColor.hex === color.hex;
    }

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorResearchData/colorResearchComplete/colorResearchCompleteView.html",
    link: link,
    scope: {
      colors: "=",
      selectedColor: "=",
      selectHandle: "=",
      isSelectable: "=",
    }
  };
});
