angular.module("app").directive("unicornIndustryUsingColorPieChart", function () {
  function link(scope) {
    scope.$watch("categoryData", bindData);
    scope.$watch('selectedColor', function (selectedColor) {
      if (selectedColor) {
        scope.selectedColor = scope.selectedColor
        bindData();
      }
    });

    function bindData() {
      const element = document.getElementById(`unicorn_industry_using_color_pie_chart_${scope.mode}_dataviz`);
      if (element) {
        element.innerHTML = "";
      }

      let width = 200;
      let height = 200;
      const margin = 0;

      var customMedia = window.matchMedia("(min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1)");

      if (customMedia.matches) {
        width = 180;
        height = 180;
      }

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      const svg = d3
        .select(`#unicorn_industry_using_color_pie_chart_${scope.mode}_dataviz`)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      // Create dummy data
      const data = {
        "color1": scope.categoryData.percentage,
        "color2": 100 - scope.categoryData.percentage,
      };

      const colorData = {
        "color1": { hex: scope.selectedColor.hex },
        "color2": { hex: "#fff" },
      };

      // Compute the position of each group on the pie:
      const pie = d3.layout
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value;
        });
      const data_ready = pie(d3.entries(data));

      const data2 = {
        "color": 100,
      };

      const data_ready2 = pie(d3.entries(data2));

      // The arc generator
      const arc = d3.svg
        .arc()
        .innerRadius(radius * 0.85) // This is the size of the donut hole
        .outerRadius(radius * 0.95);

      const arc3 = d3.svg
        .arc()
        .innerRadius(radius * 0.93)
        .outerRadius(radius * 0.95);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", function (d) {
          return colorData[d.data.key].hex;
        })
        .attr(
          "stroke", function (d) {
            return colorData[d.data.key].hex === "#ffffff"
              ? "#E0E0DD"
              : colorData[d.data.key].hex;
          }
        )
        .style(
          "stroke-width",  function (d) {
            return colorData[d.data.key].hex === "#ffffff"
              ? "1px"
              : "0px"
          }
        );

      svg
        .selectAll("allSlices")
        .data(data_ready2)
        .enter()
        .append("path")
        .attr("d", arc3)
        .attr("fill", function (d) {
          return scope.selectedColor.hex;
        });

      svg
        .append("text")
        .text(`${scope.categoryData.percentage}%`)
        .attr("transform", "translate(0, 45)")
        .attr("font-size", "24px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B")
        .attr("style", "font-weight: bold");

      svg
        .append("image")
        .attr("href", scope.categoryData.logo)
        .attr("width", 60)
        .attr("height", 60)
        .attr("transform", "translate(-30, -50)");

    }
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorUnicornData/unicornIndustryUsingColorPieChart/unicornIndustryUsingColorPieChartView.html",
    link: link,
    scope: {
      mode: "=",
      categoryData: "=",
      selectedColor: "=",
    },
  };
});
