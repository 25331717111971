angular.module("app").directive("wordSearchResultsBubbleChart", [
  "$location",
  ($location) => {
  let link = (scope) => {
    scope.selectedColors = [];

    scope.params = $location.search();
    scope.searchWord = scope.params.color;

    scope.$watch("data", bindData);

    function bindData() {
      if (scope.data && scope.data.length > 0) {
        scope.selectedColors = [...scope.data];
      }
      const element = document.getElementById(`my_word_search_result_bubble_chart${scope.chartId}_dataviz`);
      if (element) {
        element.innerHTML = "";
      }

      const width = 600;
      const height = 600;

      const Nodes = [];

      for (let i = 0; i < scope.selectedColors.length; i++) { //
        const d = scope.selectedColors[i];
        Nodes.push(d);
      }

      const svg = d3
        .select(`#my_word_search_result_bubble_chart${scope.chartId}_dataviz`)
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      const bubble = d3.layout.pack()
        .size([width, height])
        .value(function(d) {
          return d.size;
        })
        .padding(-5);

      // generate data with calculated layout values
      const nodes = bubble.nodes(processData(Nodes))
        .filter(function(d) {
          return !d.children;
        }); // filter out the outer bubble

      //nodes for Bubbles are generated and a circle is appended.
      const node = svg.selectAll('circle').data(nodes);

      node.enter().append('circle')
        .attr('transform', function(d) {
          return 'translate(' + d.x + ',' + d.y + ')';
        })
        .attr('r', function(d) {
          return d.r;
        }) //(d.size *2); })
        .attr('fill', function(d) {
          return d.color;
        })
        .style("stroke",function (d) {
          return d.color === '#ffffff' || d.color === '#FFFFFF' ?
            '#E0E0DD' : d.color;
        });

      //Label of the name on the bubble
      const label = node.enter().append("text")
        .style("font-family", "Gotham SSm A, Gotham SSm B")
        .style("text-anchor", "middle")
        .style("font-size", function(d) {
          return d.r/2 ;
        })
        .style("fill", function (d) {
          return d.color === '#000000' ? '#ffffff' : "#000000";
        })
        .text(function(d) {
          return d.size ;
        })
        .attr('transform', function(d) {
          return 'translate(' + d.x + ',' + (d.y + d.r/6) + ')';
        });
    }

    /*
     * The data is organized as name,color,size
     */
    function processData(data) {
      const obj = data;
      const newDataSet = [];
      for (const prop in obj) {
        if(obj[prop].count > 0){
          newDataSet.push({
            name: obj[prop].name,
            color: obj[prop].color,
            size: obj[prop].count
          });
        }
      }
      return {
        children: newDataSet
      };
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbWordSearchResultsBubbleChart/dbWordSearchResultsBubbleChartView.html",
    link: link,
    scope: {
      data: "=",
      chartId: "=",
    }
  };
}]);
