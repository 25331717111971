angular.module('app').directive('bigcolorsformsdetails', function () {
  function link (scope) {
    scope.$watch('cmyk', function (color) {
      if (color) scope.splittedHEX = splitForm(scope.hex);
    });
    scope.$watch('hsl', function (color) {
      if (color) scope.splittedHSL = splitForm(scope.hsl);
    });
    scope.$watch('rgb', function (color) {
      if (color) scope.splittedRGB = splitForm(scope.rgb);
    });
  }

  function splitForm (form) {
    try {
      return form.split(',');
    } catch (err) {
      return [null, null, null, null, null, null];
    }
  }

  return {
    restrict:'E',
    templateUrl:'app/directives/bigColorFormsDetails/bigColorsFormsDetails.html',
    link: link,
    scope: {
      cmyk:'=',
      hex:'=',
      hsl:'=',
      rgb:'='
    }
  }
});
