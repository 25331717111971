(function () {
  angular.module('app').directive('hueChartColorsWithGroupsForTwo',
    [
      'common', 'config', 'chartsHelper', 'colorSortService',
      function (common, config, chartsHelper, colorSortService) {

        function link(scope, element) {
          scope.$watch("data", (newValue) => {
            if (newValue) {
              bindData();
            }
          });

          function bindData() {

            if (!scope.data) {
              return;
            }

            if (scope.data[0].chart) {
              scope.palettes1 = _.chunk(scope.data[0].chart, 5);
              scope.palettes2 = _.chunk(scope.data[1].chart, 5);
              scope.year1 = scope.data[0].year;
              scope.year2 = scope.data[1].year;
            }
            const containers = element.find('[chart-block]');

            _.each(containers, function (c, i) {
              let ch;
              const container = $(c);

              const containerBagel = container.find('[chart-type="bagel"]').html('');
              const containerGroups = container.find('[chart-type="groups"]').html('');

              const title = scope.data[i].year;
              container.find('h3').text(title);

              const dataColor = scope.data[i].colors;
              const dataTopBasic = scope.data[i].top_4_rgb;
              if (dataColor) {

                let options = {
                  bars: {
                    radius: 85,
                    radiusOuter: 1,
                    radiusInner: 62,
                    legend: {},
                    separator: {radius: 6}
                  }
                };

                if (i === 1) {
                  options.bars = {
                    radius: 85,
                    radiusOuter: 1,
                    radiusInner: 62,
                    legend: {
                      position: 'left'
                    },
                    separator: {radius: 6}
                  };
                }
                const colors = _.map(dataTopBasic,
                function (c) {
                  return {
                    value: c.count,
                    valueTitle: c.percentage,
                    value2: c.count,
                    valueTitle2: c.percentage.replace('%',''),
                    title: c.hex,
                    color: c.rgb
                  }
                });

                if (containerBagel[0]) {
                  ch = new chartBoxBagelInfo({
                    data: colors,
                    container: containerBagel[0],
                    options: options
                  });

                }
                // groups
                // if (scope.mode && scope.mode.extraView) {
                //   const rowsAmount = 5, columnsAmount = 5;
                //   const allColors = [];
                //   _.each(dataColor, function (gr) {
                //     _.each(gr.colors, function (c) {
                //       allColors.push({color: c.color});
                //     });
                //   });

                //   const rows = scope['rows' + (i + 1)] = [];
                //   scope['year' + (i + 1)] = title;
                //   for (let ii = 0; ii < rowsAmount; ii++) {
                //     const row = {colors: []};
                //     rows.push(row);
                //     for (let j = 0; j < columnsAmount; j++) {
                //       const column = {};
                //       row.colors.push(column);

                //       const color = allColors[ii * columnsAmount + j];
                //       if (!color) {
                //         column.isEmpty = true;
                //       } else {
                //         column.color = color.color;
                //       }
                //     }
                //   }
                // }

                // generic groups
                const groups = _.map(dataColor,
                  function (c) {
                    return {
                      value: c.count,
                      valueTitle: c.percentage,
                      value2: c.count,
                      valueTitle2: c.percentage.replace('%',''),
                      title: c.name,
                      color: c.color
                    }
                  });

                options = {
                  layout: {
                    bars: {
                      bar: {
                        height: 130,
                        width: 25,
                        margin: {
                          left: 6,
                          right: 6
                        },
                        background: '#e3e3e3'
                      },
                      separator: {
                        height: 24,
                        radius: 6
                      },
                      value: {
                        height: 22
                      },
                      value2: {
                        height: 22
                      }
                    },
                    padding: {
                      left: 10,
                      right: 10,
                      top: 0,
                      bottom: 10
                    }
                  }
                }
                ch = new chartBoxLinearVertical({
                  data: groups,
                  container: containerGroups[0],
                  options: options
                });
              }
            });
          }
        }

        const directive = {
          link: link,
          restrict: 'E',
          replace: true,
          scope: {
            data: '=',
            mode: '='
          },
          templateUrl: 'app/directives/custom-infographics-directives/chart-colors-with-groups-for-two.html'
        };

        return directive;

      }
    ]);
}());
