angular.module("app").directive("colorindexpopupauto", () => {
  let link = (scope) => {
    scope.allData = {
      data: scope.colorList,
      index: scope.index,
    };

    scope.data = scope.allData.data[scope.allData.index];
    scope.colorRGBTitle = () => {
      if (scope.data.rgb !== null) {
        const rgb =  scope.data.rgb.split(" ");
        return `${rgb[0]} / ${rgb[1]} / ${rgb[2]}`;
      }
      return "";
    };

    scope.LeftButton = () => {
      let index = scope.allData.index;
      if (index > 0) {
        index--;
      }
      scope.allData.index = index;
      scope.data = scope.allData.data[index];
    };

    scope.RightButton = () => {
      try {
        let index = scope.allData.index;
        if (index < scope.allData.data.length - 1) {
          index++;
        }
        scope.allData.index = index;
        scope.data = scope.allData.data[index];
      } catch (e) {}
    };

    scope.closeClickHandler = () => {
      scope.toggleFunction(false);
    };

    scope.newmoodboard = (item) => {
      item.brand = scope.brand;
      item.year = scope.year;
      const data = {
        type: 'auto',
        item: item
      }  
      scope.$emit("mood_state_chart", data);
    };

    scope.shareFacebook = (url) => {
      window.open("https://www.facebook.com/sharer/sharer.php?u=" + url);
    };

    scope.shareTwitter = (url) => {
      window.open("https://twitter.com/home?status=" + url);
    };

    scope.sharePinterest = (url) => {
      window.open("https://pinterest.com/pin/create/button/?url=" + url);
    };

    scope.shareGooglePlus = (url) => {
      window.open("https://plus.google.com/share?url=" + url);
    };
    
    scope.shareInstagram = (url) => {
      window.open("https://instagram.com/?url=" + url);
    };
  };
  return {
    restrict: "E",
    templateUrl: "app/directives/colorIndexPopupAuto/colorIndexPopupAutoView.html",
    link: link,
    scope: {
      color: "=",
      toggleFunction: "=",
      colorList: "=",
      index: "=",
      mode: "=",
      brand: "=",
      year: "=",
      logo: "=",
    },
  };
});
