angular.module("app").directive("pharmaColorData", [
  "pharmaDashboardRepository",
  (pharmaDashboardRepository) => {
    let link = (scope, element) => {
      scope.therapeuticAreaData = {
        description: "This is Photoshop's version of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis, sem nibh id elit.",
        therapeuticArea: "15",
        pills: 411,
        yearRange: "1992-2000"
      };

      scope.boardType = "pharma";
      scope.showMoreColors = false;
      scope.showAutoPopup = false;
      scope.showColorPopup = false;
      scope.colorsCount = 22;
      scope.topColorsExpanded = false;
      scope.colorFrequencyExpanded = false;

      scope.description = '';
      scope.fullDescription = '';
      scope.showingFullDesc = false;
      scope.moreDescAvailable = false;
      scope.maxDescWords = 280;

      scope.top30companies = [];
      scope.colorFiveDots = [];
      scope.logo_url = '';

      // dummyData
      const colorComparisonData = {
        percentages: [
          ">18.44%",
          "9.83%-7.09%",
          "9.83%",
        ],
        mostPopular: [
          {
            percentage: 40,
            title: "color1",
            color: { hex: "#db4034" }
          },
          {
            percentage: 30,
            title: "color2",
            color: { hex: "#2f52a0" }
          },
          {
            percentage: 30,
            title: "color3",
            color: { hex: "#ffffff" }
          }
        ],
        moderate: [
          {
            percentage: 23,
            title: "color4",
            color: { hex: "#020303" }
          },
          {
            percentage: 16,
            title: "color5",
            color: { hex: "#87ae27" }
          },
          {
            percentage: 16,
            title: "color6",
            color: { hex: "#c0c0bf" }
          },
          {
            percentage: 16,
            title: "color7",
            color: { hex: "#efe648" }
          },
          {
            percentage: 16,
            title: "color8",
            color: { hex: "#e37a36" }
          },
          {
            percentage: 13,
            title: "color9",
            color: { hex: "#407d30" }
          }
        ],
        infrequent: [
          {
            percentage: 30,
            title: "color10",
            color: { hex: "#8dcfda" }
          },
          {
            percentage: 20,
            title: "color11",
            color: { hex: "#f2f3db" }
          },
          {
            percentage: 20,
            title: "color12",
            color: { hex: "#67539e" }
          },
          {
            percentage: 20,
            title: "color13",
            color: { hex: "#924a1f" }
          },
          {
            percentage: 10,
            title: "color14",
            color: { hex: "#a46bab" }
          }
        ]
      };

      scope.selectedTherpeuticArea = {
        name: "AUTOIMMUNE",
        img_url: "assets/img/therapy/autoimmune.png",
        colorComparisonData
      };

      scope.colorTherapeuticAreaComaprisonData = [
        {
          name: "CNS/NEUROLOGY",
          img_url: "assets/img/therapy/cns.png",
          colorComparisonData
        },
        {
          name: "CARDIOVASCULAR",
          img_url: "assets/img/therapy/cardiovascular.png",
          colorComparisonData
        },
        {
          name: "DERMATOLOGY",
          img_url: "assets/img/therapy/dermatology.png",
          colorComparisonData
        },
        {
          name: "ENDOCRINOLOGY",
          img_url: "assets/img/therapy/endocrinology.png",
          colorComparisonData
        },
        {
          name: "GASTROENTEROLOGY",
          img_url: "assets/img/therapy/gastroenterology.png",
          colorComparisonData
        },
        {
          name: "HEMATOLOGY",
          img_url: "assets/img/therapy/hematology.png",
          colorComparisonData
        },
        {
          name: "INFECTIOUS",
          img_url: "assets/img/therapy/infectious.png",
          colorComparisonData
        },
        {
          name: "PSYCHIATRY",
          img_url: "assets/img/therapy/psychiatry.png",
          colorComparisonData
        },
        {
          name: "RESPIRATORY",
          img_url: "assets/img/therapy/respiratory.png",
          colorComparisonData
        },
        {
          name: "UROLOGY",
          img_url: "assets/img/therapy/urology.png",
          colorComparisonData
        },
        {
          name: "PAIN",
          img_url: "assets/img/therapy/pain.png",
          colorComparisonData
        },
        {
          name: "WOMEN'S HEALTH",
          img_url: "assets/img/therapy/women-health.png",
          colorComparisonData
        },
        {
          name: "ONCOLOGY",
          img_url: "assets/img/therapy/oncology.png",
          colorComparisonData
        },
        {
          name: "RHEUMATOLOGY",
          img_url: "assets/img/therapy/rheumatology.png",
          colorComparisonData
        },
        {
          name: "OTHER",
          img_url: "assets/img/therapy/other.png",
          colorComparisonData
        }
      ];

      scope.selectedItem = scope.item;

      // dummy data
      scope.colorShapePercentageByAllTherapetuicArea = [
        {
          name: "ROUND",
          img_url: "assets/img/shapes/round.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "OVAL",
          img_url: "assets/img/shapes/oval.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "CAPSULE",
          img_url: "assets/img/shapes/capsule.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "RECTANGLE",
          img_url: "assets/img/shapes/rectangle.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "TRIANGLE",
          img_url: "assets/img/shapes/triangle.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "HEXAGON",
          img_url: "assets/img/shapes/hexagon.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "DIAMOND",
          img_url: "assets/img/shapes/diamond.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "PENTAGON",
          img_url: "assets/img/shapes/pentagon.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "SQUARE",
          img_url: "assets/img/shapes/square.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "BULLET",
          img_url: "assets/img/shapes/bullet.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "FREEFORM",
          img_url: "assets/img/shapes/freeform.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "OCTAGON",
          img_url: "assets/img/shapes/octagon.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "TEAR",
          img_url: "assets/img/shapes/tear.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "DOUBLE-CIRCLE",
          img_url: "assets/img/shapes/double-circle.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "SEMI-CIRCLE",
          img_url: "assets/img/shapes/semi-circle.png",
          percentage: "8%",
          topColor: '#bb0076'
        },
        {
          name: "CLOVER",
          img_url: "assets/img/shapes/clover.png",
          percentage: "8%",
          topColor: '#bb0076'
        }
      ];

      scope.colorShapePercentageBySelectedTherapetuicArea = [];

      for (let i = 0; i < scope.colorShapePercentageByAllTherapetuicArea.length; i++) {
        scope.colorShapePercentageBySelectedTherapetuicArea.push([
          {
            color: "#82ca9c",
            percentage: "8%"
          },
          {
            color: "#ffaab8",
            percentage: "8%"
          },
          {
            color: "#ffffff",
            percentage: "99%"
          }
        ]);
      }

      // dummy data -> top five colors for therapeutic area
      scope.topFiveColorTherapeuticAreaData = [
        {
          name: "AUTOIMMUNE",
          img_url: "assets/img/therapy/autoimmune.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "CNS/NEUROLOGY",
          img_url: "assets/img/therapy/cns.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "CARDIOVASCULAR",
          img_url: "assets/img/therapy/cardiovascular.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "DERMATOLOGY",
          img_url: "assets/img/therapy/dermatology.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "ENDOCRINOLOGY",
          img_url: "assets/img/therapy/endocrinology.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "GASTROENTEROLOGY",
          img_url: "assets/img/therapy/gastroenterology.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "HEMATOLOGY",
          img_url: "assets/img/therapy/hematology.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "INFECTIOUS",
          img_url: "assets/img/therapy/infectious.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "PSYCHIATRY",
          img_url: "assets/img/therapy/psychiatry.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "RESPIRATORY",
          img_url: "assets/img/therapy/respiratory.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "UROLOGY",
          img_url: "assets/img/therapy/urology.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "PAIN",
          img_url: "assets/img/therapy/pain.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "WOMEN'S HEALTH",
          img_url: "assets/img/therapy/women-health.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "ONCOLOGY",
          img_url: "assets/img/therapy/oncology.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "RHEUMATOLOGY",
          img_url: "assets/img/therapy/rheumatology.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        },
        {
          name: "OTHER",
          img_url: "assets/img/therapy/other.png",
          colorData: ['#ef8ca9', '#4ea5c0', '#ef8ca9', '#bfc04e', '#aa6fe7']
        }
      ];

      // color family frequency for pharma company and industry
      scope.colorFrequencyCompanyData = [
        {
          name: 'COMPANY',
          colorFrequencyData: []
        },
        {
          name: 'PHARMA INDUSTRY',
          colorFrequencyData: []
        }
      ];

      // dummy data -> color family use for pharma company across therapeutic areas
      scope.colorFamilyCompanyData = [
        {
          name: "AUTOIMMUNE",
          img_url: "assets/img/therapy/autoimmune.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "CNS/NEUROLOGY",
          img_url: "assets/img/therapy/cns.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "CARDIOVASCULAR",
          img_url: "assets/img/therapy/cardiovascular.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "DERMATOLOGY",
          img_url: "assets/img/therapy/dermatology.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "ENDOCRINOLOGY",
          img_url: "assets/img/therapy/endocrinology.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "GASTROENTEROLOGY",
          img_url: "assets/img/therapy/gastroenterology.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "HEMATOLOGY",
          img_url: "assets/img/therapy/hematology.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "INFECTIOUS",
          img_url: "assets/img/therapy/infectious.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "PSYCHIATRY",
          img_url: "assets/img/therapy/psychiatry.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "RESPIRATORY",
          img_url: "assets/img/therapy/respiratory.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "UROLOGY",
          img_url: "assets/img/therapy/urology.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "PAIN",
          img_url: "assets/img/therapy/pain.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "WOMEN'S HEALTH",
          img_url: "assets/img/therapy/women-health.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "ONCOLOGY",
          img_url: "assets/img/therapy/oncology.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "RHEUMATOLOGY",
          img_url: "assets/img/therapy/rheumatology.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        },
        {
          name: "OTHER",
          img_url: "assets/img/therapy/other.png",
          colorData: [
            {
              percentage: '99%',
              hexValue: '#ffffff'
            },
            {
              percentage: '8%',
              hexValue: '#ffaab8'
            },
            {
              percentage: '8%',
              hexValue: '#82ca9c'
            }
          ]
        }
      ];

      // dummy data -> therapeutic areas
      scope.therapeuticAreas = [
        {
          name: "AUTOIMMUNE",
          img_url: "assets/img/therapy/autoimmune.png"
        },
        {
          name: "CNS/NEUROLOGY",
          img_url: "assets/img/therapy/cns.png"
        },
        {
          name: "CARDIOVASCULAR",
          img_url: "assets/img/therapy/cardiovascular.png"
        },
        {
          name: "DERMATOLOGY",
          img_url: "assets/img/therapy/dermatology.png"
        },
        {
          name: "ENDOCRINOLOGY",
          img_url: "assets/img/therapy/endocrinology.png"
        },
        {
          name: "GASTROENTEROLOGY",
          img_url: "assets/img/therapy/gastroenterology.png"
        },
        {
          name: "HEMATOLOGY",
          img_url: "assets/img/therapy/hematology.png"
        },
        {
          name: "INFECTIOUS",
          img_url: "assets/img/therapy/infectious.png"
        },
        {
          name: "PSYCHIATRY",
          img_url: "assets/img/therapy/psychiatry.png"
        },
        {
          name: "RESPIRATORY",
          img_url: "assets/img/therapy/respiratory.png"
        },
        {
          name: "UROLOGY",
          img_url: "assets/img/therapy/urology.png"
        },
        {
          name: "PAIN",
          img_url: "assets/img/therapy/pain.png"
        },
        {
          name: "WOMEN'S HEALTH",
          img_url: "assets/img/therapy/women-health.png"
        },
        {
          name: "ONCOLOGY",
          img_url: "assets/img/therapy/oncology.png"
        },
        {
          name: "RHEUMATOLOGY",
          img_url: "assets/img/therapy/rheumatology.png"
        },
        {
          name: "OTHER",
          img_url: "assets/img/therapy/other.png",
        }
      ];

      // dummy data -> actual colors for (year) by car brands
      scope.actualColorDataForYear = [];
      for (let i = 0; i < 30; i++) {
        if (i % 4 === 0) {
          scope.actualColorDataForYear.push({
            name: 'PFIZER',
            img_url: "assets/img/company/pfizer-logo.png",
            colors: [
              '#c4df9b', '#d638c5', '#c4df9b', '#d638c5', '#c4df9b', '#d638c5', '#c4df9b', '#d638c5',
              '#c4df9b', '#d638c5', '#c4df9b', '#d638c5', '#c4df9b', '#d638c5', '#c4df9b', '#d638c5'
            ]
          });
        } else if (i % 4 === 1) {
          scope.actualColorDataForYear.push({
            name: 'TEVA',
            img_url: "assets/img/company/teva-logo.png",
            colors: [
              null, null, null, null, null, '#377dd3', null, null,
              null, null, null, '#f80c0c', null, null, null, null
            ]
          });
        } else if (i % 4 === 2) {
          scope.actualColorDataForYear.push({
            name: 'PFIZER',
            img_url: "assets/img/company/pfizer-logo.png",
            colors: [
              null, null, null, '#f80c0c', null, null, null, null,
              null, '#38d677', null, null, null, null, null, '#387fd6'
            ]
          });
        } else {
          scope.actualColorDataForYear.push({
            name: 'TEVA',
            img_url: "assets/img/company/teva-logo.png",
            colors: [
              null, null, null, null, null, null, null, null,
              null, null, null, null, '#387fd6', null, null, null
            ]
          });
        }
      }

      scope.isModeColor = () => {
        return scope.viewMode === "color";
      };

      scope.isModeYear = () => {
        return scope.viewMode === "year";
      };

      scope.isModeTherapeuticArea = () => {
        return scope.viewMode === "therapeuticArea";
      };

      scope.isModePillShape = () => {
        return scope.viewMode === "pillShape";
      };

      scope.isModeCompany = () => {
        return scope.viewMode === "pharmaceuticalCompany";
      };

      scope.setDescription = desc => {
        scope.fullDescription = desc;
        scope.description = desc.substr(0, scope.maxDescWords);
        scope.moreDescAvailable = desc.length > scope.maxDescWords;
      };
  
      scope.showMoreDesc = () => {
        if (scope.showingFullDesc) {
          scope.description = scope.fullDescription.substr(0, scope.maxDescWords);
        }
        else {
          scope.description = scope.fullDescription;
        }
        scope.showingFullDesc = !scope.showingFullDesc;
      };

      if (scope.isModeCompany()) {
        scope.isLoadingControls = true;

        pharmaDashboardRepository
        .getCompanyChartsDataByCompanyId(scope.selectedItem.id, 'header')
        .then(function (data) {
          scope.pharmaCompanyData = data.header;
          scope.logo_url = scope.pharmaCompanyData.logo_url;
          if(scope.pharmaCompanyData.description) {
            scope.setDescription(scope.pharmaCompanyData.description);
          }
          scope.isLoadingControls = false; 
        }).finally(() => {
          scope.isLoadingControls = false; 
        });

        pharmaDashboardRepository
        .getCompanyChartsDataByCompanyId(scope.selectedItem.id, 'chartData1')
        .then(function (data) {
          scope.colorFrequencyCompanyData[0].colorFrequencyData = data.chartData1.companies.colors;
          scope.colorFrequencyCompanyData[1].colorFrequencyData = data.chartData1.industries.colors;
        });

        // pharmaDashboardRepository
        // .getCompanyChartsDataByCompanyId(scope.selectedItem.id, 'chartData2')
        // .then(function (data) {
        //   console.log('dataaaaa chartData2', data);
        // });
      }

      if (scope.isModePillShape()) {
        scope.isLoadingControls = true;

        pharmaDashboardRepository
        .getShapeChartsDataByShapeId(scope.selectedItem.id, 'header')
        .then(function (data) {
          scope.pillShapeData = data.header;
          if(scope.pillShapeData.description) {
            scope.setDescription(scope.pillShapeData.description);
          }
          scope.logo_url = scope.pillShapeData.image_url;
          scope.isLoadingControls = false; 
        }).finally(() => {
          scope.isLoadingControls = false; 
        });

        pharmaDashboardRepository
        .getShapeChartsDataByShapeId(scope.selectedItem.id, 'chartData1')
        .then(function (data) {
          scope.pillShapeColorData = data.chartData1;
        });

        // pharmaDashboardRepository
        // .getShapeChartsDataByShapeId(scope.selectedItem.id, 'chartData2')
        // .then(function (data) {
        //   console.log('dataaaaa chartData2', data);
        // });
      }

      if (scope.isModeColor()) {
        scope.isLoadingControls = true;

        pharmaDashboardRepository
        .getColorChartsDataByColorId(scope.selectedItem.id, 'header')
        .then(function (data) {
          scope.colorHeaderData = data.header;
          if(scope.colorHeaderData.description) {
            scope.setDescription(scope.colorHeaderData.description);
          }
          scope.isLoadingControls = false; 
        }).finally(() => {
          scope.isLoadingControls = false; 
        });

        pharmaDashboardRepository
        .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData1')
        .then(function (data) {
          scope.colorFiveDots = data.chartData1;
          scope.colorFiveDots.forEach(element => {
            if(element.name === 'double_circle') {
              element.name = 'double circle'
            }
            if(element.name === 'semi_circle') {
              element.name = 'semi circle'
            }
          });
        });

        // pharmaDashboardRepository
        // .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData2')
        // .then(function (data) {
        //   scope.colorAreaPieData = data.chartData2;
        //   console.log('dataaaaa chartData2', data.chartData2);
        // });

        pharmaDashboardRepository
        .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData3')
        .then(function (data) {
          scope.top30companies = data.chartData3
        });
      }

      if (scope.isModeYear()) {
        scope.isLoadingControls = true;

        pharmaDashboardRepository
        .getYearChartsDataByYearId(scope.selectedItem.id, 'header')
        .then(function (data) {
          scope.yearData = data.header;
          scope.isLoadingControls = false; 
        }).finally(() => {
          scope.isLoadingControls = false; 
        });
        pharmaDashboardRepository
        .getYearChartsDataByYearId(scope.selectedItem.id, 'chartData1')
        .then(function (data) {
          scope.colorFrequencyCompanyData[0].colorFrequencyData = data.chartData1.companies.colors;
          scope.colorFrequencyCompanyData[1].colorFrequencyData = data.chartData1.industries.colors;
        });

        // pharmaDashboardRepository
        // .getYearChartsDataByYearId(scope.selectedItem.id, 'chartData2')
        // .then(function (data) {
        //   console.log('dataaaaa chartData2', data);
        // });
      }
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbPharmaColorData/dbPharmaColorDataView.html",
      link: link,
      scope: {
        item: "=",
        viewMode: "=",
        headerData: "=",
        topFinishesData: "=",
        topColorsData: "=",
        colorFrequencyData: "=",
        colorPaletteData: "=",
        shadesData: "=",
        mosaicData: "=",
        actualColorsForYear: "=",
        loadingColorsData: "=",
        logo: "=",
      },
    };
  },
]);
