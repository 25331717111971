angular.module("app").controller("autoController", [
  "$scope",
  "autoDashboardRepository",
  "dashboardOverlayService",
  "searchMenuRepository",
  "$timeout",
  "charts",
  "$state",
  "$location",
  "modalService",
  "localStorageService",
  function (
    scope,
    autoDashboardRepository,
    dashboardOverlayService,
    searchMenuRepository,
    timeout,
    charts,
    $state,
    $location,
    modalService,
    localStorageService
  ) {
    scope.menus = {
      carBrand: "",
      model: "",
      year: "",
      color: "",
    };

    scope.disabledControls = {
      brand: false,
      industry: false,
      color: false,
      attribute: false,
    };

    scope.type = {
      color: "color",
      carBrand: "carBrand",
      model: "model",
      year: "year",
    };

    scope.disabledInput = (type, value) => {
      switch (type) {
        case "carBrand":
          scope.disableModel = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "model":
          scope.disableCarBrand = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "year":
          scope.disableCarBrand = Boolean(value);
          scope.disableModel = Boolean(value);
          break;
      }
    };

    scope.mainParam = null;
    scope.mainParamId = null;
    scope.secondaryParams = {};
    scope.tab = 1;
    scope.selectedMenuId = 1;
    scope.currentPage = 0;

    scope.car_brand_place_holder = "SEARCH BY CAR BRAND";
    scope.model_place_holder = "SEARCH BY MODEL";
    scope.year_place_holder = "SEARCH BY YEAR";
    scope.header_title = "color: blue";

    scope.showDashboard = false;
    scope.title = scope.year;
    scope.subtitle = null;

    scope.isLoadingControls = true;
    scope.ajaxInProgress = false;

    scope.compareData = [];
    scope.colorsCountData = [];
    scope.colorsCountData = [];
    scope.shadesData = [];
    scope.topFamiliesData = [];
    scope.topBrandsData = [];
    scope.topColorsData = [];
    scope.colorFrequencyData = [];
    scope.topFinishesData = [];
    scope.carColorsData = [];
    scope.colorPaletteData = [];
    scope.mainSeason = [];
    scope.mosaicData = {};

    scope.carBrands =[]; 
    scope.carModels =[]; 
    scope.yearSelections = [];
    scope.showDropdown = true;
    scope.updateSelectedYear = function (year) {
      if(scope.searchMode === 'year') {
        scope.selectedYear = year;
        scope.selectedFilterValue = { title: year.name, id: year.id };
        scope.setTab(1);
        scope.loadGraphics();
      } else {
        scope.selectedYear = year;
        scope.setTab(1);
        scope.loadGraphics();
      }
    };

    if(localStorageService.get("currentUser")){
      scope.user = localStorageService.get("currentUser");
    }

    scope.params = $location.search();

    scope.$watch('params', function (params) {
      if (params.control === 'searchMenu') {
        scope.init();
      } else {
        scope.handleChangeControl(params.control, params);
      }
    });

    scope.colorPaletteBucket = 250;

    scope.setback = () => {
      $state.go("auto", {control: "searchMenu", id: "", title: "", hex: "", family: ""});
    };

    scope.init = () => {
      scope.menus = {
        carBrand: "",
        model: "",
        year: "",
        color: "",
      };
      scope.loadAutoYears();
      scope.disableCarBrand = false;
      scope.disableModel = false;
      scope.disableYear = false;
      scope.isLoadingControls = true;
      scope.currentPage = 0;
      scope.mainSeason = [];
      scope.tab = 1;
      scope.selectedMenuId = 1;
    };

    scope.menu = [
      { id: 1, title: "COLOR" },
      { id: 2, title: "BRAND A-Z" },
    ];
    scope.isSetId = function (id) {
      return scope.selectedMenuId === id;
    };
    scope.setMenuId = function (id) {
      scope.selectedMenuId = id;
      scope.currentPage = 0;
      scope.loadGraphics();
    };

    scope.setTab = (tabId) => {
      scope.tab = tabId;
      scope.select_images = false;
      scope.selected_moodboard = false;
      scope.state = 1;
    };

    scope.isSet = (tabId) => {
      return scope.tab === tabId;
    };

    scope.loadSettings = () => {
      scope.ajaxInProgress = true;
      autoDashboardRepository.getColorsForFilter().then((res) => {
        scope.colors = res.data.colors;
      }).finally(() => {
        scope.ajaxInProgress = false;
      });
    };

    scope.loadSettings();

    scope.loadAutoYears = () => {
      autoDashboardRepository.getAutoYears().then(data => { 
        scope.filteredChoices = data.data.years.map(item => {
          return { name: item, id: item };
        });
        scope.yearSelections= scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1); 
        if (scope.searchMode === 'year') {  
          let index = scope.yearSelections.findIndex(record => record.name === scope.searchId);
          scope.selectedYear = scope.yearSelections[index];
          scope.showDropdown = true;
        }
        else{
          scope.selectedYear = scope.yearSelections[0];
        }
      });
    };

    // scope.loadAutoYears();
    
    scope.changeColorPaletteBucket = function (value) {
      if (value !== scope.colorPaletteBucket) {
        autoDashboardRepository[scope.mainParam]
          .getColorPalette(scope.mainParamId, value)
          .then(function (data) {
            scope.colorPaletteData = data;
          });
      }
      scope.colorPaletteBucket = value;
    };

    scope.loadGraphics = function () {
      if (scope.mainParam) {
        scope.showDashboard = true;
        dashboardOverlayService.loadingStart(4000);
        if (scope.mainParam === "carBrand") {
          scope.mainParam = "brand";
        }

        if(localStorageService.get("currentUser")){
          scope.user = localStorageService.get("currentUser");
        }

        if (scope.mainParam === "brand") {
            autoDashboardRepository.getFrequency(scope.selectedYear.id, scope.mainParamId, scope.mainParam).then(function (res) {
              scope.newColorFrequencyData = res.data;
            })

            autoDashboardRepository.getTopFourColor(scope.selectedYear.id, scope.mainParamId, scope.mainParam).then(function (res) {
              scope.topColorsData = res.data;
            });


          const previousYear =  scope.selectedYear.id - 5;
          autoDashboardRepository.getPastYearColor(scope.mainParam, scope.selectedYear.id, previousYear, scope.mainParamId).then(function (results) {
            scope.pastSelectedYear = results.data.selected_colors;
            scope.basicColorSelected = results.data.basic_colors;
            scope.currentColorYear = [];
            scope.pastColorYear = {};
            scope.pastSelectedYear.forEach(element => {
              if(element.year === scope.selectedYear.id) {
                scope.currentColorYear.push(element);
              } else {
                scope.pastColorYear[element.year] = element.colors
              }
            });
          });

          autoDashboardRepository.getLastFiveYearColor(scope.selectedYear.id, scope.mainParamId).then(function (results) {
            scope.shadesData = results.data;
          });
        }

        if (scope.mainParam === "model") {
          const previousYear =  scope.selectedYear.id - 3;
          autoDashboardRepository.getPastYearColor(scope.mainParam, scope.selectedYear.id, previousYear, scope.mainParamId).then(function (results) {
            scope.pastSelectedYear = results.data.selected_colors;
            scope.basicColorSelected = results.data.basic_colors;
            scope.currentColorYear = [];
            scope.pastColorYear = {};
            scope.pastSelectedYear.forEach(element => {
              if(element.year === scope.selectedYear.id) {
                scope.currentColorYear.push(element);
              } else {
                scope.pastColorYear[element.year] = element.colors
              }
            });
          });

          autoDashboardRepository.getFrequency(scope.selectedYear.id, scope.mainParamId, scope.mainParam).then(function (res) {
            scope.newColorFrequencyData = res.data;
          })

          autoDashboardRepository.getTopFourColor(scope.selectedYear.id, scope.mainParamId, scope.mainParam).then(function (res) {
            scope.topColorsData = res.data;
          });
        }

        if(scope.mainParam === "color") {
          const color = scope.selectedFilterValue.id;
          autoDashboardRepository
            .getColorsBrand(color, scope.selectedYear.id)
            .then(function (results) {
              scope.carBrand = results.data.result;
            });

          
          const colorForType = scope.selectedFilterValue.id;
          autoDashboardRepository
            .getColorsType(colorForType, scope.selectedYear.id)
            .then(function (results) {
              scope.carType = results;
            });

          const previousYear =  scope.selectedYear.id - 3;
          autoDashboardRepository.getPastYearColor(scope.mainParam, scope.selectedYear.id, previousYear, scope.selectedFilterValue.id).then(function (results) {
            scope.pastSelectedYear = results.data.selected_colors;
            scope.basicColorSelected = results.data.basic_colors;
            scope.currentColorYear = [];
            scope.pastColorYear = {};
            scope.pastSelectedYear.forEach(element => {
              if(element.year === scope.selectedYear.id) {
                scope.currentColorYear.push(element);
              } else {
                scope.pastColorYear[element.year] = element.colors
              }
            });
          });
        }

        if (scope.mainParam === "year") {
          scope.mainParamId = scope.selectedYear.id;
          autoDashboardRepository.getTwoYearIndustryUseOfColor(scope.mainParamId, scope.mainParamId - 1).then(function (res) {
            scope.twoYearIndustryLogoColor = res.data;
            scope.twoYearIndustryLogoColor.forEach(element => {
              if(element.year === scope.mainParamId){
                scope.mosaicData.first = {
                  groups: element.basic_colors,
                  unique: element.colors
                }
              } else {
                scope.mosaicData.second = {
                  groups: element.basic_colors,
                  unique: element.colors
                }
              }
            });
          });
          
          autoDashboardRepository.getFrequency(scope.selectedYear.id).then(function (res) {
            scope.newColorFrequencyData = res.data;
          });

          autoDashboardRepository.getTopFourColor(scope.selectedYear.id).then(function (res) {
            scope.topColorsData = res.data;
          });

          autoDashboardRepository
            .getActualColorForYearData(scope.mainParamId)
            .then((data) => {
              scope.actualColorsForYear = data;
            });
        }

        autoDashboardRepository
          .getCarColors(
            scope.mainParam,
            scope.mainParamId,
            scope.currentPage + 1,
            scope.selectedYear.id,
            scope.selectedMenuId === 1 ? '' : 'brand'
          )
          .then(function (data) {
            if(data.limit === 'exceed'){
              modalService.showModal(11, null, scope.user.first_name);
            } else {
              scope.carColorsData = data.color_models;
              scope.pageCount = data.paginate.total_pages;
            } 
          });

        autoDashboardRepository
          .getTopFinishesColor(
            scope.mainParam,
            scope.selectedYear.id,
            scope.mainParamId
          )
          .then(function (data) {
            scope.topFinishesData = data.data;
          });

        autoDashboardRepository
          .getBasicDetails(
            scope.mainParam,
            scope.selectedYear.id,
            scope.mainParamId
          )
          .then(function (data) {
            scope.headerData = data.data;
            if (scope.mainParam === "model") {
              scope.selectedFilterValue.title = scope.headerData.model.title;
            }
          });
        scope.isLoadingControls = false;
      }
    };

    scope.setPage = (num) => {
      if (num >= 0 && num < scope.pageCount) {
        scope.currentPage = num;
        scope.loadGraphics();
      }
    };

    scope.handleChangeControl = function (control, item) {
      scope.header_title = () => {
        if (control === "carBrand") {
          return (control = "car brand");
        } else if (control === "model") {
          return (control = "car model");
        } else if (control === 'year') {
          return `${control}:`;
        }
        return `${control}: ${item.title}`;
      };
      scope.mainParam = control;
      scope.selectedFilterValue = item;
      scope.mainParamId = item.id;
      scope.searchMode = control;
      if(scope.searchMode === 'year') {
        scope.searchId = parseInt(item.title);
        scope.selectedYear = {name: parseInt(item.title), id: parseInt(item.title)};
      } else {
        scope.loadAutoYears();
      }
      scope.$watch('selectedYear', function (newValue, oldValue) {
        if(newValue) {
          scope.loadGraphics();
        }
      });
    };

    scope.handleSearchChange = function (control, item) {
      $state.go("auto", Object.assign({control: control}, item));
    };

    scope.getResultsOnFilterSearch = (type, str, page) => {
      scope.ajaxInProgress = true;  
      switch (type) {
        case 'carBrand':
          if (!str.length) {
            scope.carBrands = [];
            scope.ajaxInProgress = false;
            return;
          }
          //new logic for pagination
          if(scope.oldsearchdata === ""){
            scope.oldsearchdata = str;
            scope.selectedpage = 1;
          } 
          else if(scope.oldsearchdata !== str){  
              page = 1;
              scope.selectedpage = page;
              scope.carBrands = [];
              scope.oldsearchdata = str;
          } 
          else{
            scope.selectedpage += 1;
            page = scope.selectedpage;
          }

          autoDashboardRepository.getColorsForFilter(str, page)
            .then(data => {
              if(scope.carBrands.length>0){  
                scope.carBrands = scope.carBrands.concat(data.data.makes);
              } else {
                scope.carBrands = data.data.makes;
              }   
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break; 
        case 'carModel':
            if (!str.length) {
              scope.carModels = [];
              scope.ajaxInProgress = false;
              return;
            }
            //new logic for pagination
            if(scope.oldsearchdata === ""){
              scope.oldsearchdata = str;
              scope.selectedpage = 1;
            } 
            else if(scope.oldsearchdata !== str){  
                page = 1;
                scope.selectedpage = page;
                scope.carModels = [];
                scope.oldsearchdata = str;
            } 
            else{
              scope.selectedpage += 1;
              page = scope.selectedpage;
            }

            autoDashboardRepository.getColorsForFilter(str, page)
              .then(data => {
                if(scope.carModels.length>0){  
                  scope.carModels = scope.carModels.concat(data.data.models);
                } else {
                  scope.carModels = data.data.models;
                }   
              }).finally(() => {
                scope.ajaxInProgress = false;
              });
            break; 

        default:
          scope.ajaxInProgress = false;
      }
    }; 

    scope.$watch(
      () => {
        return dashboardOverlayService.showOverlay;
      },
      (newValue) => {
        scope.showDashboardOverlay = newValue;
      }
    );
  },
]);
