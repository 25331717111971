angular.module("app").directive("researchersStudiedSameColor", (researchIndexService) => {
  let link = (scope) => {
    scope.characters = [
      "A", "Á", "B", "C", "D", "E", "É", "F", "G", "H", "I", "K",
      "L", "M", "N", "O", "Ö", "P", "Q", "R", "S", "T", "V", "X"
    ];
    scope.selectedCharacter = "ALL";

    scope.$watch('data', function (newVal, prevVal) {
      if(newVal) {
        scope.filteredData = scope.data;
        scope.selectedCharacter = scope.data.group === 'All' ? 'ALL' : scope.data.group;
      }
    });

    scope.characterSelect = (value) => {
      scope.selectedCharacter = value;
      scope.filteredResults(value === 'ALL' ? 'All' : value);
    };
    
    scope.isSelected = (value) => {
      return scope.selectedCharacter === value;
    };

    scope.showImageDetails = false;

    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (item, index) => {
      scope.research_detail = ''
      researchIndexService.getColorChartData4DetailsById(item.id)
        .then(data => {   
          scope.research_detail = data.research_detail;
          scope.toggleImageDetails();
        });
    };

    scope.loadMore = () => {
      scope.loadMoreResearcher();
    };

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbResearchersStudiedSameColor/dbResearchersStudiedSameColorView.html",
    link: link,
    scope: {
      data: "=",
      filteredResults: "=",
      isLoadMoreResearcher: "=",
      loadMoreResearcher: "=",

    }
  };
});
