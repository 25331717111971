angular.module('app').directive('colorTrademarksAcrossCategories', () => {
  let link = (scope) => {

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorTrademarksAcrossCategories/dbColorTrademarksAcrossCategoriesView.html",
    link: link,
    scope: {
      colorData: "=",
      selectedColor: "="
    }
  };
});
