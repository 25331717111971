angular.module("app").directive("sportTeamColorPieChart", function () {
  function link(scope) {
    scope.$watch("selectedItem", bindData);

    function bindData() {
      document.getElementById("my_sport_team_dataviz").innerHTML = "";

      const width = 1000;
      const height = 500;
      const margin = 40;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      const svg = d3
        .select("#my_sport_team_dataviz")
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      // Create chart data
      const percentageData = {};
      const colorData = {};
      for (const item of scope.data) {
        colorData[item.title] = item.color;
        percentageData[item.title] = item.percentage;
      }

      // Compute the position of each group on the pie:
      const pie = d3.layout
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value;
        });
      const data_ready = pie(d3.entries(percentageData));

      // The arc generator
      const arc = d3.svg
        .arc()
        .innerRadius(radius * 0.7) // This is the size of the donut hole
        .outerRadius(radius * 0.8);

      const arc3 = d3.svg
        .arc()
        .innerRadius(radius * 0.75)
        .outerRadius(radius * 0.8);

      const arc2 = d3.svg
        .arc()
        .innerRadius(radius * 0.7)
        .outerRadius(radius * 0.9);

      // Another arc that won't be drawn. Just for labels positioning
      const outerArc = d3.svg
        .arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", function (d) {
          return colorData[d.data.key].hex;
        })
        .attr(
          "stroke", function (d) {
            return colorData[d.data.key].hex === "#ffffff"
              ? "#E0E0DD"
              : colorData[d.data.key].hex;
          }
        )
        .style(
          "stroke-width",  function (d) {
            return colorData[d.data.key].hex === "#ffffff"
              ? "1px"
              : "0px"
          }
        );

      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc3)
        .attr("fill", function (d) {
          return colorData[d.data.key].hex1 ? colorData[d.data.key].hex1 : "transparent";
        });

      // Add the polylines between chart and labels:
      svg
        .selectAll("allPolylines")
        .data(data_ready)
        .enter()
        .append("polyline")
        .attr("stroke", "black")
        .style("fill", "none")
        .attr("stroke-width", 1)
        .attr("points", function (d) {
          const posA = arc2.centroid(d); // line insertion in the slice
          const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
          const posC = outerArc.centroid(d); // Label position = almost the same as posB
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
          posC[0] = radius * 1.7 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left

          return [posA, posB, posC];
        });

      svg
        .selectAll("allLabels")
        .data(data_ready)
        .enter()
        .append("text")
        .text((d) => `${d.data.value}%`)
        .attr("transform", function (d) {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 1.75 * (midangle < Math.PI ? 1 : -1);
          pos[1] += 8;
          return "translate(" + pos + ")";
        })
        .attr("font-size", "22px")
        .attr("font-weight", "300")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B")
        .style("text-anchor", function (d) {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midangle < Math.PI ? "start" : "end";
        });

      svg
        .append("text")
        .text("38")
        .attr("transform", "translate(0, 30)")
        .attr("font-size", "40px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B");

      svg
        .append("text")
        .text("TEAMS")
        .attr("transform", "translate(0, 65)")
        .attr("font-size", "20px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B");

      svg
        .append("image")
        .attr("href", scope.selectedItem.img_url)
        .attr("width", 70)
        .attr("height", 70)
        .attr("transform", "translate(-35, -100)");

    }
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/dbSportTeamColorPieChart/dbSportTeamColorPieChartView.html",
    link: link,
    scope: {
      data: "=",
      selectedItem: "=",
    },
  };
});
