angular.module('app').directive('colorTrademarksInYear', ["legalTrendsDashboardRepository", "$timeout", (legalTrendsDashboardRepository, timeout) => {
  let link = (scope) => {
    scope.showImageDetails = false;
    scope.index = null;
    scope.saveItemImageDetails = false;
    scope.unseletedImgUrl = "/assets/img/legals/items/unselected.png";

    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (legal, item, index) => {
      scope.index = index;
      scope.imagedata = ''
      legalTrendsDashboardRepository.getYearChartData3DetailsById(scope.selectedItem.title, 'detail', legal.color_id, item.name)
        .then(data => {   
          scope.imagedata = data.detail;
          scope.toggleImageDetails();
        });
    };

    scope.loadMore = () => {
      scope.loadMoreYearData();
    };

    scope.selectTrademark = (color) => {
      scope.selectedTrademark(color)
    }

    scope.$on("mood_state_item_chart", (data) => {
      let mainData = data.targetScope.data;
      const keys = ["OWNER", "DATE GRANTED", "PRODUCT/SERVICE"];
      let newParam = {
        [keys[0]]: mainData.brand,
        [keys[1]]: mainData.filed_date,
        [keys[2]]: mainData.product,
      };

      let node = angular.element("#legalItemPopupCapture")[0];
      domtoimage
        .toPng(node, {
          bgcolor: "#fff",
        })
        .then((dataUrl) => {
          if(dataUrl) {
            scope.saveImageDetailsData = {
              base64: dataUrl,
              type: 'legal',
              data: newParam
            };
          }
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });

        let times = 2000;
        timeout(function () {
          if(scope.saveImageDetailsData) {
            scope.showImageDetails = false;
            scope.toggleSaveImageDetails();
          } else {
            timeout(function () {
              if(scope.saveImageDetailsData) {
                scope.showImageDetails = false;
                scope.toggleSaveImageDetails();
              } else {
                timeout(function () {
                  if(scope.saveImageDetailsData) {
                    scope.showImageDetails = false;
                    scope.toggleSaveImageDetails();
                  }
                }, times);
              }
            }, times);
          }
        }, times);
    });

    scope.generateImgName = function (type, board, name) {
      let result = "";
      const date = moment().format("L");
      result += name.toUpperCase() + ".";
      return result + date + type;
    };

    scope.toggleSaveImageDetails = () => {
      scope.saveItemImageDetails = !scope.saveItemImageDetails;
    };

    scope.$on("mood_state_item_chart_changed", () => {
      scope.toggleSaveImageDetails();
    });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorTrademarksInYear/dbColorTrademarksInYearView.html",
    link: link,
    scope: {
      brandData: "=",
      selectedItem: "=",
      isLoadMoreYearData: "=",
      loadMoreYearData: "=",
      colors: "=",
      selectedTrademark: "=",
    }
  };
}]);
