angular.module("app").directive("fragranceAccordDesigner", () => {
  let link = (scope) => {
    scope.getDotsList = (value) => {
      const cnt = Math.ceil(value / 10);
      
      const result = [];
      for (let i = 0; i < cnt; i ++) {
        result.push(i);
      }

      return result;
    };

    // scope.getDotsList = () => {

    //   const result = [];
    //   for (let i = 0; i < 5; i ++) {
    //     result.push(i);
    //   }
    //   return result;
    // };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbFragranceAccordDesigner/dbFragranceAccordDesignerView.html",
    link: link,
    scope: {
      data: "=",
      selectedColor: "=",
    }
  };
});
