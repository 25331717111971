angular.module("app").service("authService", [
  "$http",
  "$cookies",
  "$window",
  "appConfig",
  "$state",
  "localStorageService",
  function ($http, $cookies, $window, appConfig, $state, localStorageService) {
    const self = this;
    self.token = $cookies.get("hg_session") || null;

    this.login = function (email, password, terms, isRemembered) {
      return $http
        .post(appConfig.dashboardServiceUrl + "api/sessions.json", {
          email: email,
          password: password,
          terms: terms === true ? 1 : 0
        })
        .then(function (res) {
          if (res.data && res.data.success) {
            self.setToken(res.data.token, isRemembered);
            self.currentUser = res.data.user;
            self.token = res.data.token;
            localStorageService.set("currentUser", self.currentUser);
          }
          return res.data;
        });
    };

    this.schoolEnterpriseLogin = function (email, password, isRemembered) {
      return $http
        .post(appConfig.dashboardServiceUrl + "api/sessions.json", {
          email: email,
          password: password,
          enterprise: true
        })
        .then(function (res) {
          if (res.data && res.data.success) {
            self.setToken(res.data.token, isRemembered);
            self.currentUser = res.data.user;
            self.token = res.data.token;
            localStorageService.set("currentUser", self.currentUser);
          }
          return res.data;
        });
    };

    this.schoolSSOLogin = function (data) {
      return $http
        .post(appConfig.dashboardServiceUrl + "api/sessions/auth/saml", {
          school_id: data.school_id
        })
        .then(function (res) {
          return res.data;
        });
    };

    this.schoolSSOLoginUser = function (data) {
      return $http
        .post(appConfig.dashboardServiceUrl + "api/sessions/saml_user", {
          token: data
        })
        .then(function (res) {
          if (res.data && res.data.success) {
            self.setToken(res.data.token, false);
            self.currentUser = res.data.user;
            self.token = res.data.token;
            localStorageService.set("currentUser", self.currentUser);
          }
          return res.data;
        });
    };

    this.logOut = function () {
      $cookies.remove("hg_session");
      self.token = null;
      self.currentUser = {};
      localStorageService.set("currentUser", {});
      $state.go("landing-page");
    };

    this.loadCurrentUser = function () {
      return $http
        .get(appConfig.dashboardServiceUrl + "api/users.json", {
          params: { token: self.token },
        })
        .then(function (data) {
          if (data.status === 200) {
            self.currentUser = data.data;
          } else {
            localStorageService.set("currentUser", {});
          }
          return data;
        });
    };

    this.getCurrentUser = function () {
      return self.currentUser || {};
    };

    this.setToken = function (token, isRemembered) {
      const date = new Date();
      if (isRemembered) {
        date.setFullYear(date.getFullYear() + 1);
      } else {
        date.setDate(date.getDate() + 1);
      }
      $cookies.put("hg_session", token, { expires: date });
      this.token = token;
    };

    this.getHeaderConfig = function () {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorizing: self.token,
        },
      };

      return config;
    };

    this.getHeaderConfigWithAccept = function () {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorizing: self.token,
          Accept: 'application/json'
        },
      };

      return config;
    };
  },
]);
