angular
  .module('app')
  .component('educationTeachingComponent', {
    templateUrl: 'app/components/education-teaching/education-teaching.tmpl.html',
    controller: function (
      $location,
      anchorSmoothScroll,
      localStorageService,
      educationService
    ) {
      const vm = this;
      vm.pageData = {};
  
      vm.items = [];
      vm.flag = true;
      let count = 1;
      const numberOfElements = 12;
      
      vm.init = function () {
        educationService.getAllTeachingMaterial().then(function (res) {
          if (res && res.data) {
            vm.pageData = res.data.collections;
            vm.select();
          }
        });
      };

      vm.sortItems = function () {
        vm.filterData.forEach(function (elem, index) {
          if (index > numberOfElements * count - 1) {
            elem.style = "display: none";
            vm.flag = false;
          } else {
            elem.style = "";
            vm.flag = true;
          }
          vm.items.push(elem);
        });
      };

      vm.more = function () {
        vm.items = [];
        count++;
        vm.sortItems();
      };

      vm.select = function (obj) {
        vm.filterData = angular.copy(vm.pageData);
        vm.items = [];
        count = 1;
        vm.sortItems();
      };

      vm.gotoElement = function (eID) {
        $location.hash("prefooter");
        anchorSmoothScroll.scrollTo(eID);
        $location.hash("");
      };
      vm.getUser = function () {
        return localStorageService.get("currentUser").id === undefined;
      };
      
      vm.allPosts = function() {
        vm.init();
      };
    }
  });
