angular
  .module('app')
  .component('subscriptionSuccessComponent', {
    templateUrl: 'app/components/subscription-success/subscription-success.tmpl.html',
    controller: function ($state) {
        const vm = this;
        vm.goToLanding = function () {
            $state.go("/");
          };
    }
  });