angular.module("app").directive("schoolModalDetail", () => {
  let link = (scope) => {

    scope.closeClickHandler = () => {
      scope.closefunction();
    };

    scope.getSelectedSchool = (school) => {
      scope.getSchool(school);
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/components/internships/schoolModalView.html",
    link: link,
    scope: {
      onClose: "&",
      closefunction: "=",
      schoolData: "=",
      getSchool: "=",
    },
  };
});
