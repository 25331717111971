angular.module("app").directive("topFiveLogoColorPieChart", function () {
  function link(scope) {
    scope.$watch("selectedItem", bindData);

    function bindData() {
      const element = document.getElementById(`top_five_logo_color_${scope.regionId}_dataviz`);
      if (element) {
        element.innerHTML = "";
      }

      const width = 350;
      const height = 200;
      const margin = 0;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      const svg = d3
        .select(`#top_five_logo_color_${scope.regionId}_dataviz`)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      // Create dummy data
      const percentageData = {};
      const colorData = {};
      for (const item of scope.selectedItem.colors) { 
        colorData[item.name] = item.color;
        if(typeof(item.percentage) == "string" && item.percentage.indexOf('%') > -1) {
          percentageData[item.name] = item.percentage.replace('%','');
        } else {
          percentageData[item.name] = item.percentage;
        }
      }

      // Compute the position of each group on the pie:
      const pie = d3.layout
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value;
        });
      const data_ready = pie(d3.entries(percentageData));
      

      // The arc generator
      const arc = d3.svg
       .arc()
       .innerRadius(radius * 0.7) // This is the size of the donut hole
       .outerRadius(radius * 0.8);

     const arc2 = d3.svg
       .arc()
       .innerRadius(radius * 0.7)
       .outerRadius(radius * 0.9);

     // Another arc that won't be drawn. Just for labels positioning
     const outerArc = d3.svg
       .arc()
       .innerRadius(radius * 0.9)
       .outerRadius(radius * 0.9);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", function (d) {
          return colorData[d.data.key].hex;
        })
        .attr(
          "stroke", function (d) {
            return colorData[d.data.key].hex === "#ffffff"
              ? "#E0E0DD"
              : colorData[d.data.key].hex;
          }
        )
        .style(
          "stroke-width",  function (d) {
            return colorData[d.data.key].hex === "#ffffff"
              ? "1px"
              : "0px"
          }
        );

      svg
        .append("text")
        .text(scope.selectedItem.percentage)
        .attr("transform", "translate(0, -30)")
        .attr("font-size", "20px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B; font-weight: 600;");

        svg
        .append("text")
        .text("UNICORNS")
        .attr("transform", "translate(0, 5)")
        .attr("font-size", "18px")
        .attr("font-weight", "500")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase;")
        .attr("text-anchor", "middle")

      svg
        .append("image")
        .attr("href", scope.selectedItem.logo)
        .attr("width", 40)
        .attr("height", 40)
        .attr("transform", "translate(-20, 20)");

      // Add the polylines between chart and labels:
      svg
        .selectAll("allPolylines")
        .data(data_ready)
        .enter()
        .append("polyline")
        .attr("stroke", "black")
        .style("fill", "none")
        .attr("stroke-width", 1)
        .attr("points", function (d) {
          const posA = arc2.centroid(d); // line insertion in the slice
          const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
          const posC = outerArc.centroid(d); // Label position = almost the same as posB
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
          posC[0] = radius * 1 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left

          return [posA, posB, posC];
        });

      svg
        .selectAll("allLabels")
        .data(data_ready)
        .enter()
        .append("text")
        .text((d) => `${d.value}%`)
        .attr("transform", function (d) {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 1.25 * (midangle < Math.PI ? 1 : -1);
          pos[1] += 5;
          return "translate(" + pos + ")";
        })
        .attr("font-size", "18px")
        .attr("font-weight", "500")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B")
        .style("text-anchor", function (d) {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midangle < Math.PI ? "start" : "end";
        });      
    }
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/customInfographicsUnicornDirectives/topFiveLogoColorPieChart/topFiveLogoColorPieChartView.html",
    link: link,
    scope: {
      selectedItem: "=",
      regionId: "=",
    },
  };
});
