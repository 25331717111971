angular.module("app").directive("wordList", () => {
  let link = (scope) => {
    scope.showImageDetails = false;
    scope.toggleImageDetails = (index) => {
      scope.showImageDetails = !scope.showImageDetails;
      let data = {
        index: index,
        data: scope.wordArray,
      };
      scope.wordArrayData = data
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/wordList/wordListView.html",
    link: link,
    scope: {
      wordArray: "=",
      selectedCharacter: "=",
    }
  };
});
