angular.module('app').directive('homeproductscontent', ['$timeout', (timeout) => {
  let link = (scope, element) => {
    scope.showRoomPopup = false;
    scope.selectedRoom = {
      name: '',
      link: ''
    };

    scope.itemHeight = () => {
      const elements = element.find(".home-img");
      if (!elements.length || elements[0].clientWidth <= 0) {
        return;
      }
      return (scope.elemHeight =
          elements[0].clientWidth && elements[0].clientWidth + "px");
    };

    scope.itemHeight();

    $(window).resize(() => {
      const elements = element.find(".home-img");
      if (!elements.length || elements[0].clientWidth <= 0) {
        return;
      }
      timeout(() => {
        return (scope.elemHeight = elements[0].clientWidth + "px");
      }, 10);
    });

    scope.toggleRoomPopup = (needShow, room, index) => {
      if (needShow === null)
        return scope.showRoomPopup = !scope.showRoomPopup;
      if (room)
        scope.selectedRoom = room;
      scope.showRoomPopup = needShow;
      scope.index = index;
    };

    scope.$on("mood_state", (data) => {
      scope.saveImageDetailsData = data.targetScope.data;
      scope.toggleRoomPopup(null, '', '');;
      scope.toggleSaveImageDetails();
    });

    scope.toggleSaveImageDetails = () => {
      scope.saveImageDetails = scope.saveImageDetails ? false : true;
    };

    scope.$on("mood_state_changed", () => {
      scope.toggleSaveImageDetails();
    });
    // scope.$on("mood_state_changed", () => {
    //   scope.toggleRoomPopup(null, '', '');
    // });
  };

  return {
    restrict: 'E',
    templateUrl: 'app/directives/homeProductsContent/homeProductsContentView.html',
    link: link,
    scope: {
      homeList: '='
    }
  };
}]);
