angular.module("app").directive("studiesContainingTheColor", (researchIndexService) => {
  let link = (scope) => {
    scope.showImageDetails = false;

    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (index, item) => {
      scope.index = index;
      scope.research_detail = ''
      researchIndexService.getColorChartData4DetailsById(item.id)
        .then(data => {   
          scope.research_detail = data.research_detail;
          scope.toggleImageDetails();
        });
    };

    scope.loadMore = () => {
      scope.loadMoreData();
    };

    scope.sorting = (colName) => {
      scope.sortColumn(colName);
    }

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbStudiesContainingTheColor/dbStudiesContainingTheColorView.html",
    link: link,
    scope: {
      data: "=",
      selectedColor: "=",
      mode: "=",
      isLoadMoreData: "=",
      loadMoreData: "=",
      sortColumn: "=",
      modalTitle: "=",
    }
  };
});
