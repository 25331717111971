angular.module("app").directive("colorFrequencyHomeCategory", () => {
  let link = (scope) => {
    scope.$watch('data', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.data = newValue;
      }
    }, true);
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorFrequencyHomeCategory/dbColorFrequencyHomeCategoryView.html",
    link: link,
    scope: {
      data: "="
    }
  };
});
