angular.module('app').directive('loadingIcon', () => {
  let link = () => {
  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/loading-icon/loadingIconView.html',
    link: link
  };
});
