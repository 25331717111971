angular.module("app").directive("globalSourceAndSentiment", () => {
  let link = (scope) => {
    // default selection -> this will be replaced by selecting other sources
    scope.selectedSource = ['instagram', 'twitter'];
    scope.isSelectSource = (source) => {
      if (scope.selectedSource.indexOf(source) !== -1) {
        return true;
      }

      return false;
    };
    scope.sourceSelect = (source) => {
      if (!scope.isSelectSource(source)) {
        scope.selectedSource.push(source);
      }
      else {
        scope.selectedSource = scope.selectedSource.filter(item => item !== source);
      }

      scope.setSources(scope.selectedSource.join(','));
    };

    scope.selectedSentiments = ['positive', 'negative'];
    scope.isSelectSentiment = (sentiment) => {
      if (scope.selectedSentiments.indexOf(sentiment) !== -1) {
        return true;
      }

      return false;
    };
    scope.sentimentSelect = (sentiment) => {
      if (!scope.isSelectSentiment(sentiment)) {
        scope.selectedSentiments.push(sentiment);
      }
      else {
        scope.selectedSentiments = scope.selectedSentiments.filter(item => item !== sentiment);
      }

      scope.setSentiments(scope.selectedSentiments.join(','));
    };

    scope.sourceList = [
      {
        id: 0,
        title: "twitter",
        img_url: "/assets/img/global/twitter-icon.svg",
      },
      {
        id: 1,
        title: "instagram",
        img_url: "/assets/img/global/instagram-round.png",
      },
      // {
      //   id: 2,
      //   title: "news",
      //   img_url: "/assets/img/global/news-black.png",
      // }
    ];
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbGlobalColorData/globalColorTracker/globalSourceAndSentiment/globalSourceAndSentimentView.html",
    link: link,
    scope: {
      isAreaView: "=",
      setSentiments: "=",
      setSources: "=",
    }
  };
});
