angular
  .module("app")
  .directive(
    "brandingcontentdetail",
    ($timeout, searchMenuRepository, brandingDashboardRepository, moodboardService) => {
      let link = (scope) => {
        scope.allData = {
          data: scope.logodata,
          index: scope.index,
        };

        scope.data = scope.logodata[scope.index];

        scope.mode =
          scope.chartdetail === "brand" ? "company" : scope.chartdetail;
        
        scope.logoColors = [];
        switch (scope.chartdetail) {
          case "industry":
            brandingDashboardRepository.color
              .getRGBLogoColors(scope.data.id)
              .then((data) => {
                scope.logoColors = data ? data : [];
              });
            break;
          case "color":
            brandingDashboardRepository.color
              .getRGBLogoColors(scope.data.id)
              .then((data) => {
                scope.logoColors = data ? data : [];
              });
            break;
          case "attribute":
            brandingDashboardRepository.color
              .getRGBLogoColors(scope.data.id)
              .then((data) => {
                scope.logoColors = data ? data : [];
              });
            break;
          case "brand":
            brandingDashboardRepository.color
              .getRGBLogoColors(scope.data.id)
              .then((data) => {
                scope.logoColors = data ? data : [];
              });
            break;
        }

        scope.LeftButtonHandler = () => {
          let index = scope.allData.index;
          if (index > 0) {
            index--;
          }
          scope.allData.index = index;
          scope.data = scope.allData.data[index];
          scope.logoColors = [];
          switch (scope.chartdetail) {
            case "industry":
              brandingDashboardRepository.color
                .getRGBLogoColors(scope.data.id)
                .then((data) => {
                  scope.logoColors = data ? data : [];
                });
              break;
            case "color":
              brandingDashboardRepository.color
                .getRGBLogoColors(scope.data.id)
                .then((data) => {
                  scope.logoColors = data ? data : [];
                });
              break;
            case "attribute":
              brandingDashboardRepository.color
                .getRGBLogoColors(scope.data.id)
                .then((data) => {
                  scope.logoColors = data ? data : [];
                });
              break;
            case "brand":
              brandingDashboardRepository.color
                .getRGBLogoColors(scope.data.id)
                .then((data) => {
                  scope.logoColors = data ? data : [];
                });
              break;
          }
        };

        scope.RightButtonHandler = () => {
          try {
            let index = scope.allData.index;
            if (index < scope.allData.data.length - 1) {
              index++;
            }
            scope.allData.index = index;
            scope.data = scope.allData.data[index];
            scope.logoColors = [];
            switch (scope.chartdetail) {
              case "industry":
                brandingDashboardRepository.color
                  .getRGBLogoColors(scope.data.id)
                  .then((data) => {
                    scope.logoColors = data ? data : [];
                  });
                break;
              case "color":
                brandingDashboardRepository.color
                  .getRGBLogoColors(scope.data.id)
                  .then((data) => {
                    scope.logoColors = data ? data : [];
                  });
                break;
              case "attribute":
                brandingDashboardRepository.color
                  .getRGBLogoColors(scope.data.id)
                  .then((data) => {
                    scope.logoColors = data ? data : [];
                  });
                break;
              case "brand":
                brandingDashboardRepository.color
                  .getRGBLogoColors(scope.data.id)
                  .then((data) => {
                    scope.logoColors = data ? data : [];
                  });
                break;
            }
          } catch (e) {}
        };

        scope.newmoodboard = (data) => {
          data.type = 'brand';
          scope.$emit("mood_state", data);
        };
        
        scope.closeClickHandler = () => {
          scope.closefunction();
        };

        scope.shareFacebook = (url) => {
          window.open("https://www.facebook.com/sharer/sharer.php?u=" + url);
        };

        scope.shareTwitter = (url) => {
          window.open("https://twitter.com/home?status=" + url);
        };

        scope.sharePinterest = (url) => {
          window.open("https://pinterest.com/pin/create/button/?url=" + url);
        };

        scope.shareGooglePlus = (url) => {
          window.open("https://plus.google.com/share?url=" + url);
        };

        scope.shareInstagram = (url) => {
          window.open("https://instagram.com/?url=" + url);
        };
      };

      return {
        restrict: "E",
        templateUrl:
          "app/directives/dbBrandingContent/dbBrandingContentDetailsView.html",
        link: link,
        scope: {
          logodata: "=",
          index: "=",
          onClose: "&",
          closefunction: "=",
          chartdetail: "=",
          titledetail: "=",
          searchId: "=",
        },
      };
    }
  );
