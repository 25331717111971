(function () {
  'use strict';
  angular.module('app')
    .directive('hueTop4Grid', ['common', 'config', 'chartsHelper', '$timeout',
      function (common, config, chartsHelper, $timeout) {
        function link(scope) {
          scope.$watch("data", (newValue) => {
            if (newValue) {
              bindData();
            }
          });
          scope.alphabet = [
            "a",
            "à",
            "b",
            "c",
            "d",
            "e",
            "é",
            "f",
            "g",
            "h",
            "i",
            "j",
            "k",
            "l",
            "m",
            "n",
            "o",
            "ö",
            "p",
            "q",
            "r",
            "s",
            "t",
            "u",
            "v",
            "w",
            "x",
            "y",
            "z",
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
          ];

          function bindData() {
            if (!scope.data) {
              return;
            }

            scope.grouppedData = _.groupBy(scope.data, function (item) {
              return item.name && item.name.toLowerCase().substr(0, 1);
            });
          }
        }

        const directive = {
          link: link,
          restrict: 'E',
          replace: true,
          scope: {
            data: '='
          },
          templateUrl: 'app/directives/custom-infographics-directives/top4-grid.html'
        };

        return directive;
      }
    ]);
}());
