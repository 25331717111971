angular.module("app").directive("cityOverallUseOfColorAcrossCitiesChart", function () {
  function link(scope) {
    scope.$watch("data", bindData);

    function bindData() {
      scope.data.colors.sort((a, b) => b.percentage.replace('%', '') - a.percentage.replace('%', ''));
      scope.colorSelections = scope.data.colors.slice(0, 4);
      scope.data.colors = scope.data.colors.slice(0, 4);
      const element = document.getElementById(`overall-top-four-colors-chart_dataviz`);
      if (element) {
        element.innerHTML = "";
      }

      const output = [scope.data.svg.slice(0, 4), ` width='100' height='134'`, scope.data.svg.slice(4)].join('');

      const width = 400;
      const height = 400;
      const margin = 0;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      const svg = d3
        .select(`#overall-top-four-colors-chart_dataviz`)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      // Create chart data
      const percentageData = {};
      const colorData = {};
      for (const item of scope.data.colors) { 
        colorData[item.name] = item.color; 
        percentageData[item.name] = item.percentage.replace('%',''); 
      }

      // Compute the position of each group on the pie:
      const pie = d3.layout
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value;
        });
      const data_ready = pie(d3.entries(percentageData));

      // The arc generator
      const arc = d3.svg
        .arc()
        .innerRadius(radius * 0.7) // This is the size of the donut hole
        .outerRadius(radius * 0.8);

      const arc2 = d3.svg
        .arc()
        .innerRadius(radius * 0.7)
        .outerRadius(radius * 0.9);

      // Another arc that won't be drawn. Just for labels positioning
      const outerArc = d3.svg
        .arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

      var tooltip = d3.select("body")
        .append("div")
        .style("position", "absolute")
        .style("z-index", "10")
        .style("visibility", "hidden")
        .style("background", "#f7f7f7")
        .style("text-transform", "uppercase")
        .style("padding", "10px")
        .style("color", "#000000")
        .text("a simple tooltip");

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", function (d) {
          return colorData[d.data.key].hex;
        })
        .attr(
          "stroke", function (d) {
            return colorData[d.data.key].hex === "#ffffff" || colorData[d.data.key].hex === "#FFFFFF"
              ? "#E0E0DD"
              : colorData[d.data.key].hex;
          }
        )
        .style(
          "stroke-width",  function (d) {
            return colorData[d.data.key].hex === "#ffffff" || colorData[d.data.key].hex === "#FFFFFF"
              ? "0.5px"
              : "0px"
          }
        )
        .on("mouseover", function(d){tooltip.text(d.data.key + ' ' + d.value + '%'); return tooltip.style("visibility", "visible");})
        .on("mousemove", function(){return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX+10)+"px");})
        .on("mouseout", function(){return tooltip.style("visibility", "hidden");});


      svg
        .append("text")
        .text(scope.data.name)
        .attr("transform", "translate(0, 20)")
        .attr("font-size", "18px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase; font-weight: normal");

      svg
        .append("text")
        .text(scope.data.designerCount)
        .attr("font-size", "16px")
        .attr("transform", "translate(-50, 67)")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase; font-weight: normal");

      svg
        .append("text")
        .text(scope.data.colorCount)
        .attr("font-size", "16px")
        .attr("transform", "translate(50, 67)")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase; font-weight: normal");

      svg
        .append("text")
        .text("COLORS")
        .attr("font-size", "12px")
        .attr("transform", "translate(50, 80)")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase; font-weight: normal");

      svg
        .append("text")
        .text("DESIGNERS")
        .attr("font-size", "12px")
        .attr("transform", "translate(-50, 80)")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase; font-weight: normal");

      svg
        .append("g")
        .attr("transform", "translate(-50, -110)")
        .append("svg")
        .html(output)
        .attr("height", 134)
        .attr("text-anchor", "middle")
    }
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/dbCityOverallUseOfColorAcrossCitiesChart/dbCityOverallUseOfColorAcrossCitiesChartView.html",
    link: link,
    scope: {
      data: "=",
    },
  };
});
