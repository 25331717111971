angular.module("app").directive("globalDailyColor", [
  "trackDashboardRepository",
  "dateTimeFormatter",
  "localStorageService", "modalService",
  (trackDashboardRepository, dateTimeFormatter, localStorageService, modalService) => {
    let link = (scope) => {
      scope.ajaxInProgress = false;
      scope.headerData = {};
      scope.socials = [
        {
          id: 0,
          title: "twitter",
          img_url: "assets/img/global/twitter-icon.svg",
        },
        {
          id: 1,
          title: "instagram",
          img_url: "assets/img/global/instagram-round.png",
        },
        // {
        //   id: 2,
        //   title: "news",
        //   img_url: "/assets/img/global/news-black.png",
        // }
      ];

      if(localStorageService.get("currentUser")){
        scope.user = localStorageService.get("currentUser");
      }

      scope.sources = 'instagram,twitter';
      scope.selectedSocials = ['instagram', 'twitter'];
      scope.isSelectedSocial = (social) => {
        if (scope.selectedSocials.indexOf(social) !== -1)
          return true;

        return false;
      };
      scope.selectSocial = (social) => {
        if (!scope.isSelectedSocial(social)) {
          scope.selectedSocials.push(social);
        } else {
          scope.selectedSocials = scope.selectedSocials.filter(item => item !== social);
        }

        scope.sources = scope.selectedSocials.join(',');
        scope.loadGraphics();
      };

      scope.sentiments = 'positive,negative';
      scope.selectedSentiments = ['positive', 'negative'];
      scope.isSelectSentiment = (sentiment) => {
        if (scope.selectedSentiments.indexOf(sentiment) !== -1) {
          return true;
        }

        return false;
      };
      scope.sentimentSelect = (sentiment) => {
        if (!scope.isSelectSentiment(sentiment)) {
          scope.selectedSentiments.push(sentiment);
        }
        else {
          scope.selectedSentiments = scope.selectedSentiments.filter(item => item !== sentiment);
        }

        scope.sentiments = (scope.selectedSentiments.join(','));
        scope.loadGraphics();
      };

      scope.colorSentimentMonthData = {};
      scope.colorSentimentOverallData = {};

      scope.monthData = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
      ];

      const now = new Date();
      scope.year = now.getFullYear();
      scope.month = now.getMonth();
      scope.day = now.getDate();

      scope.selectedFormattedDate = `${scope.monthData[scope.month]} ${scope.year}`;

      scope.$watch('selectedDate', (date) => {
        if (!date) return;
        scope.selectedFormattedDate = dateTimeFormatter.formatDateForMonthAndYear(date);
        scope.year = date.getFullYear();
        scope.month = date.getMonth();
      });

      scope.nextMonth = () => {
        if (scope.month === 11) {
          scope.year += 1;
        }
        scope.month = (scope.month + 1) % 12;
        scope.selectedFormattedDate = `${scope.monthData[scope.month]} ${scope.year}`;
        scope.loadGraphics();
      };

      scope.prevMonth = () => {
        if (scope.month === 0) {
          scope.year -= 1;
        }
        scope.month = (scope.month + 11) % 12;
        scope.selectedFormattedDate = `${scope.monthData[scope.month]} ${scope.year}`;
        scope.loadGraphics();
      };

      scope.loadGraphics = () => {
        scope.ajaxInProgress = true;
        trackDashboardRepository
          .getDailyViewTrackerData(
            scope.getFormattedDate(),
            scope.sources,
            scope.sentiments
          )
          .then(data => {
            scope.ajaxInProgress = false;
            scope.colorSentimentMonthData = data.colorSentimentMonthData;
            scope.colorSentimentMonthData.date = scope.selectedFormattedDate;
            scope.colorSentimentOverallData = data.colorSentimentOverallData;
            scope.sentimetnData = data.sentimentMonthColorData;
          });

        // new header API bind
        trackDashboardRepository
          .getGlobalPlusHeaderData(
            scope.getFormattedDate(),
            scope.sources,
            scope.sentiments
          )
          .then(data => {
            scope.headerData = data;
          });
      };

      scope.getFormattedDate = () => {
        const month = scope.month + 1;
        const monthToString = month < 10 ? '0' + month : month;
        const dayToString = scope.day < 10 ? '0' + scope.day : scope.day;

        return `${scope.year}-${monthToString}-${dayToString}`;
      };

      scope.loadGraphics();
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbGlobalColorData/globalDailyColor/globalDailyColorView.html",
      link: link,
      scope: {
      }
    };
  }
]);
