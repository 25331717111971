const app = angular.module("app", [
  "ngResource",
  "ngCookies",
  "ngSanitize",
  "countTo",
  "angular-extend-promises",
  "ui.router",
  "ui.select",
  "ui.bootstrap",
  "LocalStorageModule",
  "ngFileUpload",
  "updateMeta",
  "vcRecaptcha",
  "ui.sortable",
  "usMap"
]);

const config = {
  routeUrl: "",
};

app.value("config", config);
const run = [
  "localStorageService",
  "authService",
  "$state",
  "$transitions",
  "modalService",
  "$anchorScroll",
  "$rootScope",
  function (
    localStorageService,
    authService,
    $state,
    $transitions,
    modalService,
    $anchorScroll,
    $rootScope
  ) {
    if (!localStorageService.get("currentUser")) {
      localStorageService.set("currentUser", {});
    }
    if (
      ($state.$current.self.protected &&
        !localStorageService.get("currentUser").is_client) ||
      ($state.$current.self.onlyAdmin &&
        !localStorageService.get("currentUser").approved)
    ) {
      $state.go("landing-page");
    }
    $transitions.onStart({}, function (transition) {
      if (
        transition.to().protected &&
        !localStorageService.get("currentUser").is_client
      ) {
        modalService.showModal(1);
        return false;
      } else if (
        transition.to().onlyAdmin &&
        !localStorageService.get("currentUser").approved
      ) {
        $state.go("landing-page");
        return false;
      } else if (
        transition.to().protected && transition.to().url === "/color-customized-infographics?tab" &&
        !(localStorageService.get("currentUser").plan_access.access ? localStorageService.get("currentUser").plan_access.access.custom_analytics : true)
      ) {
        modalService.showModal(12, null, localStorageService.get("currentUser").first_name);
        return false;
      }
    });

    $anchorScroll.yOffset = 85;

    $rootScope.$on("$viewContentLoaded", function () {
      angular.element("html, body").animate({ scrollTop: 0 }, 200);
    });
  },
];

app.run(run);

angular.module("app").constant("appConfig", {
  appName: "huefashion",
  webServiceUrl: "https://huedata-fashion.herokuapp.com/api/",
  autoServiceUrl: "https://huedata-auto.herokuapp.com/api/",
  brandingServiceUrl: "https://huebrand.herokuapp.com/api/",
  brandingServiceUrlApi: "https://huedata-site.herokuapp.com/api/brands/",
  brandingServiceUrlNewApi: "https://huedata-brand.herokuapp.com/api/",
  colorsServiceUrlNewApi: "https://huedata-site.herokuapp.com/api/fashion/",
  legalServiceUrl: "https://huelegal.herokuapp.com/api/",
  authServiceUrl: "",
  dashboardServiceUrl: "https://huedata-site.herokuapp.com/",
  colorNamingIndex: "https://huedata-site.herokuapp.com/api/color_names/",

  repositories: {
    mainParams: [
      "region_id",
      "designer_id",
      "category_id",
      "season_id",
      "year",
      "city_id",
    ],
  },

  socialLinks: {
    FACEBOOK_LINK: 'https://www.facebook.com/Huedata',
    INSTEGRAM_LINK: 'https://www.instagram.com/huedata_inc/',
    TWITTER_LINK: 'https://twitter.com/home',
    LINKEDID_LINK: 'https://www.linkedin.com/company/huedata',
    PINTEREST_LINK: 'https://www.pinterest.com/huedata1/_saved'
  },
});
