angular.module("app").component("contactUsComponent", {
  templateUrl: "app/components/contact-us/contact-us.tmpl.html",
  controller: function ($state, $http, appConfig, dataValidate) {
    this.data = {
      first_name: {
        value: "",
        required: true,
        name: "first name",
        type: "provide",
      },
      last_name: {
        value: "",
        required: true,
        name: "last name",
        type: "provide",
      },
      title: { value: "", required: true, name: "title", type: "" },
      company: {
        value: "",
        required: true,
        name: "company name",
        type: "provide",
      },
      phone: { value: "", name: "phone number", type: "enter" },
      email: {
        value: "",
        required: true,
        name: "company email",
        type: "provide",
      },
      body: { value: "", required: true, name: "comments", type: "enter" },
    };

    this.contactUs = function () {
      if (dataValidate.validate(this.data)) {
        const data = {};
        for (const item in this.data) {
          data[item] = this.data[item].value;
        }
        $http
          .post(appConfig.dashboardServiceUrl + "api/forms/contact_us.json", {
            contact: data,
          })
          .then(function (res) {
            if (res.status === 201) {
              $state.go("thank-you");
            }
          });
      }
    };
  },
});
