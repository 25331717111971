angular.module("app").directive("sportContent", [
  "$timeout",
  "$window",
  (timeout, window) => {
    let link = (scope, element) => {
      scope.menu = [
        { id: 1, title: "ALL" },
        { id: 2, title: "BASEBALL" },
        { id: 3, title: "BASKETBALL" },
        { id: 4, title: "FOOTBALL" },
        { id: 5, title: "HOCKEY" },
        { id: 6, title: "SOCCER" },
        { id: 7, title: "LACROSSE" },
        { id: 8, title: "RUGBY" },
      ];

      scope.selectedMenuId = 1;
      scope.isSetMenu = function (id) {
        return scope.selectedMenuId === id;
      };

      scope.setMenuId = function (id) {
        scope.selectedMenuId = id;
      };

      scope.showCollection = false;
      scope.showImageDetails = false;
      scope.imageDetailsData = null;
      window.scr = scope;
      scope.collectionDataList = [];

      scope.itemHeight = () => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        return (scope.elemHeight =
          elements[0].clientWidth && elements[0].clientWidth + "px");
      };

      scope.itemHeight();

      $(window).resize(() => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        timeout(() => {
          return (scope.elemHeight = elements[0].clientWidth + "px");
        }, 10);
      });

      scope.toggleImageDetails = () => {
        scope.showImageDetails = !scope.showImageDetails;
      };

      scope.imageClickHandler = (index) => {
        scope.imagedata = scope.sportContentData[index];
        scope.index = index;
        scope.toggleImageDetails();
      };

      scope.isModeColor = () => {
        return scope.viewMode === "color";
      };

      scope.isModeArtist = () => {
        return scope.viewMode === "artist";
      };

      scope.isModePeriod = () => {
        return scope.viewMode === "period";
      };

      scope.isModeSchool = () => {
        return scope.viewMode === "school";
      };
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbSportContent/dbSportContentView.html",
      link: link,
      scope: {
        sportContentData: "=",
        selectedItem: "=",
        viewMode: "=",
      },
    };
  },
]);
