angular.module("app").directive("roomproductspopup", (moodboardService) => {
  let link = (scope) => {
    scope.allData = {
      data: scope.homeList,
      index: scope.index,
    };

    scope.data = scope.allData.data[scope.allData.index];

    scope.LeftButton = () => {
      let index = scope.allData.index;
      if (index > 0) {
        index--;
      }
      scope.allData.index = index;
      scope.data = scope.allData.data[index];
    };

    scope.RightButton = () => {
      try {
        let index = scope.allData.index;
        if (index < scope.allData.data.length - 1) {
          index++;
        }
        scope.allData.index = index;
        scope.data = scope.allData.data[index];
      } catch (e) {}
    };

    scope.closeClickHandler = () => {
      scope.toggleFunction(false);
    };

    scope.newmoodboard = (data) => {
      data.type = 'home';
      scope.$emit("mood_state", data);
    };

    scope.shareFacebook = (url) => {
      window.open("https://www.facebook.com/sharer/sharer.php?u=" + url);
    };

    scope.shareTwitter = (url) => {
      window.open("https://twitter.com/home?status=" + url);
    };

    scope.sharePinterest = (url) => {
      window.open("https://pinterest.com/pin/create/button/?url=" + url);
    };

    scope.shareGooglePlus = (url) => {
      window.open("https://plus.google.com/share?url=" + url);
    };
    
    scope.shareInstagram = (url) => {
      window.open("https://instagram.com/?url=" + url);
    };
  };
  return {
    restrict: "E",
    templateUrl: "app/directives/roomProductsPopup/roomProductsPopupView.html",
    link: link,
    scope: {
      room: "=",
      toggleFunction: "=",
      homeList: "=",
      index: "=",
    },
  };
});
