angular.module("app").directive("readmoreAbility", function () {
  function link(scope, element) {
    scope.difference = 0;
    scope.condition = "hidden"; // hidden | collapsed
    scope.label = "...show more";

    scope.readMoreDiv = formReadmoreDiv();
    element.append(scope.readMoreDiv);

    function formReadmoreDiv() {
      const readMoreBlock = angular.element(
        '<div class="readmore-block"></div>'
      );
      const readMoreText = angular.element(
        `<p class="readmore-text">${scope.label}</p>`
      );
      readMoreBlock.append(readMoreText);
      readMoreBlock.bind("click", function () {
        scope.toggle();
      });

      return readMoreBlock;
    }

    scope.toggle = function () {
      if (scope.condition === "hidden") {
        scope.difference = element[0].scrollHeight - element[0].clientHeight;
        element.css("height", `${element[0].scrollHeight + 30}px`);
        scope.condition = "collapsed";
        scope.label = "hide";
      } else {
        element.css(
          "height",
          `${element[0].clientHeight - scope.difference - 30}px`
        );
        scope.condition = "hidden";
        scope.label = "...show more";
      }
      scope.readMoreDiv.remove();
      scope.readMoreDiv = formReadmoreDiv();
      element.append(scope.readMoreDiv);
    };
  }
  return {
    restrict: "A",
    link: link,
  };
});
