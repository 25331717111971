angular.module('app').directive('colorattributeassociations', function () {
  function link(scope, element, attrs) {
    scope.associations = [
      'APPETISING', 'ORGANIC', 'SIMULATING', 'HEARTFELT', 'LOVING',
      'APPEALING', 'DOMINANT', 'ORIENTED', 'LIVELY', 'SOCIAL',
      'CORPORAL', 'EXCITING', 'ACTIVATING', 'INTENSIVE', 'ARTIFICIAL'
    ];
  }
  return {
    restrict: 'E',
    templateUrl: 'app/directives/colorAttributeAssociations/colorAttributeAssociations.html',
    link: link,
    scope: {
      color:'='
    }
  };
});
