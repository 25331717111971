angular.module("app").directive("colorFlowerFamilyComparison", () => {
  let link = (scope) => {
    scope.dotsPerLine = [1, 2, 3, 4, 5];

    scope.getDotLines = percentage => {
      const dotLines = [];
      const rowCnt = Math.ceil(percentage) / 2;
      for (let i = 0; i < rowCnt; i++) {
        dotLines.push(i);
      }
      return dotLines;
    };

    scope.$watch('selectedColor', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.selectedColor = newValue;
      }
    }, true);

    scope.$watch('colorData', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.colorData = newValue;
      }
    }, true);
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorFlowerFamilyComparison/dbColorFlowerFamilyComparisonView.html",
    link: link,
    scope: {
      colorData: "=",
      selectedColor: "=",
      mode: "=",
    }
  };
});
