angular
  .module('app')
  .service('reportService', ['httpService', '$http', 'appConfig', 'authService', function (httpService, $http, appConfig, authService) {

    this.getReportForDashboard = function () {
      return $http({
        url: appConfig.dashboardServiceUrl + 'api/reports.json',
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    }
    
    this.getReportDetails = function (id) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/reports/${id}.json`,
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };

    this.getAllReport = function (date, topic) {
      return $http({
        url: appConfig.dashboardServiceUrl + 'api/reports.json?q[date]=' + date + '&q[topic_eq]=' + topic,
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };
  }]);