angular.module('app').directive('pharmaCompanyComplete', [
  () => {
    let link = (scope) => {
      scope.filteredChoices = [];
      scope.isVisible = {
        suggestions: false,
      };

      // dummy data
      scope.categories = [
        "1-9","A-C", "D-F", "G-I", "J-L", "M-O", "P-R", "S-U", "V-Z"
      ];  

      scope.categoriesData = [
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        ['a', 'b', 'c'],
        ['d', 'e', 'f'],
        ['g', 'h', 'i'],
        ['j', 'k', 'l'],
        ['m', 'n', 'o'],
        ['p', 'q', 'r'],
        ['s', 't', 'u'],
        ['v', 'w', 'x', 'y', 'z']
      ];

      scope.selectedCategoryIndex = 0;
      scope.categorySelect = index => { 
        scope.selectedCategoryIndex = index;
        scope.enteredtext = scope.categories[index].toLowerCase().substr(0, 1);
        scope.searchTitle();
      };

      scope.searchTitle = () => {
        if (scope.enteredtext.length < 0) {
          return;
        }
        scope.searchhandler('pharmaceuticalCompany', scope.enteredtext, 1);
      };

      scope.filterItemsByCategory = () => {
        if (!scope.enteredtext.length) {
          scope.isVisible.suggestions = false;
          return;
        }
        scope.isVisible.suggestions = true;

        scope.selectedCategoryIndex = scope.categoriesData.findIndex(cat => cat.includes(scope.enteredtext.toLowerCase().substr(0, 1)));
      };

      scope.filterItems = () => {  
        if (!scope.enteredtext.length) {
          scope.isVisible.suggestions = false;
          return;
        }
        scope.isVisible.suggestions = true;
        scope.selectedCategoryIndex = scope.categoriesData.findIndex(cat => cat.includes(scope.enteredtext.substr(0, 1))); 
        scope.filterItemsByCategory();
      }; 

      scope.selectItem = (choice) => { 
        scope.selected = choice;
        scope.enteredtext = choice;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };

      scope.$watch('models', function (newValue, oldValue) {
        if (newValue) {
          scope.models = newValue;
          scope.filteredChoices = scope.models;
          scope.filterItems();
        }
      }, true);

    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbPharmaCompanyComplete/dbPharmaCompanyCompleteView.html",
      link: link,
      scope: {
        enteredtext: "=",
        selected: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        disabled: "=",
        disable: "=",
        board: "=",
        models:"=",
        searchhandler: "=",
      }
    };
  }
]);
