angular.module('app').directive('fragranceAccordComplete', [
  () => {
    let link = (scope) => {
      scope.filteredChoices = [];
      scope.isVisible = {
        suggestions: false,
      };

      scope.filterItems = () => {
        if (scope.enteredtext.length < 0) return;
        scope.isVisible.suggestions =
          scope.enteredtext.length === 0
            ? false
            : scope.filteredChoices.length > 0;
      };

      // scope.selectedCategoryIndex = 0;
      // scope.categorySelect = index => { 
      //   scope.selectedCategoryIndex = index;
      //   scope.enteredtext = scope.categories[index].toLowerCase().substr(0, 1);
      //   scope.searchTitle();
      // };

      // scope.searchTitle = () => {
      //   if (scope.enteredtext.length < 0) {
      //     return;
      //   }
      //   scope.searchhandler('accord', scope.enteredtext, 1);
      // };

      // scope.filterItemsByCategory = () => {
      //   if (!scope.enteredtext.length) {
      //     scope.isVisible.suggestions = false;
      //     return;
      //   }
      //   scope.isVisible.suggestions = true;

      //   scope.selectedCategoryIndex = scope.categoriesData.findIndex(cat => cat.includes(scope.enteredtext.toLowerCase().substr(0, 1)));
      // };

      // scope.filterItems = () => {  
      //   if (!scope.enteredtext.length) {
      //     scope.isVisible.suggestions = false;
      //     return;
      //   }
      //   scope.isVisible.suggestions = true;
      //   scope.selectedCategoryIndex = scope.categoriesData.findIndex(cat => cat.includes(scope.enteredtext.substr(0, 1))); 
      //   scope.filterItemsByCategory();
      // }; 

      scope.selectItem = (choice) => { 
        scope.selected = choice;
        scope.enteredtext = choice;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };

      scope.$watch('models', function (newValue, oldValue) {
        if (newValue) {
          scope.models = newValue;
          scope.filteredChoices = scope.models;
        }
      }, true);

    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbFragranceAccordComplete/dbFragranceAccordCompleteView.html",
      link: link,
      scope: {
        enteredtext: "=",
        selected: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        disabled: "=",
        disable: "=",
        board: "=",
        models:"=",
        searchhandler: "=",
      }
    };
  }
]);
