angular.module("app").directive("autoCharts", function () {
  function link(scope) {
    scope.$watch("data", (newValue, oldValue) => {
      if(newValue) {
        bindData();
      }
    });

    function bindData() {
      if (document.getElementById("my_dataviz")) {
        document.getElementById("my_dataviz").innerHTML = "";
      }
      const containerWidth = document.getElementsByClassName(
        "middle-section__pie-chart"
      )[0].offsetWidth;
      const width = containerWidth - 20;
      const height = 340;
      const margin = 40;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      // append the svg object to the div called 'my_dataviz'
      const svg = d3
        .select("#my_dataviz")
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      const apiData = scope.data.data.result;
      scope.tempData = [];
      apiData.forEach(element => {
        if(element.percentage !== 0) {
          scope.tempData.push(element);
        }
      });
      // scope.tempData.sort(() => Math.random() - 0.5);
      let count = scope.tempData.length;
      let fixedHeight = 100 / count;
      scope.tempData.forEach(element => {
        element.height = fixedHeight;
      });
      const logos = {
        Hatchback: "assets/img/icons/auto/car_logo_3.svg",
        Pickup: "assets/img/icons/auto/car_logo_8.svg",
        Suv: "assets/img/icons/auto/car_logo_6.svg",
        Minivan: "assets/img/icons/auto/car_logo_6.svg",
        "Convertible And Coupe": "assets/img/icons/auto/car_logo_2.svg",
        "Sedan And Vagon": "assets/img/icons/auto/car_logo_1.svg",
      };

      // Compute the position of each group on the pie:
      const pie = d3.layout
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          const tempData = {key: d.value.title, value: d.value.percentage, height: d.value.height}
          return tempData.height;
        });
      const data_ready = pie(d3.entries(scope.tempData));
      data_ready.map((item) => {
        item.data.logo = logos[item.data.value.title];
      });

      // The arc generator
      const arc = d3.svg
        .arc()
        .innerRadius(radius * 0.7) // This is the size of the donut hole
        .outerRadius(radius * 0.8);

      const arc2 = d3.svg
        .arc()
        .innerRadius(radius * 0.7)
        .outerRadius(radius * 0.9);

      // Another arc that won't be drawn. Just for labels positioning
      const outerArc = d3.svg
        .arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", scope.selectedColor.hex)
        .attr(
          "stroke",
          scope.selectedColor.title === "White" ? "#E0E0DD" : "white"
        )
        .style(
          "stroke-width",
          scope.selectedColor.title === "White" ? "1px" : "5px"
        );

      // Add the polylines between chart and labels:
      svg
        .selectAll("allPolylines")
        .data(data_ready)
        .enter()
        .append("polyline")
        .attr("stroke", "black")
        .style("fill", "none")
        .attr("stroke-width", 1)
        .attr("points", function (d) {
          const posA = arc2.centroid(d); // line insertion in the slice
          const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
          const posC = outerArc.centroid(d); // Label position = almost the same as posB
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
          posC[0] = radius * 1.2 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
          return [posA, posB, posC];
        });

      // Add the polylines between chart and labels:
      svg
        .selectAll("allLabels")
        .data(data_ready)
        .enter()
        .append("text")
        .text((d) => d.data.value.title)
        .attr("transform", function (d) {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 2.4 * (midangle < Math.PI ? 1 : -1);
          return "translate(" + pos + ")";
        })
        .attr("font-size", "14px")
        .style("text-anchor", function (d) {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midangle < Math.PI ? "start" : "end";
        });

      svg
        .selectAll("allLabels")
        .data(data_ready)
        .enter()
        .append("text")
        .text((d) => `${d.data.value.percentage}%`)
        .attr("transform", function (d) {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 1.3 * (midangle < Math.PI ? 1 : -1);
          return "translate(" + pos + ")";
        })
        .attr("font-size", "18px")
        .style("text-anchor", function (d) {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midangle < Math.PI ? "start" : "end";
        });

      svg
        .selectAll("allLabels")
        .data(data_ready)
        .enter()
        .append("svg:image")
        .attr("height", 60)
        .attr("width", 60)
        .attr("xlink:href", (d) => d.data.logo)
        .attr("transform", function (d) {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          const imagePosition = 60;
          pos[0] =
            radius * 1.95 * (midangle < Math.PI ? 1 : -1) -
            (midangle < Math.PI ? 30 : 30);
          pos[1] -= 30;
          return "translate(" + pos + ")";
        });
    }

    if(scope.data.data.total_percentage !== 0){
      scope.totalPercentage = scope.data.data.total_percentage;
      bindData();
    } else {
      scope.totalPercentage = scope.data.data.total_percentage;
    }
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/pieChartAuto/pieChartAutoView.html",
    link: link,
    scope: {
      selectedColor: "=",
      data: "=",
    },
  };
});
