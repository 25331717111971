(function () {
  angular.module("app").directive("hueChartByColorUseInFlagsWorldwide", [
    function () {
      function link(scope, element) {
        chartBase(self);

        const data = {
          periods: [
            { color: "#c10000" },
            { color: "#ff6d00" },
            { color: "#c1ff28" },
            { color: "#33d8ff" },
            { color: "#a900c5" },
            { color: "#dcffcb" },
            { color: "#ffbc82" },
            { color: "#69ff5e" },
            { color: "#005ab9" },
            { color: "#e8a6ff" },
            { color: "#00c220" },
            { color: "#9282ff" },
            { color: "#aa00dd" },
            { color: "#ff979d" },
          ],
          points: [
            { value: 145, percentage: "44%" },
            { value: 140, percentage: "44%" },
            { value: 135, percentage: "44%" },
            { value: 130, percentage: "44%" },
            { value: 125, percentage: "44%" },
            { value: 120, percentage: "44%" },
            { value: 115, percentage: "44%" },
            { value: 90, percentage: "44%" },
            { value: 70, percentage: "44%" },
            { value: 60, percentage: "44%" },
            { value: 50, percentage: "44%" },
            { value: 40, percentage: "44%" },
            { value: 30, percentage: "44%" },
            { value: 25, percentage: "44%" },
          ],
          flags: [{ title: 0 }, { title: 50 }, { title: 100 }, { title: 150 }],
          percent: [{ title: 25 }, { title: 50 }, { title: 75 }],
        };

        const container = element.find("[chart-type]").html("");
        const width = $(container[0]).width();
        const dx = (width - 70) / data.periods.length;
        const margin = 20;
        const svg = d3
          .select(container[0])
          .append("svg")
          .attr("width", "100%")
          .attr("height", "440");

        const g = svg
          .append("g")
          .attr("transform", "translate(" + -10 + "," + 420 + ")");
        const g0 = svg
          .append("g")
          .attr("transform", "translate(" + -10 + "," + 470 + ")");

        g.append("line")
          .attr("x1", 70)
          .attr("x2", 70)
          .attr("y1", -50)
          .attr("y2", -415)
          .style({ stroke: "#ccc", "stroke-width": 2 });

        g0.append("line")
          .attr("x1", 100)
          .attr("x2", width - 55)
          .attr("y1", -50)
          .attr("y2", -50)
          .style({ stroke: "#ccc", "stroke-width": 2 });

        g0.append("text")
          .attr("text-anchor", "middle")
          .attr("dominant-baseline", "central")
          .attr("y", -50)
          .attr("x", 70)
          .text("MOST");

        g0.append("text")
          .attr("text-anchor", "middle")
          .attr("dominant-baseline", "central")
          .attr("y", -50)
          .attr("x", width - 30)
          .text("LEAST");

        for (let j = 0; j < data.flags.length; j++) {
          const g1 = svg
            .append("g")
            .attr(
              "transform",
              "translate(" + 5 + "," + (data.flags[j].title * 2 + 14) + ")"
            );

          g.append("line")
            .attr("x1", 60)
            .attr("x2", width)
            .attr("y1", (-50 - data.flags[j].title) * 2)
            .attr("y2", (-50 - data.flags[j].title) * 2)
            .style({ stroke: "#ccc", "stroke-width": 1 });

          g.append("text")
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "central")
            .attr("y", (-50 - data.flags[j].title) * 2)
            .attr("x", 40)
            .text(data.flags[j].title);

          g1.append("path").attr(
            "d",
            "M 12.929688 13.148438 L 11.386719 13.148438 L 11.386719 1.023438 C 11.386719 0.460938 10.9375 0 10.375 0 C 9.820312 0 9.375 0.441406 9.355469 0.992188 L 7.917969 0.476562 C 7.394531 0.292969 6.796875 0.347656 6.3125 0.628906 L 5.121094 1.328125 C 4.886719 1.464844 4.625 1.535156 4.355469 1.535156 C 4.113281 1.535156 3.871094 1.476562 3.660156 1.367188 L 2.367188 0.707031 C 2.109375 0.574219 1.792969 0.585938 1.546875 0.738281 C 1.296875 0.890625 1.144531 1.164062 1.144531 1.460938 L 1.144531 6.238281 C 1.144531 6.554688 1.316406 6.84375 1.601562 6.992188 L 3.476562 7.953125 C 3.75 8.09375 4.050781 8.164062 4.355469 8.164062 C 4.695312 8.164062 5.03125 8.074219 5.324219 7.902344 L 6.519531 7.207031 C 6.75 7.070312 7.015625 7 7.285156 7 C 7.457031 7 7.617188 7.027344 7.777344 7.082031 L 9.339844 7.652344 L 9.339844 13.148438 L 7.839844 13.148438 C 7.332031 13.148438 6.917969 13.566406 6.917969 14.074219 C 6.917969 14.585938 7.332031 15 7.839844 15 L 12.929688 15 C 13.441406 15 13.855469 14.585938 13.855469 14.074219 C 13.855469 13.566406 13.441406 13.148438 12.929688 13.148438 Z M 12.929688 13.148438"
          );
        }

        for (let k = 0; k < data.percent.length; k++) {
          g.append("line")
            .attr("x1", 52)
            .attr("x2", width)
            .attr("y1", -50 - data.percent[k].title * 4)
            .attr("y2", -50 - data.percent[k].title * 4)
            .style({
              stroke: "#ccc",
              "stroke-width": 1,
              "stroke-dasharray": "10, 10",
            });

          g.append("text")
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "central")
            .attr("y", -50 - data.percent[k].title * 4)
            .attr("x", 40)
            .style({ "font-weight": 200, fill: "#bdbdbd", "font-size": "13px" })
            .text(`${data.percent[k].title}%`);
        }

        for (let i = 0; i < data.periods.length; i++) {
          const lastItem = i === data.periods.length - 1;
          g.append("rect")
            .style("fill", data.periods[i].color)
            .attr("x", 70)
            .attr("y", -30)
            .attr("width", (width - 70) / data.periods.length - margin)
            .attr("transform", self.formatTranslate(i * dx + margin / 2, -20))
            .attr("height", 30);

          g.append("circle")
            .attr("transform", self.formatTranslate(i * dx + dx / 2, 0))
            .attr("cx", 70)
            .attr("cy", (-50 - data.points[i].value) * 2)
            .attr("r", "10px")
            .style("fill", data.periods[i].color);

          g.append("text")
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "central")
            .attr("y", (-50 - data.points[i].value) * 2 - 20)
            .attr("x", 70)
            .attr("transform", self.formatTranslate(i * dx + dx / 2, 0))
            .text(data.points[i].value);

          g.append("text")
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "central")
            .attr("y", (-50 - data.points[i].value) * 2 + 20)
            .attr("x", 70)
            .attr("transform", self.formatTranslate(i * dx + dx / 2, 0))
            .text(data.points[i].percentage);

          if (!lastItem) {
            g.append("line")
              .attr("x1", i * dx + dx / 2 + 12 + 70)
              .attr("x2", (i + 1) * dx + dx / 2 - 12 + 70)
              .attr("y1", (-50 - data.points[i].value) * 2)
              .attr("y2", (-50 - data.points[i + 1].value) * 2)
              .style({ stroke: "#666666", "stroke-width": 2 });
          }
        }
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {},
        templateUrl:
          "app/directives/custom-infographics-brand-directives/chart-by-color-use-in-flags-worldwide.html",
      };

      return directive;
    },
  ]);
})();
