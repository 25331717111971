angular.module("app").directive("volumeStudiedColorChart", () => {
  let link = (scope) => {
    scope.$watch('data', bindData);
    scope.$watch('selectedColor', bindData);

    function bindData() {
      scope.chartData = Object.assign({}, scope.data.data);
      scope.chartData.color = scope.selectedColor;
      scope.averageData = scope.data.averageData;
      if(scope.averageData.length) {
        scope.averageData = [...new Set(scope.averageData)]; // removes duplicate values from the averageData array
      }
      scope.allChartData = [];
      scope.allChartData.push(scope.chartData);
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbVolumeStudiedColorData/dbVolumeStudiedColorDataView.html",
    link: link,
    scope: {
      data: "=",
      selectedColor: "=",
    }
  };
});
