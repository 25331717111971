angular
  .module('app')
  .service('cartService', ['httpService', '$http', 'appConfig', 'authService', function (httpService, $http, appConfig, authService) {

    this.getCartItem = function () {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/carts`,
        method: "GET",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };

    this.createCart = function (params) {
      return $http({
        url:
          appConfig.dashboardServiceUrl +
          "api/carts",
        method: "POST",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        data: params,
      });
    };

    this.deleteCartItem = function (id) {
      console.log('id', id)
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/carts/${id}`,
        method: "DELETE",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
    };

    this.checkoutCart = function (params) {
      return $http({
        url:
          appConfig.dashboardServiceUrl +
          "api/checkouts.json",
        method: "POST",
        headers: {
          "Authorizing": authService.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        data: params,
      });
    };

  }]);