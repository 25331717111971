angular.module("app").directive("sportColorData", [
  () => {
    let link = (scope, element) => {
      scope.colorHeaderData = {
        description:
          "Birds are traditionally thought of as a well-studied group, with more than 95% of the global species diversity estimated to have been described. Scientists estimate that there are roughly 9,000 - 10,000 species of birds.",
        teams: "8,734",
        colorUsedPercentage: "96%",
        shades: "96"
      };

      scope.sportHeaderData = {
        description: "Birds are traditionally thought of as a well-studied group, with more than 95% of the global species diversity estimated to have been described. Scientists estimate that there are roughly 9,000 - 10,000 species of birds.",
        teams: "8,734",
        combinationColor: "yellow - blue",
        moreUsedColor: "red",
      };

      scope.teamHeaderData = {
        description: "This is Photoshop's version of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis, sem nibh id elit.",
        league: "laliga",
        mostUsedColor: "white",
        colors: 8,
      };

      scope.boardType = "sport";
      scope.showMoreColors = false;
      scope.showAutoPopup = false;
      scope.showColorPopup = false;
      scope.colorsCount = 22;
      scope.topColorsExpanded = false;
      scope.colorFrequencyExpanded = false;

      scope.selectedItem = scope.item;
      scope.colors = scope.colorSelectionData;

      scope.isModeSport = () => {
        return scope.viewMode === "sport";
      };

      scope.isModeTeam = () => {
        return scope.viewMode === "team";
      };

      scope.isModeColor = () => {
        return scope.viewMode === "color";
      };

      // dummy data -> use of (color) across all sports
      scope.colorDataAcrossSports = [
        {
          "id": 0,
          "title": "Baseball",
          "img_url": "/assets/img/sports/types/baseball.png",
          "percentage": 11,
        },
        {
          "id": 1,
          "title": "Basketball",
          "img_url": "/assets/img/sports/types/basketball.png",
          "percentage": 11,
        },
        {
          "id": 2,
          "title": "Football",
          "img_url": "/assets/img/sports/types/football.png",
          "percentage": 11,
        },
        {
          "id": 3,
          "title": "Hockey",
          "img_url": "/assets/img/sports/types/hockey.png",
          "percentage": 11,
        },
        {
          "id": 4,
          "title": "Soccer",
          "img_url": "/assets/img/sports/types/soccer.png",
          "percentage": 11,
        },
        {
          "id": 5,
          "title": "Lacrosse",
          "img_url": "/assets/img/sports/types/lacrosse.png",
          "percentage": 11,
        },
        {
          "id": 6,
          "title": "Rugby",
          "img_url": "/assets/img/sports/types/rugby.png",
          "percentage": 11,
        },
      ];

      // dummy Data -> use of (color) compared to use of all other color families across all sports
      scope.colorFrequencyData = {
        percentages: [
          ">18.44%",
          "9.83%-7.09%",
          "6.89%-4.76%",
          "9.83%",
        ],
        mostPopular: [
          {
            percentage: 20,
            title: "color1",
            color: { hex: "#fff6f9" }
          },
          {
            percentage: 20,
            title: "color2",
            color: { hex: "#f6bac6", hex1: "#ff0000" }
          },
          {
            percentage: 15,
            title: "color3",
            color: { hex: "#ff7f00" }
          },
          {
            percentage: 15,
            title: "color4",
            color: { hex: "#fff640", hex1: "#f3d725" }
          },
          {
            percentage: 15,
            title: "color5",
            color: { hex: "#2d9641" }
          },
          {
            percentage: 15,
            title: "color6",
            color: { hex: "#2065dc" }
          }
        ],
        moderate: [
          {
            percentage: 55,
            title: "color7",
            color: { hex: "#7d1f7f" }
          },
          {
            percentage: 45,
            title: "color8",
            color: { hex: "#644321" }
          },
        ],
        infrequent: [
          {
            percentage: 100,
            title: "color9",
            color: { hex: "#b9b9b8" }
          },
        ],
        not_used: [
          {
            percentage: 30,
            title: "color10",
            color: { hex: "#ffffff" }
          },
          {
            percentage: 70,
            title: "color11",
            color: { hex: "#020303" }
          },
        ]
      };

      // dummy data -> popular color combinations for (color) across all sports
      const popularCombinationData = [
        {
          color: { hex: "#d8d635", title: "color1" },
          percentage: 12,
        },
        {
          color: { hex: "#89884b", title: "color2" },
          percentage: 12,
        },
        {
          color: { hex: "#684b89", title: "color3" },
          percentage: 24,
        },
        {
          color: { hex: "#bea1de", title: "color4" },
          percentage: 8,
        },
        {
          color: { hex: "#89884b", title: "color5" },
          percentage: 12,
        },
        {
          color: { hex: "#684b89", title: "color6" },
          percentage: 24,
        },
        {
          color: { hex: "#bea1de", title: "color7" },
          percentage: 8,
        }
      ];
      scope.sportColorCombinationData = [
        {
          "id": 0,
          "title": "Baseball",
          "img_url": "/assets/img/sports/types/baseball.png",
          "colorData": popularCombinationData,
        },
        {
          "id": 1,
          "title": "Basketball",
          "img_url": "/assets/img/sports/types/basketball.png",
          "colorData": popularCombinationData,
        },
        {
          "id": 2,
          "title": "Football",
          "img_url": "/assets/img/sports/types/football.png",
          "colorData": popularCombinationData,
        },
        {
          "id": 3,
          "title": "Hockey",
          "img_url": "/assets/img/sports/types/hockey.png",
          "colorData": popularCombinationData,
        },
        {
          "id": 4,
          "title": "Soccer",
          "img_url": "/assets/img/sports/types/soccer.png",
          "colorData": popularCombinationData,
        },
        {
          "id": 5,
          "title": "Lacrosse",
          "img_url": "/assets/img/sports/types/lacrosse.png",
          "colorData": popularCombinationData,
        },
        {
          "id": 6,
          "title": "Rugby",
          "img_url": "/assets/img/sports/types/rugby.png",
          "colorData": popularCombinationData,
        },
      ];

      // dummy data -> use of (color) across all sport teams
      scope.colorDataAcrossTeams = [
        {
          id: 0,
          title: "Anaheim Ducks",
          img_url: "/assets/img/sports/teams/1.png",
          percentage: 99,
        },
        {
          id: 1,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/2.png",
          percentage: 70,
        },
        {
          id: 2,
          title: "Calgary Flames",
          img_url: "/assets/img/sports/teams/3.png",
          percentage: 40,
        },
        {
          id: 3,
          title: "Edmonton Oilers",
          img_url: "/assets/img/sports/teams/4.png",
          percentage: 60,
        },
        {
          id: 4,
          title: "Los Angeles Kings",
          img_url: "/assets/img/sports/teams/5.png",
          percentage: 80,
        },
        {
          id: 5,
          title: "San Jose Sharks",
          img_url: "/assets/img/sports/teams/6.png",
          percentage: 75,
        },
        {
          id: 6,
          title: "Vancouver Canucks",
          img_url: "/assets/img/sports/teams/7.png",
          percentage: 65,
        },
        {
          id: 7,
          title: "Vegas Golden Knights",
          img_url: "/assets/img/sports/teams/8.png",
          percentage: 70,
        },
        {
          id: 8,
          title: "Anaheim ducks",
          img_url: "/assets/img/sports/teams/9.png",
          percentage: 99,
        },
        {
          id: 9,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/10.png",
          percentage: 85,
        },
        {
          id: 10,
          title: "Calgary Flames",
          img_url: "/assets/img/sports/teams/1.png",
          percentage: 74,
        },
        {
          id: 11,
          title: "Edmonton Oilers",
          img_url: "/assets/img/sports/teams/2.png",
          percentage: 70,
        },
        {
          id: 12,
          title: "Los Angeles Kings",
          img_url: "/assets/img/sports/teams/3.png",
          percentage: 40,
        },
        {
          id: 13,
          title: "San Jose Sharks",
          img_url: "/assets/img/sports/teams/4.png",
          percentage: 50,
        },
        {
          id: 14,
          title: "Vancouver Canucks",
          img_url: "/assets/img/sports/teams/5.png",
          percentage: 30,
        },
        {
          id: 15,
          title: "Anaheim Ducks",
          img_url: "/assets/img/sports/teams/1.png",
          percentage: 80,
        },
        {
          id: 16,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/2.png",
          percentage: 70,
        },
        {
          id: 17,
          title: "Calgary Flames",
          img_url: "/assets/img/sports/teams/3.png",
          percentage: 60,
        },
        {
          id: 18,
          title: "Edmonton Oilers",
          img_url: "/assets/img/sports/teams/4.png",
          percentage: 67,
        },
        {
          id: 19,
          title: "Los Angeles Kings",
          img_url: "/assets/img/sports/teams/5.png",
          percentage: 73,
        },
        {
          id: 20,
          title: "San Jose Sharks",
          img_url: "/assets/img/sports/teams/6.png",
          percentage: 78,
        },
        {
          id: 21,
          title: "Vancouver Canucks",
          img_url: "/assets/img/sports/teams/7.png",
          percentage: 80,
        },
        {
          id: 22,
          title: "Vegas Golden Knights",
          img_url: "/assets/img/sports/teams/8.png",
          percentage: 85,
        },
        {
          id: 23,
          title: "Anaheim ducks",
          img_url: "/assets/img/sports/teams/9.png",
          percentage: 86,
        },
        {
          id: 24,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/10.png",
          percentage: 70,
        },
        {
          id: 25,
          title: "Calgary Flames",
          img_url: "/assets/img/sports/teams/1.png",
          percentage: 90,
        },
        {
          id: 26,
          title: "Edmonton Oilers",
          img_url: "/assets/img/sports/teams/2.png",
          percentage: 60,
        },
        {
          id: 27,
          title: "Los Angeles Kings",
          img_url: "/assets/img/sports/teams/3.png",
          percentage: 50,
        },
        {
          id: 28,
          title: "San Jose Sharks",
          img_url: "/assets/img/sports/teams/4.png",
          percentage: 90,
        },
        {
          id: 29,
          title: "Vancouver Canucks",
          img_url: "/assets/img/sports/teams/5.png",
          percentage: 80,
        },
      ];

      // dummy data -> actual (color) used across all sport teams
      scope.actualColorsAcrossTemas = [
        {
          id: 0,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#0088bb",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 1,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 2,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#065af5",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 3,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#0088bb",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 4,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#06c0f5",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 5,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#91dbf7",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 6,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 7,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2806f5",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 8,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#0088bb",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 8,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#0088bb",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 9,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#06c0f5",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 10,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#91dbf7",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 11,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 12,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#0088bb",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 13,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 14,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#065af5",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 15,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#0088bb",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 16,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#06c0f5",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 17,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#91dbf7",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 18,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 19,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2806f5",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 20,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#0088bb",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 21,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#0088bb",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 22,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#06c0f5",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 23,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#91dbf7",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 24,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 25,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#0088bb",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 26,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 27,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#065af5",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 28,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#0088bb",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 29,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#06c0f5",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 30,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#91dbf7",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 31,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 32,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2806f5",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 33,
          name: "104-A BLUE RIBBON",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#0088bb",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 34,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#0088bb",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 35,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#06c0f5",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 36,
          name: "BLUE RIBBON DAY",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#91dbf7",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 37,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 38,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 39,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 40,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 41,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 42,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 43,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 44,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 45,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        },
        {
          id: 46,
          name: "BLUE RIBBON DOG",
          team: "Juventus",
          sport: "soccer",
          league: "la liga",
          type: "team road uniform",
          logo_url: '/assets/img/sports/logo-new.svg',
          color: {
            hex: "#2babda",
            R: 253, G: 217, B: 16
          },
        }
      ];

      // dummy data -> use of colors by all (sports) teams
      scope.sportTeamUsedColorData = [
        {
          id: 0,
          title: "color1",
          color: { hex: "#e51c20", hex1: "#f6bac6" },
          percentage: 11
        },
        {
          id: 1,
          title: "color2",
          color: { hex: "#f07e14" },
          percentage: 11
        },
        {
          id: 2,
          title: "color3",
          color: { hex: "#f5eb49", hex1: "#f1d724" },
          percentage: 11
        },
        {
          id: 3,
          title: "color4",
          color: { hex: "#299741" },
          percentage: 11
        },
        {
          id: 4,
          title: "color5",
          color: { hex: "#3462ac" },
          percentage: 11
        },
        {
          id: 5,
          title: "color6",
          color: { hex: "#7c217f" },
          percentage: 11
        },
        {
          id: 6,
          title: "color7",
          color: { hex: "#654322" },
          percentage: 11
        },
        {
          id: 7,
          title: "color8",
          color: { hex: "#b9b9b8" },
          percentage: 11
        },
        {
          id: 8,
          title: "color9",
          color: { hex: "#ffffff" },
          percentage: 11
        },
        {
          id: 9,
          title: "color10",
          color: { hex: "#040506" },
          percentage: 11
        },
      ]

      // dummy data -> color use comparison for (selected sport) and all other sports
      const colorComparisonData = {
        percentages: [
          ">18.44%",
          "9.83%-7.09%",
          "6.89%-4.76%",
          "9.83%",
        ],
        mostPopular: [
          {
            percentage: 20,
            title: "color1",
            color: { hex: "#fff6f9" }
          },
          {
            percentage: 20,
            title: "color2",
            color: { hex: "#f6bac6", hex1: "#ff0000" }
          },
          {
            percentage: 15,
            title: "color3",
            color: { hex: "#ff7f00" }
          },
          {
            percentage: 15,
            title: "color4",
            color: { hex: "#fff640", hex1: "#f3d725" }
          },
          {
            percentage: 15,
            title: "color5",
            color: { hex: "#2d9641" }
          },
          {
            percentage: 15,
            title: "color6",
            color: { hex: "#2065dc" }
          }
        ],
        moderate: [
          {
            percentage: 55,
            title: "color7",
            color: { hex: "#7d1f7f" }
          },
          {
            percentage: 45,
            title: "color8",
            color: { hex: "#644321" }
          },
        ],
        infrequent: [
          {
            percentage: 100,
            title: "color9",
            color: { hex: "#b9b9b8", hex1: "#2f52a0" }
          },
        ],
        not_used: [
          {
            percentage: 30,
            title: "color10",
            color: { hex: "#ffffff" }
          },
          {
            percentage: 70,
            title: "color11",
            color: { hex: "#020303" }
          },
        ]
      };
      scope.sportComparisonData = [
        {
          name: "Baseball",
          img_url: "/assets/img/sports/types/baseball.png",
          colorComparisonData
        },
        {
          name: "Basketball",
          img_url: "/assets/img/sports/types/basketball.png",
          colorComparisonData
        },
        {
          name: "Football",
          img_url: "/assets/img/sports/types/football.png",
          colorComparisonData
        },
        {
          name: "Hockey",
          img_url: "/assets/img/sports/types/hockey.png",
          colorComparisonData
        },
        {
          name: "Lacrosse",
          img_url: "/assets/img/sports/types/lacrosse.png",
          colorComparisonData
        },
        {
          name: "Rugby",
          img_url: "/assets/img/sports/types/rugby.png",
          colorComparisonData
        },
        {
          name: "Soccer",
          img_url: "/assets/img/sports/types/soccer.png",
          colorComparisonData
        },
      ];

      // dummy data -> color use across all (selected sport) teams
      scope.colorDataAcrossAllSportTeams = [
        {
          id: 0,
          title: "Anaheim Ducks",
          img_url: "/assets/img/sports/teams/1.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', null, '#8db723', null, null, '#3563ad',
            '#5d4596', null, '#974d15', '#f4f5dc', null, '#ffffff', '#020303'
          ]
        },
        {
          id: 1,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/2.png",
          sport: "NFL",
          colors: [
            '#ef103a', null, null, '#8db723', '#0a8236', null, '#3563ad',
            null, '#a6529b', '#974d15', null, '#c0c0bf', '#ffffff', null
          ]
        },
        {
          id: 2,
          title: "Calgary Flames",
          img_url: "/assets/img/sports/teams/3.png",
          sport: "NFL",
          colors: [
            null, '#f07e14', '#f1e60f', null, '#0a8236', null, null,
            '#5d4596', '#a6529b', null, '#f4f5dc', '#c0c0bf', '#ffffff', '#020303'
          ]
        },
        {
          id: 3,
          title: "Edmonton Oilers",
          img_url: "/assets/img/sports/teams/4.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', '#f1e60f', null, null, null, '#3563ad',
            null, '#a6529b', null, '#f4f5dc', '#c0c0bf', null, '#020303'
          ]
        },
        {
          id: 4,
          title: "Los Angeles Kings",
          img_url: "/assets/img/sports/teams/5.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', null, '#8db723', null, '#6ac6d9', null,
            '#5d4596', '#a6529b', null, '#f4f5dc', '#c0c0bf', null, null
          ]
        },
        {
          id: 5,
          title: "San Jose Sharks",
          img_url: "/assets/img/sports/teams/6.png",
          sport: "NFL",
          colors: [
            null, '#f07e14', null, '#8db723', null, null, '#3563ad',
            '#5d4596', null, '#974d15', null, '#c0c0bf', '#ffffff', '#020303'
          ]
        },
        {
          id: 6,
          title: "Vancouver Canucks",
          img_url: "/assets/img/sports/teams/7.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', '#f1e60f', '#8db723', '#0a8236', '#6ac6d9', '#3563ad',
            '#5d4596', '#a6529b', '#974d15', '#f4f5dc', '#c0c0bf', '#ffffff', '#020303'
          ]
        },
        {
          id: 7,
          title: "Vegas Golden Knights",
          img_url: "/assets/img/sports/teams/8.png",
          sport: "NFL",
          colors: [
            '#ef103a', null, '#f1e60f', null, null, '#6ac6d9', '#3563ad',
            '#5d4596', null, '#974d15', null, '#c0c0bf', null, '#020303'
          ]
        },
        {
          id: 8,
          title: "Anaheim ducks",
          img_url: "/assets/img/sports/teams/9.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', '#f1e60f', '#8db723', null, '#6ac6d9', null,
            '#5d4596', null, null, '#f4f5dc', '#c0c0bf', null, null
          ]
        },
        {
          id: 9,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/10.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', null, '#8db723', '#0a8236', null, '#3563ad',
            '#5d4596', null, null, '#f4f5dc', null, null, '#020303'
          ]
        },
        {
          id: 10,
          title: "Calgary Flames",
          img_url: "/assets/img/sports/teams/1.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', null, null, '#0a8236', '#6ac6d9', '#3563ad',
            '#5d4596', '#a6529b', null, null, null, null, '#020303'
          ]
        },
        {
          id: 11,
          title: "Edmonton Oilers",
          img_url: "/assets/img/sports/teams/2.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', '#f1e60f', '#8db723', '#0a8236', null, '#3563ad',
            '#5d4596', '#a6529b', null, '#f4f5dc', null, null, null
          ]
        },
        {
          id: 12,
          title: "Los Angeles Kings",
          img_url: "/assets/img/sports/teams/3.png",
          sport: "NFL",
          colors: [
            null, '#f07e14', '#f1e60f', null, '#0a8236', '#6ac6d9', '#3563ad',
            '#5d4596', '#a6529b', '#974d15', null, '#c0c0bf', null, null
          ]
        },
        {
          id: 13,
          title: "San Jose Sharks",
          img_url: "/assets/img/sports/teams/4.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', '#f1e60f', '#8db723', null, '#6ac6d9', null,
            '#5d4596', null, '#974d15', null, '#c0c0bf', null, null
          ]
        },
        {
          id: 14,
          title: "Vancouver Canucks",
          img_url: "/assets/img/sports/teams/5.png",
          sport: "NFL",
          colors: [
            null, null, '#f1e60f', '#8db723', null, '#6ac6d9', '#3563ad',
            null, '#a6529b', '#974d15', '#f4f5dc', null, '#ffffff', null
          ]
        },
        {
          id: 15,
          title: "Anaheim Ducks",
          img_url: "/assets/img/sports/teams/1.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', null, '#8db723', null, null, '#3563ad',
            '#5d4596', null, '#974d15', '#f4f5dc', null, '#ffffff', '#020303'
          ]
        },
        {
          id: 16,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/2.png",
          sport: "NFL",
          colors: [
            '#ef103a', null, null, '#8db723', '#0a8236', null, '#3563ad',
            null, '#a6529b', '#974d15', null, '#c0c0bf', '#ffffff', null
          ]
        },
        {
          id: 17,
          title: "Calgary Flames",
          img_url: "/assets/img/sports/teams/3.png",
          sport: "NFL",
          colors: [
            null, '#f07e14', '#f1e60f', null, '#0a8236', null, null,
            '#5d4596', '#a6529b', null, '#f4f5dc', '#c0c0bf', '#ffffff', '#020303'
          ]
        },
        {
          id: 18,
          title: "Edmonton Oilers",
          img_url: "/assets/img/sports/teams/4.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', '#f1e60f', null, null, null, '#3563ad',
            null, '#a6529b', null, '#f4f5dc', '#c0c0bf', null, '#020303'
          ]
        },
        {
          id: 19,
          title: "Los Angeles Kings",
          img_url: "/assets/img/sports/teams/5.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', null, '#8db723', null, '#6ac6d9', null,
            '#5d4596', '#a6529b', null, '#f4f5dc', '#c0c0bf', null, null
          ]
        },
        {
          id: 20,
          title: "San Jose Sharks",
          img_url: "/assets/img/sports/teams/6.png",
          sport: "NFL",
          colors: [
            null, '#f07e14', null, '#8db723', null, null, '#3563ad',
            '#5d4596', null, '#974d15', null, '#c0c0bf', '#ffffff', '#020303'
          ]
        },
        {
          id: 21,
          title: "Vancouver Canucks",
          img_url: "/assets/img/sports/teams/7.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', '#f1e60f', '#8db723', '#0a8236', '#6ac6d9', '#3563ad',
            '#5d4596', '#a6529b', '#974d15', '#f4f5dc', '#c0c0bf', '#ffffff', '#020303'
          ]
        },
        {
          id: 22,
          title: "Vegas Golden Knights",
          img_url: "/assets/img/sports/teams/8.png",
          sport: "NFL",
          colors: [
            '#ef103a', null, '#f1e60f', null, null, '#6ac6d9', '#3563ad',
            '#5d4596', null, '#974d15', null, '#c0c0bf', null, '#020303'
          ]
        },
        {
          id: 23,
          title: "Anaheim ducks",
          img_url: "/assets/img/sports/teams/9.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', '#f1e60f', '#8db723', null, '#6ac6d9', null,
            '#5d4596', null, null, '#f4f5dc', '#c0c0bf', null, null
          ]
        },
        {
          id: 24,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/10.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', null, '#8db723', '#0a8236', null, '#3563ad',
            '#5d4596', null, null, '#f4f5dc', null, null, '#020303'
          ]
        },
        {
          id: 25,
          title: "Calgary Flames",
          img_url: "/assets/img/sports/teams/1.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', null, null, '#0a8236', '#6ac6d9', '#3563ad',
            '#5d4596', '#a6529b', null, null, null, null, '#020303'
          ]
        },
        {
          id: 26,
          title: "Edmonton Oilers",
          img_url: "/assets/img/sports/teams/2.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', '#f1e60f', '#8db723', '#0a8236', null, '#3563ad',
            '#5d4596', '#a6529b', null, '#f4f5dc', null, null, null
          ]
        },
        {
          id: 27,
          title: "Los Angeles Kings",
          img_url: "/assets/img/sports/teams/3.png",
          sport: "NFL",
          colors: [
            null, '#f07e14', '#f1e60f', null, '#0a8236', '#6ac6d9', '#3563ad',
            '#5d4596', '#a6529b', '#974d15', null, '#c0c0bf', null, null
          ]
        },
        {
          id: 28,
          title: "San Jose Sharks",
          img_url: "/assets/img/sports/teams/4.png",
          sport: "NFL",
          colors: [
            '#ef103a', '#f07e14', '#f1e60f', '#8db723', null, '#6ac6d9', null,
            '#5d4596', null, '#974d15', null, '#c0c0bf', null, null
          ]
        },
        {
          id: 29,
          title: "Vancouver Canucks",
          img_url: "/assets/img/sports/teams/5.png",
          sport: "NFL",
          colors: [
            null, null, '#f1e60f', '#8db723', null, '#6ac6d9', '#3563ad',
            null, '#a6529b', '#974d15', '#f4f5dc', null, '#ffffff', null
          ]
        },
      ];

      // dummy data -> color combinations for each of the (selected sport) teams
      const colorCombinationData = [
        {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 24},
        {hex: "#89884b", R: 137, G: 136, B: 75, percent: 24},
        {hex: "#684b89", R: 104, G: 75, B: 137, percent: 44},
        {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 8}
      ];
      scope.colorCombinationTeamData = [
        {
          id: 0,
          title: "Anaheim Ducks",
          img_url: "/assets/img/sports/teams/1.png",
          colors: colorCombinationData
        },
        {
          id: 1,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/2.png",
          colors: colorCombinationData
        },
        {
          id: 2,
          title: "Calgary Flames",
          img_url: "/assets/img/sports/teams/3.png",
          colors: colorCombinationData
        },
        {
          id: 3,
          title: "Edmonton Oilers",
          img_url: "/assets/img/sports/teams/4.png",
          colors: colorCombinationData
        },
        {
          id: 4,
          title: "Los Angeles Kings",
          img_url: "/assets/img/sports/teams/5.png",
          colors: colorCombinationData
        },
        {
          id: 5,
          title: "San Jose Sharks",
          img_url: "/assets/img/sports/teams/6.png",
          colors: colorCombinationData
        },
        {
          id: 6,
          title: "Vancouver Canucks",
          img_url: "/assets/img/sports/teams/7.png",
          colors: colorCombinationData
        },
        {
          id: 7,
          title: "Vegas Golden Knights",
          img_url: "/assets/img/sports/teams/8.png",
          colors: colorCombinationData
        },
        {
          id: 8,
          title: "Anaheim ducks",
          img_url: "/assets/img/sports/teams/9.png",
          colors: colorCombinationData
        },
        {
          id: 9,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/10.png",
          colors: colorCombinationData
        },
      ];

      // dummy data -> colors used by (selected team) compared with all other (selected sport) teams
      scope.selectedTeam = {
        title: scope.selectedItem.title,
        img_url: "/assets/img/sports/logo2.png",
        sport: "soccer",
        type: "team road uniform",
        logoColors: [
          {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
          {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
          {hex: "#684b89", R: 104, G: 75, B: 137, percent: 40},
          {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 20}
        ],
        jerseyColors: [
          {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
          {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
          {hex: "#684b89", R: 104, G: 75, B: 137, percent: 40},
          {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 20},
        ]
      };
      scope.colorComparisonTeamData = [
        {
          id: 0,
          title: "Anaheim Ducks",
          img_url: "/assets/img/sports/teams/1.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 1,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/2.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 2,
          title: "Calgary Flames",
          img_url: "/assets/img/sports/teams/3.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 3,
          title: "Edmonton Oilers",
          img_url: "/assets/img/sports/teams/4.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 4,
          title: "Los Angeles Kings",
          img_url: "/assets/img/sports/teams/5.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 5,
          title: "San Jose Sharks",
          img_url: "/assets/img/sports/teams/6.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 6,
          title: "Vancouver Canucks",
          img_url: "/assets/img/sports/teams/7.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 7,
          title: "Vegas Golden Knights",
          img_url: "/assets/img/sports/teams/8.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 8,
          title: "Anaheim ducks",
          img_url: "/assets/img/sports/teams/9.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 9,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/10.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 10,
          title: "Anaheim Ducks",
          img_url: "/assets/img/sports/teams/1.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 11,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/2.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 12,
          title: "Calgary Flames",
          img_url: "/assets/img/sports/teams/3.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 13,
          title: "Edmonton Oilers",
          img_url: "/assets/img/sports/teams/4.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 14,
          title: "Los Angeles Kings",
          img_url: "/assets/img/sports/teams/5.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 15,
          title: "San Jose Sharks",
          img_url: "/assets/img/sports/teams/6.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 16,
          title: "Vancouver Canucks",
          img_url: "/assets/img/sports/teams/7.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 17,
          title: "Vegas Golden Knights",
          img_url: "/assets/img/sports/teams/8.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 18,
          title: "Anaheim ducks",
          img_url: "/assets/img/sports/teams/9.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
        {
          id: 19,
          title: "Arizona Coyotes",
          img_url: "/assets/img/sports/teams/10.png",
          sport: "soccer",
          type: "team road uniform",
          logoColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 15},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 25},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 35},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ],
          jerseyColors: [
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#d8d635", R: 216, G: 214, B: 53, percent: 10},
            {hex: "#89884b", R: 137, G: 136, B: 75, percent: 10},
            {hex: "#684b89", R: 104, G: 75, B: 137, percent: 10},
            {hex: "#bea1de", R: 190, G: 161, B: 222, percent: 10}
          ]
        },
      ];

    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbSportColorData/dbSportColorDataView.html",
      link: link,
      scope: {
        item: "=",
        viewMode: "=",
        colorSelectionData: "=",
      },
    };
  },
]);
