angular.module("app").directive("brandColorTrademark", () => {
  let link = (scope) => {
    scope.getDotsList = (value) => {
      const cnt = Math.ceil(value / 10);

      const result = [];
      for (let i = 0; i < cnt; i ++) {
        result.push(i);
      }

      return result;
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbBrandColorTrademark/dbBrandColorTrademarkView.html",
    link: link,
    scope: {
      data: "=",
      selectedColor: "=",
    }
  };
});
