angular.module("app").directive("namingColorData", [
  () => {
    let link = (scope, element) => {
      scope.selectedItem = scope.item;

      // selected color dummy data
      if (scope.viewMode === "color-search") {
        
        scope.headerData = {
          shades: 1313,
          crowdSourced: 37,
          colorConsulting: 32,
          other: 19,
          paint: 12,
          associatedTerms: 323,
          light: 37,
          medium: 27,
          dark: 8
        };
      } else {

        if(scope.attrData){
          console.log(scope.attrHeadData)
        }
        scope.headerData = {
          mostAssociatedColor: {
            percent: 24,
            hex: scope.attrHeadData.most_associated_color
          },
          colors: {
            count: scope.attrHeadData.colors_count,
            crowdSourced: 37,
            colorConsulting: 32,
            other: 19,
            paint: 12
          },
          exactMatchColors: {
            count: scope.attrHeadData.exact_match_colors,
            crowdSourced: 15,
            colorConsulting: 4,
            other: 3,
            paint: 2
          },
          colorFamilyCount: scope.attrHeadData.color_families,
          colorFamilies: [
            {
              name: "purple",
              percent: 37,
            },
            {
              name: "green",
              percent: 32,
            },
            {
              name: "blue",
              percent: 19,
            },
            {
              name: "red",
              percent: 12,
            }
          ]
        };
      }

      scope.isModeWordSearch = () => {
        return scope.viewMode === "word-search";
      };

      scope.isModeColorSearch = () => {
        return scope.viewMode === "color-search";
      };

      scope.isCloudView = true;

      scope.isRGBSpaceView = false;


      // dummy Old data -> word search-results
      // const  data = {
      //   name: graph.color_family,
      //   color: graph.color_hex,
      //   percentage: graph.percentage,
      //   count: graph.count,
      //   R: 0,
      //   G: 0,
      //   B: 0,
      //   source: "crowdsource"
      // }
      
      scope.$watch('item', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          scope.selectedItem = newValue;
        }
      });

      scope.$watch('viewMode', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          scope.viewMode = newValue;
        }
      });

      scope.$watch('bubbleData', function (newValue, oldValue) {
        if (newValue) {
          scope.bubbleNewData = [];
          newValue.forEach(element => {
            scope.bubbleNewData.push({
              count: element.count,
              hex: element.color,
              percent: element.percentage,
              title: element.name
            })
          });
        }
      });
      scope.$watch('attrHeadData', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          scope.attrHeadData = newValue;
          let count;
          if (scope.attrHeadData.color_families <= 3) {
            count = 25;
          } else if (scope.attrHeadData.color_families <= 7) {
            count = 50;
          } else if (scope.attrHeadData.color_families >= 8) {
            count = 75;
          }
          // dummy data -> names distributed across color families
          scope.namesDistributedAcrossColorFamilies = {
            value: count,
            texts: ['below average', 'above average']
          };
          // dummy data -> sentiment analysis of color names
          scope.sentimentsAnalysisOfColorNames = {
            value: 50,
            texts: ['negative', 'positive']
          };
        }
      });

      scope.$watch('colorNameCloud', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          scope.colorNameCloud = newValue;
        }
      });

    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbNamingColorData/dbNamingColorDataView.html",
      link: link,
      scope: {
        item: "=",
        viewMode: "=",
        colorFamily: "=",
        bubbleData: "=",
        attrHeadData: "=",
        colorNameHeader:"=",
        colorNameCloud:"=",
        r:"=",
        g:"=",
        b:"=",
        relevantCount:"=",
        exactCount:"=",
        allCounts: "=",
      },
    };
  },
]);
