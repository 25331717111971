angular.module("app").directive("sportTeamColorCombination", () => {
  let link = (scope) => {

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbSportTeamColorCombination/dbSportTeamColorCombinationView.html",
    link: link,
    scope: {
      data: "=",
      mode: "=",
    }
  };
});
