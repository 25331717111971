angular.module('app').directive('colorCombinationAcrossAllPeriodsChart', () => {
  let link = (scope) => {
    scope.maxPercentage = 1;
    scope.maxValuePercent = 1;
    scope.data = scope.colorData;
    scope.$watch('colorData', function (newVal, prevVal) {
      if(newVal) {
        scope.colorData = newVal;
        bindData();
      }
    });

    scope.heightDynamic = percent => {
      if (percent == 0){
        return 0
      } else if(percent > 0 && percent <= 1){
        return 4;
      } else if(percent > 1 && percent <= 5){
        return 20;
      } else if(percent > 5 && percent <= 10){
        return 40;
      } else if(percent > 10 && percent <= 15){
        return 60;
      } else if(percent > 15 && percent <= 20){
        return 80;
      } else if(percent > 20 && percent <= 25){
        return 100;
      } else if(percent > 25){
        return 100;
      }
    }

    scope.$watch('selectedColor', function (newVal, prevVal) {
      if(newVal != prevVal) {
        scope.selectedColor = newVal;
        bindData();
      }
    });

    function bindData() {
      if (scope.colorData.length) {
        scope.maxPercentage = Number(100 / scope.colorData.reduce((prev, current) => (prev.percent > current.percent) ? prev : current).percent);
      }
      scope.data = scope.colorData.filter((item) => {
        if (scope.selectedColor) {
          return item.hex !== scope.selectedColor.hex;
        }
        return item;
      });

      scope.filterPercentData = scope.data.filter((item) =>{
        if(typeof(item.percent) === 'string'){
          return item.percent = item.percent.replace('%', '');//.slice(0, -1);
        } else {
          return item.percent;
        }
      }); 

      scope.maxValuePercent = Math.max.apply(Math, scope.filterPercentData.map(function(o) { return o.percent; })); 
    }

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorCombinationAcrossAllPeriodsChart/dbColorCombinationAcrossAllPeriodsChartView.html",
    link: link,
    scope: {
      colorData: "=",
      selectedColor: "=",
      mode: "=",
    }
  };
});
