angular.module('app').directive('solidfrequencyline', function () {
  function link (scope) {

  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/solidFrequencyLine/solidFrequencyLineView.html',
    link: link,
    scope: {
      colors: '='
    }
  }
});
