angular.module("app").component("profileComponent", {
  templateUrl: "app/components/profile/profile.tmpl.html",
  controller: function (
    $state,
    $http,
    appConfig,
    categoryValues,
    dataValidate,
    localStorageService,
    authService,
    $scope,
    $q,
    $timeout,
    Upload,
    paymentService,
    modalService
  ) {
    const vm = this;
    $scope.uploadFiles = function (file) {
      if (file) {
        getFileBuffer(file).then((data) => {
          file.upload = Upload.upload({
            url:
              appConfig.dashboardServiceUrl +
              `api/users.json?token=${authService.token}`,
            method: "PATCH",
            headers: {
              "Content-Type": "multipart/form-data",
            },
            fields: { user: { image_url: data } },
          }).then((res) => {
            vm.userData = res.data;
          });
        });
      }
    };

    vm.logOut = function () {
      authService.logOut();
    };

    function getFileBuffer(file) {
      const deferred = new $q.defer();
      const reader = new FileReader();
      reader.onloadend = function (e) {
        deferred.resolve(e.target.result);
      };
      reader.onerror = function (e) {
        deferred.reject(e.target.error);
      };
      reader.readAsDataURL(file);
      return deferred.promise;
    }

    vm.job_function = categoryValues("job function");
    vm.company_size = categoryValues("company size");
    vm.industry = categoryValues("industry");
    vm.country = categoryValues("country");

    vm.editFlag = false;
    vm.fileFlag = true;
    vm.inviteList = true;
    vm.teamMemberLimit = false;
    if (localStorageService.get("currentUser")) {
      vm.currentUserPlan = localStorageService.get("currentUser").active_plan;
      if(vm.currentUserPlan.name === 'team-yearly' || vm.currentUserPlan.name === 'team-monthly'){
        vm.teamPlan = true;
      } else {
        vm.teamPlan = false;
      }
    }
    
    vm.getMemberList = function () {
      paymentService.getInviteTeamList().then((data) => {
        vm.teamUserList = data.members;
        if(vm.teamUserList && vm.teamUserList.length >= 9) {
          vm.teamMemberLimit = true;
        } else {
          vm.teamMemberLimit = false;
        }
      });
    }

    vm.getMemberList();

    vm.init = function () {
      authService.loadCurrentUser().then(function (res) {
        $http
          .get(appConfig.dashboardServiceUrl + "api/users.json", {
            params: { token: authService.token },
          })
          .then(function (res) {
            if (res && res.data) {
              vm.userData = res.data;
              vm.date = moment(
                vm.userData.date_year +
                  "-" +
                  vm.userData.date_month +
                  "-" +
                  vm.userData.date_day
              ).format("YYYY-MM-DD");

              vm.indexes = {
                company_size: vm.searchIndex(
                  vm.company_size,
                  vm.userData.company_size
                ),
                industry: vm.searchIndex(vm.industry, vm.userData.industry),
                job_function: vm.searchIndex(vm.job_function, vm.userData.job_function),
                country: vm.searchIndex(vm.country, vm.userData.country),
              };
              vm.intEditData();
            }
          });
      });
    };

    vm.intEditData = function () {
      vm.data = {
        first_name: {
          value: vm.userData.first_name,
          required: true,
          name: "first name",
          type: "provide",
        },
        last_name: {
          value: vm.userData.last_name,
          required: true,
          name: "last name",
          type: "provide",
        },
        email: {
          value: vm.userData.email,
          required: true,
          name: "email",
          type: "provide",
        },
        company: {
          value: vm.userData.company,
          required: true,
          name: "company name",
          type: "provide",
        },
        job_title: {
          value: vm.userData.job_title,
          required: true,
          name: "job title",
          type: "provide",
        },
        bio: { value: vm.userData.bio, name: "bio", type: "provide" },
        job_function: {
          value:
            vm.company_size[vm.indexes.job_function] ||
            vm.userData.job_function,
          required: true,
          name: "job function",
          type: "select",
        },
        company_size: {
          value:
            vm.company_size[vm.indexes.company_size] ||
            vm.userData.company_size,
          required: true,
          name: "company size",
          type: "select",
        },
        industry: {
          value: vm.industry[vm.indexes.industry] || vm.userData.industry,
          required: true,
          name: "industry",
          type: "select",
        },
        country: {
          value: vm.country[vm.indexes.country] || vm.userData.country,
          required: true,
          name: "country",
          type: "select",
        },
      };
    };

    vm.searchIndex = function (arr, value) {
      return _.findIndex(arr, function (item) {
        return item.title === value;
      });
    };

    vm.goCart = function () {
      $state.go("cart-page", { wayBack: "profile" });
    };

    vm.goPurchase = function () {
      $state.go("my-purchases");
    };

    vm.cancel = function () {
      vm.editFlag = false;
      vm.intEditData();
    };

    vm.save = function () {
      if (dataValidate.validate(vm.data)) {
        const data = {};
        for (let item in vm.data) {
          if (vm.data[item].type === "select") {
            data[item] = vm.data[item].value.title;
          } else {
            data[item] = vm.data[item].value;
          }
        }
        $http
          .patch(
            appConfig.dashboardServiceUrl +
              `api/users.json?token=${authService.token}`,
            { user: data }
          )
          .then(function (res) {
            if (res.status !== 200) {
            }
          });
        vm.editFlag = false;
      }
    };

    vm.editProfile = function () {
      for (let key in vm.indexes) {
        if (vm.indexes[key] < 0) {
          vm.data[key].value = vm[key][0];
        }
      }
      vm.editFlag = true;
    };
    
    vm.subscriptionCancel = function () {
      const param = {
        token: authService.getHeaderConfig().headers.Authorizing
      }
      $http.post(appConfig.dashboardServiceUrl +
        `api/subscriptions/subscriptions/cancel`,param).then(function (res) {
          if (res.status === 200) {
            if(res.data.success){
              modalService.showModal(9, null, res.data.message);
            }
            if(!res.data.success){
              modalService.showModal(9, null, res.data.error);
            }
            vm.init();
          }
        });
    }

    vm.goInviteUser = function () {
      vm.intInviteUser();
      vm.inviteList = false;
    }

    vm.intInviteUser = function () {
      vm.inviteUser = {
        first_name: {
          value: '',
          required: true,
          name: "first name",
          type: "provide",
        },
        last_name: {
          value: '',
          required: true,
          name: "last name",
          type: "provide",
        },
        email: {
          value: '',
          required: true,
          name: "email",
          type: "provide",
        },
      };
    };

    vm.deleteMember = function (item) {
        paymentService.deleteInviteMember(item.id).then((data) => {
          vm.getMemberList();
        });
    }

    vm.sendInvite = function () {
      if (dataValidate.validate(vm.inviteUser)) {
        const data = {};
        for (let item in vm.inviteUser) {
          data[item] = vm.inviteUser[item].value;
        }
        const param = { "user": data };
        paymentService.createInviteMember(param).then((data) => {
          vm.inviteList = true;
          vm.getMemberList();
        });
      }
    };

    vm.backToList = function () {
      vm.inviteList = true;
    }
  },
});
