angular
  .module('app')
  .component('privacyComponent', {
    templateUrl: 'app/components/privacy-policy/privacy-policy.tmpl.html',
    controller: function ($http, appConfig) {
      const vm = this;
      vm.init = function () {
        $http.get(appConfig.dashboardServiceUrl + 'api/pages/privacy-policy.json')
          .then(function (res) {
            if (res && res.data) {
              vm.pageData = res.data;
            }
          });
      };
    }
  });
