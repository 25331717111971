angular
  .module("app")
  .service("searchMenuRepository",
    function (
      httpService,
      $q,
      appConfig,
      authService
    ) {
      const self = this;
      let cachedMain = null;
      let cachedMainDeffered = null;
      this.cachedMainBranding = null;
      this.cachedMainLegal = null;
      this.cachedMainAuto = null;
      this.cachedMainFashion = null;

      const getMainNoParams = function (callback) {
        if (cachedMain) {
          callback(cachedMain);
        } else if (cachedMainDeffered == null) {
          cachedMainDeffered = $q.defer();
          cachedMainDeffered.promise.then(function (data) {
            callback(data);
          });

          return httpService
            .get(appConfig.webServiceUrl + "main.json", {
              params: { token: authService.token },
            })
            .then(function (data) {
              cachedMain = data.data;
              cachedMainDeffered.resolve(data.data);
              return data.data;
            });
        } else {
          cachedMainDeffered.promise.then(function (data) {
            callback(data);
          });
        }
      };

      const getMainWithParams = function () {
        const params = { token: authService.token };

        const mainParams = appConfig.repositories.mainParams;
        const count = mainParams.length;
        for (let i = 0; i < count; i++) {
          if (arguments[i] != null && arguments[i] != undefined) {
            params[mainParams[i]] = arguments[i];
          }
        }

        return httpService
          .get(appConfig.webServiceUrl + "main.json", { params: params })
          .then(function (data) {
            return data.data;
          });
      };

      this.getControlsData = function (params, ignoreCache) {
        return $q(function (resolve, reject) {
          if (self.cachedMainFashion !== null && !ignoreCache) {
            return resolve(self.cachedMainFashion);
          }

          const items = Object.keys(params);
          const query = new URLSearchParams();
          items.forEach((key) => {
            query.append(`q[${key}]`, params[key]);
          });

          httpService
            .get(
              appConfig.dashboardServiceUrl +
              "api/fashion/collections.json?" +
              query.toString(),
              authService.getHeaderConfig()
            )
            .then(function (data) {
              self.cachedMainFashion = data.data;
              data.data.years = data.data.years.reverse();
              angular.forEach(data.data, function (item, key) {
                const upperKey = key.toUpperCase();
                if (key !== "paginate") {
                  item.unshift(
                    key === "color_standards"
                      ? { title: "COLORS" + " (All)", id: "" }
                      : { name: upperKey + " (All)", id: "" }
                  );
                }
                if (key === "years") {
                  data.data.years = data.data.years.map((item, i) =>
                    i !== 0 ? { id: item, name: item } : item
                  );
                }
              });
              return resolve(data.data);
            });
        });
      };

      this.getControlsDataAuto = function (params) {
        return $q(function (resolve, reject) {
          if (self.cachedMainAuto !== null) {
            return resolve(self.cachedMainAuto);
          }

          httpService
            .get(appConfig.autoServiceUrl + "main.json", { params: params })
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getControlsDataLegal = function (params) {
        return $q(function (resolve, reject) {
          if (self.cachedMainLegal !== null) {
            return resolve(self.cachedMainLegal);
          }

          httpService
            .get(appConfig.legalServiceUrl + "main.json", { params: params })
            .then(function (data) {
              self.cachedMainLegal = data.data;
              angular.forEach(data.data, function (item, key) {
                var upperKey = key.toUpperCase();
                if (key !== "tooltips" && key !== "manufacturers") {
                  item.unshift({ title: upperKey + " (All)", id: "" });
                } else if (key === "manufacturers") {
                  item.unshift({ title: "BRANDS (All)", id: "" });
                }
              });
              return resolve(data.data);
            });
        });
      };

      this.getBrandingItems = function (type, query) {
        // type : company | industry | attribute | color
        const endpoint = `${appConfig.brandingServiceUrlNewApi}/${type}.json?q[title_cont]=${query}`;
        return httpService.get(endpoint).then((res) => res.data[type]);
      };

      this.getControlsDataBranding = function (params) {
        return $q(function (resolve, reject) {
          httpService
            .get(appConfig.brandingServiceUrl + "main.json", { params: params })
            .then(function (data) {
              self.cachedMainBranding = data.data;
              angular.forEach(data.data, function (item, key) {
                var upperKey = key.toUpperCase();
                if (key !== "tooltips" && key !== "manufacturers") {
                  item.unshift({ title: upperKey + " (All)", id: "" });
                } else if (key === "manufacturers") {
                  item.unshift({ title: "BRANDS (All)", id: "" });
                }
              });
              return resolve(data.data);
            });
        });
      };

      this.getControlsDataBrandingBind = function (control, id, params) {
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.brandingServiceUrl +
              control +
              "/logo_colors_search/" +
              id +
              ".json",
              { params: params }
            )
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getControlsDataBrandingBindNewApi = function (control, id, params) {
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.brandingServiceUrlNewApi + "industries/" + id + ".json",
              { params: params }
            )
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getControlsDataBrandingChart = function (detailcontrol, id, params) {
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.brandingServiceUrl +
              detailcontrol +
              "/top_colors/" +
              id +
              ".json",
              { params: params }
            )
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getControlsDataBrandingChartNewApi = function (
        detailcontrol,
        id,
        params
      ) {
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.brandingServiceUrlNewApi + "industries/" + id + ".json",
              { params: params }
            )
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getFashionChartsDataByColorNode = function (id,year,node) { 
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "colors/" + id + ".json",{
                params: { token: authService.token, year: year, node: node },})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getFashionChartsDataByColorId = function (id,year) { 
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "colors/" + id + ".json",{
                params: { token: authService.token, year: year },})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getFashionChartsDataTopFourColor = function (id,year, letter, flag) { 
        let url;
        if (flag === 'color'){
          url = {
            token: authService.token,
            'collection[year]': year,
            'designer[letter]': letter,
            color_id: id
          };
        } else if (flag === 'category') {
          url = {
            token: authService.token,
            'collection[year]': year,
            'designer[letter]': letter,
            'collection[category]': id
          };
        } else if (flag === 'season') {
          url = {
            token: authService.token,
            'collection[year]': year,
            'designer[letter]': letter,
            'collection[season]': id
          };
        } else if (flag === 'region') {
          url = {
            token: authService.token,
            'collection[year]': year,
            'designer[letter]': letter,
            'collection[city_id]': id
          };
        } else if (flag === 'year') {
          url = {
            token: authService.token,
            'collection[year]': id,
            'designer[letter]': letter,
          };
        }
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "designers/top_4/",{
                params: url})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      }

      this.getFashionCombinationColor = function (id, designer_id, year, flag) { 
        let url = {
          token: authService.token,
          'designer_id': designer_id,
          'year': year,
          'node': flag,
        };
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "colors/"+ id +"/combination_colors.json?",{
                params: url})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      }

      this.getFashionCombinationYearColor = function (id, year, cate, flag) { 
        let url = {
          token: authService.token,
          'year': year,
          'node': flag,
          'category': cate.toLowerCase()
        };
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "colors/"+ id +"/combination_colors.json?",{
                params: url})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      }

      this.getFashionChartsDataByCategoryId = function (id,year) { 
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "categories/" + id + ".json",{
                params: { token: authService.token, year: year },})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getFashionChartsDataByCategoryNode = function (id,year,node) { 
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "categories/" + id + ".json",{
                params: { token: authService.token, year: year, node:node },})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getFashionChartsDataBySeasonId = function (id,year) { 
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "seasons/" + id + ".json",{
                params: { token: authService.token, year: year },})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getFashionChartsDataBySeasonNode = function (id,year,node) { 
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "seasons/" + id + ".json",{
                params: { token: authService.token, year: year, node:node },})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getFashionChartsDataByDesignerId = function (id, year) { 
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "designers/" + id + ".json",{
                params: { token: authService.token, year: year },})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getFashionChartsDataByDesignerNode = function (id, year, node) { 
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "designers/" + id + ".json",{
                params: { token: authService.token, year: year, node:node },})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getFashionChartsDataByRegionId = function (id, year) { 
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "cities/" + id + ".json",{
                params: { token: authService.token, year: year },})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getFashionChartsDataByRegionNode = function (id, year, node) { 
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "cities/" + id + ".json",{
                params: { token: authService.token, year: year, node:node },})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getFashionChartsDataByYearId = function (id) { 
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "years/" + id + ".json",{
                params: { token: authService.token },})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getFashionChartsDataByYearNode = function (id, node) { 
        return $q(function (resolve, reject) {
          httpService
            .get(
              appConfig.colorsServiceUrlNewApi + "years/" + id + ".json",{
                params: { token: authService.token, node:node },})
            .then(function (data) {
              return resolve(data.data);
            });
        });
      };

      this.getMain = function () {
        var lastArgIndex = arguments.length - 1;

        if (_.isFunction(arguments[0])) {
          getMainNoParams(arguments[0]);
        } else if (
          _.isFunction(arguments[lastArgIndex]) &&
          _.every(arguments, function (item, index) {
            return item == null || index == lastArgIndex;
          })
        ) {
          getMainNoParams(arguments[lastArgIndex]);
        } else {
          return getMainWithParams.apply(this, arguments);
        }
      };
    }
  );
