angular.module('app').directive('infographicsForPharma', [
  '$location',
  '$timeout',
  'customInfographicsConstants',
  'pharmaDashboardRepository',
  '$interpolate',
  function (
    $location,
    timeout,
    customInfographicsConstants,
    pharmaDashboardRepository,
    $interpolate
  ) {
    function link(scope) {
      scope.currentYear = moment().year();
      scope.meta = {};
      scope.isFilterVisible = customInfographicsConstants.isFilterVisible;

      scope.pharmaSelectedColor = {
        title: "Pink",
        hex: "#e091b5",
      };
      scope.pharmaCompanyColorData =
          customInfographicsConstants.pharmaCompanyColorData;
      scope.pillShapeColorData =
          customInfographicsConstants.pillShapeColorData;
      scope.colurUseDataByPillShape =
          customInfographicsConstants.colurUseDataByPillShape;

      scope.colorsForPharma = [
        { id: "all", title: "COLOR"},
        { id: 0, title: "red", hex: "#f70000" },
        { id: 1, title: "orange", hex: "#f77a00" },
        { id: 2, title: "yellow", hex: "#ebd01c" },
        { id: 3, title: "green", hex: "#037b03" },
        { id: 4, title: "turquoise", hex: "#114394" },
        { id: 5, title: "blue", hex: "#e091b5" },
        { id: 6, title: "purple", hex: "#5f3e19" },
      ];

      scope.shapesForPharma = [];

      scope.chartsForPharma = [
        {
          qNumber: "PHARMA1",
          title: "COLOR USE FOR DIAMOND SHAPE PILLS",
          id: "colorUseForDiamondPillShape",
          filters: {
            shapeForPharma: true,
          },
        },
        {
          qNumber: "PHARMA2",
          title: "TOP 30 PHARMACEUTICAL COMPANIES THAT USE (COLOR)",
          id: "top30PharmaCompanyUse",
          filters: {
            colorForPharma: true,
          },
          api: function () {
            return pharmaDashboardRepository
                .getSettings()
                .then((result) => {
                  scope.filtersForPharma.color = result.colors;
                  return result;
                });
          },
        },
        {
          qNumber: "PHARMA3",
          title: "USE OF (COLOR) ACROSS THERAPEUTIC AREAS",
          id: "useAcrossTherapeuticArea",
          filters: {
            colorForPharma: true,
          },
          api: function () {
            return pharmaDashboardRepository
                .getSettings()
                .then((result) => {
                  scope.filtersForPharma.color = result.colors;
                  return result;
                });
          },
        },
        {
          qNumber: "PHARMA4",
          title: "USE OF (COLOR) BY PILL SHAPE",
          id: "useByPillShape",
          filters: {
            colorForPharma: true,
          },
          api: function () {
            return pharmaDashboardRepository
                .getSettings()
                .then((result) => {
                  scope.filtersForPharma.color = result.colors;
                  return result;
                });
          },
        },
      ];

      scope.currentChartForPharma = scope.chartsForPharma[0];
      scope.currentShapeForPharma = scope.shapesForPharma[0];
      scope.currentColorForPharma = scope.colorsForPharma[0];

      scope.updateColor = function (color) {
        scope.currentColorForPharma = color;
      };
      scope.updateChart = function (chart) {
        scope.currentChartForPharma = chart;
      };
      scope.updateShape = function (shape) {
        scope.currentShapeForPharma = shape;
      };

      function prepareTitle(title) {
        if (!title) {
          return '';
        }
        const exp = $interpolate(title);
        return exp(scope);
      }

      pharmaDashboardRepository.getSettings().then(res => {
        scope.shapesForPharma = res.shapes;
        scope.currentShapeForPharma = scope.shapesForPharma[0];
      });

      function loadData(newV, oldV) {
        loading.currentRequestId = Math.random();

        loading.filtersReady.then(function () {
          scope.model = null;

          (function () {
            if (scope.currentChartForPharma.api) {
              scope.currentChartForPharma.api().then((result) => {
                scope.model = result;
              });
            }
            scope.titleForPharma = prepareTitle(scope.currentChartForPharma.title);
          })(loading.currentRequestId);
        });
      }

      scope.$watch(
          "[scope.currentChartForPharma]",
          (newValue, oldValue) => {
            if (!_.isEqual(newValue, oldValue)) {
              loadData(newValue, oldValue);
            }
          },
          true
      );

      scope.refresh = () => {};

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
          result += name.toUpperCase() + ".";

        return result + date + type;
      };

      scope.exportJpgThroughDomToImage = (name) => {
        let node = angular.element("#pharmaCapture")[0];
        
        const titles = angular.element(".graphic-titles");
        const header = angular.element(".graphic-titles-export");
        const footer = angular.element(".customized-infographics-footer");

        footer.css({ display: "block" });
        header.css({ display: "block" });
        titles.css({ display: "none" });
        $("html").addClass("hide-scrollbar");

        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            const img = new Image();
            img.src = dataUrl;

            const link = document.createElement("a");
            link.href = img.src;
            link.download = scope.generateImgName(".png", "brand", name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            $("html").removeClass("hide-scrollbar");
            footer.css({ display: "none" });
            header.css({ display: "none" });
            titles.css({ display: "block" });
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      };
    }

    return {
      restrict: 'E',
      templateUrl:
        'app/components/customized-infographics/infographicsForPharma/infographicsForPharma.html',
      link: link,
      scope: {
      }
    };
  }
]);
