angular.module("app").directive("fashionYearColorAndFrequencyData", () => {
  let link = (scope) => {
    scope.$watch("data", (newValue) => {
      if (newValue.length !== 0) {
        bindData();
      }
    });
    scope.regions = [
      "All",
      "Europe",
      "North America",
      "South America",
      "Asia",
      "Australia"
    ];

    
    scope.isSelectedRegion = (region) => {
      return scope.selectedRegion === region;
    };
    scope.selectRegion = (region) => {
      scope.selectedRegion = region;
      scope.data.map(obj => {
        if(obj.region.toLowerCase() === scope.selectedRegion.toLowerCase()){
          scope.selectedData = obj.colors;
          scope.RBGData = obj.chart_colors;
          scope.RBGData = _.sortBy(
            _.map(scope.RBGData, function (d) {
              d.__order = Math.random();
              return d;
            }),
            "__order"
          );
        }
      })
    };
    

    function bindData() {
      scope.selectedRegion = scope.regions[0];
      scope.selectedData = scope.data[0].colors;
      scope.RBGData = scope.data[0].chart_colors;
      scope.RBGData = _.sortBy(
        _.map(scope.RBGData, function (d) {
          d.__order = Math.random();
          return d;
        }),
        "__order"
      );
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbFashionYearColorAndFrequencyData/dbFashionYearColorAndFrequencyDataView.html",
    link: link,
    scope: {
      data: "=",
      rgbData: "=",
    }
  };
});
