angular.module('app').directive('colorTrademarksAcrossBar', () => {
  let link = (scope) => {
    scope.$watch("colorData", (newValue) => {
      if (newValue) {
        scope.colorData = newValue;
      }
    });

    scope.heightDynamic = percent => {
      if (percent == 0){
        return 0
      } else if(percent > 0 && percent <= 1){
        return 10;
      } else if(percent > 1 && percent <= 2){
        return 20;
      } else if(percent > 2 && percent <= 3){
        return 30;
      } else if(percent > 3 && percent <= 4){
        return 40;
      } else if(percent > 4 && percent <= 5){
        return 50;
      } else if(percent > 5 && percent <= 6){
        return 60;
      } else if(percent > 6 && percent <= 7){
        return 70;
      } else if(percent > 7 && percent <= 8){
        return 80;
      } else if(percent > 8 && percent <= 9){
        return 90;
      } else if(percent > 9 && percent <= 10){
        return 100;
      } else if(percent > 25){
        return 100;
      }
    }

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorTrademarksAcrossBar/dbColorTrademarksAcrossBarView.html",
    link: link,
    scope: {
      colorData: "=",
      mode: "=",
      selectedColor: "=",
    }
  };
});
