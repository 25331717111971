angular.module("app").directive("colorPharmaCompanyProgress", () => {
  let link = (scope) => {
    scope.colorbarWidth = scope.item.percentage * 0.8;
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/colorPharmaCompanyProgress/colorPharmaCompanyProgress.html",
    link: link,
    scope: {
      item: "=",
      progressColor: "="
    }
  };
});
