angular.module("app").directive("colorStudyModalDetail", (researchIndexService) => {
  let link = (scope) => {
    scope.btnText = "REMOVE STUDY"
    scope.allData = {
      data: scope.logodata,
      index: scope.index,
    };

    scope.notifyStudy = (item) => {
      if(scope.btnText === "REMOVE STUDY") {
        researchIndexService.sendNotifyEmail(item.id)
          .then(data => {
            if(data.status === "ok") {
              scope.btnText = "THANK YOU"
            }
          });
      }
    }

    scope.data = scope.logodata;

    scope.closeClickHandler = () => {
      scope.closefunction();
    };

    scope.shareFacebook = (url) => {
      window.open("https://www.facebook.com/sharer/sharer.php?u=" + url);
    };

    scope.shareTwitter = (url) => {
      window.open("https://twitter.com/home?status=" + url);
    };

    scope.sharePinterest = (url) => {
      window.open("https://pinterest.com/pin/create/button/?url=" + url);
    };

    scope.shareGooglePlus = (url) => {
      window.open("https://plus.google.com/share?url=" + url);
    };

    scope.shareInstagram = (url) => {
      window.open("https://instagram.com/?url=" + url);
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbStudiesContainingTheColor/dbColorStudyModalView.html",
    link: link,
    scope: {
      logodata: "=",
      index: "=",
      onClose: "&",
      closefunction: "=",
      modalTitle: "="
    },
  };
});
