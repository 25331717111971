angular.module("app").directive("gameContent", [
  "$timeout",
  "$window",
  (timeout, window) => {
    let link = (scope, element) => {
      scope.data = [];
      scope.showCollection = false;
      scope.showImageDetails = false;
      scope.saveItemImageDetails = false;
      scope.imageDetailsData = null;
      window.scr = scope;

      
      scope.itemHeight = () => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        return (scope.elemHeight =
          elements[0].clientWidth && elements[0].clientWidth + "px");
      };

      scope.itemHeight();

      $(window).resize(() => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        timeout(() => {
          return (scope.elemHeight = elements[0].clientWidth + "px");
        }, 10);
      });

      scope.toggleImageDetails = () => {
        scope.showImageDetails = !scope.showImageDetails;
      };

      scope.imageClickHandler = (pill, index) => {
        scope.imagedata = scope.collectionData[index];
        scope.index = index;
        scope.toggleImageDetails();
      };

      scope.$on("mood_state_item_chart", (data) => {
        let mainData = data.targetScope.data;
        let newParam = {};
        if(mainData) {
          const keys = ["VR GAMES", "GENRE", "YEAR", "CREATOR"];
          newParam = {
            [keys[0]]: mainData.name,
            [keys[1]]: mainData.genre,
            [keys[2]]: mainData.year,
            [keys[3]]:  mainData.creator
          }
        }
        let node = angular.element("#gameItemPopupCapture")[0];
        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            if(dataUrl) {
              scope.saveImageDetailsData = {
                base64: dataUrl,
                type: 'video',
                data: newParam
              };
            }
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
  
          let times = 2000;
          timeout(function () {
            if(scope.saveImageDetailsData) {
              scope.showImageDetails = false;
              scope.toggleSaveImageDetails();
            } else {
              timeout(function () {
                if(scope.saveImageDetailsData) {
                  scope.showImageDetails = false;
                  scope.toggleSaveImageDetails();
                } else {
                  timeout(function () {
                    if(scope.saveImageDetailsData) {
                      scope.showImageDetails = false;
                      scope.toggleSaveImageDetails();
                    }
                  }, times);
                }
              }, times);
            }
          }, times);
      });
  
      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
        result += name.toUpperCase() + ".";
        return result + date + type;
      };
  
      scope.toggleSaveImageDetails = () => {
        scope.saveItemImageDetails = !scope.saveItemImageDetails;
      };
  
      scope.$on("mood_state_item_chart_changed", () => {
        scope.toggleSaveImageDetails();
      });

      scope.$watch('collectionData', function (collectionData) {
        if(collectionData){
          scope.collectionData = collectionData;

        }
      });
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbGameContent/dbGameContentView.html",
      link: link,
      scope: {
        originalData: "=data",
        collectionData: "=",
        chart: "=",
      },
    };
  },
]);
