angular.module("app").directive("topFourColorsAcrossAllShowsInDatabase", ["$timeout", (timeout) => {
  let link = (scope) => {
    scope.showImageDetails = false;
    scope.saveItemImageDetails = false;
    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (index) => {
      scope.index = index;
      scope.toggleImageDetails();
    };

    scope.$on("mood_state_item_chart", (data) => {
      let mainData = data.targetScope.data[data.targetScope.index];
      let newParam = {};
      if(scope.mode !== 'fashionYearMode') {
        const keys = ["DESIGNER", "SHOWS"];
        newParam = {
          [keys[0]]: mainData.designer,
          [keys[1]]: mainData.shows,
        }
      } else {
        const keys = ["CITY", "YEAR"];
        newParam = {
          [keys[0]]: mainData.name,
          [keys[1]]:  mainData.year
        }
      }

      let node = angular.element("#fashionItemPopupCapture")[0];
      domtoimage
        .toPng(node, {
          bgcolor: "#fff",
        })
        .then((dataUrl) => {
          if(dataUrl) {
            scope.saveImageDetailsData = {
              base64: dataUrl,
              type: 'fashion',
              data: newParam
            };
          }
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });

        let times = 2000;
        timeout(function () {
          if(scope.saveImageDetailsData) {
            scope.showImageDetails = false;
            scope.toggleSaveImageDetails();
          } else {
            timeout(function () {
              if(scope.saveImageDetailsData) {
                scope.showImageDetails = false;
                scope.toggleSaveImageDetails();
              } else {
                timeout(function () {
                  if(scope.saveImageDetailsData) {
                    scope.showImageDetails = false;
                    scope.toggleSaveImageDetails();
                  }
                }, times);
              }
            }, times);
          }
        }, times);
    });

    scope.generateImgName = function (type, board, name) {
      let result = "";
      const date = moment().format("L");
      result += name.toUpperCase() + ".";
      return result + date + type;
    };

    scope.toggleSaveImageDetails = () => {
      scope.saveItemImageDetails = !scope.saveItemImageDetails;
    };

    scope.$on("mood_state_item_chart_changed", () => {
      scope.toggleSaveImageDetails();
    });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbTopFourColorsAcrossAllShowsInDatabase/dbTopFourColorsAcrossAllShowsInDatabaseView.html",
    link: link,
    scope: {
      data: "=",
      mode: "=",
    }
  };
}]);
