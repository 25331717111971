angular.module("app").directive("cityTopFourColorChartPopup", function () {
  function link(scope) {
    scope.$watch("data", bindData);

    function bindData() {
      const element = document.getElementById(`my_fashion_color_popup_${scope.mode}_dataviz`);
      if (element) {
        element.innerHTML = "";
      }

      let width = 250;
      let height = 250;
      const margin = 0;

      if (scope.type === 'year') {
        width = 220;
        height = 220;
      }
      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      const svg = d3
        .select(`#my_fashion_color_popup_${scope.mode}_dataviz`)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      // Create chart data
      const percentageData = {};
      const colorData = {};
      for (const item of scope.data.colors) { 
        colorData[item.name] = item.color;
        if(typeof(item.percentage) == "string" && item.percentage.indexOf('%') > -1) {
          percentageData[item.name] = item.percentage.replace('%','');
        } else {
          percentageData[item.name] = item.percentage;
        }
      }

      // Compute the position of each group on the pie:
      const pie = d3.layout
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value;
        });
      const data_ready = pie(d3.entries(percentageData));

      const data2 = {
        "color": 100,
      };

      const data_ready2 = pie(d3.entries(data2));

      // The arc generator
      const arc = d3.svg
        .arc()
        .innerRadius(radius * 0.85) // This is the size of the donut hole
        .outerRadius(radius * 0.95);

      const arc2 = d3.svg
        .arc()
        .innerRadius(radius * 0.85)
        .outerRadius(radius * 0.95);

      // Another arc that won't be drawn. Just for labels positioning
      const outerArc = d3.svg
        .arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      if(data_ready.length > 0){
        svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", function (d) {
          return colorData[d.data.key].hex;
        })
        .attr(
          "stroke", function (d) {
            return colorData[d.data.key].hex === "#ffffff" || colorData[d.data.key].hex === "#FFFFFF"
              ? "#E0E0DD"
              : colorData[d.data.key].hex;
          }
        )
        .style(
          "stroke-width",  function (d) {
            return colorData[d.data.key].hex === "#ffffff" || colorData[d.data.key].hex === "#FFFFFF"
              ? "0.5px"
              : "0px"
          }
        );
      } else {
        svg
        .selectAll("allSlices")
        .data(data_ready2)
        .enter()
        .append("path")
        .attr("d", arc2)
        .attr("stroke", "#E0E0DD")
        .style("stroke-width", "1px")
        .attr("fill", "none");
      }

      if (scope.data.svg) {
        svg
          .append("g")
          .attr("transform", "translate(-25, -70)")
          .append("svg")
          .html(scope.data.svg)
          .attr("width", 50)
          .attr("height", 50)
          .attr("text-anchor", "middle")

        svg
          .append("text")
          .text(scope.data.name)
          .attr("transform", "translate(0, 5)")
          .attr("font-size", "18px")
          .attr("text-anchor", "middle")
          .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase;");

        svg
          .append("text")
          .text(scope.data.designerCount)
          .attr("transform", "translate(-37, 33)")
          .attr("font-size", "16px")
          .attr("text-anchor", "middle")
          .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase;");

        svg
          .append("text")
          .text("DESIGNERS")
          .attr("transform", "translate(-37, 45)")
          .attr("font-size", "12px")
          .attr("text-anchor", "middle")
          .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase;");

        svg
          .append("text")
          .text(scope.data.colorCount)
          .attr("transform", "translate(37, 33)")
          .attr("font-size", "16px")
          .attr("text-anchor", "middle")
          .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase;");

        svg
          .append("text")
          .text("COLORS")
          .attr("transform", "translate(37, 45)")
          .attr("font-size", "12px")
          .attr("text-anchor", "middle")
          .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase;");
      }
      else {
      svg
        .append("text")
        .text(scope.data.name)
        .attr("transform", "translate(0, -15)")
        .attr("font-size", "18px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase;");

      svg
        .append("text")
        .text(scope.data.designerCount)
        .attr("transform", "translate(-37, 20)")
        .attr("font-size", "16px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase;");
      svg
        .append("text")
        .text("DESIGNERS")
        .attr("transform", "translate(-37, 33)")
        .attr("font-size", "12px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase;");

      svg
        .append("text")
        .text(scope.data.colorCount)
        .attr("transform", "translate(37, 20)")
        .attr("font-size", "16px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase;");
      svg
        .append("text")
        .text("COLORS")
        .attr("transform", "translate(37, 33)")
        .attr("font-size", "12px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase;");
    }

  }
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/cityTopFourColorChartPopup/cityTopFourColorChartPopupView.html",
    link: link,
    scope: {
      data: "=",
      mode: "=",
      type: "="
    },
  };
});
