angular.module('app').directive('scatter3dChart', [
  "$location",
  ($location) => {
  let link = (scope) => {
    scope.selectedColors = [];

    scope.params = $location.search();
    scope.searchWord = scope.params.color;

    scope.showImageDetails = false;
    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    if (scope.data && scope.data.length > 0) {
      scope.selectedColors = [...scope.data];
    }

    scope.selectColor = (index) => {
      scope.index = index;
      scope.toggleImageDetails();
    };

    Plotly.d3.csv('', function () {
      function unpack(value) {
        return [...new Array(100)].map((_, index) => {
          const val = value + (Math.random() * 100);
          return val > 255 ? 255 : val;
        });
      }

      let traces = [];

      scope.data.map(item => {
        traces.push({
          x: unpack(item.R),
          y: unpack(item.G),
          z: unpack(item.B),
          mode: 'markers',
          marker: {
            size: 5,
            line: {
              color: `rgba(${item.R}, ${item.G}, ${item.B}, 0.14)`,
              width: 0.5
            },
            opacity: 0.8
          },
          type: 'scatter3d'
        });
      });

      var layout = {
        dragmode: false,
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 0
        }};
      Plotly.newPlot(`scatter_chart_${scope.chartId}_dataviz`, traces, layout, {showSendToCloud: true});
    });
  };
  return {
    restrict: 'E',
    templateUrl: 'app/directives/scatter3dChart/scatter3dChart.html',
    link: link,
    scope: {
      data: "=",
      chartId: "=",
    }
  };
}]);