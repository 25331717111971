angular.module("app").component("pricingComponent", {
  templateUrl: "app/components/pricing/pricing.tmpl.html",
  controller: function (
    localStorageService,
    $scope,
    $state,
    $http,
    appConfig,
    dataValidate,
    modalService,
    paymentService
  ) {
    const vm = this;
    vm.annuallyMode = true;
    vm.showSignUpModal = false;
    vm.currentUserPlan = undefined;
    vm.isSource = undefined;

    vm.stripePubKey = null;
    paymentService.getSubscription().then((data) => {
      vm.stripePubKey = data.publishable_key;
      vm.planPrices = data.prices;
    });

    if (!angular.equals(localStorageService.get("currentUser"), {})) {
      vm.isSource = localStorageService.get("currentUser").is_source;
      paymentService.getCurrentPlan().then((data) => {
        if (angular.equals(data, {})) {
          vm.currentUserPlan = undefined;
        } else {
          vm.currentUserPlan = data;
        }
      });
    }

    vm.dataForm = {
      first_name: {
        value: "",
        required: true,
        name: "first name",
        type: "provide",
      },
      last_name: {
        value: "",
        required: true,
        name: "last name",
        type: "provide",
      },
      title: { value: "", name: "title", type: "" },
      company: {
        value: "",
        name: "company name",
        type: "provide",
      },
      email: {
        value: "",
        required: true,
        name: "email",
        type: "provide",
      },
      condition: {
        value: true,
        required: false,
        name: "condition",
        type: "provide",
      },
    };

    vm.toggleSignUpDetails = function (item) {
      vm.selectedFreePlan = item;
      vm.showSignUpModal = !vm.showSignUpModal;
    };

    vm.getCurrentPlan = function () {
      if (!angular.equals(localStorageService.get("currentUser"), {})) {
        paymentService.getCurrentPlan().then((data) => {
          vm.currentUserPlan = data;
        });
      }
    };

    vm.signUp = function (data) {
      if (vm.validate(data)) {
        const data = {};
        for (const item in this.data) {
          data[item] = this.data[item].value;
        }
        delete data["condition"];
        $http
          .post(
            appConfig.dashboardServiceUrl +
              "api/subscriptions/subscriptions/subscribed",
            {
              user: data,
              plan_id: vm.annuallyMode
                ? vm.selectedFreePlan.yearlyId
                : vm.selectedFreePlan.monthlyId,
            }
          )
          .then((res) => {
            vm.showSignUpModal = !vm.showSignUpModal;
            $state.go("thank-you", { free: true });
          })
          .catch((error) => {
            if (error.status === 422) {
              vm.serverError = Object.keys(error.data.errors).map((key) => {
                return `Field ${key} ${error.data.errors[key].join(" ")}`;
              });
            }
          });
      } else {
        vm.showSignUpModal = !vm.showSignUpModal;
      }
    };

    vm.validate = function (data) {
      var EMAIL_REGEXP =
        /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
      var errorMessage = [];
      var types = {
        provide: "*Please provide Your ",
        select: "*Please select ",
        enter: "*Please enter Your ",
        numeric: "*Must be number: ",
        both: "*Please enter or select Your ",
      };

      for (var item in data) {
        if (data[item].required) {
          if (
            data[item].value === "" ||
            !data[item].value ||
            (data[item].type === "select" && data[item].value.id === null) ||
            (data[item].type === "both" &&
              (data[item].value === "" ||
                data[item].value ===
                  'STATE/PROVINCE <span class="red-text">*</span>'))
          ) {
            var type = types[data[item].type] || "*Please enter ";
            errorMessage.push(type + data[item].name);
          } else if (data[item].name.includes("email")) {
            var isMatchRegex = EMAIL_REGEXP.test(data[item].value);
            if (!isMatchRegex) {
              errorMessage.push("*Please enter valid " + data[item].name);
            }
          } else if (data[item].type === "numeric") {
            if (
              !(
                !isNaN(parseFloat(data[item].value)) &&
                isFinite(data[item].value)
              )
            ) {
              errorMessage.push(types.numeric + data[item].name);
            }
          }
        }
      }
      if (errorMessage.length !== 0) {
        modalService.showModal(0, null, errorMessage);
        return false;
      }
      return true;
    };

    vm.pricings = [
      {
        type: "pro",
        description: "Professionals Working Extensively With Color",
        monthlyPrice: 33,
        totalPrice: 396,
        annuallyPrice: 30,
        billedPrice: 360,
        features: [
          {
            name: "Color Data - Unlimited",
            img_url: "/assets/img/pricing/feature/colorData.png",
          },
          {
            name: "Color Indexes - Limited",
            img_url: "/assets/img/pricing/feature/colorIndexes.png",
          },
          {
            name: "Infographics",
            img_url: "/assets/img/pricing/feature/infographics.png",
          },
          {
            name: "Custom Analytics",
            img_url: "/assets/img/pricing/feature/customInfographics.png",
          },
          {
            name: "My Boards",
            img_url: "/assets/img/pricing/feature/myBoard.png",
          },
        ],
      },
      {
        type: "teams",
        description: "Teams And Agencies Of Up To 10 Members",
        monthlyPrice: 275,
        totalPrice: 3300,
        annuallyPrice: 250,
        billedPrice: 3300,
        features: [
          {
            name: "Reports - Included / Discounted",
            img_url: "/assets/img/pricing/feature/report.png",
          },
        ],
      },
      {
        type: "enterprise",
        description:
          "Companies W/ Large Design Innovation Units, And Academic Programs",
        features: [
          {
            name: "Reports - Included / Discounted",
            img_url: "/assets/img/pricing/feature/report.png",
          },
          {
            name: "Account Support",
            img_url: "/assets/img/pricing/feature/account.png",
          },
          {
            name: "Api Access - Optional",
            img_url: "/assets/img/pricing/feature/api.png",
          },
        ],
      },
    ];

    paymentService.getPlan().then((data) => {
      data.forEach((element) => {
        if (element.plan_type === "monthly") {
          element.plans.forEach((obj) => {
            if (obj.name === "pro-monthly") {
              vm.pricings[0].monthlyId = obj.id;
              vm.pricings[0].discount = obj.discount;
              vm.pricings[0].monthlyPrice = obj.price;
              vm.pricings[0].totalPrice = obj.yearly_price;
            } else if (obj.name === "team-monthly") {
              vm.pricings[1].monthlyId = obj.id;
              vm.pricings[1].discount = obj.discount;
              vm.pricings[1].monthlyPrice = obj.price;
              vm.pricings[1].totalPrice = obj.yearly_price;
            }
          });
        } else if (element.plan_type === "yearly") {
          element.plans.forEach((obj) => {
            if (obj.name === "pro-yearly") {
              vm.pricings[0].yearlyId = obj.id;
              vm.pricings[0].discount = obj.discount;
              vm.pricings[0].annuallyPrice = obj.price;
              vm.pricings[0].billedPrice = obj.yearly_price;
            } else if (obj.name === "team-yearly") {
              vm.pricings[1].yearlyId = obj.id;
              vm.pricings[1].discount = obj.discount;
              vm.pricings[1].annuallyPrice = obj.price;
              vm.pricings[1].billedPrice = obj.yearly_price;
            }
          });
        }
      });
    });

    vm.pricingQuestions = [
      {
        id: 0,
        question: "What is HueData 14-day satisfaction guarantee?",
        answer: `You can cancel your subscription within the first 14 days and receive a full refund. No questions asked! If you cancel within the first 14 days, your plan is canceled immediately and you can no longer make use of the plan features. <br>Please read the policy terms below:<br>
          <ul>
          <li>The 14 day trial period includes the first 14 days after subscribing to any paid plan.</li>
          <li>A credit card is required for the 14-day trial. During your trial, you will be have access to
          all features including in your subscription level. After your trial expires, your credit card
          on file will be charged automatically unless you cancel your subscription before the 14
          days are up. Your billing cycle will begin at the end of your trial.</li>
          <li>If you upgrade or change from one paid plan to another, the new upgrade is not
          considered an “initial purchase" and won't be refunded unless you cancel your plan
          within the 14 day trial period of the original purchase.</li>
          </ul>
          Please allow up to 30 business days for your refund to appear on your credit card statement.`,
      },
      {
        id: 1,
        question: "How often does Huedata add new data?",
        answer:
          "We add new color data, analytics and insights all the time! In addition to tracking color daily on social media and the news, we also capture new releases of fashion color designs, new car colors, new logos’ colors, and newly added color trademarks as some examples of data enhancements. We also constantly grow Huedata’s span of color analytics and insights across new areas from color of earth to color of money indexes and everything in between.",
      },
      {
        id: 2,
        question: "Can I share my Huedata searches?",
        answer:
          "Yes of course - go for it! Share with colleagues and friends and let others enjoy the power of Huedata color analytics to inspire, inform and validate all color decisions.",
      },
      {
        id: 3,
        question: "Who owns the My Boards that I create?",
        answer:
          "You do! Your boards are yours though the assets featured on the boards belong with Huedata and/or are third party assets and you are required not to violate any copyrights associated with such assets.",
      },
      {
        id: 4,
        question: "Is the free plan really free?",
        answer:
          "It’s absolutely free and a great opportunity to evaluate whether Huedata analytics and insights are right for you. We just ask that for any Huedata materials you source on our site, please mention “Powered by Huedata” and link back to our website.",
      },
      {
        id: 5,
        question:
          "Do I need to provide my credit card when I sign up for the free plan?",
        answer:
          "No, you do not need to provide your credit card credentials in order to register for our free plan.",
      },
      {
        id: 6,
        question: "Am I going to be automatically charge every month? ",
        answer:
          "Yes, we’ll charge you automatically every month so that you don’t experience any interruption using our Huedata color analytics and insights platform. While we’ll be using auto-renewal of the subsection plan you signed up for, you can always cancel or upgrade your subscription as your needs change.",
      },
      {
        id: 7,
        question: "How can I cancel my plan?",
        answer:
          "While the Huedata subscription is non-refundable, you can always downgrade to our Free plan. Note that your current subscription plan will remain active until the end of the billing period. Log in to your profile and choose “Billing details” from the menu on the left in your dashboard. There’s a select menu with all the subscription plan options. Choose “Free ($0)” and click on the green “Update” button at the bottom of the page.",
      },
      {
        id: 8,
        question:
          "Will I lose access to My Boards if I cancel my subscription?",
        answer:
          "Yes, at the end of the billing period of your canceled subscription, you will no longer have access to to features previously included in your subscription, including the boards you created. Please make sure to download your personal boards prior to canceling your subscription.",
      },
      {
        id: 9,
        question: "Do you offer plans for corporate or schools ?",
        answer:
          "We offer tailored plans for companies and academic institutions with group access to the platform features as well as optional access to relevant segments of our proprietary color data. For further detail contact us here.",
      },
    ];
  },
});
