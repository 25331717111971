angular.module("app").directive("topFourColorsForCitiesInYear", () => {
  let link = (scope) => {
    scope.regions = [
      "All",
      "Europe",
      "North America",
      "South America",
      "Asia",
      "Australia"
    ];

    scope.selectedRegion = scope.regions[0];
    scope.isSelectedRegion = (region) => {
      return scope.selectedRegion === region;
    };
    scope.selectRegion = (region) => {
      scope.selectedRegion = region;
      scope.filteredChoices = [];
      if(region === 'All'){
        scope.data.map(obj => {
          scope.filteredChoices.push(obj);
        });
      } else {
        scope.data.map(obj => {
          if(obj.region.toLowerCase() === region.toLowerCase()){
            scope.filteredChoices.push(obj);
          }
        });
      }
    };
    scope.$watch('data', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.data = newValue;
        scope.filterItems();
      } else {
        scope.data = newValue;
        scope.filterItems();
      }
    });

    scope.filterItems = () => {
      scope.filteredChoices = [];
      if(scope.selectedRegion === 'All'){
        scope.data.map(obj => {
          scope.filteredChoices.push(obj);
        });
      }
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbTopFourColorsForCitiesInYear/dbTopFourColorsForCitiesInYearView.html",
    link: link,
    scope: {
      data: "="
    }
  };
});
