(function () {
  'use strict';
  angular.module('app').directive('hueChartCitiesByColorPeriod',
    ['common', 'config', 'chartsHelper',
      function (common, config, chartsHelper) {
        function link(scope, element) {
          scope.$watch('data', bindData);
          function bindData() {
            const options = {};
            options.layout = {
              padding: {
                left: 10,
                right: 10,
                top: 75,
                bottom: 0
              }
            };

            const container = chartsHelper.initContainer(element);

            const ch = new chartDotsWidth({
              data: scope.data || {},
              container: container[0],
              options: options
            });
          }
        }

        const directive = {
          link: link,
          restrict: 'E',
          replace: true,
          scope: {
            data: '='
          },
          templateUrl: 'app/directives/custom-infographics-directives/chart-cities-by-color-period.html'
        };

        return directive;

      }
    ]);
}());
