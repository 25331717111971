angular.module("app").directive("globalSentiments", () => {
  let link = (scope) => {
    scope.showImageDetails = false;

    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (index) => {
      scope.index = index;
      scope.toggleImageDetails();
    };

    scope.modalData = [
      {
        date: "Juventus",
        twitter: "349/7832",
        instagram: "228/1892",
        color: { hex: "#b7b61d" },
      }
    ];

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbGlobalColorData/globalDailyColor/globalSentiments/globalSentimentsView.html",
    link: link,
    scope: {
      sentimentData: "=",
    }
  };
});
