angular.module("app").directive("legalContent", [
  "$timeout",
  "$window",
  (timeout, window) => {
    let link = (scope, element) => {
      scope.data = [];
      scope.showCollection = false;
      scope.showImageDetails = false;
      scope.saveImageDetails = false;
      scope.imageDetailsData = null;
      window.scr = scope;
      scope.collectionDataList = [];

      scope.itemHeight = () => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        return (scope.elemHeight =
          elements[0].clientWidth && elements[0].clientWidth + "px");
      };

      scope.itemHeight();

      $(window).resize(() => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        timeout(() => {
          return (scope.elemHeight = elements[0].clientWidth + "px");
        }, 10);
      });

      scope.$on("mood_state", (data) => {
        scope.saveImageDetailsData = data.targetScope.data;
        scope.toggleImageDetails();
        scope.toggleSaveImageDetails();
      });

      scope.toggleSaveImageDetails = () => {
        scope.saveImageDetails = scope.saveImageDetails ? false : true;
      };

      scope.$on("mood_state_changed", () => {
        scope.toggleSaveImageDetails();
      });

      scope.toggleImageDetails = () => {
        scope.showImageDetails = !scope.showImageDetails;
      };

      scope.imageClickHandler = (legal, index) => {
        scope.imagedata = scope.legals[index];
        scope.index = index;
        scope.toggleImageDetails();
      };

      scope.isModeColor = () => {
        return scope.viewMode === "color";
      };

      scope.isModeYear = () => {
        return scope.viewMode === "year";
      };

      scope.isModeProduct = () => {
        return scope.viewMode === "productOrService";
      };

      scope.isModeBrand = () => {
        return scope.viewMode === "brand";
      };
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbLegalContent/dbLegalContentView.html",
      link: link,
      scope: {
        originalData: "=data",
        legals: "=",
        legals2: "=",
        selectedItem: "=",
        viewMode: "=",
      },
    };
  },
]);
