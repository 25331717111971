angular.module("app").controller("artController", [
  "$scope",
  "dashboardOverlayService",
  "searchMenuRepository",
  "$timeout",
  "charts",
  "$state",
  "$location",
  "modalService",
  "localStorageService",
  "artDashboardRepository",
  function (
    scope,
    dashboardOverlayService,
    searchMenuRepository,
    timeout,
    charts,
    $state,
    $location,
    modalService,
    localStorageService,
    artDashboardRepository
  ) {
    scope.menus = {
      artist: "",
      period: "",
      school: "",
      color: "",
    };

    scope.disabledControls = {
      artist: false,
      period: false,
      school: false,
      color: false,
    };

    scope.type = {
      artist: "artist",
      period: "period",
      school: "school",
      color: "color",
    };

    scope.artistList = [];
    scope.periodList = [];
    scope.showDropdown = false;

    scope.characters = [
      "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
      "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
    ];
    scope.selectedCharacter = "ALL";

    scope.characterSelect = (value) => {
      scope.selectedCharacter = value;
      scope.loadGraphics();
    };

    scope.isSelected = (value) => {
      return scope.selectedCharacter === value;
    };

    artDashboardRepository.getArtColor().then(function (data) {
      scope.colors = data.colors;
    });

    if(localStorageService.get("currentUser")){
      scope.user = localStorageService.get("currentUser");
    }

    scope.params = $location.search();

    scope.$watch('params', function (params) {
      if (params.control === 'searchMenu') {
        scope.init();
      } else {
        scope.handleChangeControl(params.control, params);
      }
    });

    scope.disabledInput = (type, value) => {
      switch (type) {
        case "artist":
          scope.disablePeriod = Boolean(value);
          scope.disableSchool = Boolean(value);
          break;
        case "period":
          scope.disableArtist = Boolean(value);
          scope.disableSchool = Boolean(value);
          break;
        case "school":
          scope.disableArtist = Boolean(value);
          scope.disablePeriod = Boolean(value);
          break;
      }
    };

    scope.mainParam = null;
    scope.mainParamId = null;
    scope.secondaryParams = {};
    scope.tab = 1;
    scope.currentPage = 0;

    scope.artist_place_holder = "SEARCH BY ARTIST";
    scope.period_place_holder = "SEARCH BY PERIOD";
    scope.school_place_holder = "SEARCH BY SCHOOL";

    scope.showDashboard = false;
    scope.title = scope.year;
    scope.subtitle = null;

    scope.isLoadingControls = true;

    // dummy data -> colors
    scope.schoolList = [
      {
        id: 1,
        name: 'All Schools'
      },
      {
        id: 2,
        name: 'american'
      },
      {
        id: 3,
        name: 'austrian'
      },
      {
        id: 4,
        name: 'belgian'
      },
      {
        id: 5,
        name: 'bohemian'
      },
      {
        id: 6,
        name: 'catalan'
      },
      {
        id: 7,
        name: 'danish'
      },
      {
        id: 8,
        name: 'dutch'
      },
      {
        id: 9,
        name: 'english'
      },
      {
        id: 10,
        name: 'finnish'
      },
      {
        id: 11,
        name: 'flemish'
      },
      {
        id: 12,
        name: 'french'
      },
      {
        id: 13,
        name: 'german'
      },
      {
        id: 14,
        name: 'greek'
      },
      {
        id: 15,
        name: 'hungarian'
      },
      {
        id: 16,
        name: 'irish'
      },
      {
        id: 17,
        name: 'italian'
      },
      {
        id: 18,
        name: 'netherlandish'
      },
      {
        id: 19,
        name: 'norwegian'
      },
      {
        id: 20,
        name: 'polish'
      },
      {
        id: 21,
        name: 'portugees'
      },
      {
        id: 22,
        name: 'russian'
      },
      {
        id: 23,
        name: 'scotish'
      },
      {
        id: 24,
        name: 'spanish'
      },
      {
        id: 25,
        name: 'swedish'
      },
      {
        id: 26,
        name: 'swiss'
      },
      {
        id: 27,
        name: 'other'
      }
    ]
    scope.selectedSchool = scope.schoolList[0];

    scope.updateSelectedSchool = function (school) {
      scope.selectedSchool = school;
      scope.loadGraphics();
      scope.setTab(1);
    };

    scope.getResultsOnFilterSearch = (type, str, page) => {
      scope.ajaxInProgress = true;  
      switch (type) {
        case 'artArtist':
          if (!str.length) {
            scope.artistList = [];
            scope.ajaxInProgress = false;
            return;
          }
          //new logic for pagination
          if(scope.oldsearchdata === ""){
            scope.oldsearchdata = str;
            scope.selectedpage = 1;
          } 
          else if(scope.oldsearchdata !== str){  
              page = 1;
              scope.selectedpage = page;
              scope.artistList = [];
              scope.oldsearchdata = str;
          } 
          else{
            scope.selectedpage += 1;
            page = scope.selectedpage;
          }

          artDashboardRepository.getArtArtist(str, page)
            .then(data => {
              scope.artistList = [];
              if(scope.artistList.length>0){  
                scope.artistList = scope.artistList.concat(data.artists);
              } else {
                scope.artistList = data.artists;
              }   
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break; 

          case 'artPeriod':
          if (!str.length) {
            scope.periodList = [];
            scope.ajaxInProgress = false;
            return;
          }
          //new logic for pagination
          if(scope.oldsearchdata === ""){
            scope.oldsearchdata = str;
            scope.selectedpage = 1;
          } 
          else if(scope.oldsearchdata !== str){  
              page = 1;
              scope.selectedpage = page;
              scope.periodList = [];
              scope.oldsearchdata = str;
          } 
          else{
            scope.selectedpage += 1;
            page = scope.selectedpage;
          }

          artDashboardRepository.getArtPeriods(str, page)
            .then(data => {
              scope.periodList = [];
              if(scope.periodList.length>0){  
                scope.periodList = scope.periodList.concat(data.periods);
              } else {
                scope.periodList = data.periods;
              }   
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break; 

        default:
          scope.ajaxInProgress = false;
      }
    }; 

    scope.artworkData = [];

    scope.setback = () => {
      $state.go("art", {control: "searchMenu", id: "", title: "", name: "", hex: ""});
    };

    scope.init = () => {
      scope.menus = {
        artist: "",
        period: "",
        school: "",
        color: "",
      };
      scope.disableArtist = false;
      scope.disablePeriod = false;
      scope.disableSchool = false;
      scope.isLoadingControls = true;
      scope.currentPage = 0;
      scope.mainSeason = [];
      scope.tab = 1;
    };

    scope.setTab = (tabId) => {
      scope.tab = tabId;
      scope.select_images = false;
      scope.selected_moodboard = false;
      scope.state = 1;
    };

    scope.isSet = (tabId) => {
      return scope.tab === tabId;
    };

    // searchMenuRepository.getControlsDataAuto().then(function (data) {
    //   scope.controlsData = data;
    // });

    scope.setColorAsMain = function (color) {
      scope.iconUrl = null;
      scope.secondaryParams = {};
      scope.mainParam = null;
      scope.tempColor = color;
      scope.menus = {
        artist: "",
        period: "",
        school: "",
        color: "",
      };
      scope.menus.color = color.id;
      if (
        !scope.controlsData.colors.find(function (item) {
          return item.id === scope.tempColor.id;
        })
      ) {
        scope.controlsData.colors.unshift(scope.tempColor);
      }
      scope.tempColor = null;
      scope.handleChangeControl("color");
      scope.loadGraphics();
    };

    scope.loadGraphics = function () {
      if (scope.mainParam) {
        scope.showDashboard = true;
        dashboardOverlayService.loadingStart(4000);

        if(localStorageService.get("currentUser")){
          scope.user = localStorageService.get("currentUser");
        }

        if (scope.mainParam === "artist") {
          scope.showDropdown = false;
          artDashboardRepository
            .getArtWork(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              scope.selectedCharacter
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.artworkData = data.artworks;
                scope.pageCount = data.pagination.total_pages;
              } 
            });
        } else if (scope.mainParam === "period") {
          scope.showDropdown = false;
          artDashboardRepository
            .getArtWork(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              scope.selectedCharacter
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.artworkData = data.artworks;
                scope.pageCount = data.pagination.total_pages;
              } 
            });

        } else if (scope.mainParam === "school") {
          scope.showDropdown = true;
          artDashboardRepository
            .getArtWork(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              scope.selectedCharacter
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.artworkData = data.artworks;
                scope.pageCount = data.pagination.total_pages;
              } 
            });

            scope.selectedSchool.name = scope.selectedFilterValue.title;
        } else if (scope.mainParam === "color") {
          scope.showDropdown = true;
          artDashboardRepository
            .getArtWork(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              scope.selectedCharacter,
              scope.selectedSchool
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.artworkData = data.artworks;
                scope.pageCount = data.pagination.total_pages;
              }
            });
        }

        scope.isLoadingControls = false;
      }
    };


    scope.setPage = (num) => {
      if (num >= 0 && num < scope.pageCount) {
        scope.currentPage = num;
        scope.loadGraphics();
      }
    };

    scope.handleChangeControl = function (control, item) {
      scope.header_title = () => {
        if (control === 'color') {
          return `${control}: ${item.name}`;
        } else if (control === 'school') {
          return `${control}:`;
        } else {
          return `${control}: ${item.title}`;
        }
      };
      scope.mainParam = control;
      scope.selectedFilterValue = item;
      scope.mainParamId = item.id;
      scope.searchMode = control;

      scope.loadGraphics();
    };

    scope.handleSearchChange = function (control, item) {
      if(item.name === 'medium yellow/green') {
        item.name = 'medium yellow - green'
      } else if(item.name === 'light yellow/green') {
        item.name = 'light yellow - green'
      } else if(item.name === 'dark yellow/green') {
        item.name = 'dark yellow - green'
      }
      $state.go("art", Object.assign({control: control}, item));
    };

    scope.$watch(
      () => {
        return dashboardOverlayService.showOverlay;
      },
      (newValue) => {
        scope.showDashboardOverlay = newValue;
      }
    );
  },
]);
