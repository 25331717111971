angular.module("app").component("staffLoginComponent", {
  templateUrl: "app/components/staff-login/staff-login.tmpl.html",
  controller: function (authService, appConfig, $state) {
    const self = this;
    self.buttonGoogleUrl = appConfig.dashboardServiceUrl + "auth/google_oauth2";
    this.email = "";
    this.password = "";
    this.isRemembered = false;
    this.error = "";

    this.login = () => {
      self.error = false;
      authService
        .login(this.email, this.password, this.isRemembered)
        .then((data) => {
          if (data && data.success === true) {
            $state.go("landing-page");
          } else {
            self.error = true;
          }
        });
    };
  },
});
