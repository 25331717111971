angular.module('app').directive('uniqueColorsUsedByArtistSchool', () => {
  let link = (scope) => {
    scope.acutalColorData = [];
    scope.isLoadedAllData = true;  
    scope.$watch('colorData', function (colorData) {
      if (colorData) {
        scope.colorData = scope.colorData
        bindData();
      }
    });

    function bindData() {
      if (scope.colorData.length > 120) {
        scope.acutalColorData = scope.colorData.slice(0, 120);
        scope.moreAcutalColorData = scope.colorData.slice(120);
        scope.isLoadedAllData = false;
      } else {
        scope.acutalColorData = scope.colorData;
        scope.isLoadedAllData = true;
      }
    }

    scope.loadMoreColorArtist = () => { 
      if (!scope.isLoadedAllData) {
        if (scope.moreAcutalColorData.length > 120) {
          scope.acutalColorData = scope.acutalColorData.concat(scope.moreAcutalColorData.slice(0, 120));
          scope.moreAcutalColorData = scope.moreAcutalColorData.slice(120);
          scope.isLoadedAllData = false;
        } else {
          scope.acutalColorData = scope.acutalColorData.concat(scope.moreAcutalColorData);
          scope.isLoadedAllData = true;
          scope.moreAcutalColorData = [];
        }
      }
    };

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorsUsedByArtistSchool/dbColorsUsedByArtistSchoolView.html",
    link: link,
    scope: {
      colorData: "=",
      mode: "=",
    }
  };
});
