angular.module("app").service("httpService", [
  "$http",
  "authService",
  "modalService",
  function (http, authService, modalService) {
    this.get = (url, config) => {
      return http.get(url, config)
        .then(res => res)
        .catch(error => {
          if (error.status === 403) {
            authService.logOut();
            modalService.showModal(6);
          } else if (error.status === 503) {
            const erorMessage = 'Something went wrong. Try again in a few minutes';
            modalService.showModal(10, null, erorMessage);
          }
        });
    };

    this.post = (url, data) => {
      return http.post(url, data)
        .then(res => res)
        .catch(error => {
          if (error.status === 403) {
            authService.logOut();
            modalService.showModal(6);
          } else if (error.status === 503) {
            const erorMessage = 'Something went wrong. Try again in a few minutes';
            modalService.showModal(10, null, erorMessage);
          }
        });
    };
  },
]);
