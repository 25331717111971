angular.module("app").component("appFooter", {
  templateUrl: "app/footer.html",
  controller: function (
    $state,
    $scope,
    $stateParams,
    scrollService,
    modalService,
    $http,
    appConfig,
    dataValidate
  ) {
    const self = this;
    self.permissions = {
      "Daily Insights": false,
      "Research Partner": false,
      "Education Offerings": false,
    };
    self.relationship = {
      "Expert Panelist": false,
    };

    self.currentYear = moment().year();

    self.shouldShowFooter = () =>
      [
        "landing-page",
        "infographics",
        "infographicsDetails",
        "colorEmotionStudy",
        "colorBlog",
        "contact",
        "partners",
        "press",
        "reports",
      ].includes($state.current.name);

    self.data = {
      email: { value: "", required: true, name: "email", type: "" },
    };

    self.modalUpdate = function (number) {
      modalService.showModal(number);
    };

    self.scroll = function () {
      scrollService.scrollMember();
    };

    self.submitEmail = function () {
      if (self.validate(this.data)) {
        const data = { email: this.data.email.value };
        $http
          .post(
            appConfig.dashboardServiceUrl + "api/forms/stay_connected.json",
            {
              contact: data,
            }
          )
          .then(function (res) {
            if (res.status === 201) {
              self.email = "";
              modalService.showModal(
                0,
                null,
                [
                  "Thank you for signing up! Welcome to the HUEDATA community! Check your inbox!",
                ],
                true
              );
            } else {
              modalService.showModal(0, null, ["Please try again"], false);
            }
          });
      }
    };

    self.validate = function (data) {
      var EMAIL_REGEXP = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
      var errorMessage = [];
  
      for (var item in data) {
        if (data[item].required) {
          if (data[item].name.includes('email')) {
            var isMatchRegex = EMAIL_REGEXP.test(data[item].value);
            if (!isMatchRegex) {
              errorMessage.push('*Please enter valid email');
            }
          }
        }
      }
      if (errorMessage.length !== 0) {
        modalService.showModal(0, null, errorMessage);
        return false;
      }
      return true;
    };

    self.hidePrefooter = function () {
      return (
        $state.current.name === "login" ||
        $state.current.name === "staffLogin" ||
        $state.current.name === "privacy" ||
        $state.current.name === "contact" ||
        $state.current.name === "terms" ||
        $state.current.name === "recover" ||
        $state.current.name === "password-recover" ||
        $state.current.name === "press" ||
        $state.current.name === "aboutUs"
      );
    };
  },
});
