angular.module("app").factory("researchIndexService",
  function (httpService, appConfig, authService) {
    return {
        getColorsList: function () {
            return httpService
                .get(`${appConfig.dashboardServiceUrl}api/research/search/colors`, {
                    params: { token: authService.token },
                }).then(function (attr) {
                    return attr.data;
            });
        },

        getBasicDetailsCount: function () {
            return httpService
                .get(`${appConfig.dashboardServiceUrl}api/research/search/basic_details_count`, {
                    params: { token: authService.token },
                }).then(function (attr) {
                    return attr.data;
            });
        },

        getResearchCategories: function() {
            return httpService.get(appConfig.dashboardServiceUrl + 'api/research/search/categories',  {
                params: { token: authService.token },
            }).then(function (data) {
                return data && data.data.categories ? data.data.categories : data;
            });
        },

        getResearchTopicAreas: function(str) {
            str = str.split(' ').join('+');
            return httpService.get(appConfig.dashboardServiceUrl + 'api/research/search/topic_areas?keyword=' + str,
              authService.getHeaderConfig()
            ).then(function (data) {
                return data && data.data.topic_areas ? data.data.topic_areas : data;
            });
        },

        getResearchResearchers: function(str) {
            str = str.split(' ').join('+');
            return httpService.get(appConfig.dashboardServiceUrl + 'api/research/search/researchers?last_name=' + str,
              authService.getHeaderConfig()
            ).then(function (data) {
                return data && data.data.researchers ? data.data.researchers : data;
            });
        },

        getResearchJournals: function(str) {
            str = str.split(' ').join('+');
            return httpService.get(appConfig.dashboardServiceUrl + 'api/research/search/journals?journal=' + str, {
                params: { token: authService.token },
            }).then(function (data) {
                return data && data.data.categories ? data.data.categories : data;
            });
        },

        //color
        getColorChartsDataByColorId: function (id,node) { 
            return httpService.get(appConfig.dashboardServiceUrl + "api/research/dashboard/color_details?color_id=" + id,{
                    params: { token: authService.token, node: node }})
                .then(function (data) {
                  return (data.data);
                });
        },

        getColorChartData4ByColorId: function (id,node,letter,keyword,page,col,order) { 
            return httpService.get(appConfig.dashboardServiceUrl + 
                `api/research/dashboard/color_details?color_id=${id}&page=${page}&per_page=10`,{
                    params: { token: authService.token, node: node, last_name: letter, keyword: keyword, order_column: col, order_in: order }})
                .then(function (data) {
                  return (data.data);
                });
        },

        getColorChartData4DetailsById: function (id) { 
            return httpService.get(appConfig.dashboardServiceUrl + "api/research/dashboard/research_detail?id=" + id,{
                    params: { token: authService.token }})
                .then(function (data) {
                  return (data.data);
                });
        },

        //topic
        getTopicChartsDataByTopic: function (keyword,node) { 
            return httpService.get(appConfig.dashboardServiceUrl + "api/research/dashboard/topic_details?keyword=" + keyword,{
                    params: { token: authService.token, node: node }})
                .then(function (data) {
                  return (data.data);
                });
        },

        getTopicChartData3ByTopicId: function (id,node,page,col,order) { 
            return httpService.get(appConfig.dashboardServiceUrl + 
                `api/research/dashboard/topic_details?keyword=${id}&page=${page}&per_page=50`,{
                    params: { token: authService.token, node: node, order_column: col, order_in: order }})
                .then(function (data) {
                  return (data.data);
                });
        },

        // Researcher
        getResearcherChartsDataByResearcherId: function (id,node) { 
            return httpService.get(appConfig.dashboardServiceUrl + `api/research/dashboard/researcher_details?author_id=${id}`,{
                    params: { token: authService.token, node: node }})
                .then(function (data) {
                  return (data.data);
                });
        },

        getResearcherChartsData2ByResearcherId: function (id,node,letter,page) { 
            return httpService.get(appConfig.dashboardServiceUrl + `api/research/dashboard/researcher_details?author_id=${id}&page=${page}&per_page=100`,{
                    params: { token: authService.token, node: node, last_name: letter }})
                .then(function (data) {
                  return (data.data);
                });
        },

        getResearcherChartData1ByResearcherId: function (id,node,page,col,order) { 
            return httpService.get(appConfig.dashboardServiceUrl + 
                `api/research/dashboard/researcher_details?author_id=${id}&page=${page}&per_page=10`,{
                    params: { token: authService.token, node: node, order_column: col, order_in: order }})
                .then(function (data) {
                  return (data.data);
                });
        },

        //jounral
        getJournalChartsDataByJournal: function (keyword,node) { 
            return httpService.get(appConfig.dashboardServiceUrl + "api/research/dashboard/journal_details?journal=" + keyword,{
                    params: { token: authService.token, node: node }})
                .then(function (data) {
                  return (data.data);
                });
        },

        getJounralChartData3ByJounral: function (id,node,letter,keyword,page,col,order) { 
            return httpService.get(appConfig.dashboardServiceUrl + 
                `api/research/dashboard/journal_details?journal=${id}&page=${page}&per_page=10`,{
                    params: { token: authService.token, node: node, color_id: letter, keyword: keyword, order_column: col, order_in: order }})
                .then(function (data) {
                  return (data.data);
                });
        },

        sendNotifyEmail: function (id) { 
            return httpService.get(appConfig.dashboardServiceUrl + 
                `api/research/dashboard/notify?`, {
                    params: { token: authService.token }})
                .then(function (data) {
                  return (data.data);
                });
        },
    };
  }
);