angular.module("app").directive("categoryUseOfColor", ["$timeout", (timeout) => {
  let link = (scope) => {  
    scope.characters = [
      "A", "Á", "B", "C", "D", "E", "É", "F", "G", "H", "I", "K",
      "L", "M", "N", "O", "Ö", "P", "Q", "R", "S", "T", "V", "X"
    ];
    scope.selectedCharacter = "ALL";
    scope.saveItemImageDetails = false;

    scope.$watch('data', function (newVal, prevVal) {
      if(newVal) {
        scope.filteredData = scope.data;
        scope.selectedCharacter = scope.data.group === 'All' ? 'ALL' : scope.data.group;
      }
    });
   
    scope.characterSelect = (value) => {
      scope.selectedCharacter = value;
      scope.filteredResults(value === 'ALL' ? 'All' : value);
    };

    scope.isSelected = (value) => {
      return scope.selectedCharacter === value;
    };

    scope.showImageDetails = false;
    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (item, index) => {
      scope.logoData = item.brands;
      scope.index = index;
      scope.toggleImageDetails();
    };

    scope.createString = (arr, key) => {
      return arr
        .map(obj => `${obj[key]}`)
        .join(', ');
    }

    scope.$on("mood_state_item_chart", (data) => {
      let mainData = data.targetScope.data;
      const keys = ["CATEGORY", "COLOR", "STYLE", "BRAND"];
      let newParam = {
        [keys[0]]: mainData.category,
        [keys[1]]: scope.createString(mainData.top_4_colors, 'family'),
        [keys[2]]: mainData.style,
        [keys[3]]: mainData.title
      }
      let node = angular.element("#homeItemPopupCapture")[0];
      domtoimage
        .toPng(node, {
          bgcolor: "#fff",
        })
        .then((dataUrl) => {
          if(dataUrl) {
            scope.saveImageDetailsData = {
              base64: dataUrl,
              type: 'home',
              data: newParam
            };
          }
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });

        let times = 2000;
        timeout(function () {
          if(scope.saveImageDetailsData) {
            scope.showImageDetails = false;
            scope.toggleSaveImageDetails();
          } else {
            timeout(function () {
              if(scope.saveImageDetailsData) {
                scope.showImageDetails = false;
                scope.toggleSaveImageDetails();
              } else {
                timeout(function () {
                  if(scope.saveImageDetailsData) {
                    scope.showImageDetails = false;
                    scope.toggleSaveImageDetails();
                  }
                }, times);
              }
            }, times);
          }
        }, times);
    });

    scope.generateImgName = function (type, board, name) {
      let result = "";
      const date = moment().format("L");
      result += name.toUpperCase() + ".";
      return result + date + type;
    };

    scope.toggleSaveImageDetails = () => {
      scope.saveItemImageDetails = !scope.saveItemImageDetails;
    };

    scope.$on("mood_state_item_chart_changed", () => {
      scope.toggleSaveImageDetails();
    });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbCategoryUseOfColor/dbCategoryUseOfColorView.html",
    link: link,
    scope: {
      data: "=",
      filteredResults: "="
    }
  };
}]);
