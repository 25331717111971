angular.module("app").component("aboutUsComponent", {
  templateUrl: "app/components/about-us/about-us.tmpl.html",
  controller: function ($state, $http, appConfig, dataValidate) {
    const vm = this;


    vm.necessaries = [
      {
        imgUrl: "/assets/img/about/necessaries/necessary-1.png",
        desc: "Conduct A Color<br />Competitive Analysis And Develop<br />The Company/Product Color Strategy",
      },
      {
        imgUrl: "/assets/img/about/necessaries/necessary-2.png",
        desc: "Rationalize Color<br />Recommendations To Other<br />C-Level Executives",
      },
      {
        imgUrl: "/assets/img/about/necessaries/necessary-3.png",
        desc: "Identify Global Color<br />Preferences Associated<br />With Specific Products",
      },
      {
        imgUrl: "/assets/img/about/necessaries/necessary-4.png",
        desc: "Mine Color Sentiments<br />On Social Media To Assess<br />Readiness For New Colors",
      },
      {
        imgUrl: "/assets/img/about/necessaries/necessary-5.png",
        desc: "Keynote On Color<br />Strategy And Design",
      },
      {
        imgUrl: "/assets/img/about/necessaries/necessary-6.png",
        desc: "Name New<br />Color Introductions",
      },
      {
        imgUrl: "/assets/img/about/necessaries/necessary-7.png",
        desc: "Research Emerging Color<br />Trends In Selected Industries",
      },
    ];

    vm.basicKnowledge = [
      "We Designed HueData's Comprehensive, Dynamic, Usable and Affordable Color Data To Inspire, Inform And Validate Your Color Decisions",
      "We Support Color Research With Our State Of The Art API That Connects You With Millions Of Color Data Points Across Your Areas Of Interest /Work",
      "We Deeply Believe in Teaching The Next Generation's Leaders And Work With Educators To Enhance The Teaching Of Color",
      "We Look To Infuse Our Color Data In Technologies From Al To IoT, 3D Printing, And AR/VR, To Promote Color Innovation",
      "We Celebrate The Work Of The Color Community And To Feature New Color Creations And Research.",
      "We Ingest New Color Data Daily And Constantly Advance The Analytics, Insights And Predictions To Inform All Color Decisions",
      "We Love To Innovate, And Look Forward To Collaborating On Projects And Ideas That Bring New Color Value And Solutions",
    ];

    vm.highQualityColors = [
      {
        name: "red",
        hex: "#f20000",
        percents: [
          {
            value: 6.3,
            weight: 60,
          },
          {
            value: 2.6,
            weight: 40,
          },
          {
            value: 5.3,
            weight: 20,
          },
        ],
      },
      {
        name: "orange",
        hex: "#f27900",
        percents: [
          {
            value: 1.8,
            weight: 50,
          },
          {
            value: 1.4,
            weight: 50,
          },
          {
            value: 2.6,
            weight: 5,
          },
        ],
      },
      {
        name: "yellow",
        hex: "#f2f202",
        percents: [
          {
            value: 0.8,
            weight: 50,
          },
          {
            value: 0.8,
            weight: 50,
          },
          {
            value: 1.1,
            weight: 5,
          },
        ],
      },
      {
        name: "yellow/green",
        hex: "#86ae00",
        percents: [
          {
            value: 1,
            weight: 50,
          },
          {
            value: 1,
            weight: 50,
          },
          {
            value: 0.9,
            weight: 5,
          },
        ],
      },
      {
        name: "green",
        hex: "#007900",
        percents: [
          {
            value: 0.2,
            weight: 50,
          },
          {
            value: 0.2,
            weight: 50,
          },
          {
            value: 0.2,
            weight: 5,
          },
        ],
      },
      {
        name: "cyan",
        hex: "#02f2f2",
        percents: [
          {
            value: 0.7,
            weight: 50,
          },
          {
            value: 0.5,
            weight: 50,
          },
          {
            value: 0.8,
            weight: 5,
          },
        ],
      },
      {
        name: "blue",
        hex: "#0000f2",
        percents: [
          {
            value: 3.9,
            weight: 25,
          },
          {
            value: 6.3,
            weight: 60,
          },
          {
            value: 1.6,
            weight: 5,
          },
        ],
      },
      {
        name: "violet",
        hex: "#8800f2",
        percents: [
          {
            value: 7.5,
            weight: 25,
          },
          {
            value: 6.8,
            weight: 35,
          },
          {
            value: 6.8,
            weight: 30,
          },
        ],
      },
      {
        name: "magenta",
        hex: "#f200f2",
        percents: [
          {
            value: 2.3,
            weight: 50,
          },
          {
            value: 0.8,
            weight: 50,
          },
          {
            value: 1.7,
            weight: 5,
          },
        ],
      },
      {
        name: "brown",
        hex: "#8e4700",
        percents: [
          {
            value: 4.3,
            weight: 50,
          },
          {
            value: 4.3,
            weight: 50,
          },
          {
            value: 4.3,
            weight: 5,
          },
        ],
      },
      {
        name: "beige",
        hex: "#e9e9d1",
        percents: [
          {
            value: 8.6,
            weight: 65,
          },
          {
            value: 5.2,
            weight: 50,
          },
          {
            value: 7.9,
            weight: 5,
          },
        ],
      },
      {
        name: "gray",
        hex: "#b6b6b6",
        percents: [
          {
            value: 17.1,
            weight: 55,
          },
          {
            value: 18.7,
            weight: 55,
          },
          {
            value: 16.1,
            weight: 30,
          },
        ],
      },
      {
        name: "white",
        hex: "#ffffff",
        percents: [
          {
            value: 8.7,
            weight: 35,
          },
          {
            value: 6.9,
            weight: 50,
          },
          {
            value: 9.2,
            weight: 35,
          },
        ],
      },
      {
        name: "black",
        hex: "#000000",
        percents: [
          {
            value: 36.8,
            weight: 60,
          },
          {
            value: 44.5,
            weight: 50,
          },
          {
            value: 38.5,
            weight: 50,
          },
        ],
      },
    ];
  },
});
