angular.module("app").component("colorIndexTabsComponent", {
  templateUrl: "app/components/color-index-tabs/color-index-tabs.tmpl.html",
  controller: function ($scope, $location, colorRequest) {
    $scope.activeTabId = 1;
    $scope.selectedMenuId = 1;
    $scope.currentPage = 0;
    $scope.showColorPopup = false;
    $scope.contentData = [];

    $scope.menuForColorIndexTabs = [
      { id: 1, title: "ALL NAMES" },
      { id: 2, title: "COLOR ASSOCIATION NAMES" },
      { id: 3, title: "COLOURLOVERS NAMES" },
      { id: 4, title: "PAINT NAMES" },
    ];

    $scope.params = $location.search();
    $scope.viewMode = $scope.params.mode;
    $scope.loadData = () => {
      colorRequest
        .getRgb($scope.params.color, $scope.currentPage + 1)
        .then((res) => {
          $scope.contentData = res.color_names;
          $scope.pageCount = res.paginate.total_pages;
        });
    };

    $scope.loadData();

    // dummy data -> color names
    $scope.allColorNames = [
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: 'o1b4 harlequin',
        rgb: '222,156,192',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '10357-6 harlequin red',
        rgb: '198,80,100',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '1u5b harlequin',
        rgb: '161,97,115',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '221b harlequin',
        rgb: '217,227,182',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '2437 harlequin',
        rgb: '60,131,179',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '4062-53 harlequin',
        rgb: '178,109,136',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '50bb27/232 harlequin',
        rgb: '113,141,182',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '5390 harlequin',
        rgb: '90,166,101',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '7153-54 harlequin pink',
        rgb: '187,124,149',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '744 harlequin',
        rgb: '118,143,117',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '91501 harlequin',
        rgb: '111,94,147',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: 'blue harlequin',
        rgb: '49,48,107',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: 'o1b4 harlequin',
        rgb: '222,156,192',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '10357-6 harlequin red',
        rgb: '198,80,100',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '1u5b harlequin',
        rgb: '161,97,115',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '221b harlequin',
        rgb: '217,227,182',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '2437 harlequin',
        rgb: '60,131,179',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '4062-53 harlequin',
        rgb: '178,109,136',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '50bb27/232 harlequin',
        rgb: '113,141,182',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '5390 harlequin',
        rgb: '90,166,101',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '7153-54 harlequin pink',
        rgb: '187,124,149',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '744 harlequin',
        rgb: '118,143,117',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: '91501 harlequin',
        rgb: '111,94,147',
        source: null
      },
      {
        brand: 'KitchenAid',
        hsl: '[43, 0.11, 0.58]',
        name: 'blue harlequin',
        rgb: '49,48,107',
        source: null
      }
    ];

    $scope.selectColorPopupBox = (needShow, color, index) => {
      if (needShow === null)
        return ($scope.showColorPopup = !$scope.showColorPopup);
      if (color) $scope.selectedColor = color;
      $scope.showColorPopup = needShow;
      $scope.index = index;
    };

    $scope.backToSearch = () => $location.path("/color-index");
    $scope.isSet = (tabId) => $scope.activeTabId === tabId;

    $scope.setTab = (tabId) => {
      if (tabId < 0 || tabId > 5) {
        return;
      }
      $scope.activeTabId = tabId;
    };

    $scope.setPage = (num) => {
      if (num >= 0 && num < $scope.pageCount) {
        $scope.currentPage = num;
        $scope.loadData();
      }
    };
  },
});
