angular.module('app').directive('colorBoxPreventHorCutoff', function () {
  function link (scope, element) {
    const sideDiff = document.documentElement.clientWidth - element[0].getBoundingClientRect().x;

    if (sideDiff < 650) {
      element.css('right', '150px');
      element.css('left', 'auto')
    }
  }
  return {
    restrict: 'A',
    link
  }
});
