angular.module("app").directive("hueChartMosaicForAuto", function () {
  function link(scope) {
    scope.$watch("data", (newValue, oldValue) => {
      if (newValue) {
        bindData();
      }
    });

    function bindData() {
      scope.firstData = {}
      scope.secondData = {}
      if(scope.data) {
        scope.data.forEach(element => {
          if(element.year === scope.firstField.title){
            scope.firstData = {
              groups: element.basic_colors,
              unique: element.colors
            }
          } else {
            scope.secondData = {
              groups: element.basic_colors,
              unique: element.colors
            }
          }
        });
      }
    }
  }

  const directive = {
    link: link,
    restrict: "E",
    replace: true,
    scope: {
      data: "=",
      data1: "=",
      mode: "=",
      firstField: "=",
      secondField: "=",
    },
    templateUrl:
      "app/directives/customInfographicsAutoDirectives/mosaicForAuto/chartMosaicForAuto.html",
  };

  return directive;
});
