angular.module("app").controller("pharmaController", [
  "$scope",
  "pharmaDashboardRepository",
  "dashboardOverlayService",
  "searchMenuRepository",
  "$timeout",
  "charts",
  "$state",
  "$location",
  "modalService",
  "localStorageService",
  function (
    scope,
    pharmaDashboardRepository,
    dashboardOverlayService,
    searchMenuRepository,
    timeout,
    charts,
    $state,
    $location,
    modalService,
    localStorageService
  ) {
    scope.menus = {
      pharmaceuticalCompany: "",
      therapeuticArea: "",
      pillShape: "",
      year: "",
      color: "",
    };

    scope.disabledControls = {
      pharmaceuticalCompany: false,
      therapeuticArea: false,
      pillShape: false,
      year: false,
      color: false,
    };

    scope.type = {
      pharmaceuticalCompany: "pharmaceuticalCompany",
      therapeuticArea: "therapeuticArea",
      pillShape: "pillShape",
      year: "year",
      color: "color",
    };

    scope.params = $location.search();

    if(localStorageService.get("currentUser")){
      scope.user = localStorageService.get("currentUser");
    }

    scope.$watch('params', function (params) {
     if (params.control === 'searchMenu') {
       scope.init();
     } else {
       scope.handleChangeControl(params.control, params);
     }
    });

    scope.disabledInput = (type, value) => {
      switch (type) {
        case "pharmaceuticalCompany":
          scope.disableTherapeuticArea = Boolean(value);
          scope.disablePillShape = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "therapeuticArea":
          scope.disablePharmaceuticalCompany = Boolean(value);
          scope.disablePillShape = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "pillShape":
          scope.disablePharmaceuticalCompany = Boolean(value);
          scope.disableTherapeuticArea = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "year":
          scope.disablePharmaceuticalCompany = Boolean(value);
          scope.disableTherapeuticArea = Boolean(value);
          scope.disablePillShape = Boolean(value);
          break;
      }
    };

    scope.mainParam = null;
    scope.mainParamId = null;
    scope.secondaryParams = {};
    scope.tab = 1;
    scope.currentPage = 0;

    scope.pharmaceutical_company_place_holder =
      "SEARCH BY PHARMACEUITICAL COMPANY";
    scope.therapeutic_area_place_holder = "SEARCH BY THERAPEUTIC AREA";
    scope.pill_shape_place_holder = "SERACH BY PILL SHAPE";
    scope.year_place_holder = "SEARCH BY YEAR";
    scope.header_title = "color: blue";

    scope.showDashboard = false;
    scope.title = scope.year;
    scope.subtitle = null;

    scope.isLoadingControls = true;

    scope.compareData = [];
    scope.colorsCountData = [];
    scope.colorsCountData = [];
    scope.shadesData = [];
    scope.topFamiliesData = [];
    scope.topBrandsData = [];
    scope.topColorsData = [];
    scope.colorFrequencyData = [];
    scope.topFinishesData = [];
    scope.carColorsData = [];
    scope.colorPaletteData = [];
    scope.mainSeason = [];
    scope.mosaicData = {};
    scope.companyList = [];
    scope.areaList = [];
    scope.yearList = [];
    scope.shapeList = [];
    scope.pillColorsData = [];

    scope.colorPaletteBucket = 38;

    pharmaDashboardRepository.getSettings().then((data) => {
      scope.colors = data.colors;
      scope.yearList = data.years;
      scope.shapeList = data.shapes;
    });

    scope.setback = () => {
      $state.go("pharma", {control: "searchMenu", id: "", title: "", hex: ""});
    };

    scope.init = () => {
      scope.menus = {
        pharmaceuticalCompany: "",
        therapeuticArea: "",
        pillShape: "",
        year: "",
        color: "",
      };
      scope.disablePharmaceuticalCompany = false;
      scope.disableTherapeuticArea = false;
      scope.disablePillShape = false;
      scope.disableYear = false;
      scope.isLoadingControls = true;
      scope.currentPage = 0;
      scope.mainSeason = [];
      scope.tab = 1;
      if(!scope.user.admin) {
        modalService.showModal(10, null, "We're working to finalize this dashboard and except it launched in Q3 of 2022.");
      }
    };

    scope.setTab = (tabId) => {
      scope.tab = tabId;
      scope.select_images = false;
      scope.selected_moodboard = false;
      scope.state = 1;
    };

    scope.isSet = (tabId) => {
      return scope.tab === tabId;
    };

    // searchMenuRepository.getControlsDataAuto().then(function (data) {
    //   scope.controlsData = data;
    // });

    scope.changeColorPaletteBucket = function (value) {
      scope.colorPaletteBucket = value;
    };

    scope.setColorAsMain = function (color) {
      scope.iconUrl = null;
      scope.secondaryParams = {};
      scope.mainParam = null;
      scope.tempColor = color;
      scope.menus = {
        pharmaceuticalCompany: "",
        therapeuticArea: "",
        pillShape: "",
        year: "",
        color: "",
      };
      scope.menus.color = color.id;
      if (
        !scope.controlsData.colors.find(function (item) {
          return item.id === scope.tempColor.id;
        })
      ) {
        scope.controlsData.colors.unshift(scope.tempColor);
      }
      scope.tempColor = null;
      scope.handleChangeControl("color");
      scope.loadGraphics();
    };

    scope.loadGraphics = function () {
      if (scope.mainParam) {
        scope.showDashboard = true;
        dashboardOverlayService.loadingStart(4000);

        // ----------------------------------------

        pharmaDashboardRepository
          .getPills(
            scope.mainParam,
            scope.mainParamId,
            scope.currentPage + 1,
            40
          )
          .then(function (data) {
            scope.pillColorsData = data.pills;
            scope.pageCount = data.paginate.total_pages;
          });

        scope.isLoadingControls = false;
      }
    };

    scope.setPage = (num) => {
      if (num >= 0 && num < scope.pageCount) {
        scope.currentPage = num;
        scope.loadGraphics();
      }
    };

    scope.handleChangeControl = function (control, item) {
      scope.header_title = () => {
        if (control === "therapeuticArea") {
          return (control = "therapeutic area");
        } else if (control === "pillShape") {
          return (control = "shape");
        }
        else if (control === "pharmaceuticalCompany") {
          return (control = "pharma company");
        }

        return `${control}: ${item.title}`;
      };
      scope.mainParam = control;
      scope.selectedFilterValue = item;
      scope.mainParamId = item.id;
      scope.searchMode = control;

      scope.loadGraphics();
    };

    scope.handleSearchChange = function (control, item) {
      $state.go("pharma", Object.assign({control: control}, item));
    };

    scope.getResultsOnFilterSearch = (type, str, page) => {
      scope.ajaxInProgress = true;  
      switch (type) {
        case 'pharmaceuticalCompany':
          if (!str.length) {
            scope.companyList = [];
            scope.ajaxInProgress = false;
            return;
          }

          pharmaDashboardRepository.getCompanyList(str)
            .then(data => {
              scope.companyList = [];
              scope.companyList = data;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break; 

        case 'therapeuticArea':
          if (!str.length) {
            scope.areaList = [];
            scope.ajaxInProgress = false;
            return;
          }

          pharmaDashboardRepository.getAreaList(str)
            .then(data => {
              scope.areaList = [];
              scope.areaList = data;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break; 

        default:
          scope.ajaxInProgress = false;
      }
    }; 

    scope.$watch(
      () => {
        return dashboardOverlayService.showOverlay;
      },
      (newValue) => {
        scope.showDashboardOverlay = newValue;
      }
    );
  },
]);
