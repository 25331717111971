angular.module("app").directive("signUpPopup", () => {
  let link = (scope) => {

    scope.$watch('formData', function (formData) {
      scope.data = formData;
    });
   
    

    scope.closeClickHandler = () => {
      scope.closefunction();
    };

    scope.submit = function () {
      scope.getUserData(scope.data);
    };

    
  };
  return {
    restrict: "E",
    templateUrl: "app/directives/signUPPopup/signUpPopupView.html",
    link: link,
    scope: {
      onClose: "&",
      closefunction: "=",
      getUserData: "=",
      formData: "="
    }
  };
});
