(function () {
  angular.module("app").directive("globalColorLineChart", [
    function () {
      function link(scope, element) {
        chartBase(self);

        const data = {
          yAxis: [
            { title: 299, position: 10 },
            { title: 300, position: 20 },
            { title: 301, position: 30 },
            { title: 302, position: 40 },
            { title: 303, position: 50 }
          ],
        };

        function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); } 

        function bindData() {
          if(!scope.allChartData || !scope.allChartData.length) return;
          let allTempChartData = scope.allChartData.map(item => {
            let points = item.points.map(point => {
              let val = point.value;
              if (isNaN(val)) {
                val = isNaN(point) ? 0 : point;
              }
              // return {value: (300 + Math.random())}; // Uncomment this to check graph with dummy data
              return {value: val};
            });
            let timeValues = item.timeValues.map(val => {
              if(isNumber(val)) {
                return parseInt(val);
              } else {
                return val;
              }
            });
            return {
              color: item.color.hex,
              points,
              timeValues
            };
          });

          let tempChartPoints = scope.chartData.points.map(point => {
            // return {value: (400 + Math.random())}; // Uncomment this to check graph with dummy data
            let val = point.value;
            if (isNaN(val)) {
              val = isNaN(point) ? 0 : point;
            }
            return {value: val};
          });
          let timeValues = scope.chartData.timeValues.map(val => {
            if(isNumber(val)) {
              return parseInt(val);
            } else {
              return val;
            }return parseInt(val);
          });
          let tempAverageData = scope.averageData.map(item => isNaN(item) ? 0 : item);
          // Uncomment this section to use dummy data
          // tempAverageData = [];
          // for (let j = 0; j < 100; j++) {
          //   tempAverageData.push(400.1 + Math.sin(j / 100 * 2.5 * Math.PI) * 1.3);
          // }

          const container = element.find(`#my_global_line_chart_${scope.index}`).html("");
          const width = $(container[0]).width() || 1368;
          const svg = d3
            .select(container[0])
            .append("svg")
            .attr("width", "100%")
            .attr("height", "600");

          const gradientDefs = svg
            .append("defs")
            .append("linearGradient")
            .attr("id", `my_global_line_chart_${scope.index}_gradient`)
            .attr("x1", "0%")
            .attr("y1", "0%")
            .attr("x2", "0%")
            .attr("y2", "100%");

          gradientDefs
            .append("stop")
            .attr("offset", "5%")
            .attr("stop-color", scope.chartData.color.hex)
            .attr("stop-opacity", "0.8");

          gradientDefs
            .append("stop")
            .attr("offset", "95%")
            .attr("stop-color", scope.chartData.color.hex)
            .attr("stop-opacity", "0");

          const g = svg
            .append("g")
            .attr("transform", "translate(" + -10 + "," + 580 + ")");

          // get MIN and MAX value and gradient color
          let allPoints = [];

          for (let k = 0; k < allTempChartData.length; k++) {
            const chartPoints = allTempChartData[k].points.map(item => item.value);
            allPoints = [...allPoints, ...chartPoints];
          }

          const MIN_POINT = Math.min(...allPoints);
          const MAX_POINT = Math.max(...allPoints);
          const INTERVAL = Math.round((MAX_POINT - MIN_POINT) / 4);
          for (let i = 0; i < 5; i++) {
            data.yAxis[i].title = (MIN_POINT + INTERVAL * i);
          }

          // const chartPoints = tempChartPoints.map(item => item.value);
          // const MIN_POINT = Math.min(...chartPoints);
          // const MAX_POINT = Math.max(...chartPoints);
          // const INTERVAL = (MAX_POINT - MIN_POINT) / 4 || 1;
          // for (let i = 0; i < 5; i++) {
          //   data.yAxis[i].title = (MIN_POINT + INTERVAL * i).toFixed(1);
          // }

          // draw horizontal line
          g.append("line")
            .attr("x1", 60)
            .attr("x2", width)
            .attr("y1", -30)
            .attr("y2", -30)
            .style({ stroke: "#ccc", "stroke-width": 1 });

          for (let j = 0; j < data.yAxis.length; j++) {
            g.append("line")
              .attr("x1", 60)
              .attr("x2", width - 30)
              .attr("y1", -30 - data.yAxis[j].position * 10)
              .attr("y2", -30 - data.yAxis[j].position * 10)
              .style({ stroke: "#ccc", "stroke-width": 1 });

            g.append("text")
              .attr("text-anchor", "middle")
              .attr("dominant-baseline", "central")
              .attr("y", -30 - data.yAxis[j].position * 10)
              .attr("x", width - 10)
              .text(data.yAxis[j].title + "%");
          }

          // draw vertical line
          for (let j = 0; j < timeValues.length; j++) {
            g.append("line")
              .attr("x1", 60 + j * (width - 60) / timeValues.length)
              .attr("x2", 60 + j * (width - 60) / timeValues.length)
              .attr("y1", -30)
              .attr("y2", -530)
              .style({ stroke: "#ccc", "stroke-width": 1 });

            g.append("text")
              .attr("text-anchor", "middle")
              .attr("dominant-baseline", "central")
              .attr("y", -10)
              .attr("x", 60 + j * (width - 60) / timeValues.length)
              .text(timeValues[j])
              .attr("style", "font-family: Gotham SSm A, Gotham SSm B");
          }


          const chartData = tempChartPoints.slice(-timeValues.length);

          // draw charts
          const dx = (width - 90) / (chartData.length - 1);
          let points = "60, -30 ";
          for (let i = 0; i < chartData.length; i++) {
            const lastItem = i === chartData.length - 1;

            if (!lastItem) {
              g.append("line")
                .attr("x1", i * dx + 60)
                .attr("x2", (i + 1) * dx + 60)
                .attr("y1", -130 - (chartData[i].value - MIN_POINT) / INTERVAL * 100)
                .attr("y2", -130 - (chartData[i + 1].value - MIN_POINT) / INTERVAL * 100)
                .style({ stroke: scope.chartData.color.hex, "stroke-width": 1 });
            }

            points += `${i * dx + 60},${-130 - (chartData[i].value - MIN_POINT) / INTERVAL * 100} `;
          }

          points += `${width - 30},-30`;

          g.append("polygon")
            .attr("points", points)
            .style("fill", `url(#my_global_line_chart_${scope.index}_gradient)`);

          // dummy average data
          const cnt = tempAverageData.length;
          const dx1 = (width - 90) / (cnt - 1);
          for (let i = 0 ; i < cnt; i++) {
            const lastItem = i === cnt - 1;

            if (!lastItem) {
              g.append("line")
                .attr("x1", i * dx1 + 60)
                .attr("x2", (i + 1) * dx1 + 60)
                .attr("y1", -130 - (tempAverageData[i] - MIN_POINT) / INTERVAL * 100)
                .attr("y2", -130 - (tempAverageData[i + 1]  - MIN_POINT) / INTERVAL * 100)
                .style({ stroke: '#000', "stroke-width": 6, "stroke-dasharray": "1, 12", "stroke-linecap": "round" });
            }
          }
        }

        $(window).resize(() => {
          bindData();
        });

        scope.$watch('chartData', bindData);
        scope.$watch('allChartData', bindData);

        bindData();

        // control modal
        scope.showImageDetails = false;

        scope.toggleImageDetails = () => {
          scope.showImageDetails = !scope.showImageDetails;
        };

        scope.imageClickHandler = (index) => {
          scope.index = index;
          scope.toggleImageDetails();
        };

        scope.modalData = [
          {
            id: 0,
            title: "@FOXYYOUNGK_JAE",
            twitter: "NAME@NAME",
            date: "NOV 28, 2020 / 9:44 AM",
            description: "WIZ KHALIFA PLAYING BLACK AND YELLOW",
            img_url: `/assets/img/global/mentions/1.png`,
          }
        ];
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          chartData: "=",
          index: "=",
          averageData: "=",
          allChartData: "=",
          mode: "=",
        },
        templateUrl:
          "app/directives/dbGlobalColorData/globalColorTracker/globalColorLineChart/globalColorLineChartView.html",
      };

      return directive;
    },
  ]);
})();
