angular.module('app').directive('hueDashboardOverlay', ['dashboardOverlayService', (dashboardOverlayService) => {
    function link(scope) {
        scope.showErrorMessage = false;

        scope.$watch(() => {
            return dashboardOverlayService.showErrorMessage;
        }, (newValue) => {
            scope.showErrorMessage = newValue;
        });
    }

    return {
        restrict: 'A',
        link: link,
        template:
            '<div class="overlay-message message-loading" ng-show="!showErrorMessage"></div>' +
            '<div class="overlay-message message-error" ng-show="showErrorMessage"></div>',
        scope: true
    };
}]);
