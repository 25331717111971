angular.module("app").directive("colorUnicornData", (unicornConstants, modalService, localStorageService) => {
  let link = (scope) => {
    scope.headerData = {
      companyIndex: 859,
      uniCountries: 8,
      industrySpace: 15,
      countryWithHighNo: 'USA'
    };

    scope.colorHeaderData = {
      description: "Companies in this industry manufacture aircraft, aircraft components, and missiles and space vehicles. Major Companies include US-based Boeing, General Dynamics, Lockheed Martin, Northrop Grumman, and Raytheon, as well as the Netherlands-based EADS(and its French subsidiary Airbus.), BAE Systems (UK), Bombardier (Canada), and Finmeccanica (Italy)   ",
      unicornUsingLogo: 2123,
      mostPairedColor: 'RED',
    };

    scope.countryHeaderData = {
      countryFlag: '/assets/img/unicorn/flag/spain.png',
      countryName: 'SPAIN',
      countryInclude: 'HEALTH, FOOD, TRANSPORTATION',
      unicornInCountry: 43,
      mostPopularThisCountry: 'RED'
    };

    scope.industryHeaderData = {
      industryIcon: '/assets/img/unicorn/artificial.png',
      industryName: 'SUPPLY CHAIN, LOGISTICS & DELIVERY',
      industryInclude: 'CHINA, USA, UK, RANCE AND ESTONIA',
      unicornInIndustry: 43,
      mostPopularThisIndustry: 'RED'
    };

    scope.yearHeaderData = {
      countryInclude: 'USA, CHINA, UK, FRANCE',
      industryInclude: 'HEALTH, FOOD, TRANSPORTATION',
      unicornInYear: 43,
      mostPopularYear: 'RED'
    };

    // selectedMenuId = 1;
    scope.characters = [
      "ARTIFICIAL INTELLIGENCE",
      "AUTO & TRANSPORTATION",
      "CONSUMER & RETAIL",
      "CYBERSECURITY",
      "DATA MANAGEMENT & ANALYTICS",
      "E-COMMERCE & DIRECT-TO-CONSUMER",
      "EDTECH",
      "FINTECH",
      "FOOD",
      "HARDWARE",
      "HEALTH",
      "INTERNET SOFTWARE & SERVICE",
      "MOBILE & TELECOMMUNICATIONS",
      "OTHER",
      "SUPPLY CHAIN, LOGISTICS & DELIVERY"
    ];
    scope.selectedCharacter = "ALL";
    scope.characterSelect = (value) => {
      scope.selectedCharacter = value;
    };
    scope.isSelected = (value) => {
      return scope.selectedCharacter === value;
    };

    scope.researcherHeaderData = {
      researchPapers: 7,
      studiedColors: [
        { hex: '#4780e1' },
        { hex: '#a24c4c' },
        { hex: '#4ca258' },
        { hex: '#a4d521' },
        { hex: '#f8ff8d' },
        { hex: '#8db7ff' },
        { hex: '#f6aa07' },
        { hex: '#07d1f6' },
        { hex: '#4780e1' },
        { hex: '#a24c4c' },
        { hex: '#4ca258' },
        { hex: '#a4d521' },
        { hex: '#f8ff8d' },
        { hex: '#8db7ff' },
        { hex: '#f6aa07' },
        { hex: '#07d1f6' },
      ],
      mostStudiedTopic: "Color Forecast",
    };

    if(localStorageService.get("currentUser")){
      scope.user = localStorageService.get("currentUser");
    }

    scope.sourceHeaderData = {
      researchPapers: 2319,
      researcher: 124,
      associatedColors: 5,
    };

    scope.monthData = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];

    const now = new Date();
    scope.year = now.getFullYear();
    scope.month = now.getMonth();
    scope.day = now.getDate();

    scope.menus = {
      company: "",
      industry: "",
      country: "",
      year: ""
    };

    scope.disabledControls = {
      company: false,
      industry: false,
      country: false,
      year: false
    };

    scope.type = {
      company: "company",
      industry: "industry",
      country: "country",
      year: "year"
    };

    scope.wordCloudOptions = {
      width: 500,
      height: 350,
    };

    scope.searchMode = null;
    scope.selectedFilterValue = null;

    scope.company_place_holder = "SEARCH BY COMPANY";
    scope.industry_place_holder = "SEARCH BY INDUSTRY";
    scope.country_place_holder = "SEARCH BY COUNTRY";
    scope.year_place_holder = "SEARCH BY YEAR";

    scope.companyColor = {
      hex: "#ff7f00"
    }
    if(!scope.user.admin) {
      modalService.showModal(10, null, "We're working to finalize this color index and except it launched in Q3 of 2022.");
    }
    scope.handleSearchChange = function (control, item) {
      if (control === 'color' && item === null) {
        scope.searchMode = null;
      }
      else {
        scope.selectedFilterValue = Object.assign({}, item);
        scope.searchMode = control;
      }

      switch (control) {
        case "color":
          scope.menus.company = "";
          scope.menus.industry = "";
          scope.menus.country = "";
          scope.menus.year = "";
          break;
        case "company":
          scope.menus.industry = "";
          scope.menus.country = "";
          scope.menus.year = "";
          break;
        case "industry":
          scope.menus.company = "";
          scope.menus.country = "";
          scope.menus.year = "";
          break;
        case "country":
          scope.menus.company = "";
          scope.menus.industry = "";
          scope.menus.year = "";
          break;
        case "year":
          scope.menus.company = "";
          scope.menus.industry = "";
          scope.menus.country = "";
          break;
      }

    };

    //dummy data for search
    scope.countryList = unicornConstants.countries;

    // dummy data for color
    scope.colors = [
      {
        "id": "beige",
        "title": "beige",
        "hex": "#f5b8c7"
      },
      {
        "id": "purple",
        "title": "purple",
        "hex": "#e51b1f"
      },
      {
        "id": "magenta",
        "title": "magenta",
        "hex": "#f07f13"
      },
      {
        "id": "blue",
        "title": "blue",
        "hex": "#f5ea46"
      },
      {
        "id": "green",
        "title": "green",
        "hex": "#d1d839"
      },
      {
        "id": "turquoise",
        "title": "turquoise",
        "hex": "#4eb15b"
      },
      {
        "id": "black",
        "title": "black",
        "hex": "#64c4d7"
      },
      {
        "id": "cyan",
        "title": "cyan",
        "hex": "#3563ad"
      },
      {
        "id": "pink",
        "title": "pink",
        "hex": "#7d4b98"
      },
      {
        "id": "red",
        "title": "red",
        "hex": "#e22087"
      },
      {
        "id": "chartreuse",
        "title": "chartreuse",
        "hex": "#654320"
      },
      {
        "id": "orange",
        "title": "orange",
        "hex": "#f0e8c7"
      },
      {
        "id": "brown",
        "title": "brown",
        "hex": "#ffffff"
      },
      {
        "id": "grey",
        "title": "grey",
        "hex": "#a5a6a6"
      },
      {
        "id": "yellow",
        "title": "yellow",
        "hex": "#000000"
      },
    ];
    scope.selectedColor = scope.colors[0];
    scope.menu = unicornConstants.menu;
    scope.unicornColorVolumeDataOverYears = unicornConstants.unicornColorVolumeDataOverYears;
    scope.colorFrequencyData = unicornConstants.colorFrequencyData;
    scope.unicornUsingLogoAcrossIndustry = unicornConstants.dataForLogos;
    scope.unicornColorCombinationData = unicornConstants.unicornColorCombinationData;
    scope.unicornActualLogos = unicornConstants.unicornActualLogos;

    scope.countryColorForLogos = [
      {
        "id": "beige",
        "title": "beige",
        "hex": "#f5b8c7"
      },
      {
        "id": "purple",
        "title": "purple",
        "hex": "#e51b1f"
      },
      {
        "id": "magenta",
        "title": "magenta",
        "hex": "#f07f13"
      },
      {
        "id": "blue",
        "title": "blue",
        "hex": "#f5ea46"
      },
      {
        "id": "green",
        "title": "green",
        "hex": "#d1d839"
      },
      {
        "id": "turquoise",
        "title": "turquoise",
        "hex": "#4eb15b"
      },
      {
        "id": "black",
        "title": "black",
        "hex": "#64c4d7"
      },
      {
        "id": "cyan",
        "title": "cyan",
        "hex": "#3563ad"
      },
      {
        "id": "pink",
        "title": "pink",
        "hex": "#7d4b98"
      },
      {
        "id": "red",
        "title": "red",
        "hex": "#e22087"
      },
      {
        "id": "chartreuse",
        "title": "chartreuse",
        "hex": "#654320"
      },
      {
        "id": "orange",
        "title": "orange",
        "hex": "#f0e8c7"
      },
      {
        "id": "brown",
        "title": "brown",
        "hex": "#ffffff"
      },
      {
        "id": "grey",
        "title": "grey",
        "hex": "#a5a6a6"
      },
      {
        "id": "yellow",
        "title": "yellow",
        "hex": "#000000"
      },
    ];
    scope.colorSelectLogos = (color) => {
      scope.selectedColorLogo = color;
    };

    scope.isSelectedColorLogos = (color) => {
      return scope.selectedColorLogo && scope.selectedColorLogo.hex === color.hex;
    }
    scope.currentTypeForCountry = {
      "id": 0,
      "title": "Spain",
      "img_url": "/assets/img/unicorn/flag/spain.png",
    },
    scope.countryComparisonData = unicornConstants.countryLogoComparisonData;

    //industry
    scope.industryColorForLogos = [
      {
        "id": "beige",
        "title": "beige",
        "hex": "#f5b8c7"
      },
      {
        "id": "purple",
        "title": "purple",
        "hex": "#e51b1f"
      },
      {
        "id": "magenta",
        "title": "magenta",
        "hex": "#f07f13"
      },
      {
        "id": "blue",
        "title": "blue",
        "hex": "#f5ea46"
      },
      {
        "id": "green",
        "title": "green",
        "hex": "#d1d839"
      },
      {
        "id": "turquoise",
        "title": "turquoise",
        "hex": "#4eb15b"
      },
      {
        "id": "black",
        "title": "black",
        "hex": "#64c4d7"
      },
      {
        "id": "cyan",
        "title": "cyan",
        "hex": "#3563ad"
      },
      {
        "id": "pink",
        "title": "pink",
        "hex": "#7d4b98"
      },
      {
        "id": "red",
        "title": "red",
        "hex": "#e22087"
      },
      {
        "id": "chartreuse",
        "title": "chartreuse",
        "hex": "#654320"
      },
      {
        "id": "orange",
        "title": "orange",
        "hex": "#f0e8c7"
      },
      {
        "id": "brown",
        "title": "brown",
        "hex": "#ffffff"
      },
      {
        "id": "grey",
        "title": "grey",
        "hex": "#a5a6a6"
      },
      {
        "id": "yellow",
        "title": "yellow",
        "hex": "#000000"
      },
    ];
    scope.colorSelectIndustryLogos = (color) => {
      scope.selectedColorIndustryLogo = color;
    };

    scope.isSelectedColorIndustryLogos = (color) => {
      return scope.selectedColorIndustryLogo && scope.selectedColorIndustryLogo.hex === color.hex;
    }

    scope.currentTypeForIndustry = {
      "id": 0,
      "title": "ARTIFICIAL INTELLIGENCE",
      "img_url": "/assets/img/unicorn/artificial_intelligence.svg",
    },
    scope.industryComparisonData = unicornConstants.industryLogoComparisonData;
    scope.colorLogoAcrossIndustryData = unicornConstants.colorLogoAcrossIndustryData;
    scope.industryList = unicornConstants.listAllIndustryData;

    // year search
    scope.yearColorForLogos = [
      {
        "id": "beige",
        "title": "beige",
        "hex": "#f5b8c7"
      },
      {
        "id": "purple",
        "title": "purple",
        "hex": "#e51b1f"
      },
      {
        "id": "magenta",
        "title": "magenta",
        "hex": "#f07f13"
      },
      {
        "id": "blue",
        "title": "blue",
        "hex": "#f5ea46"
      },
      {
        "id": "green",
        "title": "green",
        "hex": "#d1d839"
      },
      {
        "id": "turquoise",
        "title": "turquoise",
        "hex": "#4eb15b"
      },
      {
        "id": "black",
        "title": "black",
        "hex": "#64c4d7"
      },
      {
        "id": "cyan",
        "title": "cyan",
        "hex": "#3563ad"
      },
      {
        "id": "pink",
        "title": "pink",
        "hex": "#7d4b98"
      },
      {
        "id": "red",
        "title": "red",
        "hex": "#e22087"
      },
      {
        "id": "chartreuse",
        "title": "chartreuse",
        "hex": "#654320"
      },
      {
        "id": "orange",
        "title": "orange",
        "hex": "#f0e8c7"
      },
      {
        "id": "brown",
        "title": "brown",
        "hex": "#ffffff"
      },
      {
        "id": "grey",
        "title": "grey",
        "hex": "#a5a6a6"
      },
      {
        "id": "yellow",
        "title": "yellow",
        "hex": "#000000"
      },
    ];
    scope.colorSelectYearLogos = (color) => {
      scope.selectedColorLogo = color;
    };

    scope.isSelectedColorYearLogos = (color) => {
      return scope.selectedColorLogo && scope.selectedColorLogo.hex === color.hex;
    }
    scope.yearList = unicornConstants.yearList.sort((a, b) => (a.id < b.id) ? 1 : -1)
    scope.unicornFiveTopColorLogoData = unicornConstants.unicornFiveTopColorLogoData;

    scope.setMenuId = function (id) {
      console.log('menu id', id);
    };
    scope.isSetId = function (id) {
      return scope.selectedMenuId === id;
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorUnicornData/dbColorUnicornDataView.html",
    link: link,
    scope: {
    }
  };
});
