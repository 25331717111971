angular.module("app").directive("natureColorData", [
  "natureFlowerDashboardRepository",
  "$timeout",
  (natureFlowerDashboardRepository, timeout) => {
    let link = (scope, element) => {
      // scope.colorHeaderData = {
      //   description:
      //     "This is Photoshop's version of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis, sem nibh id elit.",
      //   flowerFamilies: "34",
      //   colorPercentage: "2362/12324 (19.4%)",
      //   lifespan: {short: "96%", moderate: "11%", long: "39%"}
      // };

      // scope.familyHeaderData = {
      //   description:
      //     "This is Photoshop's version of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis, sem nibh id elit.",
      //   flowerFamilies: "324",
      //   growthRate: {slow: "96%", moderate: "11%", rapid: "39%"},
      //   lifespan: {short: "96%", moderate: "11%", long: "39%"}
      // };

      // scope.flowerLifespanHeaderData = {
      //   description: "Floral longevity, the length of time a flower remains open and functional, varies among plant species. Flowers of some species live less than one day (morning glory), whereas others live for serveral weeks",
      //   flowerFamilies: "324",
      //   lifespan: { short: "58%", moderate: "11%", long: "39%" },
      // };

      scope.flowerLocationHeaderData = {
        description: "The Flora Of North America north of Mexico treats all native and naturalized vascular plants and bryophytes in Canada. Greenland, St.Pierre et Miquelon, and the continential United States including the Floriad Keys and Aleutian Islands(approximately 18 million square kilometers). https://bitJy/32idoph",
        countries: 2,
        states: 48,
        grow: "58%"
      };

      scope.birdColorHeaderData = {
        description:
          "Color in birds is a result of two main ingredients - pigment and keratin. Three pigments give b birds' feather their colors: 1. Melanin produces black or dark brown coloration (white feathers are caused by a lack of pigmentation and are considered much weaker weaker weaker",
        birdFamilies: "34",
        colorPercentage: "2362/12324 (19.4%)",
        lifespan: {short: "96%", moderate: "11%", long: "39%"}
      };

      scope.birdFamilyHeaderData = {
        description:
          "Birds are traditionally thought of as a well-studied group, with more than 95% of the global species diversity estimated to have been described. Scientists estimate that there are roughly 9,000 - 10,000 species of birds.",
        birdFamilies: "324",
        size: {small: "96%", medium: "11%", large: "39%"},
        lifespan: {ten: "96%", twenty: "11%", more: "39%"}
      };

      scope.birdSizeHeaderData = {
        description: "Birds range in size the 5 cm (2 inches) bee hummingbird to the 2.75 m (9ft) ostrich",
        birdFamilies: "324",
        size: {small: "96%", medium: "11%", large: "39%"},
        lifespan: {ten: "96%", twenty: "11%", more: "39%"}
      };

      scope.birdLocationHeaderData = {
        description: "Birds are traditionally thought of as a well-studied group, with more than 95% of the global species diversity estimated to have been described. Scientists estimate that there are roughly 9,000 - 10,000 species of birds.",
        birds: "324",
        regions: "6",
        countries: "139",
      };

      scope.boardType = "nature";
      scope.showMoreColors = false;
      scope.showAutoPopup = false;
      scope.showColorPopup = false;
      scope.colorsCount = 22;
      scope.topColorsExpanded = false;
      scope.colorFrequencyExpanded = false;

      scope.selectedItem = scope.item;
      scope.colors = scope.colorSelectionData;

      scope.description = '';
      scope.fullDescription = '';
      scope.showingFullDesc = false;
      scope.moreDescAvailable = false;
      scope.maxDescWords = 280;
      scope.currentPage = 1;

      scope.isModeFlowerFamily = () => {
        return scope.natureMode.title === "flowers" && scope.viewMode === "flowerFamily";
      };

      scope.isModeFlowerLifespan = () => {
        return scope.natureMode.title === "flowers" && scope.viewMode === "flowerLifespan";
      };

      scope.isModeFlowerLocation = () => {
        return scope.natureMode.title === "flowers" && scope.viewMode === "flowerLocation";
      };

      scope.isModeFlowerColor = () => {
        return scope.natureMode.title === "flowers" && scope.viewMode === "color";
      };

      scope.isModeBirdColor = () => {
        return scope.natureMode.title === "birds" && scope.viewMode === "color";
      };

      scope.isModeBirdFamily = () => {
        return scope.natureMode.title === "birds" && scope.viewMode === "birdFamily";
      };

      scope.isModeBirdLocation = () => {
        return scope.natureMode.title === "birds" && scope.viewMode === "birdLocation";
      };

      scope.isModeBirdSize = () => {
        return scope.natureMode.title === "birds" && scope.viewMode === "birdSize";
      };

      scope.setDescription = desc => {
        scope.fullDescription = desc;
        if(desc){
          scope.description = desc.substr(0, scope.maxDescWords);
          scope.moreDescAvailable = desc.length > scope.maxDescWords;
        }
      };
  
      scope.showMoreDesc = () => {
        if (scope.showingFullDesc) {
          scope.description = scope.fullDescription.substr(0, scope.maxDescWords);
        }
        else {
          scope.description = scope.fullDescription;
        }
        scope.showingFullDesc = !scope.showingFullDesc;
      };

      if (scope.viewMode === "color") {
        scope.isLoadingControls = true;
      
        natureFlowerDashboardRepository
          .getColorChartsDataByColorId(scope.item.id, 'header')
          .then(function (data) {
            scope.colorHeaderData = data.header;
            scope.setDescription(scope.colorHeaderData.description);
            scope.isLoadingControls = false; 
          });

          natureFlowerDashboardRepository
          .getColorChartsDataByColorId(scope.item.id, 'chartData1')
          .then(function (data) {
            scope.colorDataTabColorChart1 = data.chartData1;
            scope.colorDataTabColorChart1.other_nature_flowers.forEach(element => {
              if (element.family === 'yellow/green') {
                element.displayTitle = 'yellow/ green';
              } else {
                element.displayTitle = element.family;
              }
            });
            if (scope.colorDataTabColorChart1.selected_nature_flower.family === 'yellow/green') {
              scope.colorDataTabColorChart1.selected_nature_flower.displayTitle = 'yellow/ green';
            } else {
              scope.colorDataTabColorChart1.selected_nature_flower.displayTitle = scope.colorDataTabColorChart1.selected_nature_flower.family;
            }
          });

          natureFlowerDashboardRepository
          .getColorChartsDataByColorId(scope.item.id, 'chartData2')
          .then(function (data) {
            scope.flowerProfileData = data.chartData2;
          });        
      }

      scope.loadFamilyChartData2 = () => {
        
        natureFlowerDashboardRepository
          .getFamilyChartsData2ByFamily(scope.item.title, 'chartData2', scope.currentPage, 30)
          .then(function (data) {
            scope.flowerFamilyDataSelected = data.chartData2.selected_family;
            scope.flowerFamilyData = scope.flowerFamilyData.concat(data.chartData2.other_family);
            scope.isLoadMoreData = data.chartData2.paginate.current_page === data.chartData2.paginate.total_pages ? true : false;
          });
      }

      scope.loadMoreData = () => {
        scope.currentPage += 1;
        scope.loadFamilyChartData2();
      }

      if (scope.viewMode === "flowerFamily") {
        scope.isLoadingControls = true;
        scope.currentPage = 1;
        scope.flowerFamilyData = [];
      
        natureFlowerDashboardRepository
          .getFamilyChartsDataByFamily(scope.item.title, 'header')
          .then(function (data) {
            scope.familyHeaderData = data.header;
            scope.setDescription(scope.familyHeaderData.description);
            scope.isLoadingControls = false; 
          });

          natureFlowerDashboardRepository
          .getFamilyChartsDataByFamily(scope.item.title, 'chartData1')
          .then(function (data) {
            scope.colorDataTabFamilyChart1 = data.chartData1;
          });

          natureFlowerDashboardRepository
          .getFamilyChartsDataByFamily(scope.item.title, 'chartData3')
          .then(function (data) {
            scope.familyDistributionAll = data.chartData3;
          }); 

          scope.loadFamilyChartData2();
      }

      if (scope.viewMode === "flowerLifespan") {
        scope.isLoadingControls = true;
      
        natureFlowerDashboardRepository
          .getLifespanChartsDataByLifespan(scope.item.title, 'header')
          .then(function (data) {
            scope.flowerLifespanHeaderData = data.header;
            scope.setDescription(scope.flowerLifespanHeaderData.description);
            scope.isLoadingControls = false; 
          });

          natureFlowerDashboardRepository
          .getLifespanChartsDataByLifespan(scope.item.title, 'chartData1')
          .then(function (data) {
            scope.colorFamilyData = data.chartData1;
          });
      }

      // dummy data -> national birds dominant colors (flower mode)
      scope.nationalFlowerData = [
        {
          id: 0,
          name: "Afghanistan",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/af.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 1,
          name: "Albania",
          colors: ["#bb0076"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/al.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 2,
          name: "Algeria",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/dz.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 3,
          name: "Andorra",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/ad.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 4,
          name: "Angola",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/ao.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 5,
          name: "Antigua and Barbuda",
          colors: ["#be8b31"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/ag.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 6,
          name: "Argentina",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/ar.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 7,
          name: "Armenia",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/am.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 8,
          name: "Australia",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/au.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 9,
          name: "Austria",
          colors: ["#bb0076"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/at.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 10,
          name: "Austrian Empire",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/at.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 11,
          name: "Azerbaijan",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/az.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 12,
          name: "Baden*",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/br.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 13,
          name: "Bahamas",
          colors: ["#be8b31"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/bs.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 14,
          name: "Bahrain",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/bh.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 15,
          name: "Bangladesh",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/bd.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 16,
          name: "Barbados",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/bb.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 17,
          name: "Bavaria",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/br.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 18,
          name: "Benin(Dahomey)",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/bj.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 19,
          name: "Bolivia",
          colors: ["#bb0076"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/bo.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 20,
          name: "Bosnia and Herzegovina",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/ba.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 21,
          name: "Botswana",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/bw.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 22,
          name: "Brazil",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/br.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 23,
          name: "Brunei",
          colors: ["#be8b31"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/bn.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 24,
          name: "Bulgaria",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/bg.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 25,
          name: "Burkina Faso",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/bf.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 26,
          name: "Burma",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/br.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 27,
          name: "Burundi",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/bi.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 28,
          name: "Namibia",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/na.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 29,
          name: "Nassau*",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/br.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 30,
          name: "Nauru",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/nr.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 31,
          name: "Nepal",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/np.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 32,
          name: "Netherlands, The",
          colors: ["#bb0076"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/nl.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 33,
          name: "New Zealand",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/nz.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 34,
          name: "Nicaragua",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/ni.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 35,
          name: "Niger",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/ne.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 36,
          name: "Cabo Verde",
          colors: ["#be8b31"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/br.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 37,
          name: "Cambodia",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/kh.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 38,
          name: "Cameroon",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/cm.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 39,
          name: "Canada",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/ca.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 40,
          name: "Cayman Islands, The",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/ky.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 41,
          name: "Central African Republic",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/cf.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 42,
          name: "Chad",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/td.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 43,
          name: "Chile",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/cl.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 44,
          name: "China",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/cn.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 45,
          name: "Colombia",
          colors: ["#bb0076"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/co.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 46,
          name: "Comoros",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/km.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 47,
          name: "Congo Free State, The",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/cg.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 48,
          name: "Costa Rica",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/cr.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 49,
          name: "Cote d'Ivoire",
          colors: ["#be8b31"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/ci.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 50,
          name: "Croatia",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/hr.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 51,
          name: "Cuba",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/cu.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 52,
          name: "Cyprus",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/cy.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 53,
          name: "Czechia",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/cz.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 54,
          name: "Saint Kitts andd Nevis",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/kn.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 55,
          name: "Saint Lucia",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/lc.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 56,
          name: "Samoa",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/ws.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 57,
          name: "San Marino",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/sm.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 58,
          name: "Sao Tome and Principe",
          colors: ["#bb0076"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/st.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 59,
          name: "Saudi Arabia",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/sa.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 60,
          name: "Schaumburg-Lippe*",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/br.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 61,
          name: "Senegal",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/sn.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 62,
          name: "Seriba",
          colors: ["#be8b31"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/rs.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 63,
          name: "Seychelles",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/sc.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 64,
          name: "Sierra Leone",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/sl.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 65,
          name: "Singapore",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/sg.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 66,
          name: "Slovakia",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/sk.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 67,
          name: "Solvenia",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/si.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 68,
          name: "Solomon Islands",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/sb.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 69,
          name: "Somalia",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/so.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 70,
          name: "Spain",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/es.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 71,
          name: "Suriname",
          colors: ["#bb0076"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/sr.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 72,
          name: "Afghanistan",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/af.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 73,
          name: "Albania",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/al.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 74,
          name: "Algeria",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/dz.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 75,
          name: "Andorra",
          colors: ["#be8b31"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/ad.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 76,
          name: "Angola",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/ao.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 77,
          name: "Antigua and Barbuda",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/ag.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 78,
          name: "Argentina",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/ar.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 79,
          name: "Armenia",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/am.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 80,
          name: "Australia",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/au.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 81,
          name: "Austria",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/at.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 82,
          name: "Austrian Empire",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/at.png",
          logo_url: "/assets/img/flags/br.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 83,
          name: "Azerbaijan",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/az.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 84,
          name: "Baden*",
          colors: ["#bb0076"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/br.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 85,
          name: "Bahamas",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/bs.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 86,
          name: "Bahrain",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/ng.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 87,
          name: "Bangladesh",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/bd.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 88,
          name: "Barbados",
          colors: ["#be8b31"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/bb.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 89,
          name: "Bavaria",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/br.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 90,
          name: "Afghanistan",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/af.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 91,
          name: "Albania",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/al.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 92,
          name: "Algeria",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/dz.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 93,
          name: "Andorra",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/br.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 94,
          name: "Angola",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/ao.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 95,
          name: "Antigua and Barbuda",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/ag.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 96,
          name: "Argentina",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/ar.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 97,
          name: "Armenia",
          colors: ["#bb0076"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/am.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 98,
          name: "Australia",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/au.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 99,
          name: "Austria",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/at.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 100,
          name: "Austrian Empire",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/at.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 101,
          name: "Azerbaijan",
          colors: ["#be8b31"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/az.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 102,
          name: "Baden*",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/1.png",
          logo_url: "/assets/img/flags/br.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 103,
          name: "Bahamas",
          colors: ["#4cbe31"],
          img_url: "/assets/img/nature/flowers/2.png",
          logo_url: "/assets/img/flags/bs.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 104,
          name: "Bahrain",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/3.png",
          logo_url: "/assets/img/flags/bh.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 105,
          name: "Bangladesh",
          colors: ["#ef8ca9"],
          img_url: "/assets/img/nature/flowers/4.png",
          logo_url: "/assets/img/flags/bd.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 106,
          name: "Barbados",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/5.png",
          logo_url: "/assets/img/flags/bb.svg",
          flower: "trochetia boutoniana",
        },
        {
          id: 107,
          name: "Bavaria",
          colors: ["#3181be"],
          img_url: "/assets/img/nature/flowers/6.png",
          logo_url: "/assets/img/flags/br.svg",
          flower: "trochetia boutoniana",
        }
      ];

      // dummy data -> selected (color) for bird compared with all other bird color families
      scope.colorBirdFamilyData = [
        {
          id: 0,
          title: 'mixed',
          hex: "#a3d8e4",
          hex1: "#ca7db2",
          percentage: 70,
          count: 35261
        },
        {
          id: 1,
          hex: '#f7c6de',
          title: 'pink',
          percentage: 60,
          count: 75261
        },
        {
          id: 2,
          hex: '#e51d1c',
          title: 'red',
          percentage: 20,
          count: 40211
        },
        {
          id: 3,
          hex: '#c55531',
          title: 'brown',
          percentage: 100,
          count: 123230
        },
        {
          id: 4,
          hex: '#f07c16',
          title: 'dark orange',
          percentage: 5,
          count: 5261
        },
        {
          id: 5,
          hex: '#fbdf16',
          title: 'yellow',
          percentage: 40,
          count: 45261
        },
        {
          id: 6,
          hex: '#f0dc83',
          title: 'lemon chiffon',
          percentage: 70,
          count: 85261
        },
        {
          id: 7,
          hex: '#8db624',
          title: 'yellow green',
          percentage: 10,
          count: 15261
        },
        {
          id: 8,
          hex: '#0a8236',
          title: 'green',
          percentage: 20,
          count: 25261
        },
        {
          id: 9,
          hex: '#335ca7',
          title: 'blue',
          percentage: 20,
          count: 25261
        },
        {
          id: 10,
          hex: '#6d4595',
          title: 'purple',
          percentage: 20,
          count: 25261
        },
        {
          id: 11,
          hex: '#eed5b4',
          title: 'wheat',
          percentage: 20,
          count: 25261
        },
        {
          id: 12,
          hex: '#974d15',
          title: 'brown',
          percentage: 20,
          count: 25261
        },
        {
          id: 13,
          hex: '#808081',
          title: 'gray',
          percentage: 20,
          count: 25261
        },
        {
          id: 14,
          hex: '#ffffff',
          title: 'white',
          percentage: 20,
          count: 25261
        },
        {
          id: 15,
          hex: '#050607',
          title: 'black',
          percentage: 20,
          count: 25261
        },
      ]

      // dummy data -> other bird families with similar. color families representation
      scope.birdFamilyRepresentationData = [
        {
          title: "HUMMNIGBIRDS",
          match: "55%"
        },
        {
          title: "ASTERACEAE",
          match: "100%",
        },
        {
          title: "BOMBACACEAE",
          match: "41%",
        },
        {
          title: "CONACEAE",
          match: "100%",
        },
        {
          title: "FUMARIACEAE",
          match: "64%",
        },
        {
          title: "ROSACEAE",
          match: "72%",
        },
        {
          title: "MYRTACEAE",
          match: "34%",
        },
        {
          title: "RANUNCULACEAE",
          match: "98%",
        }
      ]

      // dummy data -> bird color families across regions
      scope.birdColorDataAcrossRegions = [
        {
          id: 0,
          title: "North America & Canada"
        },
        {
          id: 1,
          title: "South America"
        },
        {
          id: 2,
          title: "Europe"
        },
        {
          id: 3,
          title: "Africa"
        },
        {
          id: 4,
          title: "Asia"
        },
        {
          id: 5,
          title: "Australia"
        },
      ];

      // dummy data -> national birds dominant colors (bird mode)
      scope.nationalBirdData = [
        {
          id: 0,
          name: "Afghanistan",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/1.png",
          logo_url: "/assets/img/flags/af.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 1,
          name: "Albania",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/2.png",
          logo_url: "/assets/img/flags/al.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 2,
          name: "Algeria",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/3.png",
          logo_url: "/assets/img/flags/dz.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 3,
          name: "Andorra",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/4.png",
          logo_url: "/assets/img/flags/ad.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 4,
          name: "Angola",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/5.png",
          logo_url: "/assets/img/flags/ao.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 5,
          name: "Antigua and Barbuda",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/6.png",
          logo_url: "/assets/img/flags/ag.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 6,
          name: "Argentina",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/7.png",
          logo_url: "/assets/img/flags/ar.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 7,
          name: "Armenia",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/8.png",
          logo_url: "/assets/img/flags/am.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 8,
          name: "Australia",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/3.png",
          logo_url: "/assets/img/flags/au.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 9,
          name: "Austria",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/4.png",
          logo_url: "/assets/img/flags/at.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 10,
          name: "Austrian Empire",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/5.png",
          logo_url: "/assets/img/flags/at.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 11,
          name: "Afghanistan",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/1.png",
          logo_url: "/assets/img/flags/af.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 12,
          name: "Albania",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/2.png",
          logo_url: "/assets/img/flags/al.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 13,
          name: "Algeria",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/3.png",
          logo_url: "/assets/img/flags/dz.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 14,
          name: "Andorra",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/4.png",
          logo_url: "/assets/img/flags/ad.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 15,
          name: "Angola",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/5.png",
          logo_url: "/assets/img/flags/ao.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 16,
          name: "Antigua and Barbuda",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/6.png",
          logo_url: "/assets/img/flags/ag.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 17,
          name: "Argentina",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/7.png",
          logo_url: "/assets/img/flags/ar.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 18,
          name: "Armenia",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/8.png",
          logo_url: "/assets/img/flags/am.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 19,
          name: "Australia",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/3.png",
          logo_url: "/assets/img/flags/au.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 20,
          name: "Austria",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/4.png",
          logo_url: "/assets/img/flags/at.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 21,
          name: "Austrian Empire",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/5.png",
          logo_url: "/assets/img/flags/at.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 22,
          name: "Afghanistan",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/1.png",
          logo_url: "/assets/img/flags/af.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 23,
          name: "Albania",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/2.png",
          logo_url: "/assets/img/flags/al.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 24,
          name: "Algeria",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/3.png",
          logo_url: "/assets/img/flags/dz.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 25,
          name: "Andorra",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/4.png",
          logo_url: "/assets/img/flags/ad.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 26,
          name: "Angola",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/5.png",
          logo_url: "/assets/img/flags/ao.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 27,
          name: "Antigua and Barbuda",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/6.png",
          logo_url: "/assets/img/flags/ag.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 28,
          name: "Argentina",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/7.png",
          logo_url: "/assets/img/flags/ar.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 29,
          name: "Armenia",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/8.png",
          logo_url: "/assets/img/flags/am.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 30,
          name: "Australia",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/3.png",
          logo_url: "/assets/img/flags/au.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 31,
          name: "Austria",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/4.png",
          logo_url: "/assets/img/flags/at.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 32,
          name: "Austrian Empire",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/5.png",
          logo_url: "/assets/img/flags/at.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 33,
          name: "Afghanistan",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/1.png",
          logo_url: "/assets/img/flags/af.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 34,
          name: "Albania",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/2.png",
          logo_url: "/assets/img/flags/al.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 35,
          name: "Algeria",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/3.png",
          logo_url: "/assets/img/flags/dz.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 36,
          name: "Andorra",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/4.png",
          logo_url: "/assets/img/flags/ad.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 37,
          name: "Angola",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/5.png",
          logo_url: "/assets/img/flags/ao.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 38,
          name: "Antigua and Barbuda",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/6.png",
          logo_url: "/assets/img/flags/ag.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 39,
          name: "Argentina",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/7.png",
          logo_url: "/assets/img/flags/ar.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 40,
          name: "Armenia",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/8.png",
          logo_url: "/assets/img/flags/am.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 41,
          name: "Australia",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/3.png",
          logo_url: "/assets/img/flags/au.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 42,
          name: "Austria",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/4.png",
          logo_url: "/assets/img/flags/at.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 43,
          name: "Austrian Empire",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/5.png",
          logo_url: "/assets/img/flags/at.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 44,
          name: "Afghanistan",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/1.png",
          logo_url: "/assets/img/flags/af.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 45,
          name: "Albania",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/2.png",
          logo_url: "/assets/img/flags/al.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 46,
          name: "Algeria",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/3.png",
          logo_url: "/assets/img/flags/dz.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 47,
          name: "Andorra",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/4.png",
          logo_url: "/assets/img/flags/ad.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 48,
          name: "Angola",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/5.png",
          logo_url: "/assets/img/flags/ao.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 49,
          name: "Antigua and Barbuda",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/6.png",
          logo_url: "/assets/img/flags/ag.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 50,
          name: "Argentina",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/7.png",
          logo_url: "/assets/img/flags/ar.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 51,
          name: "Armenia",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/8.png",
          logo_url: "/assets/img/flags/am.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 52,
          name: "Australia",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/3.png",
          logo_url: "/assets/img/flags/au.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 53,
          name: "Austria",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/4.png",
          logo_url: "/assets/img/flags/at.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 54,
          name: "Austrian Empire",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/5.png",
          logo_url: "/assets/img/flags/at.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 55,
          name: "Afghanistan",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/1.png",
          logo_url: "/assets/img/flags/af.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 56,
          name: "Albania",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/2.png",
          logo_url: "/assets/img/flags/al.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 57,
          name: "Algeria",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/3.png",
          logo_url: "/assets/img/flags/dz.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 58,
          name: "Andorra",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/4.png",
          logo_url: "/assets/img/flags/ad.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 59,
          name: "Angola",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/5.png",
          logo_url: "/assets/img/flags/ao.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 60,
          name: "Antigua and Barbuda",
          colors: ["#3cb878", "#ed1955", "#dd42ba"],
          img_url: "/assets/img/nature/birds/6.png",
          logo_url: "/assets/img/flags/ag.svg",
          bird: "pink-headed fruit dove",
        },
        {
          id: 61,
          name: "Argentina",
          colors: ["#f5ca57", "#72cefd", "#3cb878"],
          img_url: "/assets/img/nature/birds/7.png",
          logo_url: "/assets/img/flags/ar.svg",
          bird: "pink-headed fruit dove",
        },
      ];

      scope.natureChartPopupAttributes = {
        modalTitle: ""
      };
  
      scope.toggleNatureChartPopup = (needShow, type) => {
        if (needShow) {
          if(type === 'chart1'){
            scope.natureChartPopupAttributes.modalTitle = 'SELECTED ' + scope.selectedItem.title + ' FLOWER COMPARED WITH ALL OTHER FLOWER COLOR FAMILIES';
            scope.typeOfPopup = type;
          } else if(type === 'chart2'){
            scope.natureChartPopupAttributes.modalTitle = scope.selectedItem.title + ' FLOWER LIFESPAN AND GROWTH RATE PROFILE';
            scope.typeOfPopup = type;
          } else if(type === 'chart3'){
            scope.natureChartPopupAttributes.modalTitle = 'COLOR FAMILY DISTRIBUTION FOR ' + scope.selectedItem.title;
            scope.typeOfPopup = type;
          } else if(type === 'chart4'){
            scope.natureChartPopupAttributes.modalTitle = 'COLOR FAMILY DISTRIBUTION ACROSS ALL FLOWER FAMILIES';
            scope.typeOfPopup = type;
          }
          scope.showNatureChartPopup = true;
        } else {
          scope.showNatureChartPopup = false;
        }
      };
  
      scope.$on("mood_state_chart", (data) => {
        let newParam = {}
        
        let node = angular.element("#naturePopupCapture")[0];
        domtoimage
        .toPng(node, {
          bgcolor: "#fff",
        })
        .then((dataUrl) => {
          if(dataUrl) {
            if(_.isEmpty(newParam)) {
                scope.saveImageDetailsData = {
                  base64: dataUrl,
                  type: 'nature'
                };
              } else {
                scope.saveImageDetailsData = {
                  base64: dataUrl,
                  type: 'nature',
                  data: newParam
                };
              }
            }
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
  
          let times = 2000;
          timeout(function () {
            if(scope.saveImageDetailsData) {
              scope.showNatureChartPopup = false;
              scope.toggleSaveImageDetails();
            } else {
              timeout(function () {
                if(scope.saveImageDetailsData) {
                  scope.showNatureChartPopup = false;
                  scope.toggleSaveImageDetails();
                }
              }, times);
            }
          }, times);
      });
  
      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
        result += name.toUpperCase() + ".";
        return result + date + type;
      };
  
      scope.toggleSaveImageDetails = () => {
        scope.saveImageDetails = scope.saveImageDetails ? false : true;
      };
  
      scope.$on("mood_state_chart_changed", () => {
        scope.toggleSaveImageDetails();
      });
  
      scope.$on("mood_state_item_chart_changed", () => {
        scope.toggleSaveImageDetails();
      });
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbNatureColorData/dbNatureColorDataView.html",
      link: link,
      scope: {
        item: "=",
        viewMode: "=",
        colorSelectionData: "=",
        natureMode: "=",
      },
    };
  },
]);
