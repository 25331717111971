angular.module("app").directive("artColorData", [
  "artDashboardRepository", "$timeout",
  (artDashboardRepository, timeout) => {
    let link = (scope, element) => {
      scope.isLoadingControls = true; 
      // dummy data
      scope.filteredChoices = [
        {
          title: "american",
          img_url: "/assets/img/art/artist/american.png",
        },
        {
          title: "austrian",
          img_url: "/assets/img/art/artist/austrian.png",
        },
        {
          title: "belgian",
          img_url: "/assets/img/art/artist/belgian.png",
        },
        {
          title: "bohemian",
          img_url: "/assets/img/art/artist/bohemian.png",
        },
        {
          title: "catalan",
          img_url: "/assets/img/art/artist/catalan.png",
        },
        {
          title: "danish",
          img_url: "/assets/img/art/artist/danish.png",
        },
        {
          title: "dutch",
          img_url: "/assets/img/art/artist/dutch.png",
        },
        {
          title: "english",
          img_url: "/assets/img/art/artist/english.png",
        },
        {
          title: "finnish",
          img_url: "/assets/img/art/artist/finnish.png",
        },
        {
          title: "flemish",
          img_url: "/assets/img/art/artist/flemish.png",
        },
        {
          title: "french",
          img_url: "/assets/img/art/artist/french.png",
        },
        {
          title: "german",
          img_url: "/assets/img/art/artist/german.png",
        },
        {
          title: "greek",
          img_url: "/assets/img/art/artist/greek.png",
        },
        {
          title: "hungarian",
          img_url: "/assets/img/art/artist/hungarian.png",
        },
        {
          title: "irish",
          img_url: "/assets/img/art/artist/irish.png",
        },
        {
          title: "italian",
          img_url: "/assets/img/art/artist/italian.png",
        },
        {
          title: "netherlandish",
          img_url: "/assets/img/art/artist/netherlandish.png",
        },
        {
          title: "norwegian",
          img_url: "/assets/img/art/artist/norwegian.png",
        },
        {
          title: "polish",
          img_url: "/assets/img/art/artist/polish.png",
        },
        {
          title: "portugees",
          img_url: "/assets/img/art/artist/portugess.png",
        },
        {
          title: "russian",
          img_url: "/assets/img/art/artist/russian.png",
        },
        {
          title: "scotish",
          img_url: "/assets/img/art/artist/scotish.png",
        },
        {
          title: "spanish",
          img_url: "/assets/img/art/artist/spanish.png",
        },
        {
          title: "swedish",
          img_url: "/assets/img/art/artist/swedish.png",
        },
        {
          title: "swiss",
          img_url: "/assets/img/art/artist/swiss.png",
        },
        {
          title: "other",
          img_url: "/assets/img/art/artist/ohter.png",
        },
      ];

      scope.description = '';
      scope.fullDescription = '';
      scope.showingFullDesc = false;
      scope.moreDescAvailable = false;
      scope.maxDescWords = 130;
      scope.selectedItem = scope.item;

      scope.colorHeaderData = {}

      scope.showMoreDesc = () => {
        if (scope.showingFullDesc) {
          scope.description = scope.fullDescription.substr(0, scope.maxDescWords);
        }
        else {
          scope.description = scope.fullDescription;
        }
        scope.showingFullDesc = !scope.showingFullDesc;
      };

      scope.boardType = "art";
      scope.showMoreColors = false;
      scope.showAutoPopup = false;
      scope.showColorPopup = false;
      scope.colorsCount = 22;
      scope.topColorsExpanded = false;
      scope.colorFrequencyExpanded = false;

      
      scope.colors = scope.colorSelectionData;

      scope.isModeArtist = () => {
        return scope.viewMode === "artist";
      };

      scope.isModePeriod = () => {
        return scope.viewMode === "period";
      };

      scope.isModeSchool = () => {
        return scope.viewMode === "school";
      };

      scope.isModeColor = () => {
        return scope.viewMode === "color";
      };

      scope.numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      if (scope.viewMode === "artist") {
        scope.isLoadingControls = true; 
          artDashboardRepository
          .getColorDataArtist(
            scope.selectedItem.id
          )
          .then(function (data) {
            scope.colorData = data.response;
            scope.artistHeaderData = scope.colorData.header;
            let desc = scope.artistHeaderData.description;
            if(desc){
              scope.fullDescription = desc;
              scope.description = desc.substr(0, scope.maxDescWords);
              scope.moreDescAvailable = desc.length > scope.maxDescWords;
            }
            scope.isLoadingControls = false; 
          });

          artDashboardRepository
          .getColorDataArtistChart1(
            scope.selectedItem.id
          )
          .then(function (data) {
            scope.colorDataTabArtistChart1 = data.response;
          });

          artDashboardRepository
          .getColorDataArtistChart2(
            scope.selectedItem.id
          )
          .then(function (data) {
            scope.colorDataTabArtistChart2 = data.response;
          });

          artDashboardRepository
          .getColorDataArtistChart3(
            scope.selectedItem.id
          )
          .then(function (data) {
            scope.colorDataTabArtistChart3 = data.response;
          });
      }
      if (scope.viewMode === "period") {
        scope.isLoadingControls = true; 
          artDashboardRepository
          .getColorDataPeriod(
            scope.selectedItem.title
          )
          .then(function (data) {
            scope.colorData = data.response;
            scope.periodHeaderData = scope.colorData.header;
            let desc = scope.periodHeaderData.description;
            if(desc){
              scope.fullDescription = desc;
              scope.description = desc.substr(0, scope.maxDescWords);
              scope.moreDescAvailable = desc.length > scope.maxDescWords;
            }
            scope.isLoadingControls = false; 
          });

          artDashboardRepository
          .getColorDataPeriodChart1(
            scope.selectedItem.title
          )
          .then(function (data) {
            scope.colorDataTabPeriodChart1 = data.response;
          });

          artDashboardRepository
          .getColorDataPeriodChart3(
            scope.selectedItem.title
          )
          .then(function (data) {
            scope.colorDataTabPeriodChart3 = data.response;
          });

          artDashboardRepository
          .getColorDataPeriodChart4(
            scope.selectedItem.title,
            'all'
          )
          .then(function (data) {
            scope.colorDataTabPeriodChart4 = data.response;
          });

          artDashboardRepository
          .getColorDataPeriodChart2(
            scope.selectedItem.title,
            'all'
          )
          .then(function (data) {
            scope.colorDataTabPeriodChart2 = data.response;
          });
      } 
      if (scope.viewMode === "school") {
        scope.isLoadingControls = true; 
          artDashboardRepository
          .getColorDataSchool(
            scope.selectedSchool.name
          )
          .then(function (data) {
            scope.colorData = data.response;
            scope.schoolHeaderData = scope.colorData.header;
            scope.filteredChoices.forEach(element => {
              if(element.title === scope.selectedSchool.name) {
                scope.selectedItem.img_url = element.img_url;
              }
            });

            artDashboardRepository
            .getColorDataSchoolChart1(
              scope.selectedSchool.name
            )
            .then(function (data) {
              scope.colorDataTabSchoolChart1 = data.response;

              artDashboardRepository
              .getColorDataSchoolChart2(
                scope.selectedSchool.name
              )
              .then(function (data) {
                scope.colorDataTabSchoolChart2 = data.response;

                artDashboardRepository
                .getColorDataSchoolChart3(
                  scope.selectedSchool.name
                )
                .then(function (data) {
                  scope.colorDataTabSchoolChart3 = data.response;

                  artDashboardRepository
                  .getColorDataSchoolChart5(
                    scope.selectedSchool.name,
                    'all'
                  )
                  .then(function (data) {
                    scope.colorDataTabSchoolChart5 = data.response;
                  });
                });
              });
            });

            scope.isLoadingControls = false; 
          });

          // artDashboardRepository
          // .getColorDataSchoolChart1(
          //   scope.selectedSchool.name
          // )
          // .then(function (data) {
          //   scope.colorDataTabSchoolChart1 = data.response;
          // });

          // artDashboardRepository
          // .getColorDataSchoolChart2(
          //   scope.selectedSchool.name
          // )
          // .then(function (data) {
          //   scope.colorDataTabSchoolChart2 = data.response;
          // });

          // artDashboardRepository
          // .getColorDataSchoolChart3(
          //   scope.selectedSchool.name
          // )
          // .then(function (data) {
          //   scope.colorDataTabSchoolChart3 = data.response;
          // });

          // artDashboardRepository
          // .getColorDataSchoolChart5(
          //   scope.selectedSchool.name,
          //   'all'
          // )
          // .then(function (data) {
          //   scope.colorDataTabSchoolChart5 = data.response;
          // });
      } 
      if (scope.viewMode === "color") {
        scope.isLoadingControls = true; 
      
          artDashboardRepository
          .getColorComparedData(
            scope.selectedSchool.name === 'All Schools' ? '' : scope.selectedSchool.name,
            scope.selectedItem.id
          )
          .then(function (data) {
            scope.colorData = data.response;
            scope.colorHeaderData = scope.colorData.header;
            let desc = scope.colorHeaderData.description;
            if(desc){
              scope.fullDescription = desc;
              scope.description = desc.substr(0, scope.maxDescWords);
              scope.moreDescAvailable = desc.length > scope.maxDescWords;
            }
            scope.isLoadingControls = false; 
          });

          artDashboardRepository
          .getColorComparedDataChart1(
            scope.selectedSchool.name === 'All Schools' ? '' : scope.selectedSchool.name,
            scope.selectedItem.id
          )
          .then(function (data) {
            scope.colorDataTabColorChart1 = data.response;
          });

          artDashboardRepository
          .getColorComparedDataChart2(
            scope.selectedSchool.name === 'All Schools' ? '' : scope.selectedSchool.name,
            scope.selectedItem.id
          )
          .then(function (data) {
            scope.colorDataTabColorChart2 = data.response;
          });

          artDashboardRepository
          .getColorComparedDataChart3(
            scope.selectedSchool.name === 'All Schools' ? '' : scope.selectedSchool.name,
            scope.selectedItem.id,
            'all'
          )
          .then(function (data) {
            scope.colorDataTabColorChart3 = data.response;
          });
      }
  
      scope.filteredResults = function (item, chart) {
        if(scope.viewMode  === "color") {
          artDashboardRepository
          .getColorComparedDataChart3(
            scope.selectedSchool.name === 'All School' ? '' : '',
            scope.selectedItem.id,
            item
          )
          .then(function (data) {
            scope.colorDataTabColorChart3 = data.response;
          });
        } else if(scope.viewMode  === "school") {
          if(chart === 'chart5'){
            artDashboardRepository
            .getColorDataSchoolChart5(
              scope.selectedSchool.name,
              item
            )
            .then(function (data) {
              scope.colorDataTabSchoolChart5 = data.response;
            });
          }
          //  else {
          //   artDashboardRepository
          //   .getColorDataSchoolChart4(
          //     scope.selectedSchool.name,
          //     item
          //   )
          //   .then(function (data) {
          //     scope.colorDataTabSchoolChart4 = data.response;
          //   });
          //   }
        } else if(scope.viewMode  === "period") {
          if(chart === 'chart2'){
            artDashboardRepository
            .getColorDataPeriodChart2(
              scope.selectedItem.title,
              item
            )
            .then(function (data) {
              scope.colorDataTabPeriodChart2 = data.response;
            });
          } else {
            artDashboardRepository
            .getColorDataPeriodChart4(
              scope.selectedItem.title,
              item
            )
            .then(function (data) {
              scope.colorDataTabPeriodChart4 = data.response;
            });
          }
        }
      };

      scope.artChartPopupAttributes = {
        modalTitle: ""
      };

      scope.toggleArtChartPopup = (needShow, type) => {
        if (needShow) {
          if(type === 'chart1'){
            scope.artChartPopupAttributes.modalTitle = 'OVERALL USE OF COLORS BY ' + scope.selectedItem.title + ' ARTISTS';
            scope.typeOfPopup = type;
          } else if(type === 'chart2'){
            scope.artChartPopupAttributes.modalTitle = 'OVERALL USE OF COLORS BY ' + scope.selectedItem.title + ' ARTISTS';
            scope.typeOfPopup = type;
          } else if(type === 'chart3'){
            scope.artChartPopupAttributes.modalTitle = 'USE OF ' + scope.selectedItem.name + ' COMPARED WITH THE USE OF ALL OTHER COLORS IN ' + scope.selectedSchool.name;
            scope.typeOfPopup = type;
          } else if(type === 'chart4'){
            scope.artChartPopupAttributes.modalTitle = 'COLOR COMBINATIONS FOR ' + scope.selectedItem.name + ' AND ALL OTHER COLORS ACROSS ALL ' + scope.selectedSchool.name + ' ARTISTS';
            scope.typeOfPopup = type;
          }
          scope.showArtChartPopup = true;
        } else {
          scope.showArtChartPopup = false;
        }
      };

      scope.$on("mood_state_chart", (data) => {
        let node = angular.element("#artPopupCapture")[0];
        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            if(dataUrl) {
              scope.saveImageDetailsData = {
                base64: dataUrl,
                type: 'art'
              };
            }
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });

          let times = 2000;
          timeout(function () {
            if(scope.saveImageDetailsData) {
              scope.showArtChartPopup = false;
              scope.toggleSaveImageDetails();
            } else {
              timeout(function () {
                if(scope.saveImageDetailsData) {
                  scope.showArtChartPopup = false;
                  scope.toggleSaveImageDetails();
                }
              }, times);
            }
          }, times);
      });

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
        result += name.toUpperCase() + ".";
        return result + date + type;
      };

      scope.toggleSaveImageDetails = () => {
        scope.saveImageDetails = scope.saveImageDetails ? false : true;
      };

      scope.$on("mood_state_chart_changed", () => {
        scope.toggleSaveImageDetails();
      });
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbArtColorData/dbArtColorDataView.html",
      link: link,
      scope: {
        item: "=",
        viewMode: "=",
        colorSelectionData: "=",
        selectedSchool: "=",
      },
    };
  },
]);
