angular
  .module('app')
  .component('educationPodcastComponent', {
    templateUrl: 'app/components/education-podcast/education-podcast.tmpl.html',
    controller: function (educationService, $scope, $http, appConfig) {
      const self = this;
      self.posts = [];
      self.categoryList = [];
      self.archiveList = [];
      self.currentPage = 0;
      self.socialLinks = appConfig.socialLinks;

      self.setPage = (num) => {
        if (num >= 0 && num < self.pageCount) {
          self.currentPage = num;
          self.loadGraphics();
        }
      };

      self.loadGraphics = function () {
        educationService.getPodcast(self.currentPage + 1).then(res => {
          let posts = res.data;
  
          posts.collections.forEach(item => {
            item.description = item.description.replace(/<p>&nbsp;<\/p>/gi, '');
            item.header = item.header[0] + item.header.slice(1).toLowerCase();
            item.isAudio = false;
          });
          self.posts = [...posts.collections];
          self.pageCount = posts.paginate.total_pages;
        });
      };

      self.loadGraphics();

      self.play = (pod) => {
        self.posts.forEach(item => {
          if(pod.id === item.id){
            item.isAudio = true;
            var audio = document.getElementById(pod.id);
            audio.play();
          } else {
            item.isAudio = false;
            var audio = document.getElementById(item.id);
            audio.pause();
          }
        });
      }

      self.monthNumToText = function (num) {
        return moment(num).format('LL');
      };
    }
  });
