angular.module("app").directive("birdSizeAcrossFamilies", () => {
  let link = (scope) => {
    scope.birdColors = [
      {
        id: 0,
        title: "mixed",
        hex: "#a3d8e4",
        hex1: "#ca7db2",
      },
      {
        id: 1,
        title: "pink",
        hex: "#f7c6de",
      },
      {
        id: 2,
        title: "red",
        hex: "#e51d1c",
      },
      {
        id: 3,
        title: "brown",
        hex: "#c55531",
      },
      {
        id: 4,
        title: "darkorange",
        hex: "#f07c16",
      },
      {
        id: 5,
        title: "yellow",
        hex: "#fbdf16",
      },
      {
        id: 6,
        title: "lemonchiffon",
        hex: "#f0dc83",
      },
      {
        id: 7,
        title: "yellowgreen",
        hex: "#8db624",
      },
      {
        id: 8,
        title: "green",
        hex: "#0a8236",
      },
      {
        id: 9,
        title: "blue",
        hex: "#335ca7",
      },
      {
        id: 10,
        title: "purple",
        hex: "#6d4595",
      },
      {
        id: 11,
        title: "wheat",
        hex: "#eed5b4",
      },
      {
        id: 12,
        title: "brown1",
        hex: "#974d15",
      },
      {
        id: 13,
        title: "gray",
        hex: "#808081",
      },
      {
        id: 14,
        title: "white",
        hex: "#ffffff",
      },
      {
        id: 15,
        title: "black",
        hex: "#050607",
      },
    ];
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbBirdSizeAcrossFamilies/dbBirdSizeAcrossFamiliesView.html",
    link: link,
    scope: {
    }
  };
});
