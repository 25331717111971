angular.module('app').directive('colorFrequencyByFragrance', () => {
  let link = (scope) => {
    scope.getWidth = function (index) {
      let totalPercentage = 0;
      if (scope.data.length > 0 ) {
        scope.data.forEach(item => {
          if(typeof(item.percentage) == "string") {
            totalPercentage += parseFloat(item.percentage);
          } else {
            totalPercentage += item.percentage;
          }
        });
        let indexPercentage = parseFloat(scope.data[index].percentage);
        return parseFloat((100 / totalPercentage) * indexPercentage) + '%';
      }
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorFrequencyByFragrance/dbColorFrequencyByFragranceView.html",
    link: link,
    scope: {
      data: "=",
    }
  };
});
