angular.module("app").directive("wordCloud", () => {
  let link = (scope) => {
    scope.$watch("wordArray", (newValue) => {
      if(newValue){
        bindData();
      }
    });
    scope.showImageDetails = false;

    scope.toggleImageDetails = (item) => {
      scope.showImageDetails = !scope.showImageDetails;
      scope.wordArrayData = item;
    };

    function bindData() {
      // scope.wordArray.forEach(element => {
      //   element.handlers = {
      //     click: function() {
      //       scope.toggleImageDetails(element.colorData)
      //     }
      //   }
      // });
      const $this = $("#word-cloud");
      // Namespace word ids to avoid collisions between multiple clouds
      const cloud_namespace = $this.attr('id') || Math.floor((Math.random()*1000000)).toString(36);

      const width = 1000;
      const height = 600;

      // Default scope.options value
      const default_options = {
        width: width,
        height: height,
        center: {
          x: ((scope.options && scope.options.width) ? scope.options.width : $this.width()) / 2.0,
          y: ((scope.options && scope.options.height) ? scope.options.height : $this.height()) / 2.0
        },
        delayedMode: scope.wordArray.length > 50,
        shape: false, // It defaults to elliptic shape
        encodeURI: true,
        removeOverflowing: true,
        steps: 30
      };

      scope.options = $.extend(default_options, scope.options || {});

      // Add the "jqcloud" class to the container for easy CSS styling, set container width/height
      $this.addClass("jqcloud").width(scope.options.width).height(scope.options.height);

      // Container's CSS position cannot be 'static'
      if ($this.css("position") === "static") {
        $this.css("position", "relative");
      }

      const drawWordCloud = function() {
        // Helper function to test if an element overlaps others
        const hitTest = function(elem, other_elems) {
          // Pairwise overlap detection
          const overlapping = function(a, b) {
            if (Math.abs(2.0*a.offsetLeft + a.offsetWidth - 2.0*b.offsetLeft - b.offsetWidth) < a.offsetWidth + b.offsetWidth) {
              if (Math.abs(2.0*a.offsetTop + a.offsetHeight - 2.0*b.offsetTop - b.offsetHeight) < a.offsetHeight + b.offsetHeight) {
                return true;
              }
            }
            return false;
          };
          // Check elements for overlap one by one, stop and return false as soon as an overlap is found
          for(let i = 0; i < other_elems.length; i++) {
            if (overlapping(elem, other_elems[i])) {
              return true;
            }
          }
          return false;
        };

        // Make sure every de2000 is a number before sorting
        // for (let i = 0; i < scope.wordArray.length; i++) {
        //   scope.wordArray[i].de2000 = parseFloat(scope.wordArray[i].de2000, 10);
        // }

        // // Sort scope.wordArray from the word with the highest de2000 to the one with the lowest
        // scope.wordArray.sort(function(a, b) { if (a.de2000 < b.de2000) {return 1;} else if (a.de2000 > b.de2000) {return -1;} else {return 0;} });

        const step = (scope.options.shape === "rectangular") ? 18.0 : 18.0,
          already_placed_words = [],
          aspect_ratio = scope.options.width / scope.options.height;

        // Function to draw a word, by moving it in spiral until it finds a suitable empty place. This will be iterated on each word.
        const drawOneWord = function(index, word) {
          // Define the ID attribute of the span that will wrap the word, and the associated jQuery selector string
          let word_id = cloud_namespace + "_word_" + index;
          let angle = 6.28 * Math.random();
          let radius = 0.0;

          // Only used if option.shape == 'rectangular'
          let steps_in_direction = 0.0;
          let quarter_turns = 0.0;

          let weight = 1;
          let custom_class = "";
          let inner_html = "";
          let word_span;

          // Extend word html scope.options with defaults
          word.html = $.extend(word.html, {id: word_id});

          // If custom class was specified, put them into a variable and remove it from html attrs, to avoid overwriting classes set by jQCloud
          if (word.html && word.html["class"]) {
            custom_class = word.html["class"];
            delete word.html["class"];
          }

          // Check if min(de2000) > max(de2000) otherwise use default
          // if (scope.wordArray[0].de2000 > scope.wordArray[scope.wordArray.length - 1].de2000) {
          //   // Linearly map the original de2000 to a discrete scale from 1 to 10
          //   weight = Math.round((word.de2000 - scope.wordArray[scope.wordArray.length - 1].de2000) /
          //     (scope.wordArray[0].de2000 - scope.wordArray[scope.wordArray.length - 1].de2000) * 9.0) + 1;
          // }
          if (index === 0) {
            weight = 5;
          } else if (index >= 1 && index <= 25 ) {
            weight = 4;
          } else if (index > 26 && index <= 50 ) {
            weight = 3;
          } else if (index > 51 && index <= 200 ) {
            weight = 2;
          } else if (index > 201 && index <= 299 ) {
            weight = 1;
          }
          word_span = $('<span>').attr(word.html).addClass('w' + weight + " " + custom_class);

          // Append link if word.url attribute was set
          if (word.link) {
            // If link is a string, then use it as the link href
            if (typeof word.link === "string") {
              word.link = {href: word.link};
            }

            // Extend link html scope.options with defaults
            if ( scope.options.encodeURI ) {
              word.link = $.extend(word.link, { href: encodeURI(word.link.href).replace(/'/g, "%27") });
            }

            inner_html = $('<a>').attr(word.link).text(word.name);
          } else {
            inner_html = word.name;
          }
          word_span.append(inner_html);

          // Bind handlers to words
          if (!!word.handlers) {
            for (const prop in word.handlers) {
              if (word.handlers.hasOwnProperty(prop) && typeof word.handlers[prop] === 'function') {
                $(word_span).bind(prop, word.handlers[prop]);
              }
            }
          }

          $this.append(word_span);

          const width = word_span.width();
          const height = word_span.height();
          let left = scope.options.center.x - width / 2.0;
          let top = scope.options.center.y - height / 2.0;

          // Save a reference to the style property, for better performance
          const word_style = word_span[0].style;
          word_style.position = "absolute";
          word_style.left = left + "px";
          word_style.top = top + "px";
          word_style.cursor = "pointer";
          word_style['text-transform'] = "lowercase";

          while(hitTest(word_span[0], already_placed_words)) {
            // option shape is 'rectangular' so move the word in a rectangular spiral
            if (scope.options.shape === "rectangular") {
              steps_in_direction++;
              if (steps_in_direction * step > (1 + Math.floor(quarter_turns / 2.0)) * step * ((quarter_turns % 4 % 2) === 0 ? 1 : aspect_ratio)) {
                steps_in_direction = 0.0;
                quarter_turns++;
              }
              switch(quarter_turns % 4) {
                case 1:
                  left += step * aspect_ratio + Math.random() * 2.0;
                  break;
                case 2:
                  top -= step + Math.random() * 2.0;
                  break;
                case 3:
                  left -= step * aspect_ratio + Math.random() * 2.0;
                  break;
                case 0:
                  top += step + Math.random() * 2.0;
                  break;
              }
            } else { // Default settings: elliptic spiral shape
              radius += step;
              angle += (index % 2 === 0 ? 1 : -1)*step;

              left = scope.options.center.x - (width / 2.0) + (radius*Math.cos(angle)) * aspect_ratio;
              top = scope.options.center.y + radius*Math.sin(angle) - (height / 2.0);
            }
            word_style.left = left + "px";
            word_style.top = top + "px";
          }

          // Don't render word if part of it would be outside the container
          if (scope.options.removeOverflowing && (left < 0 || top < 0 || (left + width) > scope.options.width || (top + height) > scope.options.height)) {
            word_span.remove();
            return;
          }

          already_placed_words.push(word_span[0]);
        };

        $this.html('');
        // Iterate drawOneWord on every word.
        $.each(scope.wordArray, drawOneWord);
      };

      // Delay execution so that the browser can render the page before the computatively intensive word cloud drawing
      setTimeout(function(){drawWordCloud();}, 10);
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/wordCloud/wordCloudView.html",
    link: link,
    scope: {
      wordArray: "=",
      options: "=",
    }
  };
});
