angular.module("app").directive("cityTopFourColorAcrossSeasonsChart", () => {
  let link = (scope) => {
    scope.$watch('data', (data) => {
      if (data) {
        scope.data.map(obj => {
          if(obj.name === 'pre_fall'){
            obj.name = 'pre fall'
          }
        });
      }
    });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbCityTopFourColorAcrossSeasonsChart/dbCityTopFourColorAcrossSeasonsChartView.html",
    link: link,
    scope: {
      data: "=",
      type: "="
    }
  };
});
