angular.module('app').directive('autoYearComplete', [
  "autoDashboardRepository",
  function (
    autoDashboardRepository
  ) {
    function link (scope) {
      
    scope.filteredChoices = [];

    scope.loadFashionYears = () => {
      scope.ajaxInProgress = true;
      autoDashboardRepository.getColorsForFilter().then(data => { 
        if (data.data.years) {
          scope.filteredChoices = data.data.years.map(item => {
            return { title: item, id: item };
          });
          scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1);
        }
      }).finally(() => {
        scope.ajaxInProgress = false;
      });
    };

    scope.loadFashionYears();

      scope.isVisible = {
        suggestions: false,
      };

      scope.filterItems = () => {
        if (scope.enteredtext.length < 0) return;
        scope.isVisible.suggestions =
          scope.enteredtext.length === 0
            ? false
            : scope.filteredChoices.length > 0;
      };

      scope.selectItem = (choice) => {
        scope.selected = choice;
        scope.enteredtext = choice.title;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbAutoYearComplete/dbAutoYearCompleteView.html",
      link: link,
      scope: {
        enteredtext: "=",
        selected: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        disabled: "=",
        disable: "=",
        board: "=",
      }
    };
  }
]);
