(function () {
  'use strict';

  angular.module('app').directive('hueChartColorsPerRegion',
    [
      'common', 'config', 'chartsHelper',
      function (common, config, chartsHelper) {

        function link(scope, element) {
          scope.$watch('data', bindData);

          function bindData() {
            if (!scope.data) {
              return;
            }

            const containers = element.find('[chart-block]');

            _.each(containers, function (c) {
              let ch;
              const container = $(c);
              const regionName = container.attr('chart-block');

              const containerBar = container.find('[chart-type="bar"]').html('');
              const containerMap = container.find('[chart-type="map"]').html('');

              const region = _.find(scope.data, {name: regionName});
              {
                const sum = _.sumBy(region.data, 'percentage');
                const data = _.map(region.data, function (d) {
                  const v = d.percentage / sum;
                  return {
                    value: v,
                    title: Math.round(d.percentage * 100) + '%',
                    color: d.color
                  }
                });

                if (!region || !region.data || !region.data.length) {
                  containerBar.append($('<p class="no-data">No data is available</p>'));
                } else {
                  ch = new chartBoxLinearBarVertical({
                    data: data,
                    container: containerBar[0],
                    options: {}
                  });
                }


              }
            });

          }
        }

        const directive = {
          link: link,
          restrict: 'E',
          replace: true,
          scope: {
            data: '='
          },
          templateUrl: 'app/directives/custom-infographics-directives/chart-colors-per-region.html'
        };

        return directive;

      }
    ]);
}());
