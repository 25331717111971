angular.module('app').directive('legalYearComplete', [
  "legalTrendsDashboardRepository",
  (legalTrendsDashboardRepository) => {
    let link = (scope) => {
      scope.filteredChoices = [];
      scope.isVisible = {
        suggestions: false,
      };

      scope.loadYears = () => {
        scope.ajaxInProgress = true;
        legalTrendsDashboardRepository.getYearList().then(data => {
          if (data.years) {
            scope.filteredChoices = data.years.map(item => {
              return { title: item, id: item };
            });
            scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1);
          }
        }).finally(() => {
          scope.ajaxInProgress = false;
        });
      };
  
      scope.loadYears();

      scope.filterItems = () => {
        if (scope.enteredtext.length < 0) return;
        scope.isVisible.suggestions =
          scope.enteredtext.length === 0
            ? false
            : scope.filteredChoices.length > 0;
      };

      scope.selectItem = (choice) => {
        scope.selected = choice;
        scope.enteredtext = choice.title;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbLegalYearComplete/dbLegalYearCompleteView.html",
      link: link,
      scope: {
        enteredtext: "=",
        selected: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        disabled: "=",
        disable: "=",
        board: "=",
      }
    };
  }
]);
