angular.module("app").controller("contentFashionController", [
  "$scope",
  "appConfig",
  "$http",
  "authService",
  "$location",
  "modalService",
  "$state",
  "$rootScope",
  (
    scope,
    appConfig,
    $http,
    authService,
    $location,
    modalService,
    $state,
    $rootScope
  ) => {
    const vm = this;

    vm.filter = {};
    vm.grayList = {};

    scope.tab = 1;

    scope.graphIndex = 0;
    scope.selected_moodboard = {};
    scope.showGraph = false;
    scope.images = [];
    scope.subscribed = true;
    scope.showGraph = false;

    // check if subscribed
    const checkSubscribed = () => {
      if (authService.token) {
        scope.subscribed = true;
      } else {
        $http({
          url: appConfig.dashboardServiceUrl + "api/subscribed.json",
          method: "GET",
          headers: {
            Authorizing: authService.token,
            "Content-Type": "application/json",
          },
          success: () => {
            scope.subscribed = true;
          },
          error: () => {
            scope.subscribed = false;
          },
        }).catch(() => {
          scope.subscribed = false;
        });
      }
    };
    checkSubscribed();

    scope.closeSubscribe = () => {
      scope.subscribed = true;
    };

    scope.subscribe = () => {
      scope.subscribed = true;
    };

    scope.data = {
      email: { value: "", required: true, name: "email", type: "" },
    };

    scope.subscribe = function () {
      const data = { email: scope.data.email.value };
      $http
        .post(appConfig.dashboardServiceUrl + "api/forms/stay_connected.json", {
          contact: data,
        })
        .then(function (res) {
          if (res.status === 201) {
            this.email = "";
            modalService.showModal(
              0,
              null,
              [
                "Thank you for signing up! Welcome to the HUEDATA community! Check your inbox!",
              ],
              true
            );
            scope.subscribed = true;
          } else {
            modalService.showModal(0, null, ["Please try again"], false);
          }
        });
    };

    scope.backToSearch = () => {
      $rootScope.showDashboard = false;
      $state.go("fashion");
    };

    scope.getModel = (index) => {
      return vm.models[index];
    };

    scope.getTitle = (index) => {
      return vm.titles[index];
    };

    scope.getDescription = (index) => {
      return vm.descriptions[index];
    };

    scope.showGraphDialog = (index) => {
      scope.graphIndex = index;
      scope.showGraph = true;
    };

    scope.shareFacebook = () => {
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" + $location.absUrl()
      );
    };

    scope.shareTwitter = () => {
      window.open("https://twitter.com/home?status=" + $location.absUrl());
    };

    scope.sharePinterest = () => {
      window.open(
        "https://pinterest.com/pin/create/button/?url=" + $location.absUrl()
      );
    };

    scope.shareGooglePlus = () => {
      window.open("https://plus.google.com/share?url=" + $location.absUrl());
    };

    const getObject = (id, prop) => {
      let obj = {
        id: "all",
        title: "all",
      };

      if (id === "") {
        return obj;
      }

      for (
        let i = 0;
        i < scope.controlsData && scope.controlsData[prop].length;
        i++
      ) {
        const data = scope.controlsData[prop][i];
        if (data.id === id) {
          obj = data;
          break;
        }
      }
      return obj;
    };

    vm.prepareRequestParams = () => {
      const params = {
        city: getObject(scope.menus.city, "cities").title,
        year: scope.menus.year == "" ? "all" : scope.menus.year,
        season: getObject(scope.menus.season, "seasons").title,
        category: getObject(scope.menus.category, "categories").title,
        region: getObject(scope.menus.region, "regions").title,
        designer: getObject(scope.menus.designer, "designers").title,
      };

      return params && params;
    };

    vm.prepareColorsParams = () => {
      const param = {};
      if (scope.menus.city !== "") {
        param.city_id = scope.menus.city;
      }
      if (scope.menus.season !== "") {
        param.season_id = scope.menus.season;
      }
      if (scope.menus.category !== "") {
        param.category_id = scope.menus.category;
      }
      if (scope.menus.designer !== "") {
        param.designer_id = scope.menus.designer;
      }

      if (scope.menus.year == "") {
        param.year_id = "all";
      } else {
        param.year_id = scope.menus.year;
      }

      return param;
    };

    vm.prepareColors = () => {
      if (scope.menus.season !== "") {
        return {
          all: getObject(scope.menus.season, "seasons"),
          category: "season",
        };
      }
      if (scope.menus.city !== "") {
        return { all: getObject(scope.menus.city, "cities"), category: "city" };
      }
      if (scope.menus.category !== "") {
        return {
          all: getObject(scope.menus.category, "categories"),
          category: "category",
        };
      }
      if (scope.menus.designer !== "") {
        return {
          all: getObject(scope.menus.designer, "designers"),
          category: "designer",
        };
      }
      return { all: { id: 2018 }, category: "year" };
    };

    scope.isSet = (tabId) => {
      return scope.tab === tabId;
    };

    scope.isActiveTab = (tab) => {
      return scope.activeTab === tab;
    };

    scope.isShowGraphDialog = () => {
      return scope.showGraph;
    };

    scope.closeGraphDialog = () => {
      scope.showGraph = false;
    };

    scope.topColorsExpanded = false;
    scope.toggleTopColorsExpandedMode = () => {
      scope.topColorsExpanded = !scope.topColorsExpanded;
    };

    scope.colorFrequencyExpanded = false;
    scope.toggleColorFrequencyExpandedMode = () => {
      scope.colorFrequencyExpanded = !scope.colorFrequencyExpanded;
    };

    scope.colorFrequencyByRegionExpanded = false;
    scope.toggleColorFrequencyByRegionExpandedMode = () => {
      scope.colorFrequencyByRegionExpanded = !scope.colorFrequencyByRegionExpanded;
    };

    scope.colorFrequencyByCityExpanded = false;
    scope.toggleColorFrequencyByCityExpandedMode = () => {
      scope.colorFrequencyByCityExpanded = !scope.colorFrequencyByCityExpanded;
    };

    scope.colorPaletteExpanded = false;
    scope.toggleColorPaletteExpandedMode = () => {
      scope.colorPaletteExpanded = !scope.colorPaletteExpanded;
    };

    scope.designerImagesExpanded = false;
    scope.toggleDesignerImagesExpandedMode = () => {
      scope.designerImagesExpanded = !scope.designerImagesExpanded;
    };
  },
]);
