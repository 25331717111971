angular
  .module('app')
  .component('colorIndexComponent', {
    templateUrl: 'app/components/color-index/color-index.tmpl.html',
    controller: function ($location, $scope, $http, appConfig, anchorSmoothScroll, searchColor, modalService, colorRequest,colorNamingIndexServiced, localStorageService ) {
      const vm = this;

      vm.currentSection = 0; // 0 => No tab visible, 1 => color_picker, 2 => color_naming_index
      vm.currentSubSection = 0; // 0 => No sub-section, 1 => color_picker_sub_section, 2 => color_naming_index_sub_section

      vm.currentColor = 'Invalid Color';
      vm.colorFamily = "";

      vm.currentMode = '';
      $scope.viewMode = '';
      $scope.ajaxInProgress = false;
      $scope.selectedTextNamingIndexCharacter = "A-Z";
      $scope.selectedTextRelevantNamingIndexCharacter = "A-Z";
      $scope.azClosestMode = false;
      $scope.relevantColorNames = [];
      $scope.closestColorData = [];
      $scope.mainAllClosest = [];
      $scope.mainClosest = [];
      $scope.mainVeryClosest = [];
      $scope.mainClose = [];

      vm.resetSubSection = () => {
        vm.currentSubSection = 0;
      };

      if(localStorageService.get("currentUser")){
        $scope.user = localStorageService.get("currentUser");
      }

      vm.setCurrentSection = index => {
        vm.resetSubSection();
        vm.currentSection = index;

        setTimeout(() => {
          let element;
          if (index === 1) {
            element = document.getElementById("color_picker_content");
          } else if (index === 2) {
            element = document.getElementById("color_naming_index_content");
          }
          if (element) {
            element.scrollIntoView({ block: "start" });
          }
        }, 10);
      };

      vm.setCurrentSubSection = index => {
        vm.currentSubSection = index;

        if (index != 0) {
          setTimeout(() => {
            const element = document.getElementById("content_sub_sections");
            if (element) {
              element.scrollIntoView({ block: "start" });
            }
          }, 10);
        }
      };

      vm.setCurrentColor = color => {
        vm.currentColor = color;
      };

      vm.setMode = mode => {
        vm.currentMode = mode;
        $scope.viewMode = mode;
      };

      // Color picker js >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

      vm.gotoElement = function (eID) {
        $location.hash('prefooter');
        anchorSmoothScroll.scrollTo(eID);
        $location.hash('');
      };
      vm.numOfpaintColorNames = 0;
      vm.numOfcolorAssociationNames = 0;
      vm.colorAssociationNameWord = '';

      const rgbToHex = (r, g, b) => {
        return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
      };

      $scope.setRgb = function (r, g, b) {
        $scope.colorRGB_R = r;
        $scope.colorRGB_G = g;
        $scope.colorRGB_B = b;
      };

      vm.statusByRGB = function (RGBString) {
        colorNamingIndexServiced.colorClosestRGBStatus(RGBString).then(statusData => {
          if(statusData.message === "processing") {
            setTimeout(() => {
              vm.statusByRGB(RGBString)
            }, 40000);
          } else {
            $scope.ajaxInProgress = false;
            $scope.currentPageClosest = 1;
            $scope.closestColorData = [];
            $scope.mainAllClosest = [];
            $scope.mainClosest = [];
            $scope.mainClosestDetla = [];
            $scope.mainVeryClosest = [];
            $scope.mainClose = [];
            $scope.allFiles = statusData.files;
            $scope.allCounts = statusData.counts;
            $http.get($scope.allFiles.all_closest_rgbs).then(function(response) {
              $scope.mainAllClosest = response.data.color_names;
            });
            $http.get($scope.allFiles.closest_rgbs).then(function(response) {
              $scope.mainClosest = response.data.color_names;
              let tempData = response.data.color_names;
              $scope.mainClosestDetla = tempData;
              $scope.closestColorData = $scope.mainClosest.slice(0, 120);
              $scope.mainClosestDetla.sort((a, b) => { return a.de2000 - b.de2000 });
              $scope.colorCloudData = $scope.mainClosestDetla.slice(0, 300);
              $scope.closestName = $scope.colorCloudData[0].name;
              $scope.isLoadedAllDataClosest = true;
            });
            $http.get($scope.allFiles.very_closest_rgbs).then(function(response) {
              $scope.mainVeryClosest = response.data.color_names;
            });
            $http.get($scope.allFiles.close_rgbs).then(function(response) {
              $scope.mainClose = response.data.color_names;
            });
          }
        })
      }

      vm.searchByRGB = function (sectionIndex) {
        $scope.ajaxInProgress = true;
        const RGB = {red: $scope.colorRGB_R, green: $scope.colorRGB_G, blue: $scope.colorRGB_B};
        $scope.RGBString = `${$scope.colorRGB_R},${$scope.colorRGB_G},${$scope.colorRGB_B}`;
        
        const hexColor = rgbToHex($scope.colorRGB_R, $scope.colorRGB_G, $scope.colorRGB_B);
        const colorNTC = ntc.name(hexColor);

        colorNamingIndexServiced.colorClosestRGB($scope.RGBString, $scope.azClosestMode).then(closestData => {
        })

        if (colorNTC[1].slice(0, 13) !== 'Invalid Color') {
          $scope.params = {mode: vm.currentMode, color: vm.currentColor};
          setTimeout(() => {
            vm.setMode('color-search');
            vm.setCurrentColor(colorNTC[1]);
            vm.setCurrentSubSection(sectionIndex);                
          }, 500);
        } else {
          modalService.showModal(5);
        }

        setTimeout(() => {
          vm.statusByRGB($scope.RGBString)
        }, 10000);        
      };

      vm.params = $location.search();
      if (vm.params.hasOwnProperty("red")) {
        vm.currentSection = 1;
        $scope.setRgb(parseInt(vm.params.red), parseInt(vm.params.green), parseInt(vm.params.blue));
        vm.searchByRGB(1);
      }

      // End >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

      // Color index section js >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

      vm.paintColorNamesData = [];
      vm.colorAssociationNames = [];

      // $scope.bubbleData = []
      $scope.attrData = {}

      vm.colorSearch = function () {
        $scope.isLoadedAllData = true;
        $scope.isLoadedAllDataExact = true;
        $scope.currentPage = 1;
        $scope.currentPageExact = 1;
        if (this.data.color !== "") {
          $scope.ajaxInProgress = true;
          colorNamingIndexServiced.colorNamingIndexBubble(this.data.color).then(colorIUndexData => {
            if(colorIUndexData.limit === 'exceed'){
              $scope.ajaxInProgress = false;
              modalService.showModal(11, null, $scope.user.first_name);
            } else {
              $scope.bubbleData = colorIUndexData;
              colorNamingIndexServiced.headerAttributeData(this.data.color).then(colorAttributeData => {
                $scope.attrData = colorAttributeData;
              });

              colorNamingIndexServiced.getColorList().then(res => {
                $scope.colorList = res.colors;
              });

              vm.setMode('word-search');
              $scope.params = {mode: vm.currentMode, color: vm.currentColor};
              vm.setCurrentSubSection(2);
              vm.setCurrentColor(vm.data.color);

              colorNamingIndexServiced.relevantColorSearchByWord(this.data.color, $scope.selectedTextRelevantNamingIndexCharacter, $scope.currentPage).then(data => {  
                $scope.relevantColorNames = data.relevent.colors; 
                $scope.relevantCount = data.relevent.pagination.total_entries;
                if (data.relevent.pagination.total_pages === $scope.currentPage) {
                  $scope.isLoadedAllData = false;
                }
                if (data.relevent.pagination.total_entries < 60) {
                  $scope.isLoadedAllData = false;
                }
              }); 

              colorNamingIndexServiced.extraColorSearchByWord(this.data.color, $scope.selectedTextNamingIndexCharacter, $scope.currentPageExact).then(data => {  
                $scope.exactColorNames = data.exact.colors;
                $scope.exactCount = data.exact.pagination.total_entries;
                if (data.exact.pagination.total_pages === $scope.currentPageExact) {
                  $scope.isLoadedAllDataExact = false;
                }
                if (data.exact.pagination.total_entries < 60) {
                  $scope.isLoadedAllDataExact = false;
                }
              }); 

              $scope.ajaxInProgress = false;
            } 
          })
        }
      };

      $scope.textNamingIndexCharacterSelect = (value) => {
        $scope.selectedTextNamingIndexCharacter = value;
        $scope.isLoadedAllDataExact = true;
        $scope.currentPageExact = 1;
        colorNamingIndexServiced.extraColorSearchByWord(this.data.color, $scope.selectedTextNamingIndexCharacter, $scope.currentPageExact).then(data => { 
          $scope.exactColorNames = data.exact.colors;
          $scope.exactCount = data.exact.pagination.total_entries;
          if (data.exact.pagination.total_pages === $scope.currentPageExact) {
            $scope.isLoadedAllDataExact = false;
          }
          if (data.exact.pagination.total_entries < 60) {
            $scope.isLoadedAllDataExact = false;
          }
        });
      };

      $scope.isTextNamingIndexSelected = (value) => {
        return $scope.selectedTextNamingIndexCharacter === value;
      };

      $scope.textRelevantNamingIndexCharacterSelect = (value) => {
        $scope.selectedTextRelevantNamingIndexCharacter = value;
        $scope.isLoadedAllData = true;
        $scope.currentPage = 1;
        colorNamingIndexServiced.relevantColorSearchByWord(this.data.color, $scope.selectedTextRelevantNamingIndexCharacter, $scope.currentPage).then(data => { 
          $scope.relevantColorNames = data.relevent.colors;
          $scope.relevantCount = data.relevent.pagination.total_entries;
          if (data.relevent.pagination.total_pages === $scope.currentPage) {
            $scope.isLoadedAllData = false;
          }
          if (data.relevent.pagination.total_entries < 60) {
            $scope.isLoadedAllData = false;
          }
        });
      };

      $scope.isTextRelevantNamingIndexSelected = (value) => {
        return $scope.selectedTextRelevantNamingIndexCharacter === value;
      };

      $scope.activeTabId = 1;
      $scope.selectedMenuId = 2;
      $scope.currentPage = 1;
      $scope.currentPageExact = 1;
      $scope.currentPageClosest = 1;
      $scope.isLoadedAllData = true;
      $scope.isLoadedAllDataExact = true;
      $scope.showColorPopup = false;
      $scope.contentData = [];

      $scope.params = {mode: vm.currentMode, color: vm.currentColor};
      $scope.viewMode = 'color-search';
      // $scope.loadData = () => {
      //   colorRequest
      //     .getRgb($scope.params.color, $scope.currentPage + 1)
      //     .then((res) => {
      //       $scope.contentData = res.color_names;
      //       $scope.pageCount = res.paginate.total_pages;
      //       $scope.params = {mode: vm.currentMode, color: vm.currentColor};
      //     });
      // };

      // $scope.loadData();

      $scope.menu = [
        { id: 1, title: "All" },
        { id: 2, title: "Closest" },
        { id: 3, title: "Very Close" },
        { id: 4, title: "Close" },
      ];
      $scope.isSetId = function (id) {
        return $scope.selectedMenuId === id;
      };
      $scope.setMenuId = function (id) {
        $scope.selectedMenuId = id;
        $scope.currentPageClosest = 1;
        if($scope.selectedMenuId === 1) {
          if($scope.mainAllClosest.length > 0) {
            $scope.closestColorData = $scope.mainAllClosest.slice(0, 120);
            if($scope.azClosestMode) {
              $scope.closestColorData.sort((a, b) => { return a.de2000 - b.de2000 });
            }
            if($scope.mainAllClosest.length > 120) {
              $scope.isLoadedAllDataClosest = true;
            } else {
              $scope.isLoadedAllDataClosest = false;
            }
          }
        }
        if($scope.selectedMenuId === 2) {
          if($scope.mainClosest.length > 0) {
            $scope.closestColorData = $scope.mainClosest.slice(0, 120);
            if($scope.azClosestMode) {
              $scope.closestColorData.sort((a, b) => { return a.de2000 - b.de2000 });
            } else {
              $scope.closestColorData.sort((a, b) => { 
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });
            }
            if($scope.mainClosest.length > 120) {
              $scope.isLoadedAllDataClosest = true;
            } else {
              $scope.isLoadedAllDataClosest = false;
            }
          }
        }
        if($scope.selectedMenuId === 3) {
          if($scope.mainVeryClosest.length > 0) {
            $scope.closestColorData = $scope.mainVeryClosest.slice(0, 120);
            if($scope.azClosestMode) {
              $scope.closestColorData.sort((a, b) => { return a.de2000 - b.de2000 });
            }
            if($scope.mainVeryClosest.length > 120) {
              $scope.isLoadedAllDataClosest = true;
            } else {
              $scope.isLoadedAllDataClosest = false;
            }
          }
        }
        if($scope.selectedMenuId === 4) {
          if($scope.mainClose.length > 0) {
            $scope.closestColorData = $scope.mainClose.slice(0, 120);
            if($scope.azClosestMode) {
              $scope.closestColorData.sort((a, b) => { return a.de2000 - b.de2000 });
            }
            if($scope.mainClose.length > 120) {
              $scope.isLoadedAllDataClosest = true;
            } else {
              $scope.isLoadedAllDataClosest = false;
            }
          }
        }
      };

      $scope.loadMoreClosest = function () {
        $scope.currentPageClosest += 1;
        if($scope.selectedMenuId === 1) {
          let count = $scope.currentPageClosest * 120;
          if($scope.mainAllClosest.length > count) {
            $scope.isLoadedAllDataClosest = true;
          } else {
            $scope.isLoadedAllDataClosest = false;
          }
          $scope.closestColorData = $scope.mainAllClosest.slice(0, count);
          if($scope.azClosestMode) {
            $scope.closestColorData.sort((a, b) => { return a.de2000 - b.de2000 });
          }
        }
        if($scope.selectedMenuId === 2) {
          let count = $scope.currentPageClosest * 120;
          if($scope.mainClosest.length > count) {
            $scope.isLoadedAllDataClosest = true;
          } else {
            $scope.isLoadedAllDataClosest = false;
          }
          $scope.closestColorData = $scope.mainClosest.slice(0, count);
          if($scope.azClosestMode) {
            $scope.closestColorData.sort((a, b) => { return a.de2000 - b.de2000 });
          } else {
            $scope.closestColorData.sort((a, b) => { 
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });
          }
        }
        if($scope.selectedMenuId === 3) {
          let count = $scope.currentPageClosest * 120;
          if($scope.mainVeryClosest.length > count) {
            $scope.isLoadedAllDataClosest = true;
          } else {
            $scope.isLoadedAllDataClosest = false;
          }
          $scope.closestColorData = $scope.mainVeryClosest.slice(0, count);
          if($scope.azClosestMode) {
            $scope.closestColorData.sort((a, b) => { return a.de2000 - b.de2000 });
          }
        }
        if($scope.selectedMenuId === 4) {
          let count = $scope.currentPageClosest * 120;
          if($scope.mainClose.length > count) {
            $scope.isLoadedAllDataClosest = true;
          } else {
            $scope.isLoadedAllDataClosest = false;
          }
          $scope.closestColorData = $scope.mainClose.slice(0, count);
          if($scope.azClosestMode) {
            $scope.closestColorData.sort((a, b) => { return a.de2000 - b.de2000 });
          }
        }
      }
      

      $scope.backToSearch = () => vm.setCurrentSubSection(0);
      $scope.isSet = (tabId) => $scope.activeTabId === tabId;

      $scope.setTab = (tabId) => {
        if (tabId < 0 || tabId > 5) {
          return;
        }
        $scope.activeTabId = tabId;
      };

      $scope.setPage = (num) => {
        if (num >= 0 && num < $scope.pageCount) {
          $scope.currentPage = num;
          $scope.loadData();
        }
      };

      $scope.loadMore = () => {
        $scope.currentPage += 1;
        colorNamingIndexServiced.relevantColorSearchByWord(this.data.color, $scope.selectedTextRelevantNamingIndexCharacter, $scope.currentPage).then(data => { 
          $scope.relevantColorNames = $scope.relevantColorNames.concat(data.relevent.colors); 
          if (data.relevent.pagination.total_pages === $scope.currentPage) {
            $scope.isLoadedAllData = false;
          }

        }); 
      }

      $scope.loadMoreExact = () => {
        $scope.currentPageExact += 1;
        colorNamingIndexServiced.extraColorSearchByWord(this.data.color, $scope.selectedTextNamingIndexCharacter, $scope.currentPageExact).then(data => { 
          $scope.exactColorNames = $scope.exactColorNames.concat(data.exact.colors); 
          if (data.exact.pagination.total_pages === $scope.currentPageExact) {
            $scope.isLoadedAllDataExact = false;
          }
        });
      }

      $scope.switchClosestFilters = () => {  
        $scope.azClosestMode = !$scope.azClosestMode;
        $scope.currentPageClosest = 1;
        if($scope.selectedMenuId === 1) {
          if($scope.mainAllClosest.length > 0) {
            $scope.closestColorData = $scope.mainAllClosest.slice(0, 120);
            if($scope.azClosestMode) {
              $scope.closestColorData.sort((a, b) => { return a.de2000 - b.de2000 });
            }
            if($scope.mainAllClosest.length > 120) {
              $scope.isLoadedAllDataClosest = true;
            } else {
              $scope.isLoadedAllDataClosest = false;
            }
          }
        }
        if($scope.selectedMenuId === 2) {
          if($scope.mainClosest.length > 0) {
            $scope.closestColorData = $scope.mainClosest.slice(0, 120);
            if($scope.azClosestMode) {
              $scope.closestColorData.sort((a, b) => { return a.de2000 - b.de2000 });
            } else {
              $scope.closestColorData.sort((a, b) => { 
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });
            }
            if($scope.mainClosest.length > 120) {
              $scope.isLoadedAllDataClosest = true;
            } else {
              $scope.isLoadedAllDataClosest = false;
            }
          }
        }
        if($scope.selectedMenuId === 3) {
          if($scope.mainVeryClosest.length > 0) {
            $scope.closestColorData = $scope.mainVeryClosest.slice(0, 120);
            if($scope.azClosestMode) {
              $scope.closestColorData.sort((a, b) => { return a.de2000 - b.de2000 });
            }
            if($scope.mainVeryClosest.length > 120) {
              $scope.isLoadedAllDataClosest = true;
            } else {
              $scope.isLoadedAllDataClosest = false;
            }
          }
        }
        if($scope.selectedMenuId === 4) {
          if($scope.mainClose.length > 0) {
            $scope.closestColorData = $scope.mainClose.slice(0, 120);
            if($scope.azClosestMode) {
              $scope.closestColorData.sort((a, b) => { return a.de2000 - b.de2000 });
            }
            if($scope.mainClose.length > 120) {
              $scope.isLoadedAllDataClosest = true;
            } else {
              $scope.isLoadedAllDataClosest = false;
            }
          }
        }
      };
      // End Sub section >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    }
  });
