angular.module("app").directive("fashionYearCategoriesFrequentColors", () => {
  let link = (scope) => {
    scope.isSelectedCouture = () => {
      return scope.selectedItem && scope.selectedItem.name.toLowerCase() === 'couture';
    };

    scope.isSelectedMenswear = () => {
      return scope.selectedItem && scope.selectedItem.name.toLowerCase() === 'menswear';
    };

    scope.isSelectedRTW = () => {
      return scope.selectedItem && scope.selectedItem.name.toLowerCase() === 'rtw';
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbFashionYearCategoriesFrequentColors/dbFashionYearCategoriesFrequentColorsView.html",
    link: link,
    scope: {
      data: "=",
      selectedItem: "="
    }
  };
});
