angular.module('app').service('brandingDashboardRepository',
  ['httpService', 'appConfig', 'authService', function (httpService, appConfig, authService) {
    var callBrandingDashboardService = function (name, module, id, params) {
      return httpService.get(appConfig.brandingServiceUrl + name + '/' + module + '/' + id, {params: params})
        .then(function (data) {
          return data && data.data ? data.data : data;
        });
    };

    this.dashboard = {
      getSettings: function() {
        return httpService.get(appConfig.brandingServiceUrlApi + 'settings.json')
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },

      getFashionColors: function() {
        return httpService.get(appConfig.colorsServiceUrlNewApi + 'colors.json',{
          params: { token: authService.token },
        }).then(function (data) {
            return data && data.data ? data.data : data;
        });
      },

      getFashionYears: function() {
        return httpService.get(appConfig.colorsServiceUrlNewApi + 'years.json',{
          params: { token: authService.token },
        }).then(function (data) {
            return data && data.data ? data.data : data;
        });
      },

      getFashionCategories: function() {
        return httpService.get(appConfig.colorsServiceUrlNewApi + 'categories.json',{
          params: { token: authService.token },
        }).then(function (data) {
            return data && data.data ? data.data : data;
        });
      },

      getFashionSeasons: function() {
        return httpService.get(appConfig.colorsServiceUrlNewApi + 'seasons.json',{
          params: { token: authService.token },
        }).then(function (data) {
            return data && data.data ? data.data : data;
        });
      },

      getFashionCities: function(str) {
        return httpService.get(appConfig.colorsServiceUrlNewApi + 'cities.json?q[name_start]='+ str,
          authService.getHeaderConfig()
        ).then(function (data) { 
            return data && data.data.cities ? data.data.cities : data;
        });
      },

      getFashionDesigners: function(str, page) {
        str = str.split(' ').join('+');
        return httpService.get(appConfig.colorsServiceUrlNewApi + 'designers.json?q[name_start]='+ str +"&per_page=500&page=" + page,
          authService.getHeaderConfig()
        ).then(function (data) {
            return data && data.data.designers ? data.data.designers : data;
        });
      } 
    };

    this.brand = {
      dashboardName: 'company',
      getPageData: function (companyId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=companyHeaderData&company_id=' + companyId)
            .then(function (data) {
              return data && data.data ? data.data : data;
            });
      },
      getPageDataNewApi: function (params, type) {
        let url;
        if (type && type === 1){
          url = `companies?q[company_id_eq]=${params.id}&page=${params.page}&per_page=${params.per_page}`;
        } else if (type && type === 2) {
          url = `companies?q[company_id_eq]=${params.id}&page=${params.page}&per_page=${params.per_page}`;
        }

        return httpService.get(appConfig.brandingServiceUrlApi +  `companies.json?q[company_id_eq]=${params.id}&page=${params.page}&per_page=${params.per_page}`,
          authService.getHeaderConfig()
          )
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },
      getCompanyLogoDataApi: function (params) {
        return httpService.get(appConfig.brandingServiceUrlApi + `companies.json?q[id_eq]=${params.id}&page=${params.page}&per_page=${params.per_page}`,
          authService.getHeaderConfig()
          )
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },
      getLogoColors: function (companyId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=chartData13&company_id=' + companyId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
        // return callBrandingDashboardService(this.dashboardName, 'top_logo_colors', companyId);
      },
      getTopColors: function (companyId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=chartData15&company_id=' + companyId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
        // return callBrandingDashboardService(this.dashboardName, 'top_colors', companyId);
      },
      getColorFrequency: function (companyId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=chartData14&company_id=' + companyId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
        // return callBrandingDashboardService(this.dashboardName, 'color_freq', companyId);
      },
      getLogos: function (companyId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=logo_colors&company_id=' + companyId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
        // return callBrandingDashboardService(this.dashboardName, 'logo_colors', companyId);
      },
      getColorAttributes: function (companyId, page) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=chartData6&company_id=' + companyId +"&page="+ page)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
        // return callBrandingDashboardService(this.dashboardName, 'color_attrs', companyId);
      },
      getMaps: function (companyId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=companyHeaderData&company_id=' + companyId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
        // return callBrandingDashboardService(this.dashboardName, 'maps', companyId);
      },
      getColorCount: function (companyId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=companyHeaderData&company_id=' + companyId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
        // return callBrandingDashboardService(this.dashboardName, 'color_count', companyId);
      },
      getFilterData: function (str, page) {
        str = str.split(' ').join('+');
        return httpService.get(appConfig.brandingServiceUrlApi + 'companies/search?q[name_start]=' + str +"&page=" + page +  `&per_page=200`,
          authService.getHeaderConfig()
        )
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      }
    };

    this.industry = {
      dashboardName: 'industry',
      getPageData: function (industryId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=industryHeaderData&industry_id=' + industryId)
            .then(function (data) {
              return data && data.data ? data.data : data;
            });
      },
      getPageDataNewApi: function (params) {
        return httpService.get(appConfig.brandingServiceUrlApi + `industries.json?q[id_eq]=${params.id}`,
          authService.getHeaderConfig()
        )
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },
      getIndustryLogoDataNewApi: function (params, type) {
        //return httpService.get(appConfig.brandingServiceUrlApi + `industries.json?q[industry_id_eq]=${params.id}`,
        let url;
        if (type && type === 1){
          url = `companies?q[industry_id_eq]=${params.id}&page=${params.page}&per_page=${params.per_page}`;
        } else if (type && type === 2) {
          url = `companies?q[industry_id_eq]=${params.id}&page=${params.page}&per_page=${params.per_page}`;
        }
        return httpService.get(appConfig.brandingServiceUrlApi + url,
          authService.getHeaderConfig()
        )
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },
      getTopColors: function (industryId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=chartData15&industry_id=' + industryId)
            .then(function (data) {
              return data && data.data ? data.data : data;
            });
      },
      getFrequentUseOfColors: function (industryId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=chartData8&industry_id=' + industryId)
            .then(function (data) {
              return data && data.data ? data.data : data;
            });
      },
      getIndustryColorsData: function (industryId, page) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=chartData9&industry_id=' + industryId +'&page='+ page)
            .then(function (data) {
              return data && data.data ? data.data : data;
            });
      },
      getColorFrequency: function (industryId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=chartData7&industry_id=' + industryId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },
      getColorAttributes: function (industryId) {
        return callBrandingDashboardService(this.dashboardName, 'color_attrs', industryId);
      },
      getLogos: function (industryId) {
        return callBrandingDashboardService(this.dashboardName, 'logo_colors', industryId);
      },
      getMaps: function (industryId) {
        return callBrandingDashboardService(this.dashboardName, 'maps', industryId);
      },
      getColorPalette: function (industryId, bucket) {
        return callBrandingDashboardService(this.dashboardName, 'palette', industryId, {bucket: bucket});
      },
      getColorCount: function (industryId) {
        return callBrandingDashboardService(this.dashboardName, 'color_count', industryId);
      },
      getFilterData: function (str) {
        str = str.split(' ').join('+');
        return httpService.get(appConfig.brandingServiceUrlApi + 'industries.json?q[title_start]=' + str,
            authService.getHeaderConfig()
          )
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },
      getIndustryData: function () { 
        return httpService.get(appConfig.brandingServiceUrlApi + 'industries.json?per_page=100',
            authService.getHeaderConfig()
          )
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      }
    };

    this.attribute = {
      dashboardName: 'attribute',
      getPageData: function (attributeId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?modules=attributeHeaderData&property=' + attributeId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },
      get38Color: function () {
        return httpService.get(appConfig.brandingServiceUrlApi + 'colors/name_colors', authService.getHeaderConfig())
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },
      getPageDataNewApi: function (params, type) {
        let url;
        if (type && type === 1){
          url = `companies?q[property_id_eq]=${params.id}&page=${params.page}&per_page=${params.per_page}`;
        } else if (type && type === 2) {
          url = `companies?q[property_id_eq]=${params.id}&page=${params.page}&per_page=${params.per_page}`;
        }
        return httpService.get(appConfig.brandingServiceUrlApi + url,
          authService.getHeaderConfig()
        )
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },
      getTopColors: function (attributeId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?modules=chartData16&property=' + attributeId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
        // return callBrandingDashboardService(this.dashboardName, 'top_colors', attributeId);
      },
      getColorFrequency: function (attributeId) {
        return callBrandingDashboardService(this.dashboardName, 'color_freq', attributeId);
      },
      getColorCombinations: function (attributeId) {
        // colorComparedInPeriodData, chartData12
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?modules=chartData12&property=' + attributeId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
        // return callBrandingDashboardService(this.dashboardName, 'color_combinations', attributeId);
      },
      getLogos: function (attributeId) {
        return callBrandingDashboardService(this.dashboardName, 'logo_colors', attributeId);
      },
      getTopIndustries: function (attributeId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?modules=chartData10&property=' + attributeId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
        // return callBrandingDashboardService(this.dashboardName, 'top_industries', attributeId);
      },
      getAcutalColors: function (attributeId, mode, page, colorId) {
        var sortParam = ""; 
        if(mode === false){
          sortParam = "alpha";
        }
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?modules=chartData11&page=' + page + '&per_page=50&property=' + attributeId+ "&order="+sortParam + "&color_id=" + colorId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
        // return callBrandingDashboardService(this.dashboardName, 'top_industries', attributeId);
      },
      getMaps: function (attributeId) {
        return callBrandingDashboardService(this.dashboardName, 'maps', attributeId);
      },
      getColorPalette: function (attributeId, bucket) {
        return callBrandingDashboardService(this.dashboardName, 'palette', attributeId, {bucket: bucket});
      },
      getColorCount: function (attributeId) {
        return callBrandingDashboardService(this.dashboardName, 'color_count', attributeId);
      },
      getFilterData: function (str, page) {
        str = str.split(' ').join('+');
        return httpService.get(appConfig.brandingServiceUrlApi + 'properties.json?q[title_start]=' + str,
          authService.getHeaderConfig()
        )
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      }
    };

    this.color = {
      dashboardName: 'color',
      getColorsList: function () {
        return httpService.get(appConfig.brandingServiceUrlNewApi + 'colors' + '.json', { params: {per_page: 50}})
            .then(function (data) {
              return data && data.data ? data.data : data;
            });
      },

      getPageData: function (colorId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=colorHeaderData&color_id=' + colorId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },

      getPageDataNewApi: function (params, type) {
        let url;
        if (type && type === 1){
          url = `companies/single?q[color_id_eq]=${params.id}&page=${params.page}&per_page=${params.per_page}`;
        } else if (type && type === 2) {
          url = `companies/multiple?q[color_id_eq]=${params.id}&page=${params.page}&per_page=${params.per_page}`;
        }
        return httpService.get(appConfig.brandingServiceUrlApi + url,
          authService.getHeaderConfig()
        )
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },

      getTopIndustries: function (colorId, attributeId, companyId, industryId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=chartData1&color_id=' + colorId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },
      getTopColors: function (colorId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=chartData2&color_id=' + colorId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },
      getLogoColors: function (Id) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'companies/' + Id, authService.getHeaderConfig())
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },
      getRGBLogoColors: function (Id) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'companies/' + Id + '/colors', authService.getHeaderConfig())
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      },
      getColorFrequency: function (colorId, attributeId, companyId, industryId) {
        return callBrandingDashboardService(this.dashboardName, 'color_freq', colorId, {
          attribute_id: attributeId,
          company_id: companyId,
          industry_id: industryId
        });
      },
      getLogos: function (colorId, attributeId, companyId, industryId) {
        return callBrandingDashboardService(this.dashboardName, 'logo_colors', colorId, {
          attribute_id: attributeId,
          company_id: companyId,
          industry_id: industryId
        });
      },
      getMaps: function (colorId, attributeId, companyId, industryId) {
        return callBrandingDashboardService(this.dashboardName, 'maps', colorId, {
          attribute_id: attributeId,
          company_id: companyId,
          industry_id: industryId
        });
      },
      getColorCount: function (colorId) {
        return callBrandingDashboardService(this.dashboardName, 'color_count', colorId);
      },
      getColorCombinations: function (colorId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=chartData3&color_id=' + colorId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });

      },
      getColorAttributes: function (colorId) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?&modules=chartData4&color_id=' + colorId)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });

      },
      getAttributeData: function (colorId, page) {
        return httpService.get(appConfig.brandingServiceUrlApi + 'info.json?modules=chartData5&color_id=' + colorId +"&page=" + page)
          .then(function (data) {
            return data && data.data ? data.data : data;
          });
      }
    };

    this.country = {
      dashboardName: 'country',
      getPageData: function (countryId) {
        return callBrandingDashboardService(this.dashboardName, 'basic_data', countryId);
      },
      getTopColors: function (countryId) {
        return callBrandingDashboardService(this.dashboardName, 'top_colors', countryId);
      },
      getTopIndustries: function (countryId) {
        return callBrandingDashboardService(this.dashboardName, 'top_industries', countryId);
      },
      getColorFrequency: function (countryId) {
        return callBrandingDashboardService(this.dashboardName, 'color_freq', countryId);
      },
      getColorCombinations: function (countryId) {
        return callBrandingDashboardService(this.dashboardName, 'color_combinations', countryId);
      },
      getLogos: function (countryId) {
        return callBrandingDashboardService(this.dashboardName, 'logo_colors', countryId);
      },
      getMaps: function (countryId) {
        return callBrandingDashboardService(this.dashboardName, 'maps', countryId);
      },
      getColorPalette: function (countryId, bucket) {
        return callBrandingDashboardService(this.dashboardName, 'palette', countryId, {bucket: bucket});
      },
      getFlag: function (countryId) {
        return callBrandingDashboardService(this.dashboardName, 'flag', countryId);
      },
      getColorCount: function (countryId) {
        return callBrandingDashboardService(this.dashboardName, 'color_count', countryId);
      }
    };
  }]);
