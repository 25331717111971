angular.module("app").directive("fragranceColorData", [
  "fragranceDashboardRepository", "$timeout",
  (fragranceDashboardRepository, timeout) => {
    let link = (scope, element) => {
        scope.currentPage = 0;
        scope.perPage = 10;
        scope.currentDesignerPage = 1;
        scope.designerPerPage = 30;

      scope.selectedItem = scope.item;

      scope.isModeColor = () => {
        return scope.viewMode === "color";
      };

      scope.isModeDesigner = () => {
        return scope.viewMode === "designer";
      };

      scope.isModeAccord = () => {
        return scope.viewMode === "accord";
      };

      scope.isModeCountry = () => {
        return scope.viewMode === "country";
      };

      scope.isModeYear = () => {
        return scope.viewMode === "year";
      };

      if (scope.isModeColor()) {
        scope.isLoadingControls = true;

        fragranceDashboardRepository
        .getColorChartsDataByColorId(scope.selectedItem.id, 'header', scope.selectedYear.id)
        .then(function (data) {
          scope.colorHeaderData = data.header;
          scope.isLoadingControls = false; 
        }).finally(() => {
          scope.isLoadingControls = false; 
        });

        fragranceDashboardRepository
        .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData1', scope.selectedYear.id)
        .then(function (data) {
            scope.overallData = data.chartData1;
            scope.mainPercentage = scope.overallData.selected_color.percentage;
        });

        fragranceDashboardRepository
        .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData2', scope.selectedYear.id)
        .then(function (data) {
          scope.topFiveData = data.chartData2;
        });
      }

      if (scope.isModeDesigner()) {
        scope.isLoadingControls = true;

        fragranceDashboardRepository
        .getDesignerChartsDataByDesigner(scope.selectedItem.title, 'header', scope.selectedYear.id)
        .then(function (data) {
          scope.designerHeaderData = data.header;
          scope.isLoadingControls = false; 
        }).finally(() => {
          scope.isLoadingControls = false; 
        });

        fragranceDashboardRepository
        .getDesignerChartsDataByDesigner(scope.selectedItem.title, 'chartData1', scope.selectedYear.id)
        .then(function (data) {
            scope.newColorFrequencyData = data.chartData1;
        });

        fragranceDashboardRepository
        .getDesignerChartsDataByDesigner(scope.selectedItem.title, 'chartData2', scope.selectedYear.id)
        .then(function (data) {
          scope.topFiveData = data.chartData2;
        });
      }

      if (scope.isModeAccord()) {
        scope.isLoadingControls = true;

        fragranceDashboardRepository
        .getAccordChartsDataByAccordId(scope.selectedItem.id, 'header', scope.selectedYear.id)
        .then(function (data) {
          scope.accordHeaderData = data.header;
          scope.isLoadingControls = false; 
        }).finally(() => {
          scope.isLoadingControls = false; 
        });

        fragranceDashboardRepository
        .getAccordChartsDataByAccordId(scope.selectedItem.id, 'chartData1', scope.selectedYear.id)
        .then(function (data) {
            scope.newColorFrequencyData = data.chartData1;
        });

        fragranceDashboardRepository
        .getAccordChartsDataByAccordId(scope.selectedItem.id, 'chartData2', scope.selectedYear.id)
        .then(function (data) {
          scope.topFiveData = data.chartData2;
        });

        fragranceDashboardRepository
        .getAccordChartsDataByAccordId(scope.selectedItem.id, 'chartData3', scope.selectedYear.id)
        .then(function (data) {
          scope.designer100Data = data.chartData3;
        });
      }

      scope.loadCountryChartData4 = (item) => {
        fragranceDashboardRepository
          .getCountryChartsData4ByCountryTitle(scope.selectedItem.title, 'chartData4', scope.selectedYear.id, item)
          .then(function (data) {
              scope.designerOfColor = scope.designerOfColor.concat(data.chartData4.designers);
              scope.selectedGroup = data.chartData4.group;
              scope.isLoadMoreDesignerData = true;
              scope.isLoadingControls = false; 
          }).finally(() => {
              scope.isLoadingControls = false; 
          });
      }

      if (scope.isModeCountry()) {
        scope.isLoadingControls = true;
        scope.designerOfColor = [];
        scope.letter = "All";
        scope.currentDesignerPage = 1;
        
        fragranceDashboardRepository
        .getCountryChartsDataByCountryTitle(scope.selectedItem.title, 'header', scope.selectedYear.id)
        .then(function (data) {
          scope.countryHeaderData = data.header;
          scope.isLoadingControls = false; 
        }).finally(() => {
          scope.isLoadingControls = false; 
        });

        fragranceDashboardRepository
        .getCountryChartsDataByCountryTitle(scope.selectedItem.title, 'chartData1', scope.selectedYear.id)
        .then(function (data) {
            scope.newColorFrequencyData = data.chartData1;
        });

        fragranceDashboardRepository
        .getCountryChartsDataByCountryTitle(scope.selectedItem.title, 'chartData2', scope.selectedYear.id)
        .then(function (data) {
          scope.topFiveData = data.chartData2;
        });

        fragranceDashboardRepository
        .getCountryChartsDataByCountryTitle(scope.selectedItem.title, 'chartData3', scope.selectedYear.id)
        .then(function (data) {
            scope.topTenColorCountrySelected = data.chartData3.selected_country;
            scope.topTenColorCountryOther = data.chartData3.other_countries;
        });

        scope.loadCountryChartData4(scope.letter);     
      }

      scope.filteredResults = function (item) {
        scope.letter = item;
        scope.designerOfColor = [];
        scope.currentDesignerPage = 1;
        scope.selectedGroup = '';
        if (scope.isModeCountry()) {
          scope.loadCountryChartData4(scope.letter);
        }
        if (scope.isModeYear()) {
          scope.loadYearChartData4(scope.letter);
        }
      };

      scope.loadMoreDesignerData = () => {
        scope.currentDesignerPage +=1;
        if (scope.isModeCountry()) {
          scope.loadCountryChartData4(scope.letter);
        }
        if (scope.isModeYear()) {
          scope.loadYearChartData4(scope.letter);
        }
      }

      scope.loadYearChartData4 = (item) => {
        fragranceDashboardRepository
          .getYearChartsData4ByYearId(scope.selectedItem.title, 'chartData4', item)
          .then(function (data) {
              scope.designerOfColor = scope.designerOfColor.concat(data.chartData4.designers);
              scope.selectedGroup = data.chartData4.group;
              scope.isLoadMoreDesignerData = true;
              scope.isLoadingControls = false; 
          }).finally(() => {
              scope.isLoadingControls = false; 
          });
      }

      if (scope.isModeYear()) {
        scope.isLoadingControls = true;
        scope.designerOfColor = [];
        scope.letter = "All";
        scope.currentDesignerPage = 1;

        fragranceDashboardRepository
        .getYearChartsDataByYearId(scope.selectedItem.title, 'header')
        .then(function (data) {
            scope.yearHeaderData = data.header;
            scope.isLoadingControls = false; 
        }).finally(() => {
            scope.isLoadingControls = false; 
        });

        fragranceDashboardRepository
        .getYearChartsDataByYearId(scope.selectedItem.title, 'chartData1')
        .then(function (data) {
            scope.newColorFrequencyData = data.chartData1;
        });

        fragranceDashboardRepository
        .getYearChartsDataByYearId(scope.selectedItem.title, 'chartData2')
        .then(function (data) {
          scope.topFiveData = data.chartData2;
        });

        fragranceDashboardRepository
        .getYearChartsDataByYearId(scope.selectedItem.title, 'chartData3')
        .then(function (data) {
          scope.topFourColorsForDesignerInYear = data.chartData3;
        });

        scope.loadYearChartData4(scope.letter);
        
      }

      scope.fragranceChartPopupAttributes = {
        modalTitle: ""
      };

      scope.toggleFragranceChartPopup = (needShow, type) => {
        if (needShow) {
          if(type === 'chart1'){
            scope.fragranceChartPopupAttributes.modalTitle = 'OVERALL USE OF ' + scope.selectedItem.name + ' ACROSS ALL FRAGRANCES COMPARED TO ALL OTHER COLORS';
            scope.typeOfPopup = type;
          } else if(type === 'chart2'){
            scope.fragranceChartPopupAttributes.modalTitle = 'TOP 5 FRAGRANCES ACCORDS USING ' + scope.selectedItem.name;
            scope.typeOfPopup = type;
          } else if(type === 'chart3'){
            scope.fragranceChartPopupAttributes.modalTitle = 'OVERALL USE OF COLORS BY ' + scope.selectedItem.title + ' COMPARED TO ALL OTHER FRAGRANCE DESIGNERS';
            scope.typeOfPopup = type;
          } else if(type === 'chart4'){
            scope.fragranceChartPopupAttributes.modalTitle = 'TOP 5 MAIN ACCORD BY ' + scope.selectedItem.title;
            scope.typeOfPopup = type;
          } else if(type === 'chart5'){
            scope.fragranceChartPopupAttributes.modalTitle = scope.selectedItem.name + ' MAIN ACCORD COLORS COMPARED TO ALL OTHER MAIN ACCORDS COLORS';
            scope.typeOfPopup = type;
          } else if(type === 'chart6'){
            scope.fragranceChartPopupAttributes.modalTitle = 'TOP 5 COUNTRIES WHEN ' + scope.selectedItem.name + ' MAIN ACCORD IS MOST POPULAR';
            scope.typeOfPopup = type;
          } else if(type === 'chart7'){
            scope.fragranceChartPopupAttributes.modalTitle = 'OVERALL USE OF COLORS BY FRAGRANCE DESIGNERS IN ' + scope.selectedItem.title + ' COMPARED TO ALL OTHER FRAGRANCE DESIGNERS';
            scope.typeOfPopup = type;
          } else if(type === 'chart8'){
            scope.fragranceChartPopupAttributes.modalTitle = 'TOP 5 MAIN ACCORD IN ' + scope.selectedItem.title;
            scope.typeOfPopup = type;
          } else if(type === 'chart9'){
            scope.fragranceChartPopupAttributes.modalTitle = 'OVERALL USE OF COLORS BY ' + scope.yearHeaderData.country_name + ' DESIGNERS IN ' + scope.selectedItem.title + ' COMPARED TO ALL OTHER FRAGRANCE DESIGNERS';
            scope.typeOfPopup = type;
          } else if(type === 'chart10'){
            scope.fragranceChartPopupAttributes.modalTitle = 'TOP 5 MAIN ACCORD IN ' + scope.selectedItem.title;
            scope.typeOfPopup = type;
          }
          scope.showFragranceChartPopup = true;
        } else {
          scope.showFragranceChartPopup = false;
        }
      };

      scope.$on("mood_state_chart", (data) => {
        let node = angular.element("#fragrancePopupCapture")[0];
        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            if(dataUrl) {
              scope.saveImageDetailsData = {
                base64: dataUrl,
                type: 'beauty'
              };
            }
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });

          let times = 2000;
          timeout(function () {
            if(scope.saveImageDetailsData) {
              scope.showFragranceChartPopup = false;
              scope.toggleSaveImageDetails();
            } else {
              timeout(function () {
                if(scope.saveImageDetailsData) {
                  scope.showFragranceChartPopup = false;
                  scope.toggleSaveImageDetails();
                }
              }, times);
            }
          }, times);
      });

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
        result += name.toUpperCase() + ".";
        return result + date + type;
      };

      scope.toggleSaveImageDetails = () => {
        scope.saveImageDetails = scope.saveImageDetails ? false : true;
      };

      scope.$on("mood_state_chart_changed", () => {
        scope.toggleSaveImageDetails();
      });
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbFragranceColorData/dbFragranceColorDataView.html",
      link: link,
      scope: {
        item: "=",
        viewMode: "=",
        selectedYear: "=",
      },
    };
  },
]);
