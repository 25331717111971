angular.module("app").component("partnersComponent", {
  templateUrl: "app/components/data-partners/partners.tmpl.html",
  controller: function (
    $state,
    $http,
    appConfig,
    categoryValues,
    dataValidate
  ) {
    const vm = this;

    $(document).ready(function() {
      setTimeout(function(){
        $(".owl-carousel").owlCarousel({
          autoplay: true,
          lazyLoad: true,
          loop: true,
          margin: 20,
          responsiveClass: true,
          autoHeight: true,
          autoplayTimeout: 7000,
          smartSpeed: 800,
          nav: true,
        }
      )},1500);
    });

    vm.jobs = categoryValues("job function");
    vm.companySizes = categoryValues("company size");
    vm.industries = categoryValues("industry");
    vm.countries = categoryValues("country");

    vm.data = {
      first_name: {
        value: "",
        required: true,
        name: "first name",
        type: "provide",
      },
      last_name: {
        value: "",
        required: true,
        name: "last name",
        type: "provide",
      },
      email: { value: "", required: true, name: "email", type: "provide" },
      company: {
        value: "",
        required: true,
        name: "company name",
        type: "provide",
      },
      title: { value: "", required: true, name: "job title", type: "provide" },
      company_size: {
        value: vm.companySizes[0],
        required: true,
        name: "company size",
        type: "select",
      },
      industry: {
        value: vm.industries[0],
        required: true,
        name: "industry",
        type: "select",
      },
      body: { value: "", required: false, name: "description", type: "enter" },
    };

    vm.init = function () {
      $http
        .get(appConfig.dashboardServiceUrl + "api/pages.json?kind=data_partner")
        .then(function (res) {
          if (res && res.data) {
            vm.pageData = res.data.pages;
          }
        });
    };

    vm.send = () => {
      if (dataValidate.validate(vm.data)) {
        const data = {};
        for (const item in vm.data) {
          if (vm.data[item].type === "select") {
            data[item] = vm.data[item].value.title;
          } else {
            data[item] = vm.data[item].value;
          }
        }
        $http
          .post(appConfig.dashboardServiceUrl + "api/forms/data_partner.json", {
            contact: data,
          })
          .then((res) => {
            if (res.status === 201) {
              $state.go("thank-you");
            }
          });
      }
    };
  },
});
