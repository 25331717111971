angular.module('app').directive('colorcombinationspopup', function () {
  function link (scope) {
    scope.closeClickHandler = function () {
      scope.toggleFunction(false);
    };

    // Mocked color details
    scope.colorsDetails = [
      {
        name: 'RED',
        rgb: '255,0,0',
        cmyk: '5,5,5,5',
        hex: '#FF0000',
        hsl: '100,100,50'
      },
      {
        name: 'BLACK',
        rgb: '0, 0, 0',
        cmyk: '0, 0, 0, 100',
        hex: '#000000',
        hsl: '0, 0, 0'
      },
      {
        name: 'TEAL',
        rgb: '0, 128, 128',
        cmyk: '100, 0, 0, 50',
        hex: '#008080',
        hsl: '180, 100, 25'
      },
      {
        name: 'RED',
        rgb: '255,0,0',
        cmyk: '5,5,5,5',
        hex: '#FF0000',
        hsl: '100,100,50'
      },
      {
        name: 'TEAL',
        rgb: '0, 128, 128',
        cmyk: '100, 0, 0, 50',
        hex: '#008080',
        hsl: '180, 100, 25'
      },
      {
        name: 'RED',
        rgb: '255,0,0',
        cmyk: '5,5,5,5',
        hex: '#FF0000',
        hsl: '100,100,50'
      },
      {
        name: 'BLACK',
        rgb: '0, 0, 0',
        cmyk: '0, 0, 0, 100',
        hex: '#000000',
        hsl: '0, 0, 0'
      },
      {
        name: 'TEAL',
        rgb: '0, 128, 128',
        cmyk: '100, 0, 0, 50',
        hex: '#008080',
        hsl: '180, 100, 25'
      },
      {
        name: 'RED',
        rgb: '255,0,0',
        cmyk: '5,5,5,5',
        hex: '#FF0000',
        hsl: '100,100,50'
      },
      {
        name: 'TEAL',
        rgb: '0, 128, 128',
        cmyk: '100, 0, 0, 50',
        hex: '#008080',
        hsl: '180, 100, 25'
      },
    ];
  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/colorCombinationsPopup/colorCombinationsPopup.html',
    link: link,
    scope: {
      colors:'=',
      mainColor:'=',
      toggleFunction:'=',
      graphSameSizeScale:'=',
      graphShowTopBorder:'=',
      graphHeightSize:'=',
      graphStyles:'=',
      graphSmallScaleLineFirst:'='
    }
  };
});
