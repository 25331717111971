angular
  .module("app")
  .directive("fashionShowImageDetails", function (
    $timeout,
    searchMenuRepository,
    userDataRepository,
    mainMenuService,
    authService,
    moodboardService
  ) {
    let link = (scope, element, attrs, rootScope) => {
      rootScope = { mood_state: 0 };
      scope.allData = scope.data;
      scope.data = scope.allData.data[scope.allData.index];

      scope.source = "";
      scope.colorActual = null;

      scope.footerMode = 0;
      scope.imageName = null;

      scope.currentUser = null;

      scope.closeClickHandler = () => {
        scope.onClose();
      };

      scope.shareFacebook = (url) => {
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + url);
      };

      scope.shareTwitter = (url) => {
        window.open("https://twitter.com/share?url=" + url);
      };

      scope.sharePinterest = (url) => {
        window.open("https://pinterest.com/pin/create/button/?media=" + url);
      };

      scope.shareGooglePlus = (url) => {
        window.open("https://plus.google.com/share?url=" + url);
      };

      scope.shareInstagram = (url) => {
        window.open("https://instagram.com/?url=" + url);
      };

      scope.newmoodboard = (data) => {
        data.type = 'fashion';
        scope.$emit("mood_state", data);
      };

      scope.LeftButtonHandler = () => {
        let index = scope.allData.index;
        if (index > 0) {
          index--;
        }
        scope.allData.index = index;
        scope.data = scope.allData.data[index];
      };

      scope.RightButtonHandler = () => {
        try {
          let index = scope.allData.index;
          if (index < scope.allData.data.length - 1) {
            index++;
          }
          scope.allData.index = index;
          scope.data = scope.allData.data[index];
        } catch (e) {}
      };

      scope.isFooterMode = (index) => {
        return scope.footerMode == index;
      };

      scope.showSaveInput = () => {
        //on SAVE button click
        scope.footerMode = 1;
      };

      scope.exportImageCard = () => {
        $timeout(() => {
          html2canvas($(".image-details-dialog", element)[0], {
            background: "#000",
            //useCORS: true,
            proxy: "/proxy",
            onrendered: (canvas) => {
              const img = canvas.toDataURL("image/jpeg");
              let name = scope.designer;

              if (scope.season) name += "_" + scope.season;
              if (scope.year) name += "_" + scope.year;

              download(
                img,
                name.replace(/[|&;$%@"<>()+,]/g, "-") + ".jpg",
                "image/jpeg"
              );
            },
          });
        }, 50);
      };

      scope.saveImage = () => {
        userDataRepository.image.createItem(
          scope.currentUser.id,
          scope.data.id,
          scope.imageName,
          (data) => {
            mainMenuService.reloadSavedImages();
          }
        );
        scope.imageName = null;
        scope.footerMode = 2;
      };

      scope.$watch(
        () => {
          return authService.currentUser;
        },
        (newValue) => {
          scope.currentUser = newValue;
        },
        true
      );
    };

    return {
      restrict: "E",
      templateUrl:
        "app/directives/dbFashionShowImages/dbFashionShowImageDetailsView.html",
      link: link,
      scope: {
        data: "=",
        onClose: "&",
      },
    };
  });
