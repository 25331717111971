angular.module("app").directive("designersUseOfColor", ["$timeout", (timeout) => {
  let link = (scope) => {  
    scope.characters = [
      "A", "Á", "B", "C", "D", "E", "É", "F", "G", "H", "I", "K",
      "L", "M", "N", "O", "Ö", "P", "Q", "R", "S", "T", "V", "X"
    ];
    scope.selectedCharacter = "ALL";
    scope.saveItemImageDetails = false;

    scope.$watch('data', function (newVal, prevVal) {
      if(newVal) {
        scope.filteredData = scope.data;
        scope.selectedCharacter = scope.data.group === 'All' ? 'ALL' : scope.data.group;
      }

    });
   
    scope.characterSelect = (value) => {
      scope.selectedCharacter = value;
      scope.filteredResults(value === 'ALL' ? 'All' : value);
    };

    scope.isSelected = (value) => {
      return scope.selectedCharacter === value;
    };

    scope.showImageDetails = false;
    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (item, index) => {
      scope.logoData = item.designers;
      scope.index = index;
      scope.toggleImageDetails();
    };

    scope.regions = [
      "All",
      "Europe",
      "North America",
      "South America",
      "Asia",
      "Australia"
    ];

    scope.selectedRegion = scope.regions[0];
    scope.isSelectedRegion = (region) => {
      return scope.selectedRegion === region;
    };
    scope.selectRegion = (region) => {
      scope.selectedRegion = region;
    };

    scope.$on("mood_state_item_chart", (data) => {
      let mainData = data.targetScope.data;
      let newParam = {};
      if(scope.selectedColor) {
        const useof = `USE OF ${scope.selectedColor.name}`;
        const keys = ["DESIGNER", "TOTAL COLORS", useof, "SHOWS"];
        newParam = {
          [keys[0]]: mainData.name,
          [keys[1]]: mainData.totalColors,
          [keys[2]]: mainData.colorUsedPercentage,
          [keys[3]]:  mainData.shows + ' ' + scope.year
        }
      } else {
        const keys = ["DESIGNER", "SHOWS"];
        newParam = {
          [keys[0]]: mainData.name,
          [keys[1]]:  mainData.shows + ' ' + scope.year
        }
      }
      let node = angular.element("#fashionItemPopupCapture")[0];
      domtoimage
        .toPng(node, {
          bgcolor: "#fff",
        })
        .then((dataUrl) => {
          if(dataUrl) {
            scope.saveImageDetailsData = {
              base64: dataUrl,
              type: 'fashion',
              data: newParam
            };
          }
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });

        let times = 2000;
        timeout(function () {
          if(scope.saveImageDetailsData) {
            scope.showImageDetails = false;
            scope.toggleSaveImageDetails();
          } else {
            timeout(function () {
              if(scope.saveImageDetailsData) {
                scope.showImageDetails = false;
                scope.toggleSaveImageDetails();
              } else {
                timeout(function () {
                  if(scope.saveImageDetailsData) {
                    scope.showImageDetails = false;
                    scope.toggleSaveImageDetails();
                  }
                }, times);
              }
            }, times);
          }
        }, times);
    });

    scope.generateImgName = function (type, board, name) {
      let result = "";
      const date = moment().format("L");
      result += name.toUpperCase() + ".";
      return result + date + type;
    };

    scope.toggleSaveImageDetails = () => {
      scope.saveItemImageDetails = !scope.saveItemImageDetails;
    };

    scope.$on("mood_state_item_chart_changed", () => {
      scope.toggleSaveImageDetails();
    });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbDesignersUseOfColor/dbDesignersUseOfColorView.html",
    link: link,
    scope: {
      data: "=",
      selectedColor: "=",
      mode: "=",
      year: "=",
      filteredResults: "="
    }
  };
}]);
