angular.module('app').directive('toplogocolorspopup', function () {
  function link (scope) {
    scope.closeClickHandler = function () {
      scope.toggleFunction(false);
    };

    scope.formsColOptionStyles = {
      topWrapper: {
        width: '100px',
      },
      name: {
        'font-size': '12px'
      }
    }

  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/topLogoColorsPopup/topLogoColorsPopup.html',
    link: link,
    scope: {
      colors:'=',
      mainColor:'=',
      toggleFunction:'=',
      modalTitle:'=',
      formsColWidth:'='
    }
  };
});
