angular.module('app').directive('uniqueColorsUsedByArtist', () => {
  let link = (scope) => {
    scope.acutalColorData = [];
    scope.isLoadedAllData = true;  
    scope.$watch('colorData', function (colorData) {
      if (colorData) {
        scope.colorData = scope.colorData
        bindData();
      }
    });

    function bindData() {
      if(scope.mode === 'infographic') {
        scope.acutalColorData = scope.colorData;
      } else {
        if (scope.colorData.length > 48) {
          scope.acutalColorData = scope.colorData.slice(0, 48);
          scope.moreAcutalColorData = scope.colorData.slice(48);
          scope.isLoadedAllData = false;
        } else {
          scope.acutalColorData = scope.colorData;
          scope.isLoadedAllData = true;
        }
      }
    }

    scope.loadMoreColorArtist = () => { 
      if (!scope.isLoadedAllData) {
        if (scope.moreAcutalColorData.length > 48) {
          scope.acutalColorData = scope.acutalColorData.concat(scope.moreAcutalColorData.slice(0, 48));
          scope.moreAcutalColorData = scope.moreAcutalColorData.slice(48);
          scope.isLoadedAllData = false;
        } else {
          scope.acutalColorData = scope.acutalColorData.concat(scope.moreAcutalColorData);
          scope.isLoadedAllData = true;
          scope.moreAcutalColorData = [];
        }
      }
    };

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorsUsedByArtist/dbColorsUsedByArtistView.html",
    link: link,
    scope: {
      colorData: "=",
      mode: "=",
    }
  };
});
