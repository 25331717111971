angular.module('app').service('modalService', function ($rootScope, $uibModal) {
  var opened = false;
  this.showModal = function (number, img, item, flag) {
    var arr = [
      {
        tmpl: 'app/components/modal/modal.tmpl.html',
        class: 'modal-err'
      },
      {
        tmpl: 'app/components/modal/membersOnlyModal.tmpl.html',
        class: 'adv-modal'
      },
      {
        tmpl: 'app/components/modal/graphic-modal.tmpl.html',
        class: 'graphic-modal'
      },
      {
        tmpl: 'app/components/modal/dailyModal.tmpl.html',
        class: 'graphic-modal'
      },
      {
        tmpl: 'app/components/modal/cat-survey-modal.tmpl.html',
        class: 'cart-modal'
      },
      {
        tmpl: 'app/components/modal/color-search.tmpl.html',
        class: 'modal-err'
      },
      {
        tmpl: 'app/components/modal/loginExpiredModal.tmpl.html',
        class: 'modal-err'
      },
      {
        tmpl: 'app/components/modal/subscription-success-modal.tmpl.html',
        class: 'modal-err'
      },
      {
        tmpl: 'app/components/modal/subscription-cancel-modal.tmpl.html',
        class: 'modal-err'
      },
      {
        tmpl: 'app/components/modal/warning-modal.tmpl.html',
        class: 'modal-err'
      },
      {
        tmpl: 'app/components/modal/dashboardModal.tmpl.html',
        class: 'adv-modal'
      },
      {
        tmpl: 'app/components/modal/limitModal.tmpl.html',
        class: 'upgrade-modal'
      },
      {
        tmpl: 'app/components/modal/customAnalyticsModal.tmpl.html',
        class: 'upgrade-modal'
      },
      {
        tmpl: 'app/components/modal/mailPdfModal.tmpl.html',
        class: 'upgrade-modal'
      },
      {
        tmpl: 'app/components/modal/videoModal.tmpl.html',
        class: 'graphic-modal'
      }
    ];

    if (opened) return;

    var modalInstance = $uibModal.open({
      templateUrl: arr[number].tmpl,
      controller: function ($scope, $uibModalInstance) {
        $scope.ok = function () {
          $uibModal.close();
        };
        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };
        $scope.img = img;
        if (item) {
          $scope.item = item;
        }
        if (flag !== undefined) {
          $scope.flag = flag;
        }
      },
      windowTopClass: arr[number].class,
      resolve: {},
      backdrop: number === 7 || number === 8 || number === 10 || number === 14 ? 'static' : true
    });

    opened = true;

    modalInstance.result.then(function (selectedItem) {
      opened = false;
    }, function () {
      opened = false;
    });
  }
});
