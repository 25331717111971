angular.module("app").controller("newFashionController", [
  "$scope",
  'brandingDashboardRepository',
  "dashboardOverlayService",
  "searchMenuRepository",
  "$timeout",
  "charts",
  "$state",
  "$location",
  "$rootScope",
  function (
    scope,
    brandingDashboardRepository,
    dashboardOverlayService,
    searchMenuRepository,
    timeout,
    charts,
    $state,
    $location,
    $rootScope
  ) {
    scope.menus = {
      category: "",
      season: "",
      region: "",
      designer: "",
      year: "",
      color: "",
    };

    scope.disabledControls = {
      category: false,
      season: false,
      region: false,
      designer: false,
      year: false,
      color: false,
    };

    scope.type = {
      category: "category",
      season: "season",
      region: "region",
      designer: "designer",
      year: "year",
      color: "color",
    };
    scope.selectedpage = 1;
    scope.oldsearchdata = "";
    scope.isNextButton = false;
    scope.yearSelections = []; 
    scope.designers =[]; 
    scope.cities =[];
    scope.showDropdown = true;
    scope.updateSelectedYear = function (year) { 
      if(scope.params.control === 'year') {
        scope.selectedYear = year;
        $rootScope.$broadcast('updateYear', scope.selectedYear);
        scope.setTab(1);
        $state.go("fashion", Object.assign({control: 'year'}, scope.selectedYear));
      } else {
        scope.selectedYear = year;
        $rootScope.$broadcast('updateYear', scope.selectedYear);
        scope.setTab(1);
      }
    };

    scope.params = $location.search();

    scope.$watch('params', function (params) {
      if (params.control === 'searchMenu') {
        scope.init();
      } else {
        scope.handleChangeControl(params.control, params);
      }
    });

    scope.disabledInput = (type, value) => {
      switch (type) {
        case "category":
          scope.disableSeason = Boolean(value);
          scope.disableRegion = Boolean(value);
          scope.disableDesigner = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "season":
          scope.disableCategory = Boolean(value);
          scope.disableRegion = Boolean(value);
          scope.disableDesigner = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "region":
          scope.disableSeason = Boolean(value);
          scope.disableCategory = Boolean(value);
          scope.disableDesigner = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "designer":
          scope.disableSeason = Boolean(value);
          scope.disableRegion = Boolean(value);
          scope.disableCategory = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "year":
          scope.disableSeason = Boolean(value);
          scope.disableRegion = Boolean(value);
          scope.disableDesigner = Boolean(value);
          scope.disableCategory = Boolean(value);
          break;
      }
    };

    scope.mainParam = null;
    scope.mainParamId = null;
    scope.secondaryParams = {};
    scope.tab = 1;
    scope.currentPage = 0;

    scope.category_place_holder = "SEARCH BY CATEGORY";
    scope.season_place_holder = "SEARCH BY SEASON";
    scope.region_place_holder = "SEARCH BY CITY, REGION";
    scope.designer_place_holder = "SEARCH BY DESIGNER";
    scope.year_place_holder = "SEARCH BY YEAR";

    scope.showDashboard = false;
    scope.title = scope.year;
    scope.subtitle = null;

    scope.isLoadingControls = true;
    scope.colors = [];
     
    scope.setback = () => {
      //$state.go("fashion", {control: "searchMenu", id: "", title: "", hex: ""});
      $state.go("fashion", {control: "searchMenu", id: "", name: "", hex: ""});
    };

    scope.init = () => {
      scope.menus = {
        category: "",
        season: "",
        region: "",
        designer: "",
        year: "",
        color: "",
      };
      scope.disableCategory = false;
      scope.disableSeason = false;
      scope.disableRegion = false;
      scope.disableDesigner = false;
      scope.disableYear = false;
      scope.isLoadingControls = true;
      scope.currentPage = 0;
      scope.mainSeason = [];
      scope.tab = 1;
    };

    scope.setTab = (tabId) => {
      scope.tab = tabId;
      scope.select_images = false;
      scope.selected_moodboard = false;
      scope.state = 1;
    };

    scope.isSet = (tabId) => {
      return scope.tab === tabId;
    };

    scope.setColorAsMain = function (color) {
      scope.iconUrl = null;
      scope.secondaryParams = {};
      scope.mainParam = null;
      scope.tempColor = color;
      scope.menus = {
        category: "",
        season: "",
        region: "",
        designer: "",
        year: "",
        color: "",
      };
      scope.menus.color = color.id;
      scope.tempColor = null;
      scope.handleChangeControl("color");
      scope.loadGraphics();
      scope.loadFashionColors();
      scope.loadFashionYears();
    };

    scope.loadGraphics = function () {
      if (scope.mainParam) {
        scope.showDashboard = true;
        dashboardOverlayService.loadingStart(4000);

        const parseData = (length) => {
          let size = length;
          for (
            let i = 0;
            i < Math.ceil(scope.colorPaletteData.length / size);
            i++
          ) {
            scope.mainSeason[i] = scope.colorPaletteData.slice(
              i * size,
              i * size + size
            );
          }
        };

        scope.isLoadingControls = false;
      }
    };

    scope.loadFashionColors = () => {
      scope.ajaxInProgress = true;
      brandingDashboardRepository.dashboard.getFashionColors().then(data => {   
        scope.colors = data.colors;

        if ($location.search() !== $location.path()) {
          scope.loadDataByQuery();
        }
      }).finally(() => {
        scope.ajaxInProgress = false;
      });
    };

    scope.loadFashionColors();  

    scope.loadFashionYears = () => {
      scope.ajaxInProgress = true;
      brandingDashboardRepository.dashboard.getFashionYears().then(data => { 
        scope.yearSelections= data.years.sort((a, b) => (a.id < b.id) ? 1 : -1); 
        if (scope.searchMode === 'year') {  
          let index = scope.yearSelections.findIndex(record => record.name === scope.searchId);
          scope.selectedYear = scope.yearSelections[index];
          scope.showDropdown = true;
        }
        else{
          scope.selectedYear = scope.yearSelections[0];
        }
      }).finally(() => {
        scope.ajaxInProgress = false;
      });
    };  

    scope.loadDataByQuery = () => { 
      scope.ajaxInProgress = true;
      const searchQuery = $location.search();
      const control = Object.values(searchQuery)[0];
      const id = Object.values(searchQuery)[1]; 
      scope.searchId = id;
      scope.searchMode = control;
      scope.control = control;
      // if (scope.searchMode === 'color') {
      //   scope.ajaxInProgress = true;
      //   brandingDashboardRepository.color.getFashionShowsData(scope.searchId)
      //     .then(data => { 
      //       scope.collections = data.collections;
      //       scope.isLoadingControls = false; 
      //     }).finally(() => {
      //       scope.ajaxInProgress = false;
      //     });
      // }  
      // if (scope.searchMode === 'category') {
      //   scope.ajaxInProgress = true;
      //   searchMenuRepository.getFashionChartsDataByCategoryId(scope.searchId)
      //     .then(data => { 
      //       scope.chartData = data;
      //       scope.isLoadingControls = false; 
      //     }).finally(() => {
      //       scope.ajaxInProgress = false;
      //     });
      // } 
    }

    scope.setPage = (num) => {
      if (num >= 0 && num < scope.pageCount) {
        scope.currentPage = num;
        scope.loadGraphics();
       //load data
        scope.loadDataByQuery();
      }
    };

    scope.handleChangeControl = function (control, item) { 
      scope.header_title = () => {
        if (control === 'region') {
          return `city: ${item.name}`;
        }  else if (control === 'year') {
          return `${control}:`;
        } else {
          return `${control}: ${item.name}`;
        }
      };
      scope.mainParam = control;
      scope.selectedFilterValue = item;
      scope.mainParamId = item.id;
      scope.searchMode = control;

      scope.loadGraphics();
      //load data
      scope.loadDataByQuery();
      scope.loadFashionYears();
    };

    scope.handleSearchChange = function (control, item) {
      if(item.name === 'medium yellow/green') {
        item.name = 'medium yellow - green'
      } else if(item.name === 'light yellow/green') {
        item.name = 'light yellow - green'
      } else if(item.name === 'dark yellow/green') {
        item.name = 'dark yellow - green'
      }
      $state.go("fashion", Object.assign({control: control}, item));
    };

    scope.getResultsOnFilterSearch = (type, str, page) => {
      scope.ajaxInProgress = true;  
      switch (type) {
        case 'designer':
          if (!str.length) {
            scope.designers = [];
            scope.ajaxInProgress = false;
            return;
          }
          //new logic for pagination
          if(scope.oldsearchdata === ""){
            scope.oldsearchdata = str;
            scope.selectedpage = 1;
          } 
          else if(scope.oldsearchdata !== str){  
              page = 1;
              scope.selectedpage = page;
              scope.designers = [];
              scope.oldsearchdata = str;
          } 
          else{
            scope.selectedpage += 1;
            page = scope.selectedpage;
          }
          brandingDashboardRepository.dashboard
            .getFashionDesigners(str, page)
            .then(data => {
              if(data.length >= 30){
                scope.isNextButton = true;
              } else {
                scope.isNextButton = false;
              }
              if(scope.designers.length>0){  
                scope.designers = scope.designers.concat(data);
              }
              else{
                scope.designers = data;
              }   
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break; 
        case 'region':
            if (!str.length) {
              scope.cities = [];
              scope.ajaxInProgress = false;
              return;
            }
            brandingDashboardRepository.dashboard
              .getFashionCities(str)
              .then(data => {
                scope.cities = data;   
              }).finally(() => {
                scope.ajaxInProgress = false;
              });
          break; 

        default:
          console.log('Type not identified');
          scope.ajaxInProgress = false;
      }
    }; 

    scope.$watch(
      () => {
        return dashboardOverlayService.showOverlay;
      },
      (newValue) => {
        scope.showDashboardOverlay = newValue;
      }
    ); 
  },
]);
