(function () {
  angular.module("app").directive("hueChartByCrossIndustryFrequency", [
    function () {
      function link(scope, element) {
        scope.$watch("data", (newValue, oldValue) => {
          if(newValue) {
            bindData();
          }
        });
        function bindData() {
          const dataBrands = {};
          const mockData = scope.data;

          dataBrands.periods = [
            { title: "1 COLORS" },
            { title: "2 COLORS" },
            { title: "3 COLORS" },
          ];
  
          dataBrands.data = _.map(mockData, function (d0, index) {
            const dResult = [];
              dResult.push(
                { title: d0 && d0.color_precentages.length ? d0.color_precentages[0].percentage + "%" : '0%', value: 0.5 },
                { title: d0 && d0.color_precentages.length ? d0.color_precentages[1].percentage + "%" : '0%', value: 0.5 },
                { title: d0 && d0.color_precentages.length ? d0.color_precentages[2].percentage + "%" : '0%', value: 0.5 }
              );
              return {
                title: d0.name,
                image1: d0.logo_svg,
                color: scope.selectedColor,
                data: dResult,
              };
          });
  
          const dataForFirstChart = {
            data: dataBrands.data.slice(0, dataBrands.data.length / 2),
            periods: dataBrands.periods,
          };
  
          const dataForSecondChart = {
            data: [
              ...dataBrands.data.slice(dataBrands.data.length / 2)
            ],
            periods: dataBrands.periods,
          };
  
          const container = element.find("[chart-type]").html("");
  
          const ch = new chartCrossIndustry({
            data: dataForFirstChart,
            container: container[0],
            options: {
              layout: {
                mode: scope.mode && scope.mode.extraView ? "colors" : "default",
              },
            },
          });
  
          const container1 = element.find("[chart-type1]").html("");
  
          const ch1 = new chartCrossIndustry({
            data: dataForSecondChart,
            container: container1[0],
            options: {
              layout: {
                mode: scope.mode && scope.mode.extraView ? "colors" : "default",
              },
            },
          });
        }
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          data: "=",
          selectedColor: "=",
        },
        templateUrl:
          "app/directives/custom-infographics-brand-directives/chart-by-cross-industry-frequency.html",
      };

      return directive;
    },
  ]);
})();
