angular.module('app').directive('gaugeChart', () => {
  let link = (scope) => {
    var opts = {
      angle: 0.0, // The span of the gauge arc
      lineWidth: 0.1, // The line thickness
      radiusScale: 1, // Relative radius
      pointer: {
        length: 0.4, // // Relative to gauge radius
        strokeWidth: 0.07, // The thickness
        color: '#000000' // Fill color
      },
      limitMax: false,     // If false, max value increases automatically if value > maxValue
      limitMin: false,     // If true, the min value of the gauge will be fixed
      colorStart: '#000000',   // Colors
      colorStop: '#000000',    // just experiment with them
      strokeColor: '#000000',  // to see which ones work best for you
      generateGradient: true,
      highDpiSupport: true,     // High resolution support
    };

    scope.$watch('texts', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.texts = newValue;
      }
    });

    scope.$watch('value', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.value = newValue;
        setTimeout(() => {
          var target = document.getElementById('gauge_chart_' + scope.chartId + 'dataviz'); // your canvas element
          var gauge = new Gauge(target).setOptions(opts); // create sexy gauge!
          gauge.maxValue = 100; // set max gauge value
          gauge.setMinValue(0); // Prefer setter over gauge.minValue = 0
          gauge.animationSpeed = 3; // set animation speed (32 is default value)
          gauge.set(scope.value); // set actual value
        }, 10);
      }
    });
      
  };
  return {
    restrict: 'E',
    templateUrl: 'app/directives/gaugeChart/gaugeChart.html',
    link: link,
    scope: {
      value: "=",
      texts: "=",
      chartId: "="
    }
  };
});
