angular.module("app").directive("flowerFamiliesRepresentation", () => {
  let link = (scope) => {
    scope.$watch('data', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.data = newValue;
        scope.allData = scope.data;
      }
    }, true);

    scope.$watch('selectedItem', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.selectedItem = newValue;
        scope.selectedData = scope.selectedItem;
      }
    }, true);

    scope.loadMore = () => {
      scope.loadMoreData();
    };

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbFlowerFamiliesRepresentation/dbFlowerFamiliesRepresentationView.html",
    link: link,
    scope: {
      data: "=",
      selectedItem: "=",
      isLoadMoreData: "=",
      loadMoreData: "=",
    }
  };
});
