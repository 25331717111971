angular.module("app").directive("infographicsForAuto", [
  "$location",
  "$timeout",
  "customInfographicsConstants",
  "autoDashboardRepository",
  "charts",
  function (
    $location,
    timeout,
    customInfographicsConstants,
    autoDashboardRepository,
    charts
  ) {
    function link(scope) {
      scope.filtersForAuto = {};
      scope.meta = {};
      scope.isFilterVisible = customInfographicsConstants.isFilterVisible;
      scope.currentYear = moment().year();
      scope.chartsForAuto = [
        {
          qNumber: "AUTO1",
          id: "colorByBrand",
          chartTitle:
            "USE OF COLOR BY CAR BRAND",
          title: "1. USE OF COLOR BY CAR BRAND",
          api: function () {
            const color = scope.filtersForAuto.color && scope.filtersForAuto.color.id ? scope.filtersForAuto.color.id : '';
            const year = scope.filtersForAuto.year4 ? scope.filtersForAuto.year4.id : '';
            return autoDashboardRepository
              .getColorsBrand(color, year)
              .then(function (results) {
                return results;
              })
          },
          filters: {
            colorForAuto: true,
            year4: true,
          },
        },
        {
          qNumber: "AUTO2",
          id: "useByCarIndustry",
          chartTitle:
            "A TWO YEAR COMPARISON VIEW OF COLOR USE THE CAR INDUSTRY",
          title: "2. A TWO YEAR COMPARISON VIEW OF COLOR USE THE CAR INDUSTRY",
          filters: {
            year1: true,
            year2: true,
          },
        },
        {
          qNumber: "AUTO3",
          id: "industryUseOfColor",
          chartTitle: "A FIVE YEAR VIEW OF THE CAR INDUSTRY USE OF COLOR",
          title: "3. A FIVE YEAR VIEW OF THE CAR INDUSTRY USE OF COLOR",
          api: function () {
            const year = scope.filtersForAuto.yearsForFiveYear ? scope.filtersForAuto.yearsForFiveYear.id : '';
            return autoDashboardRepository.getIndustryUseOfColor(year)
              .then(function (results) {
                return results.data;
            });
          },
          filters: {
            yearsForFiveYear: true,
          },
        },
        {
          qNumber: "AUTO4",
          id: "ColorByType",
          chartTitle: "USE OF COLOR BY CAR TYPE",
          title: "4. USE OF COLOR BY CAR TYPE",
          api: function () {
            const color = scope.filtersForAuto.color && scope.filtersForAuto.color.id? scope.filtersForAuto.color.id : '';
            const year = scope.filtersForAuto.year3 ? scope.filtersForAuto.year3.id : '';
            return autoDashboardRepository
              .getColorsType(color, year)
              .then(function (results) {
                return results;
            });
          },
          filters: {
            colorForAuto: true,
            year3: true,
          },
        },
      ];

      scope.currentChartForAuto = {
        selected: scope.chartsForAuto[0],
      };

      autoDashboardRepository.getColorsForFilter().then((res) => {
        scope.meta.color = res.data.colors;
        scope.meta.color.unshift({
          id: "all",
          title: "COLOR",
          all: true,
        });
        scope.filtersForAuto.color = scope.meta.color[0];
        scope.meta.yearsForAuto1 = res.data.years
          .map((item) => {
            return {
              id: item,
              title: item,
            };
          });
        scope.meta.yearsForAuto2 = scope.meta.yearsForAuto1;
        scope.meta.yearsForAuto3 = scope.meta.yearsForAuto1;
        scope.meta.yearsForAuto4 = scope.meta.yearsForAuto1;
        scope.meta.yearsForAuto5 = scope.meta.yearsForAuto1;
        scope.filtersForAuto.year1 = scope.meta.yearsForAuto1[0];
        scope.filtersForAuto.year2 = scope.meta.yearsForAuto1[1];
        scope.filtersForAuto.year3 = scope.meta.yearsForAuto1[0];
        scope.filtersForAuto.year4 = scope.meta.yearsForAuto1[0];
        scope.filtersForAuto.yearsForFiveYear = scope.meta.yearsForAuto1[0];

        scope.initStatesByQueryParamsForAuto();
      });

      scope.initStatesByQueryParamsForAuto = function () {
        timeout(() => {
          scope.currentChartForAuto.selected =
            scope.chartsForAuto.find((i) => i.id === scope.currentChartForAuto.selected.id) ||
            scope.chartsForAuto[0];
          // scope.filtersForAuto.color =
          //   (scope.meta.color && scope.meta.color.find((i) => i.id == color)) ||
          //   scope.meta.color[0];
          // scope.filtersForAuto.year1 =
          //   (scope.meta.yearsForAuto1 &&
          //     scope.meta.yearsForAuto1.find((i) => i.id === year1)) ||
          //   scope.meta.color[0];
          // scope.filtersForAuto.year2 =
          //   (scope.meta.yearsForAuto2 &&
          //     scope.meta.yearsForAuto2.find((i) => i.id === year2)) ||
          //   scope.meta.color[0];
          scope.model = [];
          if (scope.currentChartForAuto.selected.api) {
            setTimeout(function(){
              scope.currentChartForAuto.selected.api().then((result) => {
                scope.model = result;
              });
            },2000);
          }
          if (scope.currentChartForAuto.selected.api2) {
            setTimeout(function(){
              scope.currentChartForAuto.selected.api2(scope.filtersForAuto.year2.id).then((result) => {
                scope.model1 = result;
              });
            },1000);
          }
          if(scope.currentChartForAuto.selected.qNumber === 'AUTO4'){
            setTimeout(function(){
              const color = scope.filtersForAuto.color ? scope.filtersForAuto.color.title : '';
              const year = scope.filtersForAuto.year3 ? scope.filtersForAuto.year3.id : '';
              scope.titleForAuto = 'USE OF ' + color + ' BY CAR TYPE IN ' + year;
            },500);
          } if(scope.currentChartForAuto.selected.qNumber === 'AUTO2'){ 
            scope.titleForAuto = scope.currentChartForAuto.selected.chartTitle;
            setTimeout(function(){
              scope.getYearData();
            },1000);
          } else if(scope.currentChartForAuto.selected.qNumber === 'AUTO1'){ 
            setTimeout(function(){
              const color = scope.filtersForAuto.color ? scope.filtersForAuto.color.title : '';
              scope.titleForAuto = 'USE OF ' + color + ' COLOR BY CAR BRAND';
            },500);
          } else {
            scope.titleForAuto = scope.currentChartForAuto.selected.chartTitle;
          }
          
        }, 0);
      };

      scope.updateQueryBySelectorsForAuto = function () {
        // const { color } = scope.filtersForAuto;
        // $location.search({
        //   tab: 4,
        //   board: scope.selectedMenuId,
        //   chart: scope.currentChartForAuto.selected.id,
        //   color: (color && color.id) || 1,
        // });
        scope.initStatesByQueryParamsForAuto();
      };

      scope.refresh = () => {
        scope.updateQueryBySelectorsForAuto();
      };

      scope.getYearData = () => {
        autoDashboardRepository.getTwoYearIndustryUseOfColor(scope.filtersForAuto.year1.id, scope.filtersForAuto.year2.id).then(function (res) {
          scope.twoYearIndustryLogoColor = res.data;
        });
      }

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
          result += name.toUpperCase() + ".";

        return result + date + type;
      };

      scope.exportJpgThroughDomToImage = (name) => {
        let node = angular.element("#autoCapture")[0];

        const titles = angular.element(".graphic-titles");
        const header = angular.element(".graphic-titles-export");
        const footer = angular.element(".customized-infographics-footer");

        footer.css({ display: "block" });
        header.css({ display: "block" });
        titles.css({ display: "none" });
        $("html").addClass("hide-scrollbar");

        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            const img = new Image();
            img.src = dataUrl;

            const link = document.createElement("a");
            link.href = img.src;
            link.download = scope.generateImgName(".png", "brand", name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            $("html").removeClass("hide-scrollbar");
            footer.css({ display: "none" });
            header.css({ display: "none" });
            titles.css({ display: "block" });
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      };
    }

    return {
      restrict: "E",
      templateUrl:
        "app/components/customized-infographics/infographicsForAuto/infographicsForAuto.html",
      link: link,
      scope: {
      },
    };
  },
]);
