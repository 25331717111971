angular.module("app").service("unicornConstants", [
  function () {
    this.isFilterVisible = (filterId, filters) => {
      const filterOptions = filters || {};
      let filter = filterOptions.all;
      if (!filter) {
        filter = filterOptions[filterId];
      }

      if (filter === true) {
        return true;
      } else if (angular.isFunction(filter)) {
        return filter();
      }

      return false;
    };

    this.menu = [
      { id: 1, title: "ARTIFICIAL INTELLIGENCE" },
      { id: 2, title: "AUTO & TRANSPORTATION" },
      { id: 3, title: "CONSUMER & RETAIL" },
      { id: 4, title: "CYBERSECURITY" },
      { id: 5, title: "DATA MANAGEMENT & ANALYTICS" },
      { id: 6, title: "E-COMMERCE & DIRECT-TO-CONSUMER" },
      { id: 7, title: "EDTECH" },
      { id: 8, title: "FINTECH" },
      { id: 9, title: "FOOD" },
      { id: 10, title: "HARDWARE" },
      { id: 10, title: "HEALTH" },
      { id: 10, title: "INTERNET SOFTWARE & SERVICE" },
      { id: 10, title: "MOBILE & TELECOMMUNICATIONS" },
      { id: 10, title: "OTHER" },
      { id: 10, title: "SUPPLY CHAIN, LOGISTICS & DELIVERY" },
    ];

    this.dataForLogos = [
      {
        percentage: "45",
        logo: "/assets/img/unicorn/artificial.png",
        title: "ARTIFICIAL INTELLIGENCE",
      },
      {
        percentage: "70",
        logo: "/assets/img/unicorn/auto.png",
        title: "AUTO & TRANSPORTATION",
      },
      {
        percentage: "20",
        logo: "/assets/img/unicorn/consumer_retail.png",
        title: "CONSUMER & RETAIL",
      },
      {
        percentage: "60",
        logo: "/assets/img/unicorn/cybersecurity.png",
        title: "CYBERSECURITY",
      },
      {
        percentage: "88",
        logo: "/assets/img/unicorn/data_mngt.png",
        title: "DATA MANAGEMENT & ANALYTICS",
      },
      {
        percentage: "10",
        logo: "/assets/img/unicorn/ecommerce.png",
        title: "E-COMMERCE & DIRECT-TO-CONSUMER",
      },
      {
        percentage: "50",
        logo: "/assets/img/unicorn/edtech.png",
        title: "EDTECH",
      },
      {
        percentage: "30",
        logo: "/assets/img/unicorn/finetch.png",
        title: "FINTECH",
      },
      {
        percentage: "60",
        logo: "/assets/img/unicorn/food.png",
        title: "FOOD",
      },
      {
        percentage: "20",
        logo: "/assets/img/unicorn/hardware.png",
        title: "HARDWARE",
      },
      {
        percentage: "60",
        logo: "/assets/img/unicorn/health.png",
        title: "HEALTH",
      },
      {
        percentage: "75",
        logo: "/assets/img/unicorn/internet_software.png",
        title: "INTERNET SOFTWARE & SERVICE",
      },
      {
        percentage: "40",
        logo: "/assets/img/unicorn/mobile_telecom.png",
        title: "MOBILE & TELECOMMUNICATIONS",
      },
      {
        percentage: "80",
        logo: "/assets/img/unicorn/other.png",
        title: "OTHER",
      },
      {
        percentage: "30",
        logo: "/assets/img/unicorn/supply_chain_logistics.png",
        title: "SUPPLY CHAIN, LOGISTICS & DELIVERY",
      }
    ];

    // dummy data -> unicorn popular color combinations for (color) across all industry
    const popularCombinationData = [
      {
        color: { hex: "#d8d635", title: "color1" },
        percentage: 12,
      },
      {
        color: { hex: "#89884b", title: "color2" },
        percentage: 42,
      },
      {
        color: { hex: "#684b89", title: "color3" },
        percentage: 24,
      },
      {
        color: { hex: "#bea1de", title: "color4" },
        percentage: 8,
      },
      {
        color: { hex: "#89884b", title: "color5" },
        percentage: 14,
      },
    ];
    this.unicornColorCombinationData = [
      {
        "id": 0,
        "title": "ARTIFICIAL INTELLIGENCE",
        "img_url": "/assets/img/unicorn/artificial.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 1,
        "title": "AUTO & TRANSPORTATION",
        "img_url": "/assets/img/unicorn/auto.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 2,
        "title": "CONSUMER & RETAIL",
        "img_url": "/assets/img/unicorn/consumer_retail.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 3,
        "title": "CYBERSECURITY",
        "img_url": "/assets/img/unicorn/cybersecurity.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 4,
        "title": "DATA MANAGEMENT & ANALYTICS",
        "img_url": "/assets/img/unicorn/data_mngt.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 5,
        "title": "E-COMMERCE & DIRECT-TO-CONSUMER",
        "img_url": "/assets/img/unicorn/ecommerce.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 6,
        "title": "EDTECH",
        "img_url": "/assets/img/unicorn/edtech.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 7,
        "title": "FINTECH",
        "img_url": "/assets/img/unicorn/finetch.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 8,
        "title": "FOOD",
        "img_url": "/assets/img/unicorn/food.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 9,
        "title": "HARDWARE",
        "img_url": "/assets/img/unicorn/hardware.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 10,
        "title": "HEALTH",
        "img_url": "/assets/img/unicorn/health.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 11,
        "title": "INTERNET SOFTWARE & SERVICE",
        "img_url": "/assets/img/unicorn/internet_software.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 12,
        "title": "MOBILE & TELECOMMUNICATIONS",
        "img_url": "/assets/img/unicorn/mobile_telecom.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 13,
        "title": "OTHER",
        "img_url": "/assets/img/unicorn/other.png",
        "colorData": popularCombinationData,
      },
      {
        "id": 14,
        "title": "SUPPLY CHAIN, LOGISTICS & DELIVERY",
        "img_url": "/assets/img/unicorn/supply_chain_logistics.png",
        "colorData": popularCombinationData,
      },
    ];

    this.countries = [
      {
        title: "ARGENTINA",
        percentage: "15%",
      },
      {
        title: "AUSTRALIA",
        percentage: "15%",
      },
      {
        title: "AUSTRIA",
        percentage: "15%",
      },
      {
        title: "BAHRAIN",
        percentage: "15%",
      },
      {
        title: "BANGLADESH",
        percentage: "15%",
      },
      {
        title: "BARBADOS",
        percentage: "15%",
      },
      {
        title: "BELGIUM",
        percentage: "15%",
      },
      {
        title: "BERMUDA",
        percentage: "15%",
      },
      {
        title: "BOSNIA AND HERZEGOVINA",
        percentage: "15%",
      },
      {
        title: "BOTSWANA",
        percentage: "15%",
      },
      {
        title: "BRAZIL",
        percentage: "15%",
      },
      {
        title: "BRITISH VIRGIN ISLANDS",
        percentage: "15%",
      },
      {
        title: "BULGARIA",
        percentage: "15%",
      },
      {
        title: "CANADA",
        percentage: "15%",
      },
      {
        title: "CAYMAN ISLANDS",
        percentage: "15%",
      },
      {
        title: "CHILE",
        percentage: "15%",
      },
      {
        title: "COLOMBIA",
        percentage: "15%",
      },
      {
        title: "COTE D'IVOIRE",
        percentage: "15%",
      },
      {
        title: "CROATIA",
        percentage: "15%",
      },
      {
        title: "CURACAO",
        percentage: "15%",
      },
      {
        title: "CYPRUS",
        percentage: "15%",
      },
      {
        title: "DENMARK",
        percentage: "15%",
      },
      {
        title: "ECUADOR",
        percentage: "15%",
      },
      {
        title: "EGYPT",
        percentage: "15%",
      },
      {
        title: "ESTONIA",
        percentage: "15%",
      },
      {
        title: "FINLAND",
        percentage: "15%",
      },
      {
        title: "FRANCE",
        percentage: "15%",
      },
      {
        title: "GABON",
        percentage: "15%",
      },
      {
        title: "GEORGIA",
        percentage: "15%",
      },
      {
        title: "GERMANY",
        percentage: "15%",
      },
      {
        title: "GHANA",
        percentage: "15%",
      },
      {
        title: "GREECE",
        percentage: "15%",
      },
      {
        title: "GUERNSEY",
        percentage: "15%",
      },
      {
        title: "HONG KONG",
        percentage: "15%",
      },
      {
        title: "HUNGARY",
        percentage: "15%",
      },
      {
        title: "ICELAND",
        percentage: "15%",
      },
      {
        title: "INDIA",
        percentage: "15%",
      },
      {
        title: "INDONESIA",
        percentage: "15%",
      },
      {
        title: "IRELAND",
        percentage: "15%",
      },
      {
        title: "ISLE OF MAN",
        percentage: "15%",
      },
      {
        title: "ISRAEL",
        percentage: "15%",
      },
      {
        title: "ITALY",
        percentage: "15%",
      },
      {
        title: "JAMAICA",
        percentage: "15%",
      },
      {
        title: "JAPAN",
        percentage: "15%",
      },
      {
        title: "JERSEY",
        percentage: "15%",
      },
      {
        title: "JORDAN",
        percentage: "15%",
      },
      {
        title: "KAZAKHSTAN",
        percentage: "15%",
      },
      {
        title: "KENYA",
        percentage: "15%",
      },
      {
        title: "KUWAIT",
        percentage: "15%",
      },
      {
        title: "LATVIA",
        percentage: "15%",
      },
      {
        title: "LEBANON",
        percentage: "15%",
      },
      {
        title: "LITHUANIA",
        percentage: "15%",
      },
      {
        title: "LUXEMBOURG",
        percentage: "15%",
      },
      {
        title: "MACAU",
        percentage: "15%",
      },
      {
        title: "MALAYSIA",
        percentage: "15%",
      },
      {
        title: "MALTA",
        percentage: "15%",
      },
      {
        title: "MAURITIUS",
        percentage: "15%",
      },
      {
        title: "MEXICO",
        percentage: "15%",
      },
      {
        title: "MONACO",
        percentage: "15%",
      },
      {
        title: "MOROCCO",
        percentage: "15%",
      },
      {
        title: "NAMIBIA",
        percentage: "15%",
      },
      {
        title: "NEW ZEALAND",
        percentage: "15%",
      },
      {
        title: "NIGERIA",
        percentage: "15%",
      },
      {
        title: "NORTHERN MARIANA",
        percentage: "15%",
      },
      {
        title: "ISLANDS",
        percentage: "15%",
      },
      {
        title: "NORWAY",
        percentage: "15%",
      },
      {
        title: "OMAN",
        percentage: "15%",
      },
      {
        title: "PAKISTAN",
        percentage: "15%",
      },
      {
        title: "PANAMA",
        percentage: "15%",
      },
      {
        title: "PERU",
        percentage: "15%",
      },
      {
        title: "POLAND",
        percentage: "15%",
      },
      {
        title: "PORTUGAL",
        percentage: "15%",
      },
      {
        title: "PUERTO RICO",
        percentage: "15%",
      },
      {
        title: "QATAR",
        percentage: "15%",
      },
      {
        title: "ROMANIA",
        percentage: "15%",
      },
      {
        title: "RUSSIA",
        percentage: "15%",
      },
      {
        title: "SAUDI ARABIA",
        percentage: "15%",
      },
      {
        title: "SENEGAL",
        percentage: "15%",
      },
      {
        title: "SERBIA",
        percentage: "15%",
      },
      {
        title: "SINGAPORE",
        percentage: "15%",
      },
      {
        title: "SLOVAKIA",
        percentage: "15%",
      },
      {
        title: "SLOVENIA",
        percentage: "15%",
      },
      {
        title: "SOUTH AFRICA",
        percentage: "15%",
      },
      {
        title: "SOUTH KOREA",
        percentage: "15%",
      },
      {
        title: "SPAIN",
        percentage: "15%",
      },
      {
        title: "SRI LANKA",
        percentage: "15%",
      },
      {
        title: "SWEDEN",
        percentage: "15%",
      },
      {
        title: "SWITZERLAND",
        percentage: "15%",
      },
      {
        title: "TAIWAN",
        percentage: "15%",
      },
      {
        title: "TANZANIA THAILAND",
        percentage: "15%",
      },
      {
        title: "THE BAHAMAS",
        percentage: "15%",
      },
      {
        title: "THE CZECH REPUBLIC",
        percentage: "15%",
      },
      {
        title: "THE NETHERLANDS",
        percentage: "15%",
      },
      {
        title: "THE PEOPLE'S",
        percentage: "15%",
      },
      {
        title: "REPUBLIC OF CHINA",
        percentage: "15%",
      },
      {
        title: "THE PHILIPPINES",
        percentage: "15%",
      },
      {
        title: "THE UNITED ARAB",
        percentage: "15%",
      },
      {
        title: "THE UNITED KINGDOM",
        percentage: "15%",
      },
      {
        title: "THE UNITED STATES",
        percentage: "15%",
      },
      {
        title: "TOGO",
        percentage: "15%",
      },
      {
        title: "TRINIDAD AND TOBAGO",
        percentage: "15%",
      },
      {
        title: "TUNISIA",
        percentage: "15%",
      },
      {
        title: "TURKEY",
        percentage: "15%",
      },
      {
        title: "UGANDA",
        percentage: "15%",
      },
      {
        title: "UKRAINE",
        percentage: "15%",
      },
      {
        title: "VENEZUELA",
        percentage: "15%",
      },
      {
        title: "VIETNAM",
        percentage: "15%",
      },
      {
        title: "ZAMBIA",
        percentage: "15%",
      },
      {
        title: "ZIMBABWE",
        percentage: "15%",
      },
    ];
    // dummy data -> VOLUME OF UNICORNS USING BEIGE IN THEIR LOGOS OVER THE YEARS 
    this.unicornColorVolumeDataOverYears = {
      data: {
        color: { hex: '#f00' },
        points: [300, 300.3, 300.7, 302.5, 299.5, 300.4, 300.7, 299.6, 298, 301.2, 298.7, 300.6, 302.5, 303, 297, 300.5],
        timeValues: ['2009' ,'2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020']
      },
      averageData: [298, 298.37476262917147, 298.7362491053694, 299.071653589958, 299.3690942118574, 299.6180339887499, 299.80965410493206, 299.93716632225727, 299.99605345685654, 299.98422940262896, 299.9021130325903, 299.7526133600877, 299.5410264855516, 299.2748479794974, 298.96350734820345, 298.6180339887499, 298.2506664671286, 297.8744189609414, 297.5026202256703, 297.14844141686984, 296.8244294954151, 296.5420627451572, 296.31134414899594, 296.1404470282235, 296.0354254985426, 296, 298.0354254985426, 296.1404470282235, 296.31134414899594, 298.5420627451572, 298.8244294954151, 299.14844141686984, 299.5026202256703, 299.8744189609414, 300.2506664671286, 300.6180339887499, 300.96350734820345, 301.2748479794974, 301.5410264855516, 301.7526133600877, 301.9021130325903, 301.98422940262896, 301.99605345685654, 301.93716632225727, 301.80965410493206, 301.6180339887499, 301.3690942118574, 301.071653589958, 300.7362491053694, 300.37476262917147],
    };
    this.colorFrequencyData = {
      percentages: [
        ">18.44%",
        "9.83%-7.09%",
        "6.89%-4.76%",
        "9.83%",
      ],
      mostPopular: [
        {
          percentage: 20,
          title: "color1",
          color: { hex: "#fff6f9" }
        },
        {
          percentage: 20,
          title: "color2",
          color: { hex: "#f6bac6", hex1: "#ff0000" }
        },
        {
          percentage: 15,
          title: "color3",
          color: { hex: "#ff7f00" }
        },
        {
          percentage: 15,
          title: "color4",
          color: { hex: "#fff640", hex1: "#f3d725" }
        },
        {
          percentage: 15,
          title: "color5",
          color: { hex: "#2d9641" }
        },
        {
          percentage: 15,
          title: "color6",
          color: { hex: "#2065dc" }
        }
      ],
      moderate: [
        {
          percentage: 55,
          title: "color7",
          color: { hex: "#7d1f7f" }
        },
        {
          percentage: 45,
          title: "color8",
          color: { hex: "#644321" }
        },
      ],
      infrequent: [
        {
          percentage: 100,
          title: "color9",
          color: { hex: "#b9b9b8", hex1: "#2f52a0" }
        },
      ],
      not_used: [
        {
          percentage: 30,
          title: "color10",
          color: { hex: "#ffffff" }
        },
        {
          percentage: 70,
          title: "color11",
          color: { hex: "#020303" }
        },
      ]
    };
    // dummy data -> logo
    this.unicornActualLogos = [
      {
          "id": 326228,
          "title": "01.com",
          "description": "High Tech",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/326/228/original/01-com-squarelogo-1374523301243.png?1612541437",
          "industry_name": "High Tech",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 300973,
          "title": "1000Bulbs.com",
          "description": "Wholesale & Distribution",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/300/973/original/1000bulbs-com-squarelogo-1463056533038.png?1612537881",
          "industry_name": "Wholesale & Distribution",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361406,
          "title": "100run.com",
          "description": "100run.com is an online wealth management platform.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/406/original/01d71c163ea92c891be5.png?1612457319",
          "industry_name": "Financial Services",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361412,
          "title": "1040Now",
          "description": "1040Now Online Tax Prep and E-Filing is a breeze to use.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/412/original/fpvygpvltor6p7ztvchp.png?1612457322",
          "industry_name": "Financial Services",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361417,
          "title": "10up Japan",
          "description": "10UP is an Education facility management company.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/417/original/7fbdc903415825423f9b.webp?1612559520",
          "industry_name": "Consulting",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 162483,
          "title": "123Greetings",
          "description": "123Greetings.com is the world's leading online destination for human expressions reaching 95 million visitors annually.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/162/483/original/123greetings-squarelogo-1429599790435.png?1612519841",
          "industry_name": "High Tech",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 283989,
          "title": "123.Net",
          "description": "123Net offers fiber optic, fixed wireless, and dedicated internet access, colocation, IP transport, and private networking solutions.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/283/989/original/123-net-squarelogo-1539865313866.png?1612535732",
          "industry_name": "Telecommunications",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361447,
          "title": "12 Pillars",
          "description": "12 Pillars is provide digital futures, smart cities, and alternative delivery models.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/447/original/gdxdayfeadbgsdpvnkcm.png?1612457336",
          "industry_name": "Consulting",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361460,
          "title": "14Trees",
          "description": "14Trees is dedicated to accelerating the provision of affordable housing in Africa.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/460/original/l1iizuoyixpihlsiosle.webp?1612559685",
          "industry_name": "Warehousing & Storage",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361462,
          "title": "150KaDum",
          "description": "150KaDum is a distribution platform provides network marketing.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/462/original/4418ae39dfc1aedb1d60.png?1612457342",
          "industry_name": "Consulting",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361466,
          "title": "15post",
          "description": "15POST is a Strategy and Digital Transformation Specialists for Media Companies and Brands.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/466/original/fcb1d177d1735420c13e.png?1612457344",
          "industry_name": "Consulting",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361468,
          "title": "160 world",
          "description": "160 world is an interactive platform providing SMS messaging services and mobile instant messaging (MIM).",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/468/original/aed393f21516f7b3437c4e554c9c1593.png?1612457344",
          "industry_name": "Telecommunications",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361471,
          "title": "16th Ventures",
          "description": "Conseil aux entreprises et prise de participation",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/471/original/gxwnqfhevehqjhs0j8pd.webp?1612559692",
          "industry_name": "Consulting",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361474,
          "title": "1717910.com",
          "description": "1717910.com is a service platform for small and medium-sized copper enterprises under micro copper technology.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/474/original/85715502316e56404b03.png?1612457347",
          "industry_name": "Warehousing & Storage",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361478,
          "title": "17SUZAO",
          "description": "17SUZAO is an online platform that provides professional and comprehensive plastic physical property table query.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/478/original/0323e4ee18b5f4849b08.png?1612457349",
          "industry_name": "Miscellaneous Manufacturing",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 263623,
          "title": "1-800-Radiator",
          "description": "Automotive",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/263/623/original/1-800-radiator-squarelogo-1411145460019.png?1612533082",
          "industry_name": "Automotive",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361490,
          "title": "18shopping",
          "description": "18shopping is a membership system shopping mall.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/490/original/7e8e18ea59cfd7bf0d35.png?1612457357",
          "industry_name": "Real Estate",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361501,
          "title": "1a-Neuware",
          "description": "1a-Neuware is an online store that provides products for the home kitchen, home living, grills, and household appliances.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/501/original/492bd8efb84dafff5647.png?1612457362",
          "industry_name": "Wholesale & Distribution",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361507,
          "title": "1DayMakeover",
          "description": "1DayMakeover assembles hair stylists, makeup artists, and wardrobe experts to offer makeover services for customers.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/507/original/1489a472fe4576bd74332bcd1d90460d.gif.webp?1612559719",
          "industry_name": "Cosmetics & Personal Care",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361511,
          "title": "1Derrick",
          "description": "1Derrick is an independent oil and gas research and consulting firm.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/511/original/r1uonuhpbzerox0ckgxy.png?1612457366",
          "industry_name": "Oil & Gas",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361519,
          "title": "1 India Family Mart",
          "description": "1-India Family Mart (parent organisation Nysaa Retail Private Limited), the fastest growing Value Retail Chain in India.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/519/original/yi94wtzergjurrfbya5u.png?1612457369",
          "industry_name": "Real Estate",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361522,
          "title": "1mg",
          "description": "1mg is an online pharmacy network platform and generic medicine engine.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/522/original/lzuoi4ozzrdtii4p2bz9.png?1612457371",
          "industry_name": "Pharmaceuticals",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361523,
          "title": "1molar",
          "description": "1molar inc. is a biomedical technology company.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/523/original/tk9oe8njarfm1gmgbhdn.webp?1612559726",
          "industry_name": "Pharmaceuticals",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 320719,
          "title": "1-Page",
          "description": "1-Page is a HR technology company revolutionizing the way companies source, hire, and engage talent.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/320/719/original/1-page-squarelogo-1475524498583.png?1612540560",
          "industry_name": "High Tech",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361528,
          "title": "1Rivet",
          "description": "1Rivet offers information technology advice, outsourced services, and talent acquisition to enterprises.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/528/original/woeekz1bkswykruuf9zz.png?1612457373",
          "industry_name": "Commercial Services",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361530,
          "title": "1Sale formerly 1SaleADay",
          "description": "1Sale is an online e-commerce website that offers daily deals for products ranging from computer-related products to magazine subscriptions.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/530/original/a8fb56fc04311c44265005373e039cfb.jpg.webp?1612559730",
          "industry_name": "Telecommunications",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 281366,
          "title": "1st American",
          "description": "High Tech",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/281/366/original/1st-american-squarelogo-1428497364754.png?1612535364",
          "industry_name": "High Tech",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 1855,
          "title": "1st Ayd",
          "description": "Miscellaneous Manufacturing",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/001/855/original/1st-ayd-squarelogo-1424853427023.png?1612454603",
          "industry_name": "Miscellaneous Manufacturing",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361537,
          "title": "1st Choice Aerospace",
          "description": "1st Choice Aerospace provides MRO services, including pneumatics, fuel, electro-mechanical, electrical accessories and crew seats.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/537/original/y8tv02vylpwsy4ynlxau.webp?1612559733",
          "industry_name": "Aerospace & Defense",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361538,
          "title": "1st Choice Recruitment",
          "description": "1st Choice Recruitment is a team of dedicated professionals committed to job seekers.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/538/original/d0fc2e829eb54170579f.png?1612457377",
          "industry_name": "Consulting",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361542,
          "title": "1st Equity Title & Closing Services",
          "description": "1st Equity Title & Closing Services offers a title insurance and related services with one of the fastest turnarounds in the industry.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/542/original/l2g1idurumus8w0c8uar.webp?1612559736",
          "industry_name": "Insurance",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361562,
          "title": "1st United Credit Union",
          "description": "1st United Credit Union aims at credit, loan, banking and financial services.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/562/original/fynd7ce5lxrpcdgfywzd.webp?1612559759",
          "industry_name": "Banking",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361577,
          "title": "21cp.com",
          "description": "21cp.com is an e-commerce platform that produces plastic products.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/577/original/458b54681fbb16128c83.png?1612457391",
          "industry_name": "Miscellaneous Manufacturing",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361579,
          "title": "21gram",
          "description": "21gram provides pet funeral services.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/579/original/6c07e8e9fbc6061704db.png?1612457392",
          "industry_name": "Funeral Services",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361581,
          "title": "21Net",
          "description": "21Net Ltd. provides on-board broadband, multimedia, and connectivity solutions to railway companies.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/581/original/mzs4lnmmzap40rcvba1w.png?1612457392",
          "industry_name": "Telecommunications",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 357244,
          "title": "21st Century Consultancy For You",
          "description": "Business Services",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/357/244/original/21st-century-consultancy-for-you-squarelogo-1446795428782.png?1612548958",
          "industry_name": "Business Services",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361611,
          "title": "24 FIX",
          "description": "24 FIX offers maintenance solution to all type of properties, covering the whole range of works, from small to large scale maintenance.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/611/original/a3rgabiolugh6a3llgri.png?1612457403",
          "industry_name": "Home Furnishings",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361613,
          "title": "24Halal",
          "description": "Discover Halal locations in your area.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/613/original/qass4qdoszba5p1evnsq.png?1612457404",
          "industry_name": "Membership Organizations",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 346904,
          "title": "24 Hour Fitness",
          "description": "24 Hour Fitness is the world's largest privately owned and operated fitness center chain, and third in number of clubs behind Gold's Gym",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/346/904/original/24-hour-fitness-squarelogo-1497932055642.png?1612544760",
          "industry_name": "Arts, Entertainment & Recreation",
          "year": 1999,
          "website": "www.hightech.com"
      },
      {
          "id": 361616,
          "title": "24hourtek",
          "description": "24hourtek assists organizations of various sectors with IT Consulting, computer support and network services to solve technical issues.",
          "logo_url": "https://s3.amazonaws.com/huestorage/huedata-site/brand/companies/logos/000/361/616/original/vg1byypylbdmf7mbv6p1.png?1612457405",
          "industry_name": "Telecommunications",
          "year": 1999,
          "website": "www.hightech.com"
      }
    ]

    // dummy data -> country logo color use comparison
    const colorComparisonData = {
      percentages: [
        ">18.44%",
        "9.83%-7.09%",
        "6.89%-4.76%",
        "9.83%",
      ],
      mostPopular: [
        {
          percentage: 40,
          title: "color1",
          color: { hex: "#e51b1f" }
        },
        {
          percentage: 30,
          title: "color2",
          color: { hex: "#3563ad" }
        },
        {
          percentage: 30,
          title: "color3",
          color: { hex: "#ffffff" }
        },
      ],
      moderate: [
        {
          percentage: 33,
          title: "color4",
          color: { hex: "#000000" }
        },
        {
          percentage: 30,
          title: "color5",
          color: { hex: "#d1d839" }
        },
        {
          percentage: 37,
          title: "color6",
          color: { hex: "#a5a6a6" }
        },
      ],
      infrequent: [
        {
          percentage: 33,
          title: "color7",
          color: { hex: "#f5ea46" }
        },
        {
          percentage: 30,
          title: "color8",
          color: { hex: "#f07f13" }
        },
        {
          percentage: 37,
          title: "color9",
          color: { hex: "#4eb15b" }
        },
      ],
      not_used: [
        {
          percentage: 30,
          title: "color10",
          color: { hex: "#64c4d7" }
        },
        {
          percentage: 20,
          title: "color11",
          color: { hex: "#f0e8c7" }
        },
        {
          percentage: 10,
          title: "color12",
          color: { hex: "#e22087" }
        },
        {
          percentage: 25,
          title: "color13",
          color: { hex: "#654320" }
        },
        {
          percentage: 25,
          title: "color14",
          color: { hex: "#f5b8c7" }
        },
      ]
    };
    this.countryLogoComparisonData = [
      {
        name: "Spain",
        img_url: "/assets/img/unicorn/flag/spain.png",
        png: "https://flagcdn.com/w320/es.png",
        colorComparisonData
      },
      {
        name: "Argentina",
        img_url: "/assets/img/unicorn/flag/argentina.png",
        png: "https://flagcdn.com/w320/ar.png",
        colorComparisonData
      },
      {
        name: "Australia",
        img_url: "/assets/img/unicorn/flag/austallia.png",
        png: "https://flagcdn.com/w320/au.png",
        colorComparisonData
      },
      {
        name: "Belgium",
        img_url: "/assets/img/unicorn/flag/belgium.png",
        png: "https://flagcdn.com/w320/be.png",
        colorComparisonData
      },
      {
        name: "Brazil",
        img_url: "/assets/img/unicorn/flag/brazil.png",
        png: "https://flagcdn.com/w320/br.png",
        colorComparisonData
      },
      {
        name: "Canada",
        img_url: "/assets/img/unicorn/flag/canada.png",
        png: "https://flagcdn.com/w320/ca.png",
        colorComparisonData
      },
      {
        name: "Chile",
        img_url: "/assets/img/unicorn/flag/chile.png",
        png: "https://flagcdn.com/w320/cl.png",
        colorComparisonData
      },
      {
        name: "China",
        img_url: "/assets/img/unicorn/flag/china.png",
        png: "https://flagcdn.com/w320/cn.png",
        colorComparisonData
      },
      {
        name: "Colombia",
        img_url: "/assets/img/unicorn/flag/colombia.png",
        png: "https://flagcdn.com/w320/co.png",
        colorComparisonData
      },
      {
        name: "Croatia",
        img_url: "/assets/img/unicorn/flag/croatia.png",
        png: "https://flagcdn.com/w320/hr.png",
        colorComparisonData
      },
      {
        name: "Czech Republic",
        img_url: "/assets/img/unicorn/flag/czech.png",
        png: "https://flagcdn.com/w320/cz.png",
        colorComparisonData
      },
      {
        name: "Denmark",
        img_url: "/assets/img/unicorn/flag/denmark.png",
        png: "https://flagcdn.com/w320/dk.png",
        colorComparisonData
      },
      {
        name: "Estonia",
        img_url: "/assets/img/unicorn/flag/estonia.png",
        png: "https://flagcdn.com/w320/ee.png",
        colorComparisonData
      },  
    ];

    // dummy data -> industry logo color use comparison
    this.industryLogoComparisonData = [
      {
        name: "ARTIFICIAL INTELLIGENCE",
        img_url: "/assets/img/unicorn/artificial.png",
        colorComparisonData
      },
      {
        name: "AUTO & TRANSPORTATION",
        img_url: "/assets/img/unicorn/auto.png",
        colorComparisonData
      },
      {
        name: "CONSUMER & RETAIL",
        img_url: "/assets/img/unicorn/consumer_retail.png",
        colorComparisonData
      },
      {
        name: "CYBERSECURITY",
        img_url: "/assets/img/unicorn/cybersecurity.png",
        colorComparisonData
      },
      {
        name: "DATA MANAGEMENT & ANALYTICS",
        img_url: "/assets/img/unicorn/data_mngt.png",
        colorComparisonData
      },
      {
        name: "E-COMMERCE & DIRECT-TO-CONSUMER",
        img_url: "/assets/img/unicorn/ecommerce.png",
        colorComparisonData
      },
      {
        name: "EDTECH",
        img_url: "/assets/img/unicorn/edtech.png",
        colorComparisonData
      },
      {
        name: "FINTECH",
        img_url: "/assets/img/unicorn/finetch.png",
        colorComparisonData
      },
      {
        name: "FOOD",
        img_url: "/assets/img/unicorn/food.png",
        colorComparisonData
      },
      {
        name: "HARDWARE",
        img_url: "/assets/img/unicorn/hardware.png",
        colorComparisonData
      },
      {
        name: "HEALTH",
        img_url: "/assets/img/unicorn/health.png",
        colorComparisonData
      },
      {
        name: "INTERNET SOFTWARE & SERVICE",
        img_url: "/assets/img/unicorn/internet_software.png",
        colorComparisonData
      },
      {
        name: "MOBILE & TELECOMMUNICATIONS",
        img_url: "/assets/img/unicorn/mobile_telecom.png",
        colorComparisonData
      },
      {
        name: "OTHER",
        img_url: "/assets/img/unicorn/other.png",
        colorComparisonData
      },
      {
        name: "SUPPLY CHAIN, LOGISTICS & DELIVERY",
        img_url: "/assets/img/unicorn/supply_chain_logistics.png",
        colorComparisonData
      },
    ];

    // dummy data -> industry logo color across all industry
    this.colorLogoAcrossIndustryData = [
      {
        name: "ARTIFICIAL INTELLIGENCE",
        img_url: "/assets/img/unicorn/artificial.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "AUTO & TRANSPORTATION",
        img_url: "/assets/img/unicorn/auto.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "CONSUMER & RETAIL",
        img_url: "/assets/img/unicorn/consumer_retail.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "CYBERSECURITY",
        img_url: "/assets/img/unicorn/cybersecurity.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "DATA MANAGEMENT & ANALYTICS",
        img_url: "/assets/img/unicorn/data_mngt.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "E-COMMERCE & DIRECT-TO-CONSUMER",
        img_url: "/assets/img/unicorn/ecommerce.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "EDTECH",
        img_url: "/assets/img/unicorn/edtech.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "FINTECH",
        img_url: "/assets/img/unicorn/finetch.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "FOOD",
        img_url: "/assets/img/unicorn/food.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "HARDWARE",
        img_url: "/assets/img/unicorn/hardware.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "HEALTH",
        img_url: "/assets/img/unicorn/health.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "INTERNET SOFTWARE & SERVICE",
        img_url: "/assets/img/unicorn/internet_software.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "MOBILE & TELECOMMUNICATIONS",
        img_url: "/assets/img/unicorn/mobile_telecom.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "OTHER",
        img_url: "/assets/img/unicorn/other.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
      {
        name: "SUPPLY CHAIN, LOGISTICS & DELIVERY",
        img_url: "/assets/img/unicorn/supply_chain_logistics.png",
        color1: "30%",
        color2: "30%",
        color3: "30%"
      },
    ];

    // dummy data -> industry logo list
    this.listAllIndustryData = [
      {
        title: "ARTIFICIAL INTELLIGENCE",
        img_url: "/assets/img/unicorn/search/artificial_intelligence.svg",
      },
      {
        title: "AUTO & TRANSPORTATION",
        img_url: "/assets/img/unicorn/search/auto_transporatation.svg",
      },
      {
        title: "CONSUMER & RETAIL",
        img_url: "/assets/img/unicorn/search/consumer.svg",
      },
      {
        title: "CYBERSECURITY",
        img_url: "/assets/img/unicorn/search/cybersecurity.svg",
      },
      {
        title: "DATA MANAGEMENT & ANALYTICS",
        img_url: "/assets/img/unicorn/search/data_managment.svg",
      },
      {
        title: "E-COMMERCE & DIRECT-TO-CONSUMER",
        img_url: "/assets/img/unicorn/search/ecommerce.svg",
      },
      {
        title: "EDTECH",
        img_url: "/assets/img/unicorn/search/edtech.svg",
      },
      {
        title: "FINTECH",
        img_url: "/assets/img/unicorn/search/fintech.svg",
      },
      {
        title: "FOOD",
        img_url: "/assets/img/unicorn/search/food.svg",
      },
      {
        title: "HARDWARE",
        img_url: "/assets/img/unicorn/search/hardware.svg",
      },
      {
        title: "HEALTH",
        img_url: "/assets/img/unicorn/search/health.svg",
      },
      {
        title: "INTERNET SOFTWARE & SERVICE",
        img_url: "/assets/img/unicorn/search/interner_software.svg",
      },
      {
        title: "MOBILE & TELECOMMUNICATIONS",
        img_url: "/assets/img/unicorn/search/mobile_telecommunication.svg",
      },
      {
        title: "OTHER",
        img_url: "/assets/img/unicorn/search/other.svg",
      },
      {
        title: "SUPPLY CHAIN, LOGISTICS & DELIVERY",
        img_url: "/assets/img/unicorn/search/supply_chain.svg",
      },
    ];

    // dummy year list
    this.yearList = [
      {
          "id": 1988,
          "title": "1988"
      },
      {
          "id": 1989,
          "title": "1989"
      },
      {
          "id": 1990,
          "title": "1990"
      },
      {
          "id": 1991,
          "title": "1991"
      },
      {
          "id": 1992,
          "title": "1992"
      },
      {
          "id": 1993,
          "title": "1993"
      },
      {
          "id": 1994,
          "title": "1994"
      },
      {
          "id": 1995,
          "title": "1995"
      },
      {
          "id": 1996,
          "title": "1996"
      },
      {
          "id": 1997,
          "title": "1997"
      },
      {
          "id": 1998,
          "title": "1998"
      },
      {
          "id": 1999,
          "title": "1999"
      },
      {
          "id": 2000,
          "title": "2000"
      },
      {
          "id": 2001,
          "title": "2001"
      },
      {
          "id": 2002,
          "title": "2002"
      },
      {
          "id": 2003,
          "title": "2003"
      },
      {
          "id": 2004,
          "title": "2004"
      },
      {
          "id": 2005,
          "title": "2005"
      },
      {
          "id": 2006,
          "title": "2006"
      },
      {
          "id": 2007,
          "title": "2007"
      },
      {
          "id": 2008,
          "title": "2008"
      },
      {
          "id": 2009,
          "title": "2009"
      },
      {
          "id": 2010,
          "title": "2010"
      },
      {
          "id": 2011,
          "title": "2011"
      },
      {
          "id": 2012,
          "title": "2012"
      },
      {
          "id": 2013,
          "title": "2013"
      },
      {
          "id": 2014,
          "title": "2014"
      },
      {
          "id": 2015,
          "title": "2015"
      },
      {
          "id": 2016,
          "title": "2016"
      },
      {
          "id": 2017,
          "title": "2017"
      },
      {
          "id": 2018,
          "title": "2018"
      },
      {
          "id": 2019,
          "title": "2019"
      },
      {
          "id": 2020,
          "title": "2020"
      },
      {
          "id": 2021,
          "title": "2021"
      }
  ];

  //dummy data for top five logo color
  this.unicornFiveTopColorLogoData = [
    {
      "id": 0,
      "title": "ARTIFICIAL INTELLIGENCE",
      "img_url": "/assets/img/unicorn/artificial.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 1,
      "title": "AUTO & TRANSPORTATION",
      "img_url": "/assets/img/unicorn/auto.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 2,
      "title": "CONSUMER & RETAIL",
      "img_url": "/assets/img/unicorn/consumer_retail.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 3,
      "title": "CYBERSECURITY",
      "img_url": "/assets/img/unicorn/cybersecurity.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 4,
      "title": "DATA MANAGEMENT & ANALYTICS",
      "img_url": "/assets/img/unicorn/data_mngt.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 5,
      "title": "E-COMMERCE & DIRECT-TO-CONSUMER",
      "img_url": "/assets/img/unicorn/ecommerce.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 6,
      "title": "EDTECH",
      "img_url": "/assets/img/unicorn/edtech.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 7,
      "title": "FINTECH",
      "img_url": "/assets/img/unicorn/finetch.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 8,
      "title": "FOOD",
      "img_url": "/assets/img/unicorn/food.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 9,
      "title": "HARDWARE",
      "img_url": "/assets/img/unicorn/hardware.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 10,
      "title": "HEALTH",
      "img_url": "/assets/img/unicorn/health.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 11,
      "title": "INTERNET SOFTWARE & SERVICE",
      "img_url": "/assets/img/unicorn/internet_software.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 12,
      "title": "MOBILE & TELECOMMUNICATIONS",
      "img_url": "/assets/img/unicorn/mobile_telecom.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 13,
      "title": "OTHER",
      "img_url": "/assets/img/unicorn/other.png",
      "colorData": popularCombinationData,
    },
    {
      "id": 14,
      "title": "SUPPLY CHAIN, LOGISTICS & DELIVERY",
      "img_url": "/assets/img/unicorn/supply_chain_logistics.png",
      "colorData": popularCombinationData,
    },
  ];
  //-----------------------
      
    this.alphabet = [
      "a",
      "à",
      "b",
      "c",
      "d",
      "e",
      "é",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "ö",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];

    this.atoz = [
      {id: 'a-z', title: 'A - Z'},
      {id: 'a', title: 'a'},
      {id: 'b', title: 'b'},
      {id: 'c', title: 'c'},
      {id: 'd', title: 'd'},
      {id: 'e', title: 'e'},
      {id: 'f', title: 'f'},
      {id: 'g', title: 'g'},
      {id: 'h', title: 'h'},
      {id: 'i', title: 'i'},
      {id: 'j', title: 'j'},
      {id: 'k', title: 'k'},
      {id: 'l', title: 'l'},
      {id: 'm', title: 'm'},
      {id: 'n', title: 'n'},
      {id: 'o', title: 'o'},
      {id: 'p', title: 'p'},
      {id: 'q', title: 'q'},
      {id: 'r', title: 'r'},
      {id: 's', title: 's'},
      {id: 't', title: 't'},
      {id: 'u', title: 'u'},
      {id: 'v', title: 'v'},
      {id: 'w', title: 'w'},
      {id: 'x', title: 'x'},
      {id: 'y', title: 'y'},
      {id: 'z', title: 'z'}
    ]
    this.citiesAbbrevs = {
      London: "LN",
      Milan: "MI",
      Paris: "PR",
      Berlin: "BR",
      NewYork: "NY",
      Mexico: "MX",
      RioDeJaneiro: "RJ",
      Seoul: "SE",
      Tokyo: "TK",
      SaoPaulo: "SP",
      Istanbul: "IS",
      Monaco: "MN",
      Florence: "FL",
      Rome: "RO",
      Kiev: "KI",
      LosAngeles: "LA",
      LakmeIndia: "LI",
      Copenhagen: "CP",
      Salzburg: "SA",
      Stockholm: "ST",
      Madrid: "MA",
      Sydney: "SY",
      Dubai: "DU",
      Kaliningrad: "KA",
      Moscow: "MO",
      PalmSprings: "PS",
      Cannes: "CN",
      Cambridge: "CB",
      Tbilisi: "TB",
      Havana: "HA",
      Kyoto: "KO",
      SaintPetersburg: "SG",
      Shanghai: "SH",
    };

    this.regionsAbbrevs = {
      AsiaAndPacific: "AP",
      Europe: "EU",
      SouthAmerica: "LA",
      NorthAmerica: "NA",
    };

    this.groupTitlesTemplates = {
      beige: {
        name: "#f5f5dc",
        template: "#f5f5{0}",
      },
      black: {
        name: "#000000",
        template: "#{0}{0}{0}",
      },
      blue: {
        name: "#0000ff",
        template: "#{0}{0}ff",
      },
      brown: {
        name: "#964b00",
        template: "#{0}{1}00",
      },
      cyan: {
        name: "#00ffff",
        template: "#{0}ffff",
      },
      gray: {
        name: "#c0c0c0",
        template: "#{0}{0}{0}",
      },
      green: {
        name: "#008000",
        template: "#{0}80{0}",
      },
      magenta: {
        name: "#ff00ff",
        template: "#ff{0}ff",
      },
      orange: {
        name: "#ff7f00",
        template: "#ff{0}00",
      },
      red: {
        name: "#ff0000",
        template: "#ff{0}{1}",
      },
      violet: {
        name: "#8f00ff",
        template: "#{0}00ff",
      },
      white: {
        name: "#ffffff",
        template: "#{0}{0}{0}",
      },
      yellow: {
        name: "#ffff00",
        template: "#ffff{0}",
      },
      yellowgreen: {
        name: "#8db600",
        template: "#8d{0}00",
      },
    };

    // dummy data -> top 30 pharmaceutical companies that use (color)
    this.pharmaCompanyColorData = [];
    for (let i = 0; i < 30; i++) {
      if (i % 4 === 0) {
        this.pharmaCompanyColorData.push({
          name: "PFIZER",
          img_url: "/assets/img/company/pfizer-logo.png",
          percentage: 30,
        });
      } else {
        this.pharmaCompanyColorData.push({
          name: "TEVA",
          img_url: "/assets/img/company/teva-logo.png",
          percentage: 99,
        });
      }
    }

    // dummy data -> color use for diamond shape pills
    this.pillShapeColorData = [
      {
        name: "red",
        hexValue: "#ff111a",
        percentage: 5,
        count: 35261,
      },
      {
        name: "orange",
        hexValue: "#ff7f1a",
        percentage: 20,
        count: 35261,
      },
      {
        name: "yellow",
        hexValue: "#f4d629",
        percentage: 34,
        count: 35261,
      },
      {
        name: "green",
        hexValue: "#037b03",
        percentage: 5,
        count: 35261,
      },
      {
        name: "blue",
        hexValue: "#114394",
        percentage: 10,
        count: 35261,
      },
      {
        name: "violet",
        hexValue: "#78177a",
        percentage: 99,
        count: 35261,
      },
      {
        name: "magenta",
        hexValue: "#e896bc",
        percentage: 5,
        count: 35261,
      },
      {
        name: "brown",
        hexValue: "#5f3e19",
        percentage: 64,
        count: 35261,
      },
      {
        name: "gray",
        hexValue: "#b3b3b2",
        percentage: 5,
        count: 35261,
      },
      {
        name: "white",
        hexValue: "#f5f6f6",
        percentage: 5,
        count: 35261,
      },
      {
        name: "black",
        hexValue: "#000000",
        percentage: 99,
        count: 35261,
      },
    ];

    // dummy data -> use of (color) by pill shape
    this.colurUseDataByPillShape = [
      {
        name: "ROUND",
        img_url: "/assets/img/shapes/round.png",
        count: 12456,
        percentage: 99,
      },
      {
        name: "OVAL",
        img_url: "/assets/img/shapes/oval.png",
        count: 12456,
        percentage: 5,
      },
      {
        name: "CAPSULE",
        img_url: "/assets/img/shapes/capsule.png",
        count: 12456,
        percentage: 15,
      },
      {
        name: "RECTANGLE",
        img_url: "/assets/img/shapes/rectangle.png",
        count: 12456,
        percentage: 99,
      },
      {
        name: "TRIANGLE",
        img_url: "/assets/img/shapes/triangle.png",
        count: 12456,
        percentage: 5,
      },
      {
        name: "HEXAGON",
        img_url: "/assets/img/shapes/hexagon.png",
        count: 12456,
        percentage: 15,
      },
      {
        name: "DIAMOND",
        img_url: "/assets/img/shapes/diamond.png",
        count: 12456,
        percentage: 5,
      },
      {
        name: "PENTAGON",
        img_url: "/assets/img/shapes/pentagon.png",
        count: 12456,
        percentage: 15,
      },
      {
        name: "SQUARE",
        img_url: "/assets/img/shapes/square.png",
        count: 12456,
        percentage: 99,
      },
      {
        name: "BULLET",
        img_url: "/assets/img/shapes/bullet.png",
        count: 12456,
        percentage: 5,
      },
      {
        name: "FREEFORM",
        img_url: "/assets/img/shapes/freeform.png",
        count: 12456,
        percentage: 15,
      },
      {
        name: "OCTAGON",
        img_url: "/assets/img/shapes/octagon.png",
        count: 12456,
        percentage: 5,
      },
      {
        name: "TEAR",
        img_url: "/assets/img/shapes/tear.png",
        count: 12456,
        percentage: 99,
      },
      {
        name: "DOUBLE-CIRCLE",
        img_url: "/assets/img/shapes/double-circle.png",
        count: 12456,
        percentage: 5,
      },
      {
        name: "SEMI-CIRCLE",
        img_url: "/assets/img/shapes/semi-circle.png",
        count: 12456,
        percentage: 15,
      },
      {
        name: "CLOVER",
        img_url: "/assets/img/shapes/clover.png",
        count: 12456,
        percentage: 15,
      },
    ];

    // dummy data -> (color) trademarks across all product and service categories
    this.colorTrademarkDataAcrossProduct = [
      {
        name: "chemicals",
        img_url: "/assets/img/legals/products/chemicals.png",
        percentage: 30
      },
      {
        name: "paints",
        img_url: "/assets/img/legals/products/paints.png",
        percentage: 30
      },
      {
        name: "cosmetics and cleaning products",
        img_url: "/assets/img/legals/products/cosmetics-and-cleaning-products.png",
        percentage: 30
      },
      {
        name: "lubricants and fuels",
        img_url: "/assets/img/legals/products/lubricants-and-fuels.png",
        percentage: 60
      },
      {
        name: "pharmaceuticals",
        img_url: "/assets/img/legals/products/pharmaceuticals.png",
        percentage: 40
      },
      {
        name: "metal goods",
        img_url: "/assets/img/legals/products/metal-goods.png",
        percentage: 50
      },
      {
        name: "machinery",
        img_url: "/assets/img/legals/products/machinery.png",
        percentage: 85
      },
      {
        name: "hand tools",
        img_url: "/assets/img/legals/products/hand-tools.png",
        percentage: 35
      },
      {
        name: "electrical and scientific apparatus",
        img_url: "/assets/img/legals/products/electrical-and-scientific-appratus.png",
        percentage: 15
      },
      {
        name: "medical apparatus",
        img_url: "/assets/img/legals/products/medical-apparatus.png",
        percentage: 67
      },
      {
        name: "environmental control apparatus",
        img_url: "/assets/img/legals/products/environmental-control-apparatus.png",
        percentage: 50
      },
      {
        name: "vehicles",
        img_url: "/assets/img/legals/products/vehicles.png",
        percentage: 40
      },
      {
        name: "firearms",
        img_url: "/assets/img/legals/products/firearms.png",
        percentage: 90
      },
      {
        name: "jewelry",
        img_url: "/assets/img/legals/products/jewelry.png",
        percentage: 20
      },
      {
        name: "musical instruments",
        img_url: "/assets/img/legals/products/musical-instruments.png",
        percentage: 40
      },
      {
        name: "paper goods and printed matter",
        img_url: "/assets/img/legals/products/paper-goods-and-printed-matter.png",
        percentage: 10
      },
      {
        name: "rubber goods",
        img_url: "/assets/img/legals/products/rubber-goods.png",
        percentage: 100
      },
      {
        name: "leather goods",
        img_url: "/assets/img/legals/products/leather-goods.png",
        percentage: 70
      },
      {
        name: "toys and sporting goods",
        img_url: "/assets/img/legals/products/toys-and-sporting-goods.png",
        percentage: 70
      },
      {
        name: "meats and processed foods",
        img_url: "/assets/img/legals/products/meats-and-processed-foods.png",
        percentage: 30
      },
      {
        name: "staple foods",
        img_url: "/assets/img/legals/products/staple-foods.png",
        percentage: 75
      },
      {
        name: "natural agricultural products",
        img_url: "/assets/img/legals/products/natural-agricultural-products.png",
        percentage: 40
      },
      {
        name: "light beverages",
        img_url: "/assets/img/legals/products/light-beverages.png",
        percentage: 50
      },
      {
        name: "wines and sprits",
        img_url: "/assets/img/legals/products/wines-and-spirits.png",
        percentage: 100
      },
      {
        name: "smokers articles",
        img_url: "/assets/img/legals/products/smokers-articles.png",
        percentage: 30
      },
      {
        name: "advertising and business",
        img_url: "/assets/img/legals/products/advertising-and-business.png",
        percentage: 70
      },
      {
        name: "insurance and financial",
        img_url: "/assets/img/legals/products/insurance-and-financial.png",
        percentage: 70
      },
      {
        name: "non-metalic building materials",
        img_url: "/assets/img/legals/products/non-metalic-building-materials.png",
        percentage: 60
      },
      {
        name: "toys and sporting goods",
        img_url: "/assets/img/legals/products/toys-and-sporting-goods.png",
        percentage: 70
      },
      {
        name: "meats and processed foods",
        img_url: "/assets/img/legals/products/meats-and-processed-foods.png",
        percentage: 70
      },
      {
        name: "staple foods",
        img_url: "/assets/img/legals/products/staple-foods.png",
        percentage: 70
      },
      {
        name: "natural agricultural products",
        img_url: "/assets/img/legals/products/natural-agricultural-products.png",
        percentage: 100
      },
      {
        name: "light beverages",
        img_url: "/assets/img/legals/products/light-beverages.png",
        percentage: 20
      },
      {
        name: "wines and spirits",
        img_url: "/assets/img/legals/products/wines-and-spirits.png",
        percentage: 90
      },
      {
        name: "smokers articles",
        img_url: "/assets/img/legals/products/smokers-articles.png",
        percentage: 70
      },
      {
        name: "advertising and business",
        img_url: "/assets/img/legals/products/advertising-and-business.png",
        percentage: 40
      },
      {
        name: "insurance and financial",
        img_url: "/assets/img/legals/products/insurance-and-financial.png",
        percentage: 20
      },
      {
        name: "non-metallic building materials",
        img_url: "/assets/img/legals/products/non-metalic-building-materials.png",
        percentage: 40
      },
      {
        name: "furniture and articles not otherwise",
        img_url: "/assets/img/legals/products/furniture-and-articles.png",
        percentage: 70
      },
      {
        name: "housewares and glass",
        img_url: "/assets/img/legals/products/housewares-and-glass.png",
        percentage: 60
      },
      {
        name: "cordage and fibers",
        img_url: "/assets/img/legals/products/cordage-and-fibers.png",
        percentage: 10
      },
      {
        name: "yarns and threads",
        img_url: "/assets/img/legals/products/yarns-and-threads.png",
        percentage: 70
      },
      {
        name: "fabrics",
        img_url: "/assets/img/legals/products/fabrics.png",
        percentage: 30
      },
      {
        name: "clothing",
        img_url: "/assets/img/legals/products/clothing.png",
        percentage: 70
      },
      {
        name: "fancy goods",
        img_url: "/assets/img/legals/products/fancy-goods.png",
        percentage: 70
      },
      {
        name: "floor coverings",
        img_url: "/assets/img/legals/products/floor-coverings.png",
        percentage: 70
      },
      {
        name: "construction and repair",
        img_url: "/assets/img/legals/products/construction-and-repair.png",
        percentage: 10
      },
      {
        name: "communication",
        img_url: "/assets/img/legals/products/communication.png",
        percentage: 70
      },
      {
        name: "transporation and storage",
        img_url: "/assets/img/legals/products/transporation-and-storage.png",
        percentage: 20
      },
      {
        name: "material treatment",
        img_url: "/assets/img/legals/products/material-treatment.png",
        percentage: 10
      },
      {
        name: "education and entertainment",
        img_url: "/assets/img/legals/products/education-and-entertainment.png",
        percentage: 100
      },
      {
        name: "computer, scientific and legal",
        img_url: "/assets/img/legals/products/computer-scientific-and-legal.png",
        percentage: 70
      },
      {
        name: "hotels and restaurants",
        img_url: "/assets/img/legals/products/hotels-and-restaurants.png",
        percentage: 70
      },
      {
        name: "medical, beauty and agricultural",
        img_url: "/assets/img/legals/products/medical-beauty-and-agricultural.png",
        percentage: 30
      },
      {
        name: "staple foods",
        img_url: "/assets/img/legals/products/personal.png",
        percentage: 50
      },
    ];

    // dummy data -> (color) trademarks granted over five years
    this.colorTrademarkDataOverFiveYears = [
      {
        items: [
          [ "2015", "50" ],
          [ "2016", "60" ],
          [ "2017", "30" ],
          [ "2018", "80" ],
          [ "2019", "10" ],
          [ "2020", "70" ],
        ],
        color: {
          color: {hex: "#fff6f9"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "25" ],
          [ "2017", "40" ],
          [ "2018", "50" ],
          [ "2019", "80" ],
          [ "2020", "100" ],
        ],
        color: {
          color: {hex: "#f6bac6", hex1: "#ff0000"}
        }
      },
      {
        items: [
          [ "2015", "90" ],
          [ "2016", "90" ],
          [ "2017", "40" ],
          [ "2018", "80" ],
          [ "2019", "10" ],
          [ "2020", "50" ],
        ],
        color: {
          color: {hex: "#ff7f00"}
        }
      },
      {
        items: [
          [ "2015", "15" ],
          [ "2016", "25" ],
          [ "2017", "35" ],
          [ "2018", "50" ],
          [ "2019", "60" ],
          [ "2020", "90" ],
        ],
        color: {
          color: {hex: "#fff640", hex1: "#f3d725"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "15" ],
          [ "2017", "75" ],
          [ "2018", "45" ],
          [ "2019", "60" ],
          [ "2020", "20" ],
        ],
        color: {
          color: {hex: "#2d9641"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "80" ],
          [ "2017", "20" ],
          [ "2018", "75" ],
          [ "2019", "40" ],
          [ "2020", "45" ],
        ],
        color: {
          color: {hex: "#2065dc"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "40" ],
          [ "2017", "50" ],
          [ "2018", "15" ],
          [ "2019", "88" ],
          [ "2020", "43" ],
        ],
        color: {
          color: {hex: "#7d1f7f"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "45" ],
          [ "2017", "15" ],
          [ "2018", "95" ],
          [ "2019", "28" ],
          [ "2020", "36" ],
        ],
        color: {
          color: {hex: "#644321"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "16" ],
          [ "2017", "45" ],
          [ "2018", "55" ],
          [ "2019", "68" ],
          [ "2020", "76" ],
        ],
        color: {
          color: {hex: "#b9b9b8"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "23" ],
          [ "2017", "42" ],
          [ "2018", "59" ],
          [ "2019", "48" ],
          [ "2020", "66" ],
        ],
        color: {
          color: {hex: "#FFFFFF"}
        }
      },
      {
        items: [
          [ "2015", "10" ],
          [ "2016", "47" ],
          [ "2017", "54" ],
          [ "2018", "19" ],
          [ "2019", "69" ],
          [ "2020", "99" ],
        ],
        color: {
          color: {hex: "#020303"}
        }
      },
    ];

    // dummy data -> (color) combination for (color) used by (period) artists
    this.colorCombinationDataForArt = [
        {
          id: 0,
          hex: '#f0b7c2',
          title: 'color1',
          count: 31,
          percent: 70,
        },
        {
          id: 1,
          hex: '#d62421',
          title: 'color2',
          count: 31,
          percent: 40,
        },
        {
          id: 2,
          hex: '#771812',
          title: 'color3',
          count: 31,
          percent: 30,
        },
        {
          id: 3,
          hex: '#ecaa4d',
          title: 'color4',
          count: 31,
          percent: 90,
        },
        {
          id: 4,
          hex: '#e4791e',
          title: 'color5',
          count: 31,
          percent: 70,
        },
        {
          id: 5,
          hex: '#e68722',
          title: 'color6',
          count: 31,
          percent: 50,
        },
        {
          id: 6,
          hex: '#e9e698',
          title: 'color7',
          count: 31,
          percent: 70,
        },
        {
          id: 7,
          hex: '#e3db42',
          title: 'color8',
          count: 31,
          percent: 90,
        },
        {
          id: 8,
          hex: '#e7cf30',
          title: 'color9',
          count: 31,
          percent: 70,
        },
        {
          id: 9,
          hex: '#c7ce35',
          title: 'color10',
          count: 31,
          percent: 20,
        },
        {
          id: 10,
          hex: '#83a72c',
          title: 'color11',
          count: 31,
          percent: 70,
        },
        {
          id: 11,
          hex: '#495123',
          title: 'color12',
          count: 31,
          percent: 40,
        },
        {
          id: 12,
          hex: '#79b772',
          title: 'color13',
          count: 31,
          percent: 80,
        },
        {
          id: 13,
          hex: '#0f7835',
          title: 'color14',
          count: 31,
          percent: 70,
        },
        {
          id: 14,
          hex: '#0f311f',
          title: 'color15',
          count: 31,
          percent: 20,
        },
        {
          id: 15,
          hex: '#e5f1f5',
          title: 'color16',
          count: 31,
          percent: 70,
        },
        {
          id: 16,
          hex: '#6cbdcb',
          title: 'color17',
          count: 31,
          percent: 55,
        },
        {
          id: 17,
          hex: '#1d8180',
          title: 'color18',
          count: 31,
          percent: 40,
        },
        {
          id: 18,
          hex: '#a9c0c8',
          title: 'color19',
          count: 31,
          percent: 70,
        },
        {
          id: 19,
          hex: '#10468e',
          title: 'color20',
          count: 31,
          percent: 70,
        },
        {
          id: 20,
          hex: '#262f63',
          title: 'color21',
          count: 31,
          percent: 60,
        },
        {
          id: 21,
          hex: '#bc92ba',
          title: 'color22',
          count: 31,
          percent: 70,
        },
        {
          id: 22,
          hex: '#58418c',
          title: 'color23',
          count: 31,
          percent: 30,
        },
        {
          id: 23,
          hex: '#663887',
          title: 'color24',
          count: 31,
          percent: 70,
        },
        {
          id: 24,
          hex: '#de92b4',
          title: 'color25',
          count: 31,
          percent: 90,
        },
        {
          id: 25,
          hex: '#994e91',
          title: 'color26',
          count: 31,
          percent: 70,
        },
        {
          id: 26,
          hex: '#762377',
          title: 'color27',
          count: 31,
          percent: 30,
        },
        {
          id: 27,
          hex: '#ffffff',
          title: 'color28',
          count: 31,
          percent: 70,
        },
        {
          id: 28,
          hex: '#eff1d9',
          title: 'color29',
          count: 31,
          percent: 70,
        },
        {
          id: 29,
          hex: '#ba9669',
          title: 'color30',
          count: 31,
          percent: 90,
        },
        {
          id: 30,
          hex: '#806543',
          title: 'color31',
          count: 31,
          percent: 70,
        },
        {
          id: 31,
          hex: '#aebcb3',
          title: 'color32',
          count: 31,
          percent: 40,
        },
        {
          id: 32,
          hex: '#c0c0be',
          title: 'color33',
          count: 31,
          percent: 70,
        },
        {
          id: 33,
          hex: '#34454e',
          title: 'color34',
          count: 31,
          percent: 50,
        },
        {
          id: 34,
          hex: '#806751',
          title: 'color35',
          count: 31,
          percent: 70,
        },
        {
          id: 35,
          hex: '#8f4b17',
          title: 'color36',
          count: 31,
          percent: 70,
        },
        {
          id: 36,
          hex: '#634221',
          title: 'color37',
          count: 31,
          percent: 100,
        },
        {
          id: 37,
          hex: '#050607',
          title: 'color38',
          count: 31,
          percent: 70,
        },
        {
          id: 38,
          hex: '#060606',
          title: 'color39',
          count: 31,
          percent: 80,
        },
      ];

    // dummy data -> selected color combinations by (artist)
    this.selectedColorCombinationsByArtist = [
      [
        {hex: "#d000a4", R: 208, G: 0, B: 164, percent: 24},
        {hex: "#ff9c00", R: 255, G: 156, B: 0, percent: 24},
        {hex: "#e78e00", R: 231, G: 142, B: 0, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#1600e7", R: 22, G: 0, B: 231, percent: 24},
        {hex: "#ec5cff", R: 236, G: 92, B: 255, percent: 24},
        {hex: "#e78e00", R: 231, G: 142, B: 0, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#9fff00", R: 159, G: 255, B: 0, percent: 24},
        {hex: "#4584ff", R: 69, G: 132, B: 255, percent: 24},
        {hex: "#e78e00", R: 231, G: 142, B: 0, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#5c3800", R: 96, G: 52, B: 0, percent: 24},
        {hex: "#3c005c", R: 60, G: 0, B: 92, percent: 24},
        {hex: "#7dff45", R: 125, G: 255, B: 69, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#9fff00", R: 159, G: 255, B: 0, percent: 24},
        {hex: "#4584ff", R: 69, G: 132, B: 255, percent: 24},
        {hex: "#eaff45", R: 234, G: 255, B: 69, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#8c17ff", R: 140, G: 23, B: 255, percent: 24},
        {hex: "#ffd58b", R: 255, G: 213, B: 139, percent: 24},
        {hex: "#3200d0", R: 50, G: 0, B: 208, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#9fff00", R: 159, G: 255, B: 0, percent: 24},
        {hex: "#4584ff", R: 69, G: 132, B: 255, percent: 24},
        {hex: "#375c00", R: 55, G: 92, B: 0, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#730028", R: 115, G: 0, B: 40, percent: 24},
        {hex: "#18ff00", R: 24, G: 255, B: 0, percent: 24},
        {hex: "#e78e00", R: 231, G: 142, B: 0, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#9fff00", R: 159, G: 255, B: 0, percent: 24},
        {hex: "#4584ff", R: 69, G: 132, B: 255, percent: 24},
        {hex: "#300073", R: 48, G: 0, B: 115, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ],
      [
        {hex: "#ff3f17", R: 255, G: 63, B: 23, percent: 24},
        {hex: "#00a29f", R: 0, G: 162, B: 159, percent: 24},
        {hex: "#e78e00", R: 231, G: 142, B: 0, percent: 44},
        {hex: "#d000bd", R: 208, G: 0, B: 189, percent: 8},
      ]
    ];

    // dummy data -> unique colors used by (artist)
    this.artistUnqiueColorsData = [
      {hex: "#deb4a1", R: 222, G: 180, B: 161},
      {hex: "#dfc3b8", R: 223, G: 195, B: 184},
      {hex: "#a8a1de", R: 168, G: 161, B: 222},
      {hex: "#dec3a1", R: 222, G: 195, B: 161},
      {hex: "#dea1a1", R: 222, G: 161, B: 161},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#de5e2c", R: 222, G: 94, B: 44},
      {hex: "#a1debf", R: 161, G: 222, B: 191},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#dec3a1", R: 222, G: 195, B: 161},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#a1debf", R: 161, G: 222, B: 191},

      {hex: "#a8a1de", R: 168, G: 161, B: 222},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#a8a1de", R: 168, G: 161, B: 222},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#875c4b", R: 135, G: 92, B: 75},
      {hex: "#a1debf", R: 161, G: 222, B: 191},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#de5e2c", R: 222, G: 94, B: 44},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#c3dea1", R: 195, G: 222, B: 161},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#c3dea1", R: 195, G: 222, B: 161},

      {hex: "#c3dea1", R: 195, G: 222, B: 161},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#a1debf", R: 161, G: 222, B: 191},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#ded8a1", R: 222, G: 216, B: 161},
      {hex: "#bea1de", R: 190, G: 161, B: 222},
      {hex: "#a8a1de", R: 168, G: 161, B: 222},
      {hex: "#875c4b", R: 135, G: 92, B: 75},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#dea1a1", R: 222, G: 161, B: 161},

      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#a1dec9", R: 161, G: 222, B: 201},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#de5e2c", R: 222, G: 94, B: 44},
      {hex: "#dea1a1", R: 222, G: 161, B: 161},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#a1debf", R: 161, G: 222, B: 191},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#ded8a1", R: 222, G: 216, B: 161},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
      {hex: "#bea1de", R: 190, G: 161, B: 222},
      {hex: "#9612c7", R: 150, G: 18, B: 199},
    ];

    // dummy data -> birds color families in 6 regions
    this.birdColorDataAcrossRegions = [
      {
        id: 0,
        title: "North America & Canada"
      },
      {
        id: 1,
        title: "South America"
      },
      {
        id: 2,
        title: "Europe"
      },
      {
        id: 3,
        title: "Africa"
      },
      {
        id: 4,
        title: "Asia"
      },
      {
        id: 5,
        title: "Australia"
      },
    ];

    // dummy data -> flower lifespan across color families
    this.flowerLifespanData = [
      {
        id: 0,
        hex: '#e6341c',
        title: 'red',
        img_url: "/assets/img/nature/flower-families/red.png",
      },
      {
        id: 1,
        hex: '#f2891d',
        title: 'orange',
        img_url: "/assets/img/nature/flower-families/orange.png",
      },
      {
        id: 2,
        hex: '#fae51b',
        title: 'yellow',
        img_url: "/assets/img/nature/flower-families/yellow.png",
      },
      {
        id: 3,
        hex: '#46ac33',
        title: 'green',
        img_url: "/assets/img/nature/flower-families/green.png",
      },
      {
        id: 4,
        hex: '#37b1e6',
        title: 'blue',
        img_url: "/assets/img/nature/flower-families/blue.png",
      },
      {
        id: 5,
        hex: '#6d4595',
        title: 'purple',
        img_url: "/assets/img/nature/flower-families/purple.png",
      },
      {
        id: 6,
        hex: '#f7c6de',
        title: 'pink',
        img_url: "/assets/img/nature/flower-families/pink.png",
      },
      {
        id: 7,
        hex: '#63310e',
        title: 'brown',
        img_url: "/assets/img/nature/flower-families/brown.png",
      },
      {
        id: 8,
        hex: '#ffffff',
        title: 'white',
        img_url: "/assets/img/nature/flower-families/white.png",
      },
    ];

    // dummy data -> most liked/disliked colors // april 2022
    this.colorSentimentMonthData = {
      date: "",
      positiveData: {
        colors: [
          { hex: "#fff6f9", percentage: 34 },
          { hex: "#f6bac6", hex1: "#ff0000", percentage: 33 },
          { hex: "#ff7f00", percentage: 33 }
        ],
        percentData: "8.44%"
      },
      negativeData: {
        colors: [
          { hex: "#752478", percentage: 34 },
          { hex: "#634321", percentage: 33 },
          { hex: "#2a9042", percentage: 33 }
        ],
        percentData: "8.44%"
      }
    };

    // dummy data -> use of (color) across all sports
    this.colorDataAcrossSports = [
      {
        "id": 0,
        "title": "Baseball",
        "img_url": "/assets/img/sports/types/baseball.png",
        "percentage": 11,
      },
      {
        "id": 1,
        "title": "Basketball",
        "img_url": "/assets/img/sports/types/basketball.png",
        "percentage": 11,
      },
      {
        "id": 2,
        "title": "Football",
        "img_url": "/assets/img/sports/types/football.png",
        "percentage": 11,
      },
      {
        "id": 3,
        "title": "Hockey",
        "img_url": "/assets/img/sports/types/hockey.png",
        "percentage": 11,
      },
      {
        "id": 4,
        "title": "Soccer",
        "img_url": "/assets/img/sports/types/soccer.png",
        "percentage": 11,
      },
      {
        "id": 5,
        "title": "Lacrosse",
        "img_url": "/assets/img/sports/types/lacrosse.png",
        "percentage": 11,
      },
      {
        "id": 6,
        "title": "Rugby",
        "img_url": "/assets/img/sports/types/rugby.png",
        "percentage": 11,
      },
    ];

    // dummy data -> use of (color) across all sport teams
    this.colorDataAcrossTeams = [
      {
        id: 0,
        title: "Anaheim Ducks",
        img_url: "/assets/img/sports/teams/1.png",
        percentage: 99,
      },
      {
        id: 1,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/2.png",
        percentage: 70,
      },
      {
        id: 2,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/3.png",
        percentage: 40,
      },
      {
        id: 3,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/4.png",
        percentage: 60,
      },
      {
        id: 4,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/5.png",
        percentage: 80,
      },
      {
        id: 5,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/6.png",
        percentage: 75,
      },
      {
        id: 6,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/7.png",
        percentage: 65,
      },
      {
        id: 7,
        title: "Vegas Golden Knights",
        img_url: "/assets/img/sports/teams/8.png",
        percentage: 70,
      },
      {
        id: 8,
        title: "Anaheim ducks",
        img_url: "/assets/img/sports/teams/9.png",
        percentage: 99,
      },
      {
        id: 9,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/10.png",
        percentage: 85,
      },
      {
        id: 10,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/1.png",
        percentage: 74,
      },
      {
        id: 11,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/2.png",
        percentage: 70,
      },
      {
        id: 12,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/3.png",
        percentage: 40,
      },
      {
        id: 13,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/4.png",
        percentage: 50,
      },
      {
        id: 14,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/5.png",
        percentage: 30,
      },
      {
        id: 15,
        title: "Anaheim Ducks",
        img_url: "/assets/img/sports/teams/1.png",
        percentage: 80,
      },
      {
        id: 16,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/2.png",
        percentage: 70,
      },
      {
        id: 17,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/3.png",
        percentage: 60,
      },
      {
        id: 18,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/4.png",
        percentage: 67,
      },
      {
        id: 19,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/5.png",
        percentage: 73,
      },
      {
        id: 20,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/6.png",
        percentage: 78,
      },
      {
        id: 21,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/7.png",
        percentage: 80,
      },
      {
        id: 22,
        title: "Vegas Golden Knights",
        img_url: "/assets/img/sports/teams/8.png",
        percentage: 85,
      },
      {
        id: 23,
        title: "Anaheim ducks",
        img_url: "/assets/img/sports/teams/9.png",
        percentage: 86,
      },
      {
        id: 24,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/10.png",
        percentage: 70,
      },
      {
        id: 25,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/1.png",
        percentage: 90,
      },
      {
        id: 26,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/2.png",
        percentage: 60,
      },
      {
        id: 27,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/3.png",
        percentage: 50,
      },
      {
        id: 28,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/4.png",
        percentage: 90,
      },
      {
        id: 29,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/5.png",
        percentage: 80,
      },
    ];

    // dummy data -> color combinations for each of the (selected sport) teams
    const colorCombinationData = [
      {hex: "#40ff0e", R: 64, G: 255, B: 14, percent: 28},
      {hex: "#0084da", R: 0, G: 132, B: 218, percent: 28},
      {hex: "#a7007f", R: 167, G: 0, B: 127, percent: 12},
      {hex: "#ffe32b", R: 255, G: 227, B: 43, percent: 32}
    ];
    this.colorCombinationTeamData = [
      {
        id: 0,
        title: "Anaheim Ducks",
        img_url: "/assets/img/sports/teams/1.png",
        colors: colorCombinationData
      },
      {
        id: 1,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/2.png",
        colors: colorCombinationData
      },
      {
        id: 2,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/3.png",
        colors: colorCombinationData
      },
      {
        id: 3,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/4.png",
        colors: colorCombinationData
      },
      {
        id: 4,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/5.png",
        colors: colorCombinationData
      },
      {
        id: 5,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/6.png",
        colors: colorCombinationData
      },
      {
        id: 6,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/7.png",
        colors: colorCombinationData
      },
    ];

    // dummy data -> color use across all (selected sport) teams
    this.colorDataAcrossAllSportTeams = [
      {
        id: 0,
        title: "Anaheim Ducks",
        img_url: "/assets/img/sports/teams/1.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', null, '#8db723', null, null, '#3563ad',
          '#5d4596', null, '#974d15', '#f4f5dc', null, '#ffffff', '#020303'
        ]
      },
      {
        id: 1,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/2.png",
        sport: "NFL",
        colors: [
          '#ef103a', null, null, '#8db723', '#0a8236', null, '#3563ad',
          null, '#a6529b', '#974d15', null, '#c0c0bf', '#ffffff', null
        ]
      },
      {
        id: 2,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/3.png",
        sport: "NFL",
        colors: [
          null, '#f07e14', '#f1e60f', null, '#0a8236', null, null,
          '#5d4596', '#a6529b', null, '#f4f5dc', '#c0c0bf', '#ffffff', '#020303'
        ]
      },
      {
        id: 3,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/4.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', null, null, null, '#3563ad',
          null, '#a6529b', null, '#f4f5dc', '#c0c0bf', null, '#020303'
        ]
      },
      {
        id: 4,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/5.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', null, '#8db723', null, '#6ac6d9', null,
          '#5d4596', '#a6529b', null, '#f4f5dc', '#c0c0bf', null, null
        ]
      },
      {
        id: 5,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/6.png",
        sport: "NFL",
        colors: [
          null, '#f07e14', null, '#8db723', null, null, '#3563ad',
          '#5d4596', null, '#974d15', null, '#c0c0bf', '#ffffff', '#020303'
        ]
      },
      {
        id: 6,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/7.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', '#8db723', '#0a8236', '#6ac6d9', '#3563ad',
          '#5d4596', '#a6529b', '#974d15', '#f4f5dc', '#c0c0bf', '#ffffff', '#020303'
        ]
      },
      {
        id: 7,
        title: "Vegas Golden Knights",
        img_url: "/assets/img/sports/teams/8.png",
        sport: "NFL",
        colors: [
          '#ef103a', null, '#f1e60f', null, null, '#6ac6d9', '#3563ad',
          '#5d4596', null, '#974d15', null, '#c0c0bf', null, '#020303'
        ]
      },
      {
        id: 8,
        title: "Anaheim ducks",
        img_url: "/assets/img/sports/teams/9.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', '#8db723', null, '#6ac6d9', null,
          '#5d4596', null, null, '#f4f5dc', '#c0c0bf', null, null
        ]
      },
      {
        id: 9,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/10.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', null, '#8db723', '#0a8236', null, '#3563ad',
          '#5d4596', null, null, '#f4f5dc', null, null, '#020303'
        ]
      },
      {
        id: 10,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/1.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', null, null, '#0a8236', '#6ac6d9', '#3563ad',
          '#5d4596', '#a6529b', null, null, null, null, '#020303'
        ]
      },
      {
        id: 11,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/2.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', '#8db723', '#0a8236', null, '#3563ad',
          '#5d4596', '#a6529b', null, '#f4f5dc', null, null, null
        ]
      },
      {
        id: 12,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/3.png",
        sport: "NFL",
        colors: [
          null, '#f07e14', '#f1e60f', null, '#0a8236', '#6ac6d9', '#3563ad',
          '#5d4596', '#a6529b', '#974d15', null, '#c0c0bf', null, null
        ]
      },
      {
        id: 13,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/4.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', '#8db723', null, '#6ac6d9', null,
          '#5d4596', null, '#974d15', null, '#c0c0bf', null, null
        ]
      },
      {
        id: 14,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/5.png",
        sport: "NFL",
        colors: [
          null, null, '#f1e60f', '#8db723', null, '#6ac6d9', '#3563ad',
          null, '#a6529b', '#974d15', '#f4f5dc', null, '#ffffff', null
        ]
      },
      {
        id: 15,
        title: "Anaheim Ducks",
        img_url: "/assets/img/sports/teams/1.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', null, '#8db723', null, null, '#3563ad',
          '#5d4596', null, '#974d15', '#f4f5dc', null, '#ffffff', '#020303'
        ]
      },
      {
        id: 16,
        title: "Arizona Coyotes",
        img_url: "/assets/img/sports/teams/2.png",
        sport: "NFL",
        colors: [
          '#ef103a', null, null, '#8db723', '#0a8236', null, '#3563ad',
          null, '#a6529b', '#974d15', null, '#c0c0bf', '#ffffff', null
        ]
      },
      {
        id: 17,
        title: "Calgary Flames",
        img_url: "/assets/img/sports/teams/3.png",
        sport: "NFL",
        colors: [
          null, '#f07e14', '#f1e60f', null, '#0a8236', null, null,
          '#5d4596', '#a6529b', null, '#f4f5dc', '#c0c0bf', '#ffffff', '#020303'
        ]
      },
      {
        id: 18,
        title: "Edmonton Oilers",
        img_url: "/assets/img/sports/teams/4.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', null, null, null, '#3563ad',
          null, '#a6529b', null, '#f4f5dc', '#c0c0bf', null, '#020303'
        ]
      },
      {
        id: 19,
        title: "Los Angeles Kings",
        img_url: "/assets/img/sports/teams/5.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', null, '#8db723', null, '#6ac6d9', null,
          '#5d4596', '#a6529b', null, '#f4f5dc', '#c0c0bf', null, null
        ]
      },
      {
        id: 20,
        title: "San Jose Sharks",
        img_url: "/assets/img/sports/teams/6.png",
        sport: "NFL",
        colors: [
          null, '#f07e14', null, '#8db723', null, null, '#3563ad',
          '#5d4596', null, '#974d15', null, '#c0c0bf', '#ffffff', '#020303'
        ]
      },
      {
        id: 21,
        title: "Vancouver Canucks",
        img_url: "/assets/img/sports/teams/7.png",
        sport: "NFL",
        colors: [
          '#ef103a', '#f07e14', '#f1e60f', '#8db723', '#0a8236', '#6ac6d9', '#3563ad',
          '#5d4596', '#a6529b', '#974d15', '#f4f5dc', '#c0c0bf', '#ffffff', '#020303'
        ]
      },
      {
        id: 22,
        title: "Vegas Golden Knights",
        img_url: "/assets/img/sports/teams/8.png",
        sport: "NFL",
        colors: [
          '#ef103a', null, '#f1e60f', null, null, '#6ac6d9', '#3563ad',
          '#5d4596', null, '#974d15', null, '#c0c0bf', null, '#020303'
        ]
      },
    ];

    // dummy data -> use of colors by all (sports) teams
    this.sportTeamUsedColorData = [
      {
        id: 0,
        title: "color1",
        color: { hex: "#e51c20", hex1: "#f6bac6" },
        percentage: 11
      },
      {
        id: 1,
        title: "color2",
        color: { hex: "#f07e14" },
        percentage: 11
      },
      {
        id: 2,
        title: "color3",
        color: { hex: "#f5eb49", hex1: "#f1d724" },
        percentage: 11
      },
      {
        id: 3,
        title: "color4",
        color: { hex: "#299741" },
        percentage: 11
      },
      {
        id: 4,
        title: "color5",
        color: { hex: "#3462ac" },
        percentage: 11
      },
      {
        id: 5,
        title: "color6",
        color: { hex: "#7c217f" },
        percentage: 11
      },
      {
        id: 6,
        title: "color7",
        color: { hex: "#654322" },
        percentage: 11
      },
      {
        id: 7,
        title: "color8",
        color: { hex: "#b9b9b8" },
        percentage: 11
      },
      {
        id: 8,
        title: "color9",
        color: { hex: "#ffffff" },
        percentage: 11
      },
      {
        id: 9,
        title: "color10",
        color: { hex: "#040506" },
        percentage: 11
      },
    ];

    // dummy data -> cross industry logo color families comparison
    const colorFamilyComparisonData = {
      percentages: [
        "8.44%",
        "8.44%",
        "8.44%",
        "8.44%",
      ],
      mostPopular: [
        {
          percentage: 40,
          title: "color1",
          color: { hex: "#db4034" }
        },
        {
          percentage: 30,
          title: "color2",
          color: { hex: "#2f52a0" }
        },
        {
          percentage: 30,
          title: "color3",
          color: { hex: "#ffffff" }
        }
      ],
      moderate: [
        {
          percentage: 55,
          title: "color4",
          color: { hex: "#020303" }
        },
        {
          percentage: 45,
          title: "color5",
          color: { hex: "#87ae27" }
        },
      ],
      infrequent: [
        {
          percentage: 55,
          title: "color7",
          color: { hex: "#efe648" }
        },
        {
          percentage: 45,
          title: "color8",
          color: { hex: "#e37a36" }
        },
      ],
      not_used: [
        {
          percentage: 55,
          title: "color10",
          color: { hex: "#8dcfda" }
        },
        {
          percentage: 45,
          title: "color11",
          color: { hex: "#f2f3db" }
        },
      ]
    };

    this.crossIndustryLogoData = [
      {
        name: "ARTS, ENTERTAINMENT & RECREATION",
        img_url: "assets/img/brands/industry/arts.png",
        colorFamilyComparisonData
      },
      {
        name: "ANIMAL HEALTH",
        img_url: "assets/img/brands/industry/animal.png",
        colorFamilyComparisonData
      },
      {
        name: "APPAREL",
        img_url: "assets/img/brands/industry/apparel.png",
        colorFamilyComparisonData
      }
    ];

    // dummy data -> top 10 industries using color in logos
    this.top10IndustriesUsingColorInLogos = [
      {
        category: "advertising",
        percentage: 60,
        img_url: "/assets/img/brands/industry/advertising.png",
      },
      {
        category: "aerospace & defense",
        percentage: 40,
        img_url: "/assets/img/brands/industry/aerospace.png",
      },
      {
        category: "agriculture",
        percentage: 80,
        img_url: "/assets/img/brands/industry/agriculture.png",
      },
      {
        category: "airlines",
        percentage: 60,
        img_url: "/assets/img/brands/industry/airlines.png",
      },
      {
        category: "animal",
        percentage: 60,
        img_url: "/assets/img/brands/industry/animal.png",
      },
      {
        category: "arts",
        percentage: 40,
        img_url: "/assets/img/brands/industry/arts.png",
      },
      {
        category: "banking",
        percentage: 80,
        img_url: "/assets/img/brands/industry/banking.png",
      },
      {
        category: "business",
        percentage: 60,
        img_url: "/assets/img/brands/industry/business.png",
      },
      {
        category: "consulting",
        percentage: 70,
        img_url: "/assets/img/brands/industry/consulting.png",
      },
      {
        category: "education",
        percentage: 90,
        img_url: "/assets/img/brands/industry/education.png",
      }
    ];

    // dummy data -> top attributed associated with (color)
    this.topAttributedAssociatedData = [
      {
        "title": "new",
        "title1": "new",
        "description": "not exist before; made, introduced, or discovered recently or now for the first time",
      },
      {
        "title": "playful",
        "title1": "play·ful",
        "description": "fond of games and amusement; lighthearted.",
      },
      {
        "title": "premium",
        "title1": "pre·mi·um",
        "description": "an amount to be paid for an insurance policy.",
      },
      {
        "title": "new",
        "title1": "new",
        "description": "not exist before; made, introduced, or discovered recently or now for the first time",
      },
      {
        "title": "playful",
        "title1": "play·ful",
        "description": "fond of games and amusement; lighthearted.",
      },
      {
        "title": "premium",
        "title1": "pre·mi·um",
        "description": "an amount to be paid for an insurance policy.",
      },
      {
        "title": "new",
        "title1": "new",
        "description": "not exist before; made, introduced, or discovered recently or now for the first time",
      },
      {
        "title": "playful",
        "title1": "play·ful",
        "description": "fond of games and amusement; lighthearted.",
      },
      {
        "title": "premium",
        "title1": "pre·mi·um",
        "description": "an amount to be paid for an insurance policy.",
      },
      {
        "title": "new",
        "title1": "new",
        "description": "not exist before; made, introduced, or discovered recently or now for the first time",
      },
    ];
  },
]);
