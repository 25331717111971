angular.module('app').directive('toggleSwitch', function () {
  function link (scope) {
    scope.toggle = function () {
      scope.toggled = !scope.toggled;
    }
  }

  return {
    restrict: 'E',
    link,
    templateUrl: 'app/directives/toggleSwitch/toggleSwitch.html',
    scope: {
      toggled: '='
    }
  }
});
