angular.module("app").component("schoolRegisterComponent", {
  templateUrl: "app/components/school-register/school-register.tmpl.html",
  controller: function (
    authService,
    $state,
    localStorageService,
    categoryValues,
    dataValidate,
    $http,
    appConfig
  ) {
    const self = this;
    self.email = "";
    self.password = "";
    self.isRemembered = false;
    self.error = "";
    self.serverError = "";
    self.country = categoryValues("country");

    self.init = function () {
      self.getSchoolPlan();
    };


    self.register = {
      contact_person_name: {
        value: "",
        required: true,
        name: "contact person name",
        type: "provide",
      },
      contact_person_email: {
        value: "",
        required: true,
        name: "contact person email",
        type: "provide"
      },
      name: {
        value: "",
        required: true,
        name: "school name",
        type: "provide",
      },
      university: {
        value: "",
        required: true,
        name: "university name",
        type: "provide",
      },
      location: {
        value: "",
        required: true,
        name: "country",
        type: "select",
      },
      subscription_level: {
        value: "",
        required: true,
        name: "subscription level",
        type: "select",
      },
      number_of_students: {
        value: "",
        required: true,
        name: "number of students",
        type: "provide",
      },
      number_of_faculties: {
        value: "",
        required: true,
        name: "number of faculties",
        type: "provide",
      },
      comment: {
        value: "",
        required: false,
        name: "comment",
        type: "provide",
      }
    };

    self.submit = () => {
      self.reg_error = false;
      if (dataValidate.validate(self.register)) {
        const data = {};

        for (item in this.register) {
          if (self.register[item].type === "select") {
            if(item != 'subscription_level' && item !== 'location') {
              data[item] = self.register[item].value.id;
            } else if(item === 'location') {
              data[item] = self.register[item].value.title;
            }
          } else {
            if(item != 'subscription_level') {
              data[item] = self.register[item].value;
            }
          }          
        }
        if (!self.reg_error) {
          $http
            .post(appConfig.dashboardServiceUrl + "api/enterprise/schools", {
              school: data,
              plan_id: self.register['subscription_level'].value.id
            })
            .then((res) => {
              for (item in this.register) {
                self.register[item].value = "";  
              }
            })
            .catch((error) => {
              if (error.status === 422) {
                self.serverError = Object.keys(error.data.errors).map((key) => {
                  return `Field ${key} ${error.data.errors[key].join(" ")}`;
                });
              }
            });
        }
      }
    };

    self.getSchoolPlan = () => {
      $http
        .get(appConfig.dashboardServiceUrl + "api/enterprise/plans")
        .then((res) => {
          self.planList = res.data.plans;
        })
        .catch((error) => {
          if (error.status === 422) {
            self.serverError = Object.keys(error.data.errors).map((key) => {
              return `Field ${key} ${error.data.errors[key].join(" ")}`;
            });
          }
        });
    }

  },
});
