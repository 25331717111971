angular.module('app').directive('topShadesColorBySchool', () => {
  let link = (scope) => {
    scope.characters = [
      "A", "Á", "B", "C", "D", "E", "É", "F", "G", "H", "I", "K",
      "L", "M", "N", "O", "Ö", "P", "Q", "R", "S", "T", "V", "X"
    ];
    scope.selectedCharacter = "ALL";
    scope.characterSelect = (value) => {
      scope.selectedCharacter = value;
      scope.filteredResults(value === 'ALL' ? 'all' : value);
    };
    scope.isSelected = (value) => {
      return scope.selectedCharacter === value;
    };

    scope.showImageDetails = false;
    scope.index = null;

    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (index) => {
      scope.imagedata = scope.colorData[index];
      scope.index = index;
      scope.toggleImageDetails();
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbTopShadesColorBySchool/dbTopShadesColorBySchoolView.html",
    link: link,
    scope: {
      colorData: "=",
      filteredResults: "="
    }
  };
});
