angular.module('app').directive('artColorComparison', () => {
  let link = (scope) => {
    scope.widthDynamic = percent => {
      if (percent == 0){
        return `calc(((100% - 87px) / 25) * 0 + 3px)`;
      } else if(percent > 0 && percent <= 1){
        return `calc(((100% - 87px) / 25) * 1 + 31px)`;
      } else if(percent > 1 && percent <= 5){
        return `calc(((100% - 87px) / 25) * 5 + 47px)`;
      } else if(percent > 5 && percent <= 10){
        return `calc(((100% - 87px) / 25) * 10 + 66px)`;
      } else if(percent > 10 && percent <= 15){
        return `calc(((100% - 87px) / 25) * 15 + 87px)`;
      } else if(percent > 15 && percent <= 20){
        return `calc(((100% - 87px) / 25) * 20 + 106px)`;
      } else if(percent > 20 && percent <= 25){
        return `calc(((100% - 87px) / 25) * 25 + 74px)`;
      } else if(percent > 25){
        return `calc(((100% - 87px) / 25) * 25 + 74px)`;
      }
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbArtColorComparison/dbArtColorComparisonView.html",
    link: link,
    scope: {
      data: "=",
    }
  };
});
