angular.module("app").directive("top10IndustriesUsingColorInLogos", () => {
  let link = (scope) => {

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbTop10IndustriesUsingColorInLogos/dbTop10IndustriesUsingColorInLogosView.html",
    link: link,
    scope: {
      data: "=",
      selectedColor: "=",
    }
  };
});
