angular.module('app').directive('infographicsForFashion', [
    "appConfig",
    "$interpolate",
    "$q",
    "$timeout",
    "authService",
    "customInfographicsConstants",
    "httpService",
  function (
      appConfig,
      $interpolate,
      $q,
      timeout,
      authService,
      customInfographicsConstants,
      httpService
    ) {
    function link(scope) {
      scope.grayList = {};
      scope.meta = {};
      scope.filter = {
        "season": {
          "id": "all",
          "title": "SEASONS",
          "region": "all",
          "serverName": "all",
          "all": true
        },
        "designer": {
          "id": "all",
          "title": "DESIGNERS",
          "region": "all",
          "serverName": "all",
          "all": true
        },
        "category": {
          "id": "all",
          "title": "CATEGORIES",
          "region": "all",
          "serverName": "all",
          "all": true
        },
        "year": {
          "id": "all",
          "title": "YEARS",
          "region": "all",
          "serverName": "all",
          "all": true
        },
        "region": {
          "id": "all",
          "title": "REGIONS",
          "region": "all",
          "serverName": "all",
          "all": true
        },
        "city": {
          "id": "all",
          "title": "CITIES",
          "region": "all",
          "serverName": "all",
          "all": true
        }
      }
      scope.isLoadingControls = true;
      scope.selectedMenuId = 1;
      scope.currentYear = moment().year();
      const cache = {
        designers: [],
        categories: [],
        regions: {},
        cities: [],
      };

      const loading = {
        ready: false,
        designersReady: $q.defer(),
        metaLoaded: $q.defer(),
        metaReady: $q.defer(),
      };

      scope.isFilterVisible = customInfographicsConstants.isFilterVisible;

      scope.alphabet = customInfographicsConstants.alphabet;
      const citiesAbbrevs = customInfographicsConstants.citiesAbbrevs;
      const regionsAbbrevs = customInfographicsConstants.regionsAbbrevs;
      const groupTitlesTemplates =
        customInfographicsConstants.groupTitlesTemplates;

      scope.prepareRequestParams = function () {
        scope.updateQueryBySelectors();
        const regCity = new RegExp("CITIES");
        const regCate = new RegExp("CATEGORIES");
        const regSeason = new RegExp("SEASONS");
        const regDesi = new RegExp("DESIGNERS");
        let city, season, category, designer;
        try {
          city = regCity.test(scope.filter.city.title) ? "all" : scope.filter.city.title;
          season = regSeason.test(scope.filter.season.title)
            ? "all"
            : scope.filter.season.title;
          category = regCate.test(scope.filter.category.title)
            ? "all"
            : scope.filter.category.title;
          designer = regDesi.test(scope.filter.designer.title)
            ? "all"
            : scope.filter.designer.title.replace(/ /g, "_");
        } catch (error) {
          city = "all";
          season = "all";
          category = "all";
          designer = "all";
        }

        return {
          city: city,
          year: scope.filter.year && scope.filter.year.id ? scope.filter.year.id : "all",
          season: season,
          category: category,
          region: scope.filter.region && scope.filter.region.id ? scope.filter.region.id : "all",
          designer: designer,
        };
      };

      scope.prepareColorsParams = function () {
        const reg = new RegExp("ALL ");
        const param = {};
        if (!reg.test(scope.filter.city.title)) {
          param.city_id = scope.filter.city.id;
        }
        if (!reg.test(scope.filter.season.title)) {
          param.season_id = scope.filter.season.id;
        }
        if (!reg.test(scope.filter.category.title)) {
          param.category_id = scope.filter.category.id;
        }
        if (!reg.test(scope.filter.designer.title)) {
          param.designer_id = scope.filter.designer.id;
        }
        param.year_id = scope.filter.year.id;
        return param;
      };

      scope.prepareColors = function () {
        const reg = new RegExp("ALL ");
        if (!reg.test(scope.filter.season.title)) {
          return { all: scope.filter.season, category: "season" };
        }
        if (!reg.test(scope.filter.city.title)) {
          return { all: scope.filter.city, category: "city" };
        }
        if (!reg.test(scope.filter.category.title)) {
          return { all: scope.filter.category, category: "category" };
        }
        if (!reg.test(scope.filter.designer.title)) {
          return { all: scope.filter.designer, category: "designer" };
        }
        return { all: { id: 2018 }, category: "year" };
      };

      loading.metaLoadedStrongLink = loading.metaLoaded;
      loading.filtersReady = $q.all([
        loading.designersReady.promise,
        loading.metaReady.promise,
      ]);

      scope.getOptionForDrop = function (option) {
        let count = 0;
        let url;
        if(option){
          if(option.qNumber === 'CO1a') {
            count = 1;
          } else if(option.qNumber === 'CO2a') {
            count = 2;
          } else if(option.qNumber === 'CO3a') {
            count = 3;
          } else if(option.qNumber === 'RE1a') {
            count = 4;
          } else if(option.qNumber === 'RE2a') {
            count = 5;
          } else if(option.qNumber === 'SE1a') {
            count = 6;
          } else if(option.qNumber === 'SE2a') {
            count = 7;
          } else if(option.qNumber === 'SE3a') {
            count = 8;
          } else if(option.qNumber === 'CA1a') {
            count = 9;
          } else if(option.qNumber === 'CA2a') {
            count = 10;
          } else if(option.qNumber === 'CA2b') {
            count = 11;
          } else if(option.qNumber === 'CA3a') {
            count = 12;
          } else if(option.qNumber === 'DE1a') {
            count = 13;
          } else if(option.qNumber === 'DE1b') {
            count = 14;
          } else if(option.qNumber === 'DE2a') {
            count = 15;
          } else if(option.qNumber === 'DE2b') {
            count = 16;
          } else if(option.qNumber === 'DE3a') {
            count = 17;
          }
        }

        url = {
          token: authService.token,
          category: scope.filter.category ? scope.filter.category.id : 'all',
          season: scope.filter.season ? scope.filter.season.id : 'all',
          designer_id: scope.filter.designer ? scope.filter.designer.id : 'all',
          city_id: scope.filter.city ? scope.filter.city.id : 'all',
          region: scope.filter.region ? scope.filter.region.id : 'all',
          year: scope.filter.year ? scope.filter.year.id === 'all' ? '' : scope.filter.year.id : ''
        }

        httpService.get(appConfig.colorsServiceUrlNewApi + 'custom_analytics/options?option='+count,{
          params: url}
        ).then(function (res) {
          if (res.data.response) {
            if(res.data.response.categories) {
              const newCat = [];
              res.data.response.categories.forEach(element => {
                newCat.push({id: element.id, title: scope.capitalizeFirstLetter(element.category)})
              });
            
              const newTitle = "categories";
              newCat.unshift({
                id: "all",
                title: newTitle.toUpperCase(),
                region: "all",
                serverName: "all",
                all: true,
              });
              scope.meta.categories = newCat;
            } 
            if(res.data.response.seasons) {
              const newSeason = [];
              res.data.response.seasons.forEach(element => {
                newSeason.push({id: element.id, title: scope.capitalizeFirstLetter(element.season)})
              });
              const newTitle = "seasons";
              newSeason.unshift({
                id: "all",
                title: newTitle.toUpperCase(),
                region: "all",
                serverName: "all",
                all: true,
              });
              scope.meta.seasons = newSeason;
            }
            if(res.data.response.cities) {
              const newCities = [];
              res.data.response.cities.forEach(element => {
                newCities.push({id: element.id, title: scope.capitalizeFirstLetter(element.name)})
              });
              const newTitle = "cities";
              newCities.unshift({
                id: "all",
                title: newTitle.toUpperCase(),
                region: "all",
                serverName: "all",
                all: true,
              });
              scope.meta.cities = newCities;
              scope.meta.city = newCities;
              angular.copy(scope.meta.cities, cache.cities);
            }
            if(res.data.response.regions) {
              const newRegions = [];
              res.data.response.regions.forEach(element => {
                newRegions.push({id: element.id, title: scope.capitalizeFirstLetter(element.region)})
              });
              const newTitle = "regions";
              newRegions.unshift({
                id: "all",
                title: newTitle.toUpperCase(),
                region: "all",
                serverName: "all",
                all: true,
              });
              scope.meta.regions = newRegions;
            }
            if(res.data.response.designers) {
              const newDesign = [];
              res.data.response.designers.forEach(element => {
                newDesign.push({id: element.id, title: scope.capitalizeFirstLetter(element.name)})
              });
              const newTitle = "designers";
              newDesign.unshift({
                id: "all",
                title: newTitle.toUpperCase(),
                region: "all",
                serverName: "all",
                all: true,
              });
              scope.meta.designers = newDesign;
              loading.designersReady.resolve();
            }
            if(res.data.response.years) {
              const newYears = [];
              res.data.response.years.reverse();
              res.data.response.years.forEach(element => {
                newYears.push({id: element, title: element})
              });
              // scope.filter.year = newYears[0];
              const newTitle = "years";
              newYears.unshift({
                id: "all",
                title: newTitle.toUpperCase(),
                region: "all",
                serverName: "all",
                all: true,
              });
              scope.meta.years = newYears;
              loading.metaLoaded.resolve();
            }
          }
          scope.isLoadingControls = false;
        });
      }

      scope.capitalizeFirstLetter = (string) => {
        if(string === 'rtw') {
          return string.toUpperCase();
        } else if(string === 'Pre Fall') {
          return 'Pre-Fall';
        } else {
          return string.charAt(0).toUpperCase() + string.slice(1);
        }
      }

      httpService.get(appConfig.colorsServiceUrlNewApi + 'colors.json?',
        authService.getHeaderConfig()
      ).then(function (res) {
        const newColor = [];
        res.data.colors.forEach(element => {
          newColor.push({id: element.id, title: scope.capitalizeFirstLetter(element.name), hex: element.hex})
        });
        scope.meta.colorGroups = newColor;
        scope.isLoadingControls = false;
      });

      scope.refresh = function () {
        scope.loadData();
      };

      scope.cityOrRegionTitle = function () {
        return scope.filter.city.all && scope.filter.region.all
          ? null
          : scope.filter.city.all
          ? scope.filter.region.title
          : scope.filter.city.title;
      };

      scope.getTitle = function (type) {
        try {
          return !scope.filter[type].all ? scope.filter[type].title : null;
        } catch (err) {
          return "";
        }
      };

      scope.getAbbrv = function (type) {
        let value;
        if (type === "category") {
          value = scope.filter.category.title;
          if (value === "Couture") {
            return "CT";
          } else if (value === "Menswear") {
            return "MW";
          }
          return value;
        } else if (type === "season") {
          value = scope.filter.season.title;
          if (value === "Fall") {
            return "FW";
          } else if (value === "Pre-Fall") {
            return "PF";
          } else if (value === "Spring") {
            return "SS";
          } else if (value === "Resort") {
            return "RS";
          } else if (value === "SEASONS") {
            return "ALL";
          }
          return value;
        } else if (type === "city") {
          value = scope.filter.city.title.replace(/\s/g, "").toLowerCase();
          return _.find(citiesAbbrevs, function (item, key) {
            return value === key.toLowerCase();
          });
        } else if (type === "region") {
          value = scope.filter.region.title.replace(/\s/g, "").toLowerCase();
          return _.find(regionsAbbrevs, function (item, key) {
            return value === key.toLowerCase();
          });
        }
      };

      scope.parseTitle = function (number) {
        let divider = "";
        let result = "";
        _.map(scope.currentChart.titleGroups[number], function (title) {
          if (scope.getTitle(title) !== null) {
            divider = "//";
          }
          if (scope.getTitle(title)) {
            result = result + " " + scope.getTitle(title);
          }
        });
        return divider + result;
      };

      scope.getOptionChartData = (option, item) => {
        let count = 0;
        let url;
        if(option){
          if(option.qNumber === 'CO1a') {
            count = 1;
          } else if(option.qNumber === 'CO2a') {
            count = 2;
          } else if(option.qNumber === 'CO3a') {
            count = 3;
          } else if(option.qNumber === 'RE1a') {
            count = 4;
          } else if(option.qNumber === 'RE2a') {
            count = 5;
          } else if(option.qNumber === 'SE1a') {
            count = 6;
          } else if(option.qNumber === 'SE2a') {
            count = 7;
          } else if(option.qNumber === 'SE3a') {
            count = 8;
          } else if(option.qNumber === 'CA1a') {
            count = 9;
          } else if(option.qNumber === 'CA2a') {
            count = 10;
          } else if(option.qNumber === 'CA2b') {
            count = 11;
          } else if(option.qNumber === 'CA3a') {
            count = 12;
          } else if(option.qNumber === 'DE1a') {
            count = 13;
          } else if(option.qNumber === 'DE1b') {
            count = 14;
          } else if(option.qNumber === 'DE2a') {
            count = 15;
          } else if(option.qNumber === 'DE2b') {
            count = 16;
          } else if(option.qNumber === 'DE3a') {
            count = 17;
          }
        }

        url = {
          token: authService.token,
          category: scope.filter.category ? scope.filter.category.id : 'all',
          season: scope.filter.season ? scope.filter.season.id : 'all',
          designer_id: scope.filter.designer ? scope.filter.designer.id : 'all',
          city_id: scope.filter.city ? scope.filter.city.id : 'all',
          region: scope.filter.region ? scope.filter.region.id : 'all',
          year: scope.filter.year ? scope.filter.year.id === 'all' ? scope.currentYear : scope.filter.year.id : scope.currentYear
        }

        return httpService.get(appConfig.colorsServiceUrlNewApi + 'custom_analytics/charts?option='+count,{
          params: url}
        ).then(function (res) {
          return res.data.result;
        });
      }

      scope.charts = [
        {
          qNumber: "CO1a",
          id: "colorsByCityPeriod",
          group: "colorsByCityPeriod",
          title: "1. Color Popularity Overview",
          chartTitle:
            "Color Popularity Overview {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              _.each(results, function (d) {
                const value = d.count;
                const par = d.percentage.replace('%','')
                d.value = value;
                d.valueTitle = d.percentage;
                d.valueTitle2 = value;
                d.title = d.name;
                d.percentage = par / 100;
              });
              return results;
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            designer: true,
            region: true,
            city: true,
          },
          titleGroups: [["designer", "category", "season", "year"], ["region", "city"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "CO2a",
          id: "colorsUniqueWithLevels",
          group: "colorsUniqueWithLevels",
          title: "2. Color Mosaic View With Popularity",
          chartTitle:
            "Color Mosaic View With Popularity {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              return {
                groups: results.colors,
                unique: results.chart,
              };
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            region: true,
            city: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
        },
        {
          qNumber: "CO3a",
          id: "trends",
          group: "trends",
          title: "3. Five Year Color Comparison",
          chartTitle:
            "Five Year Color Comparison {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              _.each(results, function (obj) {
                _.each(obj.data, function (d) {
                  const value = d.count;
                  const par = d.percentage.replace('%','')
                  d.value = value;
                  d.valueTitle = d.percentage;
                  d.valueTitle2 = value;
                  d.title = d.name;
                  d.percentage = par / 100;
                })
              });
              return results  
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            region: true,
            city: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
        },
        {
          qNumber: "RE1a",
          id: "colorsByRegionPeriodNA",
          group: "colorsByRegionPeriod",
          title: "4. Color Popularity By Region With City Breakdown",
          chartTitle:
            "Color Popularity By Region With City Breakdown {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              _.each(results.colors, function (d) {
                const value = d.count;
                const par = d.percentage.replace('%','')
                d.value = value;
                d.valueTitle = d.percentage;
                d.valueTitle2 = value;
                d.title = d.name;
                d.percentage = par / 100;
              })

              if(results.chart.length > 0){
                _.each(results.chart[0].cities, function (obj) {
                  _.each(obj.colors, function (d) {
                    const value = d.count;
                    const par = d.percentage.replace('%','')
                    d.value = value;
                    d.value2 = value;
                    d.valueTitle = d.percentage;
                    d.valueTitle2 = Number(par);
                    d.title = d.family;
                    d.color = d.hex;
                    d.percentage = par / 100;
                  })
                })
              } else {
                results.chart = [];
              }

              const param = { 
                region: {
                  name: results.chart.length > 0 ? results.chart[0].region.toLowerCase() : '',
                  cities: {
                    title: "Top 4 colors",
                    settings: {},
                    data: results.chart.length > 0 ? results.chart[0].cities : [],
                  },
                },
                charts: {
                  settings: {},
                  data: results.colors,
                }
              }
              return param
            });
          },
          filters: {
            category: true,
            region: true,
            season: true,
            year: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
          options: {},
        },
        {
          qNumber: "RE2a",
          id: "colorsPerRegions",
          group: "colorsPerRegions",
          title: "5. Cross Region Top Four Colors",
          chartTitle: "Cross Region Top Four Colors {{scope.parseTitle(0)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              _.each(results, function (d) {
                d.name = d.region.toLowerCase();
                d.title = d.region.toLowerCase();
                _.each(d.colors, function (dd) {
                  const par = dd.percentage.replace('%','')
                  dd.percentage = par / 100;
                })
                d.data = d.colors
              })
              return results
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
          },
          titleGroups: [["category", "season", "year"]],
        },
        {
          qNumber: "SE1a",
          id: "colorsUniqueGroupsCommon",
          group: "colorsUniqueGroupsCommon",
          title: "6. Color Popularity By Season",
          chartTitle:
            "Color Popularity By Season {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              _.each(results.colors, function (d) {
                const value = d.count;
                const par = d.percentage.replace('%','')
                d.value = value;
                d.title = d.name;
                d.percentage = par / 100;
              })
              _.each(results.top_4, function (d) {
                const value = d.count;
                const par = d.percentage.replace('%','')
                d.value = value;
                d.percentage = par / 100;
              })
              const param = { 
                common: results.top_4,
                groups: results.colors,
                unique: results.chart
              }
              return param
            });
          },
          apiAfter: function (model) {
            model.season = scope.filter.season.title;
            model.year = scope.filter.year.title;
          },
          filters: {
            category: true,
            region: true,
            city: true,
            season: true,
            year: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
        },
        {
          qNumber: "SE2a",
          id: "colorsUniqueByPeriodFiveYears",
          group: "colorsUniqueByPeriodFiveYears",
          title: "7. Five Year Comparison Of Seasons Colors",
          chartTitle:
            "Five Year Comparison Of Seasons Colors {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              _.each(results, function (obj) {
                obj.season = scope.filter.season.title;
              })
              return results
            });
          },
          filters: {
            category: true,
            region: true,
            city: true,
            season: true,
            year: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "SE3a",
          id: "uniqueWithGroupsPerSeason",
          group: "uniqueWithGroupsPerSeason",
          title: "8. Color Mosaic View By Season With Popularity",
          chartTitle:
            "Color Mosaic View By Season With Popularity {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            category: true,
            year: true,
            region: true,
            city: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
        },
        {
          qNumber: "CA1a",
          id: "colorsByCategoryPeriod",
          group: "colorsByCategoryPeriod",
          title: "9. Color Popularity By Category",
          chartTitle:
            "Color Popularity By Category {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            region: true,
            city: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
        },
        {
          qNumber: "CA2a",
          id: "colorsByCategory",
          group: "colorsGridNails",
          title: "10. Cross Category Color Popularity",
          chartTitle:
            "Cross Category Color Popularity {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            season: true,
            year: true,
            region: true,
            city: true,
          },
          titleGroups: [["season", "year"], ["region", "city"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "CA3a",
          id: "colorsByRtwCategory",
          group: "colorsGridNails2",
          title: "11. Three Year Comparison Of Color Popularity",
          chartTitle:
            "Three Year Comparison Of Color Popularity {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            region: true,
            city: true,
          },
          titleGroups: [["category", "season", "year"], ["region"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "DE1a",
          id: "colorsGroupsCommon",
          group: "colorsGroupsCommon",
          title: "12. Color Popularity By Designer",
          chartTitle:
            "Color Popularity By Designer {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            designer: true,
          },
          titleGroups: [["designer"], ["category", "season", "year"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "DE1b",
          id: "colorsGroupsCommon2",
          group: "colorsGroupsCommon2",
          title: "13. Expanded Color Popularity By Designer",
          chartTitle:
            "Expanded Color Popularity By Designer {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            designer: true,
          },
          titleGroups: [["designer"], ["category", "season", "year"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "DE2a",
          id: "top4forDesigner",
          group: "top4forDesigner",
          title: "14. Two Year Comparison of Color Popularity By Designer",
          chartTitle:
            "Two Year Comparison of Color Popularity By Designer {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          apiAfter: function (model) {
            model.city = scope.filter.city.title;
          },
          filters: {
            category: true,
            season: true,
            year: true,
            designer: true,
          },
          titleGroups: [["designer"], ["category", "season", "year"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "DE2b",
          id: "top4forDesigner2",
          group: "top4forDesigner2",
          title: "15. Expanded Two Year Comparison of Color Popularity By Designer",
          chartTitle:
            "Expanded Two Year Comparison of Color Popularity By Designer {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          apiAfter: function (model) {
            model.city = scope.filter.city.title;
          },
          filters: {
            category: true,
            season: true,
            year: true,
            designer: true,
          },
          titleGroups: [["designer"], ["category", "season", "year"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "DE3a",
          id: "top4Grid",
          group: "top4Grid",
          title: "16. Designers Top Four Colors",
          chartTitle:
            "Designers Top Four Colors {{scope.parseTitle(0)}} {{scope.parseTitle(1)}}",
          api: function () {
            return scope.getOptionChartData(scope.currentChart, scope.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
          },
          titleGroups: [["category", "season", "year"]],
        },
      ];

      scope.currentChart = scope.charts[0];

      scope.$watch(
          "[currentChart, filter]",
        (newValue, oldValue) => {
          if (newValue) {
            scope.loadData();
          }
        },
        true
      );

      if (!loading.isMetaLoadedSetup) {
        loading.metaLoaded.promise.then(function () {
          loading.metaReady.resolve();
        });
        loading.isMetaLoadedSetup = true;
      }
        
      scope.loadData = (newV, oldV) => {
        loading.currentRequestId = Math.random();
        if (newV && oldV) {
          if (newV[0].qNumber !== oldV[0].qNumber) {
            scope.filter.designer = scope.meta.designers && scope.meta.designers[0];
            if (newV[0].qNumber === "RE1a" || newV[0].qNumber === "CI1a") {
              scope.meta.regions = _.filter(scope.meta.regions, function (item) {
                return !item.all;
              });
              scope.meta.colorGroups = _.filter(scope.meta.colorGroups, function (
                item
              ) {
                return !item.all;
              });
              scope.filter.region = scope.meta.regions[0];
              scope.filter.color = scope.meta.colorGroups[0];
            } else if (!_.find(scope.meta.regions, "all")) {
              scope.meta.regions.unshift({
                id: "all",
                title: "REGIONS",
                region: "all",
                serverName: "all",
                all: true,
              });
              scope.filter.region = scope.meta.regions[0];
            }
          }
        }

        loading.filtersReady.then(function () {
          scope.model = null;

          (function (currentRequestId) {
            scope.currentChart.api().then((result) => {
              if (currentRequestId !== loading.currentRequestId || !result) {
                return;
              }
              scope.model = result;
              if (scope.currentChart.apiAfter) {
                scope.currentChart.apiAfter(scope.model, result);
              }
              scope.title = scope.prepareTitle(scope.currentChart.chartTitle);
            });
          })(loading.currentRequestId);
        });
      }

      scope.prepareTitle = (title) => {
        if (!title) {
          return "";
        }
        const exp = $interpolate(title);
        return exp(scope);
      }

      scope.labelToGray = function (selector, title) {
        let result = true;

        if (title.indexOf("ALL ") === -1) {
          _.forEach(scope.grayList[selector], function (item) {
            if (item.title === title) {
              result = false;
            }
          });
        }
        return result;
      };

      scope.isFilterItems = function (selector, title) {
        let result = true;
        if (scope.grayList[selector] === undefined) {
          result = false;
        } else if (
          selector === "years" &&
          scope.filter.year.title === "ALL YEARS" &&
          (scope.currentChart.qNumber === "CO3a" ||
          scope.currentChart.qNumber === "SE2a" ||
          scope.currentChart.qNumber === "SE2b" ||
          scope.currentChart.qNumber === "CA3a" ||
          scope.currentChart.qNumber === "CA3b" ||
          scope.currentChart.qNumber === "DE2a" ||
          scope.currentChart.qNumber === "DE2b")
        ) {
          result = false;
        } else if (
          selector === "regions" &&
          (scope.currentChart.qNumber === "RE1a" ||
          scope.currentChart.qNumber === "CI1a" ||
          scope.currentChart.qNumber === "DE3a")
        ) {
          result = false;
        } else if (
          scope.grayList === {} ||
          title.toString().indexOf("ALL ") === -1
        ) {
          _.forEach(scope.grayList[selector], function (item) {
            if (item.title === title.toString()) {
              result = false;
            }
          });
        } else {
          result = false;
        }
        return result;
      };

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
        result += name.toUpperCase() + ".";

        return result + date + type;
      };

      scope.exportJpg = function (name) {
        const captureEl = angular.element("#capture");
        const footer = angular.element(".customized-infographics-footer");
        const titles = angular.element(".graphic-titles");
        const header = angular.element(".graphic-titles-export");
        let newWidth;

        if (scope.currentChart.qNumber === "CA1a") {
          $("#custom-chart").removeClass("chart");
        }

        if (captureEl.width() < 1135) {
          newWidth = "calc(100% - 15px)";
        } else {
          newWidth = "100%";
        }

        footer.css({ display: "block", width: newWidth });
        header.css({ display: "block", width: newWidth });
        titles.css({ display: "none" });
        captureEl.css({ "padding-top": "30px", "margin-top": "-30px" });
        const captureElHeight = captureEl.height();
        const captureElWidth = captureEl.width();
        $("html").addClass("hide-scrollbar");

        timeout(function () {
          html2canvas(captureEl[0], {
            height: captureElHeight + 20,
            width: captureElWidth,
            allowTaint: true,
            logging: true,
          }).then(function (canvas) {
            const img = canvas.toDataURL("image/jpeg");
            download(img, scope.generateImgName(".jpg", "fashion", name), "image/jpg");
            $("html").removeClass("hide-scrollbar");
            captureEl.css({ "padding-top": "0", "margin-top": "0" });
            footer.css({ display: "none", width: "100%" });
            header.css({ display: "none", width: "100%" });
            titles.css({ display: "block" });
          });
        }, 50);
      };

      scope.updateQueryBySelectors = () => {
          scope.title = scope.prepareTitle(scope.currentChart.chartTitle);
          scope.getOptionForDrop(scope.currentChart);
          scope.isLoadingControls = true;
      };

      scope.updateQueryBySelectors();

      scope.updateQueryBySelectorsMain = function (chart) {
          scope.currentChart = chart;
          scope.filter = {
            "season": {
              "id": "all",
              "title": "SEASONS",
              "region": "all",
              "serverName": "all",
              "all": true
            },
            "designer": {
              "id": "all",
              "title": "DESIGNERS",
              "region": "all",
              "serverName": "all",
              "all": true
            },
            "category": {
              "id": "all",
              "title": "CATEGORIES",
              "region": "all",
              "serverName": "all",
              "all": true
            },
            "year": {
              "id": "all",
              "title": "YEARS",
              "region": "all",
              "serverName": "all",
              "all": true
            },
            "region": {
              "id": "all",
              "title": "REGIONS",
              "region": "all",
              "serverName": "all",
              "all": true
            },
            "city": {
              "id": "all",
              "title": "CITIES",
              "region": "all",
              "serverName": "all",
              "all": true
            }
          }
          scope.title = scope.prepareTitle(scope.currentChart.chartTitle);
          scope.getOptionForDrop(scope.currentChart);
          scope.isLoadingControls = true;
      };
    }

    return {
      restrict: 'E',
      templateUrl:
        'app/components/customized-infographics/infographicsForFashion/infographicsForFashion.html',
      link: link,
      scope: {
      }
    };
  }
]);

app.filter('propsFilter', function() {
  return function(items, props) {
    var out = [];
    if (angular.isArray(items)) {
      items.forEach(function(item) {
        var keys = Object.keys(props);
        var prop = keys[0];
        var text = props[prop].toLowerCase();
        if (item[prop].toString().toLowerCase().indexOf(text) === 0) {
          out.push(item);
        }
      });
    } else {
      out = items;
    }
    return out;
  };
});