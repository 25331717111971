angular.module('app').directive('topColorsUseBySchool', () => {
  let link = (scope) => {
    scope.$watch('data', function (data) {
      if (data) {
        scope.filteredChoices.forEach(element => {
          if(element.title === scope.data.selected_school.school) {
            scope.data.selected_school.img_url = element.img_url;
          }
          scope.data.other_schools.forEach(obj => {
            if(element.title === obj.school) {
              obj.img_url = element.img_url;
            }
          })
        });
        scope.selectedSchool = scope.data.selected_school;
        scope.otherSchools = scope.data.other_schools;
      }
    });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbTopColorsUseBySchool/dbTopColorsUseBySchoolView.html",
    link: link,
    scope: {
      data: "=",
      selectedItem: "=",
      filteredChoices: "="
    }
  };
});
