angular.module('app').directive('artColorCombination', () => {
  let link = (scope) => {

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/artColorCombination/artColorCombinationView.html",
    link: link,
    scope: {
      data: "=",
      viewMode: "=",
    }
  };
});
