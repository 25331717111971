angular.module("app").directive("natureContent", [
  "$timeout",
  "$window",
  (timeout, window) => {
    let link = (scope, element) => {
      scope.data = [];
      scope.showCollection = false;
      scope.showImageDetails = false;
      scope.saveImageDetails = false;
      scope.imageDetailsData = null;
      window.scr = scope;
      scope.collectionDataList = [];

      scope.itemHeight = () => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        return (scope.elemHeight =
          elements[0].clientWidth && elements[0].clientWidth + "px");
      };

      scope.itemHeight();

      $(window).resize(() => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        timeout(() => {
          return (scope.elemHeight = elements[0].clientWidth + "px");
        }, 10);
      });

      scope.$on("mood_state", (data) => {
        scope.saveImageDetailsData = data.targetScope.data;
        scope.toggleImageDetails();
        scope.toggleSaveImageDetails();
      });

      scope.toggleSaveImageDetails = () => {
        scope.saveImageDetails = scope.saveImageDetails ? false : true;
      };

      scope.$on("mood_state_changed", () => {
        scope.toggleSaveImageDetails();
      });

      scope.toggleImageDetails = () => {
        scope.showImageDetails = !scope.showImageDetails;
      };

      scope.imageClickHandler = (index) => {
        scope.index = index;
        scope.toggleImageDetails();
      };

      scope.$watch('flowerContentData', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          scope.flowerContentData = newValue;
        }
      }, true);

      // dummy data -> color flower content
      // scope.flowerContentData = [
      //   {
      //     id: 0,
      //     name: "glossy abelia",
      //     img_url: "/assets/img/nature/flowers/1.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 1,
      //     name: "glossy abelia 1",
      //     img_url: "/assets/img/nature/flowers/2.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 2,
      //     name: "glossy abelia 2",
      //     img_url: "/assets/img/nature/flowers/3.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 3,
      //     name: "glossy abelia 3",
      //     img_url: "/assets/img/nature/flowers/4.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 4,
      //     name: "glossy abelia 4",
      //     img_url: "/assets/img/nature/flowers/5.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 5,
      //     name: "glossy abelia 5",
      //     img_url: "/assets/img/nature/flowers/6.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 6,
      //     name: "glossy abelia 6",
      //     img_url: "/assets/img/nature/flowers/6.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 7,
      //     name: "glossy abelia 7",
      //     img_url: "/assets/img/nature/flowers/6.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 8,
      //     name: "glossy abelia 8",
      //     img_url: "/assets/img/nature/flowers/1.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 9,
      //     name: "glossy abelia 9",
      //     img_url: "/assets/img/nature/flowers/2.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 10,
      //     name: "glossy abelia 10",
      //     img_url: "/assets/img/nature/flowers/3.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 11,
      //     name: "glossy abelia 11",
      //     img_url: "/assets/img/nature/flowers/4.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 12,
      //     name: "glossy abelia 12",
      //     img_url: "/assets/img/nature/flowers/5.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 13,
      //     name: "glossy abelia 13",
      //     img_url: "/assets/img/nature/flowers/6.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 14,
      //     name: "glossy abelia 14",
      //     img_url: "/assets/img/nature/flowers/6.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   },
      //   {
      //     id: 15,
      //     name: "glossy abelia 15",
      //     img_url: "/assets/img/nature/flowers/6.png",
      //     family: "caprifoliaceae",
      //     lifespan: "short"
      //   }
      // ];

      // dummy data -> color bird content
      scope.birdContentData = [
        {
          id: 0,
          name: "pink-headed fruit dove 1",
          img_url: "/assets/img/nature/birds/1.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 1,
          name: "pink-headed fruit dove 2",
          img_url: "/assets/img/nature/birds/2.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 2,
          name: "pink-headed fruit dove 3",
          img_url: "/assets/img/nature/birds/3.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 3,
          name: "pink-headed fruit dove 4",
          img_url: "/assets/img/nature/birds/4.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 4,
          name: "pink-headed fruit dove 5",
          img_url: "/assets/img/nature/birds/5.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 5,
          name: "pink-headed fruit dove 6",
          img_url: "/assets/img/nature/birds/6.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 6,
          name: "pink-headed fruit dove 7",
          img_url: "/assets/img/nature/birds/7.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 7,
          name: "pink-headed fruit dove 8",
          img_url: "/assets/img/nature/birds/8.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 8,
          name: "pink-headed fruit dove 9",
          img_url: "/assets/img/nature/birds/1.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 9,
          name: "pink-headed fruit dove 10",
          img_url: "/assets/img/nature/birds/2.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 10,
          name: "pink-headed fruit dove 11",
          img_url: "/assets/img/nature/birds/3.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 11,
          name: "pink-headed fruit dove 12",
          img_url: "/assets/img/nature/birds/4.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 12,
          name: "pink-headed fruit dove 13",
          img_url: "/assets/img/nature/birds/5.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 13,
          name: "pink-headed fruit dove 14",
          img_url: "/assets/img/nature/birds/6.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 14,
          name: "pink-headed fruit dove 15",
          img_url: "/assets/img/nature/birds/7.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        },
        {
          id: 15,
          name: "pink-headed fruit dove 16",
          img_url: "/assets/img/nature/birds/8.png",
          family: "columbidae",
          size: "small",
          lifespan: "1-10 years"
        }
      ];

      scope.isFlowerNatureMode = () => {
        return scope.natureMode.title === "flowers";
      };

      scope.isBirdNatureMode = () => {
        return scope.natureMode.title === "birds";
      };

      scope.isButterflyNatureMode = () => {
        return scope.natureMode.title === "butterflies";
      };

      scope.isEarthNatureMode = () => {
        return scope.natureMode.title === "earth";
      };

    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbNatureContent/dbNatureContentView.html",
      link: link,
      scope: {
        originalData: "=data",
        natureMode: "=",
        selectedItem: "=",
        flowerContentData: "=",
      },
    };
  },
]);
