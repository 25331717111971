angular.module('app').directive('colorTrademarksCountries', () => {
  let link = (scope) => {
    scope.unseletedImgUrl = "/assets/img/legals/items/unselected.png";

    scope.selectedTrademarks = [];

    scope.selectTrademark = (index) => {
      if (!scope.isSelected(index)) {
        scope.selectedTrademarks.push(index);
      } else {
        const selected = scope.selectedTrademarks.filter(item => item !== index);
        scope.selectedTrademarks = [...selected];
      }
    };

    scope.isSelected = (index) => {
      return scope.selectedTrademarks.indexOf(index) !== -1;
    };

    scope.$watch("countriesData",
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          var myInterval = setInterval(scope.selectTrademark(scope.selectedColor.id - 1), 5000);
          clearInterval(myInterval);
        }
      });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorTrademarksCountries/dbColorTrademarksCountriesView.html",
    link: link,
    scope: {
      countriesData: "=",
      selectedColor: "=",
      colors: "="
    }
  };
});
