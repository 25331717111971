angular.module("app").component("membershipComponent", {
  templateUrl: "app/components/membership/membership.tmpl.html",
  controller: function (
    $stateParams,
    $state,
    scrollService,
    categoryValues,
    $http,
    appConfig,
    modalService,
    dataValidate
  ) {
    scrollService.scrollMember();
    const self = this;
    this.jobs = categoryValues("job function");
    this.countries = categoryValues("country");
    this.industries = categoryValues("industry");
    this.companySizes = categoryValues("company size");

    this.data = {
      first_name: {
        value: "",
        required: true,
        name: "first name",
        type: "provide",
      },
      last_name: {
        value: "",
        required: true,
        name: "last name",
        type: "provide",
      },
      email: { value: "", required: true, name: "email", type: "provide" },
      company: {
        value: "",
        required: true,
        name: "company name",
        type: "provide",
      },
      title: {
        value: "",
        required: true,
        name: "job title",
        type: "provide",
      },
      // job_function: {value: self.jobs[0], required: true, name: 'job function', type: 'select'},
      company_size: {
        value: self.companySizes[0],
        required: true,
        name: "company size",
        type: "select",
      },
      industry: {
        value: self.industries[0],
        required: true,
        name: "industry",
        type: "select",
      },
    };

    this.submitInquiry = () => {
      if (dataValidate.validate(this.data)) {
        const data = {};
        for (const item in this.data) {
          if (item !== "permissions") {
            if (this.data[item].type === "select") {
              data[item] = this.data[item].value.title;
            } else {
              data[item] = this.data[item].value;
            }
          } else if (item === "permissions") {
            data.permissions = [];
            _.forEach(this.data[item], function (i, k) {
              if (i === true) {
                data.permissions.push(categoryValues("permissions")[k]);
              }
            });
            data.permissions = JSON.stringify(data.permissions);
          }
        }
        $http
          .post(appConfig.dashboardServiceUrl + "api/forms/membership.json", {
            contact: data,
          })
          .then((res) => {
            if (res.status === 201) {
              $state.go("thank-you");
            }
          });
      }
    };
  },
});
