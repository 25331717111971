angular.module('app').directive('homecolordata', ["homeColorsService", "$timeout", (homeColorsService, timeout) => {
  let link = (scope) => {
    scope.isModeRoom = () => { return scope.viewMode === 'category'; };
    scope.isModeProduct = () => { return scope.viewMode === 'subCategory'; };
    scope.isModeColor = () => { return scope.viewMode === 'color'; };
    scope.isModeStyle = () => { return scope.viewMode === 'style'; };

    scope.selectedItem = scope.item;
    scope.categoryUseOfColor = []; 

    // color chartData4 - sorting by category

    scope.selectedColorCategoryLetter = "ALL";

    scope.categoriesColorCharacterSelect = (value, type) => {
      if(type) {
        scope.selectedColorCategoryLetter = value.id;
      } else {
        scope.selectedColorCategoryLetter = value;
      }
      scope.colorCategoryColorData = [];
      scope.currentPage = 1;
      scope.mainColorCategoryColorData = [];
      homeColorsService
        .getColorChartsData4ByColorId(scope.selectedItem.id, 'chartData4', scope.selectedColorCategoryLetter)
        .then(function (data) {
          scope.isLoadingControls = false; 
          scope.mainColorCategoryColorData = data.chartData4;
          scope.colorCategoryColorData = scope.mainColorCategoryColorData.slice(0, 120);
          if(scope.mainColorCategoryColorData.length > 120) {
            scope.isLoadMoreData = true;
          } else {
            scope.isLoadMoreData = false;
          }
        });
    };

    scope.isCategoriesColorSelected = (value, type) => {
      if(type) {
        return scope.selectedColorCategoryLetter === value.id;
      } else {
        return scope.selectedColorCategoryLetter === value;
      }
    };

    // color chartData3 - sorting by category

    scope.selectedColorSubCategoryLetter = "ALL";

    scope.subCategoriesColorCharacterSelect = (value, type) => {
      if(type) {
        scope.selectedColorSubCategoryLetter = value.id;
      } else {
        scope.selectedColorSubCategoryLetter = value;
      }
      scope.colorTrademarkColorData = [];
      homeColorsService
        .getColorChartsData3ByColorId(scope.selectedItem.id, 'chartData3', scope.selectedColorSubCategoryLetter)
        .then(function (data) {
          scope.isLoadingControls = false; 
          scope.colorTrademarkColorData = data.chartData3;
        });
    };

    scope.isSubCategoriesColorSelected = (value, type) => {
      if(type) {
        return scope.selectedColorSubCategoryLetter === value.id;
      } else {
        return scope.selectedColorSubCategoryLetter === value;
      }
    };

    // // style chartData4 - sorting by category
    scope.colorsCount = 22;
    scope.selectedColorStyleLetter = 0;

    scope.styleColorCharacterSelect = (value, type) => {
      if(type) {
        scope.selectedColorStyleLetter = value.id;
      }
      scope.styleCategoryColorData = [];
      homeColorsService
        .getStyleChartsDataLetterByStyle(scope.selectedItem.title, 'chartData5', scope.selectedColorStyleLetter)
        .then(function (data) {
          scope.styleCategoryColorData = data.chartData5;
          scope.isLoadingControls = false; 
        });
    };

    scope.isStyleColorSelected = (value, type) => {
      if(type) {
        return scope.selectedColorStyleLetter === value.id;
      }
    };

    if (scope.viewMode === "category") {
      scope.isLoadingControls = true;
      scope.topTenColorLetter = 'ALL';
    
      homeColorsService
        .getCategoriesChartsDataByCategory(scope.selectedItem.id, 'header')
        .then(function (data) {
          scope.categoryHeaderData = data.header;
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getCategoriesChartsDataByCategory(scope.selectedItem.id, 'chartData1')
        .then(function (data) {
          scope.chartData1ColorData = data.chartData1;
          scope.chartData1ColorData.forEach(element => {
            if (element.name === 'blackwhite') {
              element.displayTitle = 'black/ white';
            } else if (element.name === 'multicolor') {
              element.displayTitle = 'multi- color';
            } else {
              element.displayTitle = element.name;
            }
          });
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getCategoriesChartsDataByCategory(scope.selectedItem.id, 'chartData2')
        .then(function (data) {
          scope.chartData2ColorData = data.chartData2;
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getCategoriesChartsData3ByCategory(scope.selectedItem.id, 'chartData3', scope.topTenColorLetter)
        .then(function (data) {
          scope.categoryUseOfColor = data.chartData3;
          scope.isLoadingControls = false; 
        });
    }

    if (scope.viewMode === "color") {
      scope.isLoadingControls = true;
      scope.currentPage = 1;
      scope.selectedColorCategoryLetter = 'ALL';
      scope.colorTrademarkColorData = [];
      scope.mainColorCategoryColorData = [];
      scope.colorCategoryColorData = [];
    
      homeColorsService
        .getColorChartsDataByColorId(scope.selectedItem.id, 'header')
        .then(function (data) {
          scope.colorHeaderData = data.header;
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData1')
        .then(function (data) {
          scope.chartData1SearchByColor = data.chartData1;
          scope.chartData1SearchByColor.other_colors.forEach(element => {
            if (element.name === 'blackwhite') {
              element.displayTitle = 'black/ white';
            } else if (element.name === 'multicolor') {
              element.displayTitle = 'multi- color';
            } else {
              element.displayTitle = element.name;
            }
          });
          scope.chartData1SearchByColor.selected_color.forEach(element => {
            if (element.name === 'blackwhite') {
              element.displayTitle = 'black/ white';
            } else if (element.name === 'multicolor') {
              element.displayTitle = 'multi- color';
            } else {
              element.displayTitle = element.name;
            }
          });
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData2')
        .then(function (data) {
          scope.chartData2SearchByColor = data.chartData2;
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getColorChartsData3ByColorId(scope.selectedItem.id, 'chartData3', scope.selectedColorSubCategoryLetter)
        .then(function (data) {
          scope.colorTrademarkColorData = data.chartData3;
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getColorChartsData4ByColorId(scope.selectedItem.id, 'chartData4', scope.selectedColorCategoryLetter)
        .then(function (data) {
          scope.isLoadingControls = false; 
          scope.mainColorCategoryColorData = data.chartData4;
          scope.colorCategoryColorData = scope.mainColorCategoryColorData.slice(0, 120);
          if(scope.mainColorCategoryColorData.length > 120) {
            scope.isLoadMoreData = true;
          } else {
            scope.isLoadMoreData = false;
          }
        });
    }

    if (scope.viewMode === "subCategory") {
      scope.isLoadingControls = true;
      scope.topFourColorLetter = 'ALL';
    
      homeColorsService
        .getSubCategoriesChartsDataBySubCategory(scope.selectedItem.title, 'header')
        .then(function (data) {
          scope.subCategoryHeaderData = data.header;
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getSubCategoriesChartsDataBySubCategory(scope.selectedItem.title, 'chartData1')
        .then(function (data) {
          scope.chartDataSubCateColorData = data.chartData1;
          scope.chartDataSubCateColorData.forEach(element => {
            if (element.name === 'blackwhite') {
              element.displayTitle = 'black/ white';
            } else if (element.name === 'multicolor') {
              element.displayTitle = 'multi- color';
            } else {
              element.displayTitle = element.name;
            }
          });
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getSubCategoriesChartsDataBySubCategory(scope.selectedItem.title, 'chartData2')
        .then(function (data) {
          scope.colorFrequencySubCategoryData = data.chartData2;
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getSubCategoriesChartsDataLetterBySubCategory(scope.selectedItem.title, 'chartData3', scope.topFourColorLetter)
        .then(function (data) {
          scope.categoryUseOfColor = data.chartData3;
          scope.isLoadingControls = false; 
        });
    }

    if (scope.viewMode === "style") {
      scope.isLoadingControls = true;
      scope.selectedColorStyleLetter = 0;
      scope.styleTrademarkColorData = [];
      scope.styleCategoryColorData = [];
      scope.selectedItem.hex = '#ffffff';
    
      homeColorsService
        .getStyleChartsDataByStyle(scope.selectedItem.title, 'header')
        .then(function (data) {
          scope.styleHeaderData = data.header;
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getStyleChartsDataByStyle(scope.selectedItem.title, 'chartData1')
        .then(function (data) {
          scope.chartDataStyleCateColorData = data.chartData1;
          scope.chartDataStyleCateColorData.forEach(element => {
            if (element.name === 'blackwhite') {
              element.displayTitle = 'black/ white';
            } else if (element.name === 'multicolor') {
              element.displayTitle = 'multi- color';
            } else {
              element.displayTitle = element.name;
            }
          });
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getStyleChartsDataByStyle(scope.selectedItem.title, 'chartData2')
        .then(function (data) {
          scope.colorFrequencyStyleData = data.chartData2;
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getStyleChartsDataByStyle(scope.selectedItem.title, 'chartData3')
        .then(function (data) {
          scope.chartDataStyleColorData = data.chartData3;
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getStyleChartsDataByStyle(scope.selectedItem.title, 'chartData4')
        .then(function (data) {
          scope.styleTrademarkColorData = data.chartData4;
          scope.isLoadingControls = false; 
        });

      homeColorsService
        .getStyleChartsDataLetterByStyle(scope.selectedItem.title, 'chartData5', scope.selectedColorStyleLetter)
        .then(function (data) {
          scope.styleCategoryColorData = data.chartData5;
          scope.isLoadingControls = false; 
        });
    }

    scope.filteredResults = function (item) {
      scope.topTenColorLetter = item;
      scope.topFourColorLetter = item;
      if (scope.viewMode === "category") {
        homeColorsService
        .getCategoriesChartsData3ByCategory(scope.selectedItem.id, 'chartData3', scope.topTenColorLetter)
        .then(function (data) {
          scope.categoryUseOfColor = data.chartData3;
          scope.isLoadingControls = false; 
        });
      } else if (scope.viewMode === "subCategory") {
        homeColorsService
        .getSubCategoriesChartsDataLetterBySubCategory(scope.selectedItem.title, 'chartData3', scope.topFourColorLetter)
        .then(function (data) {
          scope.categoryUseOfColor = data.chartData3;
          scope.isLoadingControls = false; 
        });
      }
      


    };

    scope.loadMoreData = () => {
      scope.currentPage += 1;
      let count = scope.currentPage * 120;
      if(scope.mainColorCategoryColorData.length > count) {
        scope.isLoadMoreData = true;
      } else {
        scope.isLoadMoreData = false;
      }
      scope.colorCategoryColorData = scope.mainColorCategoryColorData.slice(0, count);
    }

    scope.homeChartPopupAttributes = {
      modalTitle: ""
    };

    scope.toggleHomeChartPopup = (needShow, type) => {
      if (needShow) {
        if(type === 'chart1'){
          scope.homeChartPopupAttributes.modalTitle = 'SELECTED ' + scope.selectedItem.family + ' COLOR COMPARED WITH ALL OTHER COLOR FAMILIES';
          scope.typeOfPopup = type;
        } else if(type === 'chart2'){
          scope.homeChartPopupAttributes.modalTitle = 'USE OF ' + scope.selectedItem.family + ' ACROSS HOME CATEGORIES';
          scope.typeOfPopup = type;
        } else if(type === 'chart3'){
          scope.homeChartPopupAttributes.modalTitle = 'USE OF COLOR FAMILIES ACROSS ALL ' + scope.selectedItem.title + ' PRODUCTS';
          scope.typeOfPopup = type;
        } else if(type === 'chart4'){
          scope.homeChartPopupAttributes.modalTitle = 'COMPARISON OF CATEGORIES COLOR FREQUENCY FOR THE ' + scope.selectedItem.title + ' PRODUCT';
          scope.typeOfPopup = type;
        } else if(type === 'chart5'){
          scope.homeChartPopupAttributes.modalTitle = 'USE OF COLOR FAMILIES ACROSS ALL ' + scope.selectedItem.title + ' PRODUCTS';
          scope.typeOfPopup = type;
        } else if(type === 'chart6'){
          scope.homeChartPopupAttributes.modalTitle = 'COMPARISON OF CATEGORIES COLOR FREQUENCY FOR THE ' + scope.selectedItem.title + ' PRODUCT';
          scope.typeOfPopup = type;
        } else if(type === 'chart7'){
          scope.homeChartPopupAttributes.modalTitle =  'USE OF COLOR FAMILIES ACROSS ALL ' + scope.selectedItem.title + ' STYLE PRODUCTS';
          scope.typeOfPopup = type;
        } else if(type === 'chart8'){
          scope.homeChartPopupAttributes.modalTitle =  scope.selectedItem.title + ' STYLE VERSUS ALL OTHER STYLES USE OF COLOR';
          scope.typeOfPopup = type;
        } else if(type === 'chart9'){
          scope.homeChartPopupAttributes.modalTitle =  'USE OF COLOR BY ' + scope.selectedItem.title + ' STYLE PRODUCTS ACROSS ALL CATEGORIES';
          scope.typeOfPopup = type;
        }
        scope.showHomeChartPopup = true;
      } else {
        scope.showHomeChartPopup = false;
      }
    };

    scope.$on("mood_state_chart", (data) => {
      let newParam = {}
      
      let node = angular.element("#homePopupCapture")[0];
      domtoimage
      .toPng(node, {
        bgcolor: "#fff",
      })
      .then((dataUrl) => {
        if(dataUrl) {
          if(_.isEmpty(newParam)) {
              scope.saveImageDetailsData = {
                base64: dataUrl,
                type: 'home'
              };
            } else {
              scope.saveImageDetailsData = {
                base64: dataUrl,
                type: 'home',
                data: newParam
              };
            }
          }
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });

        let times = 2000;
        timeout(function () {
          if(scope.saveImageDetailsData) {
            scope.showHomeChartPopup = false;
            scope.toggleSaveImageDetails();
          } else {
            timeout(function () {
              if(scope.saveImageDetailsData) {
                scope.showHomeChartPopup = false;
                scope.toggleSaveImageDetails();
              }
            }, times);
          }
        }, times);
    });

    scope.generateImgName = function (type, board, name) {
      let result = "";
      const date = moment().format("L");
      result += name.toUpperCase() + ".";
      return result + date + type;
    };

    scope.toggleSaveImageDetails = () => {
      scope.saveImageDetails = scope.saveImageDetails ? false : true;
    };

    scope.$on("mood_state_chart_changed", () => {
      scope.toggleSaveImageDetails();
    });

    scope.$on("mood_state_item_chart_changed", () => {
      scope.toggleSaveImageDetails();
    });
  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/homeColorData/homeColorDataView.html',
    link: link,
    scope: {
      colorsCount: '=',
      stylesCount: '=',
      item: "=",
      productsCount: '=',
      topColors:'=',
      colorFrequency:'=',
      frequencyByCategory:'=',
      infographics:'=',
      viewMode:'=',        // category | subCategory | color | style
      pageTitle:'=',
      categoryList: '=',
    }
  }
}]);
