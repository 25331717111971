angular.module("app").directive("popularcolorsgraph", function () {
  function link(scope) {
    scope.$watch("colors", bindData);

    function bindData() {
      if (scope.mode === "auto") {
        scope.colorData = scope.colors;
      } else {
        scope.colorData = [
          { hex: "#ffffff", percentage: 30 },
          { hex: "#808080", percentage: 10 },
          { hex: "#000000", percentage: 30 },
          { hex: "#ff0000", percentage: 10 },
          { hex: "#0000ff", percentage: 10 },
          { hex: "#008000", percentage: 10 },
          { hex: "#ffa500", percentage: 20 },
        ];
      }

      scope.needAssignLongClass = function (isEven) {
        if (scope.sameSizeScales) return false;
        return scope.smallScaleLineFirst ? !isEven : isEven;
      };

      scope.needAssignShortClass = function (isEven) {
        if (scope.sameSizeScales) return true;
        return scope.smallScaleLineFirst ? isEven : !isEven;
      };
    }
  }

  return {
    restrict: "E",
    templateUrl: "app/directives/popularColorsGraph/popularColorsGraph.html",
    link: link,
    scope: {
      colors: "=",
      mainColor: "=",
      showLabels: "=",
      optionWidth: "=",
      sameSizeScales: "=",
      needTopBorder: "=",
      optionHeightSize: "=", // big,
      smallScaleLineFirst: "=",
      mode: "=",
    },
  };
});
