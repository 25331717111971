angular.module('app').directive('colorfrequencyline', function () {
  function link (scope) {
  }
  return {
    restrict: 'E',
    templateUrl: 'app/directives/colorFrequencyLine/colorFrequencyLine.html',
    link: link,
    scope: {
      colors:'='
    }
  };
});
