angular.module("app").directive("fashionYearSeasonPieChart", function () {
  function link(scope) {
    scope.$watch("selectedItem", bindData);

    function bindData() {
      const element = document.getElementById(`my_fashion_year_season_pie_chart_${scope.mode}_dataviz`);
      if (element) {
        element.innerHTML = "";
      }

      const width = 160;
      const height = 160;
      const margin = 0;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      const svg = d3
        .select(`#my_fashion_year_season_pie_chart_${scope.mode}_dataviz`)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      // Create dummy data
      const data = {
        "color1": scope.chartData.percentage.replace('%', ''),
        "color2": 100 - scope.chartData.percentage.replace('%', ''),
      };

      const colorData = {
        "color1": { hex: "#000" },
        "color2": { hex: "#fff" },
      };

      // Compute the position of each group on the pie:
      const pie = d3.layout
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value;
        });
      const data_ready = pie(d3.entries(data));

      const data2 = {
        "color": 100,
      };

      const data_ready2 = pie(d3.entries(data2));

      // The arc generator
      const arc = d3.svg
        .arc()
        .innerRadius(radius * 0.91) // This is the size of the donut hole
        .outerRadius(radius * 0.98);

      const arc3 = d3.svg
        .arc()
        .innerRadius(radius * 0.99)
        .outerRadius(radius * 0.98);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", function (d) {
          return colorData[d.data.key].hex;
        })
        .attr(
          "stroke", function (d) {
            return colorData[d.data.key].hex === "#ffffff"
              ? "#E0E0DD"
              : colorData[d.data.key].hex;
          }
        )
        .style(
          "stroke-width",  function (d) {
            return colorData[d.data.key].hex === "#ffffff"
              ? "1px"
              : "0px"
          }
        );

      svg
        .selectAll("allSlices")
        .data(data_ready2)
        .enter()
        .append("path")
        .attr("d", arc3)
        .attr("fill", function (d) {
          return "#000";
        });

      svg
        .append("text")
        //.text(`${scope.chartData.percentage}%`)
        .text(`${scope.chartData.percentage}`)
        .attr("transform", "translate(0, 10)")
        .attr("font-size", "24px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B");
    }
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/dbFashionYearSeasonPieChart/dbFashionYearSeasonPieChartView.html",
    link: link,
    scope: {
      mode: "=",
      chartData: "=",
    },
  };
});
