angular.module("app").directive("pricingQuestionItem", () => {
  let link = (scope) => {
    scope.showAnswer = false;
    scope.toggleShowAnswer = () => {
      scope.showAnswer = !scope.showAnswer;
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/pricingQuestionItem/pricingQuestionItemView.html",
    link: link,
    scope: {
      question: "=",
      answer: "=",
    }
  };
});
