angular.module('app').directive('topShadesColorByArtist', ["$timeout", (timeout) => {
  let link = (scope) => {
    scope.characters = [
      "A", "Á", "B", "C", "D", "E", "É", "F", "G", "H", "I", "K",
      "L", "M", "N", "O", "Ö", "P", "Q", "R", "S", "T", "V", "X"
    ];
    scope.selectedCharacter = "ALL";
    scope.saveItemImageDetails = false;
    scope.characterSelect = (value) => {
      scope.selectedCharacter = value;
      scope.filteredResults(value === 'ALL' ? 'all' : value);
    };
    scope.isSelected = (value) => {
      return scope.selectedCharacter === value;
    };

    scope.showImageDetails = false;
    scope.index = null;

    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (index) => {
      scope.imagedata = scope.colorData[index];
      scope.index = index;
      scope.toggleImageDetails();
    };

    scope.$on("mood_state_item_chart", (data) => {
      let mainData = data.targetScope.data;
      const keys = ["ARTIST", "PERIOD", "SCHOOL", "YEAR"];
      let newParam = {
        [keys[0]]: mainData.artist,
        [keys[1]]: mainData.period,
        [keys[2]]: mainData.school,
        [keys[3]]: mainData.born_died,
      };

      let node = angular.element("#artItemPopupCapture")[0];
      domtoimage
        .toPng(node, {
          bgcolor: "#fff",
        })
        .then((dataUrl) => {
          if(dataUrl) {
            scope.saveImageDetailsData = {
              base64: dataUrl,
              type: 'art',
              data: newParam
            };
          }
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });

        let times = 2000;
        timeout(function () {
          if(scope.saveImageDetailsData) {
            scope.showImageDetails = false;
            scope.toggleSaveImageDetails();
          } else {
            timeout(function () {
              if(scope.saveImageDetailsData) {
                scope.showImageDetails = false;
                scope.toggleSaveImageDetails();
              } else {
                timeout(function () {
                  if(scope.saveImageDetailsData) {
                    scope.showImageDetails = false;
                    scope.toggleSaveImageDetails();
                  }
                }, times);
              }
            }, times);
          }
        }, times);
    });

    scope.generateImgName = function (type, board, name) {
      let result = "";
      const date = moment().format("L");
      result += name.toUpperCase() + ".";
      return result + date + type;
    };

    scope.toggleSaveImageDetails = () => {
      scope.saveItemImageDetails = !scope.saveItemImageDetails;
    };

    scope.$on("mood_state_item_chart_changed", () => {
      scope.toggleSaveImageDetails();
    });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbTopShadesColorByArtist/dbTopShadesColorByArtistView.html",
    link: link,
    scope: {
      colorData: "=",
      filteredResults: "="
    }
  };
}]);
