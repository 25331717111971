angular.module("app").component("studentStaffSignUpComponent", {
  templateUrl: "app/components/student-staff-sign-up/student-staff-sign-up.tmpl.html",
  controller: function (
    authService,
    $state,
    localStorageService,
    categoryValues,
    dataValidate,
    $http,
    appConfig,
    modalService
  ) {
    const self = this;
    self.email = "";
    self.password = "";
    self.isRemembered = false;
    self.error = "";
    self.serverError = "";

    self.init = function () {
      self.getSchools();
    };


    self.signup = {
      user_type: { value: "student", required: true, name: "user type" },
      school_id: {
        value: "",
        required: true,
        name: "school name",
        type: "select",
      },
      first_name: {
        value: "",
        required: true,
        name: "first name",
        type: "provide",
      },
      last_name: {
        value: "",
        required: true,
        name: "last name",
        type: "provide",
      },
      card_id: {
        value: "",
        required: true,
        name: "card id",
        type: "provide",
      },
      email: { value: "", required: true, name: "email", type: "provide" },
      password: {
        value: "",
        required: true,
        name: "password",
        type: "provide",
      },
      password_confirmation: {
        value: "",
        required: true,
        name: "Confirm Password",
        type: "provide",
      },
    };

    self.register = () => {
      self.reg_error = false;
      if (self.validate(self.signup)) {
        if (
          self.signup.password.value !==
          self.signup.password_confirmation.value
        ) {
          self.signup.password_confirmation.$error = true;
          self.signup.password_confirmation.$error_msg =
            "Confirm Password must be same as Password";
          self.signup.password_confirmation.value = "";
          self.reg_error = true;
          return;
        }

        const data = {};

        for (item in this.signup) {
          if (self.signup[item].type === "select") {
            data[item] = self.signup[item].value.id;
          } else {
            data[item] = self.signup[item].value;
          }          
        }
        if (!self.reg_error) {
          $http
            .post(appConfig.dashboardServiceUrl + "api/enterprise/users", {
              user: data,
            })
            .then((res) => {
              $state.go("login");
            })
            .catch((error) => {
              if (error.status === 422) {
                self.serverError = Object.keys(error.data.errors).map((key) => {
                  return `Field ${key} ${error.data.errors[key].join(" ")}`;
                });
              }
            });
        }
      }

      
    };

    self.getSchools = () => {
      $http
          .get(appConfig.dashboardServiceUrl + "api/enterprise/schools")
          .then((res) => {
            self.schoolList = res.data.schools;
          })
          .catch((error) => {
            if (error.status === 422) {
              self.serverError = Object.keys(error.data.errors).map((key) => {
                return `Field ${key} ${error.data.errors[key].join(" ")}`;
              });
            }
          });
    }

    self.validate = function (data) {
      var validDomain = [
        'aol.com', 'att.net', 'comcast.net', 'facebook.com', 'gmail.com', 'gmx.com', 'googlemail.com',
        'google.com', 'hotmail.com', 'hotmail.co.uk', 'mac.com', 'me.com', 'mail.com', 'msn.com',
        'live.com', 'sbcglobal.net', 'verizon.net', 'yahoo.com', 'yahoo.co.uk',
        'email.com', 'fastmail.fm', 'games.com', 'gmx.net', 'hush.com', 'hushmail.com', 'icloud.com',
        'iname.com', 'inbox.com', 'lavabit.com', 'love.com', 'outlook.com', 'pobox.com', 'protonmail.com',
        'rocketmail.com', 'safe-mail.net', 'wow.com', 'ygm.com', 'ymail.com', 'zoho.com', 'yandex.com', 'bellsouth.net',
        'charter.net', 'cox.net', 'earthlink.net', 'juno.com',
        'btinternet.com', 'virginmedia.com', 'blueyonder.co.uk', 'freeserve.co.uk', 'live.co.uk',
        'ntlworld.com', 'o2.co.uk', 'orange.net', 'sky.com', 'talktalk.co.uk', 'tiscali.co.uk',
        'virgin.net', 'wanadoo.co.uk', 'bt.com',
        'sina.com', 'qq.com', 'naver.com', 'hanmail.net', 'daum.net', 'nate.com', 'yahoo.co.jp', 'yahoo.co.kr',
        'yahoo.co.id', 'yahoo.co.in', 'yahoo.com.sg', 'yahoo.com.ph',
        'hotmail.fr', 'live.fr', 'laposte.net', 'yahoo.fr', 'wanadoo.fr', 'orange.fr', 'gmx.fr', 'sfr.fr', 'neuf.fr',
        'free.fr', 'gmx.de', 'hotmail.de', 'live.de', 'online.de', 't-online.de', 'web.de', 'yahoo.de',
        'libero.it', 'virgilio.it', 'hotmail.it', 'aol.it', 'tiscali.it', 'alice.it', 'live.it', 'yahoo.it', 'email.it',
        'tin.it', 'poste.it', 'teletu.it', 'mail.ru', 'rambler.ru', 'yandex.ru', 'ya.ru', 'list.ru',
        'hotmail.be', 'live.be', 'skynet.be', 'voo.be', 'tvcablenet.be', 'telenet.be',
        'hotmail.com.ar', 'live.com.ar', 'yahoo.com.ar', 'fibertel.com.ar', 'speedy.com.ar', 'arnet.com.ar',
        'yahoo.com.mx', 'live.com.mx', 'hotmail.es', 'hotmail.com.mx', 'prodigy.net.mx',
        'yahoo.com.br', 'hotmail.com.br', 'outlook.com.br', 'uol.com.br', 'bol.com.br', 'terra.com.br', 'ig.com.br',
        'itelefonica.com.br', 'r7.com', 'zipmail.com.br', 'globo.com', 'globomail.com', 'oi.com.br'
      ];
      var errorMessage = [];
      var types = {
        provide: '*Please provide Your ',
        select: '*Please select ',
        enter: '*Please enter Your ',
        numeric: '*Must be number: ',
        both: '*Please enter or select Your '
      };
  
      for (var item in data) {
        if (data[item].required) {
          if (data[item].value === '' || !data[item].value || (data[item].type === 'select' && data[item].value.id === null) ||
            (data[item].type === 'both' && (data[item].value === '' || data[item].value === 'STATE/PROVINCE <span class="red-text">*</span>'))) {
            var type = types[data[item].type] || '*Please enter ';
            errorMessage.push(type + data[item].name);
        } else if (data[item].name.includes('email')) {
          if (data[item].value.indexOf('@') !== -1 && data[item].value.indexOf('.') !== -1 &&
            data[item].value.indexOf('@') < data[item].value.lastIndexOf('.')) {
            _.forEach(validDomain, function (domain) {
              if (data[item].value.toLowerCase().includes(domain)) {
                errorMessage.push('*Please enter Your work-related email');
                return false;
              }
            });
          } else {
            errorMessage.push('*Please enter Your work-related email');
          }
          } else if (data[item].type === 'numeric') {
            if (!(!isNaN(parseFloat(data[item].value)) && isFinite(data[item].value))) {
              errorMessage.push(types.numeric + data[item].name);
            }
          }
        }
      }
      if (errorMessage.length !== 0) {
        modalService.showModal(0, null, errorMessage);
        return false;
      }
      return true;
    };
  },
});
