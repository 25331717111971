angular.module("app").directive("sportColorsAcrossTeams", () => {
  let link = (scope) => {
    scope.menus = [
      { id: 0, title: "ALL" },
      { id: 1, title: "BASEBALL" },
      { id: 2, title: "BASKETBALL" },
      { id: 3, title: "FOOTBALL" },
      { id: 4, title: "HOCKEY" },
      { id: 5, title: "SOCCER" },
      { id: 6, title: "LACROSSE" },
      { id: 7, title: "RUGBY" },
    ];

    scope.selectedMenuId = 0;
    scope.setMenu = (id) => scope.selectedMenuId = id;
    scope.isSelectedMenu = (id) => id === scope.selectedMenuId;

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbSportColorsAcrossTeams/dbSportColorsAcrossTeamsView.html",
    link: link,
    scope: {
      selectedColor: "=",
      data: "=",
      mode: "=",
    }
  };
});
