angular.module("app").directive("globalPeriodSelection", [
  "dateTimeFormatter",
  (dateTimeFormatter) => {
    let link = (scope) => {
      scope.date_from = null;
      scope.date_to = null;

      scope.selectedPeriodIndex = 3;
      scope.periodSelect = (index) => {
        scope.selectedPeriodIndex = index;
        // if (scope.periods[index].title === 'custom') {
        //   scope.customDateChange();
        // }
        // else {
          scope.setPeriod(scope.periods[index].title);
          scope.date_from = null;
          scope.date_to = null;
        // }
      };
      // dummy data
      scope.periods = [
        { title: "1d" },
        { title: "3d" },
        { title: "5d" },
        { title: "1m" },
        { title: "3m" },
        { title: "6m" },
        { title: "ytd" },
        { title: "1y" },
        { title: "max" },
        // { title: "custom" },
      ];

      scope.setPeriod(scope.periods[3].title);

      // scope.customDateChange = () => {
      //   scope.selectedPeriodIndex = scope.periods.findIndex(period => period.title === 'custom');
      //   if (scope.date_from && scope.date_to && scope.periods[scope.selectedPeriodIndex ].title === 'custom') {
      //     scope.setCustomDate(
      //       dateTimeFormatter.formatDateWithDash(scope.date_from),
      //       dateTimeFormatter.formatDateWithDash(scope.date_to));
      //   }
      // };
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbGlobalColorData/globalColorTracker/globalPeriodSelection/globalPeriodSelectionView.html",
      link: link,
      scope: {
        setPeriod: "=",
        setCustomDate: "=",
      }
    };
  }
]);
