(function () {
  angular.module("app").directive("fashionColorAcrossSeasonChart", [
    function () {
      function link(scope, element) {
        scope.data.map(obj => {
          if(obj.season === 'pre_fall'){
            obj.season = 'pre-fall'
          }
        });

        scope.heightDynamic = percent => {
          if (percent == 0){
            return 0
          } else if(percent > 0 && percent <= 10){
            return 20;
          } else if(percent > 10 && percent <= 20){
            return 40;
          } else if(percent > 20 && percent <= 30){
            return 60;
          } else if(percent > 30 && percent <= 40){
            return 80;
          } else if(percent > 40){
            return 80;
          }
        }
        var customMedia = window.matchMedia("(min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1)");
        chartBase(self);

        function bindData() {
          const container = element.find("[chart-type]").html("");
          const width = $(container[0]).width();
          const dx = (width - 70) / scope.data.length;
          const svg = d3
            .select(container[0])
            .append("svg")
            .attr("width", "100%")
            .attr("height", "500");

          const g = svg
            .append("g")
            .attr("transform", "translate(" + -10 + "," + 520 + ")");

          // draw bottom line
          g.append("line")
            .attr("x1", 60)
            .attr("x2", width)
            .attr("y1", -100)
            .attr("y2", -100)
            .style({ stroke: "#000000", "stroke-width": 1 });

          // curve line generator
          const lineFunction = d3.svg.line()
            .x(function(d) { return d.x; })
            .y(function(d) { return d.y; })
            .interpolate('basis');

          for (let i = 0; i < scope.data.length; i++) {
            g.append("text")
              .attr("text-anchor", "middle")
              .attr("dominant-baseline", "central")
              .attr("x", 70)
              .attr("y", -80)
              .attr("transform", self.formatTranslate(i * dx + dx / 2, 0))
              .text(scope.data[i].season.toUpperCase())
              .style({ "font-family": "Gotham SSm A, Gotham SSm B", "font-size": "16px" });

            const lineData = [
              { x: 70 + i * dx + 30, y: -130 },
              { x: 70 + i * dx + 35, y: -135 },
              { x: 70 + i * dx + 40, y: -140 },
              { x: 70 + (i + 1 / 2) * dx, y: -140 - 570 * scope.heightDynamic(scope.data[i].percentage.slice(0,-1)) / 100 },
              { x: 70 + (i + 1) * dx - 40 , y: -140 },
              { x: 70 + (i + 1) * dx - 35 , y: -135 },
              { x: 70 + (i + 1) * dx - 30 , y: -130 },
            ];

            g.append("path")
              .attr("d", lineFunction(lineData))
              .attr("stroke", scope.selectedColor.hex)
              .attr("stroke-width", 2)
              .attr("fill", "none")
              .attr("transform", "translate(0,0)");

            g.append("line")
              .attr("x1", 70 + (i + 1 / 2) * dx)
              .attr("x2", 70 + (i + 1 / 2) * dx)
              .attr("y1", -170 - 400 * scope.heightDynamic(scope.data[i].percentage.slice(0,-1)) / 100)
              .attr("y2", -150 - 400 * scope.heightDynamic(scope.data[i].percentage.slice(0,-1)) / 100)
              .style({ stroke: "#000000", "stroke-width": 1 });

            g.append("line")
              .attr("x1", 70 + (i + 1 / 2) * dx - 20)
              .attr("x2", 70 + (i + 1 / 2) * dx + 20)
              .attr("y1", -170 - 400 * scope.heightDynamic(scope.data[i].percentage.slice(0,-1)) / 100)
              .attr("y2", -170 - 400 * scope.heightDynamic(scope.data[i].percentage.slice(0,-1)) / 100)
              .style({ stroke: "#000000", "stroke-width": 1 });

            g.append("text")
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "central")
            .attr("x", 70)
            .attr("y", -190 - 400 * scope.heightDynamic(scope.data[i].percentage.slice(0,-1)) / 100)
            .attr("transform", self.formatTranslate(i * dx + dx / 2, 0))
            .text(`${scope.data[i].percentage}`)
            .style({ "font-family": "Gotham SSm A, Gotham SSm B", "font-size": "18px" });
          }
        }

        $(window).resize(() => {
          bindData();
        });

        scope.$watch("selectedColor", bindData);
        scope.$watch("data", bindData);
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          selectedColor: "=",
          data: "="
        },
        templateUrl:
          "app/directives/dbFashionColorAcrossSeasons/dbFashionColorAcrossSeasonsView.html",
      };

      return directive;
    },
  ]);
})();
