angular.module("app").directive("volumeResearchStudiedColorChart", () => {
  let link = (scope) => {
    scope.$watch('data', function (params) {
      if(params){
        scope.bindData();
      }
    });

    scope.bindData = () => {
      scope.chartData = Object.assign({}, scope.data.data);
      scope.chartData.color = scope.selectedColor;
      scope.averageData = scope.data.averageData;
      if(scope.averageData.length) {
        scope.averageData = [...new Set(scope.averageData)]; // removes duplicate values from the averageData array
      }
      scope.allChartData = [];
      scope.allChartData.push(scope.chartData);
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbVolumeResearchStudiedColorData/dbVolumeResearchStudiedColorDataView.html",
    link: link,
    scope: {
      data: "=",
      selectedColor: "=",
      height: "=",
      graphHeight: "=",
    }
  };
});
