angular.module('app').directive('colorCombinationsInIndustryLogos', () => {
  let link = (scope) => {
    scope.maxPercentage = 1;

    scope.$watch('colorData', function (newVal, prevVal) {
      if(newVal !== prevVal) {
        scope.colorData = newVal;
        scope.data = [];
      }
      bindData();
    });

    scope.heightDynamic = percent => {
      if (percent == 0){
        return 0
      } else if(percent > 0 && percent <= 1){
        return 4;
      } else if(percent > 1 && percent <= 5){
        return 20;
      } else if(percent > 5 && percent <= 10){
        return 40;
      } else if(percent > 10 && percent <= 15){
        return 60;
      } else if(percent > 15 && percent <= 20){
        return 80;
      } else if(percent > 20 && percent <= 25){
        return 100;
      } else if(percent > 25){
        return 100;
      }
    }

    function bindData() {
      scope.data = scope.colorData.result.filter((item) => {
        return item.hex !== scope.colorData.match_color.hex;
      });

      scope.selectedItem = scope.colorData.match_color;
    }

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorCombinationsInIndustryLogos/dbColorCombinationsInIndustryLogosView.html",
    link: link,
    scope: {
      colorData: "=",
      selectedColor: "=",
      mode: "=",
    }
  };
});
