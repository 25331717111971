angular
.module('app')
.component('reportsDetailsComponent', {
  templateUrl: 'app/components/reports-details/reports-details.tmpl.html',
  controller: function ($http, appConfig, $stateParams, $location, anchorSmoothScroll, localStorageService, $state, cartService, reportService) {
    const vm = this;

    vm.init = function () {
      reportService.getReportDetails($stateParams.id).then(function (res) {
        if (res && res.data) {
          vm.pageData = res.data;
          vm.pageData.date = res.data.published_date;
          vm.pageData.image_url =  res.data.image_url && res.data.image_url;
          vm.pageData.price = res.data.price ? res.data.price : 0
        }
      });
    };

    vm.more = function () {
      vm.pageData.analitic = _.chunk(angular.copy(vm.pageData.analitics), 3);
    };

    vm.gotoElement = function (eID) {
      $location.hash('prefooter');
      anchorSmoothScroll.scrollTo(eID);
      $location.hash('');
    };

    vm.downloadExcerpt = function () {
      $state.go('download-excerpt', {type: 'reports', id: vm.pageData.id});
      localStorageService.set('link', vm.pageData.excerpts[0].url);
    };

    vm.addProduct = function () {
      const params = {
        user_cart: { 
          cartable_id: vm.pageData.id,
          description: vm.pageData.header,
          cartable_type: "Report",
          quantity: 1
        } 
      }

      cartService.createCart(params).then(function (res) {
        if(res.status === 200) {
          $state.go('cart-page', {wayBack: 'reports'});
        }
      });
    };

    vm.getUser = function () {
      return localStorageService.get('currentUser').id === undefined;
    };
  }
});
