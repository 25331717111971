angular.module("app").directive("countryLogoColorComparison", () => {
  let link = (scope) => {
    scope.$watch("selectedItem", function (selectedItem) {
      scope.selectedData = scope.data.find(item => item.name.toLowerCase() === selectedItem.title.toLowerCase());
      scope.otherData = scope.data.filter(item => item.name.toLowerCase() !== selectedItem.title.toLowerCase());
    });

    scope.$watch("mode", function (mode) {
      if (mode === 'industry') {
        scope.selectedLabel = 'INDUSTRY';
        scope.otherLabel = 'INDUSTRIES';
      } else if (mode === 'country') {
        scope.selectedLabel = 'COUNTRY';
        scope.otherLabel = 'COUNTRIES';
      }
    });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbCountryLogoColorComparison/dbCountryLogoColorComparisonView.html",
    link: link,
    scope: {
      selectedItem: "=",
      data: "=",
      mode: "=",
    }
  };
});
