angular.module("app").directive("brandActualColorNames", () => {
  let link = (scope) => { 
   
    scope.loadMoreDatas = () => {
      scope.loadMoreData();
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbBrandActualColorNames/dbBrandActualColorNamesView.html",
    link: link,
    scope: {
      data: "=", 
      loadMoreData: "=",
      isLoadedAllData: "=",
      azMode:"="
    }
  };
});
