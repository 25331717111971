angular
  .module("app")
  .directive("saveImageInBoard", function (
    moodboardService
  ) {
    let link = (scope) => {
      scope.$watch('data', function (data) {
        if (data) {
          scope.allData = scope.data;
          bindData();
        }
      });

      function bindData() {
        scope.board = '';
        scope.board_name = '';
        if(scope.allData) {
          getListOfBoard(scope.allData.type)
        }
      }

      function getListOfBoard(param) {
        moodboardService.getListOfMoodboard(param).then((data) => {
          scope.listData = data.data
        });
      }

      scope.closeClickHandler = () => {
        scope.onClose();
      };

      scope.updateBoard = function (item) {
        scope.board = item;
      };

      scope.createmoodboard = () => {
        if(scope.mode === 'chart') {
          if(scope.board || scope.board_name) {  
            if(scope.board) {
              const param = { 
                chart_image: scope.allData.base64,
              }
              if(scope.allData.data) {
                param.details = scope.allData.data
              }
              moodboardService.existingMoodboardChart(scope.board.id, param).then((data) => {
                if(scope.allData.data) {
                  scope.$emit("mood_state_item_chart_changed", data);
                  scope.$broadcast("mood_state_item_chart_changed", data);
                } else {
                  scope.$emit("mood_state_chart_changed", data);
                  scope.$broadcast("mood_state_chart_changed", data);
                }
              });
            } else if(scope.board_name) {
              const param = {
                title: scope.board_name,
                moodboard_type: scope.allData.type,
                moodboard_charts_attributes: { 
                  "0": { 
                    chart_image: scope.allData.base64
                  }
                }
              }
              if(scope.allData.data) {
                param.moodboard_charts_attributes = {
                  "0": { 
                    chart_image: scope.allData.base64,
                    details: scope.allData.data
                  }
                }
              }
              moodboardService.createMoodboard(param).then((data) => {
                if(scope.allData.data) {
                  scope.$emit("mood_state_item_chart_changed", data);
                  scope.$broadcast("mood_state_item_chart_changed", data);
                } else {
                  scope.$emit("mood_state_chart_changed", data);
                  scope.$broadcast("mood_state_chart_changed", data);
                }
              });
            }
          }
        } else {
        if(scope.board || scope.board_name) {
          scope.main_type = ''
          if (scope.allData.type === 'fashion'){
            scope.main_type = 'Fashion::Photo';
          } else if (scope.allData.type === 'brand'){
            scope.main_type = 'Brand::Company';
          } else if (scope.allData.type === 'auto'){
            scope.main_type = 'Auto::ColorModel';
          } else if (scope.allData.type === 'art'){
            scope.main_type = 'Art::Picture';
          } else if (scope.allData.type === 'legal'){
            scope.main_type = 'Legal::ColorTrademark';
          } else if (scope.allData.type === 'pharma'){
            scope.main_type = 'Pharma::Pill';
          } else if (scope.allData.type === 'home'){
            scope.main_type = 'Home::Product';
          } else if (scope.allData.type === 'nature'){
            scope.main_type = 'Nature::Flower';
          } else if (scope.allData.type === 'video'){
            scope.main_type = 'video';
          } else if (scope.allData.type === 'beauty'){
            scope.main_type = 'Beauty::Fragnances::Perfume';
          }

          if(scope.board) {
            const param = { 
              itemable_type: scope.main_type,
              itemable_id: scope.allData.id,
              item_type: scope.allData.type
            }
            moodboardService.existingMoodboard(scope.board.id, param).then((data) => {
              scope.$emit("mood_state_changed", data);
              scope.$broadcast("mood_state_changed", data);
            });
          } else if(scope.board_name) {
            const param = {
              title: scope.board_name,
              moodboard_type: scope.allData.type,
              items_attributes: { 
                "0": { 
                  item_type: scope.allData.type,
                  itemable_type: scope.main_type,
                  itemable_id: scope.allData.id 
                }
              }
            }
            moodboardService.createMoodboard(param).then((data) => {
              scope.$emit("mood_state_changed", data);
              scope.$broadcast("mood_state_changed", data);
            });
          }
        }
        }
      };
    };

    return {
      restrict: "E",
      templateUrl:
        "app/directives/dbSaveImageInBoard/dbSaveImageInBoardView.html",
      link: link,
      scope: {
        data: "=",
        onClose: "&",
        mode: "="
      },
    };
  });
