(function () {
  angular.module("app").directive("hueCarIndustryUseOfColorChart", [
    function () {
      function link(scope) {
        scope.$watch("data", (newValue, oldValue) => {
          if (newValue) {
            bindData();
          }
        });

        function bindData() {
          const parent = document.getElementById("my_canvas_chart_container");
          while (parent && parent.firstChild) {
            parent.removeChild(parent.firstChild);
          }
          const canvas = document.createElement("canvas");

          if (parent) {
            parent.appendChild(canvas);
            canvas.setAttribute("id", "canvas");
            canvas.setAttribute("width", "100%");
          }

          const ctx = canvas.getContext("2d");
          let labels = [];
          if (scope.data && scope.data.length) {
            labels = scope.data[0].items.slice(-5).map((el) => el[0]);
        
            const datasets = scope.data.map((el) => {
              const isColorLight = () => {
                const color =
                  el.color.color.hex === "#FFFFFF"
                    ? "#010101"
                    : el.color.color.hex === "#F5F5DC"
                    ? "#030303"
                    : el.color.color.hex;

                const offset =
                  color === "#010101"
                    ? [20, 3]
                    : color === "#030303"
                    ? [10, 5]
                    : [];

                return { color, offset };
              };
              return {
                label: "",
                fill: false,
                lineTension: 0.3,
                backgroundColor: el.color.color.hex,
                borderColor: isColorLight().color,
                borderCapStyle: "butt",
                borderDash: isColorLight().offset,
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: isColorLight().color,
                pointBackgroundColor: "#dbdbdb",
                pointBorderWidth: 1,
                pointHoverRadius: 8,
                pointHoverBackgroundColor: isColorLight().color,
                pointHoverBorderColor: isColorLight().color,
                pointHoverBorderWidth: 2,
                pointRadius: 8,
                pointHitRadius: 10,
                data: el.items.slice(-5).map((el) => el[1]),
                spanGaps: false,
              };
            });

            const data = {
              labels: labels,
              datasets: datasets,
            };

            window.myLineChart = new Chart(ctx, {
              type: "line",
              data: data,
              options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  display: false,
                },
                legendCallback: function (chart) {
                  const height = ($("#canvas").height() - 46) / 14 + "px";
                  const items = [];
                  items.push(
                    '<ul class="' +
                      chart.id +
                      '-legend" style="padding: 0; margin: 0">'
                  );
                  for (let i = 0; i < scope.data.length; i++) {
                    items.push(
                      '<li id="legend-' +
                        i +
                        '-item" class="legend-item" style="background-color:' +
                        scope.data[i].color.color.hex +
                        "; height:" +
                        height +
                        '" onclick="updateDataset(event, ' +
                        "'" +
                        i +
                        "'" +
                        ')">'
                    );
                    if (scope.data[i].color.color.hex === "#FFFFFF") {
                      items.push("<p class='light-item' id='white'>- - -</p>");
                    } else if (scope.data[i].color.color.hex === "#F5F5DC") {
                      items.push("<p class='light-item' id='beige'>---</p>");
                    }
                    items.push("</li>");
                  }
                  items.push("</ul>");
                  return items.join("");
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        fontSize: 16,
                        padding: 5,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      ticks: {
                        fontSize: 16,
                        padding: 5,
                      },
                    },
                  ],
                },
              },
            });

            updateDataset = function (e, index) {
              const ci = e.view.myLineChart;
              const meta = ci.getDatasetMeta(index);

              if (meta.hidden === null) {
                meta.hidden = !ci.data.datasets[index].hidden;
                if (index === "0") {
                  $("#beige").css("opacity", "0.6");
                } else if (index === "11") {
                  $("#white").css("opacity", "0.6");
                } else {
                  $("#" + e.path[0].id).css("opacity", "0.6");
                }
              } else {
                meta.hidden = null;
                if (index === "0") {
                  $("#beige").css("opacity", "1");
                } else if (index === "11") {
                  $("#white").css("opacity", "1");
                } else {
                  $("#" + e.path[0].id).css("opacity", "1");
                }
              }

              ci.update();
            };
          }
        }
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          data: "=",
          mode: "=",
        },
        templateUrl:
          "app/directives/customInfographicsAutoDirectives/carIndustryUseOfColorChart/carIndustryUseOfColorChart.html",
      };

      return directive;
    },
  ]);
})();
