angular.module("app").directive("fashionShowImages", [
  "$timeout",
  "dashboardRepository",
  "brandingDashboardRepository",
  "modalService",
  "localStorageService",
  (timeout, dashboardRepository, brandingDashboardRepository, modalService, localStorageService) => { 
    let link = (scope) => {  

      scope.$on('updateYear', function(evt, data) { 
        scope.selectedYear = data; 
        scope.currentPageForFashion = 0;
        scope.paramsForNewApi.page = 1; 
        scope.paramsForNewApi.year_eq = scope.selectedYear.id; 
        scope.loadData();
      });
      scope.selectedItem = scope.item;
      scope.viewMode = scope.viewMode;
      scope.searchId = scope.searchId;    
      scope.data = []; 
      scope.collectionData = [];
      scope.showCollection = false;
      scope.showImageDetails = false;
      scope.saveImageDetails = false;
      scope.collectionLoading = false;
      scope.imageDetailsData = null;
      window.scr = scope;
      scope.currentPageForColection = 0;
      scope.page_limit = 35;
      scope.pageCountForCollection = 1;
      scope.currentPageForFashion = 0;
      scope.page_limit1 = 12;
      scope.pageCountForFashoin = 1;
      scope.collectionDataList = [];
      
      scope.$watch('selectedYear', function (newValue) {
        if (newValue) {
          var currentyear = scope.selectedYear.id;

          if (scope.viewMode === 'color') {  
            scope.paramsForNewApi = {
              //color_eq : scope.searchId, 
              photos_color_id_eq : scope.searchId, 
              page: scope.currentPageForFashion + 1,
              year_eq: currentyear,
            };  
          }
          if (scope.viewMode === 'category') {   
            scope.paramsForNewApi = {
              category_eq: scope.searchId, 
              page: scope.currentPageForFashion + 1,
              year_eq: currentyear,
            };  
          }
          if (scope.viewMode === 'season') {  
            scope.paramsForNewApi = {
              season_eq: scope.searchId, 
              page: scope.currentPageForFashion + 1,
              year_eq: currentyear,
            };  
          }
          if (scope.viewMode === 'region') {  
            scope.paramsForNewApi = {
              city_id_eq: scope.searchId, 
              page: scope.currentPageForFashion + 1,
              year_eq: currentyear,
            }; 
          }
          if (scope.viewMode === 'designer') {  
            scope.paramsForNewApi = {
              designer_id_eq: scope.searchId, 
              page: scope.currentPageForFashion + 1,
              year_eq: currentyear,
            }; 
          }
          if (scope.viewMode === 'year') {  
            scope.paramsForNewApi = {
              year_eq: scope.searchId, 
              page: scope.currentPageForFashion + 1,
            }; 
          }

          scope.loadData();
        }
      });
      
      

      if(localStorageService.get("currentUser")){
        scope.user = localStorageService.get("currentUser");
      }

      scope.loadData = () => {  
        dashboardRepository
          .getFashionCollections(scope.paramsForNewApi)
          .then((data) => {
            if(data.limit === 'exceed'){
              modalService.showModal(11, null, scope.user.first_name);
            } else {
              scope.data = data;
              scope.pageCountForFashoin = scope.data.paginate.total_pages;
            } 
          });  
      };

      scope.getCollectionData = () => {
        dashboardRepository
          .getCollectionData(
            scope.collectionId,
            scope.currentPageForColection + 1
          )
          .then((data) => {
            scope.collectionLoading = false;
            scope.collectionData = data;
            scope.pageCountForCollection = data.paginate.total_pages;
            timeout(() => {
              scope.showCollection = true;
            }, 300);
          });
        scope.collectionLoading = true;
      };

      scope.setPageForCollection = (num) => {
        if (num >= 0 && num < scope.pageCountForCollection) {
          scope.currentPageForColection = num;
          scope.getCollectionData();
        }
      };

      scope.setPageForFashion = (num) => {
        if (num >= 0 && num < scope.pageCountForFashoin) {
          scope.currentPageForFashion = num;
          scope.paramsForNewApi.page = num + 1; 
          scope.loadData();
        }
      };

      scope.openFullCollection = (index) => {
        scope.collectionId = scope.data.collections[index].id;
        scope.getCollectionData();
      };

      scope.closeFullCollection = () => {
        scope.showCollection = false;
      };

      scope.toggleImageDetails = () => {
        scope.showImageDetails = scope.showImageDetails ? false : true;
      };

      scope.$on("mood_state", (data) => {
        scope.saveImageDetailsData = data.targetScope.data;
        scope.toggleImageDetails();
        scope.toggleSaveImageDetails();
      });

      scope.toggleSaveImageDetails = () => {
        scope.saveImageDetails = scope.saveImageDetails ? false : true;
      };

      scope.$on("mood_state_changed", () => {
        scope.toggleSaveImageDetails();
      });

      scope.imageClickHandler = (index) => {
        let data = {
          index: index,
          data: [],
          collection: scope.collectionData.collection,
        };
        data.data = scope.collectionData.photos;
        scope.imageDetailsData = data;
        scope.toggleImageDetails();
      };
    };

    return {
      restrict: "EA",
      templateUrl: "app/directives/dbFashionShowImages/dbFashionShowImagesView.html",
      link: link, 
      scope: {
        item: "=",
        isExpanded: "=",
        singleDesigner: "=",
        yearId: "=",
        seasonId: "=",
        categoryId: "=",
        cityId: "=",
        regionId: "=",
        colorId: "=",
        menus: "=", 
        viewMode: "=",
        searchId: "=",
        selectedYear: "="
      },
    };
  },
]);
