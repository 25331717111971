angular.module("app").directive("dateModalDetail", () => {
  let link = (scope) => {
    scope.$watch("dateData", bindData);
    scope.currentYear = moment().year();
    scope.year = [
      { id: 2018, title: '2018'},
      { id: 2019, title: '2019'},
      { id: 2020, title: '2020'},
      { id: 2021, title: '2021'},
      { id: 2022, title: '2022'},
    ]
    scope.selectedYear = scope.currentYear;
    scope.index = scope.year.findIndex(x => x.id === scope.selectedYear);
    function bindData() {
        if(scope.currentYear === scope.selectedYear){
          const currentMonth = moment().month();
          for(let i=0; i < scope.dateData.length; i++){
            scope.dateData[i].isdisabled = false;
            for(let j=0; j < currentMonth+1; j++){
              scope.dateData[j].isdisabled = true;
            }
          }
        } 
        else {
          for(let i=0; i < scope.dateData.length; i++){
            scope.dateData[i].isdisabled = true;
          }
        }
    };

    scope.LeftButton = () => {
      let index = scope.index;
      if (index > 0) {
        index--;
      }
      scope.index = index;
      scope.selectedYear = scope.year[index].id;
      bindData()
    };

    scope.RightButton = () => {
      try {
        let index = scope.index;
        if (index < scope.year.length - 1) {
          index++;
        }
        scope.index = index;
        scope.selectedYear = scope.year[index].id;
        bindData()
      } catch (e) {}
    };

    scope.getSelectedMonth = (date) => {
      scope.getDate(date, scope.selectedYear);
    }

    scope.closeClickHandler = () => {
      scope.closefunction();
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/components/reports/dateModalView.html",
    link: link,
    scope: {
      onClose: "&",
      closefunction: "=",
      dateData: "=",
      getDate: "=",
    },
  };
});
