angular.module("app").directive("homeCategoryColorTherapyChart", () => {
  let link = (scope) => {
    scope.dotsPerLine = [1, 2, 3, 4, 5];

    scope.getDotLines = percentage => {
      const dotLines = [];
      const rowCnt = Math.ceil(percentage) / 2;
      for (let i = 0; i < rowCnt; i++) {
        dotLines.push(i);
      }
      return dotLines;
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/homeCategoryColorTherapyChart/homeCategoryColorTherapyChartView.html",
    link: link,
    scope: {
      colorData: "=",
      mode: "=",
    }
  };
});
