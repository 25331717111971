angular.module('app').directive('fashionColorCombination', () => {
  let link = (scope) => {
    scope.category = [
      "All",
      "Couture",
      "Rtw",
      "Menswear"
    ];
    scope.isSelectedCategory = (category) => {
      scope.selectedCategory = scope.cate;
      return scope.selectedCategory === category;
    };
    scope.selectCategory = (category) => {
      scope.selectedCategory = category;
      scope.chooseColor(scope.selectedColor, scope.selectedCategory);
    };
    scope.selectColor = (color) => {
      scope.selectedColor = color;
      scope.chooseColor(scope.selectedColor, scope.selectedCategory);
    };
    
    scope.maxValuePercent = 1;
    

    scope.heightDynamic = percent => {
      if (percent == 0){
        return 0
      } else if(percent > 0 && percent <= 1){
        return 15;
      } else if(percent > 1 && percent <= 5){
        return 30;
      } else if(percent > 5 && percent <= 10){
        return 45;
      } else if(percent > 10 && percent <= 15){
        return 60;
      } else if(percent > 15 && percent <= 20){
        return 75;
      } else if(percent > 20 && percent <= 25){
        return 90;
      } else if(percent > 25){
        return 90;
      }
    }

    scope.$watch('colorData', function (newVal, prevVal) {
      if(newVal) {
        scope.colorData = newVal;
        bindData();
      }
    });

    scope.$watch('mode', function (newVal, prevVal) {
      if(newVal === prevVal) {
        scope.mode = newVal;
      }else{
        scope.mode = newVal;
      }
    });
 
    function bindData() {  
      // scope.data = scope.colorData.filter((item) => {
      //   if (scope.selectedColor) {
      //     return item.hex !== scope.selectedColor.hex;
      //   }
      //   return item;
      // });
      scope.data = scope.colorData;

      if(scope.mode === 'designer') {
        scope.filterPercentData = scope.colorData.filter((item) =>{
          return item.percentage = item.percent.replace('%', ''); 
        });  
        scope.maxValuePercent = Math.max.apply(Math, scope.filterPercentData.map(function(o) { return o.percent; }));  
      } else {
        scope.filterPercentData = scope.colorData.filter((item) =>{
          return item.percentage = item.percent.replace('%', ''); 
        });  
        scope.maxValuePercent = Math.max.apply(Math, scope.filterPercentData.map(function(o) { return o.percent; }));  
      }
    }

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbFashionColorCombination/dbFashionColorCombinationView.html",
    link: link,
    scope: {
      colorData: "=",
      selectedColor: "=",
      mode: "=",
      chooseColor: "=",
      cate: "="
    }
  };
});
