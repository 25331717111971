angular.module("app").directive("termsStudiedWithTopic", () => {
  let link = (scope) => {
    scope.chartData = [];

    scope.$watch('data', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.data = newValue;
        if (scope.data) {
          scope.chartData[0] = Object.assign({}, scope.data.data);
          scope.chartData[1] = Object.assign({}, scope.data.averageData);
    
          scope.chartData[0].color = {};
          scope.chartData[1].color = {};
    
          scope.chartData[0].color.hex = '#000000';
          scope.chartData[1].color.type = 'average';
        }
      }
    }, true);
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbTermsStudiedWithTopic/dbTermsStudiedWithTopicView.html",
    link: link,
    scope: {
      data: "=",
      chartId: "=",
    }
  };
});
