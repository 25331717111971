angular.module("app").component("recoverComponent", {
  templateUrl: "app/components/recover/recover.tmpl.html",
  controller: function ($state, $http, appConfig) {
    const self = this;
    this.successRequest = false;
    this.email = "";
    this.error = false;

    this.onSendLoginClick = () => {
      if (self.successRequest) {
        $state.go("login");
      }

      if (!self.email) {
        self.error = "The Email field is required";
      } else {
        $http
          .get(appConfig.dashboardServiceUrl + `api/users/recovery?email=${self.email}`)
          .then((res) => {
            if (res.data) {
              if (res.data.success) {
                self.successRequest = true;
              } else {
                self.error = "The e-mail you provided does not exist in our data base. Please try again.";
              }
            }
          })
          .catch(function (err) {
            if (err.status === 422) {
              self.error = err.data.errors;
            }
          });
      }
    };
  },
});
