angular
  .module("app")
  .directive("dbMyBoards", function (appConfig, $http, authService, moodboardService, modalService) {
    function link($scope, element) {
      $scope.menu = [
        { id: 1, title: "FASHION" },
        { id: 2, title: "BRANDS" },
        { id: 3, title: "HOME" },
        { id: 4, title: "CARS" },
        { id: 5, title: "PHARMA" },
        { id: 6, title: "ART" },
        { id: 8, title: "LEGAL" },
        { id: 9, title: "NATURE" },
        { id: 7, title: "RESEARCH" },
        { id: 10, title: "VR GAME" },
        { id: 11, title: "FRAGRANCE" },
      ];

      $scope.tab = 1;
      $scope.selected_index = 0;
      $scope.selected_image = false;
      $scope.selected_moodboard = {};
      $scope.images = [];
      $scope.itemsImages = [];
      $scope.chartImages = [];
      $scope.chartDetailsImages = [];
      $scope.moodboards_name = "";
      $scope.state = 1;
      $scope.type_eq = '';
      $scope.type_end = '';
      $scope.currentYear = moment().year();

      if($scope.board === 'art') {
        $scope.selectedMenuId = 6;
      } else if($scope.board === 'auto') {
        $scope.selectedMenuId = 4;
      } else if($scope.board === 'pharma') {
        $scope.selectedMenuId = 5;
      } else if($scope.board === 'brand') {
        $scope.selectedMenuId = 2;
      } else if($scope.board === 'research') {
        $scope.selectedMenuId = 7;
      } else if($scope.board === 'legal') {
        $scope.selectedMenuId = 8;
      } else if($scope.board === 'nature') {
        $scope.selectedMenuId = 9;
      } else if($scope.board === 'home') {
        $scope.selectedMenuId = 3;
      } else if($scope.board === 'video') {
        $scope.selectedMenuId = 10;
      } else if($scope.board === 'beauty') {
        $scope.selectedMenuId = 11;
      } else {
        $scope.selectedMenuId = 1;
      }

      $scope.isSetId = function (id) {
        return $scope.selectedMenuId === id;
      };

      $scope.setMenuId = function (id) {
        $scope.selectedMenuId = id;
        $scope.images = [];
        if ($scope.selectedMenuId === 1){
          $scope.type_eq = 'fashion';
          $scope.type_end = 'photo';
        } else if ($scope.selectedMenuId === 2){
          $scope.type_eq = 'brand';
          $scope.type_end = 'company';
        } else if ($scope.selectedMenuId === 3){
          $scope.type_eq = 'home';
          $scope.type_end = 'home';
        } else if ($scope.selectedMenuId === 4){
          $scope.type_eq = 'auto';
          $scope.type_end = 'color_model';
        } else if ($scope.selectedMenuId === 5){
          $scope.type_eq = 'pharma';
          $scope.type_end = 'pill';
        } else if ($scope.selectedMenuId === 6){
          $scope.type_eq = 'art';
          $scope.type_end = 'picture';
        } else if ($scope.selectedMenuId === 7){
          $scope.type_eq = 'research';
          $scope.type_end = 'research';
        } else if ($scope.selectedMenuId === 8){
          $scope.type_eq = 'legal';
          $scope.type_end = 'legal';
        } else if ($scope.selectedMenuId === 9){
          $scope.type_eq = 'nature';
          $scope.type_end = 'nature';
        } else if ($scope.selectedMenuId === 10){
          $scope.type_eq = 'video';
          $scope.type_end = 'video';
        } else if ($scope.selectedMenuId === 11){
          $scope.type_eq = 'beauty';
          $scope.type_end = 'beauty';
        }
        moodboardService.getListOfMoodboard($scope.type_eq).then((res) => {
          $scope.images = res.data;
        });
      };

      $scope.sortableOptions = {
        stop: function () {
          const order = [];
          $("#moodboard_item")
            .find(".board-items")
            .each(function () {
              if ($(this).attr("attr_id")) {
                order.push($(this).attr("attr_id"));
              }
            });

          $http({
            url:
              appConfig.dashboardServiceUrl +
              "api/moodboards/" +
              $scope.selected_moodboard.id +
              "/items/item_order.json",
            method: "PUT",
            headers: {
              Authorizing: authService.token,
              "Content-Type": "application/json",
            },
            data: { item_ids: order },
            success: function () {},
          });
        },
      };

      $scope.keypair = function (item, index) {
        return Object.keys(item)[index]
      };

      $scope.itemHeight = function () {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        return ($scope.elemHeight =
          elements[0].clientWidth && elements[0].clientWidth + "px");
      };

      $scope.sortableOptionsBoard = {
        stop: function () {
          const orderBoard = [];
          $("#board_container")
            .find(".board-items")
            .each(function () {
              if ($(this).attr("attr_id")) {
                orderBoard.push($(this).attr("attr_id"));
              }
            });

          $http({
            url:
              appConfig.dashboardServiceUrl +
              "api/moodboards/moodboard_order.json",
            method: "PUT",
            headers: {
              Authorizing: authService.token,
              "Content-Type": "application/json",
            },
            data: { moodboard_ids: orderBoard },
            success: function () {},
          });
        },
      };

      prepare_moodboard();
      function prepare_moodboard() {
        $scope.isLoadingControls = true; 
        $scope.images = [];
        if ($scope.selectedMenuId === 1){
          $scope.type_eq = 'fashion';
          $scope.type_end = 'photo';
        } else if ($scope.selectedMenuId === 2){
          $scope.type_eq = 'brand';
          $scope.type_end = 'company';
        } else if ($scope.selectedMenuId === 3){
          $scope.type_eq = 'home';
          $scope.type_end = 'home';
        } else if ($scope.selectedMenuId === 4){
          $scope.type_eq = 'auto';
          $scope.type_end = 'color_model';
        } else if ($scope.selectedMenuId === 5){
          $scope.type_eq = 'pharma';
          $scope.type_end = 'pill';
        } else if ($scope.selectedMenuId === 6){
          $scope.type_eq = 'art';
          $scope.type_end = 'picture';
        } else if ($scope.selectedMenuId === 7){
          $scope.type_eq = 'research';
          $scope.type_end = 'research';
        } else if ($scope.selectedMenuId === 8){
          $scope.type_eq = 'legal';
          $scope.type_end = 'legal';
        } else if ($scope.selectedMenuId === 9){
          $scope.type_eq = 'nature';
          $scope.type_end = 'nature';
        } else if ($scope.selectedMenuId === 10){
          $scope.type_eq = 'video';
          $scope.type_end = 'video';
        } else if ($scope.selectedMenuId === 11){
          $scope.type_eq = 'beauty';
          $scope.type_end = 'beauty';
        }
        moodboardService.getListOfMoodboard($scope.type_eq).then((res) => {
          $scope.images = res.data;
          $scope.isLoadingControls = false; 
        });
      }

      // function proceed() {
      //   for (item in $scope.images) {
      //     moodboardService.getDetailsMoodboard($scope.type_eq, $scope.type_end, $scope.images[item].moodable_id).then((data) => {
      //       for (item_data in $scope.images) {
      //         if ($scope.images[item_data].id == data.id) {
      //           $scope.images[item_data].imagedata = data.data;
      //           if (data.data.length) {
      //             $scope.images[item_data].url = data.data[0].url;
      //             $scope.images[item_data].date_created =
      //               data.data[0].date_created;
      //             $scope.images[item_data].date_last_saved =
      //               data.data[0].date_last_saved;
      //           } else {
      //             $scope.images[item_data].url = "assets/images/empty.png";
      //             $scope.images[item_data].isEmpty = true;
      //           }
      //           break;
      //         }
      //       }
      //     });
      //   }
      // }

      // function get_image_from_item(item, callback) {
      //   $http({
      //     url:
      //       appConfig.dashboardServiceUrl +
      //       "api/moodboards/" +
      //       item +
      //       "/items.json",
      //     method: "GET",
      //     headers: {
      //       Authorizing: authService.token,
      //       "Content-Type": "application/json",
      //     },
      //     params: {},
      //   }).then(function (res) {
      //     callback({ id: item, data: res.data });
      //   });
      // }

      $scope.save_moodboardname = function (name) {
        if (name) {
          $scope.isLoadingControls = true; 
          $http({
            url:
              appConfig.dashboardServiceUrl +
              "api/moodboards/" +
              $scope.selected_moodboard.id +
              ".json",
            method: "PUT",
            headers: {
              Authorizing: authService.token,
              "Content-Type": "application/json",
            },
            data: { moodboard: { title: name } },
          }).then(function (res) {
            $scope.selected_moodboard.title = res.data.title;
            $scope.isLoadingControls = false; 
            $scope.rename = false;
          });
        } else {
          alert("Please type in moodboards name");
        }
      };

      $scope.selected_moodboards = function (item) {
        $scope.selected_moodboard = item;
        $scope.itemsImages = [];
        $scope.chartImages = [];
        $scope.chartDetailsImages = [];
        $scope.isLoadingControls = true; 
        moodboardService.getMoodboardItem(item.id).then((res) => {
          $scope.itemsImages = res.data.items.sort(function(a, b) {
            var textA = a && a.name ? a.name.toUpperCase() : '';
            var textB = b && b.name ? b.name.toUpperCase() : '';
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
          $scope.chartImages = res.data.charts;
          $scope.chartDetailsImages = res.data.detail_charts;
          $scope.isLoadingControls = false; 
        });
        $scope.state = 6;
      };

      $scope.add_image = function (item) {
        if ($scope.selected_image) {
          const params = {
            item: {
              title: null,
              itemable_id: $scope.selected_image.id,
              itemable_type: "Fashion::Photo",
            },
          };
          $http({
            url:
              appConfig.dashboardServiceUrl +
              "api/moodboards/" +
              item.id +
              "/items.json",
            method: "POST",
            headers: {
              Authorizing: authService.token,
              "Content-Type": "application/json",
            },
            data: params,
          }).then(function () {
            $scope.state = 4;
            $scope.selected_moodboard = item;
            $http({
              url:
                appConfig.dashboardServiceUrl +
                "api/moodboards/" +
                item.id +
                "/items.json",
              method: "GET",
              headers: {
                Authorizing: authService.token,
                "Content-Type": "application/json",
              },
            }).then(function (res_item) {
              $scope.selected_moodboard.imagedata = res_item.data;
            });
          });
        }
      };

      $scope.mail_pdf = function () {
        $scope.isLoadingControls = true; 
        moodboardService.getMoodboardMailPDF($scope.selected_moodboard.id).then((res) => {
          $scope.isLoadingControls = false; 
          if(res.status === 200) {
            modalService.showModal(13, null, res.data.moodboard.message);
          }
        }).catch(function (error) {
          $scope.isLoadingControls = false; 
        });
      };

      // $scope.print = function () {
      //   window.open(
      //     appConfig.dashboardServiceUrl +
      //       "api/moodboards/" +
      //       $scope.selected_moodboard.id +
      //       "/print?token=" +
      //       authService.token
      //   );
      // };

      $scope.deletemoodboard = function () {
        if ($scope.selected_moodboard.id) {
          $scope.isLoadingControls = true; 
          moodboardService.deleteMoodboard($scope.selected_moodboard.id).then((res) => {
            $scope.isLoadingControls = false; 
            $scope.trash_dialog = false;
            $scope.state = 1;
            prepare_moodboard();
          });
        }
      };

      $scope.createmoodboard = function (name) {
        if (name) {
          $scope.isLoadingControls = true; 
          $scope.type = '';
          if ($scope.selectedMenuId === 1){
            $scope.type = 'fashion';
          } else if ($scope.selectedMenuId === 2){
            $scope.type = 'brand';
          } else if ($scope.selectedMenuId === 3){
            $scope.type = 'home';
          } else if ($scope.selectedMenuId === 4){
            $scope.type = 'auto';
          } else if ($scope.selectedMenuId === 5){
            $scope.type = 'pharma';
          } else if ($scope.selectedMenuId === 6){
            $scope.type = 'art';
          } else if ($scope.selectedMenuId === 7){
            $scope.type = 'research';
          } else if ($scope.selectedMenuId === 8){
            $scope.type = 'legal';
          } else if ($scope.selectedMenuId === 9){
            $scope.type = 'nature';
          } else if ($scope.selectedMenuId === 10){
            $scope.type = 'video';
          } else if ($scope.selectedMenuId === 11){
            $scope.type = 'beauty';
          }
          const params = {
            title: name,
            moodboard_type: $scope.type
          }
          moodboardService.createMoodboard(params).then((data) => {
            $scope.state = 1;
            $scope.isLoadingControls = false; 
            prepare_moodboard();
          });
        }
      };

      $scope.remove_image = function (image) {
        $scope.isLoadingControls = true; 
        moodboardService.deleteItemMoodboard($scope.selected_moodboard.id, image.id).then((res) => {
          moodboardService.getMoodboardItem($scope.selected_moodboard.id).then((res) => {
            $scope.itemsImages = res.data.items.sort(function(a, b) {
              var textA = a.name.toUpperCase();
              var textB = b.name.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            $scope.chartImages = res.data.charts;
            $scope.chartDetailsImages = res.data.detail_charts;
            $scope.isLoadingControls = false; 
          }).catch(function (error) {
            $scope.isLoadingControls = false; 
          });
        }).catch(function (error) {
          $scope.isLoadingControls = false; 
        });
      };

      $scope.remove_chart_image = function (image) {
        $scope.isLoadingControls = true; 
        moodboardService.deleteChartMoodboard($scope.selected_moodboard.id, image.id).then((res) => {
          moodboardService.getMoodboardItem($scope.selected_moodboard.id).then((res) => {
            $scope.itemsImages = res.data.items.sort(function(a, b) {
              var textA = a.name.toUpperCase();
              var textB = b.name.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            $scope.chartImages = res.data.charts;
            $scope.chartDetailsImages = res.data.detail_charts;
            $scope.isLoadingControls = false; 
          }).catch(function (error) {
            $scope.isLoadingControls = false; 
          });
        }).catch(function (error) {
          $scope.isLoadingControls = false; 
        });
      };

      $scope.addmoodboard = function () {
        $scope.state = 5;
        $scope.board_name = '';
        $scope.selected_image = false;
      };

      $scope.select_index = function (index) {
        $scope.selected_index = index;
      };

      $scope.isState = function (state) {
        return $scope.state == state;
      };

      $scope.setState = function (state) {
        $scope.state = state;
      };

      $scope.setTab = function (tabId) {
        $scope.tab = tabId;
        $scope.select_images = false;
        $scope.selected_moodboard = false;
        $scope.state = 1;
        prepare_moodboard();
      };

      $scope.isSet = function (tabId) {
        return $scope.tab === tabId;
      };

      $scope.shareFacebook = function () {
        const array = {};
        array.share = [];
        for (let i = 0; i < $scope.selected_moodboard.imagedata.length; i++) {
          const data = $scope.selected_moodboard.imagedata[i].url;
          array.share.push(data);
        }
        window.open(
          "https://www.facebook.com/sharer/sharer.php?u=" +
            array.share.join(",")
        );
      };

      $scope.shareTwitter = function () {
        const array = {};
        array.share = [];
        for (let i = 0; i < $scope.selected_moodboard.imagedata.length; i++) {
          const data = $scope.selected_moodboard.imagedata[i].url;
          array.share.push(data);
        }
        window.open("https://twitter.com/home?status=" + array.share.join(","));
      };

      $scope.sharePinterest = function () {
        const array = {};
        array.share = [];
        for (let i = 0; i < $scope.selected_moodboard.imagedata.length; i++) {
          const data = $scope.selected_moodboard.imagedata[i].url;
          array.share.push(data);
        }
        window.open(
          "https://pinterest.com/pin/create/button/?url=" +
            array.share.join(",")
        );
      };

      $scope.shareGooglePlus = function () {
        const array = {};
        array.share = [];
        for (let i = 0; i < $scope.selected_moodboard.imagedata.length; i++) {
          const data = $scope.selected_moodboard.imagedata[i].url;
          array.share.push(data);
        }
        window.open(
          "https://plus.google.com/share?url=" + array.share.join(",")
        );
      };

      $scope.$on("mood_state_changed", function (event, value) {
        $scope.selected_image = value;
        if ($scope.images.length == 0) {
          $scope.state = 1;
          $scope.tab = 3;
        } else {
          $scope.state = 3;
          $scope.tab = 3;
        }
      });

      $scope.rename = false;
      $scope.rename_board = function () {
        $scope.rename = true;
        $scope.moodboards_name = $scope.selected_moodboard.title;
        $scope.state = 6;
      };

      $scope.back_to_board = function () {
        $scope.state = 1;
        $scope.selected_moodboard = '';
      }

      $scope.send_email = function (email) {
        if (email) {
          $http({
            url:
              appConfig.dashboardServiceUrl +
              "api/moodboards/" +
              $scope.selected_moodboard.id +
              "/email.json",
            method: "POST",
            headers: {
              Authorizing: authService.token,
              "Content-Type": "application/json",
            },
            data: { email_to: email },
          }).then(function () {
            $scope.share_dialog = false;
          });
        }
      };

      $scope.share = function () {
        $scope.share_dialog = true;
      };

      $scope.close_share = function () {
        $scope.share_dialog = false;
      };

      $scope.trash_alt = function () {
        $scope.trash_dialog = true;
      };

      $scope.close_trash = function () {
        $scope.trash_dialog = false;
      };

      $scope.toggleDialog = function () {
        $scope.rename = false;
      };
    }

    return {
      restrict: "E",
      templateUrl: "app/directives/dbMyBoards/dbMyBoards.html",
      link,
      scope: {
        board: "=",
      },
    };
  });
