angular.module("app").controller("natureController", [
  "$scope",
  "dashboardOverlayService",
  "natureFlowerDashboardRepository",
  "$timeout",
  "charts",
  "$state",
  "$location",
  "modalService",
  "localStorageService",
  function (
    scope,
    dashboardOverlayService,
    natureFlowerDashboardRepository,
    timeout,
    charts,
    $state,
    $location,
    modalService,
    localStorageService
  ) {
    scope.menus = {
      flowerFamily: "",
      flowerLifespan: "",
      flowerLocation: "",
      birdFamily: "",
      birdLocation: "",
      birdSize: "",
      color: "",
    };

    scope.disabledControls = {
      flowerFamily: false,
      flowerLifespan: false,
      flowerLocation: false,
      birdFamily: false,
      birdLocation: false,
      birdSize: false,
      color: false,
    };

    scope.type = {
      flowerFamily: "flowerFamily",
      flowerLifespan: "flowerLifespan",
      flowerLocation: "flowerLocation",
      birdFamily: "birdFamily",
      birdLocation: "birdLocation",
      birdSize: "birdSize",
      color: "color",
    };

    if(localStorageService.get("currentUser")){
      scope.user = localStorageService.get("currentUser");
    }

    scope.params = $location.search();

    scope.$watch('params', function (params) {
      if (!params.mode) {
        scope.isLoadingModes = true;
        scope.searchMode = null;
        scope.init();
      }
      else {
        scope.selectedMode = {
          title: params.mode,
          img_url: scope.getModeImage(params.mode),
        };
        scope.isLoadingModes = false;

        if (params.control === 'searchMenu') {
          scope.init();
        } else {
          scope.handleChangeControl(params.control, params);
        }
      }
    });

    scope.disabledInput = (type, value) => {
      switch (type) {
        case "flowerFamily":
          scope.disableFlowerLifespan = Boolean(value);
          scope.disableFlowerLocation = Boolean(value);
          break;
        case "flowerLifespan":
          scope.disableFlowerFamily = Boolean(value);
          scope.disableFlowerLocation = Boolean(value);
          break;
        case "flowerLocation":
          scope.disableFlowerFamily = Boolean(value);
          scope.disableFlowerLifespan = Boolean(value);
          break;
        case "birdFamily":
          scope.disableBirdSize = Boolean(value);
          scope.disableBirdLocation = Boolean(value);
          break;
        case "birdLocation":
          scope.disableBirdFamily = Boolean(value);
          scope.disableBirdSize = Boolean(value);
          break;
        case "birdSize":
          scope.disableBirdFamily = Boolean(value);
          scope.disableBirdLocation = Boolean(value);
          break;
      }
    };

    scope.mainParam = null;
    scope.mainParamId = null;
    scope.secondaryParams = {};
    scope.tab = 1;
    scope.currentPage = 0;
    scope.perPage = 40;

    scope.flower_family_place_holder = "SEARCH BY FLOWER FAMILY";
    scope.lifespan_place_holder = "SEARCH BY LIFESPAN";
    scope.location_place_holder = "SEARCH BY LOCATION";
    scope.bird_family_place_holder = "SEARCH BY FAMILY";
    scope.bird_size_family_place_holder = "SEARCH BY SIZE";

    scope.showDashboard = false;
    scope.title = scope.year;
    scope.subtitle = null;
    scope.selectedMode = null;

    scope.isLoadingControls = true;
    scope.isLoadingModes = true;
    scope.flowerFamilyList = [];

    scope.modes = [
      {
        title: "flowers",
        img_url: "/assets/img/nature/mode/flower.png"
      },
      {
        title: "birds",
        img_url: "/assets/img/nature/mode/bird.png"
      },
      {
        title: "butterflies",
        img_url: "/assets/img/nature/mode/butterfly.png"
      },
      {
        title: "earth",
        img_url: "/assets/img/nature/mode/earth.png"
      }
    ];

    scope.getModeImage = (mode) => {
      if (mode === "flowers") {
        return "/assets/img/nature/mode/flower.png";
      }
      if (mode === "birds") {
        return "/assets/img/nature/mode/bird.png"
      }
      if (mode === "flowers") {
        return "/assets/img/nature/mode/butterfly.png";
      }
      if (mode === "flowers") {
        return "/assets/img/nature/mode/earth.png";
      }

      return "";
    };

    natureFlowerDashboardRepository.getFlowerColorsList().then((data) => {
      scope.flowerColors = data.colors;
    });

    natureFlowerDashboardRepository.getFlowerLifespanList().then((data) => {
      scope.flowerLifespanList = data.lifespans;
    });


    // dummy data -> bird colors
    scope.birdColors = [
      {
        id: 0,
        title: "mixed",
        hex: "#a3d8e4",
        hex1: "#ca7db2",
      },
      {
        id: 1,
        title: "pink",
        hex: "#f7c6de",
      },
      {
        id: 2,
        title: "red",
        hex: "#e51d1c",
      },
      {
        id: 3,
        title: "brown",
        hex: "#c55531",
      },
      {
        id: 4,
        title: "darkorange",
        hex: "#f07c16",
      },
      {
        id: 5,
        title: "yellow",
        hex: "#fbdf16",
      },
      {
        id: 6,
        title: "lemonchiffon",
        hex: "#f0dc83",
      },
      {
        id: 7,
        title: "yellowgreen",
        hex: "#8db624",
      },
      {
        id: 8,
        title: "green",
        hex: "#0a8236",
      },
      {
        id: 9,
        title: "blue",
        hex: "#335ca7",
      },
      {
        id: 10,
        title: "purple",
        hex: "#6d4595",
      },
      {
        id: 11,
        title: "wheat",
        hex: "#eed5b4",
      },
      {
        id: 12,
        title: "brown",
        hex: "#974d15",
      },
      {
        id: 13,
        title: "gray",
        hex: "#808081",
      },
      {
        id: 14,
        title: "white",
        hex: "#ffffff",
      },
      {
        id: 15,
        title: "black",
        hex: "#050607",
      },
    ];

    scope.setback = () => {
      $state.go("nature", {mode: scope.selectedMode.title, control: "searchMenu", id: "", title: "", hex: ""});
    };

    scope.init = () => {
      scope.menus = {
        artist: "",
        period: "",
        school: "",
        color: "",
      };
      scope.disableFlowerFamily = false;
      scope.disableFlowerLifespan = false;
      scope.disableFlowerLocation = false;
      scope.disableBirdFamily = false;
      scope.disableBirdSize = false;
      scope.disableBirdLocation = false;
      scope.isLoadingControls = true;
      scope.currentPage = 0;
      scope.mainSeason = [];
      scope.tab = 1;
      if(!scope.user.admin) {
        modalService.showModal(10, null, "We're working to finalize this dashboard and except it launched in Q2 of 2022.");
      }
    };

    scope.setTab = (tabId) => {
      scope.tab = tabId;
      scope.select_images = false;
      scope.selected_moodboard = false;
      scope.state = 1;
    };

    scope.isSet = (tabId) => {
      return scope.tab === tabId;
    };

    scope.getResultsOnFilterSearch = (type, str, page) => {
      scope.ajaxInProgress = true;  
      switch (type) {
        case 'flowerFamily':
          if (!str.length) {
            scope.flowerFamilyList = [];
            scope.ajaxInProgress = false;
            return;
          }
          //new logic for pagination
          if(scope.oldsearchdata === ""){
            scope.oldsearchdata = str;
            scope.selectedpage = 1;
          } 
          else if(scope.oldsearchdata !== str){  
              page = 1;
              scope.selectedpage = page;
              scope.flowerFamilyList = [];
              scope.oldsearchdata = str;
          } 
          else{
            scope.selectedpage += 1;
            page = scope.selectedpage;
          }

          natureFlowerDashboardRepository.getFlowerFamilyList(str)
          .then(data => {
            scope.flowerFamilyList = [];
            if(data.families.length>0){  
              scope.flowerFamilyList = data.families;
            }   
          }).finally(() => {
            scope.ajaxInProgress = false;
          });
          break; 

        default:
          scope.ajaxInProgress = false;
      }
    }; 

    scope.loadGraphics = function () {
      if (scope.mainParam) {
        scope.showDashboard = true;
        dashboardOverlayService.loadingStart(4000);

        scope.isLoadingControls = false;

        if (scope.mainParam === "color") {
          scope.isLoadingControls = false;
          natureFlowerDashboardRepository
          .getFlowersImage(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              scope.perPage
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.pageCount = data.paginate.total_pages;
                scope.flowerContentData = data.flowers;
              } 
            });
        } else if (scope.mainParam === "flowerFamily") {
          scope.isLoadingControls = false;
          natureFlowerDashboardRepository
          .getFlowersImage(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              scope.perPage
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.pageCount = data.paginate.total_pages;
                scope.flowerContentData = data.flowers;
              } 
            });
        } else if (scope.mainParam === "flowerLifespan") {
          scope.isLoadingControls = false;
          natureFlowerDashboardRepository
          .getFlowersImage(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              scope.perPage
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.pageCount = data.paginate.total_pages;
                scope.flowerContentData = data.flowers;
              } 
            });
        } else if (scope.mainParam === "flowerLocation") {
          scope.isLoadingControls = false;
          scope.flowerContentData = [
            {
              id: 0,
              name: "glossy abelia",
              image_url: "/assets/img/nature/flowers/1.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 1,
              name: "glossy abelia 1",
              image_url: "/assets/img/nature/flowers/2.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 2,
              name: "glossy abelia 2",
              image_url: "/assets/img/nature/flowers/3.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 3,
              name: "glossy abelia 3",
              image_url: "/assets/img/nature/flowers/4.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 4,
              name: "glossy abelia 4",
              image_url: "/assets/img/nature/flowers/5.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 5,
              name: "glossy abelia 5",
              image_url: "/assets/img/nature/flowers/6.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 6,
              name: "glossy abelia 6",
              image_url: "/assets/img/nature/flowers/6.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 7,
              name: "glossy abelia 7",
              image_url: "/assets/img/nature/flowers/6.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 8,
              name: "glossy abelia 8",
              image_url: "/assets/img/nature/flowers/1.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 9,
              name: "glossy abelia 9",
              image_url: "/assets/img/nature/flowers/2.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 10,
              name: "glossy abelia 10",
              image_url: "/assets/img/nature/flowers/3.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 11,
              name: "glossy abelia 11",
              image_url: "/assets/img/nature/flowers/4.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 12,
              name: "glossy abelia 12",
              image_url: "/assets/img/nature/flowers/5.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 13,
              name: "glossy abelia 13",
              image_url: "/assets/img/nature/flowers/6.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 14,
              name: "glossy abelia 14",
              image_url: "/assets/img/nature/flowers/6.png",
              family: "caprifoliaceae",
              lifespan: "short"
            },
            {
              id: 15,
              name: "glossy abelia 15",
              image_url: "/assets/img/nature/flowers/6.png",
              family: "caprifoliaceae",
              lifespan: "short"
            }
          ];
        }
      }
    };

    scope.setPage = (num) => {
      if (num >= 0 && num < scope.pageCount) {
        scope.currentPage = num;
        scope.loadGraphics();
      }
    };

    scope.handleChangeControl = function (control, item) {
      scope.header_title = () => {
        if (control === "flowerLifespan") {
          return "FLOWER LIFESPAN";
        } else if (control === "flowerLocation") {
          return `FLOWER ${item.title}-LOCATION`;
        } else if (control === "flowerFamily") {
          return `FLOWER FAMILY: ${item.title}`;
        } else if (control === "birdFamily") {
          return `BIRD FAMILY: ${item.title}`;
        } else if (control === "birdSize") {
          return `BIRD SIZE`;
        } else if (control === "birdLocation") {
          return `BIRD ${item.title}-LOCATION`;
        }

        return `${control}: ${item.title}`;
      };

      if (control === "color" && item.title === "mixed") {
        item.img_url = "/assets/img/nature/birds/color.png";
      }

      scope.mainParam = control;
      scope.selectedFilterValue = item;
      scope.mainParamId = item.id;
      scope.searchMode = control;

      scope.loadGraphics();
    };

    scope.handleChangeMode = (item) => {
      $state.go("nature", {mode: item.title, control: "searchMenu", id: "", title: "", hex: "", hex1: ""});
    };

    scope.handleSearchChange = function (control, item) {
      if (item.family) {
        if(item.family === 'yellow/green') {
          item.title = 'yellow-green'
        } else {
          item.title = item.family;
        }
      }
      $state.go("nature", Object.assign({mode: scope.selectedMode.title, control: control}, item, {hex1: item.hex1 ? item.hex1 : ''}));
    };

    scope.isFlowerNatureMode = () => {
      return scope.selectedMode.title === "flowers";
    };

    scope.isBirdNatureMode = () => {
      return scope.selectedMode.title === "birds";
    };

    scope.isButterflyNatureMode = () => {
      return scope.selectedMode.title === "butterflies";
    };

    scope.isEarthNatureMode = () => {
      return scope.selectedMode.title === "earth";
    };

    scope.$watch(
      () => {
        return dashboardOverlayService.showOverlay;
      },
      (newValue) => {
        scope.showDashboardOverlay = newValue;
      }
    );
  },
]);
