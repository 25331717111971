angular.module("app").directive("colorLogoByCountry", function () {
  function link(scope) {

  }

  const directive = {
    link: link,
    restrict: "E",
    replace: true,
    scope: {
      colorFamilies: "=",
    },
    templateUrl:
      "app/directives/customInfographicsUnicornDirectives/colorLogoByCountry/colorLogoByCountry.html",
  };

  return directive;
});
