angular.module("app").directive("hueDbColorsByFamilyRangeSelector", [
  "$location",
  function () {
    function link(scope) {
      scope.data = [
        { title: "5", value: 5 },
        { title: "10", value: 10 },
        { title: "20", value: 20 },
      ];
      scope.selectedValue = scope.data[0].value;

      scope.isYear = function (bucket) {
        return bucket == scope.selectedValue;
      };
      scope.setYear = function (bucket) {
        scope.selectedValue = bucket;
      };
    }

    return {
      restrict: "E",
      link: link,
      scope: {
        selectedValue: "=",
      },
    };
  },
]);
