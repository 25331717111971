angular.module("app").directive("nationalBirdsDominantColors", () => {
  let link = (scope) => {
    scope.showImageDetails = false;
    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (index) => {
      scope.index = index;
      scope.toggleImageDetails();
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbNationalBirdsDominantColors/dbNationalBirdsDominantColorsView.html",
    link: link,
    scope: {
      data: "=",
      mode: "=",
    }
  };
});
