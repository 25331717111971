angular
  .module('app')
  .component('cartPageComponent', {
    templateUrl: 'app/components/cart-page/cart-page.tmpl.html',
    controller: function ($state, $http, appConfig, $location, anchorSmoothScroll, localStorageService, $stateParams, modalService, $window, cartService) {
      const vm = this;

      vm.init = function () {
        vm.wayBack = $stateParams.wayBack || 'profile';
        vm.wayBackName = ' to ';

        switch ($stateParams.wayBack) {
          case 'reports':
            vm.wayBackName += 'Color Reports';
            break;
          case 'courses':
            vm.wayBackName += 'Color Courses';
            break;
          case 'teachingMaterials':
            vm.wayBackName += 'Color Teaching Materials';
            break;
          case 'profile':
            vm.wayBackName += 'Profile';
            break;
          case 'educationCourses':
            vm.wayBackName += 'Education Courses';
            break;
          case 'educationTeachingMaterials':
            vm.wayBackName += 'Education Teaching Materials';
            break;
          default:
            vm.wayBackName = '';
        }

        vm.products = [];
        vm.all = 0;
        vm.tax = 0;
        vm.IDs = localStorageService.get('products');

        if(vm.IDs) {
          vm.getProductItems(vm.IDs.courses, 'courses');
          vm.getProductItems(vm.IDs.teaching_materials, 'teaching_materials');
        }
        vm.getCartDetails();
      };

      vm.getCartDetails = function () {
        cartService.getCartItem().then(function (res) {
          if(res.status === 200 && res.data.carts.length > 0) {
            res.data.carts.forEach(element => {
              vm.pageData = element.report;
              vm.pageData.category = element.cartable_type;
              vm.pageData.count = element.quantity;
              vm.pageData.type = element.report.category;
              vm.pageData.cartId = element.id;
              vm.all = vm.all + (element.report.price * vm.pageData.count);
              vm.products.push(vm.pageData);
            });
          }
        });
      };

      vm.getProductItems = function (obj, name) {
        for (const key in obj) {
          $http.get(appConfig.dashboardServiceUrl + 'api/' + name + '/' + key + '.json')
            .then(function (res) {
              vm.pageData = res.data;
              vm.pageData.image_url = res.data.image_url && res.data.image_url;
              vm.pageData.count = obj[key];
              vm.pageData.type = name;
              vm.all = vm.all + (vm.pageData.price * vm.pageData.count);
              vm.products.push(vm.pageData);
            });
        }
      };

      vm.goCheckout = function () {
        const purchase = {IDs: {}};
        purchase.amount = vm.all;
        for (const type in vm.IDs) {
          purchase.IDs[type] = {};
          for (const id in vm.IDs[type]) {
            if (vm.IDs[type][id] < 1) {
              return;
            } else {
              purchase.IDs[type][id] = vm.IDs[type][id];
            }
          }
        }

        localStorageService.set('purchase', purchase);
        $state.go('cart-checkout');
      };

      vm.removeProduct = function (id, type, index) {
        modalService.showModal(4, function () {
          cartService.deleteCartItem(vm.products[index].cartId).then(function (res) {
            if(res.status === 200) {
              // vm.init();
              vm.products.splice(index, 1);
            }
          });
        });
      };

      vm.goWayBack = function () {
        if ($window.history.length < 3) {
          $state.go('about');
        } else if (vm.wayBackName === '') {
          $window.history.back();
        } else {
          $state.go(vm.wayBack);
        }
      };

      vm.editCount = function (id, index, type, value) {
        if (vm.products[index].count + value > 0) {
          vm.products[index].count = vm.products[index].count + value;
          vm.IDs[type][id] = vm.IDs[type][id] + value;
          localStorageService.set('products', vm.IDs);
          vm.all = vm.all + vm.products[index].price * vm.products[index].count;
        } else {
          vm.removeProduct(id, type, index);
        }
      };

      vm.addCount = function (id, index, type, value) {
        if (vm.products[index].count + value > 0) {
          vm.products[index].count = vm.products[index].count + value;
          vm.all = vm.all + vm.products[index].price * vm.products[index].count;
        }
      };
    }
  });
