angular.module('app').directive('colorFrequencyByVideo', () => {
  let link = (scope) => {
    scope.getWidth = function (index) {
      let totalPercentage = 0;
      scope.data.forEach(item => {
        if(typeof(item.percent) == "string") {
          totalPercentage += parseFloat(item.percent);
        } else {
          totalPercentage += item.percent;
        }
      });
      let indexPercentage = parseFloat(scope.data[index].percent);
      return parseFloat((100 / totalPercentage) * indexPercentage) + '%';
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorFrequencyByVideo/dbColorFrequencyByVideoView.html",
    link: link,
    scope: {
      data: "=",
    }
  };
});
