angular.module("app").directive("wordCloudModalDetail", () => {
  let link = (scope) => {
    
    scope.data = scope.wordArrayData;

    scope.closeClickHandler = () => {
      scope.closefunction();
    };

    scope.shareFacebook = (url) => {
      window.open("https://www.facebook.com/sharer/sharer.php?u=" + url);
    };

    scope.shareTwitter = (url) => {
      window.open("https://twitter.com/home?status=" + url);
    };

    scope.sharePinterest = (url) => {
      window.open("https://pinterest.com/pin/create/button/?url=" + url);
    };

    scope.shareGooglePlus = (url) => {
      window.open("https://plus.google.com/share?url=" + url);
    };

    scope.shareInstagram = (url) => {
      window.open("https://instagram.com/?url=" + url);
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/wordCloud/wordCloudModalView.html",
    link: link,
    scope: {
      onClose: "&",
      closefunction: "=",
      wordArrayData: "=",
    },
  };
});
