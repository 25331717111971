angular.module("app").component("colorIndexSearchComponent", {
  templateUrl: "app/components/color-index-search/color-index-search.tmpl.html",
  controller: function (
    dataValidate,
    appConfig,
    $window,
    $location,
    anchorSmoothScroll,
    $http,
    $scope,
    $state,
    searchColor,
    colorRequest
  ) {
    const vm = this;
    vm.paintColorNamesData = [];
    vm.colorAssociationNames = [];

    vm.colorSearch = function () {
      if (this.data.color !== "") {
        colorRequest.getShortNames(vm.data.color, 1).then(function (data) {
          vm.colorValidDataShort = data.color_names;

          if (data && data.color_names.length > 0) {
            vm.paintColorNamesData = data.short_name;
            vm.colorAssociationNames = data.short_namecontains;
            // searchColor.set(vm.paintColorNamesData, vm.colorAssociationNames);
            $state.go('colorIndexTabs', {color: vm.data.color, mode: 'word-search'});
          }
        });
      }
    };
  },
});
