angular.module("app").directive("globalColorMentions", [
  "trackDashboardRepository",
  "dateTimeFormatter",
  (trackDashboardRepository, dateTimeFormatter) => {
    let link = (scope) => {
      scope.showImageDetails = false;
      scope.ajaxInProgress = false;
      scope.currentDate = '';
      scope.meta = {
        fromDateStr: '',
        toDateStr: ''
      };
      scope.page = 1;

      scope.sortByKey = (array, key) => {
        return array.sort(function(a, b) {
          let x = a[key];
          let y = b[key];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
      };

      scope.toggleImageDetails = () => {
        scope.showImageDetails = !scope.showImageDetails;
      };

      scope.imageClickHandler = (index) => {
        scope.index = index;
        scope.toggleImageDetails();
      };

      scope.selectedSocials = [];

      scope.isSelectedSocial = (index) => {
        if (scope.selectedSocials.indexOf(index) !== -1) {
          return true;
        }
        return false;
      };

      scope.selectSocial = (index) => {
        if (!scope.isSelectedSocial(index)) {
          scope.selectedSocials.push(index);
        } else {
          scope.selectedSocials = scope.selectedSocials.filter(item => item !== index);
        }
        scope.colorMentionsData = [];
        scope.page = 1;
        scope.getMentions();
      };

      scope.colorMentionsData = [];

      scope.getMentions = () => {
        const color = scope.mentionColors.map(item => item.title).join(',');
        if (!color) {
          scope.colorMentionsData = [];
          return;
        }
        scope.ajaxInProgress = true;
        trackDashboardRepository.getMentionData(color, scope.page, scope.selectedSocials, '1m')
          .then((data) => {
            scope.ajaxInProgress = false;
            if (data) {
              if (data.colorMentionData.length) {
                scope.colorMentionsData = scope.colorMentionsData.concat(scope.sortByKey(data.colorMentionData, 'id'));
              }
              if (data.meta) {
                scope.meta = data.meta;
                if (scope.meta.from) {
                  let date = new Date(scope.meta.from);
                  scope.meta.fromDateStr = date.toDateString().split(' ').slice(1).join(' ');
                }
                if (scope.meta.to) {
                  let date = new Date(scope.meta.to);
                  scope.meta.toDateStr = date.toDateString().split(' ').slice(1).join(' ');
                }
                scope.currentDate = dateTimeFormatter.formatDateForView(data.meta.current_time);
              }
            }
          });
      };

      scope.more = () => {
        scope.page = scope.page + 1;
        scope.getMentions();
      }

      scope.$watch('mentionColors', () => {
        scope.getMentions();
      });

    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbGlobalColorData/globalColorMentions/globalColorMentionsView.html",
      link: link,
      scope: {
        mentionColors: "=",
      }
    };
  }
]);
