angular.module('app').directive('fashionYearComplete', [
  "brandingDashboardRepository",
  function (
    brandingDashboardRepository
  ) {
    function link (scope) {
      // dummy data
      // scope.filteredChoices = [
      //   {
      //     "id": 0,
      //     "title": "ALL",
      //   },
      //   {
      //     "id": 1,
      //     "title": "2021",
      //   },
      //   {
      //     "id": 2,
      //     "title": "2020",
      //   },
      //   {
      //     "id": 3,
      //     "title": "2019",
      //   },
      //   {
      //     "id": 4,
      //     "title": "2018",
      //   },
      //   {
      //     "id": 5,
      //     "title": "2017",
      //   },
      //   {
      //     "id": 6,
      //     "title": "2016",
      //   },
      //   {
      //     "id": 7,
      //     "title": "2015",
      //   },
      //   {
      //     "id": 8,
      //     "title": "2014",
      //   },
      //   {
      //     "id": 9,
      //     "title": "2013",
      //   },
      // ];

    scope.filteredChoices = [];

    scope.loadFashionYears = () => {
      scope.ajaxInProgress = true;
      brandingDashboardRepository.dashboard.getFashionYears().then(data => { 
        scope.filteredChoices= data.years.sort((a, b) => (a.id < b.id) ? 1 : -1);
      }).finally(() => {
        scope.ajaxInProgress = false;
      });
    };

    scope.loadFashionYears();

      scope.isVisible = {
        suggestions: false,
      };

      scope.filterItems = () => {
        if (scope.enteredtext.length < 0) return;
        scope.isVisible.suggestions =
          scope.enteredtext.length === 0
            ? false
            : scope.filteredChoices.length > 0;
      };

      scope.selectItem = (choice) => {
        scope.selected = choice;
        scope.enteredtext = choice.name;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbFashionComplete/fashionYearComplete/fashionYearCompleteView.html",
      link: link,
      scope: {
        enteredtext: "=",
        selected: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        disabled: "=",
        disable: "=",
        board: "=",
      }
    };
  }
]);
