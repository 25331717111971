angular.module("app").directive("categoryColorTrademarkStyle", () => {
  let link = (scope) => {
    scope.getDotsList = (value) => {
      let cnt = Math.ceil(value) / 2;
      const result = [];
      if(cnt > 10) {
        cnt = 10;
      }
      for (let i = 0; i < cnt; i ++) {
        result.push(i);
      }
      return result;
    };

    scope.loadMore = () => {
      scope.loadMoreData();
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbCategoryColorTrademarkStyle/dbCategoryColorTrademarkStyleView.html",
    link: link,
    scope: {
      data: "=",
      selectedColor: "=",
      isLoadMoreData: "=",
      loadMoreData: "=",
    }
  };
});
