angular.module("app").directive("hueColorFamiliesPharamCompany", () => {
  let link = (scope) => {
    scope.isWhite = color => {
      return color === '#ffffff';
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorFamiliesPharmaCompany/dbColorFamiliesPharmaCompanyView.html",
    link: link,
    scope: {
      colorFamiliesData: "="
    }
  };
});
