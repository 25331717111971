(function () {
  angular.module("app").directive("hueChartByMostAssociated", [
    function () {
      function link(scope, element) {
        scope.$watch("data", bindData);

        function bindData() {
          scope.mainData = scope.data
          const containers = element.find("[chart-block]");
          _.each(containers, (c, i) => {
            const container = $(c);
            container[0].innerHTML = "";
          })
          _.each(scope.mainData, (obj, i) => {
            const svg = d3
              .select(containers[i])
              .append("svg")
              .attr("width", "100%")
              .attr("height", "265px");
  
            const g = svg
              .append("g")
              .attr("transform", "translate(" + 0 + "," + 265 + ")");
  
            g.append("line")
              .style({ stroke: "#cccccc", "stroke-width": 2 })
              .attr("x1", 25)
              .attr("x2", 25)
              .attr("y1", -60)
              .attr("y2", -250);
            g.append("text").text("#3").attr("y", -95).attr("x", 5);
            g.append("text").text("#2").attr("y", -170).attr("x", 5);
            g.append("text").text("#1").attr("y", -245).attr("x", 5);
            g.append("line")
              .style("stroke-dasharray", "3, 3")
              .style({ stroke: "#cccccc", "stroke-width": 4 })
              .attr("x1", 25)
              .attr("x2", 60)
              .attr("y1", -100)
              .attr("y2", -100);
            g.append("text").text(scope.mainData[i].properties[0].title).attr("y", -95).attr("x", 65).style("text-transform", "uppercase");
            g.append("line")
              .style("stroke-dasharray", "3, 3")
              .style({ stroke: "#cccccc", "stroke-width": 4 })
              .attr("x1", 25)
              .attr("x2", 60)
              .attr("y1", -175)
              .attr("y2", -175);
            g.append("text").text(scope.mainData[i].properties[1].title).attr("y", -170).attr("x", 65).style("text-transform", "uppercase");
            g.append("line")
              .style("stroke-dasharray", "3, 3")
              .style({ stroke: "#cccccc", "stroke-width": 4 })
              .attr("x1", 25)
              .attr("x2", 60)
              .attr("y1", -250)
              .attr("y2", -250);
            g.append("text").text(scope.mainData[i].properties[2].title).attr("y", -245).attr("x", 65).style("text-transform", "uppercase");
  
            g.append("rect")
              .style("fill", scope.mainData[i].hex)
              .style("stroke", scope.mainData[i].hex === '#FFFFFF' || scope.mainData[i].hex === '#ffffff' ? '#d1d3d4' : 'none')
              .attr("x", 0)
              .attr("y", -50)
              .attr("width", "100%")
              .attr("height", 50);
          });
        }
       
      }
      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          data: '=',
        },
        templateUrl:
          "app/directives/custom-infographics-brand-directives/chart-by-most-associated.html",
      };

      return directive;
    },
  ]);
})();
