angular.module("app").directive("autoColorData", [
  "reduceValue", "$timeout",
  (reduceValue, timeout) => {
    let link = (scope, element) => {
      scope.tooltipsterConfig = {
        animation: 'fade',
        theme: 'tooltipster-default',
        trigger: 'custom',
        position: 'bottom'
      };
      scope.selectedBar = null;
      scope.$watch('pastSelectedYear', function (pastSelectedYear) {
        if(pastSelectedYear){
          bindData();
        }
      });
      function bindData() {
        scope.year = scope.pastSelectedYear[0] ? scope.pastSelectedYear[0].year : '';
        scope.selectedYearColorPalette = scope.pastSelectedYear[0] ? scope.pastSelectedYear[0].colors : [];
      }

      scope.tooltipTitle = function (color) {
        return `FAMILY: ${color.family}
        HEX: ${color.hex}
        RGB: ${color.rgb}`.toUpperCase();
      }

      scope.selectBar = function (event) {
        if (scope.selectedBar == event.target) {
          angular.element(scope.selectedBar).tooltipster('hide');
          scope.selectedBar = null;
        } else {
          if (scope.selectedBar)
            angular.element(scope.selectedBar).tooltipster('hide');
            angular.element(event.target).tooltipster('show');
            scope.selectedBar = event.target;
        }
      };

      scope.showMoreColors = false;
      scope.showAutoPopup = false;
      scope.showColorPopup = false;
      scope.colorsCount = 22;
      scope.topColorsExpanded = false;
      scope.colorFrequencyExpanded = false;

      scope.widthDynamic = percent => {
        if (percent == 0){
          return 0
        } else if(percent > 0 && percent <= 1){
          return 20;
        } else if(percent > 1 && percent <= 5){
          return 40;
        } else if(percent > 5 && percent <= 10){
          return 60;
        } else if(percent >= 11){
          return 80;
        }
      }

      scope.selectedItem = scope.item;
      scope.description = '';
      scope.fullDescription = '';
      scope.showingFullDesc = false;
      scope.moreDescAvailable = false;
      scope.maxDescWords = 130;

      scope.$watch('headerData', function (headerData) {
        if(headerData){
          scope.setDescription(headerData.description);
        }
      });

      scope.setDescription = desc => {
        scope.fullDescription = desc;
        scope.description = desc.substr(0, scope.maxDescWords);
        scope.moreDescAvailable = desc.length > scope.maxDescWords;
      };

      scope.showMoreDesc = () => {
        if (scope.showingFullDesc) {
          scope.description = scope.fullDescription.substr(0, scope.maxDescWords);
        }
        else {
          scope.description = scope.fullDescription;
        }
        scope.showingFullDesc = !scope.showingFullDesc;
      };

      scope.toggleTopColorsExpandedMode = () => {
        scope.topColorsExpanded = !scope.topColorsExpanded;
      };

      scope.toggleColorFrequencyExpandedMode = function () {
        scope.colorFrequencyExpanded = !scope.colorFrequencyExpanded;
      };

      scope.showMoreItems = () => {
        scope.colorsCount = 100;
        scope.showMoreColors = true;
      };

      scope.hasMoreReportsToShow = () => {
        return scope.colorsCount === 22 && scope.actualColorsForYear;
      };

      scope.toggleAutoPopup = (needShow, brand, index) => {
        scope.selectedBrand = brand;
        if (needShow === null)
          return (scope.showAutoPopup = !scope.showAutoPopup);
        if (brand) scope.selectedRoom = brand;
        scope.yearForPopupAuto = scope.selectedItem.title;
        scope.showAutoPopup = needShow;
        scope.index = index;
      };

      scope.selectColorPopupBox = (needShow, color, index) => {
        if (needShow === null)
          return (scope.showColorPopup = !scope.showColorPopup);
        if (color) {
          scope.selectedColor = color.colors;
          scope.brandForPopup = color.title;
          scope.yearForPopup = scope.selectedItem.title;
          scope.logoForPopup = color.logo_url;
        }
        scope.showColorPopup = needShow;
        scope.index = index;
      };

      scope.isModeModel = () => {
        return scope.viewMode === "model";
      };

      scope.isModeCarBrand = () => {
        return scope.viewMode === "carBrand";
      };

      scope.isModeYear = () => {
        return scope.viewMode === "year";
      };

      scope.isModeColor = () => {
        return scope.viewMode === "color";
      };

      scope.autoChartPopupAttributes = {
        modalTitle: ""
      };

      scope.toggleAutoChartPopup = (needShow, type) => {
        if (needShow) {
          if(type === 'chart1'){
            scope.autoChartPopupAttributes.modalTitle = 'TOP 4 COLORS ACROSS ALL CAR BRANDS FOR ' + scope.selectedItem.title;
            scope.typeOfPopup = type;
          } else if(type === 'chart2'){
            scope.autoChartPopupAttributes.modalTitle = 'COMPARISON OF CAR COLOR FAMILY FOR ' + scope.selectedItem.title + ' AND ALL YEARS';
            scope.typeOfPopup = type;
          } else if(type === 'chart3'){
            scope.autoChartPopupAttributes.modalTitle = 'Actual car colors for ' + scope.selectedItem.title;
            scope.typeOfPopup = type;
          } else if(type === 'chart4'){
            scope.autoChartPopupAttributes.modalTitle = 'TOP 4 COLORS FOR ' + scope.selectedItem.title + ' FOR ' + scope.selectedYear.id;
            scope.typeOfPopup = type;
          } else if(type === 'chart5'){
            scope.autoChartPopupAttributes.modalTitle = 'COMPARISON VIEW OF COLOR FAMILY FREQUENCY FOR ' + scope.selectedItem.title + ' AND THE AUTO INDUSTRY FOR ' + scope.selectedYear.id;
            scope.typeOfPopup = type;
          } else if(type === 'chart6'){
            scope.autoChartPopupAttributes.modalTitle = 'Color family use for over the past 5 years By ' + scope.selectedItem.title;
            scope.typeOfPopup = type;
          } else if(type === 'chart7'){
            scope.autoChartPopupAttributes.modalTitle = 'COLOR USE BY CAR TYPE FOR ' + scope.selectedYear.id;
            scope.typeOfPopup = type;
          } else if(type === 'chart8'){
            scope.autoChartPopupAttributes.modalTitle = 'TOP 4 COLORS FOR ' + scope.selectedItem.title + ' FOR ' + scope.selectedYear.id;
            scope.typeOfPopup = type;
          } else if(type === 'chart9'){
            scope.autoChartPopupAttributes.modalTitle = 'COLOR DISTRIBUTION BY FINISH FOR ' + scope.selectedItem.title;
            scope.typeOfPopup = type;
          } else if(type === 'chart10'){
            scope.autoChartPopupAttributes.modalTitle = 'COMPARISON VIEW OF COLOR FAMILY FREQUENCY FOR ' + scope.selectedItem.title + ' AND THE AUTO INDUSTRY FOR ' + scope.selectedYear.id;
            scope.typeOfPopup = type;
          }
          scope.showAutoChartPopup = true;
        } else {
          scope.showAutoChartPopup = false;
        }
      };

      scope.$on("mood_state_chart", (data) => {
        let newParam = {}
        if(data.targetScope.data){
          let mainData = data.targetScope.data;
          const keys = ["BRAND", "YEAR"];
          newParam = {
            [keys[0]]: mainData.brand,
            [keys[1]]: mainData.year,
          };
        }
        
        let node = angular.element("#autoPopupCapture")[0];
        domtoimage
        .toPng(node, {
          bgcolor: "#fff",
        })
        .then((dataUrl) => {
          if(dataUrl) {
            if(_.isEmpty(newParam)) {
                scope.saveImageDetailsData = {
                  base64: dataUrl,
                  type: 'auto'
                };
              } else {
                scope.saveImageDetailsData = {
                  base64: dataUrl,
                  type: 'auto',
                  data: newParam
                };
              }
            }
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });

          let times = 2000;
          timeout(function () {
            if(scope.saveImageDetailsData) {
              scope.showAutoChartPopup = false;
              scope.showColorPopup = false;
              scope.showAutoPopup = false;
              scope.toggleSaveImageDetails();
            } else {
              timeout(function () {
                if(scope.saveImageDetailsData) {
                  scope.showAutoChartPopup = false;
                  scope.showColorPopup = false;
                  scope.showAutoPopup = false;
                  scope.toggleSaveImageDetails();
                }
              }, times);
            }
          }, times);
      });

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
        result += name.toUpperCase() + ".";
        return result + date + type;
      };

      scope.toggleSaveImageDetails = () => {
        scope.saveImageDetails = scope.saveImageDetails ? false : true;
      };

      scope.$on("mood_state_chart_changed", () => {
        scope.toggleSaveImageDetails();
      });

      scope.$on("mood_state_item_chart_changed", () => {
        scope.toggleSaveImageDetails();
      });
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbAutoColorData/dbAutoColorDataView.html",
      link: link,
      scope: {
        item: "=",
        viewMode: "=",
        headerData: "=",
        topFinishesData: "=",
        topColorsData: "=",
        colorFrequencyData: "=",
        newColorFrequencyData: "=",
        colorPaletteData: "=",
        pastSelectedYear: "=",
        pastColorYear: "=",
        basicColorSelected: "=",
        shadesData: "=",
        mosaicData: "=",
        actualColorsForYear: "=",
        loadingColorsData: "=",
        logo: "=",
        carBrands: "=",
        carTypes: "=",
        selectedYear: "=",
      },
    };
  },
]);
