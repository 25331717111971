angular.module("app").directive("birdFamilyRegionPieChart", function () {
  function link(scope) {
    scope.$watch("selectedItem", bindData);

    function bindData() {
      const element = document.getElementById(`my_bird_family_region_${scope.regionId}_dataviz`);
      if (element) {
        element.innerHTML = "";
      }

      const width = 410;
      const height = 300;
      const margin = 5;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      const svg = d3
        .select(`#my_bird_family_region_${scope.regionId}_dataviz`)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      // Create dummy data
      const data = {
        "mixed": 11,
        "pink": 11,
        "red": 11,
        "brown": 11,
        "darkorange": 11,
        "yellow": 11,
        "lemonchiffon": 11,
        "yellowgreen": 11,
        "green": 11,
        "blue": 11,
        "purple": 11,
        "wheat": 11,
        "black": 11,
        "gray": 11,
        "white": 11,
      };

      const colorData = {
        "mixed": { hex: "#a3d8e4", hex1: "#ca7db2" },
        "pink": { hex: "#f7c6de" },
        "red": { hex: "#e51d1c" },
        "brown": { hex: "#c55531", },
        "darkorange": { hex: "#f07c16" },
        "yellow": { hex: "#fbdf16" },
        "lemonchiffon": { hex: "#f0dc83" },
        "yellowgreen": { hex: "#8db624" },
        "green": { hex: "#0a8236" },
        "blue": { hex: "#335ca7" },
        "purple": { hex: "#6d4595" },
        "wheat": { hex: "#eed5b4", },
        "black": { hex: "#050607" },
        "gray": { hex: "#808081" },
        "white": { hex: "#ffffff" },
      };

      // make gradient color
      for (const key in colorData) {
        if (colorData[key].hex1) {
          const gradientDefs = svg
            .append("defs")
            .append("linearGradient")
            .attr("id", `my_bird_family_region_${scope.regionId}_${key}_color_gradient`);

          gradientDefs
            .append("stop")
            .attr("offset", "5%")
            .attr("stop-color", colorData[key].hex);

          gradientDefs
            .append("stop")
            .attr("offset", "95%")
            .attr("stop-color", colorData[key].hex1);
        }
      }

      // Compute the position of each group on the pie:
      const pie = d3.layout
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value;
        });
      const data_ready = pie(d3.entries(data));

      // The arc generator
      const arc = d3.svg
        .arc()
        .innerRadius(radius * 0.7) // This is the size of the donut hole
        .outerRadius(radius * 0.8);

      const arc2 = d3.svg
        .arc()
        .innerRadius(radius * 0.7)
        .outerRadius(radius * 0.9);

      // Another arc that won't be drawn. Just for labels positioning
      const outerArc = d3.svg
        .arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", function (d) {
          if (colorData[d.data.key].hex1)
            return `url(#my_bird_family_region_${scope.regionId}_${d.data.key}_color_gradient)`;

          return colorData[d.data.key].hex;
        })
        .attr(
          "stroke", function (d) {
            return colorData[d.data.key].hex === "#ffffff"
              ? "#E0E0DD"
              : colorData[d.data.key].hex;
          }
        )
        .style(
          "stroke-width",  function (d) {
            return colorData[d.data.key].hex === "#ffffff"
              ? "1px"
              : "0px"
          }
        );

      // Add the polylines between chart and labels:
      svg
        .selectAll("allPolylines")
        .data(data_ready)
        .enter()
        .append("polyline")
        .attr("stroke", "black")
        .style("fill", "none")
        .attr("stroke-width", 1)
        .attr("points", function (d) {
          const posA = arc2.centroid(d); // line insertion in the slice
          const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
          const posC = outerArc.centroid(d); // Label position = almost the same as posB
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
          posC[0] = radius * 1 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left

          return [posA, posB, posC];
        });

      svg
        .selectAll("allLabels")
        .data(data_ready)
        .enter()
        .append("text")
        .text((d) => `${d.data.value}%`)
        .attr("transform", function (d) {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 1.05 * (midangle < Math.PI ? 1 : -1);
          pos[1] += 5;
          return "translate(" + pos + ")";
        })
        .attr("font-size", "13px")
        .attr("font-weight", "300")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B")
        .style("text-anchor", function (d) {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midangle < Math.PI ? "start" : "end";
        });

      svg
        .append("text")
        .text("495")
        .attr("transform", "translate(0, -5)")
        .attr("font-size", "25px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B");

      svg
        .append("text")
        .text("BIRD FAMILIES IN")
        .attr("transform", "translate(0, 20)")
        .attr("font-size", "17px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B");

      svg
        .append("text")
        .text("DATABASE")
        .attr("transform", "translate(0, 40)")
        .attr("font-size", "17px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B");
    }
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/dbBirdFamilyRegionPieChart/dbBirdFamilyRegionPieChartView.html",
    link: link,
    scope: {
      selectedItem: "=",
      regionId: "=",
    },
  };
});
