angular.module("app").directive("pharmaPieCharts", function () {
  function link(scope) {
    const width = 1000;
    const height = 500;
    const margin = 40;
    const selectedColor = scope.selectedColor.hexvalue;

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    const radius = Math.min(width, height) / 2 - margin;

    // append the svg object to the div called 'my_pharma_dataviz'
    const svg = d3
      .select("#my_pharma_dataviz")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    // Create dummy data
    const data = {
      "CNS/NEUROLOGY": 11,
      "CARDIOVASCULAR": 14,
      "DERMATOLOGY": 2,
      "ENDOCRINOLOGY": 10,
      "GASTROENTEROLOGY": 7,
      "HEMATOLOGY": 7,
      "INFECTIOUS": 1,
      "PSYCHIATRY": 6,
      "RESPIRATORY": 7,
      "UROLOGY": 2,
      "PAIN": 6,
      "WOMEN'S HEALTH": 1,
      "ONCOLOGY": 6,
      "RHEUMATOLOGY": 2,
      "OTHER": 3,
      "AUTOIMMUNE": 11
    };

    const dataImageUrls = {
      "CNS/NEUROLOGY": "assets/img/therapy/cns.png",
      "CARDIOVASCULAR": "assets/img/therapy/cardiovascular.png",
      "DERMATOLOGY": "assets/img/therapy/dermatology.png",
      "ENDOCRINOLOGY": "assets/img/therapy/endocrinology.png",
      "GASTROENTEROLOGY": "assets/img/therapy/gastroenterology.png",
      "HEMATOLOGY": "assets/img/therapy/hematology.png",
      "INFECTIOUS": "assets/img/therapy/infectious.png",
      "PSYCHIATRY": "assets/img/therapy/psychiatry.png",
      "RESPIRATORY": "assets/img/therapy/respiratory.png",
      "UROLOGY": "assets/img/therapy/urology.png",
      "PAIN": "assets/img/therapy/pain.png",
      "WOMEN'S HEALTH": "assets/img/therapy/women-health.png",
      "ONCOLOGY": "assets/img/therapy/oncology.png",
      "RHEUMATOLOGY": "assets/img/therapy/rheumatology.png",
      "OTHER": "assets/img/therapy/other.png",
      "AUTOIMMUNE": "assets/img/therapy/autoimmune.png"
    };

    // Compute the position of each group on the pie:
    const pie = d3.layout
      .pie()
      .sort(null) // Do not sort group by size
      .value(function (d) {
        return d.value;
      });
    const data_ready = pie(d3.entries(data));

    // The arc generator
    const arc = d3.svg
      .arc()
      .innerRadius(radius * 0.7) // This is the size of the donut hole
      .outerRadius(radius * 0.8);

    // Another arc that won't be drawn. Just for labels positioning
    const outerArc = d3.svg
      .arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9);

    // Add the polylines between chart and labels:
    svg
      .selectAll("allPolylines")
      .data(data_ready)
      .enter()
      .append("polyline")
      .attr("stroke", "black")
      .style("fill", "none")
      .attr("stroke-width", 1)
      .attr("points", function (d) {
        const posA = arc.centroid(d); // line insertion in the slice
        const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
        const posC = outerArc.centroid(d); // Label position = almost the same as posB
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
        posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left

        if (Math.abs(Math.PI - midangle) < Math.PI / 10) {   // fix offset
          posB[1] += 20;
          posC[1] += 20;
        }
        if (midangle < Math.PI / 10) {
          posB[1] -= 20;
          posC[1] -= 20;
        }
        if (Math.PI * 2 - midangle < Math.PI / 10) {
          posB[1] -= 20;
          posC[1] -= 20;
        }

        return [posA, posB, posC];
      });

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll("allSlices")
      .data(data_ready)
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", scope.selectedColor.hex)
      .attr(
        "stroke",
        scope.selectedColor.title === "White" ? "#E0E0DD" : "white"
      )
      .style(
        "stroke-width",
        scope.selectedColor.title === "White" ? "1px" : "5px"
      );

    // Add the polylines between chart and labels:
    svg
      .selectAll("allLabels")
      .data(data_ready)
      .enter()
      .append("text")
      .text(function (d) {
        return d.data.key;
      })
      .attr("transform", function (d) {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = (radius * 1.3 + 110) * (midangle < Math.PI ? 1 : -1);
        // fix offset
        if (Math.abs(Math.PI - midangle) < Math.PI / 10) {
          pos[1] += 20;
        }
        if (midangle < Math.PI / 10) {
          pos[1] -= 20;
        }
        if (Math.PI * 2 - midangle < Math.PI / 10) {
          pos[1] -= 20;
        }

        return "translate(" + pos + ")";
      })
      .attr("font-size", "12px")
      .style("text-anchor", function (d) {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? "end" : "start";
      });

    svg
      .selectAll("allLabels")
      .data(data_ready)
      .enter()
      .append("text")
      .text((d) => `${d.data.value}%`)
      .attr("transform", function (d) {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
        // fix offset
        if (Math.abs(Math.PI - midangle) < Math.PI / 10) {
          pos[1] += 20;
        }
        if (midangle < Math.PI / 10) {
          pos[1] -= 20;
        }
        if (Math.PI * 2 - midangle < Math.PI / 10) {
          pos[1] -= 20;
        }
        return "translate(" + pos + ")";
      })
      .attr("font-size", "16px")
      .style("text-anchor", function (d) {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? "start" : "end";
      });

    // Add the label Images:
    svg
      .selectAll("allLabelImagess")
      .data(data_ready)
      .enter()
      .append("image")
      .attr("href", function (d) {
        return dataImageUrls[d.data.key];
      })
      .attr("width", 24)
      .attr("height", 24)
      .attr("transform", function (d) {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = radius * 1.85 * (midangle < Math.PI ? 1 : -1);
        pos[0] += midangle < Math.PI ? 0 : -25;
        // fix offset
        if (Math.abs(Math.PI - midangle) < Math.PI / 10) {
          pos[1] += 20;
        }
        if (midangle < Math.PI / 10) {
          pos[1] -= 20;
        }
        if (Math.PI * 2 - midangle < Math.PI / 10) {
          pos[1] -= 20;
        }
        pos[1] = pos[1] - 16;

        return "translate(" + pos + ")";
      });

    svg
      .append("text")
      .text("OVERALL PINK WAS USED IN ")
      .attr("transform", "translate(0, -50)")
      .attr("font-size", "16px")
      .attr("style", "text-anchor: middle");

    svg
      .append("text")
      .text("8%")
      .attr("transform", "translate(0, 0)")
      .attr("font-size", "40px")
      .attr("style", "text-anchor: middle");

    svg
      .append("text")
      .text("OF PILLS")
      .attr("transform", "translate(0, 35)")
      .attr("font-size", "16px")
      .attr("style", "text-anchor: middle");

    svg
      .append("text")
      .text("ACROSS ALL THERAPY AREAS")
      .attr("transform", "translate(0, 56)")
      .attr("font-size", "16px")
      .attr("style", "text-anchor: middle");

    scope.$watch('selectedColor', () => {
      svg
        .selectAll("path")
        .remove();

      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", scope.selectedColor.hex)
        .attr(
          "stroke",
          scope.selectedColor.title === "White" ? "#E0E0DD" : "white"
        )
        .style(
          "stroke-width",
          scope.selectedColor.title === "White" ? "1px" : "5px"
        );

    })
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/pieChartPharma/pieChartPharmaView.html",
    link: link,
    scope: {
      selectedColor: "=",
    },
  };
});
