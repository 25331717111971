angular.module('app').directive('artColorCombinationArt', () => {
  let link = (scope) => {
    scope.getWidth = function (index) {
      let totalPercentage = 0;
      scope.data.forEach(item => {
        const percent = item.percentage.replace('%', '');
        if(typeof(percent) == "string") {
          totalPercentage += parseFloat(percent);
        } else {
          totalPercentage += percent;
        }
      });
      let indexPercentage = scope.data[index].percentage.replace('%', '');
      if(totalPercentage > 0) {
        return parseFloat((100 / totalPercentage) * indexPercentage) + '%';
      } else {
        return '25%';
      }
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/artColorCombinationArt/artColorCombinationArtView.html",
    link: link,
    scope: {
      data: "=",
      viewMode: "=",
      title: "="
    }
  };
});
