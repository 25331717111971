angular.module("app").factory("colorNamingIndexServiced",
  function (httpService, appConfig, authService) {
    return {
        colorNamingIndexBubble: function (color) {
            return httpService
                .get(`${appConfig.colorNamingIndex}attributes/${color}/graph`, authService.getHeaderConfig())
                .then(function (rgbs) {
                    let arr = []
                    if(rgbs.data.limit === 'exceed'){
                        return rgbs.data;
                      } else {
                        rgbs.data.map(rgb => {
                            arr.push({
                                name: rgb.color_family,
                                color: rgb.color_hex,
                                count: rgb.count,
                                percentage: rgb.percentage,
                                R: parseInt(rgb.r),
                                G: parseInt(rgb.g),
                                B: parseInt(rgb.b),
                            })
                        })
                        return arr;
                      }
                });
        },
        headerAttributeData: function(color){
            return httpService
                .get(`${appConfig.colorNamingIndex}attributes/${color}/header`, authService.getHeaderConfig())
                .then(function (attr) {
                    return attr.data;
            });
        },
        getColorList: function(){
            return httpService
                .get(`${appConfig.colorNamingIndex}colors`, authService.getHeaderConfig())
                .then(function (attr) {
                    return attr.data;
            });
        },
        colorSearchHeader: function(id){
            return httpService
                .get(`${appConfig.colorNamingIndex}${id}/header`, authService.getHeaderConfig())
                .then(function (attr) {
                    return attr.data;
            });
        },
        colorSearchCloud: function(id){
            return httpService
                .get(`${appConfig.colorNamingIndex}${id}/words/cloud`, authService.getHeaderConfig())
                .then(function (attr) {
                    
                    let colorObj = attr.data
                    let cloudArr = []

                    Object.keys(colorObj).map(dat => {
                        cloudArr.push({ text: colorObj[dat].colorName, weight:  colorObj[dat].weight, colorData: colorObj[dat]})
                    })
                    return cloudArr;
            });
        },
        colorSearchLetter: function(id,letter){
            return httpService
                .get(`${appConfig.colorNamingIndex}${id}/words/alphabetical/${letter}`, authService.getHeaderConfig())
                .then(function (attr) {
                    
                    let colorObj = attr.data
                    let cloudArr = []

                    Object.keys(colorObj).map(dat => {
                        cloudArr.push({ text: dat, weight: colorObj[dat] })
                    })
                    return cloudArr;
            });
        },
        colorClosest: function(rgb){

            return httpService
                .get(`${appConfig.colorNamingIndex}closest/${rgb}`, authService.getHeaderConfig())
                .then(function (attr) {
                    return attr.data;
            });
        },

        colorClosestRGB: function(rgb, mode){
            var orderMode = 'a-z';
            if(mode === true){
                orderMode = 'hue';
            };
            return httpService
                .get(`${appConfig.colorNamingIndex}closest_rgb/${rgb}?order_by=${orderMode}`, authService.getHeaderConfig())
                .then(function (attr) {
                    return attr.data;
            });
        },

        colorClosestRGBStatus: function(rgb){
            return httpService
                .get(`${appConfig.colorNamingIndex}closest_rgb_status/${rgb}`, authService.getHeaderConfig())
                .then(function (attr) {
                    return attr.data;
            });
        },

        relevantColorSearchById: function(id){
            return httpService
            .get(`${appConfig.colorNamingIndex}/${id}/relevant.json`, authService.getHeaderConfig())
            .then(function (attr) { 
                return attr.data;
            });
        },

        relevantColorSearchByWord: function(letter, mode, page){ 
            var url = '';
            if(mode === 'A-Z'){
                url = `${appConfig.colorNamingIndex}attributes/${letter}/relevant?page=${page}&per_page=60&relevant=true&order=alphabetically`;
            } else if (mode === 'ALL') {
                url = `${appConfig.colorNamingIndex}attributes/${letter}/relevant?page=${page}&per_page=60&relevant=true`;
            } else {
                url = `${appConfig.colorNamingIndex}attributes/${letter}/relevant?page=${page}&per_page=60&relevant=true&color_id=${mode.id}`;
            }
            return httpService
                .get(url, authService.getHeaderConfig())
                .then(function (attr) {  
                    return attr.data;
            });
        },

        extraColorSearchByWord: function(letter, mode, page){ 
            var url = '';
            if(mode === 'A-Z'){
                url = `${appConfig.colorNamingIndex}attributes/${letter}/relevant?page=${page}&per_page=60&relevant=false&order=alphabetically`;
            } else if (mode === 'ALL') {
                url = `${appConfig.colorNamingIndex}attributes/${letter}/relevant?page=${page}&per_page=60&relevant=false`;
            } else {
                url = `${appConfig.colorNamingIndex}attributes/${letter}/relevant?page=${page}&per_page=60&relevant=false&color_id=${mode.id}`;
            }
            return httpService
                .get(url, authService.getHeaderConfig())
                .then(function (attr) {  
                    return attr.data;
            });
        },
        
    };
  }
);