(function () {
  angular.module('app').directive('hueChartByUsingRedAndBlue', [
    function () {
      function link(scope, element) {
        const vm = this;
        const bindData = () => {
          vm.categoryData = [
            [
              {color: '#e13229', value: 48},
              {color: '#009fe3', value: 52}
            ],
            [
              {color: '#e13229', value: 38},
              {color: '#009fe3', value: 62}
            ],
            [
              {color: '#e13229', value: 28},
              {color: '#009fe3', value: 72}
            ],
            [
              {color: '#e13229', value: 22},
              {color: '#009fe3', value: 78}
            ],
            [
              {color: '#e13229', value: 18},
              {color: '#009fe3', value: 88}
            ]
          ];

          scope.imgLink = [
            '../../../assets/img/icons/infographics/pasted-image-14.png',
            '../../../assets/img/icons/infographics/pasted-image-16.png',
            '../../../assets/img/icons/infographics/pasted-image-24.png',
            '../../../assets/img/icons/infographics/pasted-image-24.png',
            '../../../assets/img/icons/infographics/pasted-image-14.png'
          ];

          vm.values = [
            48, 38, 28, 22, 18
          ];

          vm.titles = ['Airlines', 'Auto', 'Electronics', 'Electronics', 'Airlines'];
          const containers = element.find('[chart-block]');
          _.each(containers, (c, i) => {
            const container = $(c);

            const svg = d3.select(container[0])
              .append('svg')
              .attr('width', '150px')
              .attr('height', '450px');

            const arc = d3.svg.arc()
              .innerRadius(50)
              .outerRadius(60)
              .cornerRadius(70);

            const maxValue = Math.max.apply(null, vm.values);
            const lineHeight = -250 * (vm.values[i] / 100);
            const g = svg.append('g').attr('transform', 'translate(' + 75 + ',' + 380 + ')');
            const g0 = g.append('g').attr('transform', 'translate(' + 0 + ',' + -65 + ')');
            const g1 = g.append('g');

            const pie = d3.layout.pie()
              .padAngle(-0.1)
              .value(d => d.value);
            g1.selectAll('path')
              .data(pie(vm.categoryData[i]))
              .enter()
              .append('path')
              .style('fill', d => d.data.color)
              .attr('d', arc);
            g1.append('text')
              .text(vm.titles[i])
              .attr('text-anchor', 'middle')
              .attr('y', 30);

            g0.append('line')
              .style({stroke: '#000', 'stroke-width': 1})
              .attr('x1', 0)
              .attr('x2', 0)
              .attr('y1', 0)
              .attr('y2', lineHeight);
            g0.append('line')
              .style({stroke: '#000', 'stroke-width': 1})
              .attr('x1', -6)
              .attr('x2', 6)
              .attr('y1', lineHeight)
              .attr('y2', lineHeight);
            g0.append('text')
              .text(`${vm.values[i]}%`)
              .attr('text-anchor', 'middle')
              .attr('y', lineHeight - 10);
          });
        };

        bindData();
      }

      const directive = {
        link: link,
        restrict: 'E',
        replace: true,
        scope: {
          // data: '=',
        },
        templateUrl: 'app/directives/custom-infographics-brand-directives/chart-by-using-red-and-blue.html'
      };

      return directive;
    }
  ]);
})();
