angular.module('app').controller('brandingController', [
  '$scope',
  'brandingDashboardRepository',
  'dashboardOverlayService',
  'searchMenuRepository',
  '$state',
  'anchorSmoothScroll',
  '$location',
  'localStorageService',
  'modalService',
  function (
    scope,
    brandingDashboardRepository,
    dashboardOverlayService,
    searchMenuRepository,
    $state,
    anchorSmoothScroll,
    $location,
    localStorageService,
    modalService
  ) {
    scope.gotoElement = eID => {
      $location.hash('prefooter');
      anchorSmoothScroll.scrollTo(eID);
      $location.hash('');
    };

    scope.selectedMenuId = 1;
    scope.selectedMenuAnalysisId = 1;
    scope.isMenuAnalysis = true;
    scope.selectedBrandName = '';
    scope.tab = 1;
    scope.menus = {
      color: '',
      brand: '',
      industry: '',
      attribute: ''
    };
    scope.currentPage = 0;
    scope.selectedpage = 1;
    scope.oldsearchdata = "";
    scope.text = '';
    scope.minlength = 1;
    scope.selected = {};
    scope.brand_place_holder = 'SEARCH BY BRAND';
    scope.industry_place_holder = 'SEARCH BY INDUSTRY';
    scope.attribute_place_holder = 'SEARCH BY ATTRIBUTE';
    scope.disabledControls = {
      brand: false,
      industry: false,
      color: false,
      attribute: false
    };

    scope.type = {
      color: 'color',
      brand: 'company',
      industry: 'industry',
      attribute: 'attribute'
    };

    scope.mainParam = null;
    scope.mainParamId = null;
    scope.secondaryParams = {};
    scope.logoId = null;
    scope.industryId = null;

    scope.showDashboard = false;
    scope.title = null;
    scope.iconUrl = null;
    scope.colorHex = '#fff';
    scope.topIndustriesColor = '#ccc';
    scope.activeColorFrequencyColor = 0;

    scope.isLoadingControls = true;
    scope.brandTitle = '';
    scope.header_title = '';
    scope.colors = [];
    scope.filterColor = {};
    scope.logo_title = '';
    scope.ajaxInProgress = false;

    scope.brands = [];
    scope.filterBrand = {};

    scope.attribs = [];
    scope.filterAttrib = {};

    scope.industries = [];
    scope.filterIndustry = {};

    if(localStorageService.get("currentUser")){
      scope.user = localStorageService.get("currentUser");
    }
    scope.menu = [
      { id: 1, title: "SINGLE COLOR" },
      { id: 2, title: "MULTIPLE COLORS" },
    ];
    scope.isSetId = function (id) {
      return scope.selectedMenuId === id;
    };
    scope.setMenuId = function (id) {
      scope.selectedMenuId = id;
      scope.currentPage = 0;
      scope.loadDataByQuery();
    };

    scope.menuAnalysis = [
      { id: 1, title: "BRANDS" },
      { id: 2, title: "UNICORNS" },
    ];
    scope.isSetAnalysisId = function (id) {
      return scope.selectedMenuAnalysisId === id;
    };
    scope.setMenuAnalysisId = function (id) {
      if (id === 1){
        scope.isMenuAnalysis = true;
      } else {
        scope.isMenuAnalysis = false;
      }
      scope.selectedMenuAnalysisId = id;
    };

    scope.setTab = tabId => {
      scope.tab = tabId;
      scope.select_images = false;
      scope.selected_moodboard = false;
      scope.state = 1;
    };

    scope.isSet = tabId => {
      return scope.tab === tabId;
    };

    scope.setback = () => {
      scope.mainParam = '';
      scope.disableAttribute = false;
      scope.disableIndustry = false;
      scope.disableBrand = false;
      scope.isLoadingControls = true;
      $location.url($location.path());
    };

    scope.eraseMenus = () => {
      scope.menus = {
        color: '',
        brand: '',
        industry: '',
        attribute: ''
      };
    };

    scope.loadGraphics = () => {
      if (scope.mainParam) {
        scope.showDashboard = true;
        dashboardOverlayService.loadingStart();
      }
    };

    // scope.loadColors = () => {
    //   brandingDashboardRepository.color.getColorsList().then((data) => {
    //     scope.colors = data.colors;
    //     scope.filterColor = scope.colors[0] ? scope.colors[0] : '';
    //   });
    // };
    //
    // scope.loadColors();

    scope.loadSettings = () => {
      scope.ajaxInProgress = true;
      brandingDashboardRepository.dashboard.getSettings().then(data => {
        scope.colors = data.colors;
        scope.industryList = data.industries;
        // scope.filterColor = scope.colors[0] ? scope.colors[0] : '';

        // scope.brands = data.brands;
        // scope.filterBrand = scope.brands[0] ? scope.brands[0] : '';
        //
        // scope.attribs = data.attributes;
        // scope.filterAttrib = scope.attribs[0] ? scope.attribs[0] : '';
        //
        // scope.industries = data.industries;
        // scope.filterIndustry = scope.industries[0] ? scope.industries[0] : '';

        if ($location.search() !== $location.path()) {
          scope.loadDataByQuery();
        }
      }).finally(() => {
        scope.ajaxInProgress = false;
      });
      brandingDashboardRepository.attribute.get38Color().then(data => {
        scope.colorList = data;
      });
    };

    scope.loadSettings();

    scope.disabledInput = (type, value) => {
      switch (type) {
        case 'companies':
          scope.disableAttribute = Boolean(value);
          scope.disableIndustry = Boolean(value);
          break;
        case 'industries':
          scope.disableAttribute = Boolean(value);
          scope.disableBrand = Boolean(value);
          break;
        case 'attributors':
          scope.disableBrand = Boolean(value);
          scope.disableIndustry = Boolean(value);
          break;
      }
    };

    scope.colorDataFetch = false;

    scope.loadDataByQuery = () => {
      scope.ajaxInProgress = true;
      const searchQuery = $location.search();
      const control = Object.keys(searchQuery)[0];
      const id = Object.values(searchQuery)[0];
      scope.searchTitle = Object.values(searchQuery)[1];
      // if (!control) {
      //   return searchMenuRepository.getControlsDataBranding().then(data => {
      //     scope.controlsData = data;
      //     for (let item in scope.controlsData.attributes) {
      //       scope.controlsData.attributes[item].index = item;
      //     }
      //     for (let item in scope.controlsData.companies) {
      //       scope.controlsData.companies[item].index = item;
      //     }
      //     scope.isLoadingControls = true;
      //   }).finally(() => {
      //     scope.ajaxInProgress = false;
      //   });
      // }

      scope.searchId = id;
      scope.searchMode = control;
      scope.control = control;
      if (!scope.mainParam) {
        scope.mainParam = control;
        scope.mainParamId = scope.menus[control];
      }

      scope.params = {
        id: scope.searchId,
        page: scope.currentPage + 1,
        per_page: 50
      };

      if (scope.searchMode === 'industry') {
        scope.ajaxInProgress = true;
        brandingDashboardRepository.industry
          .getPageData(scope.searchId)
          .then(data => {
            scope.pageData = data.industryHeaderData;
            scope.header_title = 'INDUSTRY: ' + scope.pageData.title;
            scope.eraseMenus();
            scope.isLoadingControls = false;
            scope.title1 = scope.pageData.title;
          }).finally(() => {
            scope.ajaxInProgress = false;
          });
        //brandingDashboardRepository.industry
          //.getPageDataNewApi(scope.params)
          brandingDashboardRepository.industry
          .getIndustryLogoDataNewApi(scope.params, scope.selectedMenuId)
          .then((data) => {
            if(data.limit === 'exceed'){
              modalService.showModal(11, null, scope.user.first_name);
            } else {
              scope.pageCount = data.paginate.total_pages;
              scope.logo = data.companies;
            } 
            scope.isLoadingControls = false;
          }).finally(() => {
            scope.ajaxInProgress = false;
          });
      } else if (scope.searchMode === 'attribute') {
        scope.ajaxInProgress = true;
        // brandingDashboardRepository.attribute
        //   .getPageData(scope.searchTitle)
        //   .then(data => {
        //     scope.pageData = data.attributeHeaderData;
            scope.header_title = 'ATTRIBUTE: ' + scope.searchTitle;
            brandingDashboardRepository.attribute
              .getPageDataNewApi(scope.params, scope.selectedMenuId)
              .then(data => {
                if(data.limit === 'exceed'){
                  modalService.showModal(11, null, scope.user.first_name);
                } else {
                  if(_.isEmpty(data)) {
                    scope.pageCount = 0;
                    scope.logo = [];
                  } else {
                    scope.pageCount = data && data.paginate && data.paginate.total_pages ? data.paginate.total_pages : 0;
                    scope.logo = data.companies;
                  }
                } 
                scope.isLoadingControls = false;
              // });
          }).finally(() => {
            scope.ajaxInProgress = false;
          });
      } else if (scope.searchMode === 'company') {
        scope.ajaxInProgress = true;
        scope.isLoadingControls = true;
        brandingDashboardRepository.brand
          .getPageData(scope.searchId)
          .then(data => {
            scope.pageData = data.companyHeaderData;
            scope.eraseMenus();
            scope.isLoadingControls = false;
            if (scope.control == 'company') {
              scope.control = 'brand';
            }
            scope.title1 = scope.pageData.industry;
            brandingDashboardRepository.brand
              .getPageDataNewApi(scope.params, scope.selectedMenuId)
            // brandingDashboardRepository.brand
            //   .getCompanyLogoDataApi(scope.params)
              .then(data => {
                if(data.limit === 'exceed'){
                  modalService.showModal(11, null, scope.user.first_name);
                } else {
                  scope.pageCount = data.paginate.total_pages;
                  scope.logo = data.companies;
                  scope.brandTitle = scope.logo.length > 0 ? scope.logo[0].title : '';
                  if (scope.brandTitle){
                    scope.header_title = 'BRAND: ' + scope.brandTitle;
                  } else {
                    scope.header_title = 'BRAND: ';
                  }
                } 
                scope.isLoadingControls = false;
              });
            // this.getControlsDataBranding();
          }).finally(() => {
            scope.ajaxInProgress = false;
          });
      } else if (scope.searchMode === 'color') {
        scope.ajaxInProgress = true;
        if (!scope.colorDataFetch) {
          scope.colorDataFetch = true;
          brandingDashboardRepository.color
            .getPageDataNewApi(scope.params, scope.selectedMenuId)
            .then(data => {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.pageData = data;
                scope.logo = data.companies;
                scope.pageCount = data.paginate.total_pages;
              } 
              
              const colorId = $location.search().color;
              scope.filterColor = _.filter(scope.colors, {id: Number(colorId)});
              scope.filterColor = scope.filterColor.length ? scope.filterColor[0] : {};
              if (scope.filterColor.hexvalue) {
                scope.filterColor.hex = scope.filterColor.hexvalue;
              }
              scope.header_title = `COLOR: ${
                scope.filterColor.title ? scope.filterColor.title : ''
              }`;
              scope.eraseMenus();
              scope.isLoadingControls = false;
              scope.colorDataFetch = false;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
        }
      }

      this.getControlsDataBranding = () => {
        scope.menus[control] = scope.pageData.title;
        scope.eraseMenus();
        scope.isLoadingControls = false;
        searchMenuRepository
          .getControlsDataBrandingBind(control, id, {
            page: 1,
            per_page: 15
          })
          .then(data => {
            scope.logo = data.logo_colors;
            scope.isLoadingControls = false;
            if (scope.control == 'company') {
              scope.control = 'brand';
            }
            scope.title1 = scope.pageData.title;
          }).finally(() => {
            scope.ajaxInProgress = false;
          });
      };

      switch (scope.mainParam) {
        case 'company':
          scope.pageInfo = scope.brandPageInfo;
          scope.mainParamId = id;
          scope.disabledControls = {
            brand: false,
            industry: true,
            color: true,
            attribute: true
          };
          break;
        case 'industry':
          scope.pageInfo = scope.industryPageInfo;
          scope.mainParamId = id;
          scope.disabledControls = {
            brand: true,
            industry: false,
            color: true,
            attribute: true
          };
          break;
        case 'color':
          scope.pageInfo = scope.colorPageInfo;
          scope.mainParamId = id;
          scope.disabledControls = {
            brand: true,
            industry: true,
            color: false,
            attribute: true
          };
          break;
        case 'attribute':
          scope.pageInfo = scope.attributePageInfo;
          scope.mainParamId = id;
          scope.disabledControls = {
            brand: true,
            industry: true,
            color: true,
            attribute: false
          };
          break;
        default:
          scope.disabledControls = {
            brand: false,
            industry: false,
            color: false,
            attribute: false
          };

          scope.mainParam = null;
          scope.iconUrl = null;
          scope.showDashboard = false;

          scope.compareData = [];
          scope.colorsCountData = [];
          scope.colorsCountData = [];
          scope.shadesData = [];
          scope.topFamiliesData = [];
          scope.topBrandsData = [];
          scope.topColorsData = [];
          scope.colorFrequencyData = [];
          scope.topFinishesData = [];
          scope.carColorsData = [];
          scope.colorPaletteData = [];
          break;
      }
      scope.loadGraphics();
    };

    scope.getResultsOnFilterSearch = (type, str, page) => {
      scope.ajaxInProgress = true;
      switch (type) {
        case 'industry':
          if (!str.length) {
            scope.industries = [];
            scope.ajaxInProgress = false;
            return;
          }
          brandingDashboardRepository.industry
            .getIndustryData()
            .then(data => {
              scope.industries = data;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break;
        case 'brand':
          if (!str.length) {
            scope.brands = [];
            scope.ajaxInProgress = false;
            return;
          }
          if(scope.oldsearchdata === ""){
            scope.oldsearchdata = str;
            scope.selectedpage = 1;
          } 
          else if(scope.oldsearchdata !== str){  
              page = 1;
              scope.selectedpage = page;
              scope.brands = [];
              scope.oldsearchdata = str;
          } 
          else{
            scope.selectedpage += 1;
            page = scope.selectedpage;
          }
          brandingDashboardRepository.brand
            .getFilterData(str, page)
            .then(data => {
              if(scope.brands.length>0){  
                scope.brands = scope.brands.concat(data);
              }
              else{
                scope.brands = data;
              }
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break;
        case 'attribute': 
          if (!str.length) {
            scope.attribs = [];
            scope.ajaxInProgress = false;
            return;
          }
          //new logic for pagination
          if(scope.oldsearchdata === ""){
            scope.oldsearchdata = str;
            scope.selectedpage = 1;
          } 
          else if(scope.oldsearchdata !== str){  
              page = 1;
              scope.selectedpage = page;
              scope.attribs = [];
              scope.oldsearchdata = str;
          } 
          else{
            scope.selectedpage += 1;
            page = scope.selectedpage;
          }
          brandingDashboardRepository.attribute
            .getFilterData(str,page)
            .then(data => {  
              if(scope.attribs.length>0){  
                scope.attribs = scope.attribs.concat(data);
              }
              else{
                scope.attribs = data;
              }
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break;
        default:
          console.log('Type not identified');
          scope.ajaxInProgress = false;
      }
    };

    scope.setPage = num => {
      if (num >= 0 && num < scope.pageCount) {
        scope.currentPage = num;
        scope.loadDataByQuery();
      }
    };

    // Pages info
    scope.colorPageInfo = [
      {
        width: 2,
        type: 'desc',
        tooltip: '#description',
        data: {text: null}
      },
      {
        width: 1,
        type: 'countTo',
        tooltip: '#industries',
        data: {subtitle: 'Industries', count: 0, menuTab: 'industry'}
      },
      {
        width: 1,
        type: 'countTo',
        tooltip: '#brands',
        data: {subtitle: 'Brands', count: 0, menuTab: 'brand'}
      }
    ];

    scope.brandPageInfo = [
      {
        width: 3,
        type: 'desc',
        tooltip: '#description',
        data: {text: null}
      },
      {
        width: 1,
        type: 'title',
        classes: 'cell-clickable',
        data: {title: null, subtitle: null}
      }
    ];

    scope.industryPageInfo = [
      {
        width: 2,
        type: 'desc',
        tooltip: '#description',
        data: {text: null}
      },
      {
        width: 1,
        type: 'countTo',
        tooltip: '#brands',
        data: {subtitle: 'Brands', count: 0, menuTab: 'brand'}
      },
      {
        width: 1,
        type: 'countTo',
        tooltip: '#colors',
        data: {subtitle: 'Colors', count: 0, menuTab: 'color'}
      }
    ];

    scope.attributePageInfo = [
      {
        width: 2,
        type: 'desc',
        tooltip: '#description',
        data: {text: null}
      },
      {
        width: 1,
        type: 'countTo',
        tooltip: '#colors',
        data: {subtitle: 'Colors', count: 0, menuTab: 'color'}
      },
      {
        width: 1,
        type: 'countTo',
        tooltip: '#brands',
        data: {subtitle: 'Brands', count: 0, menuTab: 'brand'}
      }
    ];

    scope.topColorsData = [];
    scope.colorFrequencyData = [];
    scope.logosData = [];
    scope.mapsData = [];

    scope.colorPaletteBucket = 38;

    if (!scope.mainParam) {
      $state.go('branding');
    }

    scope.changeColorPaletteBucket = value => {
      if (value !== scope.colorPaletteBucket) {
        brandingDashboardRepository[scope.mainParam]
          .getColorPalette(scope.mainParamId, value)
          .then(data => {
            scope.colorPaletteData = data;
          }).finally(() => {
            scope.ajaxInProgress = false;
          });
      }
      scope.colorPaletteBucket = value;
    };

    scope.isColorFrequencyColorPickerVisible = () => {
      return scope.colorFrequencyData.length > 1;
    };

    scope.setColorAsMain = color => {
      scope.iconUrl = null;
      scope.secondaryParams = {};
      scope.mainParam = null;
      scope.tempColor = color;
      scope.menus = {
        brand: '',
        industry: '',
        color: '',
        attribute: ''
      };
      scope.menus.color = color.id;
      if (
        !scope.controlsData.colors.find(item => {
          return item.id === scope.tempColor.id;
        })
      ) {
        scope.controlsData.colors.unshift(scope.tempColor);
      }
      scope.tempColor = null;
      scope.loadGraphics();
    };

    scope.handleChangeControl = (control, choice) => {
      // let filteredData = scope.colors.filter(color =>
      //   color.id === choice.id
      // );
      // scope.filterColor = filteredData.length ? filteredData[0] : {};
      scope.selectedBrandName = choice;
      $location.search(control, choice.id || choice.title || choice);
      $location.search('title', choice.title || choice.name);
      scope.loadDataByQuery();
    };

    scope.$watch(
      () => {
        return dashboardOverlayService.showOverlay;
      },
      newValue => {
        scope.showDashboardOverlay = newValue;
      }
    );
  },
]);
