angular.module("app").config(routesConfig);

function routesConfig($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.otherwise("/");

  $stateProvider.state("aboutPage", {
    url: "/about-huedata",
    component: "aboutPage",
  });

  $stateProvider.state("aboutUs", {
    url: "/about-us",
    component: "aboutUsComponent",
  });

  $stateProvider.state("login", {
    url: "/login",
    component: "loginComponent",
  });

  $stateProvider.state("sign-up", {
    url: "/sign-up",
    component: "signUpComponent",
  });

  $stateProvider.state("school-sign-up", {
    url: "/school-sign-up",
    component: "studentStaffSignUpComponent",
  });

  $stateProvider.state("single-sign-on", {
    url: "/single-sign-on/:success/:token",
    component: "studentSingleSignOnComponent",
  });

  $stateProvider.state("school-enterprise-login", {
    url: "/school-enterprise-login",
    component: "studentStaffLoginComponent",
  });

  // $stateProvider.state("schoolRegister", {
  //   url: "/school-register",
  //   component: "schoolRegisterComponent",
  // });

  $stateProvider.state("speakingEngagements", {
    url: "/huedata-speaking-engagements",
    component: "speakingEngagementsComponent",
  });

  $stateProvider.state("press", {
    url: "/huedata-press-inquiry",
    component: "pressComponent",
  });

  $stateProvider.state("members", {
    url: "/members",
    component: "membersComponent",
    onlyAdmin: true,
  });

  $stateProvider.state("verticalCoverage", {
    url: "/vertical-coverage",
    component: "verticalCoverageComponent",
    onlyAdmin: true,
  });

  // $stateProvider.state("membership", {
  //   url: "/huedata-membership",
  //   component: "membershipComponent",
  //   params: {
  //     scrollTo: false,
  //   },
  // });

  $stateProvider.state("colorEmotion", {
    url: "/color-research",
    component: "colorEmotionComponent",
  });

  $stateProvider.state("colorIndex", {
    url: "/color-index?:red&green&blue",
    component: "colorIndexComponent",
    protected: true,
  });

  $stateProvider.state("colorPicker", {
    url: "/color-picker",
    component: "colorPickerComponent",
  });

  $stateProvider.state("colorNamingIndex", {
    url: "/color-naming-index",
    component: "colorNamingIndexComponent",
  });

  $stateProvider.state("colorIndexAccordion", {
    url: "/color-index-accordion",
    component: "colorIndexAccordionComponent",
  });

  $stateProvider.state("colorIndexTabs", {
    url: "/color-index-tabs?:color&mode",
    component: "colorIndexTabsComponent",
  });

  $stateProvider.state("colorNamingInfo", {
    url: "/color-naming-info",
    component: "colorNamingInfoComponent",
  });

  $stateProvider.state("colorIndexSearch", {
    url: "/color-index-search",
    component: "colorIndexSearchComponent",
  });

  $stateProvider.state("partners", {
    url: "/huedata-data-partners",
    component: "partnersComponent",
  });

  $stateProvider.state("reports", {
    url: "/color-reports",
    component: "reportsComponent",
    onlyAdmin: true,
    protected: true,
  });

  $stateProvider.state("reportsDetails", {
    url: "/color-reports/:id",
    component: "reportsDetailsComponent",
    onlyAdmin: true,
  });

  // $stateProvider.state("colorBlog", {
  //   url: "/color-blog",
  //   component: "colorBlogComponent",
  // });

  $stateProvider.state("infographics", {
    url: "/color-infographics",
    component: "infographicsComponent",
  });

  $stateProvider.state("infographicsDetails", {
    url: "/color-infographics/:id",
    component: "infographicsDetailsComponent",
  });

  $stateProvider.state("internships", {
    url: "/internships",
    component: "internshipsComponent",
  });

  $stateProvider.state("customizedInfographics", {
    url: "/color-customized-infographics?tab",
    component: "customizedInfographicsComponent",
    protected: true,
    onlyAdmin: true,
  });

  $stateProvider.state("membersAnalytics", {
    url: "/huedata-members-analytics",
    component: "membersAnalyticsComponent",
    protected: true,
    onlyAdmin: true,
  });

  $stateProvider.state("publicationSchedule", {
    url: "/huedata-publication-schedule",
    component: "publicationScheduleComponent",
    onlyAdmin: true,
  });
  $stateProvider.state("goodReads", {
    url: "/color-good-reads",
    component: "goodReadsComponent",
    onlyAdmin: true,
  });

  $stateProvider.state("goodReadsDetails", {
    url: "/color-good-reads/:id",
    component: "goodReadsDetailsComponent",
    onlyAdmin: true,
  });

  $stateProvider.state("teachingMaterials", {
    url: "/color-teaching-materials",
    component: "teachingMaterialsComponent",
    onlyAdmin: true,
  });

  $stateProvider.state("teachingDetailsMaterials", {
    url: "/color-teaching-materials/:id",
    component: "teachingMaterialsDetailsComponent",
    onlyAdmin: true,
  });

  $stateProvider.state("courses", {
    url: "/color-education-courses",
    component: "coursesComponent",
    onlyAdmin: true,
  });

  $stateProvider.state("coursesDetails", {
    url: "/color-education-courses/:id",
    component: "coursesDetailsComponent",
    onlyAdmin: true,
  });

  $stateProvider.state("privacy", {
    url: "/privacy-policy",
    component: "privacyComponent",
  });

  $stateProvider.state("terms", {
    url: "/terms",
    component: "termsComponent",
  });

  $stateProvider.state("contact", {
    url: "/contact-huedata",
    component: "contactUsComponent",
  });

  $stateProvider.state("test", {
    url: "/test",
    component: "test",
  });

  $stateProvider.state("productInquiry", {
    url: "/product-inquiry",
    component: "inquiriesComponent",
  });

  $stateProvider.state("partnershipInquire", {
    url: "/partnership-inquire",
    component: "inquiriesComponent",
  });

  $stateProvider.state("educationInquire", {
    url: "/education-inquire",
    component: "inquiriesComponent",
  });

  $stateProvider.state("detailedPage", {
    url: "/detailed-page",
    component: "detailedComponent",
    protected: true,
  });

  $stateProvider.state("recover", {
    url: "/recover",
    component: "recoverComponent",
  });

  // Fashion Dashboard
  // $stateProvider.state("fashion", {
  //   url: "/fashion-color-trends",
  //   templateUrl: "app/components/dashboards/fashion/fashion.tmpl.html",
  //   protected: true,
  // });
  //
  // $stateProvider.state("contentFashion", {
  //   parent: "fashion",
  //   url: "/content",
  //   templateUrl: "app/components/dashboards/fashion/content/content.tmpl.html",
  // });

  // Auto Dashboard
  $stateProvider.state("auto", {
    url: "/auto-color-trends?:control&&id&title&hex&family",
    templateUrl: "app/components/dashboards/auto/auto.tmpl.html",
    protected: true,
  });

  // Legal Dashboard
  $stateProvider.state("legal", {
    url: "/legal-color-trademarks",
    templateUrl: "app/components/dashboards/legal/legal.tmpl.html",
    protected: true,
    onlyAdmin: true,
  });

  $stateProvider.state("ownerLegal", {
    parent: "legal",
    url: "/owner",
    templateUrl: "app/components/dashboards/legal/owner/owner.tmpl.html",
  });

  $stateProvider.state("yearLegal", {
    parent: "legal",
    url: "/year",
    templateUrl: "app/components/dashboards/legal/year/year.tmpl.html",
  });

  $stateProvider.state("colorLegal", {
    parent: "legal",
    url: "/color",
    templateUrl: "app/components/dashboards/legal/color/color.tmpl.html",
  });

  $stateProvider.state("productLegal", {
    parent: "legal",
    url: "/product",
    templateUrl: "app/components/dashboards/legal/product/product.tmpl.html",
  });

  // Brand Dashboard
  $stateProvider.state("branding", {
    url: "/branding-colors",
    templateUrl: "app/components/dashboards/branding/branding.tmpl.html",
    protected: true,
  });

  $stateProvider.state("thank-you", {
    url: "/thank-you",
    params: {
      'free' : false
    },
    component: "thankYouComponent",
  });

  $stateProvider.state("password-recover", {
    url: "/password-recover/:token",
    component: "passwordRecoverComponent",
  });

  $stateProvider.state("set-password", {
    url: "/set-password/:token",
    component: "setPasswordComponent",
  });

  $stateProvider.state("staffLogin", {
    url: "/staff-login",
    component: "staffLoginComponent",
  });
  $stateProvider.state("profile", {
    url: "/profile",
    component: "profileComponent",
    protected: true,
  });
  $stateProvider.state("cart-page", {
    url: "/cart?:wayBack",
    component: "cartPageComponent",
  });
  $stateProvider.state("cart-checkout", {
    url: "/cart-checkout",
    component: "cartCheckoutMethodsComponent",
  });
  $stateProvider.state("download-excerpt", {
    url: "/download-excerpt/:type/:id",
    component: "downloadExcerptPageComponent",
  });
  $stateProvider.state("password-recover-cart", {
    url: "/password-recover-cart",
    component: "passwordRecoverCartComponent",
  });
  $stateProvider.state("cart-thank", {
    url: "/cart-thank",
    component: "cartThankComponent",
  });
  $stateProvider.state("landing-page", {
    url: "/",
    component: "landingPageComponent",
  });
  $stateProvider.state("my-purchases", {
    url: "/my-purchases",
    component: "myPurchasesComponent",
    protected: true,
  });
  $stateProvider.state("unsubscribe", {
    url: "/unsubscribe?:token",
    component: "unsubscribeComponent",
  });
  $stateProvider.state("order-email", {
    url: "/order-email?:token",
    component: "orderEmailComponent",
  });
  // $stateProvider.state("home", {
  //   url: "/home-colors",
  //   component: "homeColorsComponent",
  //   protected: true,
  // });
  $stateProvider.state("colorEmotionStudy", {
    url: "/color-emotion-study",
    component: "colorEmotionStudyComponent",
  });

  // Pharma Dashboard
  $stateProvider.state("pharma", {
    url: "/pharma-color-trends?:control&&id&title&hex",
    templateUrl: "app/components/dashboards/pharma/pharma.tmpl.html",
    protected: true,
  });

  // Game Dashboard
  $stateProvider.state("vr-games", {
    url: "/video-games-colors?:control&&id&title&hex&name",
    templateUrl: "app/components/dashboards/games/game.tmpl.html",
    protected: true,
  });

  // Fragrance Dashboard
  $stateProvider.state("fragrance", {
    url: "/fragrance-colors?:control&&id&title&hex&name",
    templateUrl: "app/components/dashboards/fragrance/fragrance.tmpl.html",
    protected: true,
  });

  // Legal Dashboard
  $stateProvider.state("legal-trends", {
    url: "/legal-color-trends?:control&&id&title&hex&family",
    templateUrl: "app/components/dashboards/legal-trends/legal.tmp.html",
    protected: true,
  });

  // Home Dashboard
  $stateProvider.state("home", {
    url: "/home-color-trends?:control&&id&title&hex&family",
    templateUrl: "app/components/dashboards/home-trends/home.tmp.html",
    protected: true,
  });

  // Art Dashboard
  $stateProvider.state("art", {
    url: "/art-color-trends?:control&&id&title&name&hex",
    templateUrl: "app/components/dashboards/art/art.tmp.html",
    protected: true,
  });

  // Nature Dashboard
  $stateProvider.state("nature", {
    url: "/nature-color-trends?:mode&control&&id&title&hex&hex1",
    templateUrl: "app/components/dashboards/nature/nature.tmp.html",
    protected: true,
  });

  // Global Color Dashboard
  $stateProvider.state("globalColor", {
    url: "/global-color-pulse",
    templateUrl: "app/components/global-colors-tracker/globalColorsTracker.tmpl.html",
    protected: true,
  });

  // Sport Dashboard
  $stateProvider.state("sport", {
    url: "/sport-color-trends?:control&&id&title&hex",
    templateUrl: "app/components/dashboards/sport/sport.tmpl.html",
    protected: true,
  });

  // New Fashion Dashboard
  $stateProvider.state("fashion", {
    url: "/fashion-color-trends?:control&&id&name&hex",
    templateUrl: "app/components/dashboards/fashion-trends/fashion.tmpl.html",
    protected: true,
  });

  // pricing
  $stateProvider.state("pricing", {
    url: "/pricing",
    component: "pricingComponent",
  });

  // color research index
  $stateProvider.state("colorResearchIndex", {
    url: "/color-research-index",
    templateUrl: "app/components/color-research/color-research.tmpl.html",
    protected: true,
  });

  // color unicorn index
  $stateProvider.state("colorUnicornIndex", {
    url: "/color-unicorn-index",
    templateUrl: "app/components/color-unicorn/color-unicorn.tmpl.html",
    protected: true,
  });
  // subscription success url
  $stateProvider.state("subscriptionSuccess", {
    url: "/subscription/success",
    component: "landingPageComponent",
  });

    // subscription cancel url
    $stateProvider.state("subscriptionCancel", {
      url: "/subscription/cancel",
      component: "landingPageComponent",
    });

  // education podcats url
  $stateProvider.state("educationPodcasts", {
    url: "/education-podcasts",
    component: "educationPodcastComponent",
    protected: true,
  });

  // education podcats url
  $stateProvider.state("educationCourses", {
    url: "/education-courses",
    component: "educationCoursesComponent",
    protected: true,
  });

  $stateProvider.state("educationCoursesDetails", {
    url: "/education-courses/:id",
    component: "educationCoursesDetailsComponent",
    protected: true,
  });

  // education podcats url
  $stateProvider.state("educationTeachingMaterials", {
    url: "/education-teaching-materials",
    component: "educationTeachingComponent",
    protected: true,
  });

  $stateProvider.state("educationTeachingDetails", {
    url: "/education-teaching/:id",
    component: "educationTeachingDetailsComponent",
    protected: true,
  });

  $stateProvider.state("marketcap-companies", {
    url: "/marketcap-companies",
    component: "marketcapCompaniesComponent"
  });
}
