(function () {
  angular.module('app').directive('hueChartCombinationInLogos', [ '$sce',
    function ($sce) {
      function link(scope) {
        scope.$watch("data", bindData);

        function bindData() {
          scope.dataForBrands = scope.data;
        }

        scope.img = function (svg) {
          if(svg){
            const output = [svg.slice(0, 4), ` width='30' height='30'`, svg.slice(4)].join('');
            return $sce.trustAsHtml(output);
          }
        }
      }

      const directive = {
        link: link,
        restrict: 'E',
        replace: true,
        scope: {
          data: '=',
        },
        templateUrl: 'app/directives/custom-infographics-brand-directives/chart-combination-in-logos.html'
      };

      return directive;

    }
  ]);
}());
