(function () {
  'use strict';
  const serviceId = 'chartsHelper';
  angular.module('app').service(serviceId, ['$q', 'repo.hue',
    function ($q, hue) {
      function initContainer(element, childPath) {
        element = $(element);

        const container = !!childPath ? $(element).find(childPath) : element;
        const containerItself = !!childPath;

        let innerContainer;
        if (containerItself) {
          innerContainer = container;
        } else {
          innerContainer = container.find('>*:first-child');
        }

        if (innerContainer.length === 0) {
          innerContainer = $('<div></div>');
          innerContainer.appendTo(container);
        } else {
          innerContainer.html('');
        }
        return innerContainer;
      }

      return {
        initContainer: initContainer
      }
    }
  ]);
}());
