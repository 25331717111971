function chartNailsLinearVertical(settings) {
  const self = this;
  self.name = 'nails-linear-v';

  chartBase(self);
  initialize();

  self.settings = settings;
  self.container = settings.container;

  self.data = settings.data;// && settings.data.data ? settings.data.data : settings.data;
  self.dataGroups = settings.data && settings.data.groups ? settings.data.groups : [];

  const _opts = self.mergeDefaults(settings.options);
  const _charts = null;

  function initialize() {
    self.getDefaults = function () {
      const d = {
        meta: {},
        groups: {
          items: {},
          separator: {
            color: '#c6c6c6',
            width: 2
          },
          height: 30
        },

        layout: {
          mode: 'default',
          periods: {
            width: 100
          },
          bars: {
            circle: {
              radius: 30
            },
            alternateColor: 'rgba(0,0,0,.03)',
            alternateIsEven: true
          }
        },
        bars: {}
      };

      d.meta.maskId = 'charts-nails-linear-v-bar-mask';

      d.layout.padding = {
        left: 14,
        right: 0,
        top: 0,
        bottom: 0
      };

      d.bars.maxValue = 1;
      return d;
    };
  }

  function prepareContainer() {
    self.initializeLayout(_opts);
    self._gr = self._svg.insert('g', ':first-child')
      .attr('class', 'axis-titles');

    self._f = self._layout.append('g');
    self.h = 450;

    const d = [];
    _.each(self.data.data, function (el) {
      self.isArray(el.data) ? d.push.apply(d, _.map(el.data, function (d) {
        return d.value;
      })) : d.push(el.value);
    });
    self.maxValue = d3.max(d);
    if (_opts.bars.maxValue && _opts.bars.maxValue > self.maxValue) {
      self.maxValue = _opts.bars.maxValue;
    }
    let max = self.maxValue || 0.001;
    max *= (_opts.bars.maxValueRangeMultiplier || 1);
    self.maxRangeValue = max;

    let partsCount = (self.data.periods || []).length;
    if (partsCount === 0) {
      partsCount = 1;
    }
    partsCount += 2; // title and circles
    self.yScale = d3.scale.linear()
      .domain([0, max])
      .range([0, (self.h - _opts.groups.height) / partsCount]);

    // bars mask definition
    const clipPath = self._svg.append('defs')
      .append('clipPath')
      .attr('id', _opts.meta.maskId);

    clipPath.append('circle')
      .attr('r', _opts.layout.bars.circle.radius);
  }

  function bindData() {
    const st = _opts.layout;

    const periods = self.data.periods || [];
    let periodsCount = (periods).length;
    if (periodsCount === 0) {
      periodsCount = 1;
    }

    self._gr.html('');

    const partsCount = periodsCount + 2;
    const contentHeight = self.h - _opts.groups.height;

    for (let j = 0; j < periodsCount; j++) {
      self._gr.append('line')
        .attr('x1', 0)
        .attr('x2', self.w)
        .attr('y1', contentHeight / partsCount * (j + 1) + 4)
        .attr('y2', contentHeight / partsCount * (j + 1) + 4)
        .style({stroke: '#ccc'});

      self.appendTextMultiline(self._gr, periods[j] ? periods[j].title : '', {anchor: 'middle'})
        .attr('transform', self.formatTranslate(_opts.layout.periods.width / 2, contentHeight / partsCount * (j + 0.5)));
    }

    self._gr.append('line')
      .attr('x1', 115)
      .attr('x2', 115)
      .attr('y1', 0)
      .attr('y2', 450)
      .style({stroke: '#ccc'});

    self._gr.append('text')
      .text('Total #')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .attr('transform', self.formatTranslate(_opts.layout.periods.width / 2, contentHeight / partsCount * (partsCount - 1 + 0.66)));

    const dx = self.data.data.length ? (self.w - st.periods.width) / self.data.data.length : 0;
    const dy = contentHeight / partsCount;
    const charts = self._c.selectAll('g').data(self.data.data)
      .enter().append('g')
      .each(function (d, i) {
        let g2;
        let g1;
        let k;
        const g0 = d3.select(this);
        g0.attr('data-eltype', 'bars')
          .attr('transform', self.formatTranslate(st.periods.width + (dx * i), 0));

        const isEvenCondition = _opts.layout.bars.alternateIsEven !== false;
        const isAlternate = i % 2 === 1;
        const isAlternateFilled = isEvenCondition && !isAlternate || !isEvenCondition && isAlternate;
        if (isAlternateFilled && _opts.layout.bars.alternateColor) {
          g0.append('rect')
            .attr('width', dx)
            .attr('height', dy * (partsCount - 1))
            .style({fill: _opts.layout.bars.alternateColor});
        }

        const data = d;

        if (_opts.layout.mode != 'colors') {
          for (k = 0; k < data.data.length; k++) {
            g1 = g0.append('g')
              .attr('transform', self.formatTranslate(dx / 2,
                dy * k))
              .attr('class', 'f-value-bar')
              .attr('data-value', data.data[k].value)
              .attr('data-value-prev', k === data.data.length - 1 ? '' : data.data[k + 1].value);

            g2 = g1.append('g')
              .attr('class', 'f-value-bar-core')
              .attr('transform', self.formatTranslate(0, dy));

            g2.append('text')
              .text(data.data[k].title)
              .attr('text-anchor', 'middle')
              .attr('dominant-baseline', 'central')
              .attr('transform', self.formatTranslate(0, -38))
              .style({opacity: 0});

            g2.append('line')
              .attr('x1', -6)
              .attr('x2', 6)
              .attr('y1', -20)
              .attr('y2', -20)
              .style({stroke: '#333', 'stroke-width': '2'});

            g2.append('line')
              .attr('class', 'f-value-bar-line')
              .attr('x1', 0)
              .attr('x2', 0)
              .attr('y1', -20)
              .attr('y2', k === data.data.length - 1 ? 0 : dy)
              .style({stroke: '#333', 'stroke-width': '2'});
          }
        } else {
          for (let l = 0; l < data.colors.length; l++) {
            g1 = g0.append('g')
              .attr('transform', self.formatTranslate(dx / 2,
                dy * k));
            g2 = g1.append('rect')
              .attr('width', 42)
              .attr('height', 16)
              .attr('fill', data.colors[l].color || data.colors[l])
              .attr('transform', self.formatTranslate(-21, dy * l + 10));

            g2 = g1.append('rect')
              .attr('width', 42)
              .attr('height', 16)
              .attr('fill', data.colors[l].color || data.colors[l])
              .style('opacity', Math.random())
              .attr('transform', self.formatTranslate(-21, dy * l + 40));

            g2 = g1.append('rect')
              .attr('width', 42)
              .attr('height', 16)
              .attr('fill', data.colors[l].color || data.colors[l])
              .style('opacity', Math.random())
              .attr('transform', self.formatTranslate(-21, dy * l + 70));
          }
        }

        // circle
        g0.append('g')
          .attr('transform', self.formatTranslate(dx / 2,
            dy * (partsCount - 2)))

          .append('g')
          .attr('transform', self.formatTranslate(0, dy / 2))
          .attr('clip-path', String.format('url(#{0})', _opts.meta.maskId))

          .append('circle')
          .attr('r', _opts.layout.bars.circle.radius)
          .attr('transform', self.formatTranslate(-_opts.layout.bars.circle.radius * 3, _opts.layout.bars.circle.radius * 3))
          .style({fill: data.color, stroke: self.isWhite(data.color) ? '#ccc' : null});

        // titles
        const gTitle = g0.append('g')
          .attr('transform', self.formatTranslate(dx / 2, dy * (partsCount - 1)));

        const lblGroup = gTitle.append('g')
          .attr('transform', self.formatTranslate(0, dy / 3));

        const lbl = lblGroup.append('text')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .text(data.title)
          .style('text-transform', 'uppercase');

        const box = lbl.node().getBBox();
        const boxMaxWidth = (dx - 8);
        if (box.width > boxMaxWidth) {
          const sentense = data.title;
          var firstWord = sentense,
            secondWord;
          let ind = sentense.lastIndexOf(' ');
          if (ind === -1) {
            ind = sentense.lastIndexOf('-');
          }
          if (ind > -1 && ind < sentense.length - 1) {
            firstWord = sentense.substr(0, ind + 1);
            secondWord = sentense.substr(ind + 1);
          }
          lbl.text(firstWord);

          if (secondWord) {
            const lbl2 = lblGroup
              .append('text')
              .text(secondWord)
              .attr('text-anchor', 'middle')
              .attr('alignment-baseline', 'middle')
              .attr('class', 'legend-title')
              .attr('transform', self.formatTranslate(0, Number(box.height)));

            lblGroup
              .attr('transform', self.formatTranslate(0, dy / 3 - box.height * (1 + 0.00) / 2));
          }
        }

        gTitle.append('text')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .text(data.value)
          .attr('transform', self.formatTranslate(0, dy / 3 * 2));
      });

    let prevGroup = null;
    const groupTitles = self._f.append('g')
      .attr('class', 'group-titles');

    let prevGroupSectionsFrom = 0;
    let prevGroupSectionsTo = 0;

    for (let i = 0; i < self.data.data.length; i++) {
      const d = self.data.data[i];
      // groups separator
      const drawGroupTitle = function () {
        groupTitles.append('text')
          .text(prevGroup ? prevGroup.title : '')
          .attr({
            'text-anchor': 'middle',
            'alignment-baseline': 'middle',
            transform: self.formatTranslate(st.periods.width +
              prevGroupSectionsFrom * dx +
              (prevGroupSectionsTo - 1 - prevGroupSectionsFrom) * dx / 2,
              self.h - _opts.groups.height / 2)
          });
      };
      prevGroupSectionsTo++;

      if (!prevGroup && (self.dataGroups || []).length) {
        prevGroup = _.find(self.dataGroups, {name: d.group});
        prevGroupSectionsFrom = 0;
      } else if (prevGroup && prevGroup.name !== (d.group || '')) {
        const x1 = dx * i - _opts.groups.separator.width / 2 + 1 +
          st.periods.width;
        self._f.append('line')
          .attr({
            x1: x1,
            x2: x1,
            y1: -_opts.layout.padding.top,
            y2: self.h
          }).style({
            stroke: _opts.groups.separator.color,
            'stroke-width': _opts.groups.separator.width,
            'stroke-dasharray': '4,4',
            'box-shadow': '0 0 2px 2px #fff'
          });

        drawGroupTitle();

        prevGroup = _.find(self.dataGroups, {name: d.group || ''});
        prevGroupSectionsFrom = prevGroupSectionsTo;
      } else if (prevGroup && i === self.data.data.length - 1) {
        drawGroupTitle();
      }
    }
    if (prevGroup) {
      self._f.append('line')
        .attr({
          x1: 0,
          x2: self.w,
          y1: self.h - _opts.groups.height - 3,
          y2: self.h - _opts.groups.height - 3
        }).style({
          stroke: _opts.groups.separator.color,
          'stroke-width': _opts.groups.separator.width,
          'stroke-dasharray': '4,4',
          'box-shadow': '0 0 2px 2px #fff'
        });
    }
  }

  function animateChanges(callback) {
    const periods = self.data.periods || [];
    let periodsCount = (periods).length;
    if (periodsCount === 0) {
      periodsCount = 1;
    }
    const partsCount = periodsCount + 2;

    const contentHeight = self.h - _opts.groups.height;

    self._c.selectAll('g[data-eltype="bars"]').data(self.data);
    d3.selectAll(self._c[0][0].childNodes)// .filter('.f-bar-value')
      .each(function (d, i) {
        if (!d) {
          return;
        }

        const g0 = d3.select(this);
        const data = d.value;

        const circle = g0.selectAll('circle');
        circle.transition()
          .remove();
        circle.transition()
          .ease('cubic-out')
          .duration(500)
          .delay(i * 10)
          .attr('transform', String.format('translate({0},{1})', 0, 0));

        const bars = g0.selectAll('.f-value-bar');

        for (let j = 0; j < bars[0].length; j++) {
          const bar = d3.select(bars[0][j]);
          const value = parseFloat(bar.attr('data-value'));
          const valuePrev = parseFloat(bar.attr('data-value-prev'));

          let valueTransformed = (Math.round(value / (1 / 3) - 0.5) + 0) / 10 * 3.33;
          valueTransformed += 0.05;

          const valueTransformedPrev = isNaN(valuePrev) ? 1 : (Math.round(valuePrev / (1 / 3) - 0.5) + 1) / 10 * 3.33;
          const p = self.yScale(valueTransformed);
          const pPrev = self.yScale(1 - valueTransformedPrev);

          const text = bar.selectAll('text');
          text.transition()
            .remove();
          text.transition()
            .ease('cubic-out')
            .duration(500)
            .delay(200 + i * 25)
            .style({opacity: 1});

          const core = bar.selectAll('.f-value-bar-core');
          const dx = d3.transform(core.attr('transform')).translate[0];

          core.transition()
            .remove();
          core.transition()
            .ease('cubic-out')
            .duration(500)
            .delay(i * 15)
            .attr('transform', String.format('translate({0},{1})', dx,
              contentHeight / partsCount - p));

          const line = bar.selectAll('.f-value-bar-line');

          line.transition()
            .remove();
          if((1 - valueTransformedPrev) === 0) {
            line.transition()
            .ease('cubic-out')
            .duration(500)
            .delay(i * 15)
            .attr('y2', p + contentHeight / partsCount * (1 - valueTransformedPrev));
          } else {
            line.transition()
            .ease('cubic-out')
            .duration(500)
            .delay(i * 15)
            .attr('y2', 20);
          }
        }
      });
  }

  self.applyValues = data => {
    if (data.length === self.data.length) { // animating changes
      self.dataPrev = self.data;
      self.data = data;
      animateChanges(function () {
        self.dataPrev = null;
      });
    } else {
      self.data = data;
      prepareContainer();
      bindData();
    }
  };

  prepareContainer();
  bindData();
  animateChanges();
}
