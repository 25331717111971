angular.module("app").directive("paginationbar", function () {
  function link(scope) {
     scope.$watch('pageCount', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.pageCount = newValue;
      }
    });
    scope.$watch('currentPage', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.currentPage = newValue;
      }
    });
    scope.$watch('setPage', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        scope.setPage = newValue;
      }
    });
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/paginationBar/paginationBarView.html",
    link: link,
    scope: {
      currentPage: "=",
      pageCount: "=",
      setPage: "=",
    },
  };
});
