angular.module('app').directive('infographicsForTracker', [
  '$location',
  '$timeout',
  'customInfographicsConstants',
  "trackDashboardRepository",
  "pulseDashboardRepository",
  (
    $location,
    timeout,
    trackDashboardRepository,
    customInfographicsConstants,
    pulseDashboardRepository
  ) => {
    let link = (scope) => {
      const now = new Date();
      scope.year = now.getFullYear();
      scope.month = now.getMonth();
      scope.day = now.getDate();

      scope.meta = {};
      scope.isFilterVisible = customInfographicsConstants.isFilterVisible;

      scope.monthData = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
      ];

      scope.source = 'instagram,twitter';
      scope.sentiments = 'positive,negative';

      scope.filtersForTracker = {
        month: [
          { id: 0, title: "January" },
          { id: 1, title: "February" },
          { id: 2, title: "March" },
          { id: 3, title: "April" },
          { id: 4, title: "May" },
          { id: 5, title: "June" },
          { id: 6, title: "July" },
          { id: 7, title: "August" },
          { id: 8, title: "September" },
          { id: 9, title: "October" },
          { id: 10, title: "November" },
          { id: 11, title: "December" },
        ],
        year: [
          {
              "id": 1988,
              "title": "1988"
          },
          {
              "id": 1989,
              "title": "1989"
          },
          {
              "id": 1990,
              "title": "1990"
          },
          {
              "id": 1991,
              "title": "1991"
          },
          {
              "id": 1992,
              "title": "1992"
          },
          {
              "id": 1993,
              "title": "1993"
          },
          {
              "id": 1994,
              "title": "1994"
          },
          {
              "id": 1995,
              "title": "1995"
          },
          {
              "id": 1996,
              "title": "1996"
          },
          {
              "id": 1997,
              "title": "1997"
          },
          {
              "id": 1998,
              "title": "1998"
          },
          {
              "id": 1999,
              "title": "1999"
          },
          {
              "id": 2000,
              "title": "2000"
          },
          {
              "id": 2001,
              "title": "2001"
          },
          {
              "id": 2002,
              "title": "2002"
          },
          {
              "id": 2003,
              "title": "2003"
          },
          {
              "id": 2004,
              "title": "2004"
          },
          {
              "id": 2005,
              "title": "2005"
          },
          {
              "id": 2006,
              "title": "2006"
          },
          {
              "id": 2007,
              "title": "2007"
          },
          {
              "id": 2008,
              "title": "2008"
          },
          {
              "id": 2009,
              "title": "2009"
          },
          {
              "id": 2010,
              "title": "2010"
          },
          {
              "id": 2011,
              "title": "2011"
          },
          {
              "id": 2012,
              "title": "2012"
          },
          {
              "id": 2013,
              "title": "2013"
          },
          {
              "id": 2014,
              "title": "2014"
          },
          {
              "id": 2015,
              "title": "2015"
          },
          {
              "id": 2016,
              "title": "2016"
          },
          {
              "id": 2017,
              "title": "2017"
          },
          {
              "id": 2018,
              "title": "2018"
          },
          {
              "id": 2019,
              "title": "2019"
          },
          {
              "id": 2020,
              "title": "2020"
          },
          {
              "id": 2021,
              "title": "2021"
          },
          {
            "id": 2022,
            "title": "2022"
        }
        ].sort((a, b) => (a.id < b.id) ? 1 : -1)
      };

      scope.sourcesList = [
        { id: 0, title: "ALL", img_url: "/assets/img/global/new_all.png" },
        { id: 1, title: "Instagram", img_url: "/assets/img/global/instagram-round.png" },
        { id: 2, title: "Twitter", img_url: "/assets/img/global/twitter-icon.svg" },
      ];

      scope.logoList = [
        {
          img_url: "/assets/img/global/instagram-round.png"
        },
        {
          img_url: "/assets/img/global/twitter-icon.svg"
        }
      ];

      scope.selectedFormattedDate = `${scope.monthData[scope.month]} ${scope.year}`;
      scope.currentSourceForTracker = scope.sourcesList[0];
      scope.currentMonthForTracker = { id: scope.month, title: scope.monthData[scope.month]}
      scope.currentYearForTracker = scope.filtersForTracker.year[0];

      scope.updateMonth = function (month) {
        scope.currentMonthForTracker = month;
        scope.month = month.id;
        scope.loadGraphics();
      };
      scope.updateYear = function (year) {
        scope.currentYearForTracker = year;
        scope.year = year.id;
        scope.loadGraphics();
      };
      scope.updateSource = function (source) {
        scope.currentSourceForTracker = source;
        scope.logoList = [];
        if (scope.currentSourceForTracker.id === 0){
          scope.logoList = [
            {
              img_url: "/assets/img/global/instagram-round.png"
            },
            {
              img_url: "/assets/img/global/twitter-icon.svg"
            }
          ];
        } else if (scope.currentSourceForTracker.id === 1){
          scope.logoList = [
            {
              img_url: "/assets/img/global/instagram-round.png"
            }
          ];
        } else if (scope.currentSourceForTracker.id === 2) {
          scope.logoList = [
            {
              img_url: "/assets/img/global/twitter-icon.svg"
            }
          ];
        }
        scope.loadGraphics();
      };


      scope.chartsForTracker = [
        {
          qNumber: 'TRACKER1',
          title: 'MOST LIKED/DISLIKED COLORS (DATE)',
          id: 'mostLikedDislikedColors'
        },
      ];

      scope.currentChartForTracker = scope.chartsForTracker[0];

      scope.updateChart = function (chart) {
        scope.currentChartForTracker = chart;
      };

      scope.loadGraphics = () => {
        if (scope.currentSourceForTracker.id === 0){
          scope.source = "instagram,twitter";
        } else if (scope.currentSourceForTracker.id === 1){
          scope.source = "instagram";
        } else if (scope.currentSourceForTracker.id === 2) {
          scope.source = "twitter";
        }
        const month = scope.currentMonthForTracker ? scope.currentMonthForTracker.title : '';
        const year = scope.currentYearForTracker ? scope.currentYearForTracker.title : '';
        scope.titleForTracker = 'MOST LIKED/DISLIKED COLORS // ' + month + ' ' + year + ' //';
        scope.selectedFormattedDate = `${scope.monthData[scope.month]} ${scope.year}`;
        pulseDashboardRepository.getDailyViewTrackerData(scope.getFormattedDate(), scope.source, scope.sentiments).then(function (res) {
          scope.colorSentimentMonthData = res.colorSentimentMonthData;
          scope.colorSentimentMonthData.date = scope.selectedFormattedDate;
          scope.sentimetnData = res.sentimentMonthColorData;
        });
      };

      scope.getFormattedDate = function () {
        const month = scope.month + 1;
        const monthToString = month < 10 ? '0' + month : month;
        const dayToString = scope.day < 10 ? '0' + scope.day : scope.day;

        return `${scope.year}-${monthToString}-${dayToString}`;
      };

      scope.refresh = () => {
        scope.loadGraphics();
      };

      scope.loadGraphics();
    }

    return {
      restrict: 'E',
      templateUrl:
        'app/components/customized-infographics/infographicsForTracker/infographicsForTracker.html',
      link: link,
      scope: {
        selectedMenuId: '=',
        exportJpgThroughDomToImage: '='
      }
    };
  }
]);
