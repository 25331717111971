angular.module("app").directive("globalSentimentColorPieChart", function () {
  function link(scope) {
    scope.$watch("selectedItem", bindData);

    function bindData() {
      const element = document.getElementById(`my_global_sentiment_color_${scope.mode}_dataviz`);
      if (element) {
        element.innerHTML = "";
      }

      const width = 150;
      const height = 150;
      const margin = 10;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      const svg = d3
        .select(`#my_global_sentiment_color_${scope.mode}_dataviz`)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      if (scope.selectedItem) {
        // Create chart data
        const data = {
          "color1": scope.selectedItem.percentage,
          "color2": 100 - scope.selectedItem.percentage,
        };

        const colorData = {
          "color1": { hex: scope.selectedItem.hex},
          "color2": { hex: "#00000000" },
        };

        // Compute the position of each group on the pie:
        const pie = d3.layout
          .pie()
          .sort(null) // Do not sort group by size
          .value(function (d) {
            return d.value;
          });
        const data_ready = pie(d3.entries(data));

        const data2 = {
          "color": 100,
        };

        const data_ready2 = pie(d3.entries(data2));

        // The arc generator
        const arc = d3.svg
          .arc()
          .innerRadius(radius * 0.65) // This is the size of the donut hole
          .outerRadius(radius * 0.8);

        const arc3 = d3.svg
          .arc()
          .innerRadius(radius * 0.75)
          .outerRadius(radius * 0.8);

        // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
        svg
          .selectAll("allSlices")
          .data(data_ready)
          .enter()
          .append("path")
          .attr("d", arc)
          .attr("fill", function (d) {
            return colorData[d.data.key].hex;
          })
          .attr(
            "stroke", function (d) {
              return colorData[d.data.key].hex === "#ffffff" || colorData[d.data.key].hex === "#FFFFFF"
                ? "#E0E0DD"
                : colorData[d.data.key].hex;
            }
          )
          .style(
            "stroke-width",  function (d) {
              return colorData[d.data.key].hex === "#ffffff" || colorData[d.data.key].hex === "#FFFFFF"
                ? "1px"
                : "0px"
            }
          );

        svg
          .selectAll("allSlices")
          .data(data_ready2)
          .enter()
          .append("path")
          .attr("d", arc3)
          .attr("fill", function (d) {
            return scope.selectedItem.hex;
          })
          .attr(
            "stroke", function (d) {
              return scope.selectedItem.hex === "#ffffff" || scope.selectedItem.hex === "#FFFFFF"
                ? "#E0E0DD"
                : scope.selectedItem.hex;
            }
          )
          .style(
            "stroke-width",  function (d) {
              return scope.selectedItem.hex === "#ffffff" || scope.selectedItem.hex === "#FFFFFF"
                ? "1px"
                : "0px"
            }
          );

        svg
          .append("text")
          .text(`${scope.selectedItem.percentage}%`)
          .attr("transform", "translate(0, 6)")
          .attr("font-size", "20px")
          .attr("text-anchor", "middle")
          .attr("style", "font-family: Gotham SSm A, Gotham SSm B");
      }

    }
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/dbGlobalColorData/globalDailyColor/globalSentiments/globalSentimentColorPieChart/globalSentimentColorPieChartView.html",
    link: link,
    scope: {
      selectedItem: "=",
      mode: "=",
    },
  };
});
