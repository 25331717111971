angular.module('app').directive('colorPopupBox', function () {
  function link () {

  }
  return {
    templateUrl: 'app/directives/colorPopupBox/colorPopupBox.html',
    restrict: 'E',
    link,
    scope: {
      hex: '=',
      boxTitle: '=',
      description: '='
    }
  }
});
