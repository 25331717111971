(function (angular) {
  const controllerName = "brandingcolordata";
  angular.module("app").directive(controllerName, [
    "brandingDashboardRepository", "$timeout",
    (brandingDashboardRepository, timeout) => {
      let link = (scope) => { 
        scope.isLoadingControls = true;
        scope.currentPage = 1;
        scope.azMode = true;  
        scope.page = 1;
        scope.attributeIndustryData = []; 
        scope.acutalColorDependableData = [];
        scope.showColorCombinationsPopup = false;
        scope.showTopLogoColorsPopup = false;
        scope.showTop5IndustriesPopup = false;
        scope.isLoadedAllIndustryData = true;
        scope.isLoadedAllBrandColorAttributeData = true;
        scope.isLoadedAllAttributeData = true;
        scope.isLoadedAcutalColorData = true;  
        scope.isLoadedAllBrandAttributeData = false;
        
        scope.mockedColorAttributes = [
          {
            boldFirst: true,
            attributes: ["APPETISING", "STIMULATING", "LOVING"],
          },
          { boldFirst: false, attributes: ["APPETISING", "ORIENTED", "SOCIAL"] },
          {
            boldFirst: false,
            attributes: ["CORPORAL", "ACTIVATING", "ARTIFICIAL"],
          },
          { boldFirst: false, attributes: ["ORGANIC", "HEARTFELT"] },
          { boldFirst: false, attributes: ["DOMINANT", "LIVELY"] },
          { boldFirst: false, attributes: ["EXCITING", "INTENSIVE"] },
        ];

        scope.toggleColorCombinationsPopup = (needShow = null) => {
          if (needShow === null) {
            scope.showColorCombinationsPopup = !scope.showColorCombinationsPopup;
          } else {
            scope.showColorCombinationsPopup = needShow;
          }
        };

        scope.toggleTopColorsPopup = (needShow = null) => {
          if (needShow === null) {
            scope.showTopLogoColorsPopup = !scope.showTopLogoColorsPopup;
          } else {
            scope.showTopLogoColorsPopup = needShow;
          }
        };

        scope.data = [];
        scope.showCollection = false;
        scope.showImageDetails = false;
        scope.imageDetailsData = null;

        scope.collectionDataList = [];

        scope.description = '';
        scope.fullDescription = '';
        scope.showingFullDesc = false;
        scope.moreDescAvailable = false;
        scope.maxDescWords = 250;
        scope.topIndustryForThisColor = {id: '', title: '', percentage: ''};

        scope.similar = '';
        scope.fullSimilar = '';
        scope.showingFullSimilar = '';
        scope.moreSimilarAvailable = false;
        scope.maxSimilarWords = 130;

        scope.typeOfPopup = '';
        scope.colorID = '';

        scope.showDashboard = true;
        scope.hexColors = ["#92a8d1", "#ffffff", "#00a6d8", "#ff0000", "#00bd42"]; // For color attribute associations

        scope.toggleTop5IndustriesPopup = (needShow, type) => {
          if (needShow) {
            if(type === 'chart1'){
              scope.topLogoColorsPopupAttributes.modalTitle = 'TOP 5 INDUSTRIES USING ' + scope.filter.title;
              scope.typeOfPopup = type;
            } else if(type === 'chart2'){
              scope.topLogoColorsPopupAttributes.modalTitle = 'USE OF ' + scope.filter.title + ' COMPARED WITH THE USE OF ALL OTHER COLORS IN LOGOS';
              scope.typeOfPopup = type;
            } else if(type === 'chart3'){
              scope.topLogoColorsPopupAttributes.modalTitle = 'COLOR COMBINATIONS FOR ' + scope.filter.title + ' AND ALL OTHER COLORS USED IN LOGOS ACROSS INDUSTRIES';
              scope.typeOfPopup = type;
            } else if(type === 'chart4'){
              scope.topLogoColorsPopupAttributes.modalTitle = 'COLOR FREQUENCY';
              scope.topLogoColorsPopupAttributes.modalSecondTitle = 'FOR THE ' + scope.pageData.industry + ' INDUSTRY';
              scope.typeOfPopup = type;
            } else if(type === 'chart5'){
              scope.topLogoColorsPopupAttributes.modalTitle = scope.pageData.title + ' INDUSTRY LOGOS TOP 4 COLORS';
              scope.typeOfPopup = type;
            } else if(type === 'chart6'){
              scope.topLogoColorsPopupAttributes.modalTitle = 'NUMBER OF COLORS IN LOGOS FOR THE ' + scope.pageData.title + ' INDUSTRY';
              scope.typeOfPopup = type;
            } else if(type === 'chart7'){
              scope.topLogoColorsPopupAttributes.modalTitle = 'FREQUENT USE OF COLORS IN LOGOS IN THE ' + scope.pageData.title + ' INDUSTRY';
              scope.typeOfPopup = type;
            } else if(type === 'chart8'){
              scope.topLogoColorsPopupAttributes.modalTitle = 'COLOR FREQUENCY FOR ' + scope.brandTitle;
              scope.typeOfPopup = type;
            }
            scope.showTop5IndustriesPopup = !scope.showTop5IndustriesPopup;
          } else {
            scope.showTop5IndustriesPopup = !scope.showTop5IndustriesPopup;
          }
        };

        scope.$on("mood_state_chart", (data) => {
          let node = angular.element("#brandPopupCapture")[0];
          domtoimage
            .toPng(node, {
              bgcolor: "#fff",
            })
            .then((dataUrl) => {
              if(dataUrl) {
                scope.saveImageDetailsData = {
                  base64: dataUrl,
                  type: 'brand'
                };
              }
            })
            .catch(function (error) {
              console.error("oops, something went wrong!", error);
            });

            let times = 2000;
            timeout(function () {
              if(scope.saveImageDetailsData) {
                scope.showTop5IndustriesPopup = false;
                scope.toggleSaveImageDetails();
              } else {
                timeout(function () {
                  if(scope.saveImageDetailsData) {
                    scope.showTop5IndustriesPopup = false;
                    scope.toggleSaveImageDetails();
                  }
                }, times);
              }
            }, times);
        });

        scope.generateImgName = function (type, board, name) {
          let result = "";
          const date = moment().format("L");
          result += name.toUpperCase() + ".";
          return result + date + type;
        };

        scope.toggleSaveImageDetails = () => {
          scope.saveImageDetails = scope.saveImageDetails ? false : true;
        };

        scope.$on("mood_state_chart_changed", () => {
          scope.toggleSaveImageDetails();
        });

        scope.setDescription = desc => {
          scope.fullDescription = desc;
          if(desc){
            scope.description = desc.substr(0, scope.maxDescWords);
            scope.moreDescAvailable = desc.length > scope.maxDescWords;
          }
        };

        scope.setSimilar = desc => {
          scope.fullSimilar = desc;
          scope.similar = desc.substr(0, scope.maxSimilarWords);
          scope.simmilarArr = scope.similar.split(',');
          scope.moreSimilarAvailable = desc.length > scope.maxSimilarWords;
        };

        scope.showMoreDesc = () => {
          if (scope.showingFullDesc) {
            scope.description = scope.fullDescription.substr(0, scope.maxDescWords);
          }
          else {
            scope.description = scope.fullDescription;
          }
          scope.showingFullDesc = !scope.showingFullDesc;
        };

        scope.showMoreSimilar = () => {
          if (scope.showingFullSimilar) {
            scope.similar = scope.fullSimilar.substr(0, scope.maxSimilarWords);
          }
          else {
            scope.similar = scope.fullSimilar;
          }
          scope.showingFullSimilar = !scope.showingFullSimilar;
        };

        scope.colorCombinationsPopupAttributes = {
          graph: {
            style: {},
            heightSize: null,
            showTopBorder: true,
            sameSizeScale: true,
            mainColor: "",
            smallScaleFirst: false,
          },
        };

        scope.topLogoColorsPopupAttributes = {
          formsColWidth: null,
          modalTitle: "TOP COLORS",
          modalSecondTitle: ''
        };

        scope.selectedAttributeBrandDashCharacter = "ALL";

        scope.attributeBrandDashCharacterSelect = (value) => {
          scope.isLoadingControls = true; 
          scope.selectedAttributeBrandDashCharacter = value;
          scope.azMode = value === 'ALL' ? true : false;
          scope.currentPage = 1;
          scope.colorID = '';
          scope.selectedColor = '';
          brandingDashboardRepository.attribute
            .getAcutalColors(scope.searchTitle, scope.azMode, scope.currentPage, scope.colorID)
            .then((data) => {  
              scope.acutalColorDependableData = data.chartData11.color_names;
              scope.isLoadedAcutalColorData = data.chartData11.paginate.current_page === data.chartData11.paginate.total_pages ? true : false;
              scope.isLoadingControls = false; 
            }).finally(() => {
              scope.isLoadingControls = false;
            });
        };

        scope.isAttributeBrandDashSelected = (value) => {
          return scope.selectedAttributeBrandDashCharacter === value;
        };

        if (scope.viewMode === "industry") {
          scope.isLoadingControls = true; 
          brandingDashboardRepository.industry
            .getPageData(scope.searchId)
            .then((data) => {
              scope.pageData = data.industryHeaderData;
              scope.industryHeaderData = data.industryHeaderData;
              scope.setDescription(scope.industryHeaderData.description);
            });

          brandingDashboardRepository.industry
            .getColorFrequency(scope.searchId)
            .then((data) => {
              scope.industryLogoData = data.chartData7;
            });

          brandingDashboardRepository.industry
            .getTopColors(scope.searchId)
            .then((data) => {
              scope.industryTopColors = data.chartData15;
            });

          brandingDashboardRepository.industry
            .getFrequentUseOfColors(scope.searchId)
            .then((data) => {
              scope.colorComparedInPeriodData = data.chartData8;
            });

          brandingDashboardRepository.industry
            .getIndustryColorsData(scope.searchId, scope.page)
            .then((data) => {
              scope.industryColorData = data.chartData9.sort((a, b) => a.name > b.name ? 1 : -1 );
              if (scope.industryColorData.length && scope.industryHeaderData.title) {
                scope.selectedIndustryData = scope.industryColorData.find((item) => item.name === scope.industryHeaderData.title);
                scope.otherIndustriesData = scope.industryColorData.filter(item => item.name !== scope.industryHeaderData.title);

                if (scope.industryColorData.length > 10) {
                  scope.industryColorData = scope.otherIndustriesData.slice(0, 10);
                  scope.moreIndustryColorData = scope.otherIndustriesData.slice(10);
                  scope.isLoadedAllIndustryData = false;
                }
                else {
                  scope.industryColorData = scope.otherIndustriesData;
                  scope.moreIndustryColorData = [];
                  scope.isLoadedAllIndustryData = true;
                }
              }
            });
            scope.isLoadingControls = false;
        } else if (scope.viewMode === "attribute") {
          scope.isLoadingControls = true; 
          scope.currentPage = 1;
          scope.colorCombinationsPopupAttributes.graph.showTopBorder = true;
          scope.colorCombinationsPopupAttributes.graph.sameSizeScale = true;

          brandingDashboardRepository.attribute
            .getPageData(scope.searchTitle)
            .then((data) => {
              // scope.pageData = data;
              // scope.colorCombinationsPopupAttributes.graph.mainColor =
              //   scope.pageData.color;
              scope.pageData = data.attributeHeaderData;
              scope.attributeHeaderData = data.attributeHeaderData;
              scope.setDescription(scope.attributeHeaderData.description);
              scope.setSimilar(scope.attributeHeaderData.similar);
            });

          // brandingDashboardRepository.attribute
          //   .getTopColors(scope.searchTitle)
          //   .then((data) => {
          //     scope.topColorsDummyData = data.chartData16;
          //   });

          // brandingDashboardRepository.attribute
          //   .getTopIndustries(scope.searchTitle)
          //   .then((data) => { 
          //     scope.colorTrademarkBrandData = data.chartData10;
          //     scope.attributeIndustryData = data && data.chartData10 ? data.chartData10 : [];
          //   });

          brandingDashboardRepository.attribute
            .getColorCombinations(scope.searchTitle)
            .then((data) => { 
              scope.colorComparedInPeriodData = data && data.chartData12 ? data.chartData12 : [];
            });

          brandingDashboardRepository.attribute
            .getAcutalColors(scope.searchTitle, scope.azMode, scope.currentPage, scope.colorID)
            .then((data) => {  
              scope.acutalColorDependableData = data.chartData11.color_names;
              scope.isLoadedAcutalColorData = data.chartData11.paginate.current_page === data.chartData11.paginate.total_pages ? true : false;
            });
          scope.isLoadingControls = false;
        } else if (scope.viewMode === "company") {
          scope.isLoadingControls = true; 
          scope.topLogoColorsPopupAttributes.modalTitle = "TOP LOGO COLORS";

          brandingDashboardRepository.brand
            .getPageData(scope.searchId)
            .then((data) => {
              scope.pageData = data.companyHeaderData;
              scope.companyHeaderData = data.companyHeaderData;
              scope.setDescription(scope.companyHeaderData.description);
            });

          brandingDashboardRepository.brand
            .getLogoColors(scope.searchId)
            .then((data) => {
              scope.topColorsDummyData = data && data.chartData13 ? data.chartData13 : [];
              // scope.logoColors = data && data.logo_colors ? data.logo_colors : [];
            });

          brandingDashboardRepository.brand
            .getTopColors(scope.searchId)
            .then((data) => {
              scope.topColors = data && data.chartData15 ? data.chartData15 : [];
            });

          brandingDashboardRepository.brand
            .getColorFrequency(scope.searchId)
            .then((data) => {
              scope.colorFrequencyDummyData = data && data.chartData14 ? data.chartData14 : [];
            });

          brandingDashboardRepository.brand
            .getColorAttributes(scope.searchId, scope.page)
            .then((data) => { 
              scope.totalAttributeData = data && data.chartData6 ? data.chartData6 : [];
              if (scope.totalAttributeData.length > 10) {
                scope.brandAttributeData = scope.totalAttributeData.slice(0, 10);
                scope.moreBrandColorAttributeData = scope.totalAttributeData.slice(10);
                scope.isLoadedAllBrandColorAttributeData = false;
              }
              else {
                scope.brandAttributeData = scope.totalAttributeData;
                scope.moreBrandColorAttributeData = [];
                scope.isLoadedAllBrandColorAttributeData = true;
              }
            });
          scope.isLoadingControls = false;
        } else if (scope.viewMode === "color") {
          scope.isLoadingControls = true; 
          scope.colorCombinationsPopupAttributes.graph.style = {
            "margin-top": "-25px",
          };
          scope.colorCombinationsPopupAttributes.graph.heightSize = "big";
          scope.colorCombinationsPopupAttributes.graph.showTopBorder = false;
          scope.colorCombinationsPopupAttributes.graph.sameSizeScale = false;
          scope.colorCombinationsPopupAttributes.graph.smallScaleFirst = true;
          scope.topLogoColorsPopupAttributes.formsColWidth = "150px";

          brandingDashboardRepository.color
            .getPageData(scope.searchId)
            .then((data) => {
              scope.pageData = data.colorHeaderData;
              scope.colorHeaderData = data.colorHeaderData;
              scope.colorCombinationsPopupAttributes.graph.mainColor =
                scope.pageData.notation;
              scope.pageData.notation = scope.pageData.length ? scope.pageData[0].color : '';
              scope.topLogoColorsPopupAttributes.modalTitle =
                "TOP FOUR " + scope.filter.title + "S";

              scope.setDescription(scope.colorHeaderData.description);
            });
          brandingDashboardRepository.color
            .getTopIndustries(scope.searchId)
            .then((data) => {
              scope.topFiveIndustryColorData = data.chartData1;
              if (data.chartData1.length) {
                scope.topIndustryForThisColor = data.chartData1[0];
              }
            });
          brandingDashboardRepository.color
            .getTopColors(scope.searchId)
            .then((data) => {
              scope.topColorsDummyData = data.chartData2;
            });
          brandingDashboardRepository.color
            .getColorCombinations(scope.searchId)
            .then((data) => {
              scope.colorComparedInPeriodData = data.chartData3;
            });
          brandingDashboardRepository.color
            .getColorAttributes(scope.searchId)
            .then((data) => {
              scope.colorTrademarkBrandData = data.chartData4;
            });
          brandingDashboardRepository.color
            .getAttributeData(scope.searchId, scope.page)
            .then((data) => {  
              scope.totalsAttributeData = data && data.chartData5 ? data.chartData5 : [];
              if (scope.totalsAttributeData.length > 12) {
                scope.attributeData = scope.totalsAttributeData.slice(0, 12);
                scope.moreBrandAttributeData = scope.totalsAttributeData.slice(12);
                scope.isLoadedAllBrandAttributeData = false;
              }
              else {
                scope.attributeData = scope.totalsAttributeData;
                scope.moreBrandAttributeData = [];
                scope.isLoadedAllBrandAttributeData = true;
              }
            });
          scope.isLoadingControls = false;
        }

        scope.isModeIndustry = () => {
          return scope.viewMode === "industry";
        };

        scope.isModeAttribute = () => {
          return scope.viewMode === "attribute";
        };

        scope.isModeCompany = () => {
          return scope.viewMode === "company";
        };

        scope.isModeColor = () => {
          return scope.viewMode === "color";
        };


        // dummy data
        // scope.colorHeaderData = {
        //   shades: "42",
        //   description:
        //     "This is Photoshop's version of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis, sem nibh id elit.",
        //   artists: "19%"
        // };

        // scope.companyHeaderData = {
        //   description: "Microsoft Corporation develops, manufactures, licenses, sells, and supports software. The Company offers operating system software, server application software, business and con-sumer applications software, software development tools, and Internet and intranet soft-ware. Microsoft also develops video games consoles and digital music entertainment devices.",
        //   industry: "Information Technology",
        //   logo_url: "/assets/img/brands/company/microsoft.png"
        // };

        // scope.industryHeaderData = {
        //   description: "Companies in this industry manufacture aircraft, aircraft components, and missiles and space vehicles. Major companies include US-based Boeing, General Dynamics, Lockheed Martin, Northrop Grumman, and Raytheon, as well as the Netherlands-based EADS Companies in this industry manufacture aircraft, aircraft components, and missiles and space vehicles. Major companies include US-based Boeing, General Dynamics, Lockheed Martin, Northrop Grumman, and Raytheon, as well as the Netherlands-based EADS",
        //   brands: 55,
        //   colors: 24,
        //   title: "AEROSPACE & DEFENSE",
        //   img_url: "/assets/img/brands/industry/aerospace1.png"
        // };

        // scope.attributeHeaderData = {
        //   title: "dependable",
        //   description: "Dependable means capable of being dependend on: worthy of trust, reliable",
        //   colors: 4,
        //   similar: "RELIABLE, ACCESSABLE, RELIABLE, ACCESSABLE, RELIABLE, ACCESSABLE",
        // };

        // scope.seletedColor = { id: 0, hex: '#e3db42', title: 'yellow'};

        scope.topFiveIndustryColorData = [];

        // scope.selectedAttributeColorDummyData = {
        //   id: 19,
        //   hex: '#10468e',
        //   title: 'color20',
        //   count: 31,
        //   percent: 70,
        // };

        scope.colorComparedInPeriodData = [];

        scope.numberWithCommas = (x) => {
          // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
        }

        scope.attributeData = [
          {
            "title": "new",
            "title1": "new",
            "description": "not exist before; made, introduced, or discovered recently or now for the first time",
          },
          {
            "title": "playful",
            "title1": "play·ful",
            "description": "fond of games and amusement; lighthearted.",
          },
          {
            "title": "premium",
            "title1": "pre·mi·um",
            "description": "an amount to be paid for an insurance policy.",
          },
          {
            "title": "relieving",
            "title1": "re·lieve",
            "description": "cause (pain, distress, or difficulty) to become less severe or serious",
          }
        ];

        scope.brandAttributeData = [
          {
            "title": "new",
            "title1": "new",
            "description": "not exist before; made, introduced, or discovered recently or now for the first time",
            "color": { hex: "#e94f1b" }
          },
          {
            "title": "playful",
            "title1": "play·ful",
            "description": "fond of games and amusement; lighthearted.",
            "color": { hex: "#fab321" }
          },
          {
            "title": "premium",
            "title1": "pre·mi·um",
            "description": "an amount to be paid for an insurance policy.",
            "color": { hex: "#757575" }
          },
          {
            "title": "relieving",
            "title1": "re·lieve",
            "description": "cause (pain, distress, or difficulty) to become less severe or serious",
            "color": { hex: "#74b52a" }
          },
          {
            "title": "relieving",
            "title1": "re·lieve",
            "description": "cause (pain, distress, or difficulty) to become less severe or serious",
            "color": { hex: "#15aae3" }
          }
        ];

        scope.industryLogoData = [
          {
            "title": "1 color logo",
            "percentage": 60,
          },
          {
            "title": "2 color logo",
            "percentage": 60,
          },
          {
            "title": "3 color logo",
            "percentage": 60,
          },
          {
            "title": "4 color logo",
            "percentage": 60,
          }
        ];

        scope.attributeIndustryData = [
          {
            "title": "",
            "percentage": 0,
            "img_url": ""
          },
          {
            "title": "",
            "percentage": 0,
            "img_url": ""
          },
          {
            "title": "",
            "percentage": 0,
            "img_url": ""
          },
        ];

        // dummyData
        const colorComparisonData = {};

        scope.selectedIndustryData = {};

        scope.industryColorData = [];

        // scope.acutalColorDependableData = [
        //   {
        //     title: "blue",
        //     attribute: "dependable",
        //     color: {hex: "#deb4a1", R: 222, G: 180, B: 161},
        //   },
        //   {
        //     title: "blue",
        //     attribute: "dependable",
        //     color: {hex: "#dfc3b8", R: 223, G: 195, B: 184},
        //   },
        //   {
        //     title: "blue",
        //     attribute: "dependable",
        //     color: {hex: "#a8a1de", R: 168, G: 161, B: 222},
        //   },
        //   {
        //     title: "blue",
        //     attribute: "dependable",
        //     color: {hex: "#dec3a1", R: 222, G: 195, B: 161},
        //   },
        //   {
        //     title: "blue",
        //     attribute: "dependable",
        //     color: {hex: "#dea1a1", R: 222, G: 161, B: 161},
        //   },
        //   {
        //     title: "blue",
        //     attribute: "dependable",
        //     color: {hex: "#9612c7", R: 150, G: 18, B: 199},
        //   },
        //   {
        //     title: "blue",
        //     attribute: "dependable",
        //     color: {hex: "#de5e2c", R: 222, G: 94, B: 44},
        //   },
        //   {
        //     title: "blue",
        //     attribute: "dependable",
        //     color: {hex: "#a1debf", R: 161, G: 222, B: 191},
        //   },
        //   {
        //     title: "blue",
        //     attribute: "dependable",
        //     color: {hex: "#9612c7", R: 150, G: 18, B: 199},
        //   },
        //   {
        //     title: "blue",
        //     attribute: "dependable",
        //     color: {hex: "#dec3a1", R: 222, G: 195, B: 161},
        //   },
        //   {
        //     title: "blue",
        //     attribute: "dependable",
        //     color: {hex: "#9612c7", R: 150, G: 18, B: 199},
        //   },
        //   {
        //     title: "blue",
        //     attribute: "dependable",
        //     color: {hex: "#a1debf", R: 161, G: 222, B: 191},
        //   },
        // ];

        scope.topColorsDummyData = [];

        scope.colorFrequencyDummyData = {
          percentages: [
            "OVER 30%",
            "30% TO 10%",
            "UNDER 10%",
            "0%"
          ],
          mostPopular: [
            {
              percentage: 30,
              title: "color1",
              color: { hex: "#878784" }
            },
            {
              percentage: 30,
              title: "color2",
              color: { hex: "#030405" }
            },
            {
              percentage: 20,
              title: "color3",
              color: { hex: "#f5f5f5" }
            },
            {
              percentage: 20,
              title: "color4",
              color: { hex: "#204899" }
            }
          ],
          moderate: [
            {
              percentage: 40,
              title: "color5",
              color: { hex: "#19b19d" }
            },
            {
              percentage: 20,
              title: "color6",
              color: { hex: "#f7e51a" }
            },
            {
              percentage: 20,
              title: "color7",
              color: { hex: "#f07f16" }
            },
            {
              percentage: 20,
              title: "color8",
              color: { hex: "#e51c1f" }
            }
          ],
          infrequent: [
            {
              percentage: 45,
              title: "color9",
              color: { hex: "#33a936" }
            },
            {
              percentage: 30,
              title: "color10",
              color: { hex: "#e72c86" }
            },
            {
              percentage: 25,
              title: "color11",
              color: { hex: "#6b4797" }
            }
          ],
          not_used: [
            {
              percentage: 50,
              title: "color12",
              color: { hex: "#fdeecd" }
            },
            {
              percentage: 50,
              title: "color13",
              color: { hex: "#9a4e1e" }
            }
          ]
        };

        scope.industryTopColors = [
          {
            title: "S0300-N",
            color: { hex: "#f4f4ef" },
            percentage: 40
          },
          {
            title: "S900-N",
            color: { hex: "#18191a" },
            percentage: 30
          },
          {
            title: "S0585-Y80R",
            color: { hex: "#ce3c30" },
            percentage: 20
          },
          {
            title: "RED 2",
            color: { hex: "#ba3432" },
            percentage: 10
          },
        ];

        scope.loadMoreIndustryColorData = () => {  
          if (!scope.isLoadedAllIndustryData && scope.moreIndustryColorData.length > 0) { 
            scope.industryColorData = scope.industryColorData.concat(scope.moreIndustryColorData);
            scope.isLoadedAllIndustryData = true;
            scope.moreIndustryColorData = [];
          }
          else{ 
          // call again 
          scope.page += 1;
          scope.isLoadedAllIndustryData = false;
          brandingDashboardRepository.industry
            .getIndustryColorsData(scope.searchId, scope.page)
            .then((data) => {
              scope.moreIndustryColorData = data.chartData9;
              if (scope.moreIndustryColorData.length > 0) {
                scope.selectedIndustryData = scope.moreIndustryColorData.find((item) => item.name === scope.industryHeaderData.title);
                scope.otherIndustriesData = scope.moreIndustryColorData.filter(item => item.name !== scope.industryHeaderData.title);  
                scope.industryColorData = scope.industryColorData.concat(scope.otherIndustriesData);
                scope.moreIndustryColorData = scope.industryColorData.concat(scope.otherIndustriesData); 
                scope.isLoadedAllIndustryData = true; 
              }
            });
          }
        };

        scope.loadMoreBrandColorAttributeData = () => { 
          if (!scope.isLoadedAllBrandColorAttributeData) {
            scope.brandAttributeData = scope.brandAttributeData.concat(scope.moreBrandColorAttributeData);
            scope.isLoadedAllBrandColorAttributeData = true;
            scope.moreBrandColorAttributeData = [];
          }
          else{
          // call again 
          scope.page += 1;
          scope.isLoadedAllBrandColorAttributeData = false;
          brandingDashboardRepository.brand
            .getColorAttributes(scope.searchId, scope.page)
            .then((data) => {   
              scope.moreBrandColorAttributeData = data.chartData6;
              if (scope.moreBrandColorAttributeData.length > 10) {
                scope.brandAttributeData = scope.brandAttributeData.concat(scope.moreBrandColorAttributeData);
                scope.isLoadedAllBrandColorAttributeData = true;
              } 
            });
          }
        };

        scope.loadMoreBrandAttributeData = () => { 
          if (scope.moreBrandAttributeData.length > 0) {
            scope.attributeData = scope.attributeData.concat(scope.moreBrandAttributeData);
            scope.isLoadedAllBrandAttributeData = false;
            scope.moreBrandAttributeData = [];
          }
          else{
          // call again 
          scope.page += 1;
          scope.isLoadedAllBrandAttributeData = false;
          brandingDashboardRepository.color
            .getAttributeData(scope.searchId, scope.page)
            .then((data) => {   
              scope.moreBrandAttributeData = data.chartData5;
              if (scope.moreBrandAttributeData.length > 12) {
                scope.attributeData = scope.attributeData.concat(scope.moreBrandAttributeData); 
                scope.moreBrandAttributeData = [];
              } else {
                scope.attributeData = scope.attributeData.concat(scope.moreBrandAttributeData); 
                scope.isLoadedAllBrandAttributeData = true;
                scope.moreBrandAttributeData = [];
              }
            });
          }
        };

        scope.loadMoreActualColorData = () => { 
          scope.isLoadingControls = true; 
          scope.currentPage +=1;
          brandingDashboardRepository.attribute
            .getAcutalColors(scope.searchTitle, scope.azMode, scope.currentPage, scope.colorID)
            .then((data) => {  
              scope.acutalColorDependableData = scope.acutalColorDependableData.concat(data.chartData11.color_names);
              scope.isLoadedAcutalColorData = data.chartData11.paginate.current_page === data.chartData11.paginate.total_pages ? true : false;
              scope.isLoadingControls = false; 
            }).finally(() => {
              scope.isLoadingControls = false;
            });
        }; 

        scope.handleChangeColor = (item) => {  
          scope.isLoadingControls = true; 
          scope.currentPage = 1;
          scope.selectedColor = item;
          scope.colorID = item.id;
          brandingDashboardRepository.attribute
            .getAcutalColors(scope.searchTitle, scope.azMode, scope.currentPage, scope.colorID)
            .then((data) => {  
              scope.acutalColorDependableData = data.chartData11.color_names;
              scope.isLoadedAcutalColorData = data.chartData11.paginate.current_page === data.chartData11.paginate.total_pages ? true : false;
              scope.isLoadingControls = false; 
            }).finally(() => {
              scope.isLoadingControls = false;
            });
        }

        scope.isHandleChangeColorSelected = (value) => {
          return scope.selectedColor === value;
        };

      };

      return {
        restrict: "E",
        templateUrl:
          "app/directives/dbBrandingColorData/dbBrandingColorDataView.html",
        link: link,
        scope: {
          originalData: "=data",
          companies: "=",
          chart: "=",
          filter: "=",
          searchId: "=",
          searchTitle: "=",
          brandTitle: "=",
          viewMode: "=", // company | industry | attribute | color
          azmode: "=",
          colorList: "="
        },
      };
    },
  ]);
})(angular);
