(function () {
  "use strict";

  angular.module("app").directive("hueChartColorsUniqueWithLevels", [
    "common",
    "config",
    "chartsHelper",
    "reduceValue",
    function (common, config, chartsHelper, reduceValue) {
      const _colors = [
        {
          value: 220,
          color: "#f5f5dc",
          percentage: 0.11777301927194861,
          title: "Beige",
          group: "achr",
        },
        {
          value: 20,
          color: "#000000",
          percentage: 0.010706638115631691,
          title: "Black",
          group: "chr",
        },
        {
          value: 158,
          color: "#0000ff",
          percentage: 0.08458244111349036,
          title: "Blue",
          group: "achr",
        },
        {
          value: 124,
          color: "#964b00",
          percentage: 0.06638115631691649,
          title: "Brown",
          group: "achr",
        },
        {
          value: 174,
          color: "#00ffff",
          percentage: 0.09314775160599571,
          title: "Cyan",
          group: "achr",
        },
        {
          value: 306,
          color: "#c0c0c0",
          percentage: 0.16381156316916487,
          title: "Gray",
          group: "achr",
        },
        {
          value: 62,
          color: "#008000",
          percentage: 0.033190578158458245,
          title: "Green",
          group: "achr",
        },
        {
          value: 64,
          color: "#ff00ff",
          percentage: 0.034261241970021415,
          title: "Magenta",
          group: "achr",
        },
        {
          value: 191,
          color: "#ff7f00",
          percentage: 0.10224839400428265,
          title: "Orange",
          group: "achr",
        },
        {
          value: 158,
          color: "#ff0000",
          percentage: 0.08458244111349036,
          title: "Red",
          group: "achr",
        },
        {
          value: 146,
          color: "#8f00ff",
          percentage: 0.07815845824411134,
          title: "Violet",
          group: "achr",
        },
        {
          value: 3,
          color: "#ffffff",
          percentage: 0.0016059957173447537,
          title: "White",
          group: "chr",
        },
        {
          value: 103,
          color: "#ffff00",
          percentage: 0.05513918629550321,
          title: "Yellow",
          group: "achr",
        },
        {
          value: 139,
          color: "#8db600",
          percentage: 0.07441113490364026,
          title: "Yellow/Green",
          group: "achr",
        },
      ];

      function link(scope, element, attributes) {
        scope.$watch("data", bindData);

        function bindData() {
          if (!scope.data) {
            return;
          }

          const containerUnique = element
            .find('[chart-type="unique"]')
            .html("");
          const containerLevels = element
            .find('[chart-type="levels"]')
            .html("");
          const containerGroups = element
            .find('[chart-type="groups"]')
            .html("");

          const options = {};
          options.layout = {
            sections: {
              horizontal: 7,
              vertical: 2,
            },
          };
          options.data = options.data || {};
          if (!scope.data.grouped) {
            options.layout = {
              sections: {
                horizontal: 1,
                vertical: 1,
              },
            };
          }
          options.data.isOrdered = !!scope.data.ordered;
          let dataUnique = scope.data.unique;
          if (!scope.data.ordered) {
            // randomize additionally
            dataUnique = _.sortBy(
              _.map(dataUnique, function (d) {
                d.__order = Math.random();
                return d;
              }),
              "__order"
            );
          }
          let groups = [{}];
          if (scope.data.grouped) {
            groups = _colors;
          }

          let ch = new chartBricks({
            data: {
              colors: dataUnique,
              groups: groups,
            },
            container: containerUnique[0],
            options: options,
          });

          const data = _.map(scope.data.groups, function (d) {
            const value = d.percentage.replace('%','');
            return {
              value: value,
              valueTitle: d.percentage,
              value2: d.percentage.replace('%',''),
              valueTitle2: d.count,
              title: d.name,
              color: d.color,
            };
          });

          const colors = _.map(scope.data.groups, function (d) {
            const value = Math.round(d.percentage * 100);
            return {
              value: reduceValue.reduce(d.value),
              valueTitle: Math.round(value) + "%",
              value2: reduceValue.reduce(d.value),
              valueTitle2: Math.round(d.percentage * 100),
              title: d.title,
              color: d.color,
            };
          });

          let maxValue = _.max(
            _.map(data, function (d) {
              return d.value;
            })
          );
          if (maxValue < 20) {
            maxValue = 20;
          }

          // if (scope.board === "auto") {
          //   ch = new chartDotsHorizontal({
          //     data: colors,
          //     container: containerGroups[0],
          //     options: {},
          //   });
          // } else {
            ch = new chartLevelsVertical({
              data: data,
              container: containerLevels[0],
              options: { bars: { maxValue: maxValue } },
            });
          // }
        }
        const windowResizeHandler = _.debounce(bindData, 200);
        scope.$on("$destroy", function () {
          $(window).off("resize", windowResizeHandler);
        });
        $(window).resize(windowResizeHandler);
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          data: "=",
          board: "=",
        },
        templateUrl:
          "app/directives/custom-infographics-directives/chart-colors-unique-with-levels.html",
      };

      return directive;
    },
  ]);
})();
