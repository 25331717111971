angular.module("app").directive("comparativeViewAcrossAllCities", () => {
  let link = (scope) => {

    scope.widthDynamic = percent => {
      if (percent == 0){
        return 0
      } else if(percent > 0 && percent <= 1){
        return 15;
      } else if(percent > 1 && percent <= 5){
        return 30;
      } else if(percent > 5 && percent <= 10){
        return 45;
      } else if(percent > 10 && percent <= 15){
        return 60;
      } else if(percent > 15 && percent <= 20){
        return 75;
      } else if(percent > 20){
        return 90;
      }
    }
  };

  

  return {
    restrict: "E",
    templateUrl: "app/directives/dbComparativeAcrossAllCities/dbComparativeAcrossAllCitiesView.html",
    link: link,
    scope: {
      selectedColor: "=",
      data: "=",
    }
  };
});
