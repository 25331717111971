angular.module("app").directive("flowerLifespanPieChart", function () {
  function link(scope) {
    scope.$watch('selectedItem', function (newValue) {
      if (newValue) {
        scope.selectedItem = newValue;
        bindData();
      }
    }, true);

    function bindData() {
      const element = document.getElementById(`my_flower_lifespan_${scope.index}_dataviz`);
      if (element) {
        element.innerHTML = "";
      }

      const width = 410;
      const height = 300;
      const margin = 20;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      const svg = d3
        .select(`#my_flower_lifespan_${scope.index}_dataviz`)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      // Compute the position of each group on the pie:
      const pie = d3.layout
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return 33.33;
        });
      const data_ready = pie(d3.entries(scope.selectedItem.lifespan_desitribution));

      // The arc generator
      const arc = d3.svg
        .arc()
        .innerRadius(radius * 0.7) // This is the size of the donut hole
        .outerRadius(radius * 0.8);

      const arc2 = d3.svg
        .arc()
        .innerRadius(radius * 0.8)
        .outerRadius(radius * 0.9);

      // Another arc that won't be drawn. Just for labels positioning
      const outerArc = d3.svg
        .arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", scope.selectedItem.hex)
        .attr(
          "stroke",
          scope.selectedItem.hex === "#ffffff" ? "#E0E0DD" : "white"
        );

      // Add the polylines between chart and labels:
      svg
        .selectAll("allPolylines")
        .data(data_ready)
        .enter()
        .append("polyline")
        .attr("stroke", "black")
        .style("fill", "none")
        .attr("stroke-width", 1)
        .attr("points", function (d) {
          const posA = arc2.centroid(d); // line insertion in the slice
          const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
          const posC = outerArc.centroid(d); // Label position = almost the same as posB
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
          posC[0] = radius * 0.9 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left

          return [posA, posB, posC];
        });

      // Add label percentage
      svg
        .selectAll("allLabels")
        .data(data_ready)
        .enter()
        .append("text")
        .text((d) => `${d.data.key}`)
        .attr("transform", function (d) {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1);
          pos[1] -= 8;
          return "translate(" + pos + ")";
        })
        .attr("font-size", "12px")
        .attr("font-weight", "300")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B; text-transform: uppercase;")
        .style("text-anchor", function (d) {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midangle < Math.PI ? "start" : "end";
        });

      // Add label percentage
      svg
        .selectAll("allLabels")
        .data(data_ready)
        .enter()
        .append("text")
        .text((d) => `${d.data.value}%`)
        .attr("transform", function (d) {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1);
          pos[1] += 13;
          return "translate(" + pos + ")";
        })
        .attr("font-size", "14px")
        .attr("font-weight", "300")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B")
        .style("text-anchor", function (d) {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midangle < Math.PI ? "start" : "end";
        });

      if (scope.mode === 'infographic') {
        svg
          .append("image")
          .attr("href", scope.selectedItem.img_url)
          .attr("width", 50)
          .attr("height", 50)
          .attr("transform", "translate(-25, -70)");

        svg
          .append("text")
          .text("239")
          .attr("transform", "translate(0, 20)")
          .attr("font-size", "40px")
          .attr("text-anchor", "middle")
          .attr("style", "font-family: Gotham SSm A, Gotham SSm B");

        svg
          .append("text")
          .text("FLOWERS")
          .attr("transform", "translate(0, 50)")
          .attr("font-size", "22px")
          .attr("text-anchor", "middle")
          .attr("style", "font-family: Gotham SSm A, Gotham SSm B");
      }
      else {
        svg
          .append("text")
          .text(scope.selectedItem.count)
          .attr("transform", "translate(0, 0)")
          .attr("font-size", "25px")
          .attr("text-anchor", "middle")
          .attr("style", "font-family: Gotham SSm A, Gotham SSm B");

        svg
          .append("text")
          .text("FLOWERS")
          .attr("transform", "translate(0, 24)")
          .attr("font-size", "20px")
          .attr("text-anchor", "middle")
          .attr("style", "font-family: Gotham SSm A, Gotham SSm B");
      }
    }
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/dbFlowerLifespanPieChart/dbFlowerLifespanPieChartView.html",
    link: link,
    scope: {
      selectedItem: "=",
      mode: "=",
      index: "=",
    },
  };
});
