angular.module("app").directive("birdFamiliesRepresentation", () => {
  let link = (scope) => {
    scope.birdColors = [
      {
        id: 0,
        title: 'mixed',
        hex: "#a3d8e4",
        hex1: "#ca7db2",
        img_url: "/assets/img/nature/bird-colors/mixed.png",
      },
      {
        id: 1,
        hex: '#f7c6de',
        title: 'pink',
        img_url: "/assets/img/nature/bird-colors/pink.png",
      },
      {
        id: 2,
        hex: '#e51d1c',
        title: 'red',
        img_url: "/assets/img/nature/bird-colors/red.png",
      },
      {
        id: 3,
        hex: '#c55531',
        title: 'brown',
        img_url: "/assets/img/nature/bird-colors/brown.png",
      },
      {
        id: 4,
        hex: '#f07c16',
        title: 'darkorange',
        img_url: "/assets/img/nature/bird-colors/darkorange.png",
      },
      {
        id: 5,
        hex: '#fbdf16',
        title: 'yellow',
        img_url: "/assets/img/nature/bird-colors/yellow.png",
      },
      {
        id: 6,
        hex: '#f0dc83',
        title: 'lemonchiffon',
        img_url: "/assets/img/nature/bird-colors/lemonchiffon.png",
      },
      {
        id: 7,
        hex: '#8db624',
        title: 'yellowgreen',
        img_url: "/assets/img/nature/bird-colors/yellowgreen.png",
      },
      {
        id: 8,
        hex: '#0a8236',
        title: 'green',
        img_url: "/assets/img/nature/bird-colors/green.png",
      },
      {
        id: 9,
        hex: '#335ca7',
        title: 'blue',
        img_url: "/assets/img/nature/bird-colors/blue.png",
      },
      {
        id: 10,
        hex: '#6d4595',
        title: 'purple',
        img_url: "/assets/img/nature/bird-colors/purple.png",
      },
      {
        id: 11,
        hex: '#eed5b4',
        title: 'wheat',
        img_url: "/assets/img/nature/bird-colors/wheat.png",
      },
      {
        id: 12,
        hex: '#974d15',
        title: 'brown-1',
        img_url: "/assets/img/nature/bird-colors/brown1.png",
      },
      {
        id: 13,
        hex: '#808081',
        title: 'gray',
        img_url: "/assets/img/nature/bird-colors/gray.png",
      },
      {
        id: 14,
        hex: '#ffffff',
        title: 'white',
        img_url: "/assets/img/nature/bird-colors/white.png",
      },
      {
        id: 15,
        hex: '#050607',
        title: 'black',
        img_url: "/assets/img/nature/bird-colors/black.png",
      },
    ];

    scope.allData = scope.data.filter(item => item.title !== scope.selectedItem.title);
    scope.selectedData = scope.data.find(item => item.title === scope.selectedItem.title);
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbBirdFamiliesRepresentation/dbBirdFamiliesRepresentationView.html",
    link: link,
    scope: {
      data: "=",
      selectedItem: "="
    }
  };
});
