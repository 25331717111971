angular.module('app').directive('colorShapesPercentageTherapeuticArea', () => {
  let link = (scope) => {
    scope.isWhite = color => {
      return color === '#ffffff';
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorShapesPercentByTherapeuticArea/dbColorShapesPercentByTherapeuticAreaView.html",
    link: link,
    scope: {
      selectedArea: "=",
      therapeuticAreas: "="
    }
  };
});
