angular.module('app').directive('infographicsForHome', [
  '$location',
  '$timeout',
  'customInfographicsConstants',
  function (
    $location,
    timeout,
    customInfographicsConstants
  ) {
    function link(scope) {
      scope.meta = {};
      scope.isFilterVisible = customInfographicsConstants.isFilterVisible;
    }

    return {
      restrict: 'E',
      templateUrl:
        'app/components/customized-infographics/infographicsForHome/infographicsForHome.html',
      link: link,
      scope: {
        selectedMenuId: '=',
        exportJpgThroughDomToImage: '='
      }
    };
  }
]);
