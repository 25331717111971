angular.module("app").directive("infographicsForSport", [
  "$location",
  "$timeout",
  "customInfographicsConstants",
  "charts",
  "appConfig",
  "$http",
  '$q',
  function (
    $location,
    timeout,
    customInfographicsConstants,
    charts,
    appConfig,
    $http,
    $q
  ) {
    function link(scope) {
      scope.currentYear = moment().year();
      scope.meta = {};
      scope.isFilterVisible = customInfographicsConstants.isFilterVisible;

      scope.filtersForSport = {
        color: [
          { id: "all", title: "COLOR"},
          { id: 0, hex: '#e51d1c', title: 'red' },
          { id: 1, hex: '#ef7c17', title: 'orange' },
          { id: 2, hex: '#ede437', title: 'yellow' },
          { id: 3, hex: '#87af27', title: 'OliveDrab' },
          { id: 4, hex: '#0c7c34', title: 'green' },
          { id: 5, hex: '#6dc6d5', title: 'SkyBlue' },
          { id: 6, hex: '#1a4899', title: 'blue' },
          { id: 7, hex: '#5d4394', title: 'indigo' },
          { id: 8, hex: '#a3509a', title: 'purple' },
          { id: 9, hex: '#924b14', title: 'brown' },
          { id: 10, hex: '#f2f3da', title: 'silver' },
          { id: 11, hex: '#c0c0bf', title: 'grey' },
          { id: 12, hex: '#ffffff', title: 'white' },
          { id: 13, hex: '#020303', title: 'black' },
        ],
        type: [
          {
            "id": 0,
            "title": "Baseball",
            "img_url": "/assets/img/sports/types/baseball.png"
          },
          {
            "id": 1,
            "title": "Basketball",
            "img_url": "/assets/img/sports/types/basketball.png"
          },
          {
            "id": 2,
            "title": "Football",
            "img_url": "/assets/img/sports/types/football.png"
          },
          {
            "id": 3,
            "title": "Hockey",
            "img_url": "/assets/img/sports/types/hockey.png"
          },
          {
            "id": 4,
            "title": "Soccer",
            "img_url": "/assets/img/sports/types/soccer.png"
          },
          {
            "id": 5,
            "title": "Lacrosse",
            "img_url": "/assets/img/sports/types/lacrosse.png"
          },
          {
            "id": 6,
            "title": "Rugby",
            "img_url": "/assets/img/sports/types/rugby.png"
          },
        ]
      };

      scope.chartsForSport = [
        {
          qNumber: "SPORT1",
          title: "USE OF (COLOR) ACROSS ALL SPORTS",
          id: "useOfColorAcrossAllSports",
          filters: {
            colorForSport: true,
          },
        },
        {
          qNumber: "SPORT2",
          title: "POPULAR COLOR COMBINATIONS FOR (COLOR) ACROSS ALL SPORTS",
          id: "popularColorCombinations",
          filters: {
            colorForSport: true,
          },
        },
        {
          qNumber: "SPORT3",
          title: "USE OF (COLOR) ACROSS ALL (SPORT) TEAMS",
          id: "useOfColorAcrossSportTeams",
          filters: {
            colorForSport: true,
            typeForSport: true,
          },
        },
        {
          qNumber: "SPORT4",
          title: "COLOR COMBINATIONS FOR EACH OF THE (SELECTED SPORT) TEAMS",
          id: "colorCombinationForEachSportTeam",
          filters: {
            typeForSport: true,
          },
        },
        {
          qNumber: "SPORT5",
          title: "COLOR USE ACROSS ALL (SELECTED SPORT) TEAMS",
          id: "colorUseAcrossAllSportTeams",
          filters: {
            typeForSport: true,
          },
        },
        {
          qNumber: "SPORT6",
          title: "COLOR USE COMPARISON FOR (SELECTED SPORT) AND ALL OTHER SPORTS",
          id: "sportColorUseComparison",
          filters: {
            typeForSport: true,
          },
        },
        {
          qNumber: "SPORT7",
          title: "USE OF COLORS BY ALL (SPORTS) TEAMS",
          id: "useOfColorsByAllSportTeams",
          filters: {
            typeForSport: true,
          },
        },
      ];

      scope.currentChartForSport = scope.chartsForSport[0];
      scope.currentColorForSport = scope.filtersForSport.color[0];
      scope.currentTypeForSport = scope.filtersForSport.type[0];

      scope.colorDataAcrossSports =
        customInfographicsConstants.colorDataAcrossSports;
      scope.sportColorCombinationData =
        customInfographicsConstants.sportColorCombinationData;
      scope.colorDataAcrossTeams =
        customInfographicsConstants.colorDataAcrossTeams;
      scope.colorCombinationTeamData =
        customInfographicsConstants.colorCombinationTeamData;
      scope.colorDataAcrossAllSportTeams =
        customInfographicsConstants.colorDataAcrossAllSportTeams;
      scope.sportComparisonData =
        customInfographicsConstants.sportComparisonData;
      scope.sportTeamUsedColorData =
        customInfographicsConstants.sportTeamUsedColorData;

      scope.updateColor = function (color) {
        scope.currentColorForSport = color;
      };
      scope.updateChart = function (chart) {
        scope.currentChartForSport = chart;
      };
      scope.updateType = function (type) {
        scope.currentTypeForSport = type;
      };

      scope.refresh = () => {};

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
          result += name.toUpperCase() + ".";

        return result + date + type;
      };

      scope.exportJpgThroughDomToImage = (name) => {
        let node = angular.element("#sportCapture")[0];
        
        const titles = angular.element(".graphic-titles");
        const header = angular.element(".graphic-titles-export");
        const footer = angular.element(".customized-infographics-footer");

        footer.css({ display: "block" });
        header.css({ display: "block" });
        titles.css({ display: "none" });
        $("html").addClass("hide-scrollbar");

        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            const img = new Image();
            img.src = dataUrl;

            const link = document.createElement("a");
            link.href = img.src;
            link.download = scope.generateImgName(".png", "brand", name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            $("html").removeClass("hide-scrollbar");
            footer.css({ display: "none" });
            header.css({ display: "none" });
            titles.css({ display: "block" });
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      };
    }

    return {
      restrict: "E",
      templateUrl:
        "app/components/customized-infographics/infographicsForSport/infographicsForSport.html",
      link: link,
      scope: {
      },
    };
  },
]);
