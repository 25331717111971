angular
  .module('app')
  .service('paymentService', ['httpService', 'appConfig', 'authService', '$http', function (httpService, appConfig, authService, $http) {
    this.getSubscription = function () {
      return httpService.get(appConfig.dashboardServiceUrl + 'api/subscriptions.json', {
        params: { token: authService.token},
        headers: authService.getHeaderConfig().headers
      }).then(data => data.data);
    };

    this.getPlan = function () {
      return httpService.get(appConfig.dashboardServiceUrl + 'api/subscriptions/plans').then(data => data.data);
    };

    this.createSubscription = function (data) {
      return httpService.post(appConfig.dashboardServiceUrl + 'api/subscriptions/subscriptions/subscribed', data).then(data => data.data);
    };

    this.joinSubscription = function (data) {
      return $http({
        url:
          appConfig.dashboardServiceUrl +
          "api/subscriptions/subscriptions/subscribed",
        method: "POST",
        headers: authService.getHeaderConfig().headers,
        data: data,
      }).then(data => data.data);
    };

    this.upgradeSubscription = function (data) {
      return $http({
        url:
          appConfig.dashboardServiceUrl +
          "api/subscriptions/subscriptions/upgrade",
        method: "POST",
        headers: authService.getHeaderConfig().headers,
        data: data,
      }).then(data => data.data);
    };

    this.getCurrentPlan = function () {
      return httpService.get(appConfig.dashboardServiceUrl + `api/subscriptions/plans/current_plan`, {
        headers: authService.getHeaderConfig().headers
      }).then(data => data.data);
    };

    this.getSubscriptionPrice = function (data) {
      return httpService.get(appConfig.dashboardServiceUrl + `api/subscriptions/${data}.json`, {
        params: { token: authService.token},
        headers: authService.getHeaderConfig().headers
      }).then(data => data.data);
    };

    this.chargePayment = function (data) {
      const params = data;
      params.token = authService.token;
      return httpService.post(appConfig.dashboardServiceUrl + 'api/subscriptions.json', {
        params: params,
        headers: authService.getHeaderConfig().headers
      }).then(data => data.data);
    };

    this.getInviteTeamList = function () {
      return httpService.get(appConfig.dashboardServiceUrl + `api/invite/teams`, {
        headers: authService.getHeaderConfig().headers
      }).then(data => data.data);
    };

    this.createInviteMember = function (data) {
      return $http({
        url:
          appConfig.dashboardServiceUrl +
          "api/invite/teams",
        method: "POST",
        headers: authService.getHeaderConfig().headers,
        data: data,
      }).then(data => data.data);
    };

    this.deleteInviteMember = function (data) {
      return $http({
        url: appConfig.dashboardServiceUrl + 'api/invite/teams/'+ data,
        method: "delete",
        headers: authService.getHeaderConfig().headers,
      }).then(data => data.data);
    };

  }]);
