angular.module("app").directive("topFiveIndustryPieChart", function () {
  function link(scope) {
    scope.$watch("data", bindData);

    function bindData() {
      const element = document.getElementById(`my_fashion_color_${scope.mode}_dataviz`);
      if (element) {
        element.innerHTML = "";
      }

      const output = [scope.data.logo_svg.slice(0, 4), ` width='50' height='50'`, scope.data.logo_svg.slice(4)].join('');

      let width = 175;
      let height = 450;
      const margin = 0;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      const svg = d3
        .select(`#my_fashion_color_${scope.mode}_dataviz`)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      // Create chart data
      const percentageData = {};
      const colorData = {};
      for (const item of scope.data.colors) {
        colorData[item.color_id] = item.hex;
        if(typeof(item.percentage) == "string" && item.percentage.indexOf('%') > -1) {
          percentageData[item.color_id] = item.percentage.replace('%','');
        } else {
          percentageData[item.color_id] = item.percentage;
        }
      }

      // Compute the position of each group on the pie:
      const pie = d3.layout
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value;
        });
      const data_ready = pie(d3.entries(percentageData));

      // The arc generator
      const arc = d3.svg
        .arc()
        .innerRadius(radius * 0.85) // This is the size of the donut hole
        .outerRadius(radius * 0.95);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      if(data_ready.length > 0) {
        svg
          .selectAll("allSlices")
          .data(data_ready)
          .enter()
          .append("path")
          .attr("d", arc)
          .attr("fill", function (d) {
            return colorData[d.data.key];
          })
          .attr(
            "stroke", function (d) {
              return colorData[d.data.key] === "#FFFFFF"
                ? "#E0E0DD"
                : colorData[d.data.key];
            }
          )
          .style(
            "stroke-width",  function (d) {
              return colorData[d.data.key] === "#FFFFFF"
                ? "1px"
                : "0px"
            }
          );

        const lineHeight = -400 * (scope.data.total_percentage / 100);

        svg.append('line')
          .style({stroke: '#000', 'stroke-width': 1})
          .attr("transform", "translate(0, -90)")
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', 0)
          .attr('y2', lineHeight);
        svg.append('line')
          .style({stroke: '#000', 'stroke-width': 1})
          .attr("transform", "translate(-10, -90)")
          .attr('x1', 20)
          .attr('x2', 0)
          .attr('y1', lineHeight)
          .attr('y2', lineHeight);
        svg.append('text')
          .text(`${scope.data.total_percentage}%`)
          .attr("transform", "translate(0, -90)")
          .attr('text-anchor', 'middle')
          .attr("font-size", "16px")
          .attr('y', lineHeight - 10);
      }

      if (scope.data.logo) {
        svg
          .append("text")
          .text(scope.data.name)
          .attr("transform", "translate(0, 25)")
          .attr("font-size", "12px")
          .attr("text-anchor", "middle")
          .attr("style", "font-family: Gotham SSm A, Gotham SSm B;")
          .call(wrap);

        svg
          .append("g")
          .attr("transform", "translate(-25, -40)")
          .append("svg")
          .html(output)
          .attr("width", 50)
          .attr("height", 50)
      }

    }
    function wrap(text) {
      text.each(function() {
          var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          y = 0,
          dy = 0,
          tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");     
          while (word = words.pop()) {
              line.push(word);
              line.forEach((element, index) => {
                tspan = text.append("tspan").attr("x", 0).attr("y", function(d) {
                  return index === 0 ? 0 : index === 1 ? 15 : index === 2 ? 30 : 45 }).attr("dy", dy + "em").text(element);
              });
          }
      });
  }
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/custom-infographics-brand-directives/topFiveIndustryPieChart/topFiveIndustryPieChartView.html",
    link: link,
    scope: {
      data: "=",
      mode: "=",
    },
  };
});
