angular.module("app").directive("unicornLogosContent", [
  "$timeout",
  "$window",
  (timeout, window) => {
    let link = (scope, element) => {

      scope.showImageDetails = false;

      scope.itemHeight = () => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        return (scope.elemHeight =
          elements[0].clientWidth && elements[0].clientWidth + "px");
      };

      scope.itemHeight();

      $(window).resize(() => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        timeout(() => {
          return (scope.elemHeight = elements[0].clientWidth + "px");
        }, 10);
      });

      scope.toggleImageDetails = () => {
        scope.showImageDetails = !scope.showImageDetails;
      };

      scope.imageClickHandler = (index) => {
        scope.imagedata = scope.companies[index];
        scope.index = index;
        scope.toggleImageDetails();
      };

      scope.$on("mood_state_changed", () => {
        scope.toggleImageDetails();
      });
    };

    return {
      restrict: "E",
      templateUrl:
        "app/directives/dbColorUnicornData/unicornLogoContent/unicornLogoContentView.html",
      link: link,
      scope: {
        companies: "=",
      },
    };
  },
]);
