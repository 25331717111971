angular.module("app").directive("sportColorTeamComparison", () => {
  let link = (scope) => {
    scope.showLogoImageDetails = false;
    scope.toggleLogoImageDetails = () => {
      scope.showLogoImageDetails = !scope.showLogoImageDetails;
    };

    scope.imageLogoClickHandler = (index) => {
      scope.logoIndex = index;
      scope.toggleLogoImageDetails();
    };

    scope.showJerseyImageDetails = false;
    scope.toggleJerseyImageDetails = () => {
      scope.showJerseyImageDetails = !scope.showJerseyImageDetails;
    };

    scope.imageJerseyClickHandler = (index) => {
      scope.jerseyIndex = index;
      scope.toggleJerseyImageDetails();
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbSportColorTeamComparison/dbSportColorTeamComparisonView.html",
    link: link,
    scope: {
      selectedTeam: "=",
      data: "="
    }
  };
});
