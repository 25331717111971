angular.module("app").directive("fragranceDesignerOfColor", ["$timeout", "gameDashboardRepository", (timeout, gameDashboardRepository) => {
  let link = (scope) => {  
    scope.characters = [
      "A", "Á", "B", "C", "D", "E", "É", "F", "G", "H", "I", "K",
      "L", "M", "N", "O", "Ö", "P", "Q", "R", "S", "T", "V", "X"
    ];
    scope.selectedCharacter = "ALL";
    scope.saveItemImageDetails = false;

    scope.$watch('data', function (newVal, prevVal) {
      if(newVal) {
        scope.filteredData = scope.data;
        scope.selectedCharacter = scope.selectedGroup === 'All' ? 'ALL' : scope.selectedGroup;
      }

    });
   
    scope.characterSelect = (value) => {
      scope.selectedCharacter = value;
      scope.filteredResults(value === 'ALL' ? 'All' : value);
    };

    scope.isSelected = (value) => {
      return scope.selectedCharacter === value;
    };

    scope.showImageDetails = false;
    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (item, index) => {
      scope.isSubLoadingControls = true;
      if (scope.mode === 'year') {
        gameDashboardRepository
          .getYearChartsData6ByYearId(scope.selectedColor.id, 'chartData6', item.name)
          .then(function (data) {
            scope.imagedata = data.chartData6;
            scope.index = index;
            scope.isSubLoadingControls = false; 
            scope.toggleImageDetails();
            }).finally(() => {
              scope.isSubLoadingControls = false; 
            });
      } else {
        gameDashboardRepository
          .getGenreChartsData4ByGenreId(scope.selectedColor.id, 'chartData4', scope.year, item.name)
          .then(function (data) {
            scope.imagedata = data.chartData4;
            scope.index = index;
            scope.isSubLoadingControls = false; 
            scope.toggleImageDetails();
            }).finally(() => {
              scope.isSubLoadingControls = false; 
            });
      }
      
    };

    scope.$on("mood_state_item_chart", (data) => {
      let mainData = data.targetScope.data;
      let newParam = {};
      if(mainData) {
        const keys = ["VR GAMES", "GENRE", "YEAR", "CREATOR"];
        newParam = {
          [keys[0]]: mainData.games,
          [keys[1]]: mainData.genre,
          [keys[2]]: mainData.year,
          [keys[3]]:  mainData.creator
        }
      }
      let node = angular.element("#fragranceDesignerItemPopupCapture")[0];
      domtoimage
        .toPng(node, {
          bgcolor: "#fff",
        })
        .then((dataUrl) => {
          if(dataUrl) {
            scope.saveImageDetailsData = {
              base64: dataUrl,
              type: 'video',
              data: newParam
            };
          }
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });

        let times = 2000;
        timeout(function () {
          if(scope.saveImageDetailsData) {
            scope.showImageDetails = false;
            scope.toggleSaveImageDetails();
          } else {
            timeout(function () {
              if(scope.saveImageDetailsData) {
                scope.showImageDetails = false;
                scope.toggleSaveImageDetails();
              } else {
                timeout(function () {
                  if(scope.saveImageDetailsData) {
                    scope.showImageDetails = false;
                    scope.toggleSaveImageDetails();
                  }
                }, times);
              }
            }, times);
          }
        }, times);
    });

    scope.generateImgName = function (type, board, name) {
      let result = "";
      const date = moment().format("L");
      result += name.toUpperCase() + ".";
      return result + date + type;
    };

    scope.toggleSaveImageDetails = () => {
      scope.saveItemImageDetails = !scope.saveItemImageDetails;
    };

    scope.$on("mood_state_item_chart_changed", () => {
      scope.toggleSaveImageDetails();
    });

    scope.loadMoreCreator = () => {
      scope.loadMoreData();
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbFragranceDesignerOfColor/dbFragranceDesignerOfColorView.html",
    link: link,
    scope: {
      data: "=",
      selectedColor: "=",
      mode: "=",
      year: "=",
      filteredResults: "=",
      loadMoreData: "=",
      isLoadedAllData: "=",
      selectedGroup: "=",
    }
  };
}]);
