angular.module('app').directive('brandsOwningColorTrademarks', () => {
  let link = (scope) => {
    scope.getDotsCount = value => {
      const count = Math.ceil(7 * value / 10);
      const result = [];
      for (let i = 0; i < count; i++) {
        result.push(i);
      }

      return result;
    };

    scope.loadMore = () => {
      scope.loadMoreBrandData();
    };

    scope.brandName = (item) => {
      scope.clickBrandName(item)
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbBrandsOwningColorTrademarks/dbBrandsOwningColorTrademarksView.html",
    link: link,
    scope: {
      colorData: "=",
      selectedColor: "=",
      isLoadBrandMoreData: "=",
      loadMoreBrandData: "=",
      clickBrandName: "=",
    }
  };
});
