angular
  .module('app')
  .component('termsComponent', {
    templateUrl: 'app/components/terms/terms.tmpl.html',
    controller: function ($http, appConfig) {
      const vm = this;
      vm.init = function () {
        $http.get(appConfig.dashboardServiceUrl + 'api/pages/terms-of-use.json')
          .then(function (res) {
            if (res && res.data) {
              vm.pageData = res.data;
            }
          });
      };
    }
  });
