angular.module("app").directive("colorFlowerFamilyDistributionPieChart", function () {
  function link(scope) {

    scope.$watch('familyDistribution', function (newValue) {
      if (newValue) {
        scope.familyDistribution = newValue;
        let count = scope.familyDistribution.colors.length;
        let fixedHeight = 100 / count;
        scope.familyDistribution.colors.forEach(element => {
          element.height = fixedHeight;
        });
        bindData();
      }
    }, true);

    function bindData() {
      if(scope.mode === 'popup'){
        document.getElementById("my_flower_family_distribution_popup_dataviz").innerHTML = "";
      } else {
        document.getElementById("my_flower_family_distribution_dataviz") ? document.getElementById("my_flower_family_distribution_dataviz").innerHTML = "" : '';
      }

      const width = 1000;
      const height = 500;
      const margin = 40;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      var svg = '';
      if(scope.mode === 'popup'){
        svg = d3
        .select("#my_flower_family_distribution_popup_dataviz")
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
      } else {
        svg = d3
        .select("#my_flower_family_distribution_dataviz")
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
      }

      // Compute the position of each group on the pie:
      const pie = d3.layout
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value.height;
        });
      const data_ready = pie(d3.entries(scope.familyDistribution.colors));

      // The arc generator
      const arc = d3.svg
        .arc()
        .innerRadius(radius * 0.7) // This is the size of the donut hole
        .outerRadius(radius * 0.8);

      const arc2 = d3.svg
        .arc()
        .innerRadius(radius * 0.7)
        .outerRadius(radius * 0.9);

      // Another arc that won't be drawn. Just for labels positioning
      const outerArc = d3.svg
        .arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", function (d) {
          return d.data.value.hex;
        })
        .attr(
          "stroke", function (d) {
            return d.data.value.hex === "#ffffff"
              ? "#E0E0DD"
              : d.data.value.hex;
          }
        )
        .style(
          "stroke-width",  function (d) {
            return d.data.value.hex === "#ffffff"
              ? "1px"
              : "0px"
          }
        );

      // Add the polylines between chart and labels:
      svg
        .selectAll("allPolylines")
        .data(data_ready)
        .enter()
        .append("polyline")
        .attr("stroke", "black")
        .style("fill", "none")
        .attr("stroke-width", 1)
        .attr("points", function (d) {
          const posA = arc2.centroid(d); // line insertion in the slice
          const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
          const posC = outerArc.centroid(d); // Label position = almost the same as posB
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
          posC[0] = radius * 1.7 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left

          return [posA, posB, posC];
        });

      svg
        .selectAll("allLabels")
        .data(data_ready)
        .enter()
        .append("text")
        .text((d) => `${d.data.value.percentage}%`)
        .attr("transform", function (d) {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 1.75 * (midangle < Math.PI ? 1 : -1);
          pos[1] += 8;
          return "translate(" + pos + ")";
        })
        .attr("font-size", "18px")
        .attr("font-weight", "300")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B")
        .style("text-anchor", function (d) {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midangle < Math.PI ? "start" : "end";
        });

      svg
        .append("text")
        .text(scope.familyDistribution.flower_types)
        .attr("transform", "translate(0, -5)")
        .attr("font-size", "40px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B");

      svg
        .append("text")
        .text("FLOWER TYPES")
        .attr("transform", "translate(0, 25)")
        .attr("font-size", "22px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B");
    }
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorFlowerFamilyDistributionPieChart/dbColorFlowerFamilyDistributionPieChartView.html",
    link: link,
    scope: {
      selectedItem: "=",
      familyDistribution: "=",
      mode: "="
    },
  };
});
