angular.module('app').directive('artSchoolComplete', () => {
  let link = (scope) => {
    scope.filteredChoices = [];
    scope.isVisible = {
      suggestions: false,
    };

    // dummy data
    scope.filteredChoices = [
      {
        title: "american",
        img_url: "/assets/img/art/artist/american.png",
      },
      {
        title: "austrian",
        img_url: "/assets/img/art/artist/austrian.png",
      },
      {
        title: "belgian",
        img_url: "/assets/img/art/artist/belgian.png",
      },
      {
        title: "bohemian",
        img_url: "/assets/img/art/artist/bohemian.png",
      },
      {
        title: "catalan",
        img_url: "/assets/img/art/artist/catalan.png",
      },
      {
        title: "danish",
        img_url: "/assets/img/art/artist/danish.png",
      },
      {
        title: "dutch",
        img_url: "/assets/img/art/artist/dutch.png",
      },
      {
        title: "english",
        img_url: "/assets/img/art/artist/english.png",
      },
      {
        title: "finnish",
        img_url: "/assets/img/art/artist/finnish.png",
      },
      {
        title: "flemish",
        img_url: "/assets/img/art/artist/flemish.png",
      },
      {
        title: "french",
        img_url: "/assets/img/art/artist/french.png",
      },
      {
        title: "german",
        img_url: "/assets/img/art/artist/german.png",
      },
      {
        title: "greek",
        img_url: "/assets/img/art/artist/greek.png",
      },
      {
        title: "hungarian",
        img_url: "/assets/img/art/artist/hungarian.png",
      },
      {
        title: "irish",
        img_url: "/assets/img/art/artist/irish.png",
      },
      {
        title: "italian",
        img_url: "/assets/img/art/artist/italian.png",
      },
      {
        title: "netherlandish",
        img_url: "/assets/img/art/artist/netherlandish.png",
      },
      {
        title: "norwegian",
        img_url: "/assets/img/art/artist/norwegian.png",
      },
      {
        title: "polish",
        img_url: "/assets/img/art/artist/polish.png",
      },
      {
        title: "portugees",
        img_url: "/assets/img/art/artist/portugess.png",
      },
      {
        title: "russian",
        img_url: "/assets/img/art/artist/russian.png",
      },
      {
        title: "scotish",
        img_url: "/assets/img/art/artist/scotish.png",
      },
      {
        title: "spanish",
        img_url: "/assets/img/art/artist/spanish.png",
      },
      {
        title: "swedish",
        img_url: "/assets/img/art/artist/swedish.png",
      },
      {
        title: "swiss",
        img_url: "/assets/img/art/artist/swiss.png",
      },
      {
        title: "other",
        img_url: "/assets/img/art/artist/ohter.png",
      },
    ];

    scope.filterItems = () => {
      if (scope.enteredtext.length < 0) return;
      scope.isVisible.suggestions =
        scope.enteredtext.length === 0
          ? false
          : scope.filteredChoices.length > 0;
    };

    scope.selectItem = (choice) => {
      scope.selected = choice;
      scope.enteredtext = choice.title;
      scope.isVisible.suggestions = false;
      scope.selecthandle(scope.selecttype, choice);
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbArtSchoolComplete/dbArtSchoolCompleteView.html",
    link: link,
    scope: {
      enteredtext: "=",
      selected: "=",
      placeholder: "=",
      selecthandle: "=",
      selecttype: "=",
      disabled: "=",
      disable: "=",
      board: "=",
    }
  };
});
