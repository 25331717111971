angular.module("app").directive("globalColorData", [
  "trackDashboardRepository",
  () => {
    let link = (scope) => {
      scope.selectedMentionColors = [];

      scope.setMentionColors = (colors) => {
        scope.selectedMentionColors = colors;
      }
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbGlobalColorData/dbGlobalColorDataView.html",
      link: link,
      scope: {
      }
    };
  }
]);
