angular.module("app").directive("pharmaContent", [
  "$timeout",
  "$window",
  (timeout, window) => {
    let link = (scope, element) => {
      scope.data = [];
      scope.showCollection = false;
      scope.showImageDetails = false;
      scope.saveImageDetails = false;
      scope.imageDetailsData = null;
      window.scr = scope;
      scope.collectionDataList = [];

      scope.itemHeight = () => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        return (scope.elemHeight =
          elements[0].clientWidth && elements[0].clientWidth + "px");
      };

      scope.itemHeight();

      $(window).resize(() => {
        const elements = element.find(".logo-item");
        if (!elements.length || elements[0].clientWidth <= 0) {
          return;
        }
        timeout(() => {
          return (scope.elemHeight = elements[0].clientWidth + "px");
        }, 10);
      });

      scope.toggleImageDetails = () => {
        scope.showImageDetails = !scope.showImageDetails;
      };

      scope.imageClickHandler = (pill, index) => {
        scope.imagedata = scope.pills[index];
        scope.index = index;
        scope.toggleImageDetails();
      };

      scope.$on("mood_state", (data) => {
        scope.saveImageDetailsData = data.targetScope.data;
        scope.toggleImageDetails();
        scope.toggleSaveImageDetails();
      });

      scope.toggleSaveImageDetails = () => {
        scope.saveImageDetails = scope.saveImageDetails ? false : true;
      };

      scope.$on("mood_state_changed", () => {
        scope.toggleSaveImageDetails();
      });
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbPharmaContent/dbPharmaContentView.html",
      link: link,
      scope: {
        originalData: "=data",
        pills: "=",
        chart: "=",
        title: "=",
        searchId: "=",
      },
    };
  },
]);
