angular.module('app').service('dateTimeFormatter', [
  function () {
    const monthData = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];

    // ex: January 2, 2021
    this.formatDateForView = (value) => {
      if (value) {
        const date = new Date(value);

        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        return `${monthData[month]} ${day}, ${year}`;
      }

      return '';
    };


    // ex: January 2021
    this.formatDateForMonthAndYear = (value) => {
      if (value) {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = date.getMonth();

        return `${monthData[month]} ${year}`;
      }

      return '';
    };

    // ex: 2021-01-02
    this.formatDateWithDash = (value) => {
      if (value) {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        const monthToString = month < 10 ? '0' + month : month;
        const dayToString = day < 10 ? '0' + day : day;

        return `${year}-${monthToString}-${dayToString}`;
      }

      return '';
    }
  }
]);
