angular.module("app").directive("fashionFiveYearColorComparisonChart", () => {
  let link = (scope) => {
    scope.regions = [
      "All",
      "Europe",
      "North America",
      "South America",
      "Asia",
      "Australia"
    ];
    scope.blackMode = false;
    scope.selectedRegion = scope.regions[0];
    scope.isSelectedRegion = (region) => {
      return scope.selectedRegion === region;
    };
    scope.selectRegion = (region) => {
      scope.selectedRegion = region;
    };
    scope.switchGraph = () => {  
      if (scope.blackMode) {
        scope.data = scope.fullData.filter(item => item.color.hex !== '#000000');
      } else {
        scope.data = scope.fullData;
      }
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbFashionFiveYearColorComparisonChart/dbFashionFiveYearColorComparisonChartView.html",
    link: link,
    scope: {
      data: "=",
      name: "=",
      fullData: "="
    }
  };
});
