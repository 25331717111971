angular.module('app').directive('hueDbColorFrequencyVideo', function () {
  function link(scope, element, attrs) {
    scope.tooltipsterConfig = {
      animation: 'fade',
      theme: 'tooltipster-default',
      trigger: 'custom',
      position: 'bottom'
    };

    scope.activeIndex = 0;
    scope.data = null;
    scope.selectedBar = null;

    scope.expandedGroupClass = null;
    scope.setExpandedGroupClass = function (groupClass) {
      scope.expandedGroupClass = groupClass;
    };
    scope.showSearchedColor = function () {
      return scope.searchedColor != null;
    };
    scope.getClass = function () {
      var result = [];
      if (scope.expandedGroupClass)
        result.push(scope.expandedGroupClass);
      if (scope.showSearchedColor())
        result.push('with-searched-color');

      return result.join(' ');
    };

    scope.getMostWidth = function (index) {
      let totalPercentage = 0;
      scope.data.mostPopular.forEach(item => {
        if(typeof(item.percentage) == "string") {
          totalPercentage += parseFloat(item.percentage);
        } else {
          totalPercentage += item.percentage;
        }
      });
      let indexPercentage = scope.data.mostPopular[index].percentage;
        return parseFloat((100 / totalPercentage) * indexPercentage) + '%';
    }

    scope.getModerateWidth = function (index) {
      let totalPercentage = 0;
      scope.data.moderate.forEach(item => {
        if(typeof(item.percentage) == "string") {
          totalPercentage += parseFloat(item.percentage);
        } else {
          totalPercentage += item.percentage;
        }
      });
      let indexPercentage = scope.data.moderate[index].percentage;
        return parseFloat((100 / totalPercentage) * indexPercentage) + '%';
    }

    scope.getInfrequentWidth = function (index) {
      let totalPercentage = 0;
      scope.data.infrequent.forEach(item => {
        if(typeof(item.percentage) == "string") {
          totalPercentage += parseFloat(item.percentage);
        } else {
          totalPercentage += item.percentage;
        }
      });
      let indexPercentage = scope.data.infrequent[index].percentage;
        return parseFloat((100 / totalPercentage) * indexPercentage) + '%';
    }

    scope.getNotUsedWidth = function (index) {
      let totalPercentage = 0;
      scope.data.not_used.forEach(item => {
        if(typeof(item.percentage) == "string") {
          totalPercentage += parseFloat(item.percentage);
        } else {
          totalPercentage += item.percentage;
        }
      });
      let indexPercentage = 0;
      indexPercentage = scope.data.not_used[index].percentage;
      return parseFloat((100 / totalPercentage) * indexPercentage) + '%';
    }

    scope.selectBar = function (event) {
      if (scope.selectedBar == event.target) {
        angular.element(scope.selectedBar).tooltipster('hide');
        scope.selectedBar = null;
      } else {
        if (scope.selectedBar)
          angular.element(scope.selectedBar).tooltipster('hide');

        angular.element(event.target).tooltipster('show');
        scope.selectedBar = event.target;
      }
    };

    scope.$watch('originalData', function (newValue, oldValue) {
      if (newValue instanceof Array)
        scope.data = newValue[scope.activeColor];
      else
        scope.data = newValue;
    });

    scope.$watch('activeColor', function (newValue, oldValue) {
      if (scope.originalData instanceof Array && newValue != null)
        scope.data = scope.originalData[newValue];
    });
  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/dbColorFrequency/dbColorFrequencyVideoView.html',
    link: link,
    scope: {
      originalData: '=data',
      searchedColor: '=',
      activeColor: '='
    }
  };
});
