angular.module("app").directive("hueDbTopColorsAuto", function () {
  function link(scope) {
    scope.selectedColor = null; 
    scope.toggleColor = function (index) {
      scope.selectedColor = scope.selectedColor == index ? null : index;
    };
    scope.isColorSelected = function (index) {
      if (index != null) return scope.selectedColor == index;
      return scope.selectedColor != null;
    };

    scope.getBarHeight = function (index) {
      let totalPercentage = 0;
      scope.data.forEach(item => {
        if(typeof(item.percentage) == "string") {
          totalPercentage += parseFloat(item.percentage);
        } else {
          totalPercentage += item.percentage;
        }
      });

      if (scope.selectedColor == null) {
        let indexPercentage = 0;
        if(typeof(scope.data[index].percentage) == "string" && scope.data[index].percentage.indexOf('%') > -1) {
          indexPercentage = scope.data[index].percentage.replace('%','');
        } else {
          indexPercentage = scope.data[index].percentage;
        }
        return parseFloat((100 / totalPercentage) * indexPercentage) + '%';
        // return scope.data[index].percentage + "%";
      }
      return scope.selectedColor === index ? "100%" : "0%";
    };
  }

  return {
    restrict: "E",
    templateUrl: "app/directives/dbTopColorsAuto/dbTopColorsAutoView.html",
    link: link,
    scope: {
      data: "=",
      optionalHeight: "=",
    },
  };
});
