angular.module("app").component("signUpComponent", {
  templateUrl: "app/components/sign-up/sign-up.tmpl.html",
  controller: function (
    authService,
    $state,
    localStorageService,
    categoryValues,
    dataValidate,
    $http,
    appConfig
  ) {
    const self = this;
    self.email = "";
    self.password = "";
    self.isRemembered = false;
    self.error = "";
    self.serverError = "";
    self.response = false;
    self.widgetId = "";
    self.jobs = categoryValues("job function");
    self.companySizes = categoryValues("company size");
    self.industries = categoryValues("industry");
    const products = { courses: {}, reports: {}, teaching_materials: {} };
    localStorageService.set("products", products);

    self.registry = {
      title: { value: "MR", required: true, name: "title" },
      company: {
        value: "",
        required: true,
        name: "company name",
        type: "provide",
      },
      company_size: {
        value: self.companySizes[0],
        required: true,
        name: "company size",
        type: "select",
      },
      industry: {
        value: self.industries[0],
        required: true,
        name: "industry",
        type: "select",
      },
      first_name: {
        value: "",
        required: true,
        name: "first name",
        type: "provide",
      },
      last_name: {
        value: "",
        required: true,
        name: "last name",
        type: "provide",
      },
      email: { value: "", required: true, name: "email", type: "provide" },
      job_title: {
        value: "",
        required: true,
        name: "job title",
        type: "provide",
      },
      password: {
        value: "",
        required: true,
        name: "password",
        type: "provide",
      },
      password_confirmation: {
        value: "",
        required: true,
        name: "Confirm Password",
        type: "provide",
      },
    };

    self.model = {
      key: "6LfDJqwUAAAAACHIqavpN1wTmv8xwZImTU79S2I6",
    };

    self.register = () => {
      self.reg_error = false;
      for (item in self.registry) {
        if (self.registry[item].required) {
          if (!self.registry[item].value) {
            self.registry[item].$error = true;
            self.registry[item].$error_msg =
              "Please Enter " + self.registry[item].name;
            self.reg_error = true;
          }
        }
      }

      if (
        self.registry.password.value !==
        self.registry.password_confirmation.value
      ) {
        self.registry.password_confirmation.$error = true;
        self.registry.password_confirmation.$error_msg =
          "Confirm Password must be same as Password";
        self.registry.password_confirmation.value = "";
        self.reg_error = true;
      } else {
        self.registry.password_confirmation.$error = false;
      }

      const data = {};

      for (item in this.registry) {
        if (item === "read" || item === "ticking" || item === "accept") {
          if (!self.registry[item].value) {
            self.registry[item].$error = true;
            self.reg_error = true;
          } else {
            self.registry[item].$error = false;
          }
        } else if (item === "rpassword") {
          self.registry[item].password_confirmation.$error = false;
        } else {
          if (self.registry[item].type === "select") {
            data[item] = self.registry[item].value.title;
          } else {
            data[item] = self.registry[item].value;
          }
        }
      }
      if (self.reg_error) {
      } else {
        $http
          .post(appConfig.dashboardServiceUrl + "api/users.json", {
            user: data,
          })
          .then((res) => {
            $state.go("thank-you");
          })
          .catch((error) => {
            if (error.status === 422) {
              self.serverError = Object.keys(error.data.errors).map((key) => {
                return `Field ${key} ${error.data.errors[key].join(" ")}`;
              });
            }
          });
        self.response = false;
      }
    };

    self.setResponse = (res) => {
      self.response = res;
    };

    self.setWidgetId = (widgetId) => {
      self.widgetId = widgetId;
    };
  },
});
