angular.module("app").directive("gameColorData", [
  "gameDashboardRepository", "$timeout",
  (gameDashboardRepository, timeout) => {
    let link = (scope, element) => {
        scope.currentPage = 0;
        scope.perPage = 10;
        scope.currentCreatorPage = 1;
        scope.creatorPerPage = 30;

    scope.gameCreatorOfColor = []; 
      scope.selectedItem = scope.item;

      scope.isModeColor = () => {
        return scope.viewMode === "color";
      };

      scope.isModeGenre = () => {
        return scope.viewMode === "genre";
      };

      scope.isModeCreator = () => {
        return scope.viewMode === "creator";
      };

      scope.isModeYear = () => {
        return scope.viewMode === "year";
      };

      if (scope.isModeColor()) {
        scope.isLoadingControls = true;

        gameDashboardRepository
        .getColorChartsDataByColorId(scope.selectedItem.id, 'header', scope.selectedYear.id)
        .then(function (data) {
          scope.colorHeaderData = data.header;
          scope.isLoadingControls = false; 
        }).finally(() => {
          scope.isLoadingControls = false; 
        });

        gameDashboardRepository
        .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData1', scope.selectedYear.id)
        .then(function (data) {
          scope.overallData = data.chartData1;
          scope.mainPercentage = scope.overallData.selected_colors.percentage;
        });

        gameDashboardRepository
        .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData2', scope.selectedYear.id)
        .then(function (data) {
          scope.topFiveGenreData = data.chartData2;
        });

        gameDashboardRepository
        .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData3', scope.selectedYear.id)
        .then(function (data) {
          scope.combinationData = data.chartData3
        });
      }

      if (scope.isModeGenre()) {
        scope.isLoadingControls = true;
        scope.gameCreatorOfColor = [];
        scope.letterCreator = "All";
        scope.currentCreatorPage = 1;
        scope.selectedGroup = '';
        
        gameDashboardRepository
        .getGenreChartsDataByGenreId(scope.selectedItem.id, 'header', scope.selectedYear.id)
        .then(function (data) {
          scope.genreHeaderData = data.header;
          scope.isLoadingControls = false; 

          scope.loadGenreChartData3(scope.letterCreator);
        }).finally(() => {
          scope.isLoadingControls = false; 
        });

        gameDashboardRepository
        .getGenreChartsDataByGenreId(scope.selectedItem.id, 'chartData1', scope.selectedYear.id)
        .then(function (data) {
          scope.frequentGenreData = data.chartData1;
        });

        gameDashboardRepository
        .getGenreChartsDataByGenreId(scope.selectedItem.id, 'chartData2', scope.selectedYear.id)
        .then(function (data) {
            scope.selectedGenreOverallData = data.chartData2.selected_genre;
            scope.genreOverallColorData = data.chartData2.other_genre;
        });
      }

      if (scope.isModeCreator()) {
        scope.isLoadingControls = true;
    
        gameDashboardRepository
        .getCreatorChartsDataByCreator(scope.selectedItem.title, 'header', scope.selectedYear.id)
        .then(function (data) {
            scope.creatorHeaderData = data.header;
            scope.isLoadingControls = false; 

            gameDashboardRepository
            .getCreatorChartsData3ByCreator(scope.selectedItem.title, 'chartData3', scope.selectedYear.id, scope.currentPage + 1, scope.perPage)
            .then(function (data) {
                scope.genreWiseColorData = data.chartData3.creators_overall_games;
                scope.pageCount = data.chartData3.paginate.total_pages;
            });
        }).finally(() => {
            scope.isLoadingControls = false; 
        });

        gameDashboardRepository
        .getCreatorChartsDataByCreator(scope.selectedItem.title, 'chartData1', scope.selectedYear.id)
        .then(function (data) {
            scope.uniqueColorsCreator = data.chartData1;
        });

        gameDashboardRepository
        .getCreatorChartsDataByCreator(scope.selectedItem.title, 'chartData2', scope.selectedYear.id)
        .then(function (data) {
            scope.frequentGenreData = data.chartData2;
        });
      }

      scope.setPage = (num) => {
        if (num >= 0 && num < scope.pageCount) {
          scope.currentPage = num;
          gameDashboardRepository
            .getCreatorChartsData3ByCreator(scope.selectedItem.title, 'chartData3', scope.selectedYear.id, scope.currentPage + 1, scope.perPage)
            .then(function (data) {
                scope.genreWiseColorData = data.chartData3.creators_overall_games;
                scope.pageCount = data.chartData3.paginate.total_pages;
            });
        }
      };

      if (scope.isModeYear()) {
        scope.isLoadingControls = true;
        scope.gameCreatorOfColor = [];
        scope.letterCreator = "All";
        scope.currentCreatorPage = 1;
        scope.selectedGroup = '';

        gameDashboardRepository
        .getYearChartsDataByYearId(scope.selectedItem.title, 'header')
        .then(function (data) {
            scope.yearHeaderData = data.header;
            scope.isLoadingControls = false; 

            gameDashboardRepository
            .getYearChartsDataByYearId(scope.selectedItem.title, 'chartData2')
            .then(function (data) {
                scope.newColorFrequencyData = data.chartData2;

            scope.loadYearChartData5(scope.letterCreator);
            });
        }).finally(() => {
            scope.isLoadingControls = false; 
        });

        gameDashboardRepository
        .getYearChartsDataByYearId(scope.selectedItem.title, 'chartData1')
        .then(function (data) {
            scope.frequentGenreData = data.chartData1;

            gameDashboardRepository
            .getYearChartsDataByYearId(scope.selectedItem.title, 'chartData3')
            .then(function (data) {
                scope.topFourColorGenreData = data.chartData3;

                gameDashboardRepository
                .getYearChartsDataByYearId(scope.selectedItem.title, 'chartData4')
                .then(function (data) {
                    scope.topTenColorForGameSelected = data.chartData4.selected_year;
                    scope.topTenColorForGameOther = data.chartData4.other_years;
                });
            });
        });
      }

      scope.loadGenreChartData3 = (item) => {
        gameDashboardRepository
          .getGenreChartsData3ByGenreId(scope.selectedItem.id, 'chartData3', scope.selectedYear.id, item, scope.currentCreatorPage, scope.creatorPerPage)
          .then(function (data) {
              scope.gameCreatorOfColor = scope.gameCreatorOfColor.concat(data.chartData3.creators);
              scope.selectedGroup = data.chartData3.group;
              scope.isLoadMoreCreatorData = parseInt(data.chartData3.paginate.current_page) === parseInt(data.chartData3.paginate.total_pages) ? true : false;
              scope.isLoadingControls = false; 
          }).finally(() => {
              scope.isLoadingControls = false; 
          });
      }

      scope.loadYearChartData5 = (item) => {
        gameDashboardRepository
          .getYearChartsData5ByYearId(scope.selectedItem.id, 'chartData5', item, scope.currentCreatorPage, scope.creatorPerPage)
          .then(function (data) {
              scope.gameCreatorOfColor = scope.gameCreatorOfColor.concat(data.chartData5.creators);
              scope.selectedGroup = data.chartData5.group;
              scope.isLoadMoreCreatorData = parseInt(data.chartData5.paginate.current_page) === parseInt(data.chartData5.paginate.total_pages) ? true : false;
              scope.isLoadingControls = false; 
          }).finally(() => {
              scope.isLoadingControls = false; 
          });
      }

      scope.filteredResults = function (item) {
        scope.letterCreator = item;
        scope.gameCreatorOfColor = [];
        scope.currentCreatorPage = 1;
        scope.selectedGroup = '';
        if (scope.isModeGenre()) {
          scope.loadGenreChartData3(scope.letterCreator);
        }
        if (scope.isModeYear()) {
          scope.loadYearChartData5(scope.letterCreator);
        }
      };

      scope.loadMoreCreatorData = () => {
        scope.currentCreatorPage +=1;
        if (scope.isModeGenre()) {
          scope.loadGenreChartData3(scope.letterCreator);
        }
        if (scope.isModeYear()) {
          scope.loadYearChartData5(scope.letterCreator);
        }
      }

      scope.loadMoreGenreOverallData = () => {  
        scope.isLoadedGenreOverallData = false;
      };

      scope.gameChartPopupAttributes = {
        modalTitle: ""
      };

      scope.toggleGameChartPopup = (needShow, type) => {
        if (needShow) {
          if(type === 'chart1'){
            scope.gameChartPopupAttributes.modalTitle = 'TOP 5 GENRES USING ' + scope.selectedItem.name;
            scope.typeOfPopup = type;
          } else if(type === 'chart2'){
            scope.gameChartPopupAttributes.modalTitle = 'COLOR COMBINATIONS FOR ' + scope.selectedItem.name + ' AND ALL OTHER COLORS USED ACROSS ALL VR GAMES GENRES';
            scope.typeOfPopup = type;
          } else if(type === 'chart3'){
            scope.gameChartPopupAttributes.modalTitle = 'OVERALL USE OF ' + scope.selectedItem.name + ' ACROSS ALL VR GAME GENRES COMPARED TO ALL OTHER COLORS';
            scope.typeOfPopup = type;
          } else if(type === 'chart4'){
            scope.gameChartPopupAttributes.modalTitle = 'FREQUENT USE OF COLORS IN THE ' + scope.selectedItem.name + ' GENRE';
            scope.typeOfPopup = type;
          } else if(type === 'chart5'){
            scope.gameChartPopupAttributes.modalTitle = 'FREQUENT USE OF COLORS ACROSS ALL GENRES IN ' + scope.selectedItem.title;
            scope.typeOfPopup = type;
          } else if(type === 'chart6'){
            scope.gameChartPopupAttributes.modalTitle = 'COLOR FREQUENCY ACROSS ALL GENRES IN ' + scope.selectedItem.title + ' COMPARED TO ALL OTHER YEARS';
            scope.typeOfPopup = type;
          }
          scope.showGameChartPopup = true;
        } else {
          scope.showGameChartPopup = false;
        }
      };

      scope.$on("mood_state_chart", (data) => {
        let node = angular.element("#gamePopupCapture")[0];
        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            if(dataUrl) {
              scope.saveImageDetailsData = {
                base64: dataUrl,
                type: 'video'
              };
            }
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });

          let times = 2000;
          timeout(function () {
            if(scope.saveImageDetailsData) {
              scope.showGameChartPopup = false;
              scope.toggleSaveImageDetails();
            } else {
              timeout(function () {
                if(scope.saveImageDetailsData) {
                  scope.showGameChartPopup = false;
                  scope.toggleSaveImageDetails();
                }
              }, times);
            }
          }, times);
      });

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
        result += name.toUpperCase() + ".";
        return result + date + type;
      };

      scope.toggleSaveImageDetails = () => {
        scope.saveImageDetails = scope.saveImageDetails ? false : true;
      };

      scope.$on("mood_state_chart_changed", () => {
        scope.toggleSaveImageDetails();
      });
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbGameColorData/dbGameColorDataView.html",
      link: link,
      scope: {
        item: "=",
        viewMode: "=",
        selectedYear: "=",
      },
    };
  },
]);
