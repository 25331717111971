angular.module("app").directive("fragranceColorModalDetail", () => {
  let link = (scope) => {
    scope.allData = {
      data: scope.logodata,
      index: scope.index,
    };

    scope.data = scope.logodata;
    scope.firstData = {
      groups: scope.data.colors,
      unique: scope.data.rgbs
    }

    scope.LeftButtonHandler = () => {
      let index = scope.allData.index;
      if (index > 0) {
        index--;
      }
      scope.allData.index = index;
      scope.data = scope.allData.data[index];
    };

    scope.RightButtonHandler = () => {
      try {
        let index = scope.allData.index;
        if (index < scope.allData.data.length - 1) {
          index++;
        }
        scope.allData.index = index;
        scope.data = scope.allData.data[index];
      } catch (e) {}
    };

    scope.newmoodboard = (item) => {
      const data = {
        type: 'video',
        item: item
      }  
      scope.$emit("mood_state_item_chart", data);
    };

    scope.closeClickHandler = () => {
      scope.closefunction();
    };

    scope.shareFacebook = (url) => {
      window.open("https://www.facebook.com/sharer/sharer.php?u=" + url);
    };

    scope.shareTwitter = (url) => {
      window.open("https://twitter.com/home?status=" + url);
    };

    scope.sharePinterest = (url) => {
      window.open("https://pinterest.com/pin/create/button/?url=" + url);
    };

    scope.shareInstagram = (url) => {
      window.open("https://instagram.com/?url=" + url);
    };

    scope.shareGooglePlus = (url) => {
      window.open("https://plus.google.com/share?url=" + url);
    };
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbFragranceDesignerOfColor/fragranceColorModalView.html",
    link: link,
    scope: {
      logodata: "=",
      index: "=",
      onClose: "&",
      closefunction: "=",
      selectedColor: "=",
      year: "=",
    },
  };
});
