angular.module("app").directive("colorResearchData", (modalService, researchIndexService, localStorageService, $timeout) => {
  let link = (scope) => {

    scope.loadColors = () => {
      researchIndexService.getColorsList().then((data) => {
        scope.colors = data.colors;
        scope.selectedColor = scope.colors[1];
        scope.handleSearchChange('color', scope.selectedColor)
      });
    };
    scope.loadColors();

    scope.loadBasicDetailsCount = () => {
      scope.ajaxInProgress = true;  
      researchIndexService.getBasicDetailsCount().then((data) => {
        scope.headerData = data;
        scope.ajaxInProgress = false;
      }).finally(() => {
        scope.ajaxInProgress = false;
      });
    };
    scope.loadBasicDetailsCount();

    scope.monthData = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];

    if(localStorageService.get("currentUser")){
      scope.user = localStorageService.get("currentUser");
    }

    const now = new Date();
    scope.year = now.getFullYear();
    scope.month = now.getMonth();
    scope.day = now.getDate();

    scope.menus = {
      topicArea: "",
      researcher: "",
      journalOrSource: ""
    };

    scope.disabledControls = {
      topicArea: false,
      researcher: false,
      journalOrSource: false
    };

    scope.type = {
      topicArea: "topicArea",
      researcher: "researcher",
      journalOrSource: "journalOrSource"
    };

    scope.colorStudiesData = [];
    scope.allTopicList = [];
    scope.allJounralTopicList = [];
    scope.topicAreas = []; 
    scope.researchers = [];
    scope.journals = [];
    scope.selectedpage = 1;
    scope.oldsearchdata = "";
    scope.topicColorStudiedData = [];

    scope.mainParam = null;
    scope.mainParamId = null;
    scope.searchMode = null;
    scope.selectedFilterValue = null;

    scope.description = '';
    scope.fullDescription = '';
    scope.showingFullDesc = false;
    scope.moreDescAvailable = false;
    scope.maxDescWords = 310;

    scope.topicArea_place_holder = "SEARCH BY TOPIC AREA";
    scope.researcher_place_holder = "SEARCH BY RESEARCHER";
    scope.journalOrSource_place_holder = "SEARCH BY JOURNAL";
    // if(!scope.user.admin) {
    //   modalService.showModal(10, null, "We're working to finalize this color index and except it launched in Q3 of 2022.");
    // }
    scope.currentPageForResearcher = 1;
    scope.selectedMenuId = 1;
    scope.selectedMenuJounralId = 1;
    scope.currentPage = 1;
    scope.columnName = 'publication_date';
    scope.columnOrder = 'desc';

    scope.showResearchChartPopup = false;
    scope.researchChartPopupAttributes = {
      modalTitle: ""
    };

    scope.menu = [
      { id: 1, title: "RESEARCHER NAME" },
      { id: 2, title: "TOPIC" },
    ];
    scope.isSetId = function (id) {
      return scope.selectedMenuId === id;
    };
    scope.setMenuId = function (id) {
      scope.selectedMenuId = id;
      scope.currentPage = 1;
      scope.oldsearchdata = "";
      scope.colorStudiesData = [];
      if(scope.selectedMenuId === 1) {
        scope.selectedCharacter = "ALL";
        scope.characterSelect(scope.selectedCharacter);
      } else {
        scope.currentTopic = {
          keyword: "All"
        }
        scope.updateSelectedTopic(scope.currentTopic);
      }
    };

    scope.characters = [
      "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
      "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
    ];
    scope.selectedCharacter = "ALL";

    scope.characterSelect = (value) => {
      scope.selectedCharacter = value;
      scope.colorStudiesData = [];
      scope.currentPage = 1;
      scope.loadColorChartData4();
    };

    scope.isSelected = (value) => {
      return scope.selectedCharacter === value;
    };

    scope.menuJounral = [
      { id: 1, title: "COLOR" },
      { id: 2, title: "TOPIC" },
    ];
    scope.isJounralSetId = function (id) {
      return scope.selectedMenuJounralId === id;
    };
    scope.setJounralMenuId = function (id) {
      scope.selectedMenuJounralId = id;
      scope.currentPage = 1;
      scope.oldsearchdata = "";
      scope.colorStudiesData = [];
      if(scope.selectedMenuJounralId === 1) {
        scope.selectedColorCharacter = "ALL";
        scope.selectedColorId = 'All';
        scope.colorCharacterSelect(scope.selectedColorCharacter);
      } else {
        scope.currentJounralTopic = {
          keyword: "All"
        }
        scope.updateJounralSelectedTopic(scope.currentJounralTopic);
      }
    };

    scope.selectedColorCharacter = "ALL";
    scope.selectedColorId = 'All';

    scope.colorCharacterSelect = (value) => {
      scope.selectedColorCharacter = value === 'ALL' ? 'All' : value.family;
      scope.selectedColorId = value === 'ALL' ? 'All' : value.id;
      scope.colorStudiesData = [];
      scope.currentPage = 1;
      scope.loadJounralChartData3();
    };

    scope.isColorSelected = (value) => {
      return scope.selectedColorCharacter === value;
    };

    scope.openMyBoard = () => {
      scope.searchMode = 'myBoard';
      scope.menus.researcher = "";
      scope.menus.journalOrSource = "";
      scope.menus.topicArea = "";
    }

    scope.setDescription = desc => {
      scope.fullDescription = desc;
      scope.description = desc.substr(0, scope.maxDescWords);
      scope.moreDescAvailable = desc.length > scope.maxDescWords;
    };

    scope.showMoreDesc = () => {
      if (scope.showingFullDesc) {
        scope.description = scope.fullDescription.substr(0, scope.maxDescWords);
      }
      else {
        scope.description = scope.fullDescription;
      }
      scope.showingFullDesc = !scope.showingFullDesc;
    };

    scope.handleSearchChange = function (control, item) {
      if (control === 'color' && item === null) {
        scope.searchMode = null;
      }
      else if (control === 'topicArea' && item !== null) {
        scope.selectedFilterValue = item;
        scope.searchMode = control;
      } else if (control === 'journalOrSource' && item !== null) {
        scope.selectedFilterValue = item;
        scope.searchMode = control;
      } else {
        scope.selectedFilterValue = Object.assign({}, item);
        scope.searchMode = control;
      }

      switch (control) {
        case "color":
          scope.menus.researcher = "";
          scope.menus.journalOrSource = "";
          scope.menus.topicArea = "";
          break;
        case "topicArea":
          scope.menus.researcher = "";
          scope.menus.journalOrSource = "";
          break;
        case "researcher":
          scope.menus.topicArea = "";
          scope.menus.journalOrSource = "";
          break;
        case "journalOrSource":
          scope.menus.researcher = "";
          scope.menus.topicArea = "";
          break;
      }
      scope.loadGraphics();
    };

    scope.getResultsOnFilterSearch = (type, str, page) => {
      scope.ajaxInProgress = true;  
      switch (type) {
        case 'topic':
          scope.menus.researcher = "";
          scope.menus.journalOrSource = "";
          if (!str.length) {
            scope.topicAreas = [];
            scope.ajaxInProgress = false;
            return;
          }
          scope.topicAreas = [];
          researchIndexService
            .getResearchTopicAreas(str)
            .then(data => {
              scope.topicAreas = data; 
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break; 
        case 'researcher':
          scope.menus.topicArea = "";
          scope.menus.journalOrSource = "";
          if (!str.length) {
            scope.researchers = [];
            scope.ajaxInProgress = false;
            return;
          }
          scope.researchers = [];
          researchIndexService
            .getResearchResearchers(str)
            .then(data => {
              scope.researchers = data;
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break; 
        case "journalOrSource":
          scope.menus.researcher = "";
          scope.menus.topicArea = "";
          if (!str.length) {
            scope.journals = [];
            scope.ajaxInProgress = false;
            return;
          }
          scope.journals = [];
          researchIndexService
            .getResearchJournals(str)
            .then(data => {
              scope.journals = data;  
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break; 
  
        default:
          console.log('Type not identified');
          scope.ajaxInProgress = false;
      }
    }; 

    scope.setPage = (num) => {
      if (num >= 0 && num < scope.pageCount) {
        scope.currentPage = num;
        scope.loadTopicChartData3();
      }
    };

    scope.loadMoreData = () => {
      scope.currentPage +=1;
      if (scope.searchMode === "color") { 
        scope.loadColorChartData4();
      }
      if (scope.searchMode === "researcher") { 
        scope.loadResearcherChartData1();
      }
      if (scope.searchMode === "journalOrSource") {
        scope.loadJounralChartData3();
      }
    }

    scope.sortColumn = (item) => {
      scope.currentPage = 1;
      scope.colorStudiesData = [];
      if (item === 'year') {
        scope.columnName = 'publication_date';
        scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
      } else {
        scope.columnName = 'researcher_name';
        scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
      }
      if (scope.searchMode === "color") { 
        scope.loadColorChartData4();
      }
      if (scope.searchMode === "topicArea") { 
        scope.currentPage = 0;
        scope.loadTopicChartData3();
      }
      if (scope.searchMode === "researcher") { 
        scope.loadResearcherChartData1();
      }
      if (scope.searchMode === "journalOrSource") {
        scope.loadJounralChartData3();
      }
    }

    scope.loadColorChartData4 = () => {
      let letter,keyword = ''
      if(scope.selectedMenuId === 1) {
        letter = scope.selectedCharacter === 'ALL' ? 'All' : scope.selectedCharacter;
        keyword = ''
      } else {
        letter = '';
        keyword = scope.currentTopic.keyword;
      }
      scope.oldsearchdata = scope.selectedCharacter;
      researchIndexService.getColorChartData4ByColorId(scope.selectedFilterValue.id, 'chartData4', letter, keyword, scope.currentPage, scope.columnName, scope.columnOrder)
        .then(data => {   
          scope.colorStudiesData = scope.colorStudiesData.concat(data.chartData4.result);
          scope.isLoadMoreData = data.chartData4.paginate.current_page === data.chartData4.paginate.total_pages ? true : false;
        });
    }

    scope.loadTopicChartData3 = () => {
      scope.isLoadMoreData = true;
      researchIndexService.getTopicChartData3ByTopicId(scope.selectedFilterValue, 'chartData3', scope.currentPage + 1, scope.columnName, scope.columnOrder)
        .then(data => {
          scope.colorStudiesData = data.chartData3.result;
          scope.pageCount = data.chartData3.paginate.total_pages;
        });
    }

    scope.loadResearcherChartData1 = () => {
      researchIndexService.getResearcherChartData1ByResearcherId(scope.selectedFilterValue.id, 'chartData1', scope.currentPage, scope.columnName, scope.columnOrder)
        .then(data => {
          scope.colorStudiesData = scope.colorStudiesData.concat(data.chartData1.result);
          scope.isLoadMoreData = data.chartData1.paginate.current_page === data.chartData1.paginate.total_pages ? true : false;
        });
    }

    scope.loadJounralChartData3 = () => {
      let letter,keyword = ''
      if(scope.selectedMenuJounralId === 1) {
        letter = scope.selectedColorId;
        keyword = ''
      } else {
        letter = '';
        keyword = scope.currentJounralTopic.keyword;
      }
      researchIndexService.getJounralChartData3ByJounral(scope.selectedFilterValue, 'chartData3', letter, keyword, scope.currentPage, scope.columnName, scope.columnOrder)
        .then(data => {   
          scope.colorStudiesData = scope.colorStudiesData.concat(data.chartData3.result);
          scope.isLoadMoreData = data.chartData3.paginate.current_page === data.chartData3.paginate.total_pages ? true : false;
        });
    }

    scope.updateSelectedTopic = (topic) => {
      scope.currentTopic = topic;
      scope.currentPage = 1;
      scope.colorStudiesData = [];
      scope.loadColorChartData4();
    }

    scope.updateJounralSelectedTopic = (jounral) => {
      scope.currentJounralTopic = jounral;
      scope.currentPage = 1;
      scope.colorStudiesData = [];
      scope.loadJounralChartData3();
    }

    scope.filteredResults = function (item) {
      scope.isLoadingControls = true;
      scope.currentPageForResearcher = 1;
      scope.selectedFilterResult = item;
      researchIndexService.getResearcherChartsData2ByResearcherId(scope.selectedFilterValue.id,'chartData2', scope.selectedFilterResult, scope.currentPageForResearcher)
      .then(data => {   
        scope.isLoadingControls = false;
        scope.researchersStudiedSameColor = data.chartData2;
        scope.isLoadMoreResearcher = data.chartData2.paginate.current_page === data.chartData2.paginate.total_pages ? true : false;
      }).finally(() => {
        scope.ajaxInProgress = false;
      });
    };

    scope.loadMoreResearcher = () => {
      scope.isLoadingControls = true;
      scope.currentPageForResearcher +=1;
      researchIndexService.getResearcherChartsData2ByResearcherId(scope.selectedFilterValue.id,'chartData2', scope.selectedFilterResult, scope.currentPageForResearcher)
      .then(data => {   
        scope.isLoadingControls = false;
        scope.researchersStudiedSameColor.researcher = scope.researchersStudiedSameColor.researcher.concat(data.chartData2.researcher);
        scope.isLoadMoreResearcher = data.chartData2.paginate.current_page === data.chartData2.paginate.total_pages ? true : false;
      }).finally(() => {
        scope.ajaxInProgress = false;
      });
    }

    scope.loadGraphics = function () {
      if (scope.searchMode === "color") { 
        scope.isLoadingControls = true;
        scope.colorStudiesData = [];
        scope.topicStudyData = [];
        scope.currentPage = 1;
        researchIndexService.getColorChartsDataByColorId(scope.selectedFilterValue.id,'header')
        .then(data => {
          scope.colorHeaderData = data.header;
          scope.setDescription(scope.colorHeaderData.description);
        });
        researchIndexService.getColorChartsDataByColorId(scope.selectedFilterValue.id,'chartData1')
        .then(data => {   
          scope.colorChartData1 = data.chartData1;
          let points = [];
          let value = [];
          let average = [];
          scope.colorChartData1.forEach(element => {
            value.push(element[0])
            points.push(element[1])
            average.push(element[2])
          });
          scope.studiedColorVolumeDataOverYears = {
            data: {
              points: points,
              timeValues: value
            },
            averageData: average
          }
        });
        researchIndexService.getColorChartsDataByColorId(scope.selectedFilterValue.id,'chartData2')
        .then(data => {
          scope.chartData2 = data.chartData2;
          scope.mainPercentage = scope.chartData2.selected_colors.percentage + '%';
        });
        researchIndexService.getColorChartsDataByColorId(scope.selectedFilterValue.id,'chartData3')
        .then(data => {
          scope.topicStudyData = data.chartData3;
          scope.allTopicList = data.chartData3.chart_details;
          scope.allTopicList.unshift({
            id: "all",
            keyword: "all",
            all: true,
          });
        scope.currentTopic = scope.allTopicList[0];
        });
        scope.loadColorChartData4();
        scope.isLoadingControls = false;
      }

      if (scope.searchMode === "topicArea") { 
        scope.isLoadingControls = true;
        scope.colorStudiesData = [];
        scope.topicColorStudiedData = [];
        scope.currentPage = 0;
        researchIndexService.getTopicChartsDataByTopic(scope.selectedFilterValue,'header')
        .then(data => {
          scope.topicHeaderData = data.header;
        });
        researchIndexService.getTopicChartsDataByTopic(scope.selectedFilterValue,'chartData1')
        .then(data => {   
          scope.topicColorStudiedData = data.chartData1;
        });
        researchIndexService.getTopicChartsDataByTopic(scope.selectedFilterValue,'chartData2')
        .then(data => {
          let points = [];
          let value = [];
          let averagePoints = [];
          data.chartData2.forEach(element => {
            value.push(element[0])
            points.push(element[1])
            averagePoints.push(element[2])
          });
          scope.tersmStudiedWithTopicData = {
            data: {
              points: points,
              timeValues: value
            },
            averageData: {
              points: averagePoints,
              timeValues: value
            }
          };
        });
        scope.loadTopicChartData3();
        scope.isLoadingControls = false;
      }

      if (scope.searchMode === "researcher") { 
        scope.isLoadingControls = true;
        scope.colorStudiesData = [];
        scope.currentPage = 1;
        scope.currentPageForResearcher = 1;
        scope.selectedFilterResult = 'All'
        researchIndexService.getResearcherChartsDataByResearcherId(scope.selectedFilterValue.id,'header')
        .then(data => {
          scope.researcherHeaderData = data.header;
        });
        scope.loadResearcherChartData1();
        researchIndexService.getResearcherChartsData2ByResearcherId(scope.selectedFilterValue.id,'chartData2', scope.selectedFilterResult, scope.currentPageForResearcher)
        .then(data => {
          scope.researchersStudiedSameColor = data.chartData2;
          scope.isLoadMoreResearcher = data.chartData2.paginate.current_page === data.chartData2.paginate.total_pages ? true : false;
        });
        scope.isLoadingControls = false;
      }

      if (scope.searchMode === "journalOrSource") { 
        scope.isLoadingControls = true;
        scope.colorStudiesData = [];
        scope.topicColorStudiedData = [];
        scope.topicStudyData = [];
        scope.currentPage = 1;
        researchIndexService.getJournalChartsDataByJournal(scope.selectedFilterValue,'header')
        .then(data => {
          scope.sourceHeaderData = data.header;
        });
        researchIndexService.getJournalChartsDataByJournal(scope.selectedFilterValue,'chartData1')
        .then(data => {   
          scope.topicColorStudiedData = data.chartData1;
        });
        researchIndexService.getJournalChartsDataByJournal(scope.selectedFilterValue,'chartData2')
        .then(data => {
          scope.topicStudyData = data.chartData2;
          scope.allJounralTopicList = data.chartData2.chart_details;
          scope.allJounralTopicList.unshift({
            id: "all",
            keyword: "all",
            all: true,
          });
        scope.currentJounralTopic = scope.allJounralTopicList[0];
        });
        scope.loadJounralChartData3();
        scope.isLoadingControls = false;
      }
    };

    scope.toggleReseachChartPopup = (needShow, type) => {
      if (needShow) {
        if(type === 'chart1'){
          scope.researchChartPopupAttributes.modalTitle = 'RESEARCH VOLUME FOR THE COLOR ' + scope.selectedFilterValue.family + ' OVER THE YEARS';
          scope.typeOfPopup = type;
        } else if(type === 'chart2'){
          scope.researchChartPopupAttributes.modalTitle = 'RESEARCH VOLUME FOR THE COLOR ' + scope.selectedFilterValue.family + ' RELATIVE TO ALL OTHER COLORS OVER THE YEARS';
          scope.typeOfPopup = type;
        } else if(type === 'chart3'){
          scope.researchChartPopupAttributes.modalTitle = 'KEY TOPICS STUDIED IN REFERENCE TO THE COLOR ' + scope.selectedFilterValue.family + ' OVER THE YEARS';
          scope.typeOfPopup = type;
        } else if(type === 'chart4'){
          scope.researchChartPopupAttributes.modalTitle = 'COLORS STUDIED IN ASSOCIATION WITH ' + scope.selectedFilterValue;
          scope.typeOfPopup = type;
        } else if(type === 'chart5'){
          scope.researchChartPopupAttributes.modalTitle = 'NUMBER OF STUDIES OF THE TOPIC ' + scope.selectedFilterValue + ' OVER THE YEARS';
          scope.typeOfPopup = type;
        } else if(type === 'chart6'){
          scope.researchChartPopupAttributes.modalTitle = 'COLORS STUDIES PUBLISHED BY ' + scope.selectedFilterValue;
          scope.typeOfPopup = type;
        } else if(type === 'chart7'){
          scope.researchChartPopupAttributes.modalTitle = 'KEY TOPICS PUBLISHED BY ' + scope.selectedFilterValue;
          scope.typeOfPopup = type;
        }
        scope.showResearchChartPopup = true;
      } else {
        scope.showResearchChartPopup = false;
      }
    };

    scope.$on("mood_state_chart", (data) => {
      let node = angular.element("#researchPopupCapture")[0];
      domtoimage
        .toPng(node, {
          bgcolor: "#fff",
        })
        .then((dataUrl) => {
          if(dataUrl) {
            scope.saveImageDetailsData = {
              base64: dataUrl,
              type: 'research'
            };
          }
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });

        let times = 2000;
        $timeout(function () {
          if(scope.saveImageDetailsData) {
            scope.showResearchChartPopup = false;
            scope.toggleSaveImageDetails();
          } else {
            $timeout(function () {
              if(scope.saveImageDetailsData) {
                scope.showResearchChartPopup = false;
                scope.toggleSaveImageDetails();
              } else {
                $timeout(function () {
                  if(scope.saveImageDetailsData) {
                    scope.showResearchChartPopup = false;
                    scope.toggleSaveImageDetails();
                  }
                }, times);
              }
            }, times);
          }
        }, times);
    });

    scope.generateImgName = function (type, board, name) {
      let result = "";
      const date = moment().format("L");
      result += name.toUpperCase() + ".";
      return result + date + type;
    };

    scope.toggleSaveImageDetails = () => {
      scope.saveImageDetails = scope.saveImageDetails ? false : true;
    };

    scope.$on("mood_state_chart_changed", () => {
      scope.toggleSaveImageDetails();
    });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbColorResearchData/dbColorResearchDataView.html",
    link: link,
    scope: {
    }
  };
});
