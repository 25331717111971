angular.module("app").component("studentStaffLoginComponent", {
  templateUrl: "app/components/student-staff-login/student-staff-login.tmpl.html",
  controller: function (authService, $state, localStorageService) {
    const self = this;
    self.email = "";
    self.password = "";
    self.isRemembered = false;
    self.error = "";
    self.serverError = "";
    self.response = false;
    self.widgetId = "";
    const products = { courses: {}, reports: {}, teaching_materials: {} };
    localStorageService.set("products", products);

    self.model = {
      key: "6LfDJqwUAAAAACHIqavpN1wTmv8xwZImTU79S2I6",
    };

    self.login = () => {
      self.error = false;
      authService
        .schoolEnterpriseLogin(self.email, self.password, self.isRemembered)
        .then(function (data) {
          if (data && data.success === true) {
            $state.go("landing-page");
          } else {
            self.error = true;
          }
        });
    };

    self.setResponse = (res) => {
      self.response = res;
    };

    self.setWidgetId = (widgetId) => {
      self.widgetId = widgetId;
    };
  },
});
