angular.module("app").controller("homeController", [
  "$scope",
  "dashboardOverlayService",
  "searchMenuRepository",
  "homeColorsService",
  "$timeout",
  "charts",
  "$state",
  "$location",
  "modalService",
  "localStorageService",
  function (
    scope,
    dashboardOverlayService,
    searchMenuRepository,
    homeColorsService,
    timeout,
    charts,
    $state,
    $location,
    modalService,
    localStorageService
  ) {
    scope.menus = {
      category: "",
      subCategory: "",
      style: "",
      color: "",
    };

    scope.disabledControls = {
      category: false,
      subCategory: false,
      style: false,
      color: false,
    };

    scope.type = {
      category: "category",
      subCategory: "subCategory",
      style: "style",
      color: "color",
    };

    if(localStorageService.get("currentUser")){
      scope.user = localStorageService.get("currentUser");
    }

    scope.params = $location.search();

    scope.$watch('params', function (params) {
      if (params.control === 'searchMenu') {
        scope.init();
      } else {
        scope.handleChangeControl(params.control, params);
      }
    });

    scope.disabledInput = (type, value) => {
      switch (type) {
        case "category":
          scope.disableSubCategory = Boolean(value);
          scope.disableStyle = Boolean(value);
          break;
        case "subCategory":
          scope.disableCategory = Boolean(value);
          scope.disableStyle = Boolean(value);
          break;
        case "style":
          scope.disableCategory = Boolean(value);
          scope.disableSubCategory = Boolean(value);
          break;
      }
    };

    scope.mainParam = null;
    scope.mainParamId = null;
    scope.secondaryParams = {};
    scope.tab = 1;
    scope.currentPage = 0;

    scope.category_place_holder = "SEARCH BY CATEGORIES";
    scope.sub_category_place_holder = "SEARCH BY SUB CATEGORIES";
    scope.style_place_holder = "SEARCH BY STYLE";

    // scope.showDashboard = false;
    // scope.title = scope.year;
    // scope.subtitle = null;

    scope.subCategoriesList = [];
    scope.homeList = [];

    // color, subcategory and style dashboard

    scope.selectedCategoriesDashCharacter = "ALL";

    scope.categoriesDashCharacterSelect = (value, type) => {
      if(type) {
        scope.selectedCategoriesDashCharacter = value.id;
      } else {
        scope.selectedCategoriesDashCharacter = value;
      }
      scope.currentPage = 0;
      scope.homeList = [];
      scope.loadGraphics();
    };

    scope.isCategoriesDashSelected = (value, type) => {
      if(type) {
        return scope.selectedCategoriesDashCharacter === value.id;
      } else {
        return scope.selectedCategoriesDashCharacter === value;
      }
    };

    scope.isLoadingControls = true;

    // get colors list
    homeColorsService.getColorsList().then((data) => {
      scope.colors = data.colors;
    });

    // get categories list
    homeColorsService.getCategoriesList().then((data) => {
      scope.categoryList = data.categories;
    });

    // get style list
    homeColorsService.getStyleList().then((data) => {
      scope.styleList = [
        ...data.styles.map((title, id) => ({
          id,
          title,
        }))
      ];
    });

    scope.setback = () => {
      $state.go("home", {control: "searchMenu", id: "", title: "", hex: "", family: ""});
    };

    scope.init = () => {
      scope.menus = {
        category: "",
        subCategory: "",
        style: "",
        color: "",
      };
      scope.disableCategory = false;
      scope.disableSubCategory = false;
      scope.disableStyle = false;
      scope.isLoadingControls = true;
      scope.currentPage = 0;
      scope.mainSeason = [];
      scope.tab = 1;
    };

    scope.setTab = (tabId) => {
      scope.tab = tabId;
      scope.select_images = false;
      scope.selected_moodboard = false;
      scope.state = 1;
    };

    scope.isSet = (tabId) => {
      return scope.tab === tabId;
    };

    scope.getResultsOnFilterSearch = (type, str, page) => {
      scope.ajaxInProgress = true;  
      switch (type) {
        case 'subCategory':
          if (!str.length) {
            scope.subCategoriesList = [];
            scope.ajaxInProgress = false;
            return;
          }
          //new logic for pagination
          if(scope.oldsearchdata === ""){
            scope.oldsearchdata = str;
            scope.selectedpage = 1;
          } 
          else if(scope.oldsearchdata !== str){  
              page = 1;
              scope.selectedpage = page;
              scope.subCategoriesList = [];
              scope.oldsearchdata = str;
          } 
          else{
            scope.selectedpage += 1;
            page = scope.selectedpage;
          }

          homeColorsService.getSubCategoriesList(str)
            .then(data => {
              scope.subCategoriesList = [];
              if(scope.subCategoriesList.length>0){  
                scope.subCategoriesList = scope.subCategoriesList.concat(data.sub_categories);
              } else {
                scope.subCategoriesList = data.sub_categories;
              }   
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break; 

        default:
          scope.ajaxInProgress = false;
      }
    }; 

    scope.loadGraphics = function () {
      if (scope.mainParam) {
        scope.isLoadingControls = true;
        dashboardOverlayService.loadingStart(4000);

        if(localStorageService.get("currentUser")){
          scope.user = localStorageService.get("currentUser");
        }

        if (scope.mainParam === "color") {
          scope.isLoadingControls = false;
          homeColorsService
          .getProductImage(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              40,
              scope.selectedCategoriesDashCharacter
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.pageCount = data.paginate.total_pages;
                scope.homeList = data.products;
              } 
            });
        } else if (scope.mainParam === "category") {
          scope.isLoadingControls = false;
          homeColorsService
          .getProductImage(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              40
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.pageCount = data.paginate.total_pages;
                scope.homeList = data.products;
              } 
            });
        } else if (scope.mainParam === "subCategory") {
          scope.isLoadingControls = false;
          homeColorsService
          .getProductImage(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              40,
              scope.selectedCategoriesDashCharacter
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.pageCount = data.paginate.total_pages;
                scope.homeList = data.products;
              } 
            });
        } else if (scope.mainParam === "style") {
          scope.isLoadingControls = false;
          homeColorsService
          .getProductImage(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              40,
              scope.selectedCategoriesDashCharacter
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.pageCount = data.paginate.total_pages;
                scope.homeList = data.products;
              } 
            });
        }
        // scope.isLoadingControls = false;
        // homeColorsService
        // .getProduct(
        //     scope.mainParam,
        //     scope.selectedFilterValue,
        //     scope.currentPage + 1,
        //     40
        //   )
        //   .then(function (data) {
        //     scope.homeList = data.products;
        //     const countData = data.color_data;
        //     scope.topColors = countData.top_four_colors;
        //     scope.colorsCount = countData.colors_count;
        //     scope.stylesCount = countData.styles_count;
        //     scope.productsCount = countData.products_count;
        //     scope.frequency = countData.accent;
        //     scope.pageCount = data.paginate.total_pages;
        //   });
      }
    };

    scope.setPage = (num) => {
      if (num >= 0 && num < scope.pageCount) {
        scope.currentPage = num;
        scope.loadGraphics();
      }
    };

    scope.handleChangeControl = function (control, item) {
      scope.header_title = () => {
        if (control === "color") {
          return `${control}: ${item.family}`;
        } else if (control === "subCategory") {
          return `sub Category: ${item.title}`;
        } else {
          return `${control}: ${item.title}`;
        }

      };
      scope.mainParam = control;
      scope.selectedFilterValue = item;
      scope.mainParamId = item.id;
      scope.searchMode = control;

      scope.loadGraphics();
    };

    scope.handleSearchChange = function (control, item) {
      $state.go("home", Object.assign({control: control}, item));
    };

        // Mocked data
        scope.choices = [1, 2, 3, 4, 5, 6, 7];

        scope.frequencyByCategory = [
          { hex: "#000000", percentage: 55 },
          { hex: "#ffffff", percentage: 10 },
          { hex: "#892194", percentage: 10 },
          { hex: "#404f47", percentage: 10 },
          { hex: "#faebff", percentage: 10 },
          { hex: "#d7915e", percentage: 5 },
        ];
    
        scope.infographics = [
          "https://s3.amazonaws.com/huestorage/hue-data-website/infographic_infographic_files/infographic_files/000/000/035/original/CO2a.RTW.FW.17.AllRe.MI.07_27_2018.jpg?1532744790",
          "https://s3.amazonaws.com/huestorage/hue-data-website/infographic_infographic_files/infographic_files/000/000/041/original/CO3a.RTW.FW.AllYe.AP.TK.07_27_2018.jpg?1532746128",
          "https://s3.amazonaws.com/huestorage/hue-data-website/infographic_infographic_files/infographic_files/000/000/039/original/CA1a.RTW.FW.17.AP.SE.07_27_2018.jpg?1532745995",
        ];
    

    scope.$watch(
      () => {
        return dashboardOverlayService.showOverlay;
      },
      (newValue) => {
        scope.showDashboardOverlay = newValue;
      }
    );
  },
]);
