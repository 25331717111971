(function (angular) {
  const controllerName = "CustomInfographicsController";
  angular.module("app").controller(controllerName, [
    "$http",
    "appConfig",
    "common",
    "repo.common",
    "$interpolate",
    "$scope",
    "charts",
    "$q",
    "repo.meta",
    "repo.designers",
    "$timeout",
    "$location",
    "dashboardOverlayService",
    "authService",
    "dashboardRepository",
    "autoDashboardRepository",
    "pharmaDashboardRepository",
    "customInfographicsConstants",
    "$state",
    "httpService",
    "localStorageService",
    "modalService",
    function (
      $http,
      appConfig,
      common,
      data,
      $interpolate,
      $scope,
      charts,
      $q,
      meta,
      designers,
      timeout,
      $location,
      dashboardOverlayService,
      authService,
      dashboardRepository,
      autoDashboardRepository,
      pharmaDashboardRepository,
      customInfographicsConstants,
      $state,
      httpService,
      localStorageService,
      modalService
    ) {
      const vm = this;

      if ((localStorageService.get("currentUser").active_plan.name === 'free-yearly' || localStorageService.get("currentUser").active_plan.name === 'free-monthly') && !localStorageService.get("currentUser").plan_access.access.custom_analytics) {
        if(!localStorageService.get("currentUser").admin) {
          modalService.showModal(11, null, localStorageService.get("currentUser").first_name);
          return;
        }
      }

      vm.grayList = {};
      vm.meta = {};
      vm.filter = {};
      vm.isLoadingControls = true;
      vm.selectedMenuId = $location.search().tab ? parseInt($location.search().tab) : 1;
      vm.currentYear = moment().year();
      const cache = {
        designers: [],
        categories: [],
        regions: {},
        cities: [],
      };

      const loading = {
        ready: false,
        designersReady: $q.defer(),
        metaLoaded: $q.defer(),
        metaReady: $q.defer(),
      };

      if (localStorageService.get("currentUser") && !localStorageService.get("currentUser").admin) {
        $scope.menu = customInfographicsConstants.menu;
      } else {
        $scope.menu = customInfographicsConstants.menuAdmin;
      }
      
      vm.isFilterVisible = customInfographicsConstants.isFilterVisible;

      vm.alphabet = customInfographicsConstants.alphabet;
      const citiesAbbrevs = customInfographicsConstants.citiesAbbrevs;
      const regionsAbbrevs = customInfographicsConstants.regionsAbbrevs;
      const groupTitlesTemplates =
        customInfographicsConstants.groupTitlesTemplates;



      $scope.params = $location.search();

      $scope.$watch('params', function (params) {
        if (params.tab) {
          vm.selectedMenuId = parseInt(params.tab, 10);
        }
        else {
          vm.selectedMenuId = 1;
        }

        vm.updateQueryBySelectors();
      });

      $scope.isSetId = function (id) {
        return vm.selectedMenuId === id;
      };

      $scope.setMenuId = function (id) {
        $state.go("customizedInfographics", {tab: id});
      };

      vm.prepareRequestParams = function () {
        vm.updateQueryBySelectors();
        const regCity = new RegExp("CITIES");
        const regCate = new RegExp("CATEGORIES");
        const regSeason = new RegExp("SEASONS");
        const regDesi = new RegExp("DESIGNERS");
        let city, season, category, designer;
        try {
          city = regCity.test(vm.filter.city.title) ? "all" : vm.filter.city.title;
          season = regSeason.test(vm.filter.season.title)
            ? "all"
            : vm.filter.season.title;
          category = regCate.test(vm.filter.category.title)
            ? "all"
            : vm.filter.category.title;
          designer = regDesi.test(vm.filter.designer.title)
            ? "all"
            : vm.filter.designer.title.replace(/ /g, "_");
        } catch (error) {
          city = "all";
          season = "all";
          category = "all";
          designer = "all";
        }

        return {
          city: city,
          year: vm.filter.year && vm.filter.year.id,
          season: season,
          category: category,
          region: vm.filter.region && vm.filter.region.id,
          designer: designer,
        };
      };

      vm.prepareColorsParams = function () {
        const reg = new RegExp("ALL ");
        const param = {};
        if (!reg.test(vm.filter.city.title)) {
          param.city_id = vm.filter.city.id;
        }
        if (!reg.test(vm.filter.season.title)) {
          param.season_id = vm.filter.season.id;
        }
        if (!reg.test(vm.filter.category.title)) {
          param.category_id = vm.filter.category.id;
        }
        if (!reg.test(vm.filter.designer.title)) {
          param.designer_id = vm.filter.designer.id;
        }
        param.year_id = vm.filter.year.id;
        return param;
      };

      vm.prepareColors = function () {
        const reg = new RegExp("ALL ");
        if (!reg.test(vm.filter.season.title)) {
          return { all: vm.filter.season, category: "season" };
        }
        if (!reg.test(vm.filter.city.title)) {
          return { all: vm.filter.city, category: "city" };
        }
        if (!reg.test(vm.filter.category.title)) {
          return { all: vm.filter.category, category: "category" };
        }
        if (!reg.test(vm.filter.designer.title)) {
          return { all: vm.filter.designer, category: "designer" };
        }
        return { all: { id: 2018 }, category: "year" };
      };

      loading.metaLoadedStrongLink = loading.metaLoaded;
      loading.filtersReady = $q.all([
        loading.designersReady.promise,
        loading.metaReady.promise,
      ]);

      vm.getOptionForDrop = function (option) {
        let count = 0;
        let url;
        if(option){
          if(option.qNumber === 'CO1a') {
            count = 1;
          } else if(option.qNumber === 'CO2a') {
            count = 2;
          } else if(option.qNumber === 'CO3a') {
            count = 3;
          } else if(option.qNumber === 'RE1a') {
            count = 4;
          } else if(option.qNumber === 'RE2a') {
            count = 5;
          } else if(option.qNumber === 'SE1a') {
            count = 6;
          } else if(option.qNumber === 'SE2a') {
            count = 7;
          } else if(option.qNumber === 'SE3a') {
            count = 8;
          } else if(option.qNumber === 'CA1a') {
            count = 9;
          } else if(option.qNumber === 'CA2a') {
            count = 10;
          } else if(option.qNumber === 'CA2b') {
            count = 11;
          } else if(option.qNumber === 'CA3a') {
            count = 12;
          } else if(option.qNumber === 'DE1a') {
            count = 13;
          } else if(option.qNumber === 'DE1b') {
            count = 14;
          } else if(option.qNumber === 'DE2a') {
            count = 15;
          } else if(option.qNumber === 'DE2b') {
            count = 16;
          } else if(option.qNumber === 'DE3a') {
            count = 17;
          }
        }

        url = {
          token: authService.token,
          category: vm.filter.category ? vm.filter.category.id : '',
          season: vm.filter.season ? vm.filter.season.id : '',
          designer_id: vm.filter.designer ? vm.filter.designer.id : '',
          city_id: vm.filter.city ? vm.filter.city.id : '',
          region: vm.filter.region ? vm.filter.region.id : '',
          year: vm.filter.year ? vm.filter.year.id === 'all' ? '' : vm.filter.year.id : ''
        }

        httpService.get(appConfig.colorsServiceUrlNewApi + 'custom_analytics/options?option='+count,{
          params: url}
        ).then(function (res) {
          if (res.data.response) {
            if(res.data.response.categories) {
              const newCat = [];
              res.data.response.categories.forEach(element => {
                newCat.push({id: element.id, title: vm.capitalizeFirstLetter(element.category)})
              });
            
              const newTitle = "categories";
              newCat.unshift({
                id: "all",
                title: newTitle.toUpperCase(),
                region: "all",
                serverName: "all",
                all: true,
              });
              vm.meta.categories = newCat;
            } 
            if(res.data.response.seasons) {
              const newSeason = [];
              res.data.response.seasons.forEach(element => {
                newSeason.push({id: element.id, title: vm.capitalizeFirstLetter(element.season)})
              });
              const newTitle = "seasons";
              newSeason.unshift({
                id: "all",
                title: newTitle.toUpperCase(),
                region: "all",
                serverName: "all",
                all: true,
              });
              vm.meta.seasons = newSeason;
            }
            if(res.data.response.cities) {
              const newCities = [];
              res.data.response.cities.forEach(element => {
                newCities.push({id: element.id, title: vm.capitalizeFirstLetter(element.name)})
              });
              const newTitle = "cities";
              newCities.unshift({
                id: "all",
                title: newTitle.toUpperCase(),
                region: "all",
                serverName: "all",
                all: true,
              });
              vm.meta.cities = newCities;
              vm.meta.city = newCities;
              angular.copy(vm.meta.cities, cache.cities);
            }
            if(res.data.response.regions) {
              const newRegions = [];
              res.data.response.regions.forEach(element => {
                newRegions.push({id: element.id, title: vm.capitalizeFirstLetter(element.region)})
              });
              const newTitle = "regions";
              newRegions.unshift({
                id: "all",
                title: newTitle.toUpperCase(),
                region: "all",
                serverName: "all",
                all: true,
              });
              vm.meta.regions = newRegions;
            }
            if(res.data.response.designers) {
              const newDesign = [];
              res.data.response.designers.forEach(element => {
                newDesign.push({id: element.id, title: vm.capitalizeFirstLetter(element.name)})
              });
              const newTitle = "designers";
              newDesign.unshift({
                id: "all",
                title: newTitle.toUpperCase(),
                region: "all",
                serverName: "all",
                all: true,
              });
              vm.meta.designers = newDesign;
              loading.designersReady.resolve();
            }
            if(res.data.response.years) {
              const newYears = [];
              res.data.response.years.reverse();
              res.data.response.years.forEach(element => {
                newYears.push({id: element, title: element})
              });
              // vm.filter.year = newYears[0];
              const newTitle = "years";
              newYears.unshift({
                id: "all",
                title: newTitle.toUpperCase(),
                region: "all",
                serverName: "all",
                all: true,
              });
              vm.meta.years = newYears;
              loading.metaLoaded.resolve();
            }
          }
          vm.initStatesByQueryParams();
          vm.isLoadingControls = false;
        });
      }

      httpService.get(appConfig.brandingServiceUrlApi + 'industries.json?per_page=100',
        authService.getHeaderConfig()
      )
      .then(function (res) {
        vm.industryList = res.data;
        vm.isLoadingControls = false;
      });

      vm.capitalizeFirstLetter = (string) => {
        if(string === 'rtw') {
          return string.toUpperCase();
        } else if(string === 'Pre Fall') {
          return 'Pre-Fall';
        } else {
          return string.charAt(0).toUpperCase() + string.slice(1);
        }
      }

      httpService.get(appConfig.colorsServiceUrlNewApi + 'colors.json?',
        authService.getHeaderConfig()
      ).then(function (res) {
        const newColor = [];
        res.data.colors.forEach(element => {
          newColor.push({id: element.id, title: vm.capitalizeFirstLetter(element.name), hex: element.hex})
        });
        vm.meta.colorGroups = newColor;
        vm.isLoadingControls = false;
      });

      httpService.get(appConfig.brandingServiceUrlApi + 'colors',
        authService.getHeaderConfig()
      ).then(function (res) {
        const newBrandColor = [];
        res.data.forEach(element => {
          newBrandColor.push({id: element.id, title: vm.capitalizeFirstLetter(element.title), hex: element.hex})
        });
        vm.meta.colorBrandGroups = newBrandColor;
        vm.isLoadingControls = false;
      });

      vm.refresh = function () {
        loadData();
      };

      vm.cityOrRegionTitle = function () {
        return vm.filter.city.all && vm.filter.region.all
          ? null
          : vm.filter.city.all
          ? vm.filter.region.title
          : vm.filter.city.title;
      };

      vm.getTitle = function (type) {
        try {
          // if (type === "region") {
          //   console.log('tittlt', vm.cityOrRegionTitle());
          //   return vm.cityOrRegionTitle();
          // }
          return !vm.filter[type].all ? vm.filter[type].title : null;
        } catch (err) {
          return "";
        }
      };

      vm.getAbbrv = function (type) {
        let value;
        if (type === "category") {
          value = vm.filter.category.title;
          if (value === "Couture") {
            return "CT";
          } else if (value === "Menswear") {
            return "MW";
          }
          return value;
        } else if (type === "season") {
          value = vm.filter.season.title;
          if (value === "Fall") {
            return "FW";
          } else if (value === "Pre-Fall") {
            return "PF";
          } else if (value === "Spring") {
            return "SS";
          } else if (value === "Resort") {
            return "RS";
          } else if (value === "SEASONS") {
            return "ALL";
          }
          return value;
        } else if (type === "city") {
          value = vm.filter.city.title.replace(/\s/g, "").toLowerCase();
          return _.find(citiesAbbrevs, function (item, key) {
            return value === key.toLowerCase();
          });
        } else if (type === "region") {
          value = vm.filter.region.title.replace(/\s/g, "").toLowerCase();
          return _.find(regionsAbbrevs, function (item, key) {
            return value === key.toLowerCase();
          });
        }
      };

      vm.parseTitle = function (number) {
        let divider = "";
        let result = "";
        _.map(vm.currentChart.titleGroups[number], function (title) {
          if (vm.getTitle(title) !== null) {
            divider = "//";
          }
          if (vm.getTitle(title)) {
            result = result + " " + vm.getTitle(title);
          }
        });
        return divider + result;
      };

      vm.getOptionChartData = function (option, item) {
        let count = 0;
        let url;
        if(option){
          if(option.qNumber === 'CO1a') {
            count = 1;
          } else if(option.qNumber === 'CO2a') {
            count = 2;
          } else if(option.qNumber === 'CO3a') {
            count = 3;
          } else if(option.qNumber === 'RE1a') {
            count = 4;
          } else if(option.qNumber === 'RE2a') {
            count = 5;
          } else if(option.qNumber === 'SE1a') {
            count = 6;
          } else if(option.qNumber === 'SE2a') {
            count = 7;
          } else if(option.qNumber === 'SE3a') {
            count = 8;
          } else if(option.qNumber === 'CA1a') {
            count = 9;
          } else if(option.qNumber === 'CA2a') {
            count = 10;
          } else if(option.qNumber === 'CA2b') {
            count = 11;
          } else if(option.qNumber === 'CA3a') {
            count = 12;
          } else if(option.qNumber === 'DE1a') {
            count = 13;
          } else if(option.qNumber === 'DE1b') {
            count = 14;
          } else if(option.qNumber === 'DE2a') {
            count = 15;
          } else if(option.qNumber === 'DE2b') {
            count = 16;
          } else if(option.qNumber === 'DE3a') {
            count = 17;
          }
        }

        url = {
          token: authService.token,
          category: vm.filter.category ? vm.filter.category.id : '',
          season: vm.filter.season ? vm.filter.season.id : '',
          designer_id: vm.filter.designer ? vm.filter.designer.id : '',
          city_id: vm.filter.city ? vm.filter.city.id : '',
          region: vm.filter.region ? vm.filter.region.id : '',
          year: vm.filter.year ? vm.filter.year.id === 'all' ? '2022' : vm.filter.year.id : ''
        }

        return httpService.get(appConfig.colorsServiceUrlNewApi + 'custom_analytics/charts?option='+count,{
          params: url}
        ).then(function (res) {
          return res.data.result;
        });
      }

      vm.charts = [
        {
          qNumber: "CO1a",
          id: "colorsByCityPeriod",
          group: "colorsByCityPeriod",
          title: "1. Color Popularity Overview",
          chartTitle:
            "Color Popularity Overview {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              _.each(results, function (d) {
                const value = d.count;
                const par = d.percentage.replace('%','')
                d.value = value;
                d.valueTitle = d.percentage;
                d.valueTitle2 = value;
                d.title = d.name;
                d.percentage = par / 100;
              });
              return results;
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            designer: true,
            region: true,
            city: true,
          },
          titleGroups: [["designer", "category", "season", "year"], ["region", "city"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "CO2a",
          id: "colorsUniqueWithLevels",
          group: "colorsUniqueWithLevels",
          title: "2. Color Mosaic View With Popularity",
          chartTitle:
            "Color Mosaic View With Popularity {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              return {
                groups: results.colors,
                unique: results.chart,
              };
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            region: true,
            city: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
        },
        {
          qNumber: "CO3a",
          id: "trends",
          group: "trends",
          title: "3. Five Year Color Comparison",
          chartTitle:
            "Five Year Color Comparison {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              _.each(results, function (obj) {
                _.each(obj.data, function (d) {
                  const value = d.count;
                  const par = d.percentage.replace('%','')
                  d.value = value;
                  d.valueTitle = d.percentage;
                  d.valueTitle2 = value;
                  d.title = d.name;
                  d.percentage = par / 100;
                })
              });
              return results  
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            region: true,
            city: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
        },
        {
          qNumber: "RE1a",
          id: "colorsByRegionPeriodNA",
          group: "colorsByRegionPeriod",
          title: "4. Color Popularity By Region With City Breakdown",
          chartTitle:
            "Color Popularity By Region With City Breakdown {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              _.each(results.colors, function (d) {
                const value = d.count;
                const par = d.percentage.replace('%','')
                d.value = value;
                d.valueTitle = d.percentage;
                d.valueTitle2 = value;
                d.title = d.name;
                d.percentage = par / 100;
              })

              if(results.chart.length > 0){
                _.each(results.chart[0].cities, function (obj) {
                  _.each(obj.colors, function (d) {
                    const value = d.count;
                    const par = d.percentage.replace('%','')
                    d.value = value;
                    d.value2 = value;
                    d.valueTitle = d.percentage;
                    d.valueTitle2 = Number(par);
                    d.title = d.family;
                    d.color = d.hex;
                    d.percentage = par / 100;
                  })
                })
              } else {
                results.chart = [];
              }

              const param = { 
                region: {
                  name: results.chart.length > 0 ? results.chart[0].region.toLowerCase() : '',
                  cities: {
                    title: "Top 4 colors",
                    settings: {},
                    data: results.chart.length > 0 ? results.chart[0].cities : [],
                  },
                },
                charts: {
                  settings: {},
                  data: results.colors,
                }
              }
              return param
            });
          },
          filters: {
            category: true,
            region: true,
            season: true,
            year: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
          options: {},
        },
        {
          qNumber: "RE2a",
          id: "colorsPerRegions",
          group: "colorsPerRegions",
          title: "5. Cross Region Top Four Colors",
          chartTitle: "Cross Region Top Four Colors {{vm.parseTitle(0)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              _.each(results, function (d) {
                d.name = d.region.toLowerCase();
                d.title = d.region.toLowerCase();
                _.each(d.colors, function (dd) {
                  const par = dd.percentage.replace('%','')
                  dd.percentage = par / 100;
                })
                d.data = d.colors
              })
              return results
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
          },
          titleGroups: [["category", "season", "year"]],
        },
        {
          qNumber: "SE1a",
          id: "colorsUniqueGroupsCommon",
          group: "colorsUniqueGroupsCommon",
          title: "6. Color Popularity By Season",
          chartTitle:
            "Color Popularity By Season {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              _.each(results.colors, function (d) {
                const value = d.count;
                const par = d.percentage.replace('%','')
                d.value = value;
                d.title = d.name;
                d.percentage = par / 100;
              })
              _.each(results.top_4, function (d) {
                const value = d.count;
                const par = d.percentage.replace('%','')
                d.value = value;
                d.percentage = par / 100;
              })
              const param = { 
                common: results.top_4,
                groups: results.colors,
                unique: results.chart
              }
              return param
            });
          },
          apiAfter: function (model) {
            model.season = vm.filter.season.title;
            model.year = vm.filter.year.title;
          },
          filters: {
            category: true,
            region: true,
            city: true,
            season: true,
            year: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
        },
        {
          qNumber: "SE2a",
          id: "colorsUniqueByPeriodFiveYears",
          group: "colorsUniqueByPeriodFiveYears",
          title: "7. Five Year Comparison Of Seasons Colors",
          chartTitle:
            "Five Year Comparison Of Seasons Colors {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              _.each(results, function (obj) {
                obj.season = vm.filter.season.title;
              })
              return results
            });
          },
          filters: {
            category: true,
            region: true,
            city: true,
            season: true,
            year: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "SE3a",
          id: "uniqueWithGroupsPerSeason",
          group: "uniqueWithGroupsPerSeason",
          title: "8. Color Mosaic View By Season With Popularity",
          chartTitle:
            "Color Mosaic View By Season With Popularity {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            category: true,
            year: true,
            region: true,
            city: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
        },
        {
          qNumber: "CA1a",
          id: "colorsByCategoryPeriod",
          group: "colorsByCategoryPeriod",
          title: "9. Color Popularity By Category",
          chartTitle:
            "Color Popularity By Category {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            region: true,
            city: true,
          },
          titleGroups: [["category", "season", "year"], ["region", "city"]],
        },
        {
          qNumber: "CA2a",
          id: "colorsByCategory",
          group: "colorsGridNails",
          title: "10. Cross Category Color Popularity",
          chartTitle:
            "Cross Category Color Popularity {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            season: true,
            year: true,
            region: true,
            city: true,
          },
          titleGroups: [["season", "year"], ["region", "city"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "CA3a",
          id: "colorsByRtwCategory",
          group: "colorsGridNails2",
          title: "11. Three Year Comparison Of Color Popularity",
          chartTitle:
            "Three Year Comparison Of Color Popularity {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            region: true,
            city: true,
          },
          titleGroups: [["category", "season", "year"], ["region"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "DE1a",
          id: "colorsGroupsCommon",
          group: "colorsGroupsCommon",
          title: "12. Color Popularity By Designer",
          chartTitle:
            "Color Popularity By Designer {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            designer: true,
          },
          titleGroups: [["designer"], ["category", "season", "year"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "DE1b",
          id: "colorsGroupsCommon2",
          group: "colorsGroupsCommon2",
          title: "13. Expanded Color Popularity By Designer",
          chartTitle:
            "Expanded Color Popularity By Designer {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
            designer: true,
          },
          titleGroups: [["designer"], ["category", "season", "year"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "DE2a",
          id: "top4forDesigner",
          group: "top4forDesigner",
          title: "14. Two Year Comparison of Color Popularity By Designer",
          chartTitle:
            "Two Year Comparison of Color Popularity By Designer {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          apiAfter: function (model) {
            model.city = vm.filter.city.title;
          },
          filters: {
            category: true,
            season: true,
            year: true,
            designer: true,
          },
          titleGroups: [["designer"], ["category", "season", "year"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "DE2b",
          id: "top4forDesigner2",
          group: "top4forDesigner2",
          title: "15. Expanded Two Year Comparison of Color Popularity By Designer",
          chartTitle:
            "Expanded Two Year Comparison of Color Popularity By Designer {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          apiAfter: function (model) {
            model.city = vm.filter.city.title;
          },
          filters: {
            category: true,
            season: true,
            year: true,
            designer: true,
          },
          titleGroups: [["designer"], ["category", "season", "year"]],
          options: {
            extraView: true,
          },
        },
        {
          qNumber: "DE3a",
          id: "top4Grid",
          group: "top4Grid",
          title: "16. Designers Top Four Colors",
          chartTitle:
            "Designers Top Four Colors {{vm.parseTitle(0)}} {{vm.parseTitle(1)}}",
          api: function () {
            return vm.getOptionChartData(vm.currentChart, vm.prepareRequestParams()).then(function (results) {
              return results
            });
          },
          filters: {
            category: true,
            season: true,
            year: true,
          },
          titleGroups: [["category", "season", "year"]],
        },
      ];

      vm.currentChart = vm.charts[0];

      $scope.$watch(
          "[vm.currentChart, vm.filter, vm.selectedMenuId]",
        (newValue, oldValue) => {
          if (!_.isEqual(newValue, oldValue)) {
            loadData();
          }
        },
        true
      );

      if (!loading.isMetaLoadedSetup) {
        loading.metaLoaded.promise.then(function () {
          loading.metaReady.resolve();
        });
        loading.isMetaLoadedSetup = true;
      }
        
      function loadData(newV, oldV) {
        loading.currentRequestId = Math.random();
        if (newV && oldV) {
          if (newV[0].qNumber !== oldV[0].qNumber) {
            vm.filter.designer = vm.meta.designers && vm.meta.designers[0];

            if (newV[0].qNumber === "RE1a" || newV[0].qNumber === "CI1a") {
              vm.meta.regions = _.filter(vm.meta.regions, function (item) {
                return !item.all;
              });
              vm.meta.colorGroups = _.filter(vm.meta.colorGroups, function (
                item
              ) {
                return !item.all;
              });
              vm.filter.region = vm.meta.regions[0];
              vm.filter.color = vm.meta.colorGroups[0];
            } else if (!_.find(vm.meta.regions, "all")) {
              vm.meta.regions.unshift({
                id: "all",
                title: "REGIONS",
                region: "all",
                serverName: "all",
                all: true,
              });
              vm.filter.region = vm.meta.regions[0];
            }
          }
        }

        loading.filtersReady.then(function () {
          vm.model = null;

          (function (currentRequestId) {
            if (vm.selectedMenuId === 1) {
              vm.currentChart.api().then((result) => {
                if (currentRequestId !== loading.currentRequestId || !result) {
                  return;
                }
                vm.model = result;
                if (vm.currentChart.apiAfter) {
                  vm.currentChart.apiAfter(vm.model, result);
                }
                vm.title = prepareTitle(vm.currentChart.chartTitle);
                vm.initStatesByQueryParams();
                // prepareDescription();
              });
            }
          })(loading.currentRequestId);
        });
      }

      function prepareTitle(title) {
        if (!title) {
          return "";
        }
        const exp = $interpolate(title);
        return exp($scope);
      }

      function prepareDescription() {
        try {
          vm.description =
            vm.description ||
            vm.filter.year.title +
              " | COLORS-" +
              vm.currentChart.qNumber +
              " | CITIES-" +
              vm.filter.city.title +
              " | REGIONS-" +
              vm.filter.region.title +
              " | DESIGNER-" +
              vm.filter.designer.title +
              " | SEASONS-" +
              vm.filter.season.title;
        } catch (error) {
          vm.description = "";
        }

        if (!vm.filter.region) {
          return;
        }

        if (!vm.filter.year) {
          return;
        }

        let yearFrom = null;
        const yearTo =
          vm.filter.year.id === "all"
            ? vm.meta.years[1].title
            : vm.filter.year.id;
        if (
          vm.currentChart.qNumber === "CO3a" ||
          vm.currentChart.qNumber === "SE2a" ||
          vm.currentChart.qNumber === "SE2b"
        ) {
          yearFrom = yearTo - 4;
        } else if (
          vm.currentChart.qNumber === "CA3a" ||
          vm.currentChart.qNumber === "CA3b"
        ) {
          yearFrom = yearTo - 2;
        } else if (
          vm.currentChart.qNumber === "DE2a" ||
          vm.currentChart.qNumber === "DE2b"
        ) {
          yearFrom = yearTo - 1;
        }

        $http({
          url: appConfig.webServiceUrl + "stats",
          method: "GET",
          params: {
            fashionSeason:
            vm.filter.season && vm.filter.season.id === "all" ? "" : vm.filter.season.id,
            fashionDesigner:
              vm.filter.designer.id === "all" ? "" : vm.filter.designer.id,
            fashionRegion: vm.filter.region && vm.filter.region.id === "all" ? "" : vm.filter.region.id,
            fashionCity: vm.filter.city && vm.filter.city.id === "all" ? "" : vm.filter.city.id,
            fashionCategory:
            vm.filter.category && vm.filter.category.id === "all" ? "" : vm.filter.category.id,
            fashionYear:
              yearFrom || vm.filter.year.id === "all" ? "" : vm.filter.year.id,
            yearFrom: yearFrom || null,
            yearTo: yearFrom ? yearTo : null,
          },
        }).then(function (res) {
          vm.grayList = res.data.data;
          vm.grayList['regions'] = vm.meta.allRegion;
          vm.grayList['years'].unshift({
            id: vm.currentYear,
            title: vm.currentYear.toString()
          });
          vm.description =
            "YEARS-" +
            res.data.counts.years +
            " | COLORS-" +
            res.data.counts.colors +
            " | CITIES-" +
            res.data.counts.cities +
            " | REGIONS-" +
            res.data.counts.regions +
            " | DESIGNER-" +
            res.data.counts.designers +
            " | SEASONS-" +
            res.data.counts.seasons;
          vm.isLoadingControls = false;
        });
      }

      vm.labelToGray = function (selector, title) {
        let result = true;

        if (title.indexOf("ALL ") === -1) {
          _.forEach(vm.grayList[selector], function (item) {
            if (item.title === title) {
              result = false;
            }
          });
        }
        return result;
      };

      vm.isFilterItems = function (selector, title) {
        let result = true;
        if (vm.grayList[selector] === undefined) {
          result = false;
        } else if (
          selector === "years" &&
          vm.filter.year.title === "ALL YEARS" &&
          (vm.currentChart.qNumber === "CO3a" ||
            vm.currentChart.qNumber === "SE2a" ||
            vm.currentChart.qNumber === "SE2b" ||
            vm.currentChart.qNumber === "CA3a" ||
            vm.currentChart.qNumber === "CA3b" ||
            vm.currentChart.qNumber === "DE2a" ||
            vm.currentChart.qNumber === "DE2b")
        ) {
          result = false;
        } else if (
          selector === "regions" &&
          (vm.currentChart.qNumber === "RE1a" ||
            vm.currentChart.qNumber === "CI1a" ||
            vm.currentChart.qNumber === "DE3a")
        ) {
          result = false;
        } else if (
          vm.grayList === {} ||
          title.toString().indexOf("ALL ") === -1
        ) {
          _.forEach(vm.grayList[selector], function (item) {
            if (item.title === title.toString()) {
              result = false;
            }
          });
        } else {
          result = false;
        }
        return result;
      };

      vm.generateImgName = function (type, board, name) {
        // const abbrevs = {
        //   qNumber: board === "fashion" ? vm.currentChart.qNumber : name,
        //   category: vm.filter.category
        //     ? vm.filter.category.all
        //       ? "AllCa"
        //       : vm.getAbbrv("category")
        //     : "AllCa",
        //   season: vm.filter.season
        //     ? vm.filter.season.all
        //       ? "AllSe"
        //       : vm.getAbbrv("season")
        //     : "AllSe",
        //   year: vm.filter.year
        //     ? vm.filter.year.all
        //       ? "AllYe"
        //       : "17"
        //     : "AllYe",
        //   region: vm.filter.region
        //     ? vm.filter.region.all
        //       ? "AllRe"
        //       : vm.getAbbrv("region")
        //     : "AllRe",
        //   city: vm.filter.city
        //     ? vm.filter.city.all
        //       ? "AllCi"
        //       : vm.getAbbrv("city")
        //     : "AllCi",
        // };
        let result = "";
        const date = moment().format("L");

        // _.map(abbrevs, function (item) {
          result += name.toUpperCase() + ".";
        // });

        return result + date + type;
      };

      vm.exportJpgThroughDomToImage = (name) => {
        let node;
        if (vm.selectedMenuId === 2) {
          node = angular.element("#brandCapture")[0];
        } else if (vm.selectedMenuId === 4) {
          node = angular.element("#autoCapture")[0];
        } else if (vm.selectedMenuId === 5) {
          node = angular.element("#pharmaCapture")[0];
        } else if (vm.selectedMenuId === 6) {
          node = angular.element("#legalCapture")[0];
        } else if (vm.selectedMenuId === 7) {
          node = angular.element("#artCapture")[0];
        } else if (vm.selectedMenuId === 8) {
          node = angular.element("#natureCapture")[0];
        } else if (vm.selectedMenuId === 9) {
          node = angular.element("#trackerCapture")[0];
        } else if (vm.selectedMenuId === 10) {
          node = angular.element("#sportCapture")[0];
        } else if (vm.selectedMenuId === 11) {
          node = angular.element('#unicornCapture')[0];
        }

        const titles = angular.element(".graphic-titles");
        const header = angular.element(".graphic-titles-export");
        const footer = angular.element(".customized-infographics-footer");

        footer.css({ display: "block" });
        header.css({ display: "block" });
        titles.css({ display: "none" });
        $("html").addClass("hide-scrollbar");

        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            const img = new Image();
            img.src = dataUrl;

            const link = document.createElement("a");
            link.href = img.src;
            link.download = vm.generateImgName(".png", "brand", name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            $("html").removeClass("hide-scrollbar");
            footer.css({ display: "none" });
            header.css({ display: "none" });
            titles.css({ display: "block" });
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      };

      vm.exportJpg = function (name) {
        const captureEl = angular.element("#capture");
        const footer = angular.element(".customized-infographics-footer");
        const titles = angular.element(".graphic-titles");
        const header = angular.element(".graphic-titles-export");
        let newWidth;

        if (vm.currentChart.qNumber === "CA1a") {
          $("#custom-chart").removeClass("chart");
        }

        if (captureEl.width() < 1135) {
          newWidth = "calc(100% - 15px)";
        } else {
          newWidth = "100%";
        }

        footer.css({ display: "block", width: newWidth });
        header.css({ display: "block", width: newWidth });
        titles.css({ display: "none" });
        captureEl.css({ "padding-top": "30px", "margin-top": "-30px" });
        const captureElHeight = captureEl.height();
        const captureElWidth = captureEl.width();
        $("html").addClass("hide-scrollbar");

        timeout(function () {
          html2canvas(captureEl[0], {
            height: captureElHeight + 20,
            width: captureElWidth,
            allowTaint: true,
            logging: true,
          }).then(function (canvas) {
            const img = canvas.toDataURL("image/jpeg");
            download(img, vm.generateImgName(".jpg", "fashion", name), "image/jpg");
            $("html").removeClass("hide-scrollbar");
            captureEl.css({ "padding-top": "0", "margin-top": "0" });
            footer.css({ display: "none", width: "100%" });
            header.css({ display: "none", width: "100%" });
            titles.css({ display: "block" });
          });
        }, 50);
      };

      function generateRandomGroupColorByGroupTitle(title) {
        title = (title || "").replace(/[^\w]/g, "").toLowerCase();
        const group =
          (groupTitlesTemplates[title] || {}).template || "#{0}{1}{2}";

        return String.format(
          group,
          randomColorFraction() + randomColorFraction(),
          randomColorFraction() + randomColorFraction(),
          randomColorFraction() + randomColorFraction()
        );

        function randomColorFraction() {
          return Math.round(Math.random() * 15).toString(16);
        }
      }

      vm.updateQueryBySelectors = function () {
        if (vm.selectedMenuId === 1) {
          vm.title = prepareTitle(vm.currentChart.chartTitle);
          // console.log('update ', vm.currentChart);
          vm.getOptionForDrop(vm.currentChart);
          const {
            designer,
            color,
            category,
            season,
            year,
            region,
            city,
          } = vm.filter;
          vm.isLoadingControls = true;
          $location.search({
            board: vm.selectedMenuId,
            chart: vm.currentChart.id,
            designer: (designer && designer.title) || "DESIGNERS",
            color: color && color.title,
            category: (category && category.title) || "CATEGORIES",
            season: (season && season.title) || "SEASONS",
            year: (year && year.title) || "YEARS",
            region: (region && region.title) || "REGIONS",
            city: (city && city.title) || "CITIES",
          });
        }
      };

      vm.updateQueryBySelectorsMain = function () {
        if (vm.selectedMenuId === 1) {
          vm.filter = {
            "season": {
              "id": "all",
              "title": "SEASONS",
              "region": "all",
              "serverName": "all",
              "all": true
            },
            "designer": {
              "id": "all",
              "title": "DESIGNERS",
              "region": "all",
              "serverName": "all",
              "all": true
            },
            "category": {
              "id": "all",
              "title": "CATEGORIES",
              "region": "all",
              "serverName": "all",
              "all": true
            },
            "year": {
              "id": "all",
              "title": "YEARS",
              "region": "all",
              "serverName": "all",
              "all": true
            },
            "region": {
              "id": "all",
              "title": "REGIONS",
              "region": "all",
              "serverName": "all",
              "all": true
            },
            "city": {
              "id": "all",
              "title": "CITIES",
              "region": "all",
              "serverName": "all",
              "all": true
            }
          }
          vm.title = prepareTitle(vm.currentChart.chartTitle);
          vm.getOptionForDrop(vm.currentChart);
          const {
            designer,
            color,
            category,
            season,
            year,
            region,
            city,
          } = vm.filter;
          vm.isLoadingControls = true;
          $location.search({
            board: vm.selectedMenuId,
            chart: vm.currentChart.id,
            designer: (designer && designer.title) || "DESIGNERS",
            color: color && color.title,
            category: (category && category.title) || "CATEGORIES",
            season: (season && season.title) || "SEASONS",
            year: (year && year.title) || "YEARS",
            region: (region && region.title) || "REGIONS",
            city: (city && city.title) || "CITIES",
          });
        }
      };

      vm.initStatesByQueryParams = function () {
        if (vm.selectedMenuId !== 1) {
          return false;
        }
        const {
          board,
          chart,
          designer,
          color,
          category,
          season,
          year,
          region,
          city,
        } = $location.search();
        timeout(() => {
          vm.selectedMenuId = Number(board) || 1;
          if (vm.selectedMenuId === 1) {
            vm.currentChart =
              vm.charts.find((i) => i.id === chart) || vm.charts[0];
            vm.filter.season =
              vm.meta.seasons &&
              vm.meta.seasons.find((i) => i.title === season);
            vm.filter.designer =
              vm.meta.designers &&
              vm.meta.designers.find((i) => i.title === designer);
            vm.filter.color =
              vm.meta.colorGroups &&
              vm.meta.colorGroups.find((i) => i.title === color);
            vm.filter.category =
              vm.meta.categories &&
              vm.meta.categories.find((i) => i.title === category);
            vm.filter.year =
              vm.meta.years && vm.meta.years.find((i) => i.title == year);
            vm.filter.region =
              vm.meta.regions &&
              vm.meta.regions.find((i) => i.title === region);
            vm.filter.city =
              vm.meta.cities && vm.meta.cities.find((i) => i.title === city);
          }
        }, 0);
      };
    },
  ]);
})(angular);

app.filter('propsFilter', function() {
  return function(items, props) {
    var out = [];
    if (angular.isArray(items)) {
      items.forEach(function(item) {
        var keys = Object.keys(props);
        var prop = keys[0];
        var text = props[prop].toLowerCase();
        if (item[prop].toString().toLowerCase().indexOf(text) === 0) {
          out.push(item);
        }
      });
    } else {
      out = items;
    }
    return out;
  };
});