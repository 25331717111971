angular.module('app').directive('hueSentimentColorFrequency', function () {
  function link(scope, element, attrs) {
    // default value
    scope.threeColorPercentage = 100;

    scope.expandedGroupClass = null;
    scope.setExpandedGroupClass = function (groupClass) {
      scope.expandedGroupClass = groupClass;
    };
    scope.getClass = function () {
      var result = [];
      if (scope.expandedGroupClass)
        result.push(scope.expandedGroupClass);

      return result.join(' ');
    };

    if (scope.colorData && scope.colorData.length) {
      scope.threeColorPercentage = scope.colorData
        .map(item => parseFloat(item.percentage))
        .reduce((sum, value) => sum + value, 0);
    }

    scope.getWidth = (percentage)=> {
      return parseFloat(percentage) / scope.threeColorPercentage * 100;
    };

  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/dbHueSentimentColorFrequency/dbHueSentimentColorFrequencyView.html',
    link: link,
    scope: {
      colorData: '=',
      percentage: '=',
      title: '=',
    }
  };
});
