angular.module("app").service("artDashboardRepository", [
  "appConfig",
  "$q",
  "authService",
  "httpService",
  function (appConfig, $q, authService, httpService) {

    this.getArtColor = () => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/art/search/colors", authService.getHeaderConfig())
        .then((data) => {
          return data.data;
        });
    }


    this.getArtSchool = () => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/art/schools", authService.getHeaderConfig())
        .then((data) => {
          return data.data;
        });
    }

    this.getArtArtist = function(str, page) {
      str = str.split(' ').join('+');
      return httpService.get(appConfig.dashboardServiceUrl + 'api/art/search/artists?q[artist_end]='+ str,
        authService.getHeaderConfig()
      ).then(function (data) {
          return data.data;
      });
    } 

    this.getArtPeriods = function(str, page) {
      str = str.split(' ').join('+');
      return httpService.get(appConfig.dashboardServiceUrl + 'api/art/search/periods?q[period_start]='+ str,
        authService.getHeaderConfig()
      ).then(function (data) {
          return data.data;
      });
    } 

    this.getArtWork = (field, id, page, letter, school) => {
      let queryField = '';
      if (field === 'color') {
        queryField = 'color_id';
      } else {
        queryField = field;
      }

      if(field === 'color') {
        return httpService
        .get(
          appConfig.dashboardServiceUrl +
          `api/art/dashboard/artworks?art[${queryField}]=${id.id}&page=${page}&per_page=40&art[letter]=${letter}&art[school]=${school.name === 'All Schools' ? '' : school.name}`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          return res.data;
        });
      } else if (field === 'artist') {
        return httpService
        .get(
          appConfig.dashboardServiceUrl +
          `api/art/dashboard/artworks?art[${queryField}]=${id.id}&page=${page}&per_page=40&art[letter]=${letter}`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          return res.data;
        });
      } else {
        return httpService
        .get(
          appConfig.dashboardServiceUrl +
          `api/art/dashboard/artworks?art[${queryField}]=${id.title}&page=${page}&per_page=40&art[letter]=${letter}`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          return res.data;
        });
      }
    };

    this.getArtWorkDetails = (id) => {
      return httpService
        .get(
          appConfig.dashboardServiceUrl +
          `api/art/dashboard/picture?id=${id}`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          return res.data;
        });
    };

    this.getColorComparedData = (school, id) => {
      return httpService
        .get(
          appConfig.dashboardServiceUrl +
          `api/art/dashboard/color?art[school]=${school}&art[color_id]=${id}&node=header`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          return res.data;
        });
    };

    this.getColorComparedDataChart1 = (school, id) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/dashboard/color?art[school]=${school}&art[color_id]=${id}&node=chartData1`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };

    this.getColorComparedDataChart2 = (school, id) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/dashboard/color?art[school]=${school}&art[color_id]=${id}&node=chartData2`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };

    this.getColorComparedDataChart3 = (school, id, letter) => {
      let letterLower = 'all'
      if(letter) {
        letterLower = letter.toLowerCase();
      }
      return httpService
        .get(
          appConfig.dashboardServiceUrl +
          `api/art/dashboard/color?art[school]=${school}&art[color_id]=${id}&art[letter]=${letterLower}&node=chartData3`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          return res.data;
        });
    };

    this.getColorDataArtist = (artist) => {
      return httpService
        .get(
          appConfig.dashboardServiceUrl +
          `api/art/dashboard/artist?art[artist]=${artist}&node=header`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          return res.data;
        });
    };

    this.getColorDataArtistChart1 = (artist) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/dashboard/artist?art[artist]=${artist}&node=chartData1`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };

    this.getColorDataArtistChart2 = (artist) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/dashboard/artist?art[artist]=${artist}&node=chartData2`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };

    this.getColorDataArtistChart3 = (artist) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/dashboard/artist?art[artist]=${artist}&node=chartData3`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };

    this.getColorDataSchool = (school) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/dashboard/school?art[school]=${school}&node=header`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };

    this.getColorDataSchoolChart1 = (school) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/dashboard/school?art[school]=${school}&node=chartData1`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };

    this.getColorDataSchoolChart2 = (school) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/dashboard/school?art[school]=${school}&node=chartData2`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };

    this.getColorDataSchoolChart3 = (school) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/dashboard/school?art[school]=${school}&node=chartData3`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };

    this.getColorDataSchoolChart4 = (school, letter) => {
      let letterLower = 'all'
      if(letter) {
        letterLower = letter.toLowerCase();
        return httpService
        .get(
          appConfig.dashboardServiceUrl +
          `api/art/dashboard/school?art[school]=${school}&node=chartData4&art[letter]=${letterLower}`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          return res.data;
        });
      }
    };

    this.getColorDataSchoolChart5 = (school, letter) => {
      let letterLower = 'all'
      if(letter) {
        letterLower = letter.toLowerCase();
        return httpService
        .get(
          appConfig.dashboardServiceUrl +
          `api/art/dashboard/school?art[school]=${school}&node=chartData5&art[letter]=${letterLower}`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          return res.data;
        });
      }
    };

    this.getColorDataPeriod = (period) => {
      return httpService
        .get(
          appConfig.dashboardServiceUrl +
          `api/art/dashboard/period?art[period]=${period}&node=header`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          return res.data;
        });
    };

    this.getColorDataPeriodChart1 = (period) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/dashboard/period?art[period]=${period}&node=chartData1`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };

    this.getColorDataPeriodChart3 = (period) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/dashboard/period?art[period]=${period}&node=chartData3`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };

    this.getColorDataPeriodChart4 = (period, letter) => {
      let letterLower = 'all'
      if(letter) {
        letterLower = letter.toLowerCase();
        return httpService
        .get(
          appConfig.dashboardServiceUrl +
          `api/art/dashboard/period?art[period]=${period}&node=chartData4&art[letter]=${letterLower}`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          return res.data;
        });
      }
    };

    this.getColorDataPeriodChart2 = (period, letter) => {
      let letterLower = 'all'
      if(letter) {
        letterLower = letter.toLowerCase();
        return httpService
        .get(
          appConfig.dashboardServiceUrl +
          `api/art/dashboard/period?art[period]=${period}&node=chartData2&art[letter]=${letterLower}`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          return res.data;
        });
      }
    };

    this.getArtInfographicsChart1 = (school, color) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/custom_analytics/chart?art[color_id]=${color}&art[school]=${school}&option=1&node=chartData2`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };

    this.getArtInfographicsChart2 = (artist) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/custom_analytics/chart?art[artist]=${artist}&option=2&node=chartData2`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };

    this.getArtInfographicsChart3 = (artist) => {
      return httpService
      .get(
        appConfig.dashboardServiceUrl +
        `api/art/custom_analytics/chart?art[artist]=${artist}&option=2&node=chartData1`,
        authService.getHeaderConfig()
      )
      .then((res) => {
        return res.data;
      });
    };
  },
]);
