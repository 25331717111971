(function () {
  angular.module("app").directive("colorLineChart", [
    function () {
      function link(scope) {
        scope.$watch('data', function (newValue, oldValue) {
          if (newValue !== oldValue) {
            scope.data = newValue;
            scope.bindData();
          }
        }, true);

        scope.bindData = () => {
          const parent = document.getElementById(`my_canvas_${scope.chartId}_container`);
          while (parent && parent.firstChild) {
            parent.removeChild(parent.firstChild);
          }
          const canvas = document.createElement("canvas");

          if (parent) {
            parent.appendChild(canvas);
            canvas.setAttribute("id", `my_canvas_${scope.chartId}`);
            canvas.setAttribute("width", "100%");
          }

          const ctx = canvas.getContext("2d");

          let tempLabels = scope.data[0].timeValues;
          let labels = []
          if(scope.name !== undefined) {
            tempLabels.map(label => {
              let name = scope.name.toUpperCase();
              labels.push([name, label])
            });
          } else {
            labels = tempLabels;
          }

          const datasets = scope.data.map((el) => {
            const isColorLight = () => {
              const color =
                el.color.hex === "#FFFFFF"
                  ? "#010101"
                  : el.color.hex === "#F5F5DC"
                  ? "#030303"
                  : el.color.hex;

              const offset =
                color === "#010101"
                  ? [20, 3]
                  : color === "#030303"
                  ? [10, 5]
                  : [];

              return { color, offset };
            };
            let points = el.points;
            el.points = points.map(point => point.value ? point.value : point);

            if (el.color && el.color.type === 'average') {
              return {
                data: el.points.slice(-scope.data[0].timeValues.length),
                borderWidth: 5, // set diameter of dots here
                backgroundColor: "#fff",
                borderColor: "#010101",
                fill: false,
                borderDash: [0, 10],
                borderCapStyle: 'round',
                pointBorderColor: isColorLight().color,
                pointBackgroundColor: "#dbdbdb",
                pointBorderWidth: 1,
                pointHoverRadius: 8,
                pointHoverBackgroundColor: isColorLight().color,
                pointHoverBorderColor: isColorLight().color,
                pointHoverBorderWidth: 2,
                pointRadius: 8,
                pointHitRadius: 10,
              };
            }

            return {
              label: "",
              fill: false,
              lineTension: 0.4,
              backgroundColor: el.color.hex,
              borderWidth: 1,
              borderColor: isColorLight().color,
              borderCapStyle: "butt",
              borderDash: isColorLight().offset,
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: isColorLight().color,
              pointBackgroundColor: "#dbdbdb",
              pointBorderWidth: 1,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: isColorLight().color,
              pointHoverBorderColor: isColorLight().color,
              pointHoverBorderWidth: 2,
              pointRadius: 8,
              pointHitRadius: 10,
              data: el.points.slice(-scope.data[0].timeValues.length),
              spanGaps: false,
            };
          });

          const data = {
            labels: labels,
            datasets: datasets,
          };
            window.myLineChart = new Chart(ctx, {
              type: "line",
              data: data,
              options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  display: false,
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero:true,
                        fontSize: 14,
                        padding: 5,
                        min: 0,
                        userCallback: function(label, index, labels) {
                          if (Math.floor(label) === label) {
                              return label;
                          }
                        },
                      },
                    },
                  ],
                  xAxes: [
                    {
                      ticks: {
                        fontSize: 14,
                        padding: 5,
                      },
                    },
                  ],
                },
              },
            });
          }
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          data: "=",
          chartId: "=",
          name: "="
        },
        templateUrl:
          "app/directives/colorLineChart/colorLineChartView.html",
      };

      return directive;
    },
  ]);
})();
