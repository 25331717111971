angular.module("app").directive("homeStyleOfColorCategory", ["$timeout", (timeout) => {
  let link = (scope) => {  
    scope.saveItemImageDetails = false;

    scope.$watch('styleCategoryData', function (newVal, prevVal) {
      if(newVal) {
        scope.styleCategoryData = newVal;
      }
    });

    scope.showImageDetails = false;
    scope.toggleImageDetails = () => {
      scope.showImageDetails = !scope.showImageDetails;
    };

    scope.imageClickHandler = (brand, index) => {
      scope.index = index;
      scope.popupData = {
        category: scope.styleCategoryData.category,
        color: brand.colors[index],
        style: scope.styleCategoryData.style,
        brand: brand.name
      }
      scope.toggleImageDetails();
    };

    scope.$on("mood_state_item_chart", (data) => {
      let mainData = data.targetScope.data;
      const keys = ["CATEGORY", "COLOR", "STYLE", "BRAND"];
      let newParam = {
        [keys[0]]: mainData.category,
        [keys[1]]: mainData.color.title,
        [keys[2]]: mainData.style,
        [keys[3]]: mainData.brand
      }
      let node = angular.element("#homeItemPopupCapture")[0];
      domtoimage
        .toPng(node, {
          bgcolor: "#fff",
        })
        .then((dataUrl) => {
          if(dataUrl) {
            scope.saveImageDetailsData = {
              base64: dataUrl,
              type: 'home',
              data: newParam
            };
          }
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });

        let times = 2000;
        timeout(function () {
          if(scope.saveImageDetailsData) {
            scope.showImageDetails = false;
            scope.toggleSaveImageDetails();
          } else {
            timeout(function () {
              if(scope.saveImageDetailsData) {
                scope.showImageDetails = false;
                scope.toggleSaveImageDetails();
              } else {
                timeout(function () {
                  if(scope.saveImageDetailsData) {
                    scope.showImageDetails = false;
                    scope.toggleSaveImageDetails();
                  }
                }, times);
              }
            }, times);
          }
        }, times);
    });

    scope.generateImgName = function (type, board, name) {
      let result = "";
      const date = moment().format("L");
      result += name.toUpperCase() + ".";
      return result + date + type;
    };

    scope.toggleSaveImageDetails = () => {
      scope.saveItemImageDetails = !scope.saveItemImageDetails;
    };

    scope.$on("mood_state_item_chart_changed", () => {
      scope.toggleSaveImageDetails();
    });
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/homeStyleOfColorCategory/homeStyleOfColorCategoryView.html",
    link: link,
    scope: {
      styleCategoryData: "=",
    }
  };
}]);
