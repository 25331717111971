angular.module("app").directive("logoAcrossAllIndustry", function () {
  function link(scope) {
    scope.selectedFilterValue = {id: 'black', title: 'black', hex: '#000000'}
  }

  const directive = {
    link: link,
    restrict: "E",
    replace: true,
    scope: {
      data: "=",
    },
    templateUrl:
      "app/directives/customInfographicsUnicornDirectives/logoAcrossAllIndustry/logoAcrossAllIndustry.html",
  };

  return directive;
});
