angular.module("app").component("appHeader", {
  templateUrl: "app/header.html",
  controller: function (
    $state,
    authService,
    $rootScope,
    $scope,
    localStorageService
  ) {
    const self = this;

    $rootScope.$on("$viewContentLoaded", function () {
      let currentScreen  = localStorage.getItem("currentScreen");
      JSON.stringify(currentScreen)
      
      if (currentScreen !== null && (currentScreen !== JSON.stringify($state.current.name))) {
        if (
          $state.current.name === "aboutUs" ||
          $state.current.name === "pricing" ||
          $state.current.name === "contact" ||
          $state.current.name === "landing-page" ||
          $state.current.name === "educationCoursesDetails" ||
          $state.current.name === "internships"
        ) {
          reactLoader = document.getElementById("react_loader");
          reactLoader.style.display = "block";
          reactHeader = document.getElementById("react-header");
          reactHeader.remove();
  
          reactScript = document.getElementById("reactBundleJs");
          reactScript.remove();
  
          const newHeader = document.createElement("div");
          newHeader.id = "react-header";
          document.getElementById("header_wrap").appendChild(newHeader);
  
          var reactScript = document.createElement("script");
          reactScript.defer = 1;
          reactScript.id = "reactBundleJs";
          reactScript.src = "./react-dist/bundle.js";
          document.getElementsByTagName("body")[0].appendChild(reactScript);
          reactLoader.style.display = "none";
        }
      }
      localStorage.setItem("currentScreen", JSON.stringify($state.current.name));
    });

    this.navigations = [
      {
        name: "About",
        subNavs: [
          {
            name: "About Us",
            state: "aboutUs",
            imgUrl: "/assets/img/menus/company.png",
            onlyAdmin: true,
          },
          {
            name: "Pricing",
            state: "pricing",
            imgUrl: "/assets/img/menus/pricing.png",
            onlyAdmin: true,
          },
          // { name: "School Registration", state: "schoolRegister", imgUrl: "/assets/img/menus/team.png", onlyAdmin: true },
          // { name: "Blog", state: "colorBlog", imgUrl: "/assets/img/menus/blog.png", onlyAdmin: true },
        ],
      },
      {
        name: "Color Data",
        subNavs: [
          {
            name: "Global Color Pulse",
            state: "globalColor",
            imgUrl: "/assets/img/menus/tracker.png",
            onlyAdmin: true,
          },
          {
            name: "Fashion Color Trends",
            state: "fashion",
            imgUrl: "/assets/img/menus/fashion.png",
            onlyAdmin: true,
          },
          {
            name: "Home Color Trends",
            state: "home",
            imgUrl: "/assets/img/menus/home.png",
            onlyAdmin: true,
          },
          {
            name: "Brand Color Insights",
            state: "branding",
            imgUrl: "/assets/img/menus/brand.png",
            onlyAdmin: true,
          },
          {
            name: "Auto Color Trends",
            state: "auto",
            imgUrl: "/assets/img/menus/auto.png",
            onlyAdmin: true,
          },
          {
            name: "Pharmaceutical Colors",
            state: "pharma",
            imgUrl: "/assets/img/menus/pharmaceutical.png",
            onlyAdmin: true,
          },
          {
            name: "Art Colors",
            state: "art",
            imgUrl: "/assets/img/menus/art.png",
            onlyAdmin: true,
          },
          {
            name: "Sport Colors",
            state: "sport",
            imgUrl: "/assets/img/menus/sport.png",
            onlyAdmin: true,
          },
          {
            name: "Nature Colors",
            state: "nature",
            imgUrl: "/assets/img/menus/nature.png",
            onlyAdmin: true,
          },
          {
            name: "Legal Colors",
            state: "legal-trends",
            imgUrl: "/assets/img/menus/legal.png",
            onlyAdmin: true,
          },
          {
            name: "VR Games Colors",
            state: "vr-games",
            imgUrl: "/assets/img/menus/game.png",
            onlyAdmin: true,
          },
          {
            name: "Fragrance Colors",
            state: "fragrance",
            imgUrl: "/assets/img/menus/fragrance.png",
            onlyAdmin: true,
          },
        ],
      },
      {
        name: "Research & Indices",
        subNavs: [
          {
            name: "Color Naming Index",
            state: "colorIndex",
            imgUrl: "/assets/img/menus/color-naming.png",
            onlyAdmin: true,
          },
          {
            name: "Color Emotion Study",
            state: "colorEmotionStudy",
            imgUrl: "/assets/img/menus/color-emotion.png",
            onlyAdmin: true,
          },
          {
            name: "Color Research Index",
            state: "colorResearchIndex",
            imgUrl: "/assets/img/menus/color-research.png",
            onlyAdmin: true,
          },
          {
            name: "Color Unicorn Index",
            state: "colorUnicornIndex",
            imgUrl: "/assets/img/menus/uni.png",
            onlyAdmin: true,
          },
        ],
      },
      {
        name: "Resources",
        subNavs: [
          {
            name: "Reports",
            state: "reports",
            imgUrl: "/assets/img/menus/reports.png",
            onlyAdmin: false,
          },
          {
            name: "Charts",
            state: "infographics",
            imgUrl: "/assets/img/menus/infographics.png",
            onlyAdmin: true,
          },
          // { name: "Custom Analytics", state: "customizedInfographics", imgUrl: "/assets/img/menus/custom-infographics.png", onlyAdmin: true },
          {
            name: "Podcasts",
            state: "educationPodcasts",
            imgUrl: "/assets/img/menus/podcast.svg",
            onlyAdmin: true,
          },
          {
            name: "Color Courses",
            state: "educationCourses",
            imgUrl: "/assets/img/menus/teaching.svg",
            onlyAdmin: false,
          },
          {
            name: "Teaching Materials",
            state: "educationTeachingMaterials",
            imgUrl: "/assets/img/menus/courses.svg",
            onlyAdmin: false,
          },
        ],
      },
      // {
      //   name: "Education",
      //   subNavs: [
      //     { name: "Podcasts", state: "educationPodcasts", imgUrl: "/assets/img/menus/podcast.svg", onlyAdmin: true },
      //     { name: "Color Courses", state: "educationCourses", imgUrl: "/assets/img/menus/teaching.svg", onlyAdmin: false },
      //     { name: "Teaching Materials", state: "educationTeachingMaterials", imgUrl: "/assets/img/menus/courses.svg", onlyAdmin: false },
      //   ],
      // },
      {
        name: "Contact",
        subNavs: [
          {
            name: "Contact Us",
            state: "contact",
            imgUrl: "/assets/img/menus/contact.png",
            onlyAdmin: true,
          },
          {
            name: "Partnership Inquires",
            state: "partners",
            imgUrl: "/assets/img/menus/data-partners.png",
            onlyAdmin: true,
          },
          {
            name: "Press Inquires",
            state: "press",
            imgUrl: "/assets/img/menus/press-inquiry.png",
            onlyAdmin: true,
          },
        ],
      },
    ];

    this.navigation_profile = [
      {
        name: "Hi",
        subNavs: [{ name: "Profile", state: "profile" }],
      },
    ];

    this.selectedTab = false;
    this.selectedSubTab = [];
    this.user = null;

    this.getActiveMainNav = function (stateName) {
      let isActive;
      angular.forEach(this.navigations, function (nav) {
        if (stateName === nav.name && nav.subNavs) {
          angular.forEach(nav.subNavs, function (subNav) {
            if (subNav.state === $state.current.name) {
              isActive = true;
            }
            if (subNav.subNavs) {
              angular.forEach(subNav.subNavs, function (item) {
                if (item.state === $state.current.name) {
                  isActive = true;
                }
              });
            }
          });
        }
      });
      return isActive;
    };

    this.userIsLoggedIn = function () {
      return Boolean(self.user && Object.keys(self.user).length);
    };

    this.logOut = function () {
      authService.logOut();
    };

    this.goProfile = function () {
      $state.go("profile");
    };

    this.goToLanding = function () {
      $state.go("/");
    };

    $scope.$watch(
      function () {
        return authService.currentUser;
      },
      function (newVal) {
        self.user = localStorageService.get("currentUser");
      }
    );

    this.hideHeader = function () {
      return $state.current.name === "landing";
    };

    this.handleLinkClick = function (path) {
      window.open(path, "_blank");
    };

    this.getRouteState = function (state) {
      if (state === "pharma") {
        return "pharma({control: 'searchMenu', id: '', title: '', hex: ''})";
      } else if (state === "legal-trends") {
        return "legal-trends({control: 'searchMenu', id: '', title: '', hex: ''})";
      } else if (state === "art") {
        return "art({control: 'searchMenu', id: '', title: '', hex: ''})";
      } else if (state === "nature") {
        return "nature({mode: '', control: '', id: '', title: '', hex: ''})";
      } else if (state === "sport") {
        return "sport({control: 'searchMenu', id: '', title: '', hex: ''})";
      } else if (state === "fashion") {
        return "fashion({control: 'searchMenu', id: '', title: '', hex: ''})";
      } else if (state === "home") {
        return "home({control: 'searchMenu', id: '', title: '', hex: ''})";
      } else if (state === "auto") {
        return "auto({control: 'searchMenu', id: '', title: '', hex: ''})";
      } else if (state === "vr-games") {
        return "vr-games({control: 'searchMenu', id: '', title: '', hex: '', name: ''})";
      } else if (state === "fragrance") {
        return "fragrance({control: 'searchMenu', id: '', title: '', hex: '', name: ''})";
      }

      return state;
    };
  },
});

$("document").ready(function () {
  // RESTYLE THE DROPDOWN MENU
  $("#google_translate_element").on("click", function () {
    // Change font family and color
    $("iframe")
      .contents()
      .find(
        ".goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div, .goog-te-menu2 *"
      )
      .css({
        color: "#333",
        "font-family": "Gotham-Book",
      });
    // Change menu's padding
    $("iframe")
      .contents()
      .find(".goog-te-menu2-item-selected")
      .css("display", "none");

    // Change menu's padding
    $("iframe").contents().find(".goog-te-menu2").css("padding", "0px");
    $("iframe")
      .contents()
      .find(".goog-te-menu2")
      .find("table")
      .css("width", "100%");

    // Change the padding of the languages
    $("iframe")
      .contents()
      .find(".goog-te-menu2-item div")
      .css({ padding: "5px", "background-color": "f6f6f6" });

    // Change hover effects
    $("iframe")
      .contents()
      .find(".goog-te-menu2-item div")
      .hover(
        function () {
          $(this)
            .css("background-color", "f6f6f6")
            .find("span.text")
            .css("color", "#333");
        },
        function () {
          $(this)
            .css("background-color", "f6f6f6")
            .find("span.text")
            .css("color", "#333");
        }
      );

    // Change Google's default blue border
    $("iframe").contents().find(".goog-te-menu2").css("border", "none");

    // Change the iframe's size and position?
    $(".goog-te-menu-frame").css({
      // 'height': '267px',
      width: "100px",
      top: "54px",
      "border-bottom": "6px solid rgb(187, 0, 118)",
      background: "#f6f6f6",
    });
    // Change iframes's size
    $("iframe").contents().find(".goog-te-menu2").css({
      // 'height': 'auto',
      width: "100%",
    });
  });
});
