angular.module("app").service("fragranceDashboardRepository", [
  "appConfig",
  "authService",
  "httpService",
  function (appConfig, authService, httpService) {

    this.getColors = () => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/beauty/fragnances/search/colors", authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getMainAccord = () => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/beauty/fragnances/search/accords", authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getCountry = () => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/beauty/fragnances/search/countries", authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getDesignerList = (str) => {
      return httpService.get(
        appConfig.dashboardServiceUrl + `api/beauty/fragnances/search/designers?designer=${str}`,
        authService.getHeaderConfig()
      ).then((res) => {
        if (res) {
          return res.data;
        }
      });
    };

    this.getYears = () => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/beauty/fragnances/search/years", authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getFragranceDashboard = (field, id, page, per_page, year) => {
      let queryField = '';
      let yearField = '';
      if (field === 'color') {
        queryField = 'color_id';
        yearField = `&year=${year}`;
      } else if (field === 'designer') {
        queryField = 'designer';
        yearField = `&year=${year}`;
      } else if (field === 'accord') {
        queryField = 'accord_id';
        yearField = `&year=${year}`;
      } else if (field === 'country') {
        queryField = 'country';
        yearField = `&year=${year}`;
      } else if (field === 'year') {
        queryField = 'year';
        yearField = '';
      } 

      return httpService
        .get(
          appConfig.dashboardServiceUrl +
            `api/beauty/fragnances/dashboard?${queryField}=${id}&page=${page}&per_page=${per_page}${yearField}`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    // color color data API 
    this.getColorChartsDataByColorId = (id, node, year) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/beauty/fragnances/dashboard/colors?color_id=" + id,{
              params: { token: authService.token, node: node, year:year }})
          .then(function (data) {
            return (data.data);
          });
    };

    // designer color data API 
    this.getDesignerChartsDataByDesigner = (id, node, year) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/beauty/fragnances/dashboard/designers?designer=" + id,{
              params: { token: authService.token, node: node, year:year }})
          .then(function (data) {
            return (data.data);
          });
    };

    // accord color data API 
    this.getAccordChartsDataByAccordId = (id, node, year) => {
      return httpService.get(appConfig.dashboardServiceUrl + "api/beauty/fragnances/dashboard/accords?accord_id=" + id,{
              params: { token: authService.token, node: node, year:year }})
          .then(function (data) {
            return (data.data);
          });
    };

    // country color data API 
    this.getCountryChartsDataByCountryTitle = (id, node, year) => {
      return httpService.get(appConfig.dashboardServiceUrl + "api/beauty/fragnances/dashboard/countries?country=" + id,{
              params: { token: authService.token, node: node, year:year }})
          .then(function (data) {
            return (data.data);
          });
    };

    this.getCountryChartsData4ByCountryTitle = (id, node, year, letter) => { 
      return httpService.get(appConfig.dashboardServiceUrl + `api/beauty/fragnances/dashboard/countries?country=${id}&brand_designer[letter]=${letter}`,{
              params: { token: authService.token, node: node, year:year }})
          .then(function (data) {
            return (data.data);
          });
    };

    // year color data API 
    this.getYearChartsDataByYearId = (id,node) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/beauty/fragnances/dashboard/years?year=" + id,{
              params: { token: authService.token, node: node }})
          .then(function (data) {
            return (data.data);
          });
    };

    this.getYearChartsData4ByYearId = (id, node, letter) => { 
      return httpService.get(appConfig.dashboardServiceUrl + `api/beauty/fragnances/dashboard/years?year=${id}&brand_designer[letter]=${letter}`,{
              params: { token: authService.token, node: node }})
          .then(function (data) {
            return (data.data);
          });
    };
  },
]);
