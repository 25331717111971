angular.module('app').directive('gameYearComplete', [
  () => {
    let link = (scope) => {
      scope.filteredChoices = [];
      scope.isVisible = {
        suggestions: false,
      };

      scope.filterItems = () => {
        if (scope.enteredtext.length < 0) return;
        scope.isVisible.suggestions =
          scope.enteredtext.length === 0
            ? false
            : scope.filteredChoices.length > 0;
      };

      scope.selectItem = (choice) => {
        scope.selected = choice;
        scope.enteredtext = choice.title;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };

      scope.$watch('models', function (newValue, oldValue) {
        if (newValue) {
          scope.models = newValue;
          scope.filteredChoices = scope.models.map(item => {
            return { title: item, id: item };
          });
          scope.filteredChoices.sort((a, b) => (a.id < b.id) ? 1 : -1);
        }
      }, true);
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbGameYearComplete/dbGameYearCompleteView.html",
      link: link,
      scope: {
        enteredtext: "=",
        selected: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        disabled: "=",
        disable: "=",
        board: "=",
        models:"=",
      }
    };
  }
]);
