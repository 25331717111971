angular.module("app").directive("crossIndustryLogoColorFamiliesComparison", () => {
  let link = (scope) => {

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbCrossIndustryLogoColorFamiliesComparison/dbCrossIndustryLogoColorFamiliesComparisonView.html",
    link: link,
    scope: {
      colorFamilies: "="
    }
  };
});
