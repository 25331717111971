angular.module("app").directive("legalPieChart", function ($filter) {
  function link(scope) {
    // scope.$watch("selectedItem", bindData);

    scope.$watch('data', function (data) {
      if(data){
        scope.data = data;
        scope.bindData();
      }
    });

    scope.bindData = () => {
      if(scope.mode === 'popup'){
        document.getElementById("my_legal_popup_dataviz").innerHTML = "";
      } else {
        document.getElementById("my_legal_dataviz") ? document.getElementById("my_legal_dataviz").innerHTML = "" : '';
      }

      const width = 1000;
      const height = 500;
      const margin = 40;

      // scope.selectedColor = {
      //   hex: '#43f234',
      //   title: 'COLOR'
      // };

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      var svg = '';
      if(scope.mode === 'popup'){
        svg = d3
        .select("#my_legal_popup_dataviz")
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
      } else {
        svg = d3
        .select("#my_legal_dataviz")
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
      }

      // Create dummy data
      // const data = {
      //   "color1": 11,
      //   "color2": 11,
      //   "color3": 11,
      //   "color4": 11,
      //   "color5": 11,
      //   "color6": 11,
      //   "color7": 11,
      //   "color8": 11,
      //   "color9": 11,
      //   "color10": 11,
      // };

      // const colorData = {
      //   "color1": { hex: "#e51c20", hex1: "#f6bac6" },
      //   "color2": { hex: "#f07e14" },
      //   "color3": { hex: "#f5eb49", hex1: "#f1d724" },
      //   "color4": { hex: "#299741" },
      //   "color5": { hex: "#3462ac" },
      //   "color6": { hex: "#7c217f" },
      //   "color7": { hex: "#654322" },
      //   "color8": { hex: "#b9b9b8" },
      //   "color9": { hex: "#ffffff" },
      //   "color10": { hex: "#040506" },
      // };

      const apiData = scope.data ? scope.data : [];
      scope.tempData = [];
      apiData.forEach(element => {
        if(element.percentage !== 0) {
          scope.tempData.push(element);
        }
      });
      let count = scope.tempData.length;
      let fixedHeight = 100 / count;
      scope.tempData.forEach(element => {
        element.height = fixedHeight;
      });

      // Compute the position of each group on the pie:
      const pie = d3.layout
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value.height;
        });
      const data_ready = pie(d3.entries(scope.tempData));

      // The arc generator
      const arc = d3.svg
        .arc()
        .innerRadius(radius * 0.7) // This is the size of the donut hole
        .outerRadius(radius * 0.8);

      const arc3 = d3.svg
        .arc()
        .innerRadius(radius * 0.75)
        .outerRadius(radius * 0.8);

      const arc2 = d3.svg
        .arc()
        .innerRadius(radius * 0.7)
        .outerRadius(radius * 0.9);

      // Another arc that won't be drawn. Just for labels positioning
      const outerArc = d3.svg
        .arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

        var tooltip = d3.select("body")
        .append("div")
        .style("position", "absolute")
        .style("z-index", "10")
        .style("visibility", "hidden")
        .style("background", "#f7f7f7")
        .style("text-transform", "uppercase")
        .style("padding", "10px")
        .style("color", "#000000")
        .text("a simple tooltip");

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", function (d) {
          return d.data.value.hex;
        })
        .attr(
          "stroke", function (d) {
            return d.data.value.hex === "#ffffff"
              ? "#E0E0DD"
              : d.data.value.hex;
          }
        )
        .style(
          "stroke-width",  function (d) {
            return d.data.value.hex === "#ffffff"
              ? "1px"
              : "0px"
          }
        )
        .on("mouseover", function(d){tooltip.text(`${d.data.value.percentage}%`); return tooltip.style("visibility", "visible");})
        .on("mousemove", function(){return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX+10)+"px");})
        .on("mouseout", function(){return tooltip.style("visibility", "hidden");});

      svg
        .selectAll("allSlices")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc3)
        .attr("fill", function (d) {
          return d.value.hex1 ? d.value.hex1 : "transparent";
        });

      // Add the polylines between chart and labels:
      svg
        .selectAll("allPolylines")
        .data(data_ready)
        .enter()
        .append("polyline")
        .attr("stroke", "black")
        .style("fill", "none")
        .attr("stroke-width", 1)
        .attr("points", function (d) {
          const posA = arc2.centroid(d); // line insertion in the slice
          const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
          const posC = outerArc.centroid(d); // Label position = almost the same as posB
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
          posC[0] = radius * 1.7 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
          return [posA, posB, posC];
        });

      svg
        .selectAll("allLabels")
        .data(data_ready)
        .enter()
        .append("text")
        .text((d) => `${d.data.value.percentage.toFixed(1)}%`)
        .attr("transform", function (d) {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * 1.75 * (midangle < Math.PI ? 1 : -1)
          pos[1] += 8;
          return "translate(" + pos + ")";
        })
        .attr("font-size", "18px")
        .attr("font-weight", "300")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B")
        .style("text-anchor", function (d) {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midangle < Math.PI ? "start" : "end";
        });

      svg
        .append("text")
        .text(scope.totalColor.toLocaleString())
        .attr("transform", "translate(0, -25)")
        .attr("font-size", "40px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B");

      svg
        .append("text")
        .text("COLOR TRADEMARKS IN")
        .attr("transform", "translate(0, 15)")
        .attr("font-size", "20px")
        .attr("text-anchor", "middle")
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B");

      svg
        .append("text")
        .text("THE DATABASE")
        .attr("transform", "translate(0, 45)")
        .attr({
          "text-anchor": "middle",
          "font-size": "20px",
          "text-transform": "uppercase",
        })
        .attr("style", "font-family: Gotham SSm A, Gotham SSm B");

      if (scope.mode === 'infographics') {
        svg
          .append("text")
          .text("2018-PRESENT")
          .attr("transform", "translate(0, 105)")
          .attr({
            "text-anchor": "middle",
            "font-size": "18px",
            "text-transform": "uppercase",
          })
          .attr("style", "font-family: Gotham SSm A, Gotham SSm B");

        svg
          .append("image")
          .attr("href", scope.selectedItem.img_url)
          .attr("width", 50)
          .attr("height", 50)
          .attr("transform", "translate(-25, -125)");
      }
    }
  }
  return {
    restrict: "E",
    templateUrl: "app/directives/colorPieChartForLegal/colorPieChartForLegalView.html",
    link: link,
    scope: {
      mode: "=",
      selectedItem: "=",
      data: "=",
      totalColor: "=",
    },
  };
});
