angular.module('app').directive('sportTeamComplete', [
  () => {
    let link = (scope) => {
      scope.isVisible = {
        suggestions: false,
      };

      // dummy data
      scope.categories = [
        "1-9", "A-C", "D-F", "G-I", "J-L", "M-O", "P-R", "S-U", "V-Z"
      ];

      scope.filteredChoices = [
        {
          "id": 361378,
          "title": "The Douglas Stewart Company"
        },
        {
          "id": 361379,
          "title": "The Orthotic Center"
        },
        {
          "id": 361380,
          "title": "The Stone Bean at Riverside"
        },
        {
          "id": 361381,
          "title": "Columbia Gorge Discovery Center"
        },
        {
          "id": 361382,
          "title": "Church of the Ascension"
        },
        {
          "id": 361383,
          "title": "The Howard Law Firm"
        },
        {
          "id": 361384,
          "title": "The Actors’ Gang"
        },
        {
          "id": 361385,
          "title": "The Daily Holdings"
        },
        {
          "id": 361386,
          "title": "The CLVR Family of Companies"
        },
        {
          "id": 361387,
          "title": "Pancakes on the Rocks"
        },
        {
          "id": 361378,
          "title": "The Douglas Stewart Company"
        },
        {
          "id": 361379,
          "title": "The Orthotic Center"
        },
        {
          "id": 361380,
          "title": "The Stone Bean at Riverside"
        },
        {
          "id": 361381,
          "title": "Columbia Gorge Discovery Center"
        },
        {
          "id": 361382,
          "title": "Church of the Ascension"
        },
        {
          "id": 361383,
          "title": "The Howard Law Firm"
        },
        {
          "id": 361384,
          "title": "The Actors’ Gang"
        },
        {
          "id": 361385,
          "title": "The Daily Holdings"
        },
        {
          "id": 361386,
          "title": "The CLVR Family of Companies"
        },
        {
          "id": 361387,
          "title": "Pancakes on the Rocks"
        },
        {
          "id": 361378,
          "title": "The Douglas Stewart Company"
        },
        {
          "id": 361379,
          "title": "The Orthotic Center"
        },
        {
          "id": 361380,
          "title": "The Stone Bean at Riverside"
        },
        {
          "id": 361381,
          "title": "Columbia Gorge Discovery Center"
        },
        {
          "id": 361382,
          "title": "Church of the Ascension"
        },
        {
          "id": 361383,
          "title": "The Howard Law Firm"
        },
        {
          "id": 361384,
          "title": "The Actors’ Gang"
        },
        {
          "id": 361385,
          "title": "The Daily Holdings"
        },
        {
          "id": 361386,
          "title": "The CLVR Family of Companies"
        },
        {
          "id": 361387,
          "title": "Pancakes on the Rocks"
        },
        {
          "id": 361378,
          "title": "The Douglas Stewart Company"
        },
        {
          "id": 361379,
          "title": "The Orthotic Center"
        },
        {
          "id": 361380,
          "title": "The Stone Bean at Riverside"
        },
        {
          "id": 361381,
          "title": "Columbia Gorge Discovery Center"
        },
        {
          "id": 361382,
          "title": "Church of the Ascension"
        },
        {
          "id": 361383,
          "title": "The Howard Law Firm"
        },
        {
          "id": 361384,
          "title": "The Actors’ Gang"
        },
        {
          "id": 361385,
          "title": "The Daily Holdings"
        },
        {
          "id": 361386,
          "title": "The CLVR Family of Companies"
        },
        {
          "id": 361387,
          "title": "Pancakes on the Rocks"
        },
        {
          "id": 361378,
          "title": "The Douglas Stewart Company"
        },
        {
          "id": 361379,
          "title": "The Orthotic Center"
        },
        {
          "id": 361380,
          "title": "The Stone Bean at Riverside"
        },
        {
          "id": 361381,
          "title": "Columbia Gorge Discovery Center"
        },
        {
          "id": 361382,
          "title": "Church of the Ascension"
        },
        {
          "id": 361383,
          "title": "The Howard Law Firm"
        },
        {
          "id": 361384,
          "title": "The Actors’ Gang"
        },
        {
          "id": 361385,
          "title": "The Daily Holdings"
        },
        {
          "id": 361386,
          "title": "The CLVR Family of Companies"
        },
        {
          "id": 361387,
          "title": "Pancakes on the Rocks"
        },
        {
          "id": 361378,
          "title": "The Douglas Stewart Company"
        },
        {
          "id": 361379,
          "title": "The Orthotic Center"
        },
        {
          "id": 361380,
          "title": "The Stone Bean at Riverside"
        },
        {
          "id": 361381,
          "title": "Columbia Gorge Discovery Center"
        },
        {
          "id": 361382,
          "title": "Church of the Ascension"
        },
        {
          "id": 361383,
          "title": "The Howard Law Firm"
        },
        {
          "id": 361384,
          "title": "The Actors’ Gang"
        },
        {
          "id": 361385,
          "title": "The Daily Holdings"
        },
        {
          "id": 361386,
          "title": "The CLVR Family of Companies"
        },
        {
          "id": 361387,
          "title": "Pancakes on the Rocks"
        },
        {
          "id": 361378,
          "title": "The Douglas Stewart Company"
        },
        {
          "id": 361379,
          "title": "The Orthotic Center"
        },
        {
          "id": 361380,
          "title": "The Stone Bean at Riverside"
        },
        {
          "id": 361381,
          "title": "Columbia Gorge Discovery Center"
        },
        {
          "id": 361382,
          "title": "Church of the Ascension"
        },
        {
          "id": 361383,
          "title": "The Howard Law Firm"
        },
        {
          "id": 361384,
          "title": "The Actors’ Gang"
        },
        {
          "id": 361385,
          "title": "The Daily Holdings"
        },
        {
          "id": 361386,
          "title": "The CLVR Family of Companies"
        },
        {
          "id": 361387,
          "title": "Pancakes on the Rocks"
        },
        {
          "id": 361378,
          "title": "The Douglas Stewart Company"
        },
        {
          "id": 361379,
          "title": "The Orthotic Center"
        },
        {
          "id": 361380,
          "title": "The Stone Bean at Riverside"
        },
        {
          "id": 361381,
          "title": "Columbia Gorge Discovery Center"
        },
        {
          "id": 361382,
          "title": "Church of the Ascension"
        },
        {
          "id": 361383,
          "title": "The Howard Law Firm"
        },
        {
          "id": 361384,
          "title": "The Actors’ Gang"
        },
        {
          "id": 361385,
          "title": "The Daily Holdings"
        },
        {
          "id": 361386,
          "title": "The CLVR Family of Companies"
        },
        {
          "id": 361387,
          "title": "Pancakes on the Rocks"
        },
        {
          "id": 361378,
          "title": "The Douglas Stewart Company"
        },
        {
          "id": 361379,
          "title": "The Orthotic Center"
        },
        {
          "id": 361380,
          "title": "The Stone Bean at Riverside"
        },
        {
          "id": 361381,
          "title": "Columbia Gorge Discovery Center"
        },
        {
          "id": 361382,
          "title": "Church of the Ascension"
        },
        {
          "id": 361383,
          "title": "The Howard Law Firm"
        },
        {
          "id": 361384,
          "title": "The Actors’ Gang"
        },
        {
          "id": 361385,
          "title": "The Daily Holdings"
        },
        {
          "id": 361386,
          "title": "The CLVR Family of Companies"
        },
        {
          "id": 361387,
          "title": "Pancakes on the Rocks"
        }
      ];

      scope.selectedCategoryIndex = 0;
      scope.categorySelect = index => {
        scope.selectedCategoryIndex = index;
      };

      scope.filterItems = () => {
        if (scope.enteredtext.length < 0) return;
        scope.isVisible.suggestions =
          scope.enteredtext.length === 0
            ? false
            : scope.filteredChoices.length > 0;
      };

      scope.selectItem = (choice) => {
        scope.selected = choice;
        scope.enteredtext = choice.title;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbSportTeamComplete/dbSportTeamCompleteView.html",
      link: link,
      scope: {
        enteredtext: "=",
        selected: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        disabled: "=",
        disable: "=",
        board: "=",
      }
    };
  }
]);
