angular.module('app').directive('infographicsForBrand', [
  '$location',
  '$timeout',
  'customInfographicsConstants',
  "httpService",
  "authService",
  "appConfig",
  function ($location, timeout, customInfographicsConstants, httpService, authService, appConfig) {
    function link(scope) {
      scope.currentYear = moment().year();
      scope.filtersForBrand = {
        color: [],
        color1: [],
        color2: [],
        color3: [],
        color4: [],
        industryColor: [],
        industryColor1: [],
        region: [{id: 0, title: 'region'}],
        attribute: [{id: 0, title: 'attribute'}],
        industry: [],
        atoz: []
      };
      scope.$watch('industryData', function (industryData) {
        if(industryData){
          scope.filtersForBrand.industry = scope.industryData;
          scope.filtersForBrand.industry.unshift({
            id: 'all',
            title: "industry",
            all: true,
          });
          scope.setIndustry();
        }
      });

      scope.setIndustry = () => {
        scope.selectedIndustry = scope.filtersForBrand.industry[0];
      }

      scope.$watch('colorGroup', function (colorGroup) {
        if (colorGroup) {
          scope.filtersForBrand.color1 = scope.colorGroup;
          scope.filtersForBrand.color2 = scope.colorGroup;
          scope.filtersForBrand.color3 = scope.colorGroup;
          scope.filtersForBrand.color4 = scope.colorGroup;
          scope.filtersForBrand.color = scope.colorGroup;
          scope.filtersForBrand.color.unshift({
            id: "all",
            title: "COLOR",
            all: true,
          });
          scope.selectedColor = scope.filtersForBrand.color[0];
          if(scope.currentChartForBrand.selected.qNumber === 'BR5d'){
            scope.titleForBrand = 'Key attributes associated with ' + scope.selectedColor.title;
          } else if(scope.currentChartForBrand.selected.qNumber === 'BR5c'){
            scope.titleForBrand = 'Top 10 industries using ' + scope.selectedColor.title + ' in logos';
          } else if(scope.currentChartForBrand.selected.qNumber === 'BR1b'){
            scope.selectedColor1 = scope.filtersForBrand.color1[0];
            scope.selectedColor2 = scope.filtersForBrand.color2[0];
            scope.titleForBrand = 'Top 5 industries using ' + scope.selectedColor1.title + ' and ' +  scope.selectedColor2.title + ' color combination in logos';
            scope.getTopFourColorLogoUseIndustry();
          } else if(scope.currentChartForBrand.selected.qNumber === 'BR4b'){
            scope.titleForBrand = 'Cross industry frequency view of ' + scope.selectedColor.title + ' logos combined with other colors';
            // scope.getIndustryFrequencyData();
          }
        }
      });

      scope.chartsForBrand = [
        {
          qNumber: 'BR4b',
          title:
            '1. Cross industry frequency view of logos with 1, 2 and 3 colors',
          chartTitle:
            'Cross industry frequency view of logos combined with other colors',
          id: 'crossIndustryFrequency',
          filters: {
            colorForBrands: true
          }
        },
        {
          qNumber: 'BR1a',
          title: '2. Top 3 popular logo colors per industry',
          chartTitle: 'Top 3 popular logo colors per industry',
          id: 'top3popularLogoPerIndustry',
          filters: {}
        },
        {
          qNumber: 'BR1b',
          title: '3. Top 5 industries using selected color combination in logos',
          chartTitle: 'Top 5 industries using selected color combination in logos',
          id: 'top5usingRedBlueCombinations',
          filters: {
            colorForBrands1: true,
            colorForBrands2: true
          }
        },
        {
          qNumber: 'BR2b',
          title: '4. Most popular logo colors across industries',
          chartTitle: 'Most popular logo colors across industries',
          id: 'top60Brands',
          filters: {}
        },
        {
          qNumber: 'BR3a',
          title: '5. Key attribute associations for each color family',
          chartTitle: 'Key attribute associations for each color family',
          id: '3mostAssociated',
          filters: {
            atozForBrands: true
          }
        },
        {
          qNumber: 'BR5a',
          title: '6. Color combinations in selected industry logos',
          chartTitle: 'Color combinations in selected industry logos',
          id: 'colorCombinationsInIndustryLogos',
          filters: {
            industryForBrands4: true,
            colorBaseIndustryBrands: true
          }
        },
        {
          qNumber: 'BR5b',
          title: '7. Use of color in logos across selected industries',
          chartTitle: 'Use of color in logos across selected industries',
          id: 'crossIndustryLogoColorFamiliesComparison',
          filters: {
            industryForBrands: true,
            industryForBrands2: true,
            industryForBrands3: true
          }
        },
        {
          qNumber: 'BR5c',
          title: '8. Top 10 industries using this selected color in logos',
          chartTitle: 'Top 10 industries using this selected color in logos',
          id: 'top10IndustriesUsingColorInLogos',
          filters: {
            colorForBrands3: true
          }
        },
        {
          qNumber: 'BR5d',
          title: '9. Key attributes associated with selected color',
          chartTitle: 'Key attributes associated with selected color',
          id: 'topAttributedAssociatedWithColor',
          filters: {
            colorForBrands4: true
          }
        },
        {
          qNumber: 'BR6a',
          title: '10. use of selected color in industry logos',
          chartTitle: 'use of selected color in industry logos',
          id: 'useOfColorInLogosComparedToAllOtherColors',
          filters: {
            industryForBrands5: true,
            colorBaseIndustryBrands1: true
          }
        }
      ];

      scope.currentChartForBrand = {
        selected: scope.chartsForBrand[0]
      };
      
      scope.dataForBrands = customInfographicsConstants.dataForBrands;
      scope.countries = customInfographicsConstants.countries;
      scope.isFilterVisible = customInfographicsConstants.isFilterVisible;
      scope.filtersForBrand.atoz = customInfographicsConstants.atoz;
      scope.currentAtoZLetter = scope.filtersForBrand.atoz[0];
      scope.selectedColor = scope.filtersForBrand.color[0];


      scope.updateColor = function (color) {
        scope.selectedColor = color;
        if(scope.currentChartForBrand.selected.qNumber === 'BR4b'){
          scope.titleForBrand = 'Cross industry frequency view of ' + scope.selectedColor.title + ' logos combined with other colors';
          scope.getIndustryFrequencyData();
        }
      };

      scope.updateIndustry = function (industry) {
        scope.selectedIndustry = industry;
      if(scope.currentChartForBrand.selected.qNumber === 'BR5b') {
          const indu1 = scope.selectedIndustry ? scope.selectedIndustry.title : 'Industry 1';
          const indu2 = scope.selectedIndustry2 ? scope.selectedIndustry2.title : 'Industry 2';
          const indu3 = scope.selectedIndustry3 ? scope.selectedIndustry3.title : 'Industry 3';
          scope.titleForBrand = 'Use of color in logos across in the ' + indu1 + ', ' + indu2 + ', ' + indu3 + ' industries';
          scope.getThreeIndustryData();
        }
      };

      scope.updateIndustry2 = function (industry) {
        scope.selectedIndustry2 = industry;
        const indu1 = scope.selectedIndustry ? scope.selectedIndustry.title : 'Industry 1';
        const indu2 = scope.selectedIndustry2 ? scope.selectedIndustry2.title : 'Industry 2';
        const indu3 = scope.selectedIndustry3 ? scope.selectedIndustry3.title : 'Industry 3';
        scope.titleForBrand = 'Use of color in logos across in the ' + indu1 + ', ' + indu2 + ', ' + indu3 + ' industries';
        scope.getThreeIndustryData();
      };

      scope.updateIndustry3 = function (industry) {
        scope.selectedIndustry3 = industry;
        const indu1 = scope.selectedIndustry ? scope.selectedIndustry.title : 'Industry 1';
        const indu2 = scope.selectedIndustry2 ? scope.selectedIndustry2.title : 'Industry 2';
        const indu3 = scope.selectedIndustry3 ? scope.selectedIndustry3.title : 'Industry 3';
        scope.titleForBrand = 'Use of color in logos across in the ' + indu1 + ', ' + indu2 + ', ' + indu3 + ' industries';
        scope.getThreeIndustryData();
      };

      scope.updateIndustry4 = function (industry) {
        scope.selectedIndustry4 = industry;
        if(scope.currentChartForBrand.selected.qNumber === 'BR5a'){
          scope.titleForBrand = 'Color combinations for logos with Color in the ' + scope.selectedIndustry4.title + ' industry ';
          scope.getColorBaseIndustryData(scope.selectedIndustry4);
        }
      };

      scope.updateIndustry5 = function (industry) {
        scope.selectedIndustry5 = industry;
        if(scope.currentChartForBrand.selected.qNumber === 'BR6a'){
          scope.titleForBrand = 'use of color in the ' + scope.selectedIndustry5.title + ' industry logos ';
          scope.selectedIndustryBaseColor1 = '';
          scope.getColorBaseIndustryData(scope.selectedIndustry5);
        }
      };

      scope.updateColor1 = function (color) {
        scope.selectedColor1 = color;
        if(scope.currentChartForBrand.selected.qNumber === 'BR1b'){
          scope.titleForBrand = 'Top 5 industries using ' + scope.selectedColor1.title + ' and ' +  scope.selectedColor2.title + ' color combination in logos';
          scope.getTopFourColorLogoUseIndustry();
        }
      };

      scope.updateColor2 = function (color) {
        scope.selectedColor2 = color;
        if(scope.currentChartForBrand.selected.qNumber === 'BR1b'){
          scope.titleForBrand = 'Top 5 industries using ' + scope.selectedColor1.title + ' and ' +  scope.selectedColor2.title + ' color combination in logos';
          scope.getTopFourColorLogoUseIndustry();
        } 
      };

      scope.updateColor3 = function (color) {
        scope.selectedColor3 = color;
        if(scope.currentChartForBrand.selected.qNumber === 'BR5c'){
          scope.titleForBrand = 'Top 10 industries using ' + scope.selectedColor3.title + ' in logos';
          scope.getTopTenIndustryByColor(scope.selectedColor3);
        }
      };

      scope.updateColor4 = function (color) {
        scope.selectedColor4 = color;
        if(scope.currentChartForBrand.selected.qNumber === 'BR5d'){
          scope.titleForBrand = 'Key attributes associated with ' + scope.selectedColor4.title;
          scope.getAttributeByColor(scope.selectedColor4);
        }
      };

      scope.updateIndustryBaseColor = function (color) {
        scope.selectedIndustryBaseColor = color; 
        if(scope.currentChartForBrand.selected.qNumber === 'BR5a'){
          scope.titleForBrand = 'Color combinations for logos with ' + scope.selectedIndustryBaseColor.name + ' in the ' + scope.selectedIndustry4.title + ' industry ';
          this.getColorCombinationLogoData()
        }
      };

      scope.updateIndustryBaseColor1 = function (color) {
        scope.selectedIndustryBaseColor1 = color;
        if(scope.currentChartForBrand.selected.qNumber === 'BR6a'){
          scope.titleForBrand = 'use of ' + scope.selectedIndustryBaseColor1.name + ' in the ' + scope.selectedIndustry5.title + ' industry logos ';
          scope.getData();
        }
      };


      scope.updateAtoZLetter = function (letter) {
        scope.currentAtoZLetter = letter;
        if(scope.currentChartForBrand.selected.qNumber === 'BR3a'){
          scope.titleForBrand = 'Three key color associations for attributes starting with ' + scope.currentAtoZLetter.title + ' across color families';
          this.getThreeKeyColorData()
        }
      };
      scope.initStatesByQueryParamsForBrand = function () {
        timeout(() => {
          scope.selectedMenuId = 2;
          scope.currentChartForBrand.selected =
            scope.chartsForBrand.find(i => i.id === scope.currentChartForBrand.selected.id) ||
            scope.chartsForBrand[0];
            if(scope.industryData) {
              scope.selectedIndustry = scope.filtersForBrand.industry[0];
              scope.setIndustry();
            }
            if(scope.colorGroup){
              scope.selectedColor = scope.filtersForBrand.color[0];
              scope.selectedColor3 = scope.filtersForBrand.color3[0];
              scope.selectedColor4 = scope.filtersForBrand.color4[0];
            }
            if(scope.currentChartForBrand.selected.qNumber === 'BR5d'){
              scope.topAttributedAssociatedData = [];
              scope.titleForBrand = 'Key attributes associated with Color';
            } else if(scope.currentChartForBrand.selected.qNumber === 'BR3a'){
              scope.titleForBrand = 'Three key color associations for attributes starting with ' + scope.currentAtoZLetter.title + ' across color families';
            } else  if(scope.currentChartForBrand.selected.qNumber === 'BR1b'){
              scope.selectedColor1 = scope.filtersForBrand.color1[0];
              scope.selectedColor2 = scope.filtersForBrand.color2[0];
              scope.titleForBrand = 'Top 5 industries using color and color color combination in logos';
              scope.getTopFourColorLogoUseIndustry();
            } else if(scope.currentChartForBrand.selected.qNumber === 'BR2b'){
              scope.getTopFourColorUseIndustry();
              scope.titleForBrand = scope.currentChartForBrand.selected.chartTitle;
            } else if(scope.currentChartForBrand.selected.qNumber === 'BR1a'){
              scope.getTopThreeColorUseIndustry();
              scope.titleForBrand = scope.currentChartForBrand.selected.chartTitle;
            } else {
              scope.top10IndustriesUsingColorInLogos = [];
              scope.titleForBrand = scope.currentChartForBrand.selected.chartTitle;
            }
        }, 0);
      };

      scope.initStatesByQueryParamsForBrand();

      scope.updateQueryBySelectorsForBrand = function () {
        // $location.search({
        //   tab: 2,
        //   board: scope.selectedMenuId,
        //   chart: scope.currentChartForBrand.selected.id
        // });
        scope.initStatesByQueryParamsForBrand();
      };

      scope.refresh = () => {
        scope.updateQueryBySelectorsForBrand();
      };

      scope.getData = () => {
        httpService.get(`${appConfig.brandingServiceUrlApi}companies/logo_colors?q[industry_id_eq]=${scope.selectedIndustry5.id}&q[color_id_eq]=${scope.selectedIndustryBaseColor1.id}`,
          authService.getHeaderConfig()
        ).then(function (res) {
          scope.useSelectedColorInIndustryLogo = res.data;
        });
      }

      scope.getColorBaseIndustryData = (industry) => {
        httpService.get(`${appConfig.brandingServiceUrlApi}company_colors?q[industry_id_eq]=${industry.id}`,
          authService.getHeaderConfig()
        ).then(function (res) {
          scope.filtersForBrand.industryColor = res.data;
          scope.filtersForBrand.industryColor1 = res.data;
        });
      }

      scope.getAttributeByColor = (color) => {
        httpService.get(`${appConfig.brandingServiceUrlApi}properties/key_attributes?q[color_id_eq]=${color.id}&page=1`,
          authService.getHeaderConfigWithAccept()
        ).then(function (res) {
          scope.topAttributedAssociatedData = res.data.properties.slice(0, 10);
        });
      }

      scope.getTopTenIndustryByColor = (color) => {
        httpService.get(`${appConfig.brandingServiceUrlApi}industries/top_ten?q[color_id_eq]=${color.id}`,
          authService.getHeaderConfigWithAccept()
        ).then(function (res) {
          scope.top10IndustriesUsingColorInLogos = res.data;
        });
      }

      scope.getTopFourColorUseIndustry = () => {
        httpService.get(`${appConfig.brandingServiceUrlApi}industries/top_four`,
          authService.getHeaderConfigWithAccept()
        ).then(function (res) {
          scope.dataForBrandsIndustry = res.data;
        });
      }

      scope.getTopThreeColorUseIndustry = () => {
        httpService.get(`${appConfig.brandingServiceUrlApi}industries/top_three`,
          authService.getHeaderConfigWithAccept()
        ).then(function (res) {
          scope.dataForBrandsThreeColorIndustry = res.data;
        });
      }

      scope.getColorCombinationLogoData = () => {
        httpService.get(`${appConfig.brandingServiceUrlApi}industries/paired_colors?q[industry_id_eq]=${scope.selectedIndustry4.id}&q[color_id_eq]=${scope.selectedIndustryBaseColor.id}`,
          authService.getHeaderConfig()
        ).then(function (res) {
          scope.colorCombinationDataForBrand = res.data;
        });
      }

      scope.getThreeIndustryData = () => {
        if (scope.selectedIndustry !== 'all' && scope.selectedIndustry2 && scope.selectedIndustry3)
          httpService.get(`${appConfig.brandingServiceUrlApi}industries/across_logo_colors?q[industry_ids][]=${scope.selectedIndustry.id}&q[industry_ids][]=${scope.selectedIndustry2.id}&q[industry_ids][]=${scope.selectedIndustry3.id}`,
            authService.getHeaderConfig()
          ).then(function (res) {
            scope.crossIndustryLogoData = res.data;
          });
      }

      scope.getIndustryFrequencyData = () => {
        httpService.get(`${appConfig.brandingServiceUrlApi}industries/connected_logos.json?q[color_id_eq]=${scope.selectedColor.id}`,
          authService.getHeaderConfig()
        ).then(function (res) {
          scope.dataForBrandsIndustryFrequencyData = res.data;
        });
      }

      scope.getTopFourColorLogoUseIndustry = () => {
        if(scope.selectedColor1 && scope.selectedColor2) {
          httpService.get(`${appConfig.brandingServiceUrlApi}industries/top_five.json?q[color_id_in][]=${scope.selectedColor1.id}&q[color_id_in][]=${scope.selectedColor2.id}`,
            authService.getHeaderConfigWithAccept()
          ).then(function (res) {
            if (res && res.data) {
              scope.dataForBrandsTopFiveIndustry = res.data;  
            }
          });
        }
      }

      scope.getThreeKeyColorData = () => {
        httpService.get(`${appConfig.brandingServiceUrlApi}company_colors/associated_color_attributes?q[title_eq]=${scope.currentAtoZLetter.id}`,
          authService.getHeaderConfigWithAccept()
        ).then(function (res) {
          if (res && res.data) {
            scope.dataForBrandsThreeKeyColor = res.data;  
          }
        });
      }

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
          result += name.toUpperCase() + ".";

        return result + date + type;
      };

      scope.exportJpgThroughDomToImage = (name) => {
        let node = angular.element("#brandCapture")[0];
        
        const titles = angular.element(".graphic-titles");
        const header = angular.element(".graphic-titles-export");
        const footer = angular.element(".customized-infographics-footer");

        footer.css({ display: "block" });
        header.css({ display: "block" });
        titles.css({ display: "none" });
        $("html").addClass("hide-scrollbar");

        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            const img = new Image();
            img.src = dataUrl;

            const link = document.createElement("a");
            link.href = img.src;
            link.download = scope.generateImgName(".png", "brand", name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            $("html").removeClass("hide-scrollbar");
            footer.css({ display: "none" });
            header.css({ display: "none" });
            titles.css({ display: "block" });
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      };

    }

    return {
      restrict: 'E',
      templateUrl:
        'app/components/customized-infographics/infographicsForBrand/infographicsForBrand.html',
      link: link,
      scope: {
        colorGroup: "=",
        industryData: "="
      }
    };
  }
]);
