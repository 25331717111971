(function () {
  angular.module("app").directive("colorOwnerChartForProduct", [
    function () {
      function link(scope, element) {
        chartBase(self);

        scope.yAxis = [
          { title: 0, label: '0' },
          { title: 4, label: '1' },
          { title: 8, label: '2' },
          { title: 12, label: '3' },
          { title: 16, label: '4' },
          { title: 20, label: '5' },
          { title: 24, label: '6' },
          { title: 28, label: '7' },
          { title: 32, label: '8' },
          { title: 36, label: '9' },
          { title: 40, label: '10' },
        ];

        scope.bindData = () => {
          const container = element.find("[chart-type]").html("");
          const width = $(container[0]).width();
          const dx = (width - 70) / scope.data.length;
          const barWidth = 30;
          const svg = d3
            .select(container[0])
            .append("svg")
            .attr("width", "100%")
            .attr("height", "580");

          const g = svg
            .append("g")
            .attr("transform", "translate(" + -10 + "," + 580 + ")");

          if(scope.yAxis.length > 0) {
            for (let j = 0; j < scope.yAxis.length; j++) {
  
              g.append("line")
                .attr("x1", 60)
                .attr("x2", width)
                .attr("y1", -100 - scope.yAxis[j].title * 10)
                .attr("y2", -100 - scope.yAxis[j].title * 10)
                .style({ stroke: "#ccc", "stroke-width": 1 });
  
              g.append("text")
                .attr("text-anchor", "middle")
                .attr("dominant-baseline", "central")
                .attr("y", -100 - scope.yAxis[j].title * 10)
                .attr("x", 50)
                .text(scope.yAxis[j].label);
            }
          }

          for (let i = 0; i < scope.data.length; i++) {
            const lastItem = i === scope.data.length - 1;

            g.append("text")
              .attr("text-anchor", "middle")
              .attr("dominant-baseline", "central")
              .attr("x", 70)
              .attr("y", -80)
              .attr("transform", self.formatTranslate(i * dx + dx / 2, i % 2 === 0 ? 0 : 20))
              .text(scope.data[i].brand)
              .attr("style", "font-family: Gotham SSm A, Gotham SSm B;font-size: 14px; text-transform: lowercase");

            for (let j = 0; j < scope.data[i].colors.length; j++) {
              let offsetY = 0;
              for (let k = 0; k <= j; k++) {
                offsetY += scope.data[i].colors[k].percentageCount;
              }

              g.append("rect")
                .style("fill", scope.data[i].colors[j].hex)
                .attr("stroke", scope.data[i].colors[j].hex === '#ffffff' ? '#dbddde' : scope.data[i].colors[j].hex)
                .attr("x", 70)
                .attr("y", -100)
                .attr("width", barWidth)
                .attr("transform", self.formatTranslate(i * dx + (dx - barWidth) / 2, -offsetY))
                .attr("height", scope.data[i].colors[j].percentageCount);
            }

            g.append("text")
              .attr("text-anchor", "middle")
              .attr("dominant-baseline", "central")
              .attr("y", -100 - scope.data[i].modifyPercentage - 40)
              .attr("x", 70)
              .attr("transform", self.formatTranslate(i * dx + dx / 2, 0))
              .text(scope.data[i].percentage)
              .attr("style", "font-family: Gotham SSm A, Gotham SSm B");

            g.append("text")
              .attr("text-anchor", "middle")
              .attr("dominant-baseline", "central")
              .attr("y", -100 - scope.data[i].modifyPercentage - 60)
              .attr("x", 70)
              .attr("transform", self.formatTranslate(i * dx + dx / 2, 0))
              .text('TOTAL')
              .attr("style", "font-family: Gotham SSm A, Gotham SSm B");

            if (!lastItem) {
              g.append("line")
                .attr("x1", i * dx + dx / 2 + 70)
                .attr("x2", (i + 1) * dx + dx / 2 + 70)
                .attr("y1", -100 - scope.data[i].modifyPercentage - 20)
                .attr("y2", -100 - scope.data[i + 1].modifyPercentage - 20)
                .style({ stroke: "#666666", "stroke-width": 1 });
            }

            g.append("circle")
              .attr("transform", self.formatTranslate(i * dx + dx / 2, 0))
              .attr("cx", 70)
              .attr("cy", -100 - scope.data[i].modifyPercentage - 20)
              .attr("r", "4px")
              .style("fill", "#000000");
          }
        }

        scope.$watch('data', function (data) {
          if(data && data.length > 0){
            scope.data = data;
            scope.bindData();
          }
        });
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          data: "=",
          maxPoint: "=",
        },
        templateUrl:
          "app/directives/colorOwnerChartForProduct/colorOwnerChartForProductView.html",
      };

      return directive;
    },
  ]);
})();
