angular.module("app").component("setPasswordComponent", {
  templateUrl: "app/components/password-set/password-set.tmpl.html",
  controller: function ($state, $http, appConfig, $stateParams) {
    var self = this;
    this.successRequest = false;
    this.name = "";
    this.password = "";
    this.passwordConfirm = "";
    this.error = false;
    this.token = $stateParams.token;

    this.onSendLoginClick = function () {
      self.error = false;
      if (self.successRequest) {
        $state.go("login");
      }

      if (!self.name || !self.password || !self.passwordConfirm) {
        self.error = "Username, Password and Confirm Password fields are required";
      } else {
        if (self.password === self.passwordConfirm) {
          const params = {
            user: {
              name: self.name,
              password: self.password,
              password_confirmation: self.passwordConfirm
            }
          };
          $http
            .put(appConfig.dashboardServiceUrl + `api/users/${self.token}/set_password`, params)
            .then(function (res) {
              if (res.data) {
                if (res.data.success) {
                  self.successRequest = true;
                } else {
                  self.error = res.data.message;
                }
              }
            })
            .catch(function (err) {
              if (err) {
                self.error = err.data.errors;
              }
            });
        } else {
          self.error = "Passwords are not identical";
        }
      }
    };
  },
});
