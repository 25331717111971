angular.module('app').directive('fashiondashboardpopup', function () {
  function link (scope) {
    scope.closeClickHandler = function () {
      scope.toggleFunction(false);
    };

    scope.formsColOptionStyles = {
      topWrapper: {
        width: '100px',
      },
      name: {
        'font-size': '12px'
      }
    }

    scope.newmoodboard = () => {
      const data = {
        type: 'fashion'
      }  
      scope.$emit("mood_state_chart", data);
    };

    scope.shareFacebook = (url) => {
      window.open("https://www.facebook.com/sharer/sharer.php?u=" + url);
    };

    scope.shareTwitter = (url) => {
      window.open("https://twitter.com/home?status=" + url);
    };

    scope.sharePinterest = (url) => {
      window.open("https://pinterest.com/pin/create/button/?url=" + url);
    };

    scope.shareInstagram = (url) => {
      window.open("https://instagram.com/?url=" + url);
    };

  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/fashionDashboardPopup/fashionDashboardPopup.html',
    link: link,
    scope: {
      typeOfPopup:'=',
      toggleFunction:'=',
      selectedItem: '=',
      shadesOfColor: '=',
      colorDataAcrossCategories: '=',
      colorDataAcrossSeasons: '=',
      comparativeViewAcrossFashions: '=',
      colorFashionComparedChartData: '=',
      cityTopfourColorsAcrossSeasons: '=',
      frequentColorDataAcrossCategories: '=',
      frequentColorsAcrossRegionsForSeason: '=',
      topFourColorsForSeasonCategories: '=',
      frequentColorsAcrossRegionsForSeason: '=',
      cityOverallTopFourColorsData: '=',
      cityColorFrequencyData: '=',
      regionColorFrequencyData: '=',
      region: '=',
      designerUseColorData: '=',
      designerTopColors: '=',
      designerColorCitiesData: '=',
      designerColorSeasonsData: '=',
      colorFashionComparedData: '=',
      colorSelectedCompareData: '=',
      yearColorFrequencyData: '=',
      yearOverallColorDataAcrossSeasons: '=',
      frequentColorDataAcrossCategories: '=',
      fiveYearColorDataAcrossDesigners: '=',
      fiveYearColorDataAcrossDesignersData: '=',
      designerColorFrequencyData: '=',
      colorCombinationsAcrossDesignersInYear: '=',
      chooseColor: '=',
      colorSelectedCompareCategoryData: '=',
      modalTitle:'='
    }
  };
});
