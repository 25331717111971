angular.module('app').directive('colorfrequencysubline', ['$window', function ($window) {
  function link (scope) {

    scope.getMostWidth = function (index) {
      let totalPercentage = 0;
      scope.colors.forEach(item => {
        if(typeof(item.percentage) == "string") {
          totalPercentage += parseFloat(item.percentage);
        } else {
          totalPercentage += item.percentage;
        }
      });
      let indexPercentage = scope.colors[index].percentage;
        return parseFloat((100 / totalPercentage) * indexPercentage) + '%';
    }
  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/colorFrequencyLine/colorFrequencySubline/colorFrequencySubline.html',
    link: link,
    scope: {
      colors:'=',
      showTopBoundary:'=',
      showLeftBoundary:'=',
      showRightBoundary:'=',
      topLabelIndent:'=',
      topLabelText:'=',
      bottomLabelText:'=',
      type:'=' // most | moderate | least | not
    }
  };
}]);
