angular.module('app').directive('infographicsForLegal', [
  '$location',
  '$timeout',
  'customInfographicsConstants',
  function (
    $location,
    timeout,
    customInfographicsConstants
  ) {
    function link(scope) {
      scope.currentYear = moment().year();
      scope.meta = {};
      scope.isFilterVisible = customInfographicsConstants.isFilterVisible;

      scope.colorTrademarkDataAcrossProduct =
          customInfographicsConstants.colorTrademarkDataAcrossProduct;
      scope.colorTrademarkDataOverFiveYears =
          customInfographicsConstants.colorTrademarkDataOverFiveYears;

      scope.colorsForLegal = [
        { id: "all", title: "COLOR"},
        {id: 0, title: 'red', hex: '#f70000'},
        {id: 1, title: 'orange', hex: '#f77a00'},
        {id: 2, title: 'yellow', hex: '#ebd01c'},
        {id: 3, title: 'green', hex: '#037b03'},
        {id: 4, title: 'turquoise', hex: '#114394'},
        {id: 5, title: 'blue', hex: '#e091b5'},
        {id: 6, title: 'purple', hex: '#5f3e19'}
      ];
      scope.chartsForLegal = [
        {
          qNumber: 'LEGAL1',
          title: '(COLOR) TRADEMARKS ACROSS ALL PRODUCT AND SERVICE CATEGORIES',
          id: 'colorTrademarksAcrossProductAndService',
          filters: {
            colorForLegal: true
          }
        },
        {
          qNumber: 'LEGAL2',
          title: 'TOP 10 BRAND OWNERS OF COLOR TRADEMARK FOR (PRODUCT NAME)',
          id: 'top10BrandOwnersOfColorTrademark',
          filters: {
            productForLegal: true
          }
        },
        {
          qNumber: 'LEGAL3',
          title: '(COLOR) TRADEMARKS GRANTED OVER FIVE YEARS',
          id: 'colorTrademarksGrantedOverFiveYears',
          filters: {
            colorForLegal: true
          }
        },
        {
          qNumber: 'LEGAL4',
          title: 'TRADEMARKS GRANTED TO (CATEGORY) BY COLOR FAMILY',
          id: 'trademarksGrantedToCategory',
          filters: {
            productForLegal: true
          }
        }
      ];
      scope.productsForLegal = [
        {
          id: 0,
          title: 'paints',
          img_url: '/assets/img/legals/products/paints.png'
        },
        {
          id: 1,
          title: 'machinery',
          img_url: '/assets/img/legals/products/machinery.png'
        },
        {
          id: 2,
          title: 'hand tools',
          img_url: '/assets/img/legals/products/hand-tools.png'
        }
      ];
      scope.currentChartForLegal = scope.chartsForLegal[0];
      scope.currentColorForLegal = scope.colorsForLegal[0];
      scope.currentProductForLegal = scope.productsForLegal[0];

      scope.updateColor = function (color) {
        scope.currentColorForLegal = color;
      };
      scope.updateChart = function (chart) {
        scope.currentChartForLegal = chart;
      };

      scope.refresh = () => {
        scope.updateQueryBySelectorsForLegal();
      };

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
          result += name.toUpperCase() + ".";

        return result + date + type;
      };

      scope.exportJpgThroughDomToImage = (name) => {
        let node = angular.element("#legalCapture")[0];
        
        const titles = angular.element(".graphic-titles");
        const header = angular.element(".graphic-titles-export");
        const footer = angular.element(".customized-infographics-footer");

        footer.css({ display: "block" });
        header.css({ display: "block" });
        titles.css({ display: "none" });
        $("html").addClass("hide-scrollbar");

        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            const img = new Image();
            img.src = dataUrl;

            const link = document.createElement("a");
            link.href = img.src;
            link.download = scope.generateImgName(".png", "brand", name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            $("html").removeClass("hide-scrollbar");
            footer.css({ display: "none" });
            header.css({ display: "none" });
            titles.css({ display: "block" });
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      };
    }

    return {
      restrict: 'E',
      templateUrl:
        'app/components/customized-infographics/infographicsForLegal/infographicsForLegal.html',
      link: link,
      scope: {
      }
    };
  }
]);
