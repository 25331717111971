angular.module("app").directive("cardForPlanPopup", (paymentService) => {
  let link = (scope) => {
    paymentService.getSubscription().then((data) => {
      // Your Stripe public key
      scope.stripe = Stripe(data.publishable_key);

      // Create `card` element that will watch for updates
      // and display error messages
      const elements = scope.stripe.elements();
      scope.card = elements.create('card', 
      { hidePostalCode: true, 
        style: {
          base: {
            color: '#000',
            fontWeight: '500',
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
          },
          invalid: {
            color: '#FF0000',
          },
        },
      });
      scope.card.mount('#card-element');
      scope.card.addEventListener('change', event => {
        const displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
    });

    scope.closeClickHandler = () => {
      scope.closefunction();
    };

    scope.submit = function () {
      scope.stripe.createToken(scope.card).then(result => {
        if (result.error) {
        const errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
        } else {
        scope.stripe_token = result.token.id;
        scope.getUpgratePlanData(scope.stripe_token);
        }
      });
    };

    
  };
  return {
    restrict: "E",
    templateUrl: "app/directives/cardForPlan/cardForPlanPopupView.html",
    link: link,
    scope: {
      onClose: "&",
      closefunction: "=",
      getUpgratePlanData: "=",
    }
  };
});
