angular.module("app").service("autoDashboardRepository", [
  "appConfig",
  "$q",
  "authService",
  "httpService",
  function (appConfig, $q, authService, httpService) {
    const callAutoDashboardService = function (name, module, id, params) {
      return httpService
        .get(appConfig.autoServiceUrl + name + "/" + module + "/" + id, {
          params: params,
        })
        .then(function (data) {
          return data && data.data ? data.data : data;
        });
    };

    this.getDataForMosaic = (year) => {
      return httpService
        .get(
          appConfig.webServiceUrl +
            "charts/uniqueColors/all/all/all/" +
            year +
            "/all/all"
        )
        .then(function (data) {
          return data && data.data ? data.data.result : data;
        });
    };

    this.getActualColorForYear = (year) => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/auto/settings.json", authService.getHeaderConfig())
        .then((data) => {
          const url = (id) =>
            `https://huedata-auto.herokuapp.com/api/brand/palette/${id}`;

          const requests = data.data.makes.map((item) =>
            httpService.get(url(item.id)).then((res) => ({
              id: item.id,
              title: item.title,
              data: res.data,
              logo: item.logo_url,
              year: year,
            }))
          );
          return $q.all(requests);
        });
    };

    this.getActualColorForYearData = (year) => {
      return httpService
        .get(appConfig.dashboardServiceUrl + `api/auto/makes/list?year=${year}`, authService.getHeaderConfig())
        .then((data) => {
          return data.data;
        });
    }

    this.getColorsForFilter = () => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/auto/settings.json", authService.getHeaderConfig())
        .then((data) => {
          return data;
        });
    };

    this.getAutoYears = () => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/auto/settings.json", authService.getHeaderConfig())
        .then((data) => {
          return data;
        });
    };

    this.getTwoYearIndustryUseOfColor = (year1, year2) => {
      return httpService
        .get(appConfig.dashboardServiceUrl + `api/auto/color_models/two_years.json?years[]=${year1}&years[]=${year2}`, authService.getHeaderConfig())
        .then((data) => {
          return data;
        });
    };

    this.getIndustryUseOfColor = (year) => {
      let query = `?q[year_eq]=${year}`;
      return httpService
        .get(appConfig.dashboardServiceUrl + `api/auto/color_models/last_five_years.json${query}`, authService.getHeaderConfig())
        .then((data) => {
          return data;
        });
    };

    this.getColorsBrand = (color, year) => {
      let query = `?q[color_id_eq]=${color}`;
      if (year) {
        query += `&q[year_eq]=${year}`;
      }
      
      return httpService
        .get(appConfig.dashboardServiceUrl + `api/auto/makes.json${query}`, authService.getHeaderConfig())
        .then((data) => {
          return data;
        });
    };

    this.getColorsType = (color, year) => {
      let query = `?q[color_id_eq]=${color}`;
      if (year) {
        query += `&q[year_eq]=${year}`;
      }
      return httpService
        .get(appConfig.dashboardServiceUrl + `api/auto/models.json${query}`, authService.getHeaderConfig())
        .then((data) => {
          return data;
        });
    }; 

    this.getTopFourColor = (year, id, field) => {
      let query = `?q[year_eq]=${year}`;
      if (field === 'model') {
        query += `&q[model_id_eq]=${id}`;
      }
      if (field === 'brand') {
        query += `&q[make_id_eq]=${id}`;
      }
      return httpService
      .get(appConfig.dashboardServiceUrl + `api/auto/color_models/top_4_colors.json${query}`, authService.getHeaderConfig())
      .then((data) => {
        return data;
      });
    }

    this.getColorGroupsForMosaic = (year) => {
      return httpService
        .get(
          appConfig.webServiceUrl +
            "charts/colorGroups/all/all/all/" +
            year +
            "/all/all"
        )
        .then(function (data) {
          return data && data.data ? data.data.result : data;
        });
    };

    this.getFrequency = (year, id, field) => {
      let query = `?year=${year}`;
      if (field === 'model') {
        query += `&model_id=${id}`;
      }
      if (field === 'brand') {
        query += `&make_id=${id}`;
      }
      return httpService
        .get(appConfig.dashboardServiceUrl + `api/auto/color_models/basic_colors${query}`, authService.getHeaderConfig())
        .then((data) => {
          return data;
      });
    }

    this.getTopFinishesColor = (field, year, id) => {
      let query = `?q[year_eq]=${year}`;
      if (field === 'model') {
        query += `&q[model_id_eq]=${id}`;
      }
      if (field === 'brand') {
        query += `&q[make_id_eq]=${id}`;
      }
      if (field === 'color') {
        query += `&q[color_id_eq]=${id}`
      }
      return httpService
        .get(appConfig.dashboardServiceUrl + `api/auto/color_models/top_3_finishes.json${query}`, authService.getHeaderConfig())
        .then((data) => {
          return data;
      });
    }; 

    this.getBasicDetails = (field, year, id) => {
      let query = `?q[year_eq]=${year}`;
      if (field === 'model') {
        query += `&q[model_id_eq]=${id}`;
        query += `&modules=model`;
      }
      if (field === 'brand') {
        query += `&q[make_id_eq]=${id}`;
        query += `&modules=make`;
      }
      if (field === 'color') {
        query += `&q[color_id_eq]=${id}`;
        query += `&modules=color`;
      }
      if( field === 'year') {
        query += '&modules=year';
      }
      return httpService
        .get(appConfig.dashboardServiceUrl + `api/auto/color_models/basic_details${query}`, authService.getHeaderConfig())
        .then((data) => {
          return data;
      });
    }

    this.getPastYearColor = (field, year1, year2, id) => {
      switch (field) {
        case "color":
          field = "color_id_eq";
          break;
        case "model":
          field = "model_id_eq";
          break;
        case "brand":
          field = "make_id_eq";
      }
      return httpService
        .get(appConfig.dashboardServiceUrl + `api/auto/color_models/selected_years.json?q[year_gt]=${year2}&q[year_lteq]=${year1}&q[${field}]=${id}`, authService.getHeaderConfig())
        .then((data) => {
          return data;
      });
    }; 

    this.getLastFiveYearColor = (year, id) => {
      return httpService
        .get(appConfig.dashboardServiceUrl + `api/auto/color_models/last_five_years_brand_colors.json?q[year]=${year}&q[make]=${id}`, authService.getHeaderConfig())
        .then((data) => {
          return data;
      });
    }; 

    this.getCarColors = (field, id, page, year, order) => {
      switch (field) {
        case "color":
          field = "color_id";
          break;
        case "model":
          field = "model_id";
          break;
        case "brand":
          field = "make_id";
      }
      if (field !== 'year') {
        return httpService
          .get(
            appConfig.dashboardServiceUrl +
            `api/auto/color_models.json?q[${field}_eq]=${id}&page=${page}&per_page=40&q[year_eq]=${year}&order=${order}`,
            authService.getHeaderConfig()
          )
          .then((res) => {
            return res.data;
          });
      } else {
        return httpService
          .get(
            appConfig.dashboardServiceUrl +
            `api/auto/color_models.json?q[${field}_eq]=${year}&page=${page}&per_page=40&order=${order}`,
            authService.getHeaderConfig()
          )
          .then((res) => {
            return res.data;
          });
      }
    };

    this.getColorDataForBrand = (id) => {
      return httpService
        .get(
          appConfig.dashboardServiceUrl + `api/auto/makes/${id}.json`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          return res.data;
        });
    };

    this.brand = {
      dashboardName: "brand",
      getPageData: function (id) {
        return callAutoDashboardService(this.dashboardName, "basic_data", id);
      },
      getColorFrequency: function (id) {
        return callAutoDashboardService(this.dashboardName, "color_freq", id);
      },
      getTopColors: function (id) {
        return callAutoDashboardService(this.dashboardName, "top_colors", id);
      },
      getColorPalette: function (id, bucket) {
        return callAutoDashboardService(this.dashboardName, "palette", id, {
          bucket: bucket,
        });
      },
      getTopFinishes: function (id) {
        return callAutoDashboardService(this.dashboardName, "top_finishes", id);
      },
      getColorsCount: function (id) {
        return callAutoDashboardService(this.dashboardName, "colors_count", id);
      },
      getShades: function (id) {
        return callAutoDashboardService(this.dashboardName, "shades", id);
      },
    };

    this.model = {
      dashboardName: "model",
      getPageData: function (id) {
        return callAutoDashboardService(this.dashboardName, "basic_data", id);
      },
      getColorFrequency: function (id) {
        return callAutoDashboardService(this.dashboardName, "color_freq", id);
      },
      getTopColors: function (id) {
        return callAutoDashboardService(this.dashboardName, "top_colors", id);
      },
      getColorPalette: function (id, bucket) {
        return callAutoDashboardService(this.dashboardName, "palette", id, {
          bucket: bucket,
        });
      },
      getTopFinishes: function (id) {
        return callAutoDashboardService(this.dashboardName, "top_finishes", id);
      },
      getCompare: function (id) {
        return callAutoDashboardService(this.dashboardName, "compare", id);
      },
      getShades: function (id) {
        return callAutoDashboardService(this.dashboardName, "shades", id);
      },
    };

    this.year = {
      dashboardName: "year",
      getPageData: function (id) {
        return callAutoDashboardService(this.dashboardName, "basic_data", id);
      },
      getColorFrequency: function (id) {
        return callAutoDashboardService(this.dashboardName, "color_freq", id);
      },
      getTopColors: function (id) {
        return callAutoDashboardService(this.dashboardName, "top_colors", id);
      },
      getColorPalette: function (id, bucket) {
        return callAutoDashboardService(this.dashboardName, "palette", id, {
          bucket: bucket,
        });
      },
      getTopFinishes: function (id) {
        return callAutoDashboardService(this.dashboardName, "top_finishes", id);
      },
      getTopFamilies: function (id) {
        return callAutoDashboardService(this.dashboardName, "top_families", id);
      },
      getCompare: function (id) {
        return callAutoDashboardService(this.dashboardName, "compare", id);
      },
    };

    this.color = {
      dashboardName: "color",
      getPageData: function (id) {
        return callAutoDashboardService(this.dashboardName, "basic_data", id);
      },
      getColorFrequency: function (id) {
        return callAutoDashboardService(this.dashboardName, "color_freq", id);
      },
      getTopColors: function (id) {
        return callAutoDashboardService(this.dashboardName, "top_colors", id);
      },
      getColorPalette: function (id, bucket) {
        return callAutoDashboardService(this.dashboardName, "palette", id, {
          bucket: bucket,
        });
      },
      getTopFinishes: function (id) {
        return callAutoDashboardService(this.dashboardName, "top_finishes", id);
      },
      getTopBrands: function (id) {
        return callAutoDashboardService(this.dashboardName, "top_brands", id);
      },
      getCompare: function (id) {
        return callAutoDashboardService(this.dashboardName, "compare", id);
      },
      getShades: function (id) {
        return callAutoDashboardService(this.dashboardName, "shades", id);
      },
    };
  },
]);
