angular.module("app").service("trackDashboardRepository", [
  "appConfig",
  "authService",
  "httpService",
  function (appConfig, authService, httpService) {
    this.getTrackerData = (sentiments, sources, period, from, to) => {
      let query = '';

      if (sentiments) {
        if (query) query += `&sentiments=${sentiments}`;
        else query += `?sentiments=${sentiments}`
      }
      if (sources) {
        if (query) query += `&sources=${sources}`;
        else query += `?sources=${sources}`
      }
      if (period) {
        if (query) query += `&period=${period}`;
        else query += `?period=${period}`
      } else if (from && to) {
        if (query) query += `&from=${from}&to=${to}`;
        else query += `?from=${from}&to=${to}`
      }

      return httpService
        .get(
          `${appConfig.dashboardServiceUrl}api/tracker/posts.json${query}`,
          authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getChartsData = (color, period, from, to, sources, sentiments, page = 10) => { 
      let query = '';
      if (period) {
        query += `?period=${period}`;
      }
      else if (from && to) {
        if (query) {
          query += `&from=${from}&to=${to}`;
        }
        else {
          query += `?from=${from}&to=${to}`;
        }
      }
      query += `&page=${page || 10}`;
      query += `&color=${color}`;
      if (sentiments) {
        query += `&sentiments=${sentiments}`;
      }
      if (sources) {
        query += `&sources=${sources}`;
      }
      query += '&modules=averageChartData,chartData,meta,colors';
      return httpService
        .get(
          `${appConfig.dashboardServiceUrl}api/tracker/posts.json${query}`,
          authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getChartsHeaderData = (from, to, sources, sentiments) => { 
      let query = '';
      if (sources) {
        query += `?sources=${sources}&`;
      }
      else {
        query += '?';
      }
      if (sentiments) {
        query += `sentiments=${sentiments}`;
      }
      if (from && to) {
        query += `&from=${from}&to=${to}`;
      }
      return httpService
        .get(
          `${appConfig.dashboardServiceUrl}api/tracker/posts/header_data1${query}`,
          authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getMentionData = (color, page, sources, period) => {
      let query = '';
      query += `?color=${color}`;
      query += `&page=${page || 10}`;
      query += `&period=${period}`;
      if (sources) {
        query += `&sources=${sources}`;
      }
      query += `&modules=colorMentionData,meta`;
      return httpService
        .get(
          `${appConfig.dashboardServiceUrl}api/tracker/posts.json${query}`,
          authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getDailyViewTrackerData = (date, sources, sentiments, period = '1m') => { 
      let query = '';
      if (sources) {
        query += `?sources=${sources}&`;
      }
      else {
        query += '?';
      }
      query += `daily_view_date=${date}`;
      query += '&modules=colorSentimentMonthData,colorSentimentOverallData,sentimentMonthColorData';
      query += `&period=${period}`;
      if (sentiments) {
        query += `&sentiments=${sentiments}`;
      }

      return httpService
        .get(
          `${appConfig.dashboardServiceUrl}api/tracker/posts.json${query}`,
          authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getGlobalPlusHeaderData = (date, sources, sentiments) => { 
      let query = '';
      if (sources) {
        query += `?sources=${sources}&`;
      }
      else {
        query += '?';
      }
      query += `date=${date}`;
      if (sentiments) {
        query += `&sentiments=${sentiments}`;
      }

      return httpService
        .get(
          `${appConfig.dashboardServiceUrl}api/tracker/posts/header_data1${query}`,
          authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

  },
]);
