angular
  .module('app')
  .component('cartCheckoutMethodsComponent', {
    templateUrl: 'app/components/cart-checkout-methods/cart-checkout-methods.tmpl.html',
    controller: function (categoryValues, dataValidate, $state, $http, appConfig, $location, cartService, localStorageService, authService, $timeout, $scope, $cookies, paymentService) {
      const vm = this;

      function init() {
        vm.methodNumber = 1;
        vm.maxMethod = 1;
        vm.userIsLoggedIn();

        vm.payError = false;
        vm.nonce = false;
        vm.errFlag = false;
        vm.payDataFlag = false;
        vm.placeOrderFlag = false;
        vm.tax = 0;
        vm.methodStyle = ['gray', 'gray', 'gray', 'gray'];
        vm.registerAndCheckout = false;
        vm.checkoutAsGuest = false;
        vm.country = categoryValues('country');
        vm.states = categoryValues('states');

        vm.email = '';
        vm.password = '';
        vm.error = '';

        vm.data = {
          first_name: {value: '', required: true, name: 'first name', type: 'provide'},
          last_name: {value: '', required: true, name: 'last name', type: 'provide'},
          email: {value: '', required: true, name: 'email', type: 'provide'},
          middle_name: {value: '', name: 'middle name', type: 'provide'},
          address: {value: '', required: true, name: 'address', type: 'provide'},
          second_address: {value: '', name: 'second_address', type: 'provide'},
          city: {value: '', required: true, name: 'city', type: 'provide'},
          zip: {value: '', required: true, name: 'zip', type: 'numeric'},
          telephone: {value: '', required: true, name: 'telephone', type: 'numeric'},
          state: {
            value: vm.states[0],
            required: true,
            name: 'state',
            type: 'both'
          },
          country: {
            value: vm.country[0],
            required: true,
            name: 'country',
            type: 'select'
          }
        };

        paymentService.getSubscription().then((data) => {
          // Your Stripe public key
          vm.stripe = Stripe(data.publishable_key);

          // Create `card` element that will watch for updates
          // and display error messages
          const elements = vm.stripe.elements();
          vm.card = elements.create('card', { hidePostalCode: true, style: {
              base: {
                color: '#000',
                fontWeight: '500',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
              },
              invalid: {
                color: '#FF0000',
              },
            },
          });
          vm.card.mount('#card-element');
          vm.card.addEventListener('change', event => {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
              displayError.textContent = event.error.message;
            } else {
              displayError.textContent = '';
            }
          });
        });

        vm.products = [];
        vm.all = 0;
        vm.purchase = localStorageService.get('purchase');
        vm.user = localStorageService.get('currentUser');

        vm.getProductItems(vm.purchase.IDs.reports, 'reports');
        vm.getProductItems(vm.purchase.IDs.courses, 'courses');
        vm.getProductItems(vm.purchase.IDs.teaching_materials, 'teaching_materials');
        vm.editGrayList();
      }

      $scope.getStates = function (search) {
        const newState = vm.states.slice();
        if (search.length > 0 && newState.indexOf(search) === -1) {
          newState.unshift(search);
        }
        return newState;
      };

      vm.getBillingData = function () {
        if (vm.user && vm.user.id) {
          $http.get(appConfig.dashboardServiceUrl + 'api/billing_infos/' + vm.user.id + '.json', {params: {token: authService.token}})
            .then(function (res) {
              if (res && res.data && res.data[0]) {
                for (const key in vm.data) {
                  if (key === 'state') {
                    const index = _.findIndex(vm.states, function (item) {
                      return item.title === res.data[0][key];
                    });
                    vm.data[key].value = vm.states[index];
                  }
                  if (key === 'country') {
                    const index2 = _.findIndex(vm.country, function (item) {
                      return item.title === res.data[0][key];
                    });
                    vm.data[key].value = vm.country[index2];
                  }
                  vm.data[key].value = res.data[0][key] || '';
                }
              }
              if (!vm.data.email.value && vm.user) {
                vm.data.email.value = vm.user.email;
              }
              vm.continue();
            })
            .catch(function (err) {
            });
        } else {
          return false;
        }
      };

      vm.login = function () {
        vm.error = false;
        authService.login(this.email, this.password)
          .then(function (data) {
            if (data && data.success) {
              vm.user = localStorageService.get('currentUser');
              vm.methodNumber = vm.methodNumber + 1;
              if (vm.maxMethod < vm.methodNumber) {
                vm.maxMethod = vm.methodNumber;
              }
              vm.getBillingData();
            } else {
              vm.error = true;
            }
          });
      };

      vm.uploadBillingInfo = function () {
        if (vm.user.id) {
          if (dataValidate.validate(vm.data)) {
            const data = {};
            for (const item in vm.data) {
              if (vm.data[item].type === 'select') {
                data[item] = vm.data[item].value.title || vm.data[item].value;
              } else {
                data[item] = vm.data[item].value;
              }
            }
            data.member_id = vm.user.id;
            data.token = authService.token;
            $http.post(appConfig.dashboardServiceUrl + 'api/billing_infos.json', data)
              .then(function (res) {
                vm.continue();
              })
              .catch(function (err) {
              });
          }
        } else {
          vm.continue();
        }
      };

      vm.continue = function () {
        if (vm.methodNumber === 2 && !dataValidate.validate(vm.data)) {
          return;
        }
        vm.methodNumber = vm.methodNumber + 1;
        if (vm.methodNumber === 3 && !vm.purchase.amount) {
          vm.methodNumber = 4;
        }
        if (vm.maxMethod < vm.methodNumber) {
          vm.maxMethod = vm.methodNumber;
        }
        vm.editGrayList();
      };

      vm.editGrayList = function () {
        vm.methodStyle.forEach(function (value, index) {
          if (index === vm.methodNumber - 1) {
            vm.methodStyle[index] = 'black';
          } else {
            vm.methodStyle[index] = 'gray';
          }
        });
      };

      vm.getProductItems = function (obj, name) {
        if (name === 'reports'){
          cartService.getCartItem().then(function (res) {
            if(res.status === 200 && res.data.carts.length > 0) {
              res.data.carts.forEach(element => {
                vm.pageData = element.report;
                vm.pageData.count = element.quantity;
                vm.pageData.type = element.report.category;
                vm.pageData.cartId = element.id;
                vm.all = vm.all + (element.report.price * vm.pageData.count);
                vm.products.push(vm.pageData);
              });
            }
          });
        } else {
          for (const key in obj) {
            $http.get(appConfig.dashboardServiceUrl + 'api/' + name + '/' + key + '.json')
              .then(function (res) {
                vm.pageData = res.data;
                vm.pageData.image_url = res.data.image_url && res.data.image_url;
                vm.pageData.count = obj[key];
                vm.pageData.type = name;
                vm.all = vm.all + (vm.pageData.price * vm.pageData.count);
                vm.products.push(vm.pageData);
              });
          }
        }
      };

      vm.goToMethod = function (number) {
        vm.errFlag = false;
        vm.methodNumber = number;
        vm.editGrayList();
      };

      vm.passwordRecover = function () {
        $state.go('password-recover-cart');
      };

      vm.userIsLoggedIn = function () {
        vm.loginFlag = ($cookies.get('hg_session') !== undefined);
        if (vm.loginFlag) {
          vm.methodNumber = vm.methodNumber + 1;
          if (vm.maxMethod < vm.methodNumber) {
            vm.maxMethod = vm.methodNumber;
          }
          vm.getBillingData();
        } else {
          vm.methodNumber = 1;
        }
      };

      vm.stepBack = function () {
        vm.methodNumber = vm.methodNumber - 1;
        vm.editGrayList();
      };

      vm.goToThank = function () {
        localStorageService.remove('rcptURL');
        localStorageService.remove('reportPdf');
        localStorageService.remove('courseVideo');
        localStorageService.remove('courseImageUrl');
        localStorageService.remove('courseHeader');
        localStorageService.remove('courseFacultyName');
        localStorageService.remove('coursePublishedDate');
        $timeout(function () {
          vm.errFlag = false;
          vm.placeOrderFlag = true;
        }, 0);
        const names = [];
        const prices = [];
        vm.products.forEach(function (item) {
          names.push(item.header);
          prices.push(item.price);
        });
        const params = { 
          user: {
            email: vm.data.email.value,
            phone: vm.data.telephone.value,
            first_name: vm.data.first_name.value,
            middle_name: vm.data.middle_name.value,
            last_name: vm.data.last_name.value,
            address: { 
              city: vm.data.city.value,
              country: vm.data.country.value.title,
              line_1: vm.data.address.value,
              line_2: vm.data.second_address.value,
              postal_code: vm.data.zip.value,
              state: vm.data.state.value
            }
          },
          stripe: {
            source: vm.stripe_token,
          },
        }
        cartService.checkoutCart(params).then(function (res) {
          if (res) {
            vm.info = res.data;
            if (vm.info.success === false) {
              vm.errFlag = true;
              $timeout(function () {
                vm.placeOrderFlag = false;
              }, 0);
            } else {
              vm.errFlag = false;
              localStorageService.set('rcptURL', res.data.url);
              localStorageService.set('reportPdf', res.data.reports);
              localStorageService.set('courseVideo', res.data.video_url);
              localStorageService.set('courseImageUrl', res.data.image_url);
              localStorageService.set('courseHeader', res.data.header);
              localStorageService.set('courseFacultyName', res.data.faculty_name);
              localStorageService.set('coursePublishedDate', res.data.published_date);
              $timeout(function () {
                vm.placeOrderFlag = false;
              }, 0);
              $state.go('cart-thank');
            }
          }
        }).catch(function (err) {
          vm.placeOrderFlag = false;
          vm.errFlag = true;
        });
      };

      vm.onSubmitPayment = function() {
        // Listen for form submission, process the form with Stripe,
        // and get the 
        const paymentForm = document.getElementById('payment-form');
        paymentForm.addEventListener('submit', event => {
          event.preventDefault();
          vm.stripe.createToken(vm.card).then(result => {
            if (result.error) {
              const errorElement = document.getElementById('card-errors');
              errorElement.textContent = result.error.message;
            } else {
              // At this point, you should send the token ID
              // to your server so it can attach
              // the payment source to a customer
              vm.stripe_token = result.token.id;
            }
          });
        });
        vm.continue();
      }

      $scope.$watch(function () {
        return authService.currentUser;
      }, function (newVal) {
        vm.user = localStorageService.get('currentUser');
        if (vm.user) {
          init();
        }

      });
    }
  });
