angular.module('app').directive('associatedattributescol', function () {
  function link (scope) {

  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/associatedAttributes/associatedAttributesCol.html',
    link: link,
    scope: {
      items: '=',
      boldFirst: '='
    }
  }
});
