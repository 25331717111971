angular.module('app').directive('unicornComplete', [
  () => {
    let link = (scope) => {
      scope.isVisible = {
        suggestions: false,
      };

      scope.$watch('data', function (newVal) {
        if (scope.data) {
          scope.filteredChoices = scope.data;
        } else {
          scope.filteredChoices = [
            {
              "id": 361378,
              "title": "Douglas"
            },
            {
              "id": 361379,
              "title": "Orthotic"
            },
            {
              "id": 361380,
              "title": "Stone Bean"
            },
            {
              "id": 361381,
              "title": "Discovery Center"
            },
            {
              "id": 361382,
              "title": "Ascension"
            },
            {
              "id": 361383,
              "title": "Howard"
            },
            {
              "id": 361384,
              "title": "Actors"
            },
            {
              "id": 361385,
              "title": "Holdings"
            },
            {
              "id": 361386,
              "title": "CLVR"
            },
            {
              "id": 361387,
              "title": "Pancakes"
            },
            {
              "id": 361378,
              "title": "Stewart"
            },
            {
              "id": 361379,
              "title": "Orthotic Center"
            },
            {
              "id": 361380,
              "title": "Riverside"
            },
            {
              "id": 361381,
              "title": "Discovery Center"
            },
            {
              "id": 361382,
              "title": "Ascension"
            },
            {
              "id": 361383,
              "title": "Howard"
            },
            {
              "id": 361384,
              "title": "Actors"
            },
            {
              "id": 361385,
              "title": "Holdings"
            },
            {
              "id": 361386,
              "title": "CLVR"
            },
            {
              "id": 361387,
              "title": "Pancakes"
            },
            {
              "id": 361378,
              "title": "Stewart"
            },
            {
              "id": 361379,
              "title": "Orthotic Center"
            },
            {
              "id": 361380,
              "title": "Riverside"
            },
            {
              "id": 361381,
              "title": "Discovery Center"
            },
            {
              "id": 361382,
              "title": "Ascension"
            },
            {
              "id": 361383,
              "title": "Howard"
            },
            {
              "id": 361384,
              "title": "Actors"
            },
            {
              "id": 361385,
              "title": "Holdings"
            },
            {
              "id": 361386,
              "title": "CLVR"
            },
            {
              "id": 361387,
              "title": "Pancakes"
            },
            {
              "id": 361378,
              "title": "Stewart"
            },
            {
              "id": 361379,
              "title": "Orthotic Center"
            },
            {
              "id": 361380,
              "title": "Riverside"
            },
            {
              "id": 361381,
              "title": "Discovery Center"
            },
            {
              "id": 361382,
              "title": "Ascension"
            },
            {
              "id": 361383,
              "title": "Howard"
            },
            {
              "id": 361384,
              "title": "Actors"
            },
            {
              "id": 361385,
              "title": "Holdings"
            },
            {
              "id": 361386,
              "title": "CLVR"
            },
            {
              "id": 361387,
              "title": "Pancakes"
            },
            {
              "id": 361378,
              "title": "Stewart"
            },
            {
              "id": 361379,
              "title": "Orthotic Center"
            },
            {
              "id": 361380,
              "title": "Riverside"
            },
            {
              "id": 361381,
              "title": "Discovery Center"
            },
            {
              "id": 361382,
              "title": "Ascension"
            },
            {
              "id": 361383,
              "title": "Howard"
            },
            {
              "id": 361384,
              "title": "Actors"
            },
            {
              "id": 361385,
              "title": "Holdings"
            },
            {
              "id": 361386,
              "title": "CLVR"
            },
            {
              "id": 361387,
              "title": "Pancakes"
            },
            {
              "id": 361378,
              "title": "Stewart"
            },
            {
              "id": 361379,
              "title": "Orthotic Center"
            },
            {
              "id": 361380,
              "title": "Riverside"
            },
            {
              "id": 361381,
              "title": "Discovery Center"
            },
            {
              "id": 361382,
              "title": "Ascension"
            },
            {
              "id": 361383,
              "title": "Howard"
            },
            {
              "id": 361384,
              "title": "Actors"
            },
            {
              "id": 361385,
              "title": "Holdings"
            },
            {
              "id": 361386,
              "title": "CLVR"
            },
            {
              "id": 361387,
              "title": "Pancakes"
            },
            {
              "id": 361378,
              "title": "Stewart"
            },
            {
              "id": 361379,
              "title": "Orthotic Center"
            },
            {
              "id": 361380,
              "title": "Riverside"
            }
          ];
        }
      });

      // dummy data
      scope.categories = [
        "1-9", "A-C", "D-F", "G-I", "J-L", "M-O", "P-R", "S-U", "V-Z"
      ];

      scope.categoriesData = [
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        ['a', 'b', 'c'],
        ['d', 'e', 'f'],
        ['g', 'h', 'i'],
        ['j', 'k', 'l'],
        ['m', 'n', 'o'],
        ['p', 'q', 'r'],
        ['s', 't', 'u'],
        ['v', 'w', 'x', 'y', 'z']
      ];

      scope.categorySelect = index => { 
        scope.selectedCategoryIndex = index;
        scope.enteredtext = scope.categories[index].toLowerCase().substr(0, 1);
      };

      scope.filterItems = () => {
        if (scope.enteredtext.length < 0) return;
        scope.isVisible.suggestions =
          scope.enteredtext.length === 0
            ? false
            : scope.filteredChoices.length > 0;
        scope.selectedCategoryIndex = scope.categoriesData.findIndex(cat => cat.includes(scope.enteredtext.substr(0, 1))); 
      };

      scope.selectItem = (choice) => {
        scope.enteredtext = choice.title;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbColorUnicornData/unicornComplete/unicornCompleteView.html",
      link: link,
      scope: {
        enteredtext: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        data: "=",
      }
    };
  }
]);
