(function () {
  'use strict';

  angular.module('app').directive('hueChartGridNailsChart',
    [
      'common', 'config', 'chartsHelper',
      function (common, config, chartsHelper) {
        function link(scope, element) {
          scope.$watch("data", (newValue) => {
            if (newValue) {
              bindData();
            }
          });

          function bindData() {
            if (!scope.data) {
              return;
            }
            
            if(scope.data && scope.data.chromatic_colors.data.length > 1) {
              let totalAchr = 0;
              let totalChr = 0;
              totalAchr = scope.data.achromatic_colors.percentage.replace('%','');
              totalChr = scope.data.chromatic_colors.percentage.replace('%','');
              const data = {
                groups: [{title: totalAchr + '% ACHROMATIC COLORS', name: 'chr'},
                  {title: totalChr + '% CHROMATIC COLORS', name: 'achr'}]
              };
              let name = scope.data.selected_category;
              data.periods = []
              _.each(scope.data.chromatic_colors.data, function (d) {
                let year = ' - ' + d.year;
                data.periods.push({title: name + year})
              })

              let chromatic = [];
              let chromaticTotal = [];
              _.each(scope.data.chromatic_colors.data, function (obj, index) {
                let tempChromatic = [];
                let tempChromaticTotal = [];
                _.each(obj.colors, function (element, index) {
                  let total = 0;
                  let data = _.map(element.categories, function (d) {
                    if(d.name === name) {
                      total = d.total;
                      let per = d.percentage.replace('%','')
                      return {
                        title: d.percentage,
                        value: (per / 1000)
                      }
                    }
                  })
                  if(name === 'rtw' && data[0]) {
                    tempChromatic[index] = data[0];
                    tempChromaticTotal[index] = total;
                  }
                  if(name === 'menswear' && data[1]) {
                    tempChromatic[index] = data[1];
                    tempChromaticTotal[index] = total;
                  }
                  if(name === 'couture' && data[2]) {
                    tempChromatic[index] = data[2];
                    tempChromaticTotal[index] = total;
                  }
                  
                })
                chromatic[index] = tempChromatic;
                chromaticTotal[index] = tempChromaticTotal;
              })

              _.each(scope.data.chromatic_colors.data, function (obj) {
                _.each(obj.colors, function (element, index) {
                  element.group = "achr";
                  element.color = element.hex;
                  element.title = element.name;
                  element.data = [];
                  element.data.push(
                    chromatic[0][index],
                    chromatic[1][index],
                    chromatic[2][index]
                  )
                  let finalValue = chromaticTotal[0][index] + chromaticTotal[1][index] + chromaticTotal[2][index]
                  element.value = finalValue;
                })
              })

              let achromatic = [];
              let achromaticTotal = [];
              _.each(scope.data.achromatic_colors.data, function (obj, index) {
                let tempAchromatic = [];
                let tempAchromaticTotal = [];
                _.each(obj.colors, function (element, index) {
                  let total = 0;
                  let data = _.map(element.categories, function (d) {
                    if(d.name === name) {
                      total = d.total;
                      let per = d.percentage.replace('%','')

                      return {
                        title: d.percentage,
                        value: (per / 1000)
                      }
                    }
                  })
                  if(name === 'rtw' && data[0]) {
                    tempAchromatic[index] = data[0];
                    tempAchromaticTotal[index] = total;
                  }
                  if(name === 'menswear' && data[1]) {
                    tempAchromatic[index] = data[1];
                    tempAchromaticTotal[index] = total;
                  }
                  if(name === 'couture' && data[2]) {
                    tempAchromatic[index] = data[2];
                    tempAchromaticTotal[index] = total;
                  }
                })
                achromatic[index] = tempAchromatic;
                achromaticTotal[index] = tempAchromaticTotal;
              })

              _.each(scope.data.achromatic_colors.data, function (obj) {
                _.each(obj.colors, function (element, index) {
                  element.group = "chr";
                  element.color = element.hex;
                  element.title = element.name;
                  element.data = [];
                  element.data.push(
                    achromatic[0][index],
                    achromatic[1][index],
                    achromatic[2][index]
                  )
                  let finalValue = achromaticTotal[0][index] + achromaticTotal[1][index] + achromaticTotal[2][index]
                  element.value = finalValue;
                })
              })
              data.data = scope.data.chromatic_colors.data[0].colors.concat(scope.data.achromatic_colors.data[0].colors);
              const container = element.find('[chart-type]').html('');
              const ch = new chartNailsLinearVertical({
                data: data,
                container: container[0],
                options: {
                  layout: {
                    mode: scope.mode && scope.mode.extraView ? 'colors' : 'default'
                  }
                }
              });
            } else {
              let totalAchr = 0;
              let totalChr = 0;
              totalAchr = scope.data.achromatic_colors.percentage.replace('%','');
              totalChr = scope.data.chromatic_colors.percentage.replace('%','');
              const data = {
                groups: [{title: totalAchr + '% ACHROMATIC COLORS', name: 'chr'},
                  {title: totalChr + '% CHROMATIC COLORS', name: 'achr'}]
              };
              let year = scope.data.chromatic_colors.data[0].year ? '- ' + scope.data.chromatic_colors.data[0].year : '';
              data.periods = [
                { title: "rtw " + year},
                { title: "menswear " + year},
                { title: "couture " + year}
              ];
              _.each(scope.data.chromatic_colors.data[0].colors, function (obj) {
                obj.group = "achr";
                obj.color = obj.hex;
                obj.title = obj.name;
                obj.data = _.map(obj.categories, function (d) {
                  let per = d.percentage.replace('%','')
                  return {
                    title: d.percentage,
                    value: (per / 1000)
                  }
                })
                let finalValue = 0;
                if(obj.categories.length > 0) {
                  obj.categories.forEach(element => {
                    finalValue = finalValue + element.total;
                  });
                  obj.value = finalValue;
                } else {
                  obj.value = finalValue;
                }
              })
              _.each(scope.data.achromatic_colors.data[0].colors, function (obj) {
                obj.group = "chr";
                obj.color = obj.hex;
                obj.title = obj.name;
                obj.data = _.map(obj.categories, function (d) {
                  const per = d.percentage.replace('%','')
                  return {
                    title: d.percentage,
                    value: (per / 1000)
                  }
                })
                let finalValue = 0;
                if(obj.categories.length > 0) {
                  obj.categories.forEach(element => {
                    finalValue = finalValue + element.total;
                  });
                  obj.value = finalValue;
                } else {
                  obj.value = finalValue;
                }
              })

              data.data = scope.data.chromatic_colors.data[0].colors.concat(scope.data.achromatic_colors.data[0].colors);

              const container = element.find('[chart-type]').html('');
              const ch = new chartNailsLinearVertical({
                data: data,
                container: container[0],
                options: {
                  layout: {
                    mode: scope.mode && scope.mode.extraView ? 'colors' : 'default'
                  }
                }
              });
            }
          }
        }

        const directive = {
          link: link,
          restrict: 'E',
          replace: true,
          scope: {
            data: '=',
            mode: '='
          },
          templateUrl: 'app/directives/custom-infographics-directives/chart-grid-nails-chart.html'
        };

        return directive;

      }
    ]);
}());
