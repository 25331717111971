angular.module("app").directive("designerUseColorAcrossCitiesChart", () => {
  let link = (scope) => {
    scope.data = scope.data.filter(item => item.colors.length > 0);
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbDesignerUseColorAcrossCitiesChart/dbDesignerUseColorAcrossCitiesChartView.html",
    link: link,
    scope: {
      data: "="
    }
  };
});
