angular.module('app').directive('fashionRegionComplete', [  "brandingDashboardRepository",
function (
  brandingDashboardRepository
) {
  function link (scope) {
      scope.isVisible = {
        suggestions: false,
      };

      // dummy data
      scope.categories = [
        "ASIA", "EUROPE", "NORTH AMERICA", "LATIN AMERICA", "AUSTRALIA"
      ];

      scope.filteredChoices = [];

      // scope.loadFashionCities = () => {
      //   scope.ajaxInProgress = true;
      //   brandingDashboardRepository.dashboard.getFashionCities().then(data => {  
      //     scope.filteredChoices= data.cities;
      //   }).finally(() => {
      //     scope.ajaxInProgress = false;
      //   });
      // }; 
      // scope.loadFashionCities();

      scope.selectedCategoryIndex = 0;
      scope.categorySelect = (item, index) => {
        scope.selectedCategoryIndex = index;
        scope.enteredtext = scope.categories[index].toLowerCase().substr(0, 1);
        scope.searchTitle();
        if(item === 'LATIN AMERICA'){
          item = 'South America';
        }
        scope.filteredChoices = [];
          scope.cities.map(obj => {
            if(obj.region.toLowerCase() === item.toLowerCase()){
              scope.filteredChoices.push(obj);
            }
          });
      };

      scope.searchTitle = () => {
        if (scope.enteredtext.length < 0) {
          return;
        }
        scope.searchhandler('region', scope.enteredtext);
      }; 
 
      scope.filterItems = () => {
        // if (scope.enteredtext.length < 0) return;
        // scope.isVisible.suggestions =
        //   scope.enteredtext.length === 0
        //     ? false
        //     : scope.filteredChoices.length > 0;

        if (!scope.enteredtext.length) {
          scope.isVisible.suggestions = false;
          return;
        }
        scope.isVisible.suggestions = true; 
        scope.enterCity = scope.cities.filter(attr => attr.name.toLowerCase().includes(scope.enteredtext.toLowerCase()) || attr.region.toLowerCase().includes(scope.enteredtext.toLowerCase()));
        // scope.filteredChoices = scope.cities.filter(attr => { 
        //   let search = scope.enteredtext.toLowerCase();
        //   let title = attr.name.toLowerCase();
        //   return title.startsWith(search.substr(0, 1)) && title.includes(search);
        // }); 
        if (scope.enterCity.length > 0) {
          let selectedRegion = scope.enterCity[0].region.toLowerCase() === 'south america' ? 'LATIN AMERICA' : scope.enterCity[0].region.toUpperCase();
          scope.selectedCategoryIndex = scope.categories.findIndex(cat => cat.includes(selectedRegion));  
          scope.filteredChoices = [];
          scope.cities.map(obj => {
            if (obj.region === scope.enterCity[0].region) {
              scope.filteredChoices.push(obj);
            }
          });
        } else {
          scope.selectedCategoryIndex = -1;
        }
      };

      scope.selectItem = (choice) => { 
        scope.selected = choice;
        scope.enteredtext = choice.title;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };

      scope.$watch('cities', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          scope.cities = newValue;
          scope.filterItems();
        }
      }, true);

    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbFashionComplete/fashionRegionComplete/fashionRegionCompleteView.html",
      link: link,
      scope: {
        enteredtext: "=",
        selected: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        disabled: "=",
        cities:"=",
        disable: "=",
        board: "=",
        searchhandler: "="
      }
    };
  }
]);
