angular.module("app").directive("legalColorData", [
  "legalTrendsDashboardRepository",
  "$timeout",
  (legalTrendsDashboardRepository, timeout) => {
    let link = (scope, element) => {
      // scope.isLoadingControls = true; 


      scope.boardType = "legal-trend";
      scope.showMoreColors = false;
      scope.showAutoPopup = false;
      scope.showColorPopup = false;
      scope.colorsCount = 22;
      scope.topColorsExpanded = false;
      scope.colorFrequencyExpanded = false;

      scope.description = '';
      scope.fullDescription = '';
      scope.showingFullDesc = false;
      scope.moreDescAvailable = false;
      scope.maxDescWords = 310;

      scope.selectedItem = scope.item;
      scope.colors = scope.colorSelectionData;

      scope.currentPage = 1;
      scope.columnOrder = 'desc';
      scope.colorCaseData = [];
      scope.isLoadedAllBrandData = false;
      scope.azORYearMode = false;
      scope.currentYearPage = 1;
      scope.selectedYear = {title: moment().year(), id: moment().year()};

      // dummyData
      const colorComparisonData = {
        percentages: [
          ">18.44%",
          "9.83%-7.09%",
          "6.89%-4.76%",
          "9.83%",
        ],
        mostPopular: [
          {
            percentage: 20,
            title: "color1",
            color: { hex: "#fff6f9" }
          },
          {
            percentage: 20,
            title: "color2",
            color: { hex: "#f6bac6", hex1: "#ff0000" }
          },
          {
            percentage: 15,
            title: "color3",
            color: { hex: "#ff7f00" }
          },
          {
            percentage: 15,
            title: "color4",
            color: { hex: "#fff640", hex1: "#f3d725" }
          },
          {
            percentage: 15,
            title: "color5",
            color: { hex: "#2d9641" }
          },
          {
            percentage: 15,
            title: "color6",
            color: { hex: "#2065dc" }
          }
        ],
        moderate: [
          {
            percentage: 55,
            title: "color7",
            color: { hex: "#7d1f7f" }
          },
          {
            percentage: 45,
            title: "color8",
            color: { hex: "#644321" }
          },
        ],
        infrequent: [
          {
            percentage: 100,
            title: "color9",
            color: { hex: "#b9b9b8", hex1: "#2f52a0" }
          },
        ],
        not_used: [
          {
            percentage: 30,
            title: "color10",
            color: { hex: "#ffffff" }
          },
          {
            percentage: 70,
            title: "color11",
            color: { hex: "#020303" }
          },
        ]
      };

      // scope.colorFrequencyData = colorComparisonData;

      // dummy data -> color trademarks comparison between (product) and all other product and service categories
      scope.selectedComparisonData = {
        name: "chemicals",
        img_url: "/assets/img/legals/products/chemicals.png",
        colorComparisonData
      };

      scope.allComparisonData = [
        {
          name: "paints",
          img_url: "/assets/img/legals/products/paints.png",
          colorComparisonData
        },
        {
          name: "cosmetics and cleaning products",
          img_url: "/assets/img/legals/products/cosmetics-and-cleaning-products.png",
          colorComparisonData
        },
        {
          name: "lubricants and fuels",
          img_url: "/assets/img/legals/products/lubricants-and-fuels.png",
          colorComparisonData
        },
        {
          name: "pharmaceuticals",
          img_url: "/assets/img/legals/products/pharmaceuticals.png",
          colorComparisonData
        },
        {
          name: "metal goods",
          img_url: "/assets/img/legals/products/metal-goods.png",
          colorComparisonData
        },
        {
          name: "machinery",
          img_url: "/assets/img/legals/products/machinery.png",
          colorComparisonData
        },
        {
          name: "hand tools",
          img_url: "/assets/img/legals/products/hand-tools.png",
          colorComparisonData
        },
        {
          name: "electrical and scientific apparatus",
          img_url: "/assets/img/legals/products/electrical-and-scientific-appratus.png",
          colorComparisonData
        },
        {
          name: "medical apparatus",
          img_url: "/assets/img/legals/products/medical-apparatus.png",
          colorComparisonData
        },
        {
          name: "environmental control apparatus",
          img_url: "/assets/img/legals/products/environmental-control-apparatus.png",
          colorComparisonData
        },
        {
          name: "vehicles",
          img_url: "/assets/img/legals/products/vehicles.png",
          colorComparisonData
        },
        {
          name: "firearms",
          img_url: "/assets/img/legals/products/firearms.png",
          colorComparisonData
        },
        {
          name: "jewelry",
          img_url: "/assets/img/legals/products/jewelry.png",
          colorComparisonData
        },
        {
          name: "musical instruments",
          img_url: "/assets/img/legals/products/musical-instruments.png",
          colorComparisonData
        },
        {
          name: "paper goods and printed matter",
          img_url: "/assets/img/legals/products/paper-goods-and-printed-matter.png",
          colorComparisonData
        },
      ];

      // dummy data -> products
      scope.colorProductData = [
        {
          name: "chemicals",
          img_url: "/assets/img/legals/products/chemicals.png",
          percentage: 30
        },
        {
          name: "paints",
          img_url: "/assets/img/legals/products/paints.png",
          percentage: 30
        },
        {
          name: "cosmetics and cleaning products",
          img_url: "/assets/img/legals/products/cosmetics-and-cleaning-products.png",
          percentage: 30
        },
        {
          name: "lubricants and fuels",
          img_url: "/assets/img/legals/products/lubricants-and-fuels.png",
          percentage: 60
        },
        {
          name: "pharmaceuticals",
          img_url: "/assets/img/legals/products/pharmaceuticals.png",
          percentage: 40
        },
        {
          name: "metal goods",
          img_url: "/assets/img/legals/products/metal-goods.png",
          percentage: 50
        },
        {
          name: "machinery",
          img_url: "/assets/img/legals/products/machinery.png",
          percentage: 85
        },
        {
          name: "hand tools",
          img_url: "/assets/img/legals/products/hand-tools.png",
          percentage: 35
        },
        {
          name: "electrical and scientific apparatus",
          img_url: "/assets/img/legals/products/electrical-and-scientific-appratus.png",
          percentage: 15
        },
        {
          name: "medical apparatus",
          img_url: "/assets/img/legals/products/medical-apparatus.png",
          percentage: 67
        },
        {
          name: "environmental control apparatus",
          img_url: "/assets/img/legals/products/environmental-control-apparatus.png",
          percentage: 50
        },
        {
          name: "vehicles",
          img_url: "/assets/img/legals/products/vehicles.png",
          percentage: 40
        },
        {
          name: "firearms",
          img_url: "/assets/img/legals/products/firearms.png",
          percentage: 90
        },
        {
          name: "jewelry",
          img_url: "/assets/img/legals/products/jewelry.png",
          percentage: 20
        },
        {
          name: "musical instruments",
          img_url: "/assets/img/legals/products/musical-instruments.png",
          percentage: 40
        },
        {
          name: "paper goods and printed matter",
          img_url: "/assets/img/legals/products/paper-goods-and-printed-matter.png",
          percentage: 10
        },
        {
          name: "rubber goods",
          img_url: "/assets/img/legals/products/rubber-goods.png",
          percentage: 100
        },
        {
          name: "leather goods",
          img_url: "/assets/img/legals/products/leather-goods.png",
          percentage: 70
        },
        {
          name: "toys and sporting goods",
          img_url: "/assets/img/legals/products/toys-and-sporting-goods.png",
          percentage: 70
        },
        {
          name: "meats and processed foods",
          img_url: "/assets/img/legals/products/meats-and-processed-foods.png",
          percentage: 30
        },
        {
          name: "staple foods",
          img_url: "/assets/img/legals/products/staple-foods.png",
          percentage: 75
        },
        {
          name: "natural agricultural products",
          img_url: "/assets/img/legals/products/natural-agricultural-products.png",
          percentage: 40
        },
        {
          name: "light beverages",
          img_url: "/assets/img/legals/products/light-beverages.png",
          percentage: 50
        },
        {
          name: "wines and sprits",
          img_url: "/assets/img/legals/products/wines-and-spirits.png",
          percentage: 100
        },
        {
          name: "smokers articles",
          img_url: "/assets/img/legals/products/smokers-articles.png",
          percentage: 30
        },
        {
          name: "advertising and business",
          img_url: "/assets/img/legals/products/advertising-and-business.png",
          percentage: 70
        },
        {
          name: "insurance and financial",
          img_url: "/assets/img/legals/products/insurance-and-financial.png",
          percentage: 70
        },
        {
          name: "non-metalic building materials",
          img_url: "/assets/img/legals/products/non-metalic-building-materials.png",
          percentage: 60
        },
        {
          name: "toys and sporting goods",
          img_url: "/assets/img/legals/products/toys-and-sporting-goods.png",
          percentage: 70
        },
        {
          name: "meats and processed foods",
          img_url: "/assets/img/legals/products/meats-and-processed-foods.png",
          percentage: 70
        },
        {
          name: "staple foods",
          img_url: "/assets/img/legals/products/staple-foods.png",
          percentage: 70
        },
        {
          name: "natural agricultural products",
          img_url: "/assets/img/legals/products/natural-agricultural-products.png",
          percentage: 100
        },
        {
          name: "light beverages",
          img_url: "/assets/img/legals/products/light-beverages.png",
          percentage: 20
        },
        {
          name: "wines and spirits",
          img_url: "/assets/img/legals/products/wines-and-spirits.png",
          percentage: 90
        },
        {
          name: "smokers articles",
          img_url: "/assets/img/legals/products/smokers-articles.png",
          percentage: 70
        },
        {
          name: "advertising and business",
          img_url: "/assets/img/legals/products/advertising-and-business.png",
          percentage: 40
        },
        {
          name: "insurance and financial",
          img_url: "/assets/img/legals/products/insurance-and-financial.png",
          percentage: 20
        },
        {
          name: "non-metallic building materials",
          img_url: "/assets/img/legals/products/non-metalic-building-materials.png",
          percentage: 40
        },
        {
          name: "furniture and articles not otherwise",
          img_url: "/assets/img/legals/products/furniture-and-articles.png",
          percentage: 70
        },
        {
          name: "housewares and glass",
          img_url: "/assets/img/legals/products/housewares-and-glass.png",
          percentage: 60
        },
        {
          name: "cordage and fibers",
          img_url: "/assets/img/legals/products/cordage-and-fibers.png",
          percentage: 10
        },
        {
          name: "yarns and threads",
          img_url: "/assets/img/legals/products/yarns-and-threads.png",
          percentage: 70
        },
        {
          name: "fabrics",
          img_url: "/assets/img/legals/products/fabrics.png",
          percentage: 30
        },
        {
          name: "clothing",
          img_url: "/assets/img/legals/products/clothing.png",
          percentage: 70
        },
        {
          name: "fancy goods",
          img_url: "/assets/img/legals/products/fancy-goods.png",
          percentage: 70
        },
        {
          name: "floor coverings",
          img_url: "/assets/img/legals/products/floor-coverings.png",
          percentage: 70
        },
        {
          name: "construction and repair",
          img_url: "/assets/img/legals/products/construction-and-repair.png",
          percentage: 10
        },
        {
          name: "communication",
          img_url: "/assets/img/legals/products/communication.png",
          percentage: 70
        },
        {
          name: "transporation and storage",
          img_url: "/assets/img/legals/products/transporation-and-storage.png",
          percentage: 20
        },
        {
          name: "material treatment",
          img_url: "/assets/img/legals/products/material-treatment.png",
          percentage: 10
        },
        {
          name: "education and entertainment",
          img_url: "/assets/img/legals/products/education-and-entertainment.png",
          percentage: 100
        },
        {
          name: "computer, scientific and legal",
          img_url: "/assets/img/legals/products/computer-scientific-and-legal.png",
          percentage: 70
        },
        {
          name: "hotels and restaurants",
          img_url: "/assets/img/legals/products/hotels-and-restaurants.png",
          percentage: 70
        },
        {
          name: "medical, beauty and agricultural",
          img_url: "/assets/img/legals/products/medical-beauty-and-agricultural.png",
          percentage: 30
        },
        {
          name: "staple foods",
          img_url: "/assets/img/legals/products/personal.png",
          percentage: 50
        },
      ];

      scope.characters = [
        "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
        "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
      ];

      scope.selectedCharacter = "ALL";

      scope.characterSelect = (value) => {
        scope.selectedCharacter = value;
        scope.currentYearPage = 1;
        scope.colorSelectedTrademark = ''
        scope.colorTrademarksYearData = [];
        scope.loadYearChartData3();
      };
  
      scope.isSelected = (value) => {
        return scope.selectedCharacter === value;
      };

      // brand trademark list
      scope.selectedBrandDashCharacter = "YEAR";

      scope.brandDashCharacterSelect = (value, type) => {
        if(type) {
          scope.selectedBrandDashCharacter = value.id;
        } else {
          scope.selectedBrandDashCharacter = value;
        }
        scope.currentPage = 1;
        scope.colorCaseData = [];
        scope.loadBrandChartData2();
      };

      scope.isBrandDashSelected = (value, type) => {
        if(type) {
          return scope.selectedBrandDashCharacter === value.id;
        } else {
          return scope.selectedBrandDashCharacter === value;
        }
      };

      // color trademark list
      scope.colorDashcharacters = [
        "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
        "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
      ];

      scope.selectedColorDashCharacter = "YEAR";

      scope.colorDashCharacterSelect = (value) => {
        scope.selectedColorDashCharacter = value;
        scope.currentPage = 1;
        scope.colorCaseData = [];
        scope.loadColorChartData6();
      };

      scope.isColorDashSelected = (value) => {
        return scope.selectedColorDashCharacter === value;
      };

      // color brand trademark list
      scope.colorBrandDashcharacters = [
        "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
        "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
      ];

      scope.selectedColorBrandDashCharacter = "ALL";

      scope.colorBrandDashCharacterSelect = (value) => {
        scope.selectedColorBrandDashCharacter = value;
        scope.brandsOwningMainColorData = [];
        scope.brandsOwningColorData = [];
        scope.currentPageBrand = 1;
        legalTrendsDashboardRepository
          .getColorChartData4ByColorId(scope.selectedItem.id, 'chartData4', scope.selectedColorBrandDashCharacter)
          .then(function (data) {
            scope.brandsOwningMainColorData = data.chartData4;
            if(scope.brandsOwningMainColorData.length > 0) {
              scope.brandsOwningColorData = scope.brandsOwningMainColorData.slice(0, 300);
              if(scope.brandsOwningMainColorData.length > 300) {
                scope.isLoadedAllBrandData = true;
              } else {
                scope.isLoadedAllBrandData = false;
              }
            }
          });
      };

      scope.isColorBrandDashSelected = (value) => {
        return scope.selectedColorBrandDashCharacter === value;
      };

      scope.isModeColor = () => {
        return scope.viewMode === "color";
      };

      scope.isModeYear = () => {
        return scope.viewMode === "year";
      };

      scope.isModeProduct = () => {
        return scope.viewMode === "productOrService";
      };

      scope.isModeBrand = () => {
        return scope.viewMode === "brand";
      };

      scope.setDescription = desc => {
        scope.fullDescription = desc;
        if(desc){
          scope.description = desc.substr(0, scope.maxDescWords);
          scope.moreDescAvailable = desc.length > scope.maxDescWords;
        }
      };
  
      scope.showMoreDesc = () => {
        if (scope.showingFullDesc) {
          scope.description = scope.fullDescription.substr(0, scope.maxDescWords);
        }
        else {
          scope.description = scope.fullDescription;
        }
        scope.showingFullDesc = !scope.showingFullDesc;
      };

      scope.productOrder = 'year'

      scope.loadMoreData = () => {
        scope.currentPage +=1;
        if (scope.viewMode === "color") { 
          scope.loadColorChartData6();
        }
        if(scope.viewMode === "productOrService") {
          scope.loadProductChartData4();
        }
        if(scope.viewMode === "brand") {
          scope.loadBrandChartData2();
        }
      }

      scope.switchFilters = () => {
        scope.currentPage = 1;
        scope.colorCaseData = [];
        scope.azORYearMode = !scope.azORYearMode; 
        if (scope.viewMode === "color") { 
          scope.loadColorChartData6();
        }
        if(scope.viewMode === "productOrService") {
          if(scope.azORYearMode){
            scope.productOrder = 'a-z';
          } else {
            scope.productOrder = 'year';
          }
          scope.loadProductChartData4();
        }
        if(scope.viewMode === "brand") {
          scope.loadBrandChartData2();
        }
      };

      scope.loadMoreBrandData = () => {
        scope.currentPageBrand += 1;
        let count = scope.currentPageBrand * 300;
        if(scope.brandsOwningMainColorData.length > count) {
          scope.isLoadedAllBrandData = true;
        } else {
          scope.isLoadedAllBrandData = false;
        }
        scope.brandsOwningColorData = scope.brandsOwningMainColorData.slice(0, count);
      }

      scope.onClickBrandName = (item) => {
        scope.moveBrandName(item);
      }

      scope.loadColorChartData6 = () => {
        legalTrendsDashboardRepository
        .getColorChartData6ByColorId(scope.selectedItem.id, 'chartData6', scope.currentPage, 20, scope.selectedColorDashCharacter, scope.columnOrder)
        .then(function (data) {
          scope.colorCaseData = scope.colorCaseData.concat(data.chartData6.legal_color_trademarks);
          scope.isLoadMoreData = data.chartData6.paginate.current_page === data.chartData6.paginate.total_pages ? true : false;
        });
      }

      scope.loadProductChartData4 = () => {
        legalTrendsDashboardRepository
          .getProductChartData4ByProduct(scope.selectedItem.title, 'chartData4', scope.currentPage, 20, scope.productOrder, scope.columnOrder)
          .then(function (data) {
            scope.colorCaseData = scope.colorCaseData.concat(data.chartData4.legal_color_trademarks);
            scope.isLoadMoreData = data.chartData4.paginate.current_page === data.chartData4.paginate.total_pages ? true : false;
          });
      }

      scope.loadBrandChartData2 = () => {
        legalTrendsDashboardRepository
        .getBrandChartData2ByBrand(scope.selectedItem.title, 'chartData2', scope.currentPage, 20, scope.selectedBrandDashCharacter, scope.columnOrder)
        .then(function (data) {
          scope.colorCaseData = scope.colorCaseData.concat(data.chartData2.legal_color_trademarks);
          scope.isLoadMoreData = data.chartData2.paginate.current_page === data.chartData2.paginate.total_pages ? true : false;
        });
      }

      scope.loadYearChartData3 = () => {
        legalTrendsDashboardRepository
          .getYearChartsData3ByYear(scope.selectedItem.title, 'chartData3', scope.currentYearPage, 20, scope.selectedCharacter, scope.colorSelectedTrademark)
          .then(function (data) {
            scope.colorTrademarksYearData = scope.colorTrademarksYearData.concat(data.chartData3.brands);
            scope.isLoadMoreYearData = parseInt(data.chartData3.paginate.current_page) === parseInt(data.chartData3.paginate.total_pages) ? true : false;
          });
      }

      scope.loadMoreYearData = () => {
        scope.currentYearPage +=1;
        scope.loadYearChartData3();
      }

      scope.selectedTrademark = (color) => {
        scope.currentYearPage = 1;
        scope.selectedCharacter = "ALL";
        scope.colorSelectedTrademark = color.id;
        scope.colorTrademarksYearData = [];
        scope.loadYearChartData3();
      }

      if (scope.viewMode === "color") {
        scope.isLoadingControls = true;
        scope.currentPage = 1; 
        scope.colorCaseData = [];
        scope.azORYearMode = false;
        scope.currentPageBrand = 1;
        scope.brandsOwningMainColorData = [];
        scope.brandsOwningColorData = [];
      
        legalTrendsDashboardRepository
          .getColorChartsDataByColorId(scope.selectedItem.id, 'header')
          .then(function (data) {
            scope.colorHeaderData = data.header;
            scope.setDescription(scope.colorHeaderData.description);
            scope.isLoadingControls = false; 
          });

          legalTrendsDashboardRepository
          .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData1')
          .then(function (data) {
            scope.colorDataTabColorChart1 = data.chartData1;
            scope.mainPercentage = scope.colorDataTabColorChart1.selected_colors.percentage + '%';
          });

          legalTrendsDashboardRepository
          .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData2', scope.selectedYear.id)
          .then(function (data) {
            scope.colorTrademarkData = data.chartData2;
          });

          // legalTrendsDashboardRepository
          // .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData3')
          // .then(function (data) {
          //   console.log('chart 3', data)
          //   // scope.colorDataTabColorChart3 = data.response;
          // });

          legalTrendsDashboardRepository
          .getColorChartData4ByColorId(scope.selectedItem.id, 'chartData4', scope.selectedColorBrandDashCharacter)
          .then(function (data) {
            scope.brandsOwningMainColorData = data.chartData4;
            if(scope.brandsOwningMainColorData.length > 0) {
              scope.brandsOwningColorData = scope.brandsOwningMainColorData.slice(0, 300);
              if(scope.brandsOwningMainColorData.length > 300) {
                scope.isLoadedAllBrandData = true;
              } else {
                scope.isLoadedAllBrandData = false;
              }
            }
          });

          legalTrendsDashboardRepository
          .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData5')
          .then(function (data) {
            scope.colorCountriesData = data.chartData5;
          });

          scope.loadColorChartData6();          
      }

      if (scope.viewMode === "productOrService") {
        scope.isLoadingControls = true;
        scope.currentPage = 1;
        scope.colorCaseData = [];
        scope.azORYearMode = false;
      
        legalTrendsDashboardRepository
          .getProductChartsDataByProduct(scope.selectedItem.title, 'header')
          .then(function (data) {
            scope.productHeaderData = data.header;
            scope.isLoadingControls = false; 
          });

          legalTrendsDashboardRepository
          .getProductChartsDataByProduct(scope.selectedItem.title, 'chartData1')
          .then(function (data) {
            scope.colorTrademarksData = data.chartData1;
          });

          legalTrendsDashboardRepository
          .getProductChartsDataByProduct(scope.selectedItem.title, 'chartData2')
          .then(function (data) {
            scope.topTenBrand = data.chartData2.color_trademarks;
            scope.topTenBrand.forEach(element => {
              let count = element.colors.length;
              if(element.percentage > 0 && element.percentage <= 1){
                element.modifyPercentage = 40;
              } else if(element.percentage > 1 && element.percentage <= 2){
                element.modifyPercentage = 80;
              } else if(element.percentage > 2 && element.percentage <= 3){
                element.modifyPercentage = 120;
              } else if(element.percentage > 3 && element.percentage <= 4){
                element.modifyPercentage = 160;
              } else if(element.percentage > 4 && element.percentage <= 5){
                element.modifyPercentage = 200;
              } else if(element.percentage > 5 && element.percentage <= 6){
                element.modifyPercentage = 240;
              } else if(element.percentage > 6 && element.percentage <= 7){
                element.modifyPercentage = 280;
              } else if(element.percentage > 7 && element.percentage <= 8){
                element.modifyPercentage = 320;
              } else if(element.percentage > 8 && element.percentage <= 9){
                element.modifyPercentage = 360;
              } else if(element.percentage > 9 && element.percentage <= 10){
                element.modifyPercentage = 400;
              } else if(element.percentage > 10){
                element.modifyPercentage = 400;
              }

              let percentageDivide = element.modifyPercentage / count;
              element.colors.forEach(element => {
                element.percentageCount = percentageDivide;
              });
            });
            scope.maxPoint = data.chartData2.points[0];
          });

          legalTrendsDashboardRepository
          .getProductChartsDataByProduct(scope.selectedItem.title, 'chartData3', scope.selectedYear.id)
          .then(function (data) {
            scope.colorTrademarkData = data.chartData3;
          });

          scope.loadProductChartData4();
      }

      if (scope.viewMode === "brand") {
        scope.isLoadingControls = true;
        scope.currentPage = 1;
        scope.colorCaseData = [];
        scope.azORYearMode = false;
      
        legalTrendsDashboardRepository
          .getBrandChartsDataByBrand(scope.selectedItem.title, 'header')
          .then(function (data) {
            scope.brandHeaderData = data.header;
            scope.isLoadingControls = false; 
          });

          legalTrendsDashboardRepository
          .getBrandChartsDataByBrand(scope.selectedItem.title, 'chartData1')
          .then(function (data) {
            scope.colorTrademarksData = data.chartData1;
          });

          scope.loadBrandChartData2();
      }

      if (scope.viewMode === "year") {
        scope.isLoadingControls = true;
        scope.currentYearPage = 1;
        scope.colorTrademarksYearData = [];
        scope.colorSelectedTrademark = ''
      
        legalTrendsDashboardRepository
          .getYearChartsDataByYear(scope.selectedItem.title, 'header')
          .then(function (data) {
            scope.yearHeaderData = data.header;
            scope.isLoadingControls = false; 
          });

          legalTrendsDashboardRepository
          .getYearChartsDataByYear(scope.selectedItem.title, 'chartData1')
          .then(function (data) {
            scope.colorTrademarksData = data.chartData1;
          });

          legalTrendsDashboardRepository
          .getYearChartsDataByYear(scope.selectedItem.title, 'chartData2')
          .then(function (data) {
            scope.colorFrequencyData = data.chartData2;
          });

          scope.loadYearChartData3();
      }

      scope.sortColumn = (item) => {
        scope.currentPage = 1;
        scope.colorCaseData = [];
        if (scope.viewMode === "color") { 
          if (item === 'date') {
            scope.selectedColorDashCharacter = 'YEAR';
            scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
          }
          if (item === 'color') {
            scope.selectedColorDashCharacter = 'COLOR';
            scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
          }
          if (item === 'product') {
            scope.selectedColorDashCharacter = 'PRODUCT';
            scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
          }
          if (item === 'brand') {
            scope.selectedColorDashCharacter = 'BRAND';
            scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
          }
          scope.loadColorChartData6();
        }
        if (scope.viewMode === "productOrService") { 
          if (item === 'date') {
            scope.productOrder = 'YEAR';
            scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
          }
          if (item === 'color') {
            scope.productOrder = 'COLOR';
            scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
          }
          if (item === 'product') {
            scope.productOrder = 'PRODUCT';
            scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
          }
          if (item === 'brand') {
            scope.productOrder = 'BRAND';
            scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
          }
          scope.loadProductChartData4();
        }
        if (scope.viewMode === "brand") { 
          if (item === 'date') {
            scope.selectedBrandDashCharacter = 'YEAR';
            scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
          }
          if (item === 'color') {
            scope.selectedBrandDashCharacter = 'COLOR';
            scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
          }
          if (item === 'product') {
            scope.selectedBrandDashCharacter = 'PRODUCT';
            scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
          }
          if (item === 'brand') {
            scope.selectedBrandDashCharacter = 'BRAND';
            scope.columnOrder = scope.columnOrder === 'desc' ? 'asc' : 'desc';
          }
          scope.loadBrandChartData2();
        }
      }

      scope.updateSelectedYear = function (year) {
        scope.selectedYear = year;
        if(scope.viewMode === "color") {
          legalTrendsDashboardRepository
          .getColorChartsDataByColorId(scope.selectedItem.id, 'chartData2', scope.selectedYear.id)
          .then(function (data) {
            scope.colorTrademarkData = data.chartData2;
          });
        } else if(scope.viewMode === "productOrService") {
          legalTrendsDashboardRepository
          .getProductChartsDataByProduct(scope.selectedItem.title, 'chartData3', scope.selectedYear.id)
          .then(function (data) {
            scope.colorTrademarkData = data.chartData3;
          });
        }
      };

      scope.legalChartPopupAttributes = {
        modalTitle: ""
      };
  
      scope.toggleLegalChartPopup = (needShow, type) => {
        if (needShow) {
          if(type === 'chart1'){
            scope.legalChartPopupAttributes.modalTitle = 'OVERALL COLOR TRADEMARKS FOR ' + scope.selectedItem.title + ' BY COLOR FAMILY';
            scope.typeOfPopup = type;
          } else if(type === 'chart2'){
            scope.legalChartPopupAttributes.modalTitle = 'TOP 10 BRANDS OWNERS OF COLOR TRADEMARKS FOR ' + scope.selectedItem.title;
            scope.typeOfPopup = type;
          } else if(type === 'chart3'){
            scope.legalChartPopupAttributes.modalTitle = scope.selectedItem.title + ' TRADEMARKS GRANTED OVER THE LAST FIVE YEARS';
            scope.typeOfPopup = type;
          } else if(type === 'chart4'){
            scope.legalChartPopupAttributes.modalTitle = 'COLOR TRADEMARKS GRANTED TO ' + scope.selectedItem.title + ' BY COLOR FAMILY';
            scope.typeOfPopup = type;
          } else if(type === 'chart5'){
            scope.legalChartPopupAttributes.modalTitle = 'COLOR TRADEMARKS GRANTED BY COLOR FAMILY IN ' + scope.selectedItem.title;
            scope.typeOfPopup = type;
          } else if(type === 'chart6'){
            scope.legalChartPopupAttributes.modalTitle = 'COLOR TRADEMARKS GRANTED BY COLOR FAMILY IN ' + scope.selectedItem.title + ' VERSUS ALL YEARS';
            scope.typeOfPopup = type;
          } else if(type === 'chart7'){
            scope.legalChartPopupAttributes.modalTitle =  scope.selectedItem.family + ' TRADEMARKS VERSUS ALL OTHER COLOR TRADEMARKS';
            scope.typeOfPopup = type;
          } else if(type === 'chart8'){
            scope.legalChartPopupAttributes.modalTitle =  scope.selectedItem.family + ' TRADEMARKS GRANTED OVER THE LAST FIVE YEARS';
            scope.typeOfPopup = type;
          }
          scope.showLegalChartPopup = true;
        } else {
          scope.showLegalChartPopup = false;
        }
      };
  
      scope.$on("mood_state_chart", (data) => {
        let newParam = {}
        
        let node = angular.element("#legalPopupCapture")[0];
        domtoimage
        .toPng(node, {
          bgcolor: "#fff",
        })
        .then((dataUrl) => {
          if(dataUrl) {
            if(_.isEmpty(newParam)) {
                scope.saveImageDetailsData = {
                  base64: dataUrl,
                  type: 'legal'
                };
              } else {
                scope.saveImageDetailsData = {
                  base64: dataUrl,
                  type: 'legal',
                  data: newParam
                };
              }
            }
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
  
          let times = 2000;
          timeout(function () {
            if(scope.saveImageDetailsData) {
              scope.showLegalChartPopup = false;
              scope.toggleSaveImageDetails();
            } else {
              timeout(function () {
                if(scope.saveImageDetailsData) {
                  scope.showLegalChartPopup = false;
                  scope.toggleSaveImageDetails();
                }
              }, times);
            }
          }, times);
      });
  
      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
        result += name.toUpperCase() + ".";
        return result + date + type;
      };
  
      scope.toggleSaveImageDetails = () => {
        scope.saveImageDetails = scope.saveImageDetails ? false : true;
      };
  
      scope.$on("mood_state_chart_changed", () => {
        scope.toggleSaveImageDetails();
      });
  
      scope.$on("mood_state_item_chart_changed", () => {
        scope.toggleSaveImageDetails();
      });
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbLegalColorData/dbLegalColorDataView.html",
      link: link,
      scope: {
        item: "=",
        viewMode: "=",
        logo: "=",
        colorSelectionData: "=",
        moveBrandName: "=",
        yearList: "=",
      },
    };
  },
]);
