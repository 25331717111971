angular.module("app").service("legalTrendsDashboardRepository", [
  "appConfig",
  "authService",
  "httpService",
  function (appConfig, authService, httpService) {
    this.getSettings = () => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/legal/settings.json", authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getColorsList = () => {
      return httpService
          .get(`${appConfig.dashboardServiceUrl}api/legal/search/colors`, {
              params: { token: authService.token },
          }) .then((res) => {
            if (res) {
              return res.data;
            }
          });
    };

    this.getYearList = () => {
      return httpService
          .get(`${appConfig.dashboardServiceUrl}api/legal/search/years`, {
              params: { token: authService.token },
          }) .then((res) => {
            if (res) {
              return res.data;
            }
          });
    };

    this.getProductList = (str) => {
      str = str.split(' ').join('+');
      return httpService
          .get(`${appConfig.dashboardServiceUrl}api/legal/search/products?product=`+ str, {
              params: { token: authService.token },
          }) .then((res) => {
            if (res) {
              return res.data;
            }
          });
    };

    this.getBrandList = (str) => {
      str = str.split(' ').join('+');
      return httpService
          .get(`${appConfig.dashboardServiceUrl}api/legal/search/brands?brand=`+ str, {
              params: { token: authService.token },
          }) .then((res) => {
            if (res) {
              return res.data;
            }
          });
    };

    this.getLogoTrademarked = (type, params, page, per_page, letter) => {
      let url;
      if (type && type === "color"){
        url = `api/legal/dashboard?color_id=${params.id}&page=${page}&per_page=${per_page}`;
      } else if (type && type === 'productOrService') {
        url = `api/legal/dashboard?product=${params.title}&page=${page}&per_page=${per_page}&order_by=${letter.toLowerCase()}`;
      } else if (type && type === 'brand') {
        if (letter === 'ALL' || letter === 'YEAR') {
          url = `api/legal/dashboard?brand=${params.title}&page=${page}&per_page=${per_page}&order_by=${letter.toLowerCase()}`;
        } else {
          url = `api/legal/dashboard?brand=${params.title}&page=${page}&per_page=${per_page}&order_by=color&order_key=${letter}`;
        }
        
      } else if (type && type === 'year') {
        if (letter === 'ALL' || letter === 'COLOR') {
          url = `api/legal/dashboard?year=${params.title}&page=${page}&per_page=${per_page}&order_by=${letter.toLowerCase()}`;
        } else {
          url = `api/legal/dashboard?year=${params.title}&page=${page}&per_page=${per_page}&order_by=product&letter=${letter.toLowerCase()}`;
        }
      }
      return httpService.get(appConfig.dashboardServiceUrl + url,
        authService.getHeaderConfig()
      )
        .then(function (data) {
          return data && data.data ? data.data : data;
        });
    };

    // color color data API 
    this.getColorChartsDataByColorId = (id,node,year) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/legal/dashboard/colors?color_id=" + id,{
              params: { token: authService.token, node: node, year: year }})
          .then(function (data) {
            return (data.data);
          });
    };

    this.getColorChartData4ByColorId = (id,node,order) => { 
      let url;
      if (order === 'YEAR' || order === 'ALL') {
        url = `api/legal/dashboard/colors?color_id=${id}&node=${node}&order_by=${order.toLowerCase()}`;
      } else {
        url = `api/legal/dashboard/colors?color_id=${id}&node=${node}&order_by=brand&letter=${order.toLowerCase()}`;
      }
      return httpService.get(appConfig.dashboardServiceUrl + url,
        authService.getHeaderConfig()
      )
      .then(function (data) {
        return (data.data);
      });
    };

    this.getColorChartData6ByColorId = (id,node,page,per_page, order,sort) => { 
      let url;
      if (order === 'ALL' || order === 'YEAR' || order === 'COLOR' || order === 'PRODUCT' || order === 'BRAND') {
        url = `api/legal/dashboard/colors?color_id=${id}&node=${node}&page=${page}&per_page=${per_page}&order_by=${order.toLowerCase()}&sort=${sort}`;
      } else {
        url = `api/legal/dashboard/colors?color_id=${id}&node=${node}&page=${page}&per_page=${per_page}&order_by=product&letter=${order.toLowerCase()}`;
      }
      return httpService.get(appConfig.dashboardServiceUrl + url,
        authService.getHeaderConfig()
      )
      .then(function (data) {
        return (data.data);
      });
    };

    this.getYearListByColorId = (id,node) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/legal/dashboard/colors?color_id=" + id,{
              params: { token: authService.token, node: node }})
          .then(function (data) {
            return (data.data);
          });
    };

    // product color data API 
    this.getProductChartsDataByProduct = (id,node,year) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/legal/dashboard/products?product=" + id,{
              params: { token: authService.token, node: node, year: year }})
          .then(function (data) {
            return (data.data);
          });
    };

    this.getProductChartData4ByProduct = (id,node,page,per_page, order, sort) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/legal/dashboard/products?product=" + id,{
              params: { token: authService.token, node: node, page: page, per_page: per_page, order_by: order.toLowerCase(), sort: sort }})
          .then(function (data) {
            return (data.data);
          });
    };

    // brand color data API 
    this.getBrandChartsDataByBrand = (id,node) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/legal/dashboard/brands?brand=" + id,{
              params: { token: authService.token, node: node }})
          .then(function (data) {
            return (data.data);
          });
    };

    this.getBrandChartData2ByBrand = (id,node,page,per_page,order,sort) => { 
      let url;
      if (order === 'ALL' || order === 'YEAR' || order === 'COLOR' || order === 'PRODUCT' || order === 'BRAND') {
        url = `api/legal/dashboard/brands?brand=${id}&node=${node}&page=${page}&per_page=${per_page}&order_by=${order.toLowerCase()}&sort=${sort}`;
      } else {
        url = `api/legal/dashboard/brands?brand=${id}&node=${node}&page=${page}&per_page=${per_page}&order_by=color&order_key=${order}`;
      }
      return httpService.get(appConfig.dashboardServiceUrl + url,
        authService.getHeaderConfig()
      )
      .then(function (data) {
        return (data.data);
      });
    };

    // year color data API 
    this.getYearChartsDataByYear = (id,node) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/legal/dashboard/years?year=" + id,{
              params: { token: authService.token, node: node }})
          .then(function (data) {
            return (data.data);
          });
    };

    this.getYearChartsData3ByYear = (id,node,page,per_page,letter,order) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/legal/dashboard/years?year=" + id,{
              params: { token: authService.token, node: node, page: page, per_page: per_page, letter: letter.toLowerCase(), order_by: order }})
          .then(function (data) {
            return (data.data);
          });
    };

    this.getYearChartData3DetailsById = (id,node,color_id,brand) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/legal/dashboard/years?year=" + id,{
              params: { token: authService.token, node: node, color_id: color_id, brand: brand }})
          .then(function (data) {
            return (data.data);
          });
    };

  },
]);
