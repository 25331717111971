angular.module("app").component("passwordRecoverComponent", {
  templateUrl: "app/components/password-recover/password-recover.tmpl.html",
  controller: function ($state, $http, appConfig, $stateParams) {
    var self = this;
    this.successRequest = false;
    this.password = "";
    this.passwordConfirm = "";
    this.error = false;
    this.type = $stateParams.token[0] || "r";
    this.token = $stateParams.token;

    this.onSendLoginClick = function () {
      self.error = false;
      if (self.successRequest) {
        $state.go("login");
      }

      if (!self.password || !self.passwordConfirm) {
        self.error = "Password and Confirm Password fields are required";
      } else {
        if (self.password === self.passwordConfirm) {
          const params = {
            password: {
              new_password: self.password,
              password_confirmation: self.passwordConfirm,
              reset_password_token: self.token
            }
          };
          $http
            .put(appConfig.dashboardServiceUrl + "api/users/update_password_with_token", params)
            .then(function (res) {
              if (res.data) {
                if (res.data.success) {
                  self.successRequest = true;
                } else {
                  self.error = "The e-mail you provided does not exist in our data base. Please try again.";
                }
              }
            })
            .catch(function (err) {
              if (err) {
                self.error = err.data.errors;
              }
            });
        } else {
          self.error = "Passwords are not identical";
        }
      }
    };
  },
});
