angular.module("app").directive("sportColorCombinationBar", () => {
  let link = (scope) => {

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbSportColorCombinationBar/dbSportColorCombinationBarView.html",
    link: link,
    scope: {
      selectedColor: "=",
      data: "=",
    }
  };
});
