angular
.module('app')
.component('educationCoursesDetailsComponent', {
  templateUrl: 'app/components/education-courses-details/education-courses-details.tmpl.html',
  controller: function ($http, appConfig, $stateParams, $location, anchorSmoothScroll, localStorageService, $state, cartService, educationService, modalService) {
    const vm = this;

    vm.init = function () {
      educationService.getCourseDetails($stateParams.id).then(function (res) {
        if (res && res.data) {
          vm.pageData = res.data.course;
          vm.pageData.date = res.data.course.published_date;
          vm.pageData.image_url =  res.data.course.image_url && res.data.course.image_url;
          vm.pageData.price = res.data.course.price ? res.data.course.price : 0
        }
      });
    };

    vm.more = function () {
      vm.pageData.analitic = _.chunk(angular.copy(vm.pageData.analitics), 3);
    };

    vm.gotoElement = function (eID) {
      $location.hash('prefooter');
      anchorSmoothScroll.scrollTo(eID);
      $location.hash('');
    };

    vm.downloadExcerpt = function () {
      $state.go('download-excerpt', {type: 'courses', id: vm.pageData.id});
      localStorageService.set('link', vm.pageData.excerpts[0].url);
    };

    vm.addProduct = function () {
      const params = {
        user_cart: { 
          cartable_id: vm.pageData.id,
          description: vm.pageData.header,
          cartable_type: "Course",
          quantity: 1
        } 
      }

      cartService.createCart(params).then(function (res) {
        if(res.status === 200) {
          $state.go('cart-page', {wayBack: 'educationCourses'});
        }
      });
    };

    vm.openVideo = function () {
      window.parent.postMessage({
        action: "OpenVideoModal", data: {
            videoData: vm.pageData
        }
      })
    };

    vm.getUser = function () {
      return localStorageService.get('currentUser').id === undefined;
    };
  }
});
