angular.module("app").directive("designerUseColorAcrossSeasonsChart", () => {
  let link = (scope) => {

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/dbDesignerUseColorAcrossSeasonsChart/dbDesignerUseColorAcrossSeasonsChartView.html",
    link: link,
    scope: {
      data: "="
    }
  };
});
