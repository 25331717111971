angular.module("app").directive("fashionColorData", [
  "searchMenuRepository", "$timeout",
  (searchMenuRepository, timeout)=> {
    let link = (scope, element) => {  

      // scope.$on('updateYear', function(evt, data) {  
      //   scope.selectedYear = data;  
      // });

      scope.isLoadingControls = true; 
      scope.isLoadedAcrossAllCitiesData = true;
      // scope.colorHeaderData = {
      //   colorShadesYear: "34",
      //   currentYear: "2021",
      //   colorShadesDatabase: "34",
      //   highestRegion: "Paris",
      //   designerUsed: "12%",
      //   fashionColorPercentage: "14%",
      // };
      scope.shadesOfColor = [];
      scope.colorDataAcrossCategories =[];
      scope.colorDataAcrossSeasons = [];
      scope.comparativeViewAcrossFashions = [];
      scope.compartiveViewAcrossAllCities = [];
      scope.colorFashionComparedData = [];
      scope.designersUseOfColor = []; 
      scope.categoryHeaderData = {};
      scope.cityTopfourColorsAcrossSeasons = []
      scope.frequentColorDataAcrossCategories = {};
      scope.frequentColorsAcrossRegionsForSeason = [];
      scope.topFourColorsForCitiesInYear = [];
      scope.fiveYearColorDataAcrossDesigners = [];
      scope.fiveYearColorDataAcrossDesignersData = [];
      scope.seasonHeaderData = {};
      scope.topFourColorsForSeasonCategories = [];
      scope.designerHeaderData = {};
      scope.designerTopColors = [];
      scope.designerColorFrequencyData = {};
      scope.designerUseColorData = [];
      scope.designerColorCitiesData = [];
      scope.designerColorSeasonsData = []; 
      scope.topFourColorsAcrossAllShowsInDatabaseData = [];
      scope.regionHeaderData = {};
      scope.yearHeaderData = {};
      scope.cityOverallTopFourColorsData = {};
      scope.yearOverallColorDataAcrossSeasons =[];
      scope.yearColorFrequencyData =[];
      scope.colorCombinationsAcrossDesignersInYear=[];
      scope.imagecolordatabyyears=[];
      scope.categoryName = '';
      // scope.designerHeaderData = {
      //   colorCount: "7",
      //   shows: "2",
      //   databaseColors: "39",
      //   databaseShows: "29",
      //   designerUsed: "12%",
      //   color: "yellow",
      // };

      // scope.regionHeaderData = {
      //   colorCount: "7",
      //   shows: "2",
      //   databaseColors: "39",
      //   databaseShows: "29",
      //   designerUsed: "12%",
      //   color: "yellow",
      // };

      // scope.yearHeaderData = {
      //   colorCount: "7",
      //   shows: "22",
      //   designers: "39",
      //   cities: "29",
      //   color: "yellow",
      // };

      // scope.seasonHeaderData = {
      //   colorCount: "7",
      //   shows: "22",
      //   designers: "39",
      //   cities: "29",
      //   color: "yellow",
      // };

      // scope.categoryHeaderData = {
      //   colorCount: "7",
      //   shows: "22",
      //   designers: "39",
      //   cities: "29",
      //   color: "yellow",
      // };

      const now = new Date();

      scope.boardType = "fashion";
      scope.showMoreColors = false;
      scope.showAutoPopup = false;
      scope.showColorPopup = false;
      scope.colorsCount = 22;
      scope.topColorsExpanded = false;
      scope.colorFrequencyExpanded = false;

      scope.selectedItem = scope.item;
      scope.region = 'EUROPE';
      scope.colors = scope.colorSelectionData;
      scope.charts = scope.chartData;

      scope.showFashionChartPopup = false;

      scope.filteredResults = function (item) {
        searchMenuRepository.getFashionChartsDataTopFourColor(scope.searchId, scope.selectedYear.id, item, scope.viewMode)
        .then(data => {   
          scope.designersUseOfColor = data;
        });
      };

      scope.chooseColor = function (item, cate) {
        if(scope.viewMode === 'designer') {
          searchMenuRepository.getFashionCombinationColor(item.id, scope.searchId,  scope.selectedYear.id, 'chartData31')
          .then(data => {
            scope.colorFashionComparedData = data.chartData31.combine_colors;
            scope.colorSelectedCompareData = data.chartData31.color;
          });
        }
        if(scope.viewMode === "year"){
          scope.colorSelectedCompareCategoryData = cate;
          searchMenuRepository.getFashionCombinationYearColor(item.id,  scope.selectedYear.id, scope.colorSelectedCompareCategoryData, 'chartData35')
          .then(data => {
            scope.colorCombinationsAcrossDesignersInYear = data.chartData35.combine_colors;
            scope.colorSelectedCompareData = data.chartData35.color;
          });
        }
      };

      if (scope.viewMode === "color") { 
        scope.isLoadingControls = true;
        searchMenuRepository.getFashionChartsDataByColorNode(scope.searchId,scope.selectedYear.id,'color_data')
        .then(data => {   
          scope.colorHeaderData = data.color_data;
        });
        searchMenuRepository.getFashionChartsDataByColorNode(scope.searchId,scope.selectedYear.id,'chartData1')
        .then(data => {   
          scope.shadesOfColor = data.chartData1; 
        });
        searchMenuRepository.getFashionChartsDataByColorNode(scope.searchId,scope.selectedYear.id,'chartData2')
        .then(data => {   
          scope.colorDataAcrossCategories = data.chartData2;
        });
        searchMenuRepository.getFashionChartsDataByColorNode(scope.searchId,scope.selectedYear.id,'chartData3')
        .then(data => {
          scope.colorDataAcrossSeasons = data.chartData3;
        });
        searchMenuRepository.getFashionChartsDataByColorNode(scope.searchId,scope.selectedYear.id,'chartData4')
        .then(data => {   
          scope.comparativeViewAcrossFashions = data.chartData4;
        });
        searchMenuRepository.getFashionChartsDataByColorNode(scope.searchId,scope.selectedYear.id,'chartData5')
        .then(data => {   
          scope.totalAllCitiesData = data.chartData5; 
          scope.compartiveViewAcrossAllCities = scope.totalAllCitiesData;
          scope.moreAllCitiesData = [];
          scope.isLoadedAcrossAllCitiesData = true;
        });
        searchMenuRepository.getFashionChartsDataByColorNode(scope.searchId,scope.selectedYear.id,'chartData6')
        .then(data => {   
          scope.colorFashionComparedData = data.chartData6;
        });
        searchMenuRepository.getFashionChartsDataByColorNode(scope.searchId,scope.selectedYear.id,'chartData7')
        .then(data => {   
          scope.colorFashionComparedChartData = data.chartData7.combine_colors;
        });
        scope.isLoadingControls = false;
        searchMenuRepository.getFashionChartsDataTopFourColor(scope.searchId,scope.selectedYear.id, 'All', scope.viewMode)
        .then(data => {   
          scope.designersUseOfColor = data;
        });
      }
 
      if (scope.viewMode === "category") { 
        scope.isLoadingControls = true;
        searchMenuRepository.getFashionChartsDataByCategoryNode(scope.searchId,scope.selectedYear.id, 'color_data')
        .then(data => {   
           scope.categoryHeaderData = data.color_data; 
        });
        searchMenuRepository.getFashionChartsDataByCategoryNode(scope.searchId,scope.selectedYear.id,'chartData8')
        .then(data => {   
           scope.cityTopfourColorsAcrossSeasons =  data.chartData8;
        });
        searchMenuRepository.getFashionChartsDataByCategoryNode(scope.searchId,scope.selectedYear.id, 'chartData9')
        .then(data => {   
           scope.frequentColorDataAcrossCategories = data.chartData9;
        });
        searchMenuRepository.getFashionChartsDataByCategoryNode(scope.searchId,scope.selectedYear.id, 'chartData10')
        .then(data => {   
           scope.frequentColorsAcrossRegionsForSeason = data.chartData10; 
        });
        searchMenuRepository.getFashionChartsDataByCategoryNode(scope.searchId,scope.selectedYear.id, 'chartData11')
        .then(data => {   
           scope.topFourColorsForCitiesInYear = data.chartData11;
        });
        searchMenuRepository.getFashionChartsDataByCategoryNode(scope.searchId,scope.selectedYear.id,'chartData13')
        .then(data => {   
           scope.categoryName = data.name; 
           scope.fiveYearColorDataAcrossDesigners = data.chartData13;
           scope.fiveYearColorDataAcrossDesignersData = data.chartData13;
        });
        scope.isLoadingControls = false;
        searchMenuRepository.getFashionChartsDataTopFourColor(scope.searchId,scope.selectedYear.id, 'All', scope.viewMode)
        .then(data => {   
          scope.designersUseOfColor = data;
        });
    }

    if (scope.viewMode === "season") { 
      scope.isLoadingControls = true;
      searchMenuRepository.getFashionChartsDataBySeasonNode(scope.searchId,scope.selectedYear.id, 'color_data')
      .then(data => {    
        scope.seasonHeaderData = data.color_data;
      });
      searchMenuRepository.getFashionChartsDataBySeasonNode(scope.searchId,scope.selectedYear.id, 'chartData14')
      .then(data => {    
        scope.topFourColorsForSeasonCategories = data.chartData14;
      });
      searchMenuRepository.getFashionChartsDataBySeasonNode(scope.searchId,scope.selectedYear.id, 'chartData15')
      .then(data => {    
        scope.frequentColorDataAcrossCategories = data.chartData15;
      });
      searchMenuRepository.getFashionChartsDataBySeasonNode(scope.searchId,scope.selectedYear.id, 'chartData16')
      .then(data => {    
        scope.frequentColorsAcrossRegionsForSeason = data.chartData16; 
      });
      searchMenuRepository.getFashionChartsDataBySeasonNode(scope.searchId,scope.selectedYear.id, 'chartData17')
      .then(data => {    
        scope.topFourColorsForCitiesInYear = data.chartData17;
      });
      searchMenuRepository.getFashionChartsDataBySeasonNode(scope.searchId,scope.selectedYear.id, 'chartData19')
      .then(data => {    
        scope.fiveYearColorDataAcrossDesigners = data.chartData19;
        scope.fiveYearColorDataAcrossDesignersData = data.chartData19;
      });
      scope.isLoadingControls = false;
      searchMenuRepository.getFashionChartsDataTopFourColor(scope.searchId,scope.selectedYear.id, 'All', scope.viewMode)
      .then(data => {   
        scope.designersUseOfColor = data;
      });
    } 

    if (scope.viewMode === "designer") { 
      scope.isLoadingControls = true;
      searchMenuRepository.getFashionChartsDataByDesignerNode(scope.searchId,scope.selectedYear.id, 'color_data')
      .then(data => {    
        scope.designerHeaderData = data.color_data;
        searchMenuRepository.getFashionCombinationColor(scope.designerHeaderData.color.id, scope.searchId,  scope.selectedYear.id, 'chartData31')
        .then(data => {
          scope.colorFashionComparedData = data.chartData31.combine_colors;
          scope.colorSelectedCompareData = data.chartData31.color;
        });
      });
      searchMenuRepository.getFashionChartsDataByDesignerNode(scope.searchId,scope.selectedYear.id, 'chartData24')
      .then(data => {    
        scope.designerTopColors = data.chartData24;
      });
      searchMenuRepository.getFashionChartsDataByDesignerNode(scope.searchId,scope.selectedYear.id, 'chartData25')
      .then(data => {    
        scope.designerColorFrequencyData = data.chartData25;
      });
      searchMenuRepository.getFashionChartsDataByDesignerNode(scope.searchId,scope.selectedYear.id, 'chartData26')
      .then(data => {    
        scope.designerUseColorData = data.chartData26;
      });
      searchMenuRepository.getFashionChartsDataByDesignerNode(scope.searchId,scope.selectedYear.id, 'chartData27')
      .then(data => {    
        if(data.chartData27){
          data.chartData27.forEach(item => {
            if(item.colors.length > 0) {
              item.svg = [item.svg.slice(0, 4), ` width='60' height='60'`, item.svg.slice(4)].join(''),
              item.colors.forEach(ele => {
                ele.color = {
                  hex: ele.hex
                };
              });
              scope.designerColorCitiesData.push(item);
            }
          });
        }
      });
      searchMenuRepository.getFashionChartsDataByDesignerNode(scope.searchId,scope.selectedYear.id, 'chartData28')
      .then(data => {    
        scope.designerColorSeasonsData = data.chartData28;
      });
      searchMenuRepository.getFashionChartsDataByDesignerNode(scope.searchId,scope.selectedYear.id, 'chartData30')
      .then(data => {    
        scope.topFourColorsAcrossAllShowsInDatabaseData = data.chartData30;
      });
      scope.isLoadingControls = false;
    } 

    if (scope.viewMode === "region") { 
      scope.isLoadingControls = true;
      searchMenuRepository.getFashionChartsDataByRegionNode(scope.searchId,scope.selectedYear.id, 'color_data')
      .then(data => {     
        scope.regionHeaderData = data.color_data;
      });
      searchMenuRepository.getFashionChartsDataByRegionNode(scope.searchId,scope.selectedYear.id, 'cityColorFrequencyData')
      .then(data => {     
        scope.cityColorFrequencyData = data.cityColorFrequencyData.colorFrequencyData;
      });
      searchMenuRepository.getFashionChartsDataByRegionNode(scope.searchId,scope.selectedYear.id, 'regionColorFrequencyData')
      .then(data => {     
        scope.regionColorFrequencyData = data.regionColorFrequencyData.colorFrequencyData;
      });
      searchMenuRepository.getFashionChartsDataByRegionNode(scope.searchId,scope.selectedYear.id, 'chartData20')
      .then(data => {     
        scope.cityOverallTopFourColorsData = data.chartData20;
      });
      searchMenuRepository.getFashionChartsDataByRegionNode(scope.searchId,scope.selectedYear.id, 'chartData21')
      .then(data => {     
        scope.cityTopfourColorsAcrossSeasons = data.chartData21;
      });
      searchMenuRepository.getFashionChartsDataByRegionNode(scope.searchId,scope.selectedYear.id, 'chartData22')
      .then(data => {     
        scope.designerUseColorData = data.chartData22;
      });
      scope.isLoadingControls = false;
      searchMenuRepository.getFashionChartsDataTopFourColor(scope.searchId,scope.selectedYear.id, 'All', scope.viewMode)
      .then(data => {   
        scope.designersUseOfColor = data;
      });
    }  
    
    if (scope.viewMode === "year") { 
      scope.isLoadingControls = true;
      searchMenuRepository.getFashionChartsDataByYearNode(scope.searchId, 'color_data')
      .then(data => {     
        scope.yearHeaderData = data.color_data; 
        scope.colorSelectedCompareCategoryData = 'All'
        searchMenuRepository.getFashionCombinationYearColor(scope.yearHeaderData.color.id,  scope.selectedYear.id, scope.colorSelectedCompareCategoryData, 'chartData35')
        .then(data => {
          scope.colorCombinationsAcrossDesignersInYear = data.chartData35.combine_colors;
          scope.colorSelectedCompareData = data.chartData35.color;
        });
      });
      searchMenuRepository.getFashionChartsDataByYearNode(scope.searchId, 'chartData31')
      .then(data => {     
        scope.designerTopColors = data.chartData31;
      });
      searchMenuRepository.getFashionChartsDataByYearNode(scope.searchId, 'chartData32')
      .then(data => {     
        scope.yearOverallColorDataAcrossSeasons = data.chartData32;
      });
      searchMenuRepository.getFashionChartsDataByYearNode(scope.searchId, 'chartData33')
      .then(data => {     
        scope.yearColorFrequencyData = data.chartData33;
      });
      searchMenuRepository.getFashionChartsDataByYearNode(scope.searchId, 'chartData34')
      .then(data => {     
        scope.frequentColorDataAcrossCategories = data.chartData34;
      });
      
      searchMenuRepository.getFashionChartsDataByYearNode(scope.searchId, 'chartData36')
      .then(data => {     
        scope.fiveYearColorDataAcrossDesigners = data.chartData36;
        scope.fiveYearColorDataAcrossDesignersData = data.chartData36;
      });
      searchMenuRepository.getFashionChartsDataByYearNode(scope.searchId, 'chartData37')
      .then(data => {     
        scope.topFourColorsForCitiesInYear = data.chartData37;
      });
      searchMenuRepository.getFashionChartsDataByYearNode(scope.searchId, 'chartData39')
      .then(data => {     
        scope.imagecolordatabyyears = data.chartData39; 
      });
      scope.isLoadingControls = false;
      searchMenuRepository.getFashionChartsDataTopFourColor(scope.searchId,scope.selectedYear.id, 'All', scope.viewMode)
      .then(data => {   
        scope.designersUseOfColor = data;
      });
    }                                                                                                                                                                                                                                                                                                                                  


      scope.isModeCategory = () => {
        return scope.viewMode === "category";
      };

      scope.isModeSeason = () => {
        return scope.viewMode === "season";
      };

      scope.isModeRegion = () => {
        return scope.viewMode === "region";
      };

      scope.isModeColor = () => {
        return scope.viewMode === "color";
      };

      scope.isModeDesigner = () => {
        return scope.viewMode === "designer";
      };

      scope.isModeYear = () => {
        return scope.viewMode === "year";
      };

      scope.setTitle = (title) => {
        if(title === 'pre_fall'){
          return 'pre fall'
        } else {
          return title;
        }
      }

      scope.fashionChartPopupAttributes = {
        modalTitle: ""
      };

      scope.toggleFashionChartPopup = (needShow, type) => {
        if (needShow) {
          if(type === 'chart1'){
            scope.fashionChartPopupAttributes.modalTitle = 'RELATIVE SHADES OF ' + scope.selectedItem.name + ' SHADES COMPARE TO ALL ' + scope.selectedItem.name + ' IN ' + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart2'){
            scope.fashionChartPopupAttributes.modalTitle = 'USE OF ' + scope.selectedItem.name + ' ACROSS THE DIFFERENT FASHION CATEGORIES FOR ' + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart3'){
            scope.fashionChartPopupAttributes.modalTitle = 'USE OF ' + scope.selectedItem.name + ' ACROSS DIFFERENT SEASONS FOR ' + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart4'){
            scope.fashionChartPopupAttributes.modalTitle = 'USE OF ' + scope.selectedItem.name + ' ACROSS DIFFERENT SEASONS FOR ' + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart5'){
            scope.fashionChartPopupAttributes.modalTitle = 'POPULAR COLOR COMBINATIONS FOR ' + scope.selectedItem.name + ' FOR ' + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart6'){
            scope.fashionChartPopupAttributes.modalTitle = 'TOP FOUR COLORS FOR ' + scope.selectedItem.name + ' CATEGORIES FOR ' + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart7'){
            scope.fashionChartPopupAttributes.modalTitle = 'FREQUENT USE OF COLORS ACROSS ALL ' + scope.selectedItem.name + ' CATEGORIES FOR ' + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart8'){
            scope.fashionChartPopupAttributes.modalTitle = 'FREQUENT USE OF COLORS ACROSS ALL REGIONS FOR ' + scope.selectedItem.name + ' ' + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart9'){
            scope.fashionChartPopupAttributes.modalTitle = 'TOP FOUR COLORS FOR ' + scope.selectedItem.name + ' ' + scope.currentYear + ' CATEGORIES';
            scope.typeOfPopup = type;
          } else if(type === 'chart10'){
            scope.fashionChartPopupAttributes.modalTitle = 'FREQUENT USE OF COLORS ACROSS CATEGORIES FOR ' + scope.selectedItem.name + ' ' + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart11'){
            scope.fashionChartPopupAttributes.modalTitle = 'FREQUENT USE OF COLORS ACROSS ALL REGIONS FOR ' + scope.selectedItem.name + ' ' + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart12'){
            scope.fashionChartPopupAttributes.modalTitle = scope.selectedItem.name + ' OVERALL TOP FOUR COLORS FOR ' + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart13'){
            scope.fashionChartPopupAttributes.modalTitle = scope.selectedItem.name + ' VERSUS ' + scope.region + 'OVERALL USE OF COLOR ACROSS THE BASIC COLOR FAMILIES FOR ' + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart14'){
            scope.fashionChartPopupAttributes.modalTitle = scope.selectedItem.name + ' TOP FOUR COLORS ACROSS ' + scope.currentYear + ' SEASONS';
            scope.typeOfPopup = type;
          } else if(type === 'chart15'){
            scope.fashionChartPopupAttributes.modalTitle = scope.selectedItem.name + ' TOP FOUR COLORS ACROSS ' + scope.currentYear + ' CATEGORIES';
            scope.typeOfPopup = type;
          } else if(type === 'chart16'){
            scope.fashionChartPopupAttributes.modalTitle = `${scope.selectedItem.name}'S TOP FOUR COLORS FOR ` + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart17'){
            scope.fashionChartPopupAttributes.modalTitle = `${scope.selectedItem.name}'S USE OF COLORS ACROSS CATEGORIES FOR ` + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart18'){
            scope.fashionChartPopupAttributes.modalTitle = `${scope.selectedItem.name}'S USE OF COLORS ACROSS CITIES FOR ` + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart19'){
            scope.fashionChartPopupAttributes.modalTitle = `${scope.selectedItem.name}'S USE OF COLORS ACROSS SEASONS FOR ` + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart20'){
            scope.fashionChartPopupAttributes.modalTitle = `${scope.selectedItem.name}'S FREQUENT COLOR COMBINATION FOR ` + scope.currentYear;
            scope.typeOfPopup = type;
          } else if(type === 'chart21'){
            scope.fashionChartPopupAttributes.modalTitle = `OVERALL TOP FOUR COLORS FOR ${scope.selectedItem.name}`;
            scope.typeOfPopup = type;
          } else if(type === 'chart22'){
            scope.fashionChartPopupAttributes.modalTitle = `TOP FOUR COLORS ACROSS ALL SEASONS OF ${scope.selectedItem.name}`;
            scope.typeOfPopup = type;
          } else if(type === 'chart23'){
            scope.fashionChartPopupAttributes.modalTitle = `ALL THE COLORS OF ${scope.selectedItem.name} AND THEIR FREQUENCIES`;
            scope.typeOfPopup = type;
          } else if(type === 'chart24'){
            scope.fashionChartPopupAttributes.modalTitle = `FREQUENT USE OF COLORS ACROSS CATEGORIES FOR ${scope.selectedItem.name}`;
            scope.typeOfPopup = type;
          } else if(type === 'chart25'){
            scope.fashionChartPopupAttributes.modalTitle = `FIVE YEARS COMPARISON OF COLOR USE ACROSS ALL DESIGNERS FOR ${scope.selectedItem.name}`;
            scope.typeOfPopup = type;
          } else if(type === 'chart26'){
            scope.fashionChartPopupAttributes.modalTitle = `${scope.selectedItem.name}'S COLOR FREQUENCY`;
            scope.typeOfPopup = type;
          } else if(type === 'chart27'){
            scope.fashionChartPopupAttributes.modalTitle = `COLOR COMBINATIONS ACROSS ALL DESIGNERS IN ${scope.selectedItem.name}`;
            scope.typeOfPopup = type;
          }
          scope.showFashionChartPopup = true;
        } else {
          scope.showFashionChartPopup = false;
        }
      };

      scope.$on("mood_state_chart", (data) => {
        let node = angular.element("#fashionPopupCapture")[0];
        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            if(dataUrl) {
              scope.saveImageDetailsData = {
                base64: dataUrl,
                type: 'fashion'
              };
            }
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });

          let times = 2000;
          timeout(function () {
            if(scope.saveImageDetailsData) {
              scope.showFashionChartPopup = false;
              scope.toggleSaveImageDetails();
            } else {
              timeout(function () {
                if(scope.saveImageDetailsData) {
                  scope.showFashionChartPopup = false;
                  scope.toggleSaveImageDetails();
                } else {
                  timeout(function () {
                    if(scope.saveImageDetailsData) {
                      scope.showFashionChartPopup = false;
                      scope.toggleSaveImageDetails();
                    }
                  }, times);
                }
              }, times);
            }
          }, times);
      });

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
        result += name.toUpperCase() + ".";
        return result + date + type;
      };

      scope.toggleSaveImageDetails = () => {
        scope.saveImageDetails = scope.saveImageDetails ? false : true;
      };

      scope.$on("mood_state_chart_changed", () => {
        scope.toggleSaveImageDetails();
      });
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbFashionColorData/dbFashionColorDataView.html",
      link: link,
      scope: {
        item: "=",
        viewMode: "=",
        colorSelectionData: "=",
        currentYear: "=",
        chartData: "=",
        searchId: "=",
        selectedYear: '=',
      },
    };
  },
]);
