angular.module("app").component("studentSingleSignOnComponent", {
  templateUrl: "app/components/student-single-sign-on/student-single-sign-on.tmpl.html",
  controller: function (authService, $state, localStorageService, $http, appConfig, dataValidate, $stateParams) {
    const self = this;
    self.serverError = "";

    self.sso = {
      school_id: {
        value: "",
        required: true,
        name: "school name",
        type: "select",
      }
    };

    self.init = function () {
      self.success = $stateParams.success;
      self.token = $stateParams.token;
      if(self.success === 't') {
        authService
          .schoolSSOLoginUser(self.token)
          .then(function (res) {
            if (res && res.success === true) {
              $state.go("landing-page");
            } else {
              self.reg_error = true;
              self.reg_error_msg = 'PLEASE CORRECT THE ERRORS BELOW';
            }
          })
          .catch((error) => {
            if (error.status === 422) {
              self.serverError = Object.keys(error.data.errors).map((key) => {
                return `Field ${key} ${error.data.errors[key].join(" ")}`;
              });
            }
          });
      } else if(self.success === 'f') {
        self.reg_error = true;
        self.reg_error_msg = 'User is not authorized to this selected school.';
      }
      self.getSchools();
    };

    self.login = () => {
      self.reg_error = false;
      if (dataValidate.validate(self.sso)) {
        const data = {};

        for (item in this.sso) {
          if (self.sso[item].type === "select") {
            data[item] = self.sso[item].value.id;
          } else {
            data[item] = self.sso[item].value;
          }          
        }
        if (!self.reg_error) {
          authService
          .schoolSSOLogin(data)
          .then(function (res) {
            window.location.href = res.url;
            })
            .catch((error) => {
              if (error.status === 422) {
                self.serverError = Object.keys(error.data.errors).map((key) => {
                  return `Field ${key} ${error.data.errors[key].join(" ")}`;
                });
              }
            });
        }
      }
    };

    self.getSchools = () => {
      $http
          .get(appConfig.dashboardServiceUrl + "api/enterprise/schools/saml")
          .then((res) => {
            self.schoolList = res.data.schools;
          })
          .catch((error) => {
            if (error.status === 422) {
              self.serverError = Object.keys(error.data.errors).map((key) => {
                return `Field ${key} ${error.data.errors[key].join(" ")}`;
              });
            }
          });
    }
  },
});
