angular.module("app").directive("colorTherapyChart", () => {
  let link = (scope) => {
    scope.dotsPerline = [1, 2, 3, 4, 5];
    scope.dotLines = [];

    const rowCnt = Math.ceil(scope.item.percentage / 100 * 25);
    for (let i = 0; i < rowCnt; i++) {
      scope.dotLines.push(i);
    }
  };

  return {
    restrict: "E",
    templateUrl: "app/directives/colorTherapyChart/colorTherapyChartView.html",
    link: link,
    scope: {
      item: "=",
      hex: "="
    }
  };
});
