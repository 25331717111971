angular.module('app').directive('hueDbHeaderDescription', ['$timeout', (timeout) => {
  let link = (scope, element, attrs) => {
    scope.textOverflow = false;

    const getDescriptionLineHeight = () => {
      const t = element.find('.description-text');
      const lh = t.css('line-height');

      if (lh.indexOf('.'))
        return lh.substring(0, lh.indexOf('.'));
      return lh.substring(0, lh.indexOf('p'));
    };

    const descriptionTextElem = element.find('.description-text');
    let isExpanded = false;

    const checkOverflow = () => {
      scope.textOverflow = descriptionTextElem.height() > 75; //if more than 4 lines
    };

    const windowResizeHandler = _.debounce(() => {
      timeout(checkOverflow, 10);
    }, 200);

    scope.isShowMoreVisible = () => {
      return scope.textOverflow && !isExpanded;
    };

    scope.isShowLessVisible = () => {
      return scope.textOverflow && isExpanded;
    };

    scope.showMoreClick = () => {
      isExpanded = true;
      scope.headerHeight = 100 + descriptionTextElem.height() - 55; //55 - three lines which are visible always
    };

    scope.showLessClick = () => {
      isExpanded = false;
      scope.headerHeight = null;
    };

    $(window).resize(windowResizeHandler);

    scope.$watch('text', (newValue) => {
      if (newValue)
        timeout(checkOverflow, 10);
    });

    scope.$on('$destroy', () => {
      $(window).off('resize', windowResizeHandler);
    });
  }

  return {
    restrict: 'E',
    template: '<div class="description-container" ng-class="{\'three-lines\': textOverflow}"><div class="description-text" ng-bind="text"></div></div><div class="show-button" ng-show="isShowMoreVisible()" ng-click="showMoreClick()">Show More</div><div class="show-button" ng-show="isShowLessVisible()" ng-click="showLessClick()">Show Less</div>',
    link: link,
    scope: {
      text: '=',
      headerHeight: '='
    }
  };
}]);
