angular.module("app").directive("topFiveIndustryLogo", () => {
  let link = (scope) => {

  };

  return {
    restrict: "E",
    templateUrl: "app/directives/custom-infographics-brand-directives/topFiveIndustryLogo/topFiveIndustryLogoView.html",
    link: link,
    scope: {
      data: "="
    }
  };
});
