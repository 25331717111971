angular.module('app').directive('sportComplete', [
  () => {
    let link = (scope) => {
      // dummy data
      scope.filteredChoices = [
        {
          "id": 0,
          "title": "Baseball",
          "img_url": "/assets/img/sports/types/baseball.png"
        },
        {
          "id": 1,
          "title": "Basketball",
          "img_url": "/assets/img/sports/types/basketball.png"
        },
        {
          "id": 2,
          "title": "Football",
          "img_url": "/assets/img/sports/types/football.png"
        },
        {
          "id": 3,
          "title": "Hockey",
          "img_url": "/assets/img/sports/types/hockey.png"
        },
        {
          "id": 4,
          "title": "Soccer",
          "img_url": "/assets/img/sports/types/soccer.png"
        },
        {
          "id": 5,
          "title": "Lacrosse",
          "img_url": "/assets/img/sports/types/lacrosse.png"
        },
        {
          "id": 6,
          "title": "Rugby",
          "img_url": "/assets/img/sports/types/rugby.png"
        },
      ];

      scope.isVisible = {
        suggestions: false,
      };

      scope.filterItems = () => {
        if (scope.enteredtext.length < 0) return;
        scope.isVisible.suggestions =
          scope.enteredtext.length === 0
            ? false
            : scope.filteredChoices.length > 0;
      };

      scope.selectItem = (choice) => {
        scope.selected = choice;
        scope.enteredtext = choice.title;
        scope.isVisible.suggestions = false;
        scope.selecthandle(scope.selecttype, choice);
      };
    };

    return {
      restrict: "E",
      templateUrl: "app/directives/dbSportComplete/dbSportCompleteView.html",
      link: link,
      scope: {
        enteredtext: "=",
        selected: "=",
        placeholder: "=",
        selecthandle: "=",
        selecttype: "=",
        disabled: "=",
        disable: "=",
        board: "=",
      }
    };
  }
]);
