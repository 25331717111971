angular.module('app').service('mainMenuRepository', [
  'httpService',
  function (httpService, appConfig, authService) {
    this.getHueProducts = function (callback) {
      httpService.get(appConfig.webServiceUrl + 'products.jsonp', {
        params: {
          token: authService.token
        }
      }).then(callback);
    };
  }
]);
