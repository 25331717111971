angular.module('app').directive('hueDbSelectedColorFrequency', function () {
  function link(scope, element, attrs) {
    scope.expandedGroupClass = null;
    scope.titleText = 'Used';
    scope.setExpandedGroupClass = function (groupClass) {
      scope.expandedGroupClass = groupClass;
    };
    scope.getClass = function () {
      var result = [];
      if (scope.expandedGroupClass)
        result.push(scope.expandedGroupClass);

      return result.join(' ');
    };
    scope.$watch('research', function (research) {
      if (research) {
        scope.titleText = 'Volume';
      } else {
        scope.titleText = 'Used';
      }
    }, true);
  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/dbSelectedColorFrequency/dbSelectedColorFrequencyView.html',
    link: link,
    scope: {
      colorData: '=',
      percentage: '=',
      research: '=',
    }
  };
});
