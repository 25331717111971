angular.module("app").service("gameDashboardRepository", [
  "appConfig",
  "authService",
  "httpService",
  function (appConfig, authService, httpService) {

    this.getColors = () => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/video/search/colors", authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getGenres = () => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/video/search/genres", authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getCreatorList = (str) => {
      return httpService.get(
        appConfig.dashboardServiceUrl + `api/video/search/creators?letter=${str}`,
        authService.getHeaderConfig()
      ).then((res) => {
        if (res) {
          return res.data;
        }
      });
    };

    this.getYears = () => {
      return httpService
        .get(appConfig.dashboardServiceUrl + "api/video/search/years", authService.getHeaderConfig())
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    this.getVideoDashboard = (field, id, page, per_page, year) => {
      let queryField = '';
      if (field === 'color') {
        queryField = 'color_id';
      } else if (field === 'genre') {
        queryField = 'genre';
      } else if (field === 'creator') {
        queryField = 'creator';
      } else if (field === 'year') {
        queryField = 'year';
      } 

      return httpService
        .get(
          appConfig.dashboardServiceUrl +
            `api/video/dashboard?${queryField}=${id}&page=${page}&per_page=${per_page}&year=${year}`,
          authService.getHeaderConfig()
        )
        .then((res) => {
          if (res) {
            return res.data;
          }
        });
    };

    // color color data API 
    this.getColorChartsDataByColorId = (id,node, year) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/video/dashboard/colors?color_id=" + id,{
              params: { token: authService.token, node: node, year: year }})
          .then(function (data) {
            return (data.data);
          });
    };

    // genre color data API 
    this.getGenreChartsDataByGenreId = (id,node, year) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/video/dashboard/genres?genre_id=" + id,{
              params: { token: authService.token, node: node, year:year }})
          .then(function (data) {
            return (data.data);
          });
    };

    this.getGenreChartsData3ByGenreId = (id, node, year, letter, page, per_page) => { 
      return httpService.get(appConfig.dashboardServiceUrl + `api/video/dashboard/genres?genre_id=${id}&creator[letter]=${letter}`,{
              params: { token: authService.token, node: node, year:year, page:page, per_page:per_page }})
          .then(function (data) {
            return (data.data);
          });
    };

    this.getGenreChartsData4ByGenreId = (id, node, year, creator) => { 
      return httpService.get(appConfig.dashboardServiceUrl + `api/video/dashboard/genres?genre_id=${id}`,{
              params: { token: authService.token, node: node, year:year, creator: creator}})
          .then(function (data) {
            return (data.data);
          });
    };

    // creator color data API 
    this.getCreatorChartsDataByCreator = (id,node, year) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/video/dashboard/creators?creator=" + id,{
              params: { token: authService.token, node: node, year:year }})
          .then(function (data) {
            return (data.data);
          });
    };

    this.getCreatorChartsData3ByCreator = (id,node, year, page, per_page) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/video/dashboard/creators?creator=" + id,{
              params: { token: authService.token, node: node, year:year, per_page: per_page, page: page }})
          .then(function (data) {
            return (data.data);
          });
    };

    // year color data API 
    this.getYearChartsDataByYearId = (id,node) => { 
      return httpService.get(appConfig.dashboardServiceUrl + "api/video/dashboard/years?year=" + id,{
              params: { token: authService.token, node: node }})
          .then(function (data) {
            return (data.data);
          });
    };

    this.getYearChartsData5ByYearId = (id, node, letter, page, per_page) => { 
      return httpService.get(appConfig.dashboardServiceUrl + `api/video/dashboard/years?year=${id}&creator[letter]=${letter}`,{
              params: { token: authService.token, node: node, per_page: per_page, page: page }})
          .then(function (data) {
            return (data.data);
          });
    };

    this.getYearChartsData6ByYearId = (id, node, creator) => { 
      return httpService.get(appConfig.dashboardServiceUrl + `api/video/dashboard/years?year=${id}`,{
              params: { token: authService.token, node: node, creator: creator}})
          .then(function (data) {
            return (data.data);
          });
    };
  },
]);
