angular.module('app').directive('infographicsForNature', [
  '$location',
  '$timeout',
  'customInfographicsConstants',
  function (
    $location,
    timeout,
    customInfographicsConstants
  ) {
    function link(scope) {
      scope.currentYear = moment().year();
      scope.meta = {};
      scope.isFilterVisible = customInfographicsConstants.isFilterVisible;

      scope.birdColorDataAcrossRegions =
          customInfographicsConstants.birdColorDataAcrossRegions;
      scope.flowerLifespanData =
          customInfographicsConstants.flowerLifespanData;

      scope.chartsForNature = [
        {
          qNumber: 'NATURE1',
          title: 'COLOR FAMILY DISTRIBUTION ACROSS ALL FLOWER FAMILIES',
          id: 'colorDistributionAcrossFlowers'
        },
        {
          qNumber: 'NATURE2',
          title: 'FLOWER LIFESPAN ACROSS COLOR FAMILIES',
          id: 'flowerLifespanAcrossColorFamilies'
        },
        {
          qNumber: 'NATURE3',
          title: 'COLOR FAMILY DISTRIBUTION ACROSS ALL BIRD FAMILIES',
          id: 'colorDistributionAcrossBirds'
        },
        {
          qNumber: 'NATURE4',
          title: 'BIRDS COLOR FAMILIES IN 6 REGIONS',
          id: 'birdsColorsIn6Regions'
        }
      ];

      scope.currentChartForNature = scope.chartsForNature[0];

      scope.updateChart = function (chart) {
        scope.currentChartForNature = chart;
      };

      scope.refresh = () => {};

      scope.generateImgName = function (type, board, name) {
        let result = "";
        const date = moment().format("L");
          result += name.toUpperCase() + ".";

        return result + date + type;
      };

      scope.exportJpgThroughDomToImage = (name) => {
        let node = angular.element("#natureCapture")[0];
        
        const titles = angular.element(".graphic-titles");
        const header = angular.element(".graphic-titles-export");
        const footer = angular.element(".customized-infographics-footer");

        footer.css({ display: "block" });
        header.css({ display: "block" });
        titles.css({ display: "none" });
        $("html").addClass("hide-scrollbar");

        domtoimage
          .toPng(node, {
            bgcolor: "#fff",
          })
          .then((dataUrl) => {
            const img = new Image();
            img.src = dataUrl;

            const link = document.createElement("a");
            link.href = img.src;
            link.download = scope.generateImgName(".png", "brand", name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            $("html").removeClass("hide-scrollbar");
            footer.css({ display: "none" });
            header.css({ display: "none" });
            titles.css({ display: "block" });
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      };
    }

    return {
      restrict: 'E',
      templateUrl:
        'app/components/customized-infographics/infographicsForNature/infographicsForNature.html',
      link: link,
      scope: {
      }
    };
  }
]);
