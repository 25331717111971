angular.module("app").controller("newLegalController", [
  "$scope",
  "dashboardOverlayService",
  "searchMenuRepository",
  "legalTrendsDashboardRepository",
  "$timeout",
  "charts",
  "$state",
  "$location",
  "modalService",
  "localStorageService",
  function (
    scope,
    dashboardOverlayService,
    searchMenuRepository,
    legalTrendsDashboardRepository,
    timeout,
    charts,
    $state,
    $location,
    modalService,
    localStorageService
  ) {
    scope.menus = {
      productOrService: "",
      brand: "",
      year: "",
      color: "",
    };

    scope.disabledControls = {
      productOrService: false,
      brand: false,
      year: false,
      color: false,
    };

    scope.type = {
      productOrService: "productOrService",
      brand: "brand",
      year: "year",
      color: "color",
    };

    if(localStorageService.get("currentUser")){
      scope.user = localStorageService.get("currentUser");
    }

    scope.params = $location.search();

    scope.$watch('params', function (params) {
      if (params.control === 'searchMenu') {
        scope.init();
      } else {
        scope.handleChangeControl(params.control, params);
      }
    });

    scope.disabledInput = (type, value) => {
      switch (type) {
        case "productOrService":
          scope.disableBrand = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "brand":
          scope.disableProductOrService = Boolean(value);
          scope.disableYear = Boolean(value);
          break;
        case "year":
          scope.disableProductOrService = Boolean(value);
          scope.disableBrand = Boolean(value);
          break;
      }
    };

    scope.mainParam = null;
    scope.mainParamId = null;
    scope.secondaryParams = {};
    scope.tab = 1;
    scope.currentPage = 0;

    scope.product_service_place_holder = "SEARCH BY PRODUCT OR SERVICE";
    scope.brand_place_holder = "SEARCH BY BRAND";
    scope.year_place_holder = "SEARCH BY YEAR";

    scope.showDashboard = false;
    scope.title = scope.year;
    scope.subtitle = null;

    scope.productList = [];
    scope.brandList = [];
    scope.colorTrademarks = [];

    scope.isLoadingControls = true;

    // product dashboard
    scope.productDashCharacters = [
      "YEAR", "COLOR"
    ];

    scope.selectedProductDashCharacter = "COLOR";

    scope.productDashCharacterSelect = (value) => {
      scope.selectedProductDashCharacter = value;
      scope.currentPage = 0;
      scope.colorTrademarks = [];
      scope.loadGraphics();
    };

    scope.isProductDashSelected = (value) => {
      return scope.selectedProductDashCharacter === value;
    };

    // brand dashboard

    scope.selectedBrandDashCharacter = "ALL";

    scope.brandDashCharacterSelect = (value, type) => {
      if(type) {
        scope.selectedBrandDashCharacter = value.id;
      } else {
        scope.selectedBrandDashCharacter = value;
      }
      scope.currentPage = 0;
      scope.colorTrademarks = [];
      scope.loadGraphics();
    };

    scope.isBrandDashSelected = (value, type) => {
      if(type) {
        return scope.selectedBrandDashCharacter === value.id;
      } else {
        return scope.selectedBrandDashCharacter === value;
      }
    };

    // year dashboard
    scope.characters = [
      "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
      "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
    ];

    scope.selectedCharacter = "COLOR";

    scope.characterSelect = (value) => {
      scope.selectedCharacter = value;
      scope.currentPage = 0;
      scope.colorTrademarks = [];
      scope.loadGraphics();
    };

    scope.isSelected = (value) => {
      return scope.selectedCharacter === value;
    };

    // get colors for filter
    legalTrendsDashboardRepository.getColorsList().then((data) => {
      scope.colors = data.colors;
    });

    legalTrendsDashboardRepository.getYearList().then(data => {
      if (data.years) {
        scope.yearList = data.years.map(item => {
          return { title: item, id: item };
        });
        scope.yearList.sort((a, b) => (a.id < b.id) ? 1 : -1);
      }
    })

    scope.setback = () => {
      $state.go("legal-trends", {control: "searchMenu", id: "", title: "", hex: "", family: ""});
    };

    scope.init = () => {
      scope.menus = {
        productOrService: "",
        brand: "",
        year: "",
        color: "",
      };
      scope.disableProductOrService = false;
      scope.disableBrand = false;
      scope.disableYear = false;
      scope.isLoadingControls = true;
      scope.currentPage = 0;
      scope.mainSeason = [];
      scope.tab = 1;
    };

    scope.setTab = (tabId) => {
      scope.tab = tabId;
      scope.select_images = false;
      scope.selected_moodboard = false;
      scope.state = 1;
    };

    scope.isSet = (tabId) => {
      return scope.tab === tabId;
    };

    scope.getResultsOnFilterSearch = (type, str, page) => {
      scope.ajaxInProgress = true;  
      switch (type) {
        case 'productOrService':
          if (!str.length) {
            scope.productList = [];
            scope.ajaxInProgress = false;
            return;
          }
          //new logic for pagination
          if(scope.oldsearchdata === ""){
            scope.oldsearchdata = str;
            scope.selectedpage = 1;
          } 
          else if(scope.oldsearchdata !== str){  
              page = 1;
              scope.selectedpage = page;
              scope.productList = [];
              scope.oldsearchdata = str;
          } 
          else{
            scope.selectedpage += 1;
            page = scope.selectedpage;
          }

          legalTrendsDashboardRepository.getProductList(str)
            .then(data => {
              scope.productList = [];
              if(data.products.length>0){  
                scope.productList = data.products;
              }   
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break; 

          case 'brand':
          if (!str.length) {
            scope.brandList = [];
            scope.ajaxInProgress = false;
            return;
          }
          //new logic for pagination
          if(scope.oldsearchdata === ""){
            scope.oldsearchdata = str;
            scope.selectedpage = 1;
          } 
          else if(scope.oldsearchdata !== str){  
              page = 1;
              scope.selectedpage = page;
              scope.brandList = [];
              scope.oldsearchdata = str;
          } 
          else{
            scope.selectedpage += 1;
            page = scope.selectedpage;
          }

          legalTrendsDashboardRepository.getBrandList(str)
            .then(data => {
              scope.brandList = [];
              if(scope.brandList.length>0){  
                scope.brandList = scope.brandList.concat(data.brands);
              } else {
                scope.brandList = data.brands;
              }   
            }).finally(() => {
              scope.ajaxInProgress = false;
            });
          break; 

        default:
          scope.ajaxInProgress = false;
      }
    }; 

    scope.loadGraphics = function () {
      if (scope.mainParam) {
        scope.isLoadingControls = true;
        dashboardOverlayService.loadingStart(4000);

        if(localStorageService.get("currentUser")){
          scope.user = localStorageService.get("currentUser");
        }

        if (scope.mainParam === "color") {
          scope.isLoadingControls = false;
          legalTrendsDashboardRepository
          .getLogoTrademarked(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              40
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.pageCount = data.paginate.total_pages;
                scope.colorTrademarks = data.color_trademarks;
              } 
            });
        } else if (scope.mainParam === "productOrService") {
          scope.isLoadingControls = false;
          legalTrendsDashboardRepository
          .getLogoTrademarked(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              40,
              scope.selectedProductDashCharacter
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.pageCount = data.paginate.total_pages;
                scope.colorTrademarks = data.color_trademarks;
              } 
            });
        } else if (scope.mainParam === "brand") {
          scope.isLoadingControls = false;
          legalTrendsDashboardRepository
          .getLogoTrademarked(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              40,
              scope.selectedBrandDashCharacter
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.pageCount = data.paginate.total_pages;
                scope.colorTrademarks = data.color_trademarks;
              } 
            });
        } else if (scope.mainParam === "year") {
          scope.isLoadingControls = false;
          legalTrendsDashboardRepository
          .getLogoTrademarked(
              scope.mainParam,
              scope.selectedFilterValue,
              scope.currentPage + 1,
              40,
              scope.selectedCharacter
            )
            .then(function (data) {
              if(data.limit === 'exceed'){
                modalService.showModal(11, null, scope.user.first_name);
              } else {
                scope.pageCount = data.paginate.total_pages;
                scope.colorTrademarks = data.color_trademarks;
              } 
            });
        }
        scope.isLoadingControls = false;
      }
    };

    scope.setPage = (num) => {
      if (num >= 0 && num < scope.pageCount) {
        scope.currentPage = num;
        scope.loadGraphics();
      }
    };

    scope.handleChangeControl = function (control, item) {
      scope.header_title = () => {
        if (control === "productOrService") {
          return (control = "product");
        }
        if (control === "color") {
          return `${control}: ${item.family}`;
        } else {
          return `${control}: ${item.title}`;
        }

      };
      scope.mainParam = control;
      scope.selectedFilterValue = item;
      scope.mainParamId = item.id;
      scope.searchMode = control;

      scope.loadGraphics();
    };

    scope.handleSearchChange = function (control, item) {
      if(item.family === 'yellow/green') {
        item.family = 'Yellow - Green'
      }
      $state.go("legal-trends", Object.assign({control: control}, item));
    };

    scope.moveBrandNamePage = (item) => {
      $state.go("legal-trends", {control: "brand", id: "", title: item.brand, hex: "", family: ""});
    }

    scope.$watch(
      () => {
        return dashboardOverlayService.showOverlay;
      },
      (newValue) => {
        scope.showDashboardOverlay = newValue;
      }
    );
  },
]);
