(function () {
  angular.module("app").directive("hueColorTrademarkChart", [
    function () {
      function link(scope) {
        scope.$watch("data", (newValue) => {
          if (newValue) {
            bindData();
          }
        });

        function bindData() {
          let ctx
          if(scope.mode === 'popup'){
            ctx = document.getElementById("canvas_popup").getContext("2d");
          } else {
            ctx = document.getElementById("canvas").getContext("2d")
          }
          let labels = [];
          if (scope.data && scope.data.length) {
            let years = Object.entries(scope.data[0].year);
            labels = years.slice(-5).map((el) => el[0]);
          }
          const datasets = scope.data.map((el) => {
            const isColorLight = () => {
              const color =
                el.hex === "#ffffff"
                  ? "#010101"
                  : el.hex === "#F5F5DC"
                  ? "#030303"
                  : el.hex;

              const offset =
                color === "#010101"
                  ? [20, 3]
                  : color === "#030303"
                  ? [10, 5]
                  : [];

              return { color, offset };
            };
            return {
              label: "",
              fill: false,
              lineTension: 0.3,
              backgroundColor: el.hex,
              borderColor: isColorLight().color,
              borderCapStyle: "butt",
              borderDash: isColorLight().offset,
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: isColorLight().color,
              pointBackgroundColor: "#dbdbdb",
              pointBorderWidth: 1,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: isColorLight().color,
              pointHoverBorderColor: isColorLight().color,
              pointHoverBorderWidth: 2,
              pointRadius: 8,
              pointHitRadius: 10,
              data: Object.entries(el.year).slice(-5).map((el) => el[1]),
              spanGaps: false,
            };
          });

          const data = {
            labels: labels,
            datasets: datasets,
          };

          window.myLineChart = new Chart(ctx, {
            type: "line",
            data: data,
            options: {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false,
              },
              legendCallback: function (chart) {
                if (scope.mode === "dashboard") {
                  const items = [];
                  items.push(
                    '<ul class="' +
                    chart.id +
                    '-legend" style="padding: 0; margin: 0">'
                  );
                  for (let i = 0; i < scope.data.length; i++) {
                    items.push(
                      '<li id="legend-' +
                      i +
                      '-item" class="trademark-item ' +
                      (scope.data[i].hex === "#ffffff" ? 'white-color' : '') +
                      (!scope.selectedItem.hex && i == 0 ? ' selected-color' : '') +
                      (scope.data[i].hex === scope.selectedItem.hex ? ' selected-color"' : '"') +
                      ' style="background-color:' +
                      scope.data[i].hex +
                      '" onclick="updateDataset(event, ' +
                      "'" +
                      i +
                      "'" +
                      ')">'
                    );
                    if (scope.data[i].hex1) {
                      items.push(
                        `<div class="secondly-color-item" style="border-bottom-color: ${scope.data[i].hex1}"></div>`
                      );
                    }
                    if (scope.data[i].hex === "#ffffff") {
                      items.push("<span class='light-item'>-</span>");
                    }

                    items.push("</li>");
                  }

                  items.push("</ul>");
                  return items.join("");
                }
                if (scope.mode === "popup") {
                  const items = [];
                  items.push(
                    '<ul class="' +
                    chart.id +
                    '-legend" style="padding: 0; margin: 0">'
                  );
                  for (let i = 0; i < scope.data.length; i++) {
                    items.push(
                      '<li id="legend-' +
                      i +
                      '-item" class="trademark-item ' +
                      (scope.data[i].hex === "#ffffff" ? 'white-color' : '') +
                      (!scope.selectedItem.hex && i == 0 ? ' selected-color' : '') +
                      (scope.data[i].hex === scope.selectedItem.hex ? ' selected-color"' : '"') +
                      ' style="background-color:' +
                      scope.data[i].hex +
                      '">'
                    );
                    if (scope.data[i].hex1) {
                      items.push(
                        `<div class="secondly-color-item" style="border-bottom-color: ${scope.data[i].hex1}"></div>`
                      );
                    }
                    if (scope.data[i].hex === "#ffffff") {
                      items.push("<span class='light-item'>-</span>");
                    }

                    items.push("</li>");
                  }

                  items.push("</ul>");
                  return items.join("");
                }
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      fontSize: 16,
                      padding: 5,
                    },
                  },
                ],
                xAxes: [
                  {
                    ticks: {
                      fontSize: 16,
                      padding: 5,
                    },
                  },
                ],
              },
            },
          });

          const generatedLegend = () => {
            if(scope.mode === 'popup') {
              $("#do_legend_popup").html(window.myLineChart.generateLegend());
            } else {
              $("#do_legend").html(window.myLineChart.generateLegend());
            }
          };

          generatedLegend();

          if (scope.mode === "dashboard") {
            const windowResizeHandler = _.debounce(generatedLegend, 200);
            scope.$on("$destroy", function () {
              $(window).off("resize", windowResizeHandler);
            });
            $(window).resize(windowResizeHandler);
          }

          updateDataset = function (e, index) {
            const ci = e.view.myLineChart;
            const meta = ci.getDatasetMeta(index);

            meta.dataset._model.borderWidth = 9;
            ci.tooltip._data.datasets.forEach((element, i) => {
              element.borderWidth = 3;
            });
            ci.tooltip._data.datasets[index].borderWidth = 6;


            $(".trademark-item").removeClass("selected-color");
            $(`#legend-${index}-item`).addClass("selected-color");
            ci.update();
          };
        }
      }

      const directive = {
        link: link,
        restrict: "E",
        replace: true,
        scope: {
          data: "=",
          mode: "=",
          selectedItem: "=",
        },
        templateUrl:
          "app/directives/dbColorTrademarksChart/dbColorTrademarksChartView.html",
      };

      return directive;
    },
  ]);
})();
